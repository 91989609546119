import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AddComment from "./AddComment";
import { appContext } from "../../App";
import firebase from "../../firebase/firebase";
import { Avatar, Grid, Menu, MenuItem, TextField } from "@material-ui/core";
import moment from "moment";
import toastr from "toastr";
import ReactAvatar from "react-avatar";
import { DATE_TIME_FORMAT } from "../../utils/utils";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ArrowBack from "@material-ui/icons/ArrowBack";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    maxWidth: "md",
  },
  submitButton: {
    position: "absolute",
    right: theme.spacing(8),
    top: theme.spacing(2),
    color: "white",
    background: "red",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, setCommentId, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Replies({
  openReplies,
  onClose,
  path,
  comment,
  commentType,
}) {
  const { authUser, isAdmin, isSuperAdmin } = useContext(appContext);
  const [comments, setComments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [myCom, setMyCom] = useState([]);
  const [editedMsg, setEditedMsg] = useState("");
  const [showTextbox, setShowTextbox] = useState(false);
  const [test, setTest] = useState([]);
  const [commentId, setCommentId] = useState("");
  const [more, setMore] = useState(3);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const handleDeleteComment = (key) => {
    if (window.confirm("Are you sure you want to delete the comment?")) {
      firebase.realtimeDb
        .ref(path + "/" + key)
        .remove()
        .then(() => {
          toastr.success("Successfully deleted");
          loadComments();
          // if (props.onClick) {
          //   props.onClick();
          // }

          handleMobileMenuClose();
        });
    }
  };
  const handleEditComment = (comment) => {
    let com = comments.filter((item) => item.key === comment.key);
    setTest(com);
    setShowTextbox(true);
  };

  const checkEditMsg = (event, comm) => {
    if (event.key === "Enter") {
      const newComment = event.target.value;
      if (newComment && newComment.length > 0) {
        firebase.realtimeDb
          .ref(path + "/" + comm.key)
          .update({
            text: newComment,
            whenSent: new Date().getTime(),
          })
          .then(() => {
            toastr.success("Successfully edited");
            setShowTextbox(false);
            setEditedMsg("");
            setEdit(false);
            loadComments();
            // if (props.onClick) {
            //   props.onClick();
            // }
          })
          .catch((error) => {
            toastr.error(error.message);
            setShowTextbox(true);
          });
      } else {
        toastr.error("please enter a valid text");
        setShowTextbox(true);
      }
    }
  };

  const isLoggedInUserLiked = (likesPath) => {
    let isLiked = false;
    if (authUser && authUser.unique_id) {
      const ref = firebase.realtimeDb.ref(likesPath);
      ref.on("value", (snapshot) => {
        snapshot.forEach((productSnapshot) => {
          let data = productSnapshot.val();
          if (data.userId === authUser.unique_id) {
            isLiked = true;
          }
        });
      });
    }
    return isLiked;
  };

  const unLikeComment = (likesPath) => {
    if (authUser && authUser.unique_id) {
      const ref = firebase.realtimeDb.ref(likesPath);
      ref.on("value", (snapshot) => {
        snapshot.forEach((productSnapshot) => {
          let key = productSnapshot.key;
          let data = productSnapshot.val();
          if (data.userId === authUser.unique_id) {
            firebase.realtimeDb.ref(likesPath + "/" + key).remove();
            console.log("removed like node");
          }
        });
      });
      ref.off("value");
    }
  };

  const toggleLike = (key) => {
    const likesPath = path + "/" + key + "/likes";
    if (isLoggedInUserLiked(likesPath)) {
      unLikeComment(likesPath);
    } else {
      addLike(
        likesPath,
        authUser.unique_id,
        (authUser &&
          authUser.name &&
          authUser.name.first_name + " " + authUser.name.last_name) ||
          "",
        authUser && authUser.profile_image_url
      );
    }
  };

  const addLike = (path, userId, name, likedByProfileImageUrl) => {
    const ref = firebase.realtimeDb.ref(path);
    const values = {};
    values.userId = userId;
    values.name = name;
    values.likedByProfileImageUrl = likedByProfileImageUrl || "";
    values.whenLiked = new Date().getTime();
    ref
      .push()
      .set(values)
      .then(() => {
        console.log("Added like node");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  useEffect(() => {
    if (openReplies) {
      loadComments();
    }
  }, [openReplies, commentId]);

  const loadComments = () => {
    const ref = firebase.realtimeDb.ref(path);

    ref.on("value", (snapshot) => {
      const commentsList = [];
      snapshot.forEach((productSnapshot) => {
        let key = productSnapshot.key;
        let data = productSnapshot.val();
        commentsList.push({ key: key, ...data });
      });
      setComments(commentsList && commentsList.reverse());
    });
  };
  const getCountAtGivenPath = (path) => {
    const ref = firebase.realtimeDb.ref(path);
    let commentsList = [];
    ref.on("value", (snapshot) => {
      commentsList = [];
      snapshot.forEach((productSnapshot) => {
        let key = productSnapshot.key;
        let data = productSnapshot.val();
        commentsList.push({ key: key, ...data });
      });
      ref.off("value");
    });
    return commentsList.length;
  };
  const handleEditMsg = (e) => {
    setEdit(true);
    setEditedMsg(e.target.value);
  };

  const handleMoreComments = () => {
    setMore(more + 3);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openReplies}
      fullWidth="true"
      maxWidth="md"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        setCommentId={setCommentId}
      >
        <ArrowBack
          onClick={onClose}
          style={{ cursur: "pointer", color: "#000" }}
        />
        {commentType === "forum" ? "Forums" : "Comments"}
      </DialogTitle>
      <DialogContent dividers>
        <div className="comments-container">
          <div className="comment-section-block">
            <div>
              <Typography
                className="recent-comments"
                style={{ marginBottom: "30px" }}
              >
                {comment.senderProfileImageUrl ? (
                  <Avatar
                    src={comment.senderProfileImageUrl}
                    alt="Avatar"
                    className="comment-avatar"
                  />
                ) : (
                  <ReactAvatar
                    name={comment.senderName}
                    size="30"
                    round={true}
                    className="comment-avatar"
                  />
                )}{" "}
                <Typography className="comments-main-details">
                  {test &&
                  test[0] &&
                  test[0].whenSent === comment.whenSent &&
                  showTextbox ? (
                    <TextField
                      type="text"
                      name="message"
                      value={edit ? editedMsg : comment.text}
                      className="comments-edit-field"
                      onChange={handleEditMsg}
                      onKeyPress={(e) => checkEditMsg(e, comment)}
                      placeholder="Add a reply"
                      width={16}
                      autoFocus
                    />
                  ) : (
                    <Typography style={{ position: "relative" }}>
                      <Typography className="comment-details">
                        <span className="commentor-name">
                          {comment.senderName}
                        </span>{" "}
                        |{" "}
                        <span className="comment-time">
                          {moment(comment.whenSent).format(DATE_TIME_FORMAT)}
                        </span>
                      </Typography>{" "}
                      <Typography className="comment-text">
                        {comment.text}
                      </Typography>
                      {/* <Button
                        className="likebtn"
                        onClick={() => toggleLike(comment.key)}
                      >
                        {isLoggedInUserLiked(likesPath) ? (
                          <ThumbUpIcon style={{ fill: "#0000ff" }} />
                        ) : (
                          <ThumbUpIcon style={{ fill: "" }} />
                        )}
                        {" (" + getCountAtGivenPath(likesPath) + ")"}
                      </Button> */}
                    </Typography>
                  )}
                </Typography>
                {/* {comment.key === commentId && (
                        <div>
                          <AddEditQuestion
                            path={path + "/" + comment.key + "/replies"}
                            senderProfileImageUrl={
                              authUser && authUser.profile_image_url
                            }
                            userId={authUser.unique_id}
                            senderName={
                              (authUser &&
                                authUser.name &&
                                authUser.name.first_name +
                                  " " +
                                  authUser.name.last_name) ||
                              ""
                            }
                            setCommentId={setCommentId}
                          />
                        </div>
                      )} */}
                {((authUser &&
                  comment.userId &&
                  authUser.unique_id === comment.userId) ||
                  isSuperAdmin ||
                  isAdmin) && (
                  <>
                    <Menu
                      anchorEl={mobileMoreAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      id={mobileMenuId}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={isMobileMenuOpen}
                      onClose={handleMobileMenuClose}
                    >
                      {authUser && myCom.userId === authUser.unique_id && (
                        <MenuItem
                          onClick={() => handleEditComment(myCom)}
                          className="my-list"
                          style={{ height: 40 }}
                        >
                          {" "}
                          <IconButton color="inherit" style={{ fontSize: 18 }}>
                            <p>Edit</p>
                          </IconButton>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => handleDeleteComment(myCom.key)}
                        style={{
                          height: 30,
                          marginTop: isAdmin || isSuperAdmin ? 10 : 0,
                        }}
                        className="my-list"
                      >
                        <IconButton color="inherit" style={{ fontSize: 18 }}>
                          <p>Delete</p>
                        </IconButton>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Typography>
            </div>
            {authUser && (
              <AddComment
                path={path}
                senderProfileImageUrl={authUser && authUser.profile_image_url}
                userId={authUser.unique_id}
                senderName={
                  (authUser &&
                    authUser.name &&
                    authUser.name.first_name + " " + authUser.name.last_name) ||
                  ""
                }
                setCommentId={setCommentId}
                loadComments={loadComments}
                promptText="Add a reply"
              />
            )}

            <div className="reply-section-block">
              {comments &&
                comments.slice(0, more).map((comment) => {
                  const likesPath = path + "/" + comment.key + "/likes";
                  return (
                    <Typography
                      className="recent-comments"
                      style={{ marginBottom: "30px" }}
                    >
                      {comment.senderProfileImageUrl ? (
                        <Avatar
                          src={comment.senderProfileImageUrl}
                          alt="Avatar"
                          className="comment-avatar"
                        />
                      ) : (
                        <ReactAvatar
                          name={comment.senderName}
                          size="30"
                          round={true}
                          className="comment-avatar"
                        />
                      )}{" "}
                      <Typography className="comments-main-details">
                        {test &&
                        test[0] &&
                        test[0].whenSent === comment.whenSent &&
                        showTextbox ? (
                          <TextField
                            type="text"
                            name="message"
                            value={edit ? editedMsg : comment.text}
                            className="comments-edit-field"
                            onChange={handleEditMsg}
                            onKeyPress={(e) => checkEditMsg(e, comment)}
                            placeholder="Add a reply"
                            width={16}
                            autoFocus
                          />
                        ) : (
                          <Typography style={{ position: "relative" }}>
                            <Typography className="comment-details">
                              <span className="commentor-name">
                                {comment.senderName}
                              </span>{" "}
                              |{" "}
                              <span className="comment-time">
                                {moment(comment.whenSent).format(
                                  DATE_TIME_FORMAT
                                )}
                              </span>
                            </Typography>{" "}
                            <Typography
                              className="comment-text"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {comment.text}
                            </Typography>
                            <div className="forumcls">
                              <div>
                                <Button onClick={() => toggleLike(comment.key)}>
                                  {isLoggedInUserLiked(likesPath) ? (
                                    <ThumbUpIcon style={{ fill: "#0000ff" }} />
                                  ) : (
                                    <ThumbUpIcon style={{ fill: "" }} />
                                  )}
                                  {" (" + getCountAtGivenPath(likesPath) + ")"}
                                </Button>
                              </div>
                              <div>
                                {authUser &&
                                  comment &&
                                  comment.userId == authUser.unique_id && (
                                    <Button
                                      onClick={() => handleEditComment(comment)}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                {((authUser &&
                                  comment &&
                                  authUser.unique_id == comment.userId) ||
                                  isSuperAdmin ||
                                  isAdmin) && (
                                  <Button
                                    onClick={() =>
                                      handleDeleteComment(comment.key)
                                    }
                                  >
                                    Delete
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Typography>
                        )}
                      </Typography>
                    </Typography>
                  );
                })}
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {!authUser && (
                  <Typography className="not-logged-text">
                    {commentType === "forum"
                      ? "Please signup or login to add a question"
                      : "Please signup or login to add a comment"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              {comments.length > more && (
                <div className="comment-btn" onClick={handleMoreComments}>
                  {commentType === "forum"
                    ? "View more questions..."
                    : "View more comments..."}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

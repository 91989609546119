import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Select, MenuItem } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../donationDetails/Payment.scss";
import { saveDonation } from "../../utils/api";

const OfflineDonationDetails = (props) => {
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState([props.donationDetails.cashUnit]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorAmount, setErrorAmount] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [isAnonymous, setAnonymous] = useState(false);
  const [donationFor, setDonationFor] = useState("donationRequest");
  const [disableCurrency, setDisableCurrency] = useState(false);

  const changeHandler = (event, type) => {
    if (type === "amount") {
      setAmount(event.target.value);
      setErrorAmount("");
    } else if (type === "name") {
      setName(event.target.value);
      setErrorName("");
    } else if (type === "email") {
      setEmail(event.target.value);
      setErrorEmail("");
    }
  };

  const restrictCharacters = (event) => {
    const allowedCharacters = /^[0-9]\d*$/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  };

  const getParams = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params;
  };
  const handleChange = (event) => {
    setAnonymous(event.target.checked);
    setErrorName("");
    setErrorEmail("");
  };

  useEffect(() => {
    let params = getParams();
    if (params.get("email")) {
      setEmail(params.get("email"));
    }
    if (params.get("name")) {
      setName(params.get("name"));
    }
    if (props.amt) {
      setAmount(props.amt);
    }
    if (params.get("donationfor")) {
      setDonationFor(params.get("donationfor"));
      if (params.get("donationfor") === "donationRequest") {
        setDisableCurrency(true);
      }
    }
    setCurrency(props.donationDetails.cashUnit);
  }, [props]);

  const submitHandler = () => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (
      name === "" &&
      email === "" &&
      (!amount || isNaN(amount) || Number(amount) < 1)
    ) {
      setErrorName("Please enter Name");
      setErrorEmail("Please enter Email");
      setErrorAmount("Please enter valid amount");
    } else if (!amount || isNaN(amount) || Number(amount) < 1) {
      setErrorAmount("Please enter valid amount");
    } else if (name === "") {
      setErrorName("Please enter Name");
    } else if (email === "" || pattern.test(email) === false) {
      setErrorEmail("Please enter a Valid address");
    } else {
      var paymentObj = {};
      paymentObj.donation_request_id = props && props.donationRequestId;
      paymentObj.type = props && props.donationDetails.requestType;
      paymentObj.quantity = Number(amount);
      paymentObj.units = props && props.donationDetails.cashUnit;
      paymentObj.notes = "Offline donation by Admin";
      paymentObj.donationFor = donationFor;
      paymentObj.anonymous = isAnonymous;
      paymentObj.externalUserInfo = {
        name: name,
        email: email,
      };
      saveDonation(paymentObj)
        .then((response) => {
          if (response.statusCode === 200) {
            toastr.success(response.message);
            if (props.redirect) {
              props.redirectEvent();
            } else {
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
      setAmount("");
      setName("");
      setEmail("");
      props.onClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className="payment-modal"
        open={props.isOpen}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isOpen}>
          <div className="public-payment-container add-offline">
            <div className="payment-page">
              <div className="pay-headername">
                <h2>Add Offline Donation Details</h2>
                <Button
                  className="close-payment"
                  onClick={props.onClose}
                  style={{
                    cursor: "pointer",
                 position:"absolute",
                 right:"10px",
                 top:"10px",
                    background: "#f3f3f3",
                    borderRadius: "50%",
                   padding:"5px",
                    color: "rgb(205, 38, 39)",
                    minWidth: "20px",
                    color:"var(--tal_primary)",
                  }}
                >
                  <Close />
                </Button>
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnonymous}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Donate as anonymous"
                />
              </div>

              <TextField
                type="text"
                className="full-width border-radius input-field input-fild-border"
                placeholder="Name"
                name="name"
                value={name}
                onChange={(e) => changeHandler(e, "name")}
              />
              <span>
                {errorName && (
                  <div style={{ color: "red", "font-size": "12px" }}>
                    {errorName}
                  </div>
                )}
              </span>
              <TextField
                type="text"
                className="full-width border-radius input-field input-fild-border"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => changeHandler(e, "email")}
              />
              <span>
                {errorEmail && (
                  <div style={{ color: "red", "font-size": "12px" }}>
                    {errorEmail}
                  </div>
                )}
              </span>
              <div className="payment-textfield-container">
                <TextField
                  type="number"
                  className="full-width border-radius payment-input"
                  placeholder="Donation Amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => changeHandler(e, "amount")}
                  onKeyPress={restrictCharacters}
                />
                <FormControl style={{ width: "30%" }}>
                  <Select
                    disabled={disableCurrency}
                    name="currency"
                    value={currency}
                    className="currency"
                  >
                    <MenuItem value={currency}>{currency}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <span>
                {errorAmount && (
                  <div style={{ color: "red", "font-size": "12px" }}>
                    {errorAmount}
                  </div>
                )}
              </span>
              <div className="payment-btn">
                <Button
            
                  className="pay-btn"
                  variant="contained"
                  type="submit"
                  onClick={submitHandler}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default withRouter(OfflineDonationDetails);

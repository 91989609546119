import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NewAddress from "../common/NewAddress";
import FormControl from "@material-ui/core/FormControl";
import { appContext } from "../../App";

import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
// import noValidate from "./noValidate";
import useFormValidation from "../../hooks/useFormValidation";
import { addOrganization, updateOrganizationStatus } from "../../utils/api";
import toastr from "toastr";
// import "./Organization.scss";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import {
    Divider,
  FormHelperText,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import PhoneNumber from "../Phone/PhoneNumber";
import { useRef } from "react";
import PhoneInput from "react-phone-number-input";
import cssstyles from "./NominateAdd.module.scss"
import Avatar from "react-avatar";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
    marginTop:"-15px"
  },

  orgTitle: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "600",
    textTransform: "inheirt",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const NominateAdd = (props) => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    occupation: "",
    gender: "",
    audioRoom: "",
  };
  const classes = useStyles();
  const { authUser, orgList, setOrgList, userLocaleInfo } =
    useContext(appContext);
  const [id, setId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phone, setPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const updateDefaultImageUrl = (newImageUrl) => {
    setDefaultImageUrl(newImageUrl);
    setOrgList(
      orgList.map((org) => {
        if (org && org.orgId && org.orgId._id === id) {
          org.orgId.defaultImageUrl = newImageUrl;
        }
        return org;
      })
    );
  };

  useEffect(() => {
    if (defaultImageUrl) {
      updateDefaultImageUrl(defaultImageUrl);
    }
  }, [defaultImageUrl]);

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const { orgDetails } = props;

  useEffect(() => {
    if (orgDetails && orgDetails.orgAddress) {
      setAddress({
        line1: orgDetails.orgAddress.hasOwnProperty("line1")
          ? orgDetails.orgAddress.line1
          : "",
        line2: orgDetails.orgAddress.hasOwnProperty("line2")
          ? orgDetails.orgAddress.line2
          : "",
        city: orgDetails.orgAddress.hasOwnProperty("city")
          ? orgDetails.orgAddress.city
          : "",
        state: orgDetails.orgAddress.hasOwnProperty("state")
          ? orgDetails.orgAddress.state
          : "",
        zip_code: orgDetails.orgAddress.hasOwnProperty("zip_code")
          ? orgDetails.orgAddress.zip_code
          : "",
        country: orgDetails.orgAddress.hasOwnProperty("country")
          ? orgDetails.orgAddress.country
          : "",
      });
    }
    if (orgDetails) {
      setId(orgDetails._id);
      setDefaultImageUrl(orgDetails.defaultImageUrl);
      setEditMode(true);
    }
  }, [orgDetails]);

  const handleAddOrg = () => {
    const finalValues = {};
    finalValues.userId = authUser && authUser.unique_id;
    finalValues.orgName = values.name ? values.name : orgDetails.orgName;
    finalValues.orgType = values.type ? values.type : orgDetails.orgType;
    finalValues.description = values.description
      ? values.description
      : orgDetails.description;
    finalValues.orgEmail = values.email
      ? values.email
      : orgDetails && orgDetails.orgEmail;
    finalValues.websiteUrl = values.website
      ? values.website
      : orgDetails && orgDetails.websiteUrl;
    finalValues.defaultImageUrl = defaultImageUrl;
    finalValues.hasCsrWing = values.isCSRWing;
    if (
      tempAddress &&
      (tempAddress.line1 || tempAddress.line2) &&
      tempAddress.city &&
      tempAddress.state &&
      tempAddress.country &&
      tempAddress.zip_code
    ) {
      finalValues.orgAddress = {
        line1: tempAddress.line1,
        line2: tempAddress.line2,
        city: tempAddress.city,
        state: tempAddress.state,
        country: tempAddress.country,
        zip_code: tempAddress.zip_code,
      };
      setAddressError("");
    } else {
      setAddressError("Please provide the full address of the Organization.");
      return;
    }
    if (editMode && orgDetails && orgDetails._id) {
      updateOrganizationStatus(orgDetails._id, finalValues).then((resp) => {
        if (resp.status === 200) {
          setIsOpen(true)
          toastr.success(resp.data.message);
          props.onSavingOrg(orgDetails._id);
          setId(orgDetails._id);
          setOrgList((currentOrgs) => {
            return [...currentOrgs, { orgId: resp.data }];
          });
        }
      });
    } else if (step === 1 && !editMode) {
      addOrganization(finalValues)
        .then((resp) => {
          setIsOpen(true)
          if (resp.statusCode === 200) {
            toastr.success(resp.message);
            const org = resp.data;
            setStep(2);
            setId(resp.data.orgId);
            setOrgList((currentOrgs) => {
              return [
                ...currentOrgs,
                { ...org, orgId: { _id: resp.data.orgId, ...finalValues } },
              ];
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toastr.error(
              "That organization name is already taken. Please use a different name"
            );
          } else {
            toastr.error(error.response.data.message);
          }
        });
    } else if (step === 2 && !editMode) {
      const valuesToSave = {};
      valuesToSave.defaultImageUrl = defaultImageUrl;
      updateOrganizationStatus(id, valuesToSave).then((resp) => {
        if (resp.status === 200) {
          setId(resp.data.data._id);
          setOrgList(
            orgList.map((org) => {
              if (org && org.orgId && org.orgId._id === id) {
                org.orgId.defaultImageUrl = defaultImageUrl;
              }
              return org;
            })
          );
          // setOrgList((currentOrgs) => {
          //   return [...currentOrgs, { orgId: resp.data.data }];
          // });
          toastr.success(resp.data.message);
          props.onSavingOrg(id);
        }
      });
    }
  };
  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    handleAddOrg
  );

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };
  

  return (
    <>
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openOrgModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        
      >
        <Fade in={props.openOrgModal}>
          <div className={classes.paper}>
            <div className={cssstyles.Fade_style}>
              <h1 className={classes.orgTitle}>Nominate Individual</h1>
              <span className={cssstyles.drop_box_close_organizatin} >
                <CloseIcon onClick={props.onCloseModal} />
              </span>
            </div>
            <div className={cssstyles.plasma}>
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography className={cssstyles.head_model}>
                  Basic Info
                </Typography>
                
              </Grid>
              <Grid container spacing={2} className={cssstyles.head_style}>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    type="text"
                    className={`input-field request-title`}
                    label ="Frist Name"

                    // className={
                    //   styles.plasma_input +
                    //   " " +
                    //   ` ${
                    //     errors.firstName
                    //       ? styles.bg_red
                    //       : values.firstName
                    //       ? styles.bg_green
                    //       : styles.bg_normal
                    //   }`
                    // }
                    variant="outlined"
                    name="firstName"
                    //disabled={!editMode}
                    value={values.firstName}
                    onChange={changeHandler}
                  />
                  {errors && errors.firstName && (
                    <Typography className="custom-error">
                      {errors.firstName}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    type="text"
                    variant="outlined"
                    className={`input-field request-title`}
                    label ="last Name"

                    // className={
                    //   styles.plasma_input +
                    //   " " +
                    //   ` ${
                    //     errors.lastName
                    //       ? styles.bg_red
                    //       : values.lastName
                    //       ? styles.bg_green
                    //       : styles.bg_normal
                    //   }`
                    // }
                    name="lastName"
                    //disabled={!editMode}
                    value={values.lastName}
                    onChange={changeHandler}
                  />
                  {errors && errors.lastName && (
                    <Typography className="custom-error">
                      {errors.lastName}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={12} style={{marginBottom:"20px"}}>
                  <TextField
                      type="text"
                      required
                      className={`input-field request-title`}
                      variant="outlined"
                      placeholder="Email"
                      label = "Email"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12} style={{ width: "100%",marginBottom:"20px" }}>
                  <PhoneInput
                    className={`input-field request-title phoneBdr `}
                    
                    style={{ margin: "15px 0 7px 0", }}
                    label ="Phone"
                    // className={
                    //   styles.plasma_input +
                    //   " " +
                    //   `phoneBdr  request-amount ${
                    //     errors.phone
                    //       ? styles.bg_red
                    //       : phone
                    //       ? styles.bg_green
                    //       : styles.bg_normal
                    //   }`
                    // }
                    variant="outlined"
                    fullWidth
                    placeholder="Phone Number"
                    name="phoneNumber"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry={
                      userLocaleInfo && userLocaleInfo.country_code
                    }
                    value={phone}
                    onChange={setPhone}
                    // onKeyPress={(e) => handleEnterClick(e)}
                  />
                  {errors && errors.phone && (
                    <Typography className="custom-error">
                      {errors.phone}
                    </Typography>
                  )}
                </Grid>
                </Grid>
                <div style={{marginTop:"10px"}}>
              <TextField
                      type="text"
                      required
                      className={`input-field request-title`}
                      variant="outlined"
                      placeholder="Profile Link*"
                      label = "Profile Link"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                      style={{marginTop:"10px"}}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                    </div>
                   
                     <Typography className={cssstyles.head_model}>Location</Typography>
                <TextField
                      type="text"
                      required
                      className={`input-field request-title`}
                      variant="outlined"
                      placeholder="Location "
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                    <Typography className={cssstyles.head_model}>Reason</Typography>
                    <TextareaAutosize
                      name="description"
                      variant="outlined"
                      className={`input-field request-title`}
                      multiline
                      rows="2"
                      placeholder="Enter Description  *"
                      value={values.description}
                      onChange={changeHandler}
                      maxLength={1000}
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        paddingTop: "26px",
                        borderRadius:"5px"
                      }}
                      >

                      </TextareaAutosize>
                       
                       <Divider/>
               <div className={cssstyles.save_btn11}>
              <Button className={classes.saveBtn} onClick={()=>setIsOpen(true)}>
               submit
              </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      
     
    </div>
     <div>
     <Modal
       aria-labelledby="spring-modal-title"
       aria-describedby="spring-modal-description"
       className="payment-modal"
       open={isOpen}
       onClose={props.onClose}
       closeAfterTransition
       BackdropComponent={Backdrop}
       BackdropProps={{
         timeout: 500,
       }}
     >
       <Fade in={isOpen}>
         <div className="payment-container">
           <div className = {cssstyles.close_icon} >
         <CloseIcon onClick={()=>setIsOpen(false)}/>
         </div >
           <div className="payment-page">
             <div>
               <div className="pay-headername">
                 <div className="avatar-cont">
                   <Avatar
                     alt="Remy Sharp"
                     className="avatar"
                     src="/images/right-tick.svg"
                   />
                 </div>
                 <p className="payment-text">
                   Your TalHeros Award Recommendations
                 </p>
                 <p className="payment-text">successfully sent</p>
                 <p >
                   Lerom ipsum is simply  dummy  text of the  printing and typesting industry {" "}
                 </p>
               </div>
               <div className="payment-btn">
                 <Button
                   color="primary"
                   className="pay-btn"
                   variant="contained"
                   type="submit"
                   onClick={()=>setIsOpen(false)}
                   style={{}}
                 >
                   Close
                 </Button>
               </div>
             </div>
           </div>
         </div>
       </Fade>
     </Modal>
   </div>
   </>
  );
};

export default withRouter(NominateAdd);

import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Grid,
  Dialog,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import PhoneNumber from "../Phone/PhoneNumber";
import AddDocument from "../common/AddDocument";
import toastr from "toastr";
import moment from "moment";
import { applyDonationRequest, getChatRooms } from "../../utils/api";
import ShortAddressIsolated from "../common/ShortAddressIsolated";

const ApplyPost = (props) => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    occupation: "",
    gender: "",
    audioRoom: "",
  };

  const [post, setPost] = useState({});
  const [documentUrl, setDocumentUrl] = useState("");
  const phoneRef = useRef();
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const { authUser, setAuthUser, chats, setChats } = useContext(appContext);
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState);
  const [address, setAddress] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const finalValues = {};
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const onSavingDocument = (message, url) => {
    setDocumentUrl(url);
    toastr.success(message);
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const [audioRoomUrl, setAudioRoomUrl] = useState("");

  const handleJoin = (row) => {
    if (!authUser || !authUser.unique_id) {
      toastr.error("Please login to access audio rooms");
      return;
    }

    window.open(
      process.env.REACT_APP_CHAT_SERVER_BASE_URL +
        "/join/" +
        row._id +
        "?userId=" +
        authUser.unique_id
    );
  };

  const handleSubmitData = () => {
    const errors = {};
    if (
      post.request_type === "internship" ||
      post.request_type === "volunteering" ||
      post.request_type === "podcast" ||
      post.request_type === "eventSpeaker" ||
      post.request_type === "mentoring"
    ) {
      if (values.firstName.length === 0) {
        errors.firstName = "Please enter First Name";
      }
      if (values.lastName.length === 0) {
        errors.lastName = "Please enter Last Name";
      }
      if (values.email.length === 0) {
        errors.email = "Please enter Email";
      }
      if (!values.gender || values.gender == " ") {
        errors.gender = "Please select gender";
      }
      if (
        tempAddress === null ||
        tempAddress.city === null ||
        tempAddress.state === null ||
        tempAddress.country === null
      ) {
        errors.address = "Please Select your Location";
      }
    } else if (post.request_type === "homework help") {
      if (Object.keys(values.audioRoom).length === 0) {
        errors.audioRoom = "Please Select AudioRoom Url";
      }
    } else if (post.request_type === "board member") {
      if (values.firstName.length === 0) {
        errors.firstName = "Please enter First Name";
      }
      if (values.lastName.length === 0) {
        errors.lastName = "Please enter Last Name";
      }
      if (values.email.length === 0) {
        errors.email = "Please enter Email";
      }
      if (!values.gender || values.gender == " ") {
        errors.gender = "Please select gender";
      }
      if (
        tempAddress === null ||
        tempAddress.city === null ||
        tempAddress.state === null ||
        tempAddress.country === null
      ) {
        errors.address = "Please Select your Location";
      }
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    finalValues.donation_request_info = post._id;
    finalValues.user_info = post.user_info._id;
    const participantName = {
      first_name: values.firstName,
      last_name: values.lastName,
    };
    finalValues.participantName = participantName;
    finalValues.participantPhone = authUser.phone;
    finalValues.participantOccupation = values.occupation;
    finalValues.participantGender = values.gender;

    if (tempAddress) {
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
      finalValues.address = tempAddress;
    } else {
      finalValues.city = address.city;
      finalValues.state = address.state;
      finalValues.country = address.country;
      finalValues.address = address;
    }
    finalValues.participantEmailAddress = values.email;

    //finalValues.participantResumeDownloadUrl="test";
    if (Object.keys(values.audioRoom).length > 0) {
      setAudioRoomUrl(
        process.env.REACT_APP_CHAT_SERVER_BASE_URL +
          "/join/" +
          values.audioRoom._id +
          "?userId=" +
          authUser.unique_id
      );
    }
    finalValues.participantAudioRoomUrl = values.audioRoom;

    applyDonationRequest(finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (post.request_type === "internship") {
            toastr.success("Successfully applied for internship request");
          } else if (post.request_type === "volunteering") {
            toastr.success("Successfully applied for Volunteering request");
          } else if (post.request_type === "homework help") {
            toastr.success("Successfully applied for Homework help");
          } else if (post.request_type === "board member") {
            toastr.success("Successfully applied for Board Member Request");
          } else if (post.request_type === "podcast") {
            toastr.success("Successfully applied for Podcast Request");
          } else if (post.request_type === "eventSpeaker") {
            toastr.success("Successfully applied for Event Speaker Request");
          } else if (post.request_type === "mentoring") {
            toastr.success("Successfully applied for Mentoring Request");
          }
          if (
            post.request_type === "board member" ||
            post.request_type === "podcast" ||
            post.request_type === "eventSpeaker" ||
            post.request_type === "mentoring"
          ) {
            props.setDonationRequest &&
              props.setDonationRequest({ ...post, isScholarshipApplied: true });
            props.setDonationRequests &&
              props.setDonationRequests([
                ...props.donationRequests.map((dr) => {
                  if (dr._id === post._id) {
                    return { ...post, isScholarshipApplied: true };
                  } else {
                    return dr;
                  }
                }),
              ]);
          }
          setErrors({});
          props.onClose();
        }
      })
      .catch((error) => {
        toastr.error(
          error.response && error.response.data && error.response.data.message
        );
      });
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      setPost(props.post);
      values.firstName = authUser.name.first_name;
      values.lastName = authUser.name.last_name;
      values.occupation = authUser.occupation;
      values.email = authUser.email;
      values.gender = authUser.gender;
      setAddress(authUser.address);
      setTempAddress(authUser.address);
    }
  }, [authUser]);

  useEffect(() => {
    finalValues.participantResumeDownloadUrl = documentUrl;
  }, [documentUrl]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      {post && post.request_type && post.request_type === "board member" ? (
        <>
          {/* <Grid item xs={12}>
           
          </Grid> */}
          <Grid
            item
            xs={12}
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                // marginBottom: "15px",
              }}
            >
              Confirm your Details
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              style={{ float: "right" }}
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container>
            <Grid item md={6} xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.firstName}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  value={values.firstName}
                  onChange={changeHandler}
                />
                <FormHelperText>{errors.firstName}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.firstName}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  value={values.lastName}
                  onChange={changeHandler}
                />
                <FormHelperText>{errors.lastName}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.email}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  value={values.email}
                  onChange={changeHandler}
                  disabled
                />
                <FormHelperText>{errors.email}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} className="phone-apply">
              <FormControl style={{ width: "100%", padding: "10px" }}>
                <PhoneNumber
                  phone={authUser && authUser.phone}
                  isPhoneVerified={authUser && authUser.phone_verified}
                  ref={phoneRef}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                style={{ width: "100%", padding: "15px" }}
                className=" recipient"
                error={errors && errors.address}
              >
                <p className="step-one-title" style={{ fontWeight: "bold" }}>
                  Select your location
                </p>
                <ShortAddressIsolated
                  value={address}
                  onChange={handleChangeAddress}
                />
                <FormHelperText>{errors.address}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container style={{ padding: "0 20px" }}>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  style={{
                    background: "#f44336",
                    margin: "30px 10px 10px 0px",
                    float: "right",
                  }}
                  alignItems="center"
                  className="submit-button"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmitData}
                >
                  Connect
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12}>
            <IconButton
              edge="start"
              color="inherit"
              style={{ float: "right" }}
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "15px",
              }}
            >
              Quickly Review and Apply
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.firstName}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="firstName"
                  label="First Name *"
                  placeholder="Enter First Name"
                  value={values.firstName}
                  onChange={changeHandler}
                />
                <Typography className="custom-error">
                  {errors.firstName}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.lastName}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="lastName"
                  label="Last Name *"
                  placeholder="Enter Last Name"
                  value={values.lastName}
                  onChange={changeHandler}
                />
                <Typography className="custom-error">
                  {errors.lastName}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.email}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="email"
                  label="Email *"
                  placeholder="Enter Email"
                  value={values.email}
                  onChange={changeHandler}
                  disabled
                />
                <Typography className="custom-error">{errors.email}</Typography>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} className="phone-apply">
              <FormControl style={{ width: "100%", padding: "10px" }}>
                <PhoneNumber
                  phone={authUser && authUser.phone}
                  isPhoneVerified={authUser && authUser.phone_verified}
                  ref={phoneRef}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                fullWidth
                style={{ padding: "7px 15px 7px 10px" }}
                error={errors && errors.gender}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{
                    margin: "0px 20px",
                    background: "#fff",
                    zIndex: "3",
                  }}
                >
                  Gender *
                </InputLabel>
                <Select
                  value={values.gender}
                  onChange={changeHandler}
                  variant="outlined"
                  className="profile-fields gender"
                  style={{ width: "100%" }}
                  name="gender"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  required
                >
                  <MenuItem value={" "}>Select Gender</MenuItem>
                  <MenuItem value={"m"}>Male</MenuItem>
                  <MenuItem value={"f"}>Female</MenuItem>
                  <MenuItem value={"o"}>Other</MenuItem>
                </Select>

                <Typography className="custom-error">
                  {errors.gender}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                style={{ width: "100%", padding: "15px" }}
                className=" recipient"
                error={errors && errors.address}
              >
                <p className="step-one-title" style={{ fontWeight: "bold" }}>
                  Select your location *
                </p>
                <ShortAddressIsolated
                  value={address}
                  onChange={handleChangeAddress}
                />
                <FormHelperText>{errors.address}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{
                background: "#f44336",
                margin: "30px 0px 0px",
                float: "right",
              }}
              alignItems="center"
              className="submit-button"
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmitData}
            >
              Apply
            </Button>
          </Grid>
          {post && (
            <AddDocument
              title="Add Supporting Documents"
              isOpen={showingAddDocumentModal}
              onClose={onCloseAddDocument}
              uid={authUser && authUser.unique_id}
              collection={`/DonationRequest/${post._id}/Responses`}
              onSavingDocument={onSavingDocument}
              canEdit={true}
            />
          )}
        </Grid>
      )}
    </Dialog>
  );
};

export default ApplyPost;

const validateDonationRequest = (values) => {
  let errors = {};
  if (values.requestType === "cash") {
    if (!values.cashQuantity) {
      errors.cashQuantity = "Please enter the cash amount";
    }
  } else if (values.requestType === "kind") {
    if (!values.kindQuantity) {
      errors.kindQuantity = "Please enter the Qunatity";
    }
  } else if (values.requestType === "time") {
    if (!values.timeQuantity) {
      errors.timeQuantity = "Please enter the time";
    }
  }
  if (!values.title) {
    errors.title = "Please enter title";
  } else if (values.title && values.title.length > 150) {
    errors.title = "Title cannot be more than 150 characters";
  }
  if (
    values.requestType !== "internship" &&
    values.requestType !== "volunteering" &&
    !values.brief
  ) {
    errors.brief = "Please enter Brief";
  }
  if (values.requestType === "cash" && !values.whyYouNeedHelp) {
    errors.whyYouNeedHelp = "Please enter Why you need help";
  }
  if (
    values &&
    values.fundsRecipient &&
    values.fundsRecipient.toLowerCase() === "self"
  ) {
    if (values.fundsRecipientName === "") {
      errors.fundsRecipientName = "Please enter Funds Recipient Name";
    }
  }
  if (values.requestType !== "career" && !values.city) {
    errors.city = "Please enter City";
  }
  return errors;
};

export default validateDonationRequest;

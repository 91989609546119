import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PublicIcon from "@material-ui/icons/Public";
import AddPhotos from "../common/AddPhotos";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddVideo from "../common/AddVideo";
import NonModalVideoPlayer from "../common/NonModalVideoPlayer";
import LocationOn from "@material-ui/icons/LocationOn";
import BusinessIcon from "@material-ui/icons/Business";
import Divider from "@material-ui/core/Divider";
import { checkUrlWithHttp } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const OrgDetailsSideBar = (props) => {
  const classes = useStyles();
  const { orgData, images, videos } = props;
  const [showPhotos, setShowPhotos] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} className="introBlk">
        <div>
          <PublicIcon className="secMainTitleImg" />
          <h3 className="secMainTitle">Intro</h3>
        </div>
        <div className="textBlk">
          {orgData && (
            <p>
              <BusinessIcon style={{ color: "#8C939C", marginRight: 10 }} />{" "}
              {orgData && orgData[0] && orgData[0].orgType}
            </p>
          )}
          <p>
            <LocationOn style={{ color: "#8C939C", marginRight: 10 }} />{" "}
            {orgData &&
              orgData[0] &&
              orgData[0].orgAddress &&
              orgData[0].orgAddress.city}
          </p>
          <p>
            {orgData && orgData[0] && orgData[0].hasCsrWing ? (
              <span>{orgData[0] && orgData[0].orgName} has CSR Wing</span>
            ) : (
              <span>
                {orgData[0] && orgData[0].orgName} does not have CSR Wing{" "}
              </span>
            )}
          </p>
          <p>
            {orgData && orgData[0] && orgData[0].websiteUrl && (
              <a
                href={checkUrlWithHttp(orgData[0].websiteUrl)}
                target="_blank"
                style={{
                  color: "#007bff",
                }}
              >
                {checkUrlWithHttp(orgData[0].websiteUrl)}
              </a>
            )}
          </p>
        </div>
        <div className="mediaBlk">
          <h3 style={{ font: "600 16px Roboto" }}>
            <span
              style={{
                display: "inline-block",
                width: "80%",
              }}
            >
              Photos{" "}
              {props && props.isAdmin && (
                <AddCircleIcon
                  onClick={() => setShowPhotos(true)}
                  style={{
                    cursor: "pointer",
                    color: "#000",
                    paddingBottom: "5px",
                  }}
                />
              )}
            </span>
            <span onClick={props.viewAllPhotos} className="public-view-all">
              View All
            </span>
          </h3>
          <Divider className="mediaHeadDivide" />
          <ul className="photosList">
            {images && images.length > 0 ? (
              images.slice(images.length - 4).map((image, index) => {
                return (
                  <li key={index}>
                    <img src={image.url} alt="" />
                  </li>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>No photos to show</p>
            )}
          </ul>
        </div>
        <div className="mediaBlk">
          <h3
            style={{
              font: "600 16px Roboto",
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "80%",
              }}
            >
              Videos
              {props && props.isAdmin && (
                <AddCircleIcon
                  onClick={() => setShowVideos(true)}
                  style={{
                    cursor: "pointer",
                    color: "#000",
                    fontSize: "20",
                    marginLeft: "8px",
                  }}
                />
              )}
            </span>
            <span onClick={props.viewAllVideos} className="public-view-all">
              View All
            </span>
          </h3>
          {videos && videos.length > 0 ? (
            <Fragment>
              <NonModalVideoPlayer
                name={videos[0].userGivenName}
                note={videos[0].note}
                url={videos[0].url}
                key={1}
              />
            </Fragment>
          ) : (
            <p style={{ textAlign: "left" }}>No videos to show</p>
          )}
        </div>
      </Grid>

      <AddPhotos
        title="Manage Images"
        isOpen={showPhotos}
        onClose={() => setShowPhotos(false)}
        uid={orgData && orgData[0] && orgData[0]._id}
        collection="Org"
        canEdit={props && props.isAdmin}
        profileRequired={false}
        onSavingPhoto={props.onSavingImage}
      />
      <AddVideo
        title="Add Videos"
        isOpen={showVideos}
        onClose={() => setShowVideos(false)}
        uid={orgData && orgData[0] && orgData[0]._id}
        collection="Org"
        onSavingDocument={props.onSavingVideo}
        canEdit={props && props.isAdmin}
      />
    </Grid>
  );
};

export default OrgDetailsSideBar;

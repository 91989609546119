import React, { useContext, useState } from "react";
import { appContext } from "../../App";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { login, resendOTP, withdrawRequest } from "../../utils/api";
import firebase from "../../firebase/firebase";
import toastr from "toastr";
import { withRouter } from "react-router";

function LoginVerify(props) {
  const { finalValues, donationRequestId } = props;
  const [state, setState] = useState({
    password: "",
  });

  const { authUser } = useContext(appContext);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };
  const loginHandler = () => {
    login({ account: authUser && authUser.email, password: state.password })
      .then((response) => {
        if (response.data.data.account_verified) {
          finalValues.password = state.password;
          withdrawRequest(donationRequestId, finalValues)
            .then((response) => {
              props.history.push(
                `/withdrawfunds/request-raised/${donationRequestId}`,
                {
                  finalValues: response.data,
                }
              );
            })
            .catch((error) => {
              toastr.error(error.message);
            });
          props.handleClose();
          firebase.analytics.logEvent("login", { method: "email" });
        }
      })
      .catch((error) => {
        // props.setSuccess(false);
        if (error.response) {
          if (error.response && error.response.status === 403) {
            const obj = {};
            obj.email = authUser.email;

            resendOTP(obj)
              .then(() => {
                toastr.error(
                  "Your email is not yet verified. Please complete the email verification."
                );
                props.history.push({
                  pathname: `/otp-validation/${state.account}`,
                  state: { password: state.password },
                });
              })
              .catch((error) => {
                toastr.error(error.message);
              });
          } else if (error.response.data.statusCode === 404) {
            toastr.error(error.response.data.message);
          } else {
            toastr.error(
              error.response.data.message ||
                "Unable to login. Please try again!"
            );
          }
        }
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Confirm your password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="account"
            label="Email Address"
            type="email"
            value={authUser && authUser.email}
            disabled
            fullWidth
          />
          <TextField
            margin="dense"
            name="password"
            label="Confirm Password"
            type="password"
            value={state.password}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={loginHandler} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withRouter(LoginVerify);

import React from "react";
import { Dialog } from "@material-ui/core";
import Spinner from "./Spinner";
import Fade from "@material-ui/core/Fade";

import "./styles.scss";

const Loader = (props) => {
  return (
    <Dialog
      className="loader-modal"
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
    >
      <Fade in={props.isOpen}>
        <Spinner />
      </Fade>
    </Dialog>
  );
};

export default Loader;

import React, { useRef } from "react";
import { Dialog, Button, Box, IconButton } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DonationReceipt from "./DonationReceipt";
import PrintIcon from '@material-ui/icons/Print';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrintReceiptModal = (props) => {
  const { receiptData } = props;
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
       <Box component="button"
          
          style={{
            width: "32px",
            padding:"5px",
            top:"5px",
            color: "var(--tal_primary)",
            position: "absolute",
            right: "5px",
            border:"none",
            borderRadius:"50px"
          }}
         
          onClick={props.onClose}
          aria-label="close"
        >
          <CloseIcon />
        </Box>

      <Box bgcolor="#283e4a" color="var(--tal_primary)" textAlign="center" paddingTop="50px">
      <Box component="Button" color="var(--tal_primary)" bgcolor="white" border="none" borderRadius="50px" marginBottom="20px"  width="40px" height="40px" onClick={handlePrint}><PrintIcon/></Box>
      <Box component="h2" color="white" fontSize="20px"> Donation Receipt </Box>
      </Box>
      <DonationReceipt ref={printRef} receiptData={receiptData} />
    </Dialog>
  );
};

export default PrintReceiptModal;

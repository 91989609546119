import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import "./TalLeaders.scss";
import {
  Button,
  Drawer,
  Grid,
  TablePagination,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import cssStyle from "./AdminTalLeaders.module.scss";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import TalLeaderProfile from "./TalLeaderProfile";
import { getUserData } from "../../utils/api";
import toastr from "toastr";
import ViewTALLeaders from "./ViewTALLeaders";
import Loader from "../common/Loader";
import { appContext } from "../../App";
import RejectorApproveTALLeaders from "./RejectorApproveTALLeaders";

const AdminNewLeaders = (props) => {
  const { talLeadersList, isLoading } = props;
  const [value, setValue] = React.useState("1");
  const [openView, setOpenView] = useState(false);
  const [usersData, setusersData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [userData, setUserData] = useState("");
  const [likeCommentValue, setLikeCommentValue] = useState(0);
  const [commentsData, setCommentsData] = useState([]);
  const [selectedRowComments, setSelectedRowComments] = useState("");
  const [rowId, setRowId] = useState("");
  const [status, setStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [drawerstate, setDrawerstate] = useState(false);
  const [id, setId] = useState("");
  const [edit, setEdit] = useState(false);

  const handleViewModal = (rowId, tabValue) => {
    setLikeCommentValue(tabValue);
    setOpenView(true);
    setRowId(rowId);
  };
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }

  const handleCloseViewModal = () => {
    setOpenView(false);
    setRowId("");
    setUserData("");
  };

  useEffect(() => {
    if (rowId) {
      handleUserData(rowId);
    }
  }, [rowId]);

  const handleUserData = (rowId) => {
    setIsLoaded(true);
    getUserData(rowId)
      .then((response) => {
        setIsLoaded(false);
        setUserData(response);
        setSelectedRowComments(response);
        setCommentsData(response.userComments);
      })
      .catch((error) => {
        setIsLoaded(false);
        toastr.error(error.response);
      });
  };

  const toggleDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setUserData("");
    setDrawerstate({ ...state, right: isopen });
  };
  const handleViewProfile = (state, isopen, rowId, editProfile) => {
    setDrawerstate({ ...state, right: isopen });
    setRowId(rowId);
    loadUserDataofTalLeaders(rowId);
    if (editProfile) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  };

  const loadUserDataofTalLeaders = (rowId) => {
    setIsLoaded(true);
    getUserData(rowId)
      .then((response) => {
        if (response) {
          setusersData(response);
          setIsLoaded(false);
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        toastr.error("Failed to get TALLeaders data");
      });
  };

  const handleActions = (status, id) => {
    setStatus(status);
    setId(id);
    if (status) {
      setOpenModal(true);
    }
  };

  const hanldeCloseDrawer = (state, isopen) => {
    setDrawerstate({ ...state, right: isopen });
    setusersData("");
  };

  return (
    <>
      <Container className={cssStyle.main_tab}>
        <TabContext value={value}>
          <TabPanel value="1" style={{ padding: "24px 0px" }}>
            <Grid container className={cssStyle.mid_row}>
              <Grid item xs={3}>
                <p className="le_text_algin">Name</p>
              </Grid>
              <Grid item xs={3}>
                <p>Areas of Interest</p>
              </Grid>
              <Grid item xs={2}>
                <p>Profile</p>
              </Grid>
              <Grid item xs={2}>
                <p>Leaders Feedback</p>
              </Grid>
              <Grid item xs={2}>
                <p>Action</p>
              </Grid>
            </Grid>
            {talLeadersList &&
              talLeadersList.length > 0 &&
              talLeadersList
                .slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                .map((row, index) => {
                  return (
                    <span key={row._id}>
                      <Grid container className={cssStyle.name_align}>
                        <Grid item xs={3} className={cssStyle.img_align}>
                          <div>
                            {row && row.image_url !== "image_url" ? (
                              <img
                                src={
                                  row && row.image_url !== ""
                                    ? row.image_url
                                    : "../images/tal-leader-icon.svg"
                                }
                                className="image_style"
                              />
                            ) : (
                              <img
                                src="../images/tal-leader-icon.svg"
                                className="image_style"
                              />
                            )}
                          </div>
                          <div
                            className={cssStyle.name_txt}
                            style={{ wordBreak: "break-all" }}
                          >
                            <span className={cssStyle.name_title}>
                              {row && row.name.first_name}{" "}
                              {row && row.name.last_name}
                            </span>
                            <p>{row && row.currentRole}</p>
                            <p>
                              <strong>
                                {" "}
                                {row && row.currentCompanyName && (
                                  <>
                                    <span style={{ fontWeight: "normal" }}>
                                      At{" "}
                                    </span>{" "}
                                    {row.currentCompanyName}
                                  </>
                                )}
                              </strong>
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={3} className={cssStyle.name_pro_box}>
                          <Grid item xs={12}>
                            {row.areasOfInterest.map((area, i) => (
                              <Button
                                key={i}
                                className="tabs-chips"
                                style={{
                                  cursor: "none",
                                  textTransform: "initial",
                                }}
                              >
                                {area}
                              </Button>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={2} className={cssStyle.Veiw_Profile}>
                          <Button
                            onClick={() =>
                              handleViewProfile("right", true, row._id, "")
                            }
                          >
                            View Application
                          </Button>
                          <Drawer
                            anchor="right"
                            open={drawerstate["right"]}
                            onClose={() => hanldeCloseDrawer("right", false)}
                            className="Veiw_Profile_radius"
                          >
                            {!isLoaded && usersData && (
                              <TalLeaderProfile
                                usersData={usersData}
                                setUserData={setusersData}
                                edit={edit}
                                setEdit={() => setEdit(false)}
                                setTalLeadersList={props.setTalLeadersList}
                                toggleDrawer={() => setDrawerstate(false)}
                                showRejectAndAcceptButtons={true}
                              />
                            )}
                          </Drawer>
                        </Grid>
                        <Grid item xs={2} className={cssStyle.View_icon}>
                          <Button
                            style={{ marginLeft: "25px" }}
                            onClick={() => handleViewModal(row._id, 0)}
                          >
                            {" "}
                            <p>
                              {" "}
                              <VisibilityOutlinedIcon />
                              View
                            </p>
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <div className={cssStyle.icon_btns}>
                            <div>
                              <BootstrapTooltip title="Approve">
                                <CheckCircleOutlineIcon
                                  className={cssStyle.icon_btns12}
                                  onClick={(e) => handleActions(1, row._id)}
                                  style={{
                                    color: "green",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </BootstrapTooltip>
                            </div>
                            <div className={cssStyle.Cancel_icon}>
                              <BootstrapTooltip title="Reject">
                                <CancelOutlinedIcon
                                  onClick={(e) => handleActions(-1, row._id)}
                                  style={{ color: "red", cursor: "pointer " }}
                                />
                              </BootstrapTooltip>
                            </div>
                            <BootstrapTooltip title="Edit">
                              <div
                                className={cssStyle.Edit_icon}
                                onClick={() =>
                                  handleViewProfile(
                                    "right",
                                    true,
                                    row._id,
                                    "edit"
                                  )
                                }
                              >
                                <img src="../images/edit-profile.svg" />{" "}
                              </div>
                            </BootstrapTooltip>
                          </div>
                        </Grid>
                      </Grid>
                    </span>
                  );
                })}
            {!isLoading && talLeadersList && talLeadersList.length === 0 && (
              <p className={cssStyle.No_leaders}>
                Uh-oh! Currently, there are no new leaders. Please come back
                later.
              </p>
            )}
          </TabPanel>

          <TabPanel value="2">All Leaders</TabPanel>
        </TabContext>{" "}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.totalCountOfRecords}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={props.handleChangePage}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
        />
        <ViewTALLeaders
          openFeedbackModal={openView}
          onCloseModal={handleCloseViewModal}
          userData={userData}
          value={likeCommentValue}
          setValue={setLikeCommentValue}
          setUserData={setUserData}
          selectedRowComments={selectedRowComments}
          setSelectedRowComments={setSelectedRowComments}
          commentsData={commentsData}
          setCommentsData={setCommentsData}
        />{" "}
        <RejectorApproveTALLeaders
          id={id}
          status={status}
          openModal={openModal}
          onCloseModal={() => setOpenModal(false)}
          setTalLeadersList={props.setTalLeadersList}
          TotalCountOfRecords={props.setTotalCountOfRecords}
        />
      </Container>

      <Loader isOpen={isLoaded} onClose={() => setIsLoaded(false)} />
    </>
  );
};

export default withRouter(AdminNewLeaders);

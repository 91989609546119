const validateLogin = (values) => {
  let errors = {};
  if (!values.account) {
    errors.account = "@touchalife.org email is required";
  } else if (!values.account.match(/^[A-Za-z0-9._\-]{1,}@(touchalife.org)$/)) {
    errors.account =
      "Please enter a valid @touchalife.org email address. (Eg: xyz@touchalife.org)";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password should contain at least 6 characters";
  }
  return errors;
};

export default validateLogin;

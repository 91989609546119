import React, { useEffect, useContext } from "react";
import Loader from "../common/Loader";
import jwt_decode from "jwt-decode";
import { Grid } from "@material-ui/core";
import { appContext } from "../../App";
import toastr from "toastr";
import { updateUserUsingToken } from "../../utils/api";
import Container from "@material-ui/core/Container";

const InviteUser = (props) => {
  const {
    setInviteGeneralUserPayload,
    setGeneralUnRegisteredUser,
    authUser,
  } = useContext(appContext);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    const decoded = jwt_decode(token);

    setInviteGeneralUserPayload(decoded);
    if (decoded && decoded._id) {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const bodyParameters = decoded;
      bodyParameters.userId = decoded.user;

      updateUserUsingToken(
        decoded._id,
        {
          roles: bodyParameters.role,
          languages: bodyParameters.languages,
          phone: bodyParameters.phone,
        },
        config
      )
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            if (authUser !== null && authUser.unique_id === decoded.user) {
              props.history.push("/");
            } else {
              props.history.push("/login");
            }
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          if (authUser !== null && authUser.unique_id === decoded.user) {
            props.history.push("/");
          } else {
            props.history.push("/login");
          }
        });
    } else {
      toastr.warning(
        "Please complete the signup process to become the member."
      );
      props.history.push("/signup");
      setGeneralUnRegisteredUser(true);
    }
  }, [authUser]);

  return (
    <div className="myDonations-container">
      <Container maxWidth="lg">
        <Grid container>
          <Grid xs={9}>
            <Loader isOpen={true} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default InviteUser;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UploadFileContactsModal from "./UploadFileContactsModal";

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    background: "#e7faf0",
    border: "1px solid #238c2e",
    margin: 10,
    padding: 5,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 8,
  },
});

export default function FileCard(props) {
  const [openCSVModal, setOpenCSVModal] = useState(false);
  // const [data, setData] = useState([]);
  const classes = useStyles();

  const handleDelete = () => {
    props.deleteFile(props.file.path);
  };

  const handleShowData = () => {
    console.log("FC data", props.data[props.file.path]);
    setOpenCSVModal(true);
  };
  const handleCSVModalClose = () => {
    setOpenCSVModal(false);
  };

  const contactsSaveHandler = () => {
    setOpenCSVModal(false);
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body2" component="span">
          <DescriptionIcon />
          {props.file.path}
          <CheckCircleIcon style={{ color: "green", float: "right" }} />
        </Typography>
      </CardContent>
      <CardActions style={{ float: "right" }}>
        <Button
          size="small"
          style={{ color: "red", textTransform: "capitalize" }}
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          size="small"
          style={{ color: "blue", textTransform: "capitalize" }}
          onClick={handleShowData}
        >
          Show Data
        </Button>
      </CardActions>
      {props.data[props.file.path] && (
        <UploadFileContactsModal
          openModal={openCSVModal}
          onModalClose={handleCSVModalClose}
          onContactsSave={contactsSaveHandler}
          data={props.data[props.file.path]}
          name={props.file.name}
        ></UploadFileContactsModal>
      )}
    </Card>
  );
}

import React, { useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import "./App.css";
import axios from "axios";
import { appContext } from "../../../App";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export default function App(props) {
  const { donationDetails, paymentRegion } = useContext(appContext);
  return (
    <div className="App payment-stripe-tal">
       <div>
      {/* <div className="sr-root"> */}
        <div className="sr-main">
         
          <Elements
            stripe={paymentRegion && loadStripe(paymentRegion.stripePk)}
          >
            <CheckoutForm amountDetails={donationDetails} />
          </Elements>
        </div>
      </div>
    </div>
  );
}

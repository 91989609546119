import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import RadioButton from "@material-ui/core/Radio";
import "./Volunteerings.scss";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ShortAddress from "../common/ShortAddress";
import { withStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createDonationRequest,
  getOrganizationList,
  updateDonationRequest,
} from "../../utils/api";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import AddPhotos from "../common/AddPhotos";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  FormGroup,
  Checkbox,
  Switch,
  Chip,
  OutlinedInput,
  MuiThemeProvider,
  Tooltip,
  createMuiTheme,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  faRupeeSign,
  faDollarSign,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    width: "100%",
    padding: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  field: {
    margin: "auto",
    marginRight: "125",
  },
}));
const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(skill, skillSet, theme) {
  return {
    fontWeight:
      skillSet.indexOf(skill) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function getSteps() {
  return ["Volunteering Request", "Image"];
}
const Volunteering = (props) => {
  const { authUser, setAuthUser, region, regions, setRegion, orgList } =
    useContext(appContext);
  const theme = useTheme();
  const initialState = {
    cashQuantity: "",
    title: "",
    description: "",
    requestType: "regular",
    cashUnit: "USD",
    status: "-2",
    fundsRecipient: "",
    profile: "",
    numberOfOpenings: "",
    startDate: moment(),
    endDate: moment(),
    duration: "",
    monthlyStipend: "",
    educationalQualification: "",
    defaultImageUrl: "",
  };
  const [closeSideMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [interviewDeclaration, setInterviewDeclaration] = useState(false);
  const [neighboringCities, setNeighboringCities] = useState(false);
  const [trainingMode, setTrainingMode] = useState("");
  const classes = useStyles();

  const [partTime, setPartTime] = React.useState(false);
  const [preplacement, setPreplacement] = React.useState(false);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  const [selectedPerks, setSelectedPerks] = useState([]);
  const [profile, setProfile] = useState("");

  const [description, setDescription] = useState("");
  const [volunteerResponsibilities, setVolunteerResponsibilities] =
    useState("");
  const [volunteerRequirements, setVolunteerRequirements] = useState("");

  const [skillSet, setSkillSet] = React.useState([]);
  const [perks, setPerks] = React.useState({
    Certificate: false,
    "Flexible Work Hours": false,
    "Informal Dress Code": false,
    "Letter Of Recommendation": false,
    "5 days a week": false,
    "Free Snacks and Beverages": false,
  });
  const [id, setId] = useState("");
  const [step, setStep] = useState(0);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [imageUpload, setImageUpload] = useState(false);

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
    saveDonationRequest(url);
  };
  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
      status: 0,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
        toastr.success(
          "Default profile Image is set for this volunteering request."
        );
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the volunteering request. " +
            error.message
        );
      });
  };
  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };
  const handleChangePerks = (event) => {
    setPerks({
      ...perks,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      setSelectedPerks([...selectedPerks, event.target.name]);
    } else {
      setSelectedPerks(
        selectedPerks.filter((perk) => perk !== event.target.name)
      );
    }
  };

  const handleChangeTrainingMode = (event) => {
    setTrainingMode(event.target.value);
  };
  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
  };

  const {
    certificate,
    flexibleWorkHours,
    informalDressCode,
    letterOfRecommendation,
    fivedaysaweek,
    freeSnacksandBeverages,
  } = perks;
  const profiles = [
    "Business Development (Sales)",
    "Web Development",
    "Graphic Design",
    "Content Writing",
    "Social Media Marketing",
    "Marketing",
    "Operations",
    "Mobile App Development",
    "Digital Marketing",
    "Human Resources (HR)",
    "Law/Legal",
    "Campus Ambassador",
    "Other",
  ];
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handlePartTime = (event) => {
    setPartTime(event.target.checked);
  };

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const changeRequirementsHandler = (e, editor) => {
    setVolunteerRequirements(editor.getData());
  };

  const changeResponsibilitiesHandler = (e, editor) => {
    setVolunteerResponsibilities(editor.getData());
  };

  const handlePrePlacement = (event) => {
    setPreplacement(event.target.checked);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  const handleChangeProfile = (event) => {
    setProfile(event.target.value);
  };

  const handleCreatePost = () => {
    const finalValues = {};
    const errors = {};
    if (activeStep === 0) {
      if (values.fundsRecipient.length === 0) {
        errors.fundsRecipient = "Please Select or Add an Organization";
      }
      if (values.profile.length === 0) {
        errors.selectProfile = "Please Select Profile";
      }

      if (description.length === 0) {
        errors.description = "Please enter Description";
      } else if (description && description.length <= 10) {
        errors.description = "Please enter atleast 10 characters";
      }
      if (
        tempAddress === null ||
        tempAddress.city === null ||
        tempAddress.state === null ||
        tempAddress.country === null
      ) {
        errors.address = "Please Select your Location";
      }

      if (startDate === null) {
        errors.startDate = "Please Select Start Date";
      }

      if (dueDate === null) {
        errors.dueDate = "Please Select Due Date";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
        finalValues.request_type = "volunteering";
        if (values.profile === "Other") {
          finalValues.title = profile;
        } else {
          finalValues.title = values.profile;
        }
        finalValues.brief = "";
        finalValues.start_date = startDate.getTime();
        finalValues.creatorType = "volunteering organization";
        finalValues.due_date = dueDate.getTime();
        finalValues.skills = skillSet;
        finalValues.units = values.cashUnit;
        if (values.fundsRecipient === "self") {
          finalValues.orgId = "Self";
        } else {
          finalValues.orgId = values.fundsRecipient;
        }
        finalValues.user_id = authUser.unique_id;
        finalValues.fundsRecipient = values.fundsRecipient;
        // finalValues.whyYouNeedHelp = "justifyToOthers";
        finalValues.description = description;
        finalValues.region = region._id;
        finalValues.city = tempAddress.city;
        finalValues.state = tempAddress.state;
        finalValues.country = tempAddress.country;
        finalValues.quantity = "0";
        finalValues.status = -2;

        const additionalInfo = {
          trainingMode: trainingMode,
          onlineInterviewAvailable: interviewDeclaration,
          partTimeAllowed: partTime,
          numberOfOpenings: values.numberOfOpenings,
          duration: values.duration,
          responsibilities: volunteerResponsibilities,
          requirements: volunteerRequirements,
          monthlyStipend: values.monthlyStipend,
          monthlyStipendUnits: values.cashUnit,
          perks: selectedPerks,
          placementOffer: preplacement,
          educationalQualification: values.educationalQualification,
        };
        finalValues.additionalInfo = additionalInfo;

        createDonationRequest(finalValues)
          .then((response) => {
            if (response.statusCode === 200) {
              setId(response.data._id);
              setActiveStep(1);
              setName(response.data.name);
              toastr.success(
                "Your Volunteer opportunity is successfully created. Please add images and click Save."
              );
              setErrors({});
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              setErrors((currentValues) => {
                return {
                  ...currentValues,
                  title:
                    "This title is already taken. Please change the title and try again.",
                };
              });
            } else {
              toastr.error(
                error.response &&
                  error.response.data &&
                  error.response.data.message
              );
            }
            setActiveStep(0);
          });
      }
    }
    if (activeStep === 1) {
      if (!finalValues.defaultImageUrl) {
        errors.image = "Please upload at least one image";
      }
      if (imageUpload) {
        props.history.push(`/donationRequest/${name}`);
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
    }
  };
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleNext);
  const handleNext = () => {
    saveDonationRequest();
  };
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };
  const handleInterviewDeclaration = (event) => {
    if (event.target.checked) {
      setInterviewDeclaration(true);
    } else {
      setInterviewDeclaration(false);
    }
  };

  const handleNeighboringCities = (event) => {
    if (event.target.checked) {
      setNeighboringCities(true);
    } else {
      setNeighboringCities(false);
    }
  };
  const [openOrgModal, setOpenOrgModal] = useState(false);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <SideMenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/volunteering.png"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "20px",
                      borderRadius: "100%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Volunteering Opportunity
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Bridging the gap between those who are in need with those
                    who are able to donate their time and efforts
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid className={classes.root}>
              <Stepper
                activeStep={activeStep}
                style={{ background: "transparent" }}
                alternativeLabel
              >
                {getSteps().map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div className="add-cover-pic">
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteering Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.fundsRecipient}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            style={{ padding: "8px 35px" }}
                          >
                            Select/Add Organization
                          </InputLabel>
                          <Select
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={values.fundsRecipient}
                            onChange={changeHandler}
                            variant="outlined"
                            placeholder="Organization"
                            // className={`input-field request-amount ${
                            //   errors && errors.fundsRecipient
                            //     ? "bg-red"
                            //     : values.fundsRecipient
                            //     ? "bg-green"
                            //     : "bg-normal"
                            // }`}
                            name="fundsRecipient"
                            label="Select/Add Organization"
                          >
                            <MenuItem value={"self"}>Self</MenuItem>
                            {orgList &&
                              orgList.length > 0 &&
                              orgList.map((org) => {
                                return (
                                  <MenuItem
                                    value={org && org.orgId && org.orgId._id}
                                  >
                                    <img
                                      src={
                                        org &&
                                        org.orgId &&
                                        org.orgId.defaultImageUrl
                                          ? org.orgId.defaultImageUrl
                                          : "/images/orgLogo.png"
                                      }
                                      alt="orgLogo"
                                      width="20px"
                                      style={{ marginRight: 8 }}
                                    />{" "}
                                    {org && org.orgId && org.orgId.orgName}{" "}
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "100%",
                                        textAlign: "end",
                                      }}
                                    >
                                      {org &&
                                      org.orgId &&
                                      org.orgId.status === 1 ? (
                                        <CheckCircleOutlineIcon
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <ErrorOutlineIcon
                                          style={{ color: "red" }}
                                        />
                                      )}
                                    </span>
                                  </MenuItem>
                                );
                              })}
                            <MenuItem value="add" onClick={handleOrganization}>
                              Add Organization
                            </MenuItem>
                          </Select>{" "}
                          <FormHelperText>
                            {errors.fundsRecipient}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          error={errors && errors.selectProfile}
                        >
                          <InputLabel
                            style={{ padding: "10px 30px" }}
                            id="demo-simple-select-outlined-label"
                          >
                            Select Profile
                          </InputLabel>
                          <Select
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={values.profile}
                            onChange={changeHandler}
                            variant="outlined"
                            name="profile"
                            label="Select Profile"
                          >
                            <MenuItem value={"select profile"}>
                              Select Profile
                            </MenuItem>
                            {profiles.map((profile) => {
                              return (
                                <MenuItem value={profile}>{profile}</MenuItem>
                              );
                            })}
                          </Select>
                          {values.profile === "Other" && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              style={{
                                paddingTop: "30px",
                                paddingLeft: "1px",
                                fontStyle: "bold",
                                fontWeight: "400",
                                fontSize: "15px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="profile"
                              placeholder="Enter Profile"
                              value={profile}
                              onChange={handleChangeProfile}
                            />
                          )}
                          <FormHelperText>
                            {errors.selectProfile}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Description for Volunteering
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          // className="recipient"
                          error={errors && errors.description}
                        >
                          <CKEditor
                            style={{ padding: "10px 20px" }}
                            data={description}
                            name="description"
                            editor={ClassicEditor}
                            onChange={changeDescriptionHandler}
                            config={{
                              toolbar: [
                                "Heading",
                                "bold",
                                "italic",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                                "Link",
                              ],
                              placeholder:
                                "Give description about this Volunteer opportunity",
                            }}
                          />

                          <FormHelperText>{errors.description}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                        >
                          <Typography
                            component="p"
                            style={{
                              fontSize: "15px",
                              color: "#263238",

                              textAlign: "start",
                              fontWeight: "500",
                            }}
                            gutterBottom
                          >
                            Volunteering Type
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} display="flex">
                        <FormControl
                          style={{ width: "100%", paddingLeft: "10px" }}
                          error={errors && errors.trainingMode}
                        >
                          <RadioGroup
                            column
                            value={trainingMode}
                            onChange={handleChangeTrainingMode}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="offline"
                              control={<Radio />}
                              label="Regular (In-office/On-field)"
                            />

                            <FormControlLabel
                              value="online"
                              control={<Radio />}
                              label="Remote/WFH (Recommended due to COVID-19 situation)"
                            />
                          </RadioGroup>
                          <FormHelperText>{errors.trainingMode}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          component="fieldset"
                          error={errors && errors.interviewDeclare}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <>
                                  <Checkbox
                                    checked={interviewDeclaration}
                                    onChange={handleInterviewDeclaration}
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    style={{
                                      marginTop: "0px",
                                      paddingTop: "0px",
                                    }}
                                  />
                                  <span
                                    className="checkaglin"
                                    variant="body1"
                                    gutterBottom
                                  >
                                    I am willing to talk to the potential
                                    volunteers over phone or through online
                                    meeting instead of a physical meeting before
                                    the volunteering work starts
                                  </span>
                                </>
                              }
                            />
                          </FormGroup>
                          <FormHelperText>
                            {errors.interviewDeclare}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          // className=" recipient"
                          error={errors && errors.address}
                        >
                          <p>Select your location</p>
                          <ShortAddress
                            value={address}
                            onChange={handleChangeAddress}
                          />
                          <FormHelperText>{errors.address}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ padding: "10px" }}
                          component="fieldset"
                          error={errors && errors.partTime}
                        >
                          <FormGroup>
                            <FormControlLabel
                              className="labelpad"
                              label="Is part-time allowed?"
                              labelPlacement="start"
                              control={
                                <>
                                  <Switch
                                    checked={partTime}
                                    onChange={handlePartTime}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </>
                              }
                            />
                          </FormGroup>
                          <FormHelperText>{errors.partTime}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          error={errors && errors.numberOfOpenings}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            style={{
                              paddingLeft: "1px",
                              fontStyle: "bold",
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "#000000",
                              width: "100%",
                            }}
                            name="numberOfOpenings"
                            label="Number of volunteers"
                            placeholder="Enter Number of volunteers"
                            value={values.numberOfOpenings}
                            onChange={changeHandler}
                          />
                          <FormHelperText>
                            {errors.numberOfOpenings}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteering start date
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.startDate}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              showTodayButton
                              format="dd-MMM-yyyy"
                              style={{ width: "60%" }}
                              margin="normal"
                              className={`input-field dob`}
                              // ${
                              // //   extraErrors && extraErrors.dueDate
                              // //     ? "bg-red"
                              // //     : dueDate
                              // //     ? "bg-green"
                              // //     : "bg-normal"
                              // // }`}
                              inputVariant="outlined"
                              label="Start Date"
                              name="startDate"
                              value={startDate}
                              onChange={handleStartDateChange}
                              disablePast
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <FormHelperText>{errors.startDate}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteering duration (Days)
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "17px",
                            }}
                            error={errors && errors.duration}
                          >
                            <TextField
                              fullWidth
                              type="number"
                              variant="outlined"
                              style={{
                                fontWeight: "400",
                                fontSize: "15px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="duration"
                              label="Duration"
                              placeholder="Enter duration"
                              value={values.duration}
                              onChange={changeHandler}
                            />
                            <FormHelperText>{errors.duration}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Volunteer responsibilities
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            padding: "10px",
                            paddingLeft: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.responsibilities}
                        >
                          <CKEditor
                            style={{ padding: "10px 20px" }}
                            maxLength={300}
                            data={volunteerResponsibilities}
                            editor={ClassicEditor}
                            onChange={changeResponsibilitiesHandler}
                            config={{
                              toolbar: [
                                "Heading",
                                "bold",
                                "italic",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                                "Link",
                              ],
                              placeholder:
                                "Selected volunteer's day-to-day responsibilities include:",
                            }}
                          />

                          <FormHelperText>
                            {errors.responsibilities}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#263238",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Application last date
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "60%",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.dueDate}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              showTodayButton
                              format="dd-MMM-yyyy"
                              style={{ width: "100%" }}
                              margin="normal"
                              className={`input-field dob`}
                              // ${
                              // //   extraErrors && extraErrors.dueDate
                              // //     ? "bg-red"
                              // //     : dueDate
                              // //     ? "bg-green"
                              // //     : "bg-normal"
                              // // }`}
                              inputVariant="outlined"
                              label="End Date"
                              name="endDate"
                              value={dueDate}
                              onChange={handleDueDateChange}
                              disablePast
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {errors && errors.dueDate && (
                            <FormHelperText>{errors.dueDate}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <hr style={{ width: "98%" }}></hr>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Volunteering Requirements
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Who can apply
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            padding: "10px",
                            paddingLeft: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.requirements}
                        >
                          <CKEditor
                            style={{ padding: "10px 20px" }}
                            maxLength={300}
                            data={volunteerRequirements}
                            editor={ClassicEditor}
                            onChange={changeRequirementsHandler}
                            config={{
                              toolbar: [
                                "Heading",
                                "bold",
                                "italic",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                                "Link",
                              ],
                              placeholder:
                                "Please describe who can apply for this volunteering opportunity",
                            }}
                          />

                          <FormHelperText>{errors.requirements}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Skills
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.skillset}
                        >
                          <Autocomplete
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={[]}
                            value={skillSet}
                            onChange={(e, newval) => {
                              setSkillSet(newval);
                            }}
                            defaultValue={[]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Skills"
                                placeholder="Enter skills"
                              />
                            )}
                          />
                          <FormHelperText>{errors.skillset}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <hr style={{ width: "96%" }}></hr>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Volunteering Benefits
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Stipend
                        </Typography>
                      </Grid>

                      <Grid container spacing={2} style={{ marginLeft: "0px" }}>
                        <Grid
                          item
                          sm={2}
                          xs={12}
                          style={{
                            marginTop: "8px",

                            // paddingTop: "15px",
                            // paddingLeft: "10px",
                            // paddingBottom:"0px",
                          }}
                        >
                          <FormControl style={{ width: "100%" }}>
                            <Select
                              variant="outlined"
                              style={{
                                paddingTop: "2px",
                                paddingLeft: "5px",
                                paddingBottom: "0px",
                                height: "55px",
                              }}
                              name="cashUnit"
                              value={region && region.currency}
                              className="currency"
                              onChange={currencyChangeHandler}
                            >
                              {regions &&
                                regions.length > 0 &&
                                regions.map((region) => {
                                  return (
                                    <MenuItem value={region.currency}>
                                      {region.currency === "INR" ? (
                                        <FontAwesomeIcon
                                          color="#059585"
                                          size="2x"
                                          icon={faRupeeSign}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          color="#059585"
                                          size="2x"
                                          icon={faDollarSign}
                                        />
                                      )}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl
                            style={{ width: "100%", paddingTop: "10px" }}
                            error={errors && errors.monthlyStipend}
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              style={{
                                fontWeight: "300",
                                fontSize: "20px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="monthlyStipend"
                              label="Monthly Stipend"
                              placeholder="Enter Monthly Stipend"
                              value={values.monthlyStipend}
                              onChange={changeHandler}
                            />
                            <FormHelperText>
                              {errors.monthlyStipend}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                          }}
                          gutterBottom
                        >
                          Perks
                        </Typography>
                        {/* <Typography
                    component="p"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                    }}
                    
                  >
                    {"Optional"}
                  </Typography> */}
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.perks}
                        >
                          <Box sx={{ display: "flex" }}>
                            <FormControl
                              style={{ paddingRight: "20px" }}
                              sx={{ m: 3 }}
                              component="fieldset"
                              variant="standard"
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={certificate}
                                      onChange={handleChangePerks}
                                      name="Certificate"
                                    />
                                  }
                                  label="Certificate"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={flexibleWorkHours}
                                      onChange={handleChangePerks}
                                      name="Flexible Work Hours"
                                    />
                                  }
                                  label="Flexible Work Hours"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={informalDressCode}
                                      onChange={handleChangePerks}
                                      name="Informal Dress Code"
                                    />
                                  }
                                  label="Informal Dress Code"
                                />
                              </FormGroup>
                            </FormControl>
                            <FormControl
                              style={{ paddingLeft: "-1px" }}
                              component="fieldset"
                              sx={{ m: 3 }}
                              variant="standard"
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={letterOfRecommendation}
                                      onChange={handleChangePerks}
                                      name="Letter Of Recommendation"
                                    />
                                  }
                                  label="Letter Of Recommendation"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={fivedaysaweek}
                                      onChange={handleChangePerks}
                                      name="5 days a week"
                                    />
                                  }
                                  label="5 days a week"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={freeSnacksandBeverages}
                                      onChange={handleChangePerks}
                                      name="Free Snacks and Beverages"
                                    />
                                  }
                                  label="Free Snacks and Beverages"
                                />
                              </FormGroup>
                            </FormControl>
                          </Box>
                          <FormHelperText>{errors.perks}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <hr style={{ width: "98%" }}></hr>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Education
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          error={errors && errors.educationalQualification}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            style={{
                              fontWeight: "400",
                              fontSize: "20px",
                              color: "#000000",
                              width: "100%",
                            }}
                            name="educationalQualification"
                            label="Enter Education Qualification"
                            placeholder="Enter Education Qualification"
                            value={values.educationalQualification}
                            onChange={changeHandler}
                            inputProps={{
                              maxLength: 20,
                            }}
                          />
                          <span
                            className="title-length"
                            style={{
                              top: 20,
                              padding: "0 15px",
                              background: "none",
                            }}
                          >
                            {" "}
                            {values.educationalQualification
                              ? values.educationalQualification.length
                              : 0}
                            /20
                          </span>
                          <FormHelperText>
                            {errors.educationalQualification}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {activeStep === 1 && (
                    <div className="request-step-two">
                      <div className="request-two-step">
                        <h3 className="step-two-title">
                          Add a cover photo or video
                        </h3>
                        <p className="image-desc">
                          This is the image that will feature right on top of
                          your page. Make it impactful!
                        </p>
                        <div className="fund-img-txt">
                          <p> A picture is worth a thousand words</p>
                          <ul>
                            <li>
                              <span>Keep it relevant</span>
                            </li>
                            <li>
                              <span>Keep it clear</span>
                            </li>
                            <li>
                              <span>Types: .PNG, .JPG, .BMP, .MP4</span>
                            </li>
                            <li>
                              <span>Size: 700px X 400px</span>
                            </li>
                          </ul>
                        </div>
                        <MuiThemeProvider
                          theme={theme2}
                          className="req-tooltip-img"
                        >
                          <Tooltip
                            classes="req-tooltip"
                            open={true}
                            title={
                              <div>
                                <h6 style={{ font: "400 12px Roboto" }}>
                                  A picture is worth a thousand words
                                </h6>
                                <p
                                  style={{
                                    margin: 3,
                                    font: "400 10px Roboto",
                                  }}
                                >
                                  <span className="tooltip-dots" />
                                  Keep it relevant
                                </p>
                                <p
                                  style={{
                                    margin: 3,
                                    font: "400 10px Roboto",
                                  }}
                                >
                                  <span className="tooltip-dots" />
                                  Keep it clear
                                </p>
                                <p
                                  style={{
                                    margin: 3,
                                    font: "400 10px Roboto",
                                  }}
                                >
                                  <span className="tooltip-dots" />
                                  Types: .PNG, .JPG, .BMP, .MP4
                                </p>
                                <p
                                  style={{
                                    margin: 3,
                                    font: "400 10px Roboto",
                                  }}
                                >
                                  <span className="tooltip-dots" />
                                  Size: 700px X 400px
                                </p>
                              </div>
                            }
                            placement="right"
                            arrow={true}
                          >
                            <img
                              src={
                                defaultImageUrl
                                  ? defaultImageUrl
                                  : "/images/default-req-img.gif"
                              }
                              alt="default"
                              className="fund-cover-img"
                            />
                          </Tooltip>
                        </MuiThemeProvider>
                        {errors && errors.image && (
                          <div className="custom-error">{errors.image}</div>
                        )}
                        <div className="add-btn-fund">
                          <Button
                            style={{
                              font: "500 12px Roboto",
                              padding: "20px 0px",
                              margin: "10px 5px",
                              background: "transparent",
                              color: "#283E4A",
                              border: "1px solid #283E4A",
                            }}
                            onClick={() => setShowingAddPhotoModal(true)}
                          >
                            <FontAwesomeIcon
                              color="#283E4A"
                              size="1x"
                              style={{ marginRight: 6 }}
                              icon={faFileUpload}
                            />{" "}
                            Add Images
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <Grid>
                  <div>
                    <Button
                      style={{
                        background: "rgb(243, 90, 87)",
                        margin: "30px",
                        float: "right",
                        color: "white",
                        marginRight: "10px",
                        width: "120px",
                      }}
                      className="submit-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleCreatePost}
                    >
                      {activeStep === 0 ? "Create" : "Save"}
                    </Button>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
        multiple={true}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(Volunteering);

import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ContactModal from "./ContactModal";
import AddContactsModal from "./AddContactsModal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { appContext } from "../../App";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import "../Topbar/Topbar.scss";
import Menu from "@material-ui/core/Menu";
import Box from '@material-ui/core/Box';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listContainer: {
    overflowX: "auto",
    height: "auto",
    maxHeight: "200px",
    "& li": {
      borderBottom: "1px solid rgb(246, 247, 251)",
      "&:hover": {
        backgroundColor: "rgb(246, 247, 251)",
      },
    },
  },
}));

const Contacts = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { contacts } = useContext(appContext);

  const [openModal, setOpenModal] = useState(false);
  const [openCSVModal, setOpenCSVModal] = useState(false);
  const [multipleContacts, setMultipleContacts] = useState(false);
  const classes = useStyles();

  const [currentContact, setCurrentContact] = React.useState(null);
  const handleAddContacts = (operation) => {
    if (operation === "addcontact") {
      setMultipleContacts(false);
      setOpenModal(true);
    } else if (operation === "uploadcontacts") {
      setMultipleContacts(false);
      setOpenCSVModal(true);
    }
  };
  const handleEditContact = (contact) => {
    setOpenModal(true);
    setCurrentContact(contact);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setCurrentContact(null);
  };

  const handleCSVModalClose = () => {
    setOpenCSVModal(false);
  };

  const contactSaveHandler = () => {
    setOpenModal(false);
    //window.location.reload();
  };

  const contactsSaveHandler = () => {
    setOpenCSVModal(false);
    //window.location.reload();
  };

  return (
    <Box className="myOrganizations drop-box contact" mt-2>
      <div className="side-box">
      <p> My Contacts{" "}</p>

        <Button
          className="drop-box"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Tooltip title="Add Contact">
            <AddIcon onClick={() => setMultipleContacts(!multipleContacts)} />
          </Tooltip>
        </Button>
        {multipleContacts && (
          <Menu
            style={{ zIndex: "1" }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              value="addcontact"
              onClick={(e) => handleAddContacts("addcontact")}
            >
              Add Contact
            </MenuItem>
            <MenuItem
              value="uploadcontacts"
              onClick={(e) => handleAddContacts("uploadcontacts")}
            >
              Import Contacts
            </MenuItem>
          </Menu>
        )}
      </div>
      <div className={classes.listContainer}>
        {contacts.length ? (
          <List className={classes.root}>
            {contacts.map((contact) => {
              const labelId = `checkbox-list-label-${contact._id}`;
              return (
                <ListItem key={contact._id} role={undefined}>
                  <ListItemText
                    id={labelId}
                    primary={`${contact.firstName} ${contact.lastName}`}
                    secondary={contact.email || contact.phoneNumber}
                  />

                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <EditIcon
                        title="Edit Contact"
                        onClick={() => {
                          handleEditContact(contact);
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <div
            style={{
              fontSize: "12px",
              padding: "10px 0",
              textAlign: "center",
            }}
          >
            No Contacts
          </div>
        )}
      </div>
      <ContactModal
        currentContact={currentContact}
        openModal={openModal}
        onModalClose={handleModalClose}
        onContactSave={contactSaveHandler}
      ></ContactModal>
      <AddContactsModal
        openModal={openCSVModal}
        onModalClose={handleCSVModalClose}
        onContactsSave={contactsSaveHandler}
      ></AddContactsModal>
    </Box>
  );
};

export default Contacts;

import { validateUrl } from "../../utils/utils";

const ValidateProfile = (values) => {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = "Please enter First Name";
  }
  if (!values.lastName) {
    errors.lastName = "Please enter Last Name";
  }
  if (!values.userName) {
    errors.userName = "Please enter User Name";
  }
  if (values.summary && values.summary.trim().length >= 100) {
    errors.summary = "Please enter less than 100 characters.";
  }
  if (values.aboutMe && values.aboutMe.trim().length >= 1000) {
    errors.aboutMe = "Please enter less than 1000 characters.";
  }
  if (values.instagramProfileUrl && !validateUrl(values.instagramProfileUrl)) {
    errors.instagramProfileUrl = "Please enter valid Instagram URL.";
  }
  if (values.facebookProfileUrl && !validateUrl(values.facebookProfileUrl)) {
    errors.facebookProfileUrl = "Please enter valid Facebook URL";
  }
  if (values.twitterProfileUrl && !validateUrl(values.twitterProfileUrl)) {
    errors.twitterProfileUrl = "Please enter valid Twitter URL.";
  }
  if (values.linkedInProfileUrl && !validateUrl(values.linkedInProfileUrl)) {
    errors.linkedInProfileUrl = "Please enter valid LinkedIn URL";
  }

  return errors;
};

export default ValidateProfile;

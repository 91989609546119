import React, { useState, useEffect } from "react";
import moment from "moment";
import toastr from "toastr";
import Button from "@material-ui/core/Button";

import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  InputLabel,
  Switch,
  Box,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { DATE_TIME_FORMAT_FULL_PICKER } from "../../utils/utils";
import DateFnsUtils from "@date-io/date-fns";
import {
  getStreamings,
  saveStreaming,
  updateStreaming,
  validateScheduleDates,
  getCurrentTime,
} from "../../utils/api";
import Loader from "../common/Loader";
import ChannelSelectionBox from "./ChannelSelectBox";

export default function StreamingForm(props) {
  const {
    playlists,
    setIsDisplayStreamings,
    isEnableEdit,
    setIsEnableEdit,
    selectedId,
    rows,
    setRows,
  } = props;

  const [editorPick, setEditorPick] = useState(false);
  const handleEditorPick = () => {
    setEditorPick(!editorPick);
  };
  const [dateConflictError, setDateConflictError] = useState("");
  const [channelPlaylist, setChannelPlaylist] = useState([]);
  const [playlist, setPlaylist] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [channel, setChannel] = useState(props.channel);
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isOpenDate, setIsOpenDate] = useState(false);
  const [name, setName] = useState("");
  const [isEditDisabled, setIsEditDisabled] = useState(false);

  useEffect(() => {
    setChannelPlaylist(playlists.filter((item) => item.channel === channel));
  }, [playlists, channel]);

  function handleName(event) {
    setName(event.target.value);
  }
  function handleChangeChannel(event) {
    if (channel !== event.target.value) setPlaylist("");
    setChannel(event.target.value);
    setChannelPlaylist(
      playlists.filter((play) => play.channel === event.target.value)
    );
  }
  function handleChangePlaylist(event) {
    setPlaylist(event.target.value);
  }
  function handleChangeDateOK() {
    setIsOpenDate(false);
  }
  function handleChangeFromDateCancel() {
    setIsOpenDate(false);
    setFromDate(null);
  }
  function handleChangeEndDateCancel() {
    setIsOpenDate(false);
    setEndDate(null);
  }

  const handleChangeFromDate = (date) => {
    const roundedDate = moment(date.getTime()).startOf("minute").toDate();
    setFromDate(roundedDate);
    setDateConflictError("");
    let obj = {};
    obj.channel = channel;
    obj.startTime = roundedDate.getTime();
    obj.endTime = endDate && endDate.getTime();
    if (selectedId) {
      obj.streamingId = selectedId;
    }
    if (date && endDate) {
      validateScheduleDates(obj)
        .then(() => {})
        .catch((error) => {
          setDateConflictError(
            "The schedule of this streaming conflicts with one of the streamings already scheduled"
          );
        });
    }
  };

  const handleChangeEndDate = (date) => {
    const roundedDate = moment(date.getTime()).startOf("minute").toDate();
    setEndDate(roundedDate);
    setDateConflictError("");
    let obj = {};
    obj.channel = channel;
    obj.startTime = fromDate && fromDate.getTime();
    obj.endTime = roundedDate.getTime();
    if (selectedId) {
      obj.streamingId = selectedId;
    }
    if (fromDate && date) {
      validateScheduleDates(obj)
        .then(() => {})
        .catch(() => {
          setDateConflictError(
            "The schedule of this streaming conflicts with one of the streamings already scheduled"
          );
        });
    }
  };

  const handleBack = () => {
    setIsEnableEdit(false);
    setIsDisplayStreamings(true);
  };

  const loadStreaming = (id) => {
    setIsLoading(true);
    getStreamings(id)
      .then((response) => {
        if (response) {
          setName(response.data.title);
          setEditorPick(response.data.editorPick);
          setChannel(response.data.channel);
          setFromDate(new Date(response.data.startTime));
          setEndDate(new Date(response.data.endTime));
          setPlaylist(response.data.playlist);
          //
          getCurrentTime().then((currentTime) => {
            if (
              currentTime > response.data.startTime &&
              currentTime < response.data.endTime
            ) {
              toastr.error(
                "You can not update this Streaming because it is currently being played."
              );
              setIsEditDisabled(true);
            }
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Stream:" + error.message);
      });
  };

  useEffect(() => {
    if (selectedId && isEnableEdit) {
      loadStreaming(selectedId);
    }
  }, [selectedId, isEnableEdit]);

  const saveStreamingData = (finalValues) => {
    saveStreaming(finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          setRows([
            {
              id: resp.data._id,
              playlist: finalValues.playlist,
              title: finalValues.title,
              startTime: finalValues.startTime,
              endTime: finalValues.endTime,
              editorPick: editorPick,
              channel: channel,
            },
            ...rows,
          ]);
          toastr.success(resp.message);
        } else {
          toastr.error(resp.message);
        }
        setIsDisplayStreamings(true);
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error.message);
      });
  };

  const updateStreamingData = (finalValues) => {
    updateStreaming(selectedId, finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          const oldRows = rows.map((item) => {
            if (item.id === selectedId) {
              return {
                id: resp.data._id,
                playlist: finalValues.playlist,
                title: finalValues.title,
                startTime: finalValues.startTime,
                endTime: finalValues.endTime,
                editorPick: editorPick,
                channel: channel,
              };
            } else {
              return item;
            }
          });
          setRows(oldRows);
          toastr.success(resp.message);
        } else {
          toastr.error(resp.message);
        }
        setIsEnableEdit(false);
        setIsDisplayStreamings(true);
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error.response.data.message);
      });
  };

  const handleNext = async () => {
    const extraErrors = {};
    if (dateConflictError) extraErrors.dateConflictError = dateConflictError;
    if (name === "") {
      extraErrors.name = "Please enter name";
    }
    if (fromDate === null) {
      extraErrors.fDate = "Select From Date";
    }
    if (endDate === null) {
      extraErrors.eDate = "Select End Date";
    }

    if (playlist === "Select Playlist" || playlist === "") {
      extraErrors.playlist = "Please select playlist";
    }

    if (
      endDate &&
      endDate.getTime() - fromDate &&
      fromDate.getTime() < 300000
    ) {
      extraErrors.fDate =
        "Atleast a 5 min difference between start time and end time";
    }
    setExtraErrors(extraErrors);
    if (extraErrors && Object.keys(extraErrors).length !== 0) return;

    const finalValues = {};
    finalValues.channel = channel;
    finalValues.startTime = fromDate.getTime();
    finalValues.endTime = endDate.getTime();
    finalValues.playlist = playlist;
    finalValues.title = name;
    finalValues.editorPick = editorPick;
    setIsWaitingForResponse(true);
    if (isEnableEdit) {
      finalValues.selectedId = selectedId;
      updateStreamingData(finalValues);
    } else {
      saveStreamingData(finalValues);
    }
  };

  return (
    <Box>
      <Box className="drop-box schedule-streaming" p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box fontWeight="400" fontSize="20px" color="#ef6c00">
              {" "}
              Schedule Streaming{" "}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="right" display="flex" justifyContent="flex-end">
              <Box component="Typography" lineHeight="36px">
                Editors Pick
              </Box>

              <Switch
                checked={editorPick}
                onChange={handleEditorPick}
                name="editorPick"
                size="medium"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              variant="outlined"
              className="input-field"
              label="Title"
              placeholder="Give a title"
              name="name"
              value={name}
              error={
                extraErrors && extraErrors.name && extraErrors.name.length > 0
              }
              helperText={extraErrors.name}
              onChange={handleName}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Channel</InputLabel>
              <ChannelSelectionBox
                channel={channel}
                channels={props.channels}
                setChannel={setChannel}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              fullWidth
              error={
                (extraErrors &&
                  extraErrors.fDate &&
                  extraErrors.fDate.length > 0) ||
                dateConflictError.length > 0
              }
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  okLabel={<Button onClick={handleChangeDateOK}>OK</Button>}
                  cancelLabel={
                    <Button onClick={handleChangeFromDateCancel}>CANCEL</Button>
                  }
                  // style={{ width: "231px" }}
                  variant="dialog"
                  value={fromDate}
                  inputVariant="outlined"
                  label="Select Start Date"
                  format={DATE_TIME_FORMAT_FULL_PICKER}
                  placeholder={DATE_TIME_FORMAT_FULL_PICKER}
                  onChange={(newDate) => {
                    handleChangeFromDate(newDate);
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormHelperText>{extraErrors.fDate}</FormHelperText>
              <FormHelperText>{dateConflictError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              fullWidth
              error={
                extraErrors && extraErrors.eDate && extraErrors.eDate.length > 0
              }
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  okLabel={<Button onClick={handleChangeDateOK}>OK</Button>}
                  cancelLabel={
                    <Button onClick={handleChangeEndDateCancel}>CANCEL</Button>
                  }
                  // style={{ width: "231px" }}
                  variant="dialog"
                  value={endDate}
                  inputVariant="outlined"
                  label="Select End Date"
                  format={DATE_TIME_FORMAT_FULL_PICKER}
                  placeholder={DATE_TIME_FORMAT_FULL_PICKER}
                  onChange={(newDate) => {
                    handleChangeEndDate(newDate);
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormHelperText>{extraErrors.eDate}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              variant="outlined"
              fullWidth
              error={
                extraErrors &&
                extraErrors.playlist &&
                extraErrors.playlist.length > 0
              }
            >
              <InputLabel>Select Playlist</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                value={playlist}
                label="Playlist"
                onChange={handleChangePlaylist}
              >
                {channelPlaylist.map((value) => {
                  return (
                    <MenuItem key={value.id} value={value.id}>
                      {value.title} (
                      {value.duration &&
                        value.duration > 0 &&
                        new Date(value.duration * 1000)
                          .toISOString()
                          .substr(11, 8)}
                      )
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{extraErrors.playlist}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Box>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <span style={{ cursor: isEditDisabled ? "not-allowed" : "default" }}>
            <Button
              style={{
                backgroundColor: "var(--tal_primary)",
                marginLeft: "5px",
                color: "white",
              }}
              disabled={isEditDisabled}
              variant="contained"
              onClick={handleNext}
            >
              {isEnableEdit ? "Save" : "Schedule"}
            </Button>
          </span>
        </Box>
      </Box>
      <Loader
        isOpen={isWaitingForResponse}
        onClose={() => setIsWaitingForResponse(false)}
      />
    </Box>
  );
}

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import ChatRoom from "./ChatRoom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Sidemenu from "../SideMenu/Sidemenu";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { appContext } from "../../App";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ChatTabs(props) {
  const { authUser } = useContext(appContext);
  const [value, setValue] = useState(0);
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleAddChatRooms = () => {
    props.history.push("/createAudioRoom");
  };
  const classes = useStyles();

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <Box
        component="div"
        p={1}
        mt={-3}
        mb={2}
        zIndex="2"
        position="sticky"
        top="86px"
        className="drop-box"
      >
        <Container maxWidth="lg">
          <Box className={classes.livestreamings}>
            <Grid container>
              <Grid item xs={12} sm={12} md={9}>
                <Box display="flex" alignItems="center" gridGap={10}>
                  <Button
                    onClick={handleCloseSideMenu}
                    style={{
                      color: "rgb(243,90,87)",
                      border: "2px solid  rgb(243,90,87)",
                      borderRadius: "100%",
                      minWidth: "50px",
                      height: "50px",
                    }}
                  >
                    {!closeSideMenu && <MenuIcon />}
                    {closeSideMenu && <ArrowBackIcon />}
                  </Button>
                  <h2>Ongoing and Future Audio Rooms</h2>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Box className={classes.rightmenu} textAlign="right">
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "rgb(243,90,87)",
                    }}
                    variant="contained"
                    onClick={() => handleAddChatRooms()}
                  >
                    {" "}
                    + Create Audio Room{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box component="div" display="flex">
          {closeSideMenu && (
            <Box marginRight="10px">
              <Sidemenu />
            </Box>
          )}

          <Box component="div" width="100%" style={{ overflowX: "auto" }}>
            <Box
              component="section"
              borderBottom="1px solid #c1c1c1"
              bgcolor="white"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="All Rooms" {...a11yProps(0)} />
                {authUser && authUser.unique_id && (
                  <Tab label="My Rooms" {...a11yProps(1)} />
                )}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ChatRoom />
            </TabPanel>
            {authUser && authUser.unique_id && (
              <TabPanel value={value} index={1}>
                <ChatRoom id={authUser && authUser.unique_id} />
              </TabPanel>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
}

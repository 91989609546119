import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Typography, Tooltip, IconButton, Grid } from "@material-ui/core";
import firebase from "../../firebase/firebase";
import { isAudio, DATE_TIME_FORMAT } from "../../utils/utils";
import "./TalRadioPlayer.scss";
import ShareTalRadioFile from "./ShareTalRadioFile";
import ShareIcon from "@material-ui/icons/Share";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { getFileInfo } from "../../utils/api";
import toastr from "toastr";
import Chip from "@material-ui/core/Chip";

const TalRadioPlayer = (props) => {
  const [openShare, setOpenShare] = useState(false);

  const [url, setUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [timeCreated, setTimeCreated] = useState(null);
  const [contentType, setContentType] = useState("");
  const [shareIcons, setShareIcons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [refereceId, setReferenceId] = useState("");

  const loadMediaFile = (fileName) => {
    setIsLoading(true);
    getFileInfo(fileName)
      .then((response) => {
        if (response) {
          setReferenceId(response._id);
          if (response.thumbnailImageUrl) {
            setThumbnailUrl(response.thumbnailImageUrl[0]);
          } else {
            setThumbnailUrl("/images/default-thumbnail.jpeg");
          }
          if (response.url) {
            setUrl(response.url);
          }
          setName(response.name);
          setNote(response.description);
          setTimeCreated(response.time);
          setSelectedCategories(response.categories);
          setContentType(response.contentType);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching file:" + error);
      });
  };
  useEffect(() => {
    if (props.match.params.file) {
      loadMediaFile(props.match.params.file);
    }
  }, [props.match.params.file]);

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const handleClickOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };

  return (
    <Box className="TalRadioPlayer" mt={-3}>
      <Container>
        <div className="podcast-cont">
          <div className="podcast-child-cont">
            {isAudio(contentType) && thumbnailUrl && (
              <img src={thumbnailUrl} alt="thumbnail" />
            )}
            {isAudio(contentType) && (
              <ReactPlayer
                url={url}
                controls={true}
                playing={true}
                height={50}
              />
            )}

            {!isAudio(contentType) && (
              <ReactPlayer url={url} controls={true} playing={true} />
            )}
          </div>

          <div>
            <div className="podcast-detail-cont">
              <Typography
                component="h1"
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {name}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography component="p" style={{ alignSelf: "center" }}>
                  {timeCreated && moment(timeCreated).format(DATE_TIME_FORMAT)}
                </Typography>

                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    pointerEvents: "auto",
                  }}
                  onMouseOver={() => setShareIcons(true)}
                  //onMouseLeave={() => setShareIcons(false)}
                  className="action-btn"
                >
                  <Tooltip title="Share request">
                    <IconButton
                      aria-label="share"
                      className="share-req-icons"
                      onClick={handleClickOpenShare}
                    >
                      <ShareIcon />{" "}
                      <Box
                        component="span"
                        fontSize="16px"
                        color="#555"
                        className="share-pop"
                      >
                        Share
                      </Box>
                    </IconButton>
                  </Tooltip>

                  {shareIcons && (
                    <Dialog
                      onClose={handleCloseShare}
                      aria-labelledby="customized-dialog-title"
                      open={openShare}
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseShare}
                      >
                        Share Podcast Url
                      </DialogTitle>
                      <ShareTalRadioFile
                        file={props.match.params.file}
                        name={name}
                        note={note}
                        contentType={contentType}
                        thumbnailUrl={thumbnailUrl}
                        representsWhat={"talmediaPodcast"}
                        referenceId={refereceId}
                      />
                      <DialogContent
                        dividers
                        style={{
                          display: "flex",
                          gap: "20px",
                          padding: "30px  ",
                        }}
                      ></DialogContent>
                    </Dialog>
                  )}
                </div>
              </div>
              {selectedCategories &&
                selectedCategories.length > 0 &&
                Array.isArray(selectedCategories) && (
                  <>
                    <Grid container style={{ gap: "3px" }}>
                      {selectedCategories.map((data) => {
                        return (
                          <Chip
                            className=" bg-secondary"
                            label={data.name}
                            style={{ color: "#fff" }}
                            variant="outlined"
                          />
                        );
                      })}
                    </Grid>
                  </>
                )}
              <hr />

              <Typography component="p">{note}</Typography>
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default TalRadioPlayer;

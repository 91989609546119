import React, { useState, useContext } from "react";
import toastr from "toastr";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SideMenu from "../SideMenu/Sidemenu";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

import { getMyFavRequests } from "../../utils/api";
import StickyBox from "react-sticky-box";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { appContext } from "../../App";

import "./MyFavorites.scss";
import { TALRADIO_SITE } from "../../utils/utils";
import DonationRequestCard from "./DonationRequestCard";
import Img from "react-cool-img";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MyFavourite = (props) => {
  const PER_PAGE = 5;
  const { authUser, currentYear } = useContext(appContext);
  const classes = useStyles();
  const [favDonationRequests, setFavDonationRequests] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const defaultRequestTypes = JSON.stringify([
    { request_type: "board member" },
    { request_type: "podcast" },
    { request_type: "eventSpeaker" },
    { request_type: "mentoring" },
  ]);

  const loadFavDonationRequests = () => {
    if (authUser && authUser.unique_id) {
      setIsLoading(true);
      getMyFavRequests(
        authUser.unique_id,
        PER_PAGE,
        offset,
        defaultRequestTypes,
        authUser && authUser.unique_id
      )
        .then((response) => {
          setIsLoading(false);
          if (
            response.data &&
            response.data instanceof Array &&
            response.data.length > 0
          ) {
            const newOffset = offset + response.data.length;
            if (newOffset < response.totalCountOfRecords) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }
            setOffset(newOffset);
            setFavDonationRequests((donationRequests) => [
              ...donationRequests,
              ...response.data,
            ]);
          } else {
            setHasNextPage(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setHasNextPage(false);
          toastr.error(error.message);
        });
    }
  };

  const handleScroll = (e) => {
    if (
      window.innerHeight + e.target.documentElement.scrollTop + 1 ===
      e.target.documentElement.scrollHeight
    ) {
      loadFavDonationRequests(keyword);
    }
  };

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: loadFavDonationRequests,
    scrollContainer: "window",
  });

  return (
    <div className={classes.root}>
      <div className="home2-container favorites">
        <Container maxWidth="lg">
          <Grid container spacing={2} className="favorites-container-main">
            <Grid item sm={12} xs={12} md={3} className="side-cont">
              <Hidden smDown>
                <SideMenu />
              </Hidden>
            </Grid>

            <Grid item sm={12} xs={12} md={6} className="container-center">
              <div>
                {favDonationRequests &&
                  favDonationRequests.length > 0 &&
                  favDonationRequests.map((donationRequest, index) => {
                    return (
                      <DonationRequestCard
                        donationRequest={donationRequest}
                        setDonationRequests={setFavDonationRequests}
                        donationRequests={favDonationRequests}
                        key={index}
                      />
                    );
                  })}
                {hasNextPage && (
                  <p ref={infiniteRef} style={{ textAlign: "center" }}>
                    Loading...
                  </p>
                )}
              </div>
              {!hasNextPage &&
                favDonationRequests &&
                favDonationRequests.length === 0 && (
                  <h2 className="no-req-text">No Favourite Requests</h2>
                )}
            </Grid>

            <Grid item sm={12} xs={12} md={3} className="container-right">
              <Hidden smDown>
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <div>
                    <Paper
                      className={classes.paper + " no-pad promoBlk"}
                      style={{
                        marginBottom: "10px",
                        padding: "0",
                        boxShadow: "none",
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      {/* <div>
                        <Link
                          href="https://www.touchalife.org/nominate-talhero/"
                          target="_blank"
                        >
                          {" "}
                          <figure className="drop-box">
                            <Img
                              src="/images/call-for-nomination.png"
                              alt="no requests"
                              width="100%"
                            />
                          </figure>
                        </Link>
                      </div> */}
                      <div className="promotion">
                        <Link href={TALRADIO_SITE} target="blank">
                          <img
                            className="max-img"
                            src="/images/radio.png"
                            alt="promotions"
                          />
                        </Link>
                      </div>
                    </Paper>

                    <Paper className={classes.paper + " no-pad latestNewsBlk"}>
                      <div className="newsList">
                        <Card className={classes.root} elevation={0}>
                          <CardContent style={{ padding: "0" }}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              className="newsList-title"
                            >
                              TAL Headlines
                            </Typography>
                            <ul>
                              <li>
                                Touch-A-Life Foundation adds accelero as charter
                                member
                              </li>
                              <li>
                                Touch-A-Life Foundation adds PulpPR as TAL
                                charter member
                              </li>
                              <li>
                                Touch-A-Life Foundation adds Royse Law Firm as
                                charter member
                              </li>
                              <li>
                                Touch-A-Life Foundation announces Hero award
                                winners for TAL Annual Day 2019
                              </li>
                              <li>
                                Touch-A-Life Foundation partners with Minds to
                                enable Cryptocurrency-based giving
                              </li>
                            </ul>
                          </CardContent>
                        </Card>
                      </div>
                    </Paper>
                    <div className="home-footer">
                      <p>
                        <a href="https://www.touchalife.org/about/">About</a> .{" "}
                        <a href="/user/help-desk">Help Desk</a>
                      </p>
                      <p>
                        <a href="javascript:void(0)">Privacy</a> .{" "}
                        <a href="https://www.touchalife.org/terms-and-conditions/">
                          Terms
                        </a>{" "}
                        .{" "}
                        <a href="https://www.touchalife.org/terms-and-conditions/">
                          Security
                        </a>{" "}
                        . <a href="javascript:void(0)">Legal</a>
                      </p>
                      <p>Touch-A-Life Foundation INC &copy; {currentYear}</p>
                    </div>
                  </div>
                </StickyBox>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default MyFavourite;

import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  LinearProgress,
  FormGroup,
  TextareaAutosize,
} from "@material-ui/core";
import Sidemenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createDonationRequest,
  updateDonationRequest,
  getUserData,
  updateProfile,
  getDonationRequest,
} from "../../utils/api";
import { KeyboardDatePicker } from "@material-ui/pickers";

import FormHelperText from "@material-ui/core/FormHelperText";
import AddPhotos from "../common/AddPhotos";
import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PhoneNumber from "../Phone/PhoneNumber";
import {
  getQueryStringValue,
  HOME_PAGE,
  STATUS_NEW,
  STATUS_APPROVED,
  STATUS_INCOMPLETE,
} from "../../utils/utils";
import jwt_decode from "jwt-decode";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { DATE_TIME_FORMAT_FULL_PICKER } from "../../utils/utils";
import DateFnsUtils from "@date-io/date-fns";
import validator from "validator";
import {
  FormControlLabel,
  Checkbox,
  MuiThemeProvider,
  Tooltip,
  createMuiTheme,
} from "@material-ui/core";

import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import ShortAddress from "../common/ShortAddress";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    width: "100%",
    padding: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  fieldlength: {},
}));
const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(language, languageSet, theme) {
  return {
    fontWeight:
      languageSet.indexOf(language) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PodcastRequest = (props) => {
  const {
    authUser,
    setAuthUser,
    isSuperAdmin,
    isAdmin,
    isVolunteer,
    region,
    regions,
    setRegion,
    orgList,
  } = useContext(appContext);
  const theme = useTheme();
  const initialState = {
    title: "",
    hostName: "",
    description: "",
    firstName: "",
    lastName: "",
    email: "",
    linkedInProfileUrl: "",
    fundsRecipient: "",
  };
  const [closeSideMenu] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const classes = useStyles();
  const phoneRef = useRef(phone);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [userId, setUserId] = useState("");
  const [term, setTerm] = useState(false);
  const [interviewDiscussion, setInterviewDiscussion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");
  const handleTerm = (event) => {
    if (event.target.checked) {
      setTerm(true);
    } else {
      setTerm(false);
    }
  };

  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [id, setId] = useState("");

  const [imageUpload, setImageUpload] = useState(false);

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
    saveDonationRequest(url);
  };

  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
    };
    if (
      authUser &&
      authUser.unique_id &&
      donationRequest &&
      donationRequest.user_info &&
      authUser.unique_id == donationRequest.user_info._id
    ) {
      finalValues.status = 0;
    } else if (!editMode) {
      finalValues.status = 0;
    }
    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
        toastr.success(
          "Default profile Image is set for this Podcast request."
        );
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the Podcast request. " +
            error.message
        );
      });
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const handleChangeEndDate = (date) => {
    const roundedDate = moment(date.getTime()).toDate();
    setEndDate(roundedDate);
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };
  const changeDescriptionHandler = (e, editor) => {
    setInterviewDiscussion(editor.getData());
  };

  const handleCreatePost = (e) => {
    e.preventDefault();
    const errors = {};
    if (activeStep === 0) {
      const userValues = {};
      const userErrors = {};
      if (!values.firstName) {
        userErrors.firstName = "Please enter firstName";
      }
      if (!values.lastName) {
        userErrors.lastName = "Please enter lastName";
      }
      userValues.name = {
        first_name: values.firstName,
        last_name: values.lastName,
      };
      userValues.email = values.email;
      //validate the phone input
      if (!phoneRef.current.isValid()) {
        return;
      }
      if (values.fundsRecipient === "self") {
        userValues.orgId = "Self";
      } else {
        userValues.orgId = values.fundsRecipient;
      }
      if (!values.fundsRecipient) {
        userErrors.fundsRecipient = "Please Select or Add an Organization";
      }
      userValues.linkedInProfileUrl = values.linkedInProfileUrl;
      setErrors(userErrors);
      if (userErrors && Object.keys(userErrors).length > 0) {
        // toastr.error("Please enter the mandatory fields ");
        return;
      }
      if (Object.keys(userErrors).length == 0) {
        setActiveStep(activeStep + 1);
        updateProfile(authUser.unique_id, userValues)
          .then((response) => {
            setAuthUser(response.data.data);
            if (authUser.unique_id) {
              localStorage.setItem(
                "authUser",
                JSON.stringify(response.data.data)
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                toastr.error(error.response.data.errors[0].msg);
              } else {
                toastr.error(error.response.message);
              }
            } else {
              toastr.error(error.message);
            }
          });
      }
    } else if (activeStep === 1) {
      const errors = {};
      const finalValues = {};
      if (values.title.length === 0) {
        errors.title = "Please enter Topic";
      }
      if (values.hostName.length === 0) {
        errors.hostName = "Please enter Host name";
      }
      if (interviewDiscussion.length === 0) {
        errors.interviewDiscussion = "Describe about Panel Discussion";
      }
      if (endDate === null) {
        errors.date = "Please select date";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      finalValues.request_type = "podcast";
      finalValues.title = values.title;
      finalValues.user_id = authUser.unique_id;
      finalValues.quantity = 1;
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
      setAddress(tempAddress);
      finalValues.shipping_address = tempAddress;
      finalValues.region = tempAddress.country ? region && region._id : null;
      finalValues.orgId = values.fundsRecipient;
      const additionalInfo = {
        podcastDate: editMode ? endDate : endDate.getTime(),
        hostName: values.hostName,
        interviewOrPanelDiscussion: interviewDiscussion,
      };
      finalValues.additionalInfo = additionalInfo;
      if (Object.keys(errors).length == 0) {
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Podcast request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
              // setActiveStep(0);
            });
        }
      }
    } else if (activeStep === 2) {
      if (!defaultImageUrl) {
        errors.image = "Please upload at least one image";
      }
      if (term === false) {
        errors.term = "Please select the checkboxes";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
        setErrors({});
        if (imageUpload) {
          toastr.success(
            "Your Podcast Request is successfully" +
              (props.match.params.id ? " updated" : " created ")
          );
          props.history.push(`/donationRequest/${name}`);
        }
      }
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleNext);

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
    saveDonationRequest();
  };
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  const [openOrgModal, setOpenOrgModal] = useState(false);

  const loadRequestData = () => {
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          if (
            isSuperAdmin ||
            isAdmin ||
            isVolunteer ||
            (response &&
              (response.status === Number(STATUS_NEW) ||
                response.status === Number(STATUS_INCOMPLETE) ||
                response.status === Number(STATUS_APPROVED)) &&
              response.user_info &&
              authUser.unique_id === response.user_info._id)
          ) {
            setEditMode(true);
          } else {
            setEditMode(false);
          }
          if (editMode) {
            setDonationRequest(response);
            setId(response._id);
            setDefaultImageUrl(response.defaultImageUrl);
            values.fundsRecipient =
              response.orgId && response.orgId._id
                ? response.orgId && response.orgId._id
                : "self";
            values.title = response.title;
            values.hostName =
              response.additionalInfo && response.additionalInfo.hostName;
            setInterviewDiscussion(
              response.additionalInfo &&
                response.additionalInfo.interviewOrPanelDiscussion
            );
            setImageUpload(true);
            setName(response.name);
            setValues(values);
            setTerm(true);
            response.shipping_address &&
              setAddress({
                line1: response.shipping_address.line1,
                line2: response.shipping_address.line2,
                city: response.shipping_address.city,
                state: response.shipping_address.state,
                zip_code: response.shipping_address.zip_code,
                country: response.shipping_address.country,
              });
            setEndDate(
              response.additionalInfo && response.additionalInfo.podcastDate
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      let userData = authUser;
      setValues((currentValues) => {
        return {
          ...currentValues,
          firstName: userData.name.first_name,
          lastName: userData.name.last_name,
          email: userData.email,
          linkedInProfileUrl: userData.linkedInProfileUrl,
        };
      });
      setPhone(userData.phone);
      setIsPhoneVerified(userData.phone_verified);
    }
  }, [authUser]);

  useEffect(() => {
    if (props.match.params.id) {
      loadRequestData(props.match.params.id);
    }
  }, [props.match.params.id, authUser, editMode]);

  useEffect(() => {
    if (props.match.params.id) {
      loadRequestData(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <Sidemenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/podcasts.png"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "10px",
                      borderRadius: "75%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Podcast Request
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Use this form to receive applications for podcast members
                    from our TALLeaders group.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid className={classes.root}>
              <div>
                <div className="field-length">
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Verify your information and select the organization
                        </Typography>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={12}>
                          <InputLabel className="secFormLabel">
                            First Name <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            className="profile-fields fname"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="firstName"
                            //disabled={!editMode}
                            value={values.firstName}
                            onChange={changeHandler}
                          />
                          {errors && errors.firstName && (
                            <Typography className="custom-error-global">
                              {errors.firstName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <InputLabel className="secFormLabel">
                            Last Name <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%" }}
                            name="lastName"
                            //disabled={!editMode}
                            value={values.lastName}
                            onChange={changeHandler}
                          />
                          {errors && errors.lastName && (
                            <Typography className="custom-error-global">
                              {errors.lastName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <InputLabel className="secFormLabel">
                            Email <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="email"
                            disabled={!editMode}
                            value={values.email}
                            onChange={changeHandler}
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ width: "100%" }}
                        >
                          <InputLabel className="secFormLabel">
                            Phone <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <PhoneNumber
                            phone={phone}
                            isPhoneVerified={isPhoneVerified}
                            ref={phoneRef}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{ width: "100%", padding: "10px 0" }}
                            className="recipient"
                            error={errors && errors.fundsRecipient}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{ padding: "5px 23px" }}
                            >
                              Select/Add Organization
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.fundsRecipient}
                              onChange={changeHandler}
                              variant="outlined"
                              placeholder="Organization"
                              // className={`input-field request-amount ${
                              //   errors && errors.fundsRecipient
                              //     ? "bg-red"
                              //     : values.fundsRecipient
                              //     ? "bg-green"
                              //     : "bg-normal"
                              // }`}
                              name="fundsRecipient"
                              label="Select/Add Organization"
                            >
                              <MenuItem value={"self"}>Self</MenuItem>
                              {orgList &&
                                orgList.length > 0 &&
                                orgList.map((org) => {
                                  return (
                                    <MenuItem
                                      value={org && org.orgId && org.orgId._id}
                                    >
                                      <img
                                        src={
                                          org &&
                                          org.orgId &&
                                          org.orgId.defaultImageUrl
                                            ? org.orgId.defaultImageUrl
                                            : "/images/orgLogo.png"
                                        }
                                        alt="orgLogo"
                                        width="20px"
                                        style={{ marginRight: 8 }}
                                      />{" "}
                                      {org && org.orgId && org.orgId.orgName}{" "}
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          textAlign: "end",
                                        }}
                                      >
                                        {org &&
                                        org.orgId &&
                                        org.orgId.status === 1 ? (
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <ErrorOutlineIcon
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </span>
                                    </MenuItem>
                                  );
                                })}
                              <MenuItem
                                value="add"
                                onClick={handleOrganization}
                              >
                                Add Organization
                              </MenuItem>
                            </Select>{" "}
                            <FormHelperText>
                              {errors.fundsRecipient}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Linkedin URL
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%", marginTop: "10px" }}
                            name="linkedInProfileUrl"
                            //disabled={!editMode}
                            label="Enter linkedIn profile url"
                            value={values.linkedInProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.linkedInProfileUrl && (
                            <Typography className="custom-error">
                              {errors.linkedInProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Box display="flex" justifyContent="flex-end">
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => props.history.push(HOME_PAGE)}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => handleCreatePost(e)}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Grid items xs={12} className="pos-rel">
                        <InputLabel
                          className="secFormLabel"
                          style={{ paddingLeft: "10px" }}
                        >
                          Topic <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          type="text"
                          className="profile-fields fname"
                          variant="outlined"
                          style={{ width: "100%", padding: "10px" }}
                          name="title"
                          value={values.title}
                          onChange={changeHandler}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "18px",
                            marginLeft: "5px",
                            position: "absolute",
                            top: "35px",
                            color: "#ccc",
                            right: "0px",
                            padding: "20px",
                          }}
                        >
                          {" "}
                          {values.title ? values.title.length : 0}/50
                        </span>
                        {errors && errors.title && (
                          <Typography className="custom-error-global">
                            {errors.title}
                          </Typography>
                        )}
                      </Grid>
                      <Grid items xs={12} className="pos-rel">
                        <InputLabel
                          className="secFormLabel"
                          style={{ paddingLeft: "10px", paddingTop: "10px" }}
                        >
                          Host Name <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          type="text"
                          className="profile-fields fname"
                          variant="outlined"
                          style={{ width: "100%", padding: "10px" }}
                          name="hostName"
                          value={values.hostName}
                          onChange={changeHandler}
                          inputProps={{
                            maxLength: 30,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "18px",
                            marginLeft: "5px",
                            position: "absolute",
                            top: "35px",
                            color: "#ccc",
                            right: "0px",
                            padding: "20px",
                          }}
                        >
                          {" "}
                          {values.hostName ? values.hostName.length : 0}/30
                        </span>
                        {errors && errors.hostName && (
                          <Typography className="custom-error-global">
                            {errors.hostName}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          className="secFormLabel"
                          style={{ paddingLeft: "10px", paddingTop: "10px" }}
                        >
                          Podcast Date <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                            className="recipient"
                            error={errors && errors.date}
                          >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                showTodayButton
                                format="dd-MMM-yyyy"
                                style={{ width: "100%" }}
                                margin="normal"
                                className={`input-field dob`}
                                // ${
                                // //   extraErrors && extraErrors.dueDate
                                // //     ? "bg-red"
                                // //     : dueDate
                                // //     ? "bg-green"
                                // //     : "bg-normal"
                                // // }`}
                                variant="dialog"
                                value={endDate}
                                inputVariant="outlined"
                                label="Select Podcast Date"
                                placeholder="dd-MMM-yyyy"
                                onChange={(newDate) => {
                                  handleChangeEndDate(newDate);
                                }}
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                            <FormHelperText>{errors.date}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className=" recipient"
                        >
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#000",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            Select your location
                          </Typography>
                          <ShortAddress
                            value={address}
                            onChange={handleChangeAddress}
                          />
                        </FormControl>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#000",
                              paddingLeft: "10px",
                              paddingTop: "20px",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            Interview / Panel Discussion{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "20px",
                            }}
                            error={errors && errors.interviewDiscussion}
                          >
                            <Grid className="ck-editor-css">
                              <CKEditor
                                style={{ padding: "10px 20px" }}
                                maxLength={300}
                                data={interviewDiscussion}
                                name="interviewDiscussion"
                                editor={ClassicEditor}
                                onChange={changeDescriptionHandler}
                                config={{
                                  toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "Link",
                                  ],
                                  placeholder: "Enter Interview Discussion",
                                }}
                              />
                            </Grid>
                            <FormHelperText>
                              {errors.interviewDiscussion}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Box display="flex" justifyContent="flex-end">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => handleCreatePost(e)}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <div className="request-step-two">
                        <div className="request-two-step">
                          <h3 className="step-two-title">
                            Add a cover photo or video
                          </h3>
                          <p className="image-desc">
                            This is the image that will feature right on top of
                            your page. Make it impactful!
                          </p>
                          <div className="fund-img-txt">
                            <p> A picture is worth a thousand words</p>
                            <ul>
                              <li>
                                <span>Keep it relevant</span>
                              </li>
                              <li>
                                <span>Keep it clear</span>
                              </li>
                              <li>
                                <span>Types: .PNG, .JPG, .BMP, .MP4</span>
                              </li>
                              <li>
                                <span>Size: 700px X 400px</span>
                              </li>
                            </ul>
                          </div>
                          <MuiThemeProvider
                            theme={theme2}
                            className="req-tooltip-img"
                          >
                            <img
                              src={
                                defaultImageUrl
                                  ? defaultImageUrl
                                  : "/images/default-req-img.gif"
                              }
                              alt="default"
                              className="fund-cover-img"
                            />
                          </MuiThemeProvider>
                          {errors && errors.image && (
                            <div className="custom-error">{errors.image}</div>
                          )}
                          <div className="add-btn-fund">
                            <Button
                              style={{
                                font: "500 12px Roboto",
                                padding: "20px 0px",
                                margin: "10px 5px",
                                background: "transparent",
                                color: "#283E4A",
                                border: "1px solid #283E4A",
                              }}
                              onClick={() => setShowingAddPhotoModal(true)}
                            >
                              <FontAwesomeIcon
                                color="#283E4A"
                                size="1x"
                                style={{ marginRight: 6 }}
                                icon={faFileUpload}
                              />{" "}
                              Add Images
                            </Button>
                          </div>
                        </div>
                      </div>
                      <Grid item xs={12}>
                        <FormControl error={errors && errors.term}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="term"
                                checked={term}
                                onChange={handleTerm}
                                required="required"
                              />
                            }
                            label={
                              <div className="linktag">
                                <span>
                                  {" "}
                                  By submitting the form you agree to the{" "}
                                </span>
                                <a
                                  href="https://www.touchalife.org/terms-and-conditions/"
                                  target="blank"
                                  style={{ color: "#0000FF" }}
                                >
                                  Terms of Use
                                </a>{" "}
                                and{" "}
                                <a
                                  href="https://www.touchalife.org/privacy-policy/"
                                  target="blank"
                                  style={{ color: "#0000FF" }}
                                >
                                  our Privacy Policy
                                </a>
                                .{" "}
                              </div>
                            }
                          />
                          <FormHelperText>{errors.term}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Box display="flex" justifyContent="flex-end">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 2 && term ? false : true}
                            onClick={handleCreatePost}
                          >
                            {editMode ? "Update" : "Create"}
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
        multiple={true}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(PodcastRequest);

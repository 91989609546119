import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Add } from "@material-ui/icons";
import AddComment from "./AddComment";
import { appContext } from "../../App";
import firebase from "../../firebase/firebase";
import { Avatar, Grid, Menu, MenuItem, TextField } from "@material-ui/core";
import moment from "moment";
import toastr from "toastr";
import ReactAvatar from "react-avatar";
import { DATE_TIME_FORMAT } from "../../utils/utils";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Replies from "./Replies";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    maxWidth: "md",
  },
  submitButton: {
    position: "absolute",
    right: theme.spacing(8),
    top: theme.spacing(2),
    color: "white",
    background: "red",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    authUser,
    children,
    classes,
    onClose,
    setCommentId,
    commentType,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {commentType === "forum" && (
        <Button
          variant="contained"
          className={classes.submitButton + " submitButton139"}
          onClick={() => {
            if (!authUser) {
              toastr.error("Please signup or login to add a question.", "", {
                timeOut: 3000,
              });
              props.history.push("/login");
            } else {
              setCommentId("newComment");
            }
          }}
        >
          <Add />
          New Question
        </Button>
      )}

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const Forum = (props) => {
  const { openForum, onClose, path, commentType } = props;
  const { authUser, isAdmin, isSuperAdmin } = useContext(appContext);
  const [comments, setComments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editedMsg, setEditedMsg] = useState("");
  const [showTextbox, setShowTextbox] = useState(false);
  const [test, setTest] = useState([]);
  const [comment, setComment] = useState("");
  const [openReplies, setOpenReplies] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [more, setMore] = useState(3);

  const handleDeleteComment = (key) => {
    if (window.confirm("Are you sure you want to delete the comment?")) {
      firebase.realtimeDb
        .ref(path + "/" + key)
        .remove()
        .then(() => {
          toastr.success("Successfully deleted");
          loadComments();
          // if (props.onClick) {
          //   props.onClick();
          // }
        });
    }
  };

  const handleEditComment = (comment) => {
    let com = comments.filter((item) => item.key === comment.key);
    setTest(com);
    setShowTextbox(true);
  };

  const checkEditMsg = (event, comm) => {
    if (event.key === "Enter") {
      const newComment = event.target.value;
      if (newComment && newComment.length > 0) {
        firebase.realtimeDb
          .ref(path + "/" + comm.key)
          .update({
            text: newComment,
            whenSent: new Date().getTime(),
          })
          .then((snapshot) => {
            toastr.success("Successfully edited");
            setShowTextbox(false);
            loadComments();
            // if (props.onClick) {
            //   props.onClick();
            // }
          })
          .catch((error) => {
            toastr.error(error.message);
            setShowTextbox(true);
          });
      } else {
        toastr.error("please enter a valid text");
        setShowTextbox(true);
      }
    }
  };
  const handleReply = (comment) => {
    // path = path + comment.key;
    if (comment && comment.key) {
      //  onClose();
      setOpenReplies(true);
      setComment(comment);
    }
  };

  useEffect(() => {
    if (openForum) {
      loadComments();
    }
  }, [openForum]);

  const loadComments = () => {
    const ref = firebase.realtimeDb.ref(path);

    ref.on("value", (snapshot) => {
      const comments = snapshot.val();
      const commentsList = [];
      snapshot.forEach((productSnapshot) => {
        let key = productSnapshot.key;
        let data = productSnapshot.val();
        commentsList.push({ key: key, ...data });
      });

      setComments(commentsList && commentsList.reverse());
    });
    //ref.off("value")
  };

  const getCountAtGivenPath = (path) => {
    const ref = firebase.realtimeDb.ref(path);
    let commentsList = [];
    ref.on("value", (snapshot) => {
      const comments = snapshot.val();
      commentsList = [];
      snapshot.forEach((productSnapshot) => {
        let key = productSnapshot.key;
        let data = productSnapshot.val();
        commentsList.push({ key: key, ...data });
      });
    });
    ref.off("value");
    return commentsList.length;
  };

  const isLoggedInUserLiked = (likesPath) => {
    let isLiked = false;
    if (authUser && authUser.unique_id) {
      const ref = firebase.realtimeDb.ref(likesPath);
      ref.on("value", (snapshot) => {
        snapshot.forEach((productSnapshot) => {
          let key = productSnapshot.key;
          let data = productSnapshot.val();
          if (data.userId === authUser.unique_id) {
            isLiked = true;
          }
        });
      });
      ref.off("value");
    }
    return isLiked;
  };

  const addLike = (path, userId, name, likedByProfileImageUrl) => {
    const ref = firebase.realtimeDb.ref(path);
    const values = {};
    values.userId = userId;
    values.name = name;
    values.likedByProfileImageUrl = likedByProfileImageUrl || "";
    values.whenLiked = new Date().getTime();
    ref
      .push()
      .set(values)
      .then(() => {
        console.log("Added like node");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const unLikeComment = (likesPath) => {
    if (authUser && authUser.unique_id) {
      const ref = firebase.realtimeDb.ref(likesPath);
      ref.on("value", (snapshot) => {
        snapshot.forEach((productSnapshot) => {
          let key = productSnapshot.key;
          let data = productSnapshot.val();
          if (data.userId === authUser.unique_id) {
            firebase.realtimeDb.ref(likesPath + "/" + key).remove();
          }
        });
      });
      ref.off("value");
    }
  };

  const toggleLike = (key) => {
    const likesPath = path + "/" + key + "/likes";
    if (isLoggedInUserLiked(likesPath)) {
      unLikeComment(likesPath);
    } else {
      addLike(
        likesPath,
        authUser.unique_id,
        (authUser &&
          authUser.name &&
          authUser.name.first_name + " " + authUser.name.last_name) ||
          "",
        authUser && authUser.profile_image_url
      );
    }
  };

  const handleEditMsg = (e) => {
    setEdit(true);
    setEditedMsg(e.target.value);
  };

  const handleMoreComments = () => {
    setMore(more + 3);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openForum}
      fullWidth="true"
      maxWidth="md"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        setCommentId={setCommentId}
        commentType={commentType}
        authUser={authUser}
        history={props.history}
      >
        {commentType === "forum" ? "Forum" : "Comments"}
      </DialogTitle>
      <DialogContent dividers>
        <div className="comments-container">
          {commentType === "forum"
            ? authUser &&
              commentId && (
                <AddComment
                  path={path}
                  senderProfileImageUrl={authUser && authUser.profile_image_url}
                  userId={authUser && authUser.unique_id}
                  senderName={
                    (authUser &&
                      authUser.name &&
                      authUser.name.first_name +
                        " " +
                        authUser.name.last_name) ||
                    ""
                  }
                  setCommentId={setCommentId}
                  promptText="What do you want to ask about this request?"
                />
              )
            : authUser && (
                <AddComment
                  path={path}
                  senderProfileImageUrl={authUser && authUser.profile_image_url}
                  userId={authUser && authUser.unique_id}
                  senderName={
                    (authUser &&
                      authUser.name &&
                      authUser.name.first_name +
                        " " +
                        authUser.name.last_name) ||
                    ""
                  }
                  setCommentId={setCommentId}
                  promptText="Say something about this"
                />
              )}

          <div className="comment-section-block">
            <div>
              {comments && comments.length > 0 ? (
                comments.slice(0, more).map((comment) => {
                  const likesPath = path + "/" + comment.key + "/likes";
                  const repliesPath = path + "/" + comment.key + "/replies";
                  const numberOfReplies = getCountAtGivenPath(repliesPath);
                  return (
                    <Typography
                      className="recent-comments"
                      style={{ marginBottom: "30px" }}
                    >
                      {comment.senderProfileImageUrl ? (
                        <Avatar
                          src={comment.senderProfileImageUrl}
                          alt="Avatar"
                          className="comment-avatar"
                        />
                      ) : (
                        <ReactAvatar
                          name={comment.senderName}
                          size="30"
                          round={true}
                          className="comment-avatar"
                        />
                      )}{" "}
                      <Typography className="comments-main-details">
                        {test &&
                        test[0] &&
                        test[0].whenSent === comment.whenSent &&
                        showTextbox ? (
                          <TextField
                            type="text"
                            name="message"
                            value={edit ? editedMsg : comment.text}
                            className="comments-edit-field"
                            onChange={handleEditMsg}
                            onKeyPress={(e) => checkEditMsg(e, comment)}
                            placeholder="What do you want to say about this donation request?"
                            width={16}
                            autoFocus
                          />
                        ) : (
                          <Typography style={{ position: "relative" }}>
                            <Typography className="comment-details">
                              <span className="commentor-name">
                                {comment.senderName}
                              </span>{" "}
                              |{" "}
                              <span className="comment-time">
                                {moment(comment.whenSent).format(
                                  DATE_TIME_FORMAT
                                )}
                              </span>
                            </Typography>{" "}
                            <Typography className="comment-text">
                              <Button
                                style={{
                                  color: "blue",
                                  textTransform: "none",
                                  fontSize: "1em",
                                  overflowWrap: "anywhere",
                                }}
                                onClick={() => handleReply(comment)}
                              >
                                {comment.text}
                              </Button>
                            </Typography>
                            <div className="forumcls">
                              <div>
                                <Button onClick={() => toggleLike(comment.key)}>
                                  {isLoggedInUserLiked(likesPath) ? (
                                    <ThumbUpIcon style={{ fill: "#0000ff" }} />
                                  ) : (
                                    <ThumbUpIcon style={{ fill: "" }} />
                                  )}
                                  {" (" + getCountAtGivenPath(likesPath) + ")"}
                                </Button>
                                <Button onClick={() => handleReply(comment)}>
                                  Reply
                                </Button>

                                {numberOfReplies > 0 ? (
                                  <Button
                                    style={{
                                      color: "blue",
                                      textTransform: "none",
                                    }}
                                    onClick={() => handleReply(comment)}
                                  >
                                    View{" "}
                                    {numberOfReplies == 1
                                      ? "1 Reply"
                                      : `${numberOfReplies} Replies`}
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div>
                                {authUser &&
                                  comment &&
                                  comment.userId == authUser.unique_id && (
                                    <Button
                                      onClick={() => handleEditComment(comment)}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                {((authUser &&
                                  comment &&
                                  authUser.unique_id == comment.userId) ||
                                  isSuperAdmin ||
                                  isAdmin) && (
                                  <Button
                                    onClick={() =>
                                      handleDeleteComment(comment.key)
                                    }
                                  >
                                    Delete
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Typography>
                        )}
                      </Typography>
                    </Typography>
                  );
                })
              ) : (
                <div>
                  {commentType === "forum" ? (
                    <h4 align="center">No question yet</h4>
                  ) : (
                    <h4 align="center">No comment yet</h4>
                  )}
                  {commentType === "forum" ? (
                    <h4 align="center">
                      <p align="center">Be the first to ask a question</p>
                    </h4>
                  ) : (
                    <h4 align="center">
                      <p align="center">Be the first to comment</p>
                    </h4>
                  )}
                </div>
              )}
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {!authUser && (
                <Typography className="not-logged-text">
                  {commentType === "forum"
                    ? "Please signup or login to add a question"
                    : "Please signup or login to add a comment"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {comments.length > more && (
                <div className="comment-btn" onClick={handleMoreComments}>
                  {commentType === "forum"
                    ? "View more questions..."
                    : "View more comments..."}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <Replies
        openReplies={openReplies}
        onClose={() => setOpenReplies(false)}
        path={path + "/" + comment.key + "/replies"}
        comment={comment}
        commentType={commentType}
      />
    </Dialog>
  );
};

export default withRouter(Forum);

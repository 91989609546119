import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Typography, Tooltip } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { formatNumber } from "../../utils/utils";
import { getTransactionDetails } from "../../utils/api";
import PrintReceiptModal from "../donationDetails/PrintReceiptModal";
import toastr from "toastr";
import Img from "react-cool-img";
import { appContext } from "../../App";
import { DATE_FORMAT } from "../../utils/utils";
import "./Thankyou.scss";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const ThankYou = (props) => {
  const { authUser } = useContext(appContext);
  const [receiptData, setReceiptData] = useState({});
  const [response, setResponse] = useState({});
  const [isOpenPrintModal, setIsOpenPrintModal] = useState(false);

  const handleHomepage = () => {
    props.history.push("/");
  };
  const handlePrintModal = () => {
    setIsOpenPrintModal(true);
  };

  useEffect(() => {
    if (props.location.state.thankyouResponse) {
      var donationQuery;
      if (props.location.state.thankyouResponse.donationFor === "individual") {
        donationQuery = "individual";
      } else {
        donationQuery = "tal";
      }
      setResponse(props.location.state.thankyouResponse);
      getTransactionDetails(
        props.location.state.thankyouResponse.donationData,
        donationQuery
      )
        .then((transactionData) => {
          var transData = transactionData.data;
          let donationFor = "";
          if (transactionData) {
            if (transData.donationData && transData.donationData.donationFor) {
              if (transData.donationData.donationFor === "tal") {
                donationFor = "TOUCH-A-LIFE FOUNDATION";
              } else if (
                transData.donationData.donationFor === "donationRequest"
              ) {
                donationFor = "Donation Request";
              } else if (transData.donationData.donationFor === "individual") {
                donationFor = "Individual";
              } else {
                donationFor = "Others";
              }
            }
            var receiptObj = {
              date: moment(transData.date).format(DATE_FORMAT),
              units: transData.donationData.units.toUpperCase(),
              amount: transData.amount,
              paymentId: transData.transactionId,
              receiptId: transData.transactionNumber,
              address:
                transData.donationData && transData.donationData.billingAddress,
              donationFor: donationFor,
            };
            if (transData.donationData.donationFor === "tal") {
              receiptObj.donatedTo = "Touch-A-Life Foundation";
            } else if (transData.donationData.donationFor === "individual") {
              receiptObj.donatedTo = transData.donationData.toUserInfo.username;
            } else {
              receiptObj.donatedTo =
                transData.donationData.donation_request_info.title;
            }
            if (transData.userId) {
              receiptObj.name =
                transData.userId.name.first_name +
                " " +
                transData.userId.name.last_name;
              receiptObj.userMail = transData.userId.email;
              receiptObj.phone = transData.userId.phone;
            } else {
              if (
                transData.donationData.externalUserInfo === "" ||
                transData.donationData.externalUserInfo === null
              ) {
                receiptObj.name = "Anonymous";
                receiptObj.userMail = "";
              } else {
                var getName = transData.donationData.externalUserInfo.name;
                receiptObj.name = getName === "" ? "Anonymous" : getName;
                receiptObj.userMail =
                  transData.donationData.externalUserInfo.email;
              }
            }
            if (transData.paymentMethod === "stripe") {
              receiptObj.paymentMethod = "card";
            } else {
              receiptObj.paymentMethod = transData.paymentMethod;
            }

            setReceiptData(receiptObj);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  }, []);
  return (
    <div className="thank-container  thankyou-page">
      <Container maxWidth="lg">
        <div className="drop-box">
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "Grid", placeContent: "center" }}
            >
              <Img
                src="/images/payment-image.jpg"
                alt="successfullppayment"
                width="100%"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "Grid", placeContent: "center" }}
            >
              <div style={{ padding: "20px" }}>
                <Typography style={{ textAlign: "center" }}>
                  <CheckCircleIcon
                    style={{ color: "green", fontSize: "30px" }}
                  />
                </Typography>
                <Typography className="success-text">
                  Payment successful!
                </Typography>
                <Typography className="amount">
                  {"Donated amount : "} {formatNumber(response.amount)}{" "}
                  {response &&
                    response.currency &&
                    response.currency.toUpperCase()}
                  <br />
                  <small>(plus 3% bank transaction charges)</small>
                </Typography>
                <Typography className="trans-num">
                  {response.transactionNumber}
                </Typography>
                <Typography
                  className="kindness-text"
                  style={{ padding: "8px 20px" }}
                >
                  {authUser && (
                    <span style={{ fontSize: "1.rem", fontWait: "600" }}>
                      Congratulations on winning {response.score} kindness
                      points.
                    </span>
                  )}
                </Typography>
                <Typography style={{ textAlign: "center" }}>
                  <Button className="home-btn" onClick={handleHomepage}>
                    Continue
                  </Button>
                </Typography>

                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <button onClick={(e) => handlePrintModal()}>
                    <Tooltip title="Print the Receipt">
                      <PrintIcon style={{ cursor: "pointer" }} />
                    </Tooltip>
                  </button>
                </div>
                <div>
                  <PrintReceiptModal
                    receiptData={receiptData}
                    isOpen={isOpenPrintModal}
                    onClose={(e) => setIsOpenPrintModal(false)}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(ThankYou);

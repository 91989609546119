import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TurnedInRoundedIcon from "@material-ui/icons/TurnedInRounded";
import { appContext } from "../../App";
import AddIcon from "@material-ui/icons/Add";
import { numberWithCommas, AVATAR_DONEE, HOME_PAGE } from "../../utils/utils";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Box from "@material-ui/core/Box";
import "./Sidemenu.scss";
import { Tooltip } from "@material-ui/core";
import Organization from "../Organization/Organization";
import Contacts from "../Contacts/Contacts";
import ContactGroups from "../ContactGroups/ContactGroups";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    // borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
}));

const SideMenu = (props) => {
  const {
    authUser,
    userSummary,
    setAvatar,
    isSuperAdmin,
    isAdmin,
    isFinAdmin,
    isTALRadioAdmin,
    isVolunteer,
    orgList,
    isTalradioAdmin,
  } = useContext(appContext);
  const classes = useStyles();
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [radioMenu, setRadioMenu] = useState("");
  const [totalMyDashboardCount, setTotalMyDashboardCount] = useState(0);
  const handleEditProfile = () => {
    setRadioMenu("");
    props.history.push(`/newUserProfile/${authUser.unique_id}`);
  };

  const showFavourites = () => {
    setRadioMenu("");
    props.history.push("/myfavourites");
  };

  const showMyDashboard = () => {
    setRadioMenu("");
    props.history.push("/myDashboard");
  };

  const handleOrgDetails = (orgData) => {
    setRadioMenu("");
    if (orgData.orgId) {
      props.history.push(`/organization/${orgData.orgId._id}`);
    }
  };

  const handleOrganization = () => {
    setRadioMenu("");
    setOpenOrgModal(true);
  };

  const CreateChatSession = () => {
    setRadioMenu("");
    props.history.push("/createAudioRoom");
  };

  const displayChatRooms = (id) => {
    setRadioMenu("");
    props.history.push("/chatTabs");
  };

  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    setRadioMenu("");
    props.history.push(`/organization/${orgId}`);
  };
  const handleTALRadioMenu = (id) => {
    setRadioMenu(id);
    props.history.push(`/talradioAdmin/manageTALRadio/tal-radio/${id}`);
  };

  useEffect(() => {
    if (userSummary) {
      let total = 0;
      userSummary &&
        userSummary.myApplications &&
        userSummary.myApplications.requestTypes &&
        userSummary.myApplications.requestTypes.forEach((requestType) => {
          if (
            ["board member", "podcast", "eventSpeaker", "mentoring"].includes(
              requestType._id
            )
          ) {
            total += requestType.count;
          }
        });
      setTotalMyDashboardCount(total);
    }
  }, [userSummary]);

  if (authUser && authUser.unique_id) {
    return (
      <div className="sidemenu-container tal_radio_menu">
        <div className="profile-block drop-box">
          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="user-details-cont">
              <div className="user-details">
                <p className="user-name">
                  <img
                    src={
                      authUser && authUser.profile_image_url !== ""
                        ? authUser.profile_image_url
                        : "/images/no-photo-available.png"
                    }
                    alt="user profile"
                    className="user-profile-image"
                  />
                  <p>
                    <span style={{ fontSize: "14px" }}>
                      Welcome, {authUser && authUser.name.first_name}
                    </span>
                    <span onClick={handleEditProfile}>Edit Profile</span>
                  </p>
                </p>
              </div>
            </div>
          </Paper>
          <hr className="hr-line" />
          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="personal-links-cont">
              <ul>
                <li style={{ fontWeight: 500 }}>
                  <span>Kindness Score</span>
                  <span style={{ color: "#000" }}>
                    {userSummary && numberWithCommas(userSummary.kindnessScore)}
                  </span>
                </li>
                <li onClick={showFavourites} style={{ fontWeight: 500 }}>
                  <span>Favourites</span>
                  <span>
                    {userSummary &&
                      userSummary.favorites &&
                      userSummary.favorites.total}
                  </span>
                </li>
                <li style={{ fontWeight: 500 }} onClick={showMyDashboard}>
                  <span>My Dashboard</span>
                  <span style={{ color: "#000" }}>{totalMyDashboardCount}</span>
                </li>
              </ul>
            </div>
          </Paper>
          <hr className="hr-line" />
        </div>

        <div className="side-Organization admin-block drop-box">
          <div className="myOrganizations">
            <Box component="div" className="side-box">
              <Box component="p" fontWeight="500">
                Collaborate
              </Box>
            </Box>

            <Box
              component="div"
              style={{ cursor: "pointer", padding: "5px 0px" }}
              onClick={() => window.open(`${process.env.REACT_APP_GMAIL_URL}`)}
            >
              <img src="../images/gmail-logo.png" className="g-icons" /> Gmail
            </Box>
            <Box
              component="div"
              style={{ cursor: "pointer", padding: "5px 0px" }}
              onClick={() =>
                window.open(`${process.env.REACT_APP_GOOGLESPACES_URL}`)
              }
            >
              <img src="../images/group.png" className="g-icons" /> TALLeaders
              Circle
            </Box>
          </div>
        </div>

        <div className="side-Organization admin-block drop-box">
          <div className="myOrganizations">
            <Box component="div" className="side-box">
              <Box component="p" fontWeight="500">
                Audio Rooms
              </Box>
              <Button className="drop-box">
                <Tooltip title="Create A New Audio Room">
                  <AddIcon onClick={CreateChatSession} />
                </Tooltip>
              </Button>
            </Box>

            <Box
              component="div"
              style={{ cursor: "pointer", padding: "5px 20px" }}
              onClick={() => displayChatRooms()}
            >
              View Ongoing and Future Rooms
            </Box>
          </div>
        </div>

        {(isSuperAdmin || isAdmin || isVolunteer) && (
          <div className="admin-block drop-box">
            {/* <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="Manage TALLeaders" />
              </div>
            </ListItem> */}
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={"admin-nav-options"}
            >
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/invitingATALLeader")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Invite someone to join TALLeaders Circle" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/AdminTalLeaders")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage TALLeaders" />
              </ListItem>
              <ListItem
                button
                onClick={(e) =>
                  props.history.push("/admin/AdminTALHerosAwards")
                }
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage TALHeros Awards" />
              </ListItem>
            </List>
          </div>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(SideMenu);

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputBase,
  Paper,
  TablePagination,
  Link,
  Typography,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { SearchRounded } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { withRouter } from "react-router";
import toastr from "toastr";
import { appContext } from "../App";
import { getUsers, UserSummary } from "../utils/api";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  HOME_PAGE,
  TIME_FORMAT_SECONDS,
} from "../utils/utils";
import Sidemenu from "./SideMenu/Sidemenu";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
  },
  search: {
    padding: "15px 13px 14px ",
    lineHeight: "1.5rem",
  },
}));

const options = {
  " ": "ALL",
  talgiving: "TALGiving",
  talradio: "TALRadio",
  talscouts: "TALScouts",
};

const UserList = (props) => {
  const { authUser, isAdmin, isVolunteer, isSuperAdmin, isFinAdmin } =
    useContext(appContext);

  const [userList, setUserList] = useState([]);
  const [sourceofSignupFilter, setSourceofSignupFilter] = useState(" ");
  const [isLoading, setIsLoading] = useState(false);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [startDateFormat, setStartDateFormat] = useState(
    moment().subtract(7, "d").startOf("day").format("x")
  );
  const [endDateFormat, setEndDateFormat] = useState(
    moment().endOf("day").format("x")
  );
  const [focusedInput, setFocusedInput] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [totalSummary, setTotalSummary] = useState(0);
  const [talgivingSummary, setTalGivingSummary] = useState(0);
  const [talScoutsSummary, setTalScoutsSummary] = useState(0);
  const [talRadioSummary, setTalRadioSummary] = useState(0);
  const [select, setSelect] = useState("");
  const userCount = () => {
    UserSummary()
      .then((response) => {
        if (response.data) {
          setTotalSummary(response.data);
          setTalGivingSummary(response.data.talgiving);
          console.log("hello", response.data.talgiving);
          setTalRadioSummary(response.data.talradio);
          setTalScoutsSummary(response.data.talscouts);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching users Summary" + error.message);
      });
  };
  useEffect(() => {
    userCount();
  }, []);

  const loadUserList = (page = 0) => {
    setIsLoading(true);
    getUsers(query, rowsPerPage, page * rowsPerPage, null)
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);

          const userListArray = [];
          userList.map((data) => {
            const obj = data;
            userListArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            responseArray.push(obj);
            return null;
          });
          setUserList([...userListArray, ...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching users:" + error.message);
      });
  };

  const handleChangePage = (event, newPage) => {
    loadUserList(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const initialFilters = () => {
    setSourceofSignupFilter("");
    setKeyword("");
    setPage(0);
    var sDate = moment().subtract(7, "d");
    var eDate = moment();
    setStartDate(sDate);
    setEndDate(eDate);
    setStartDateFormat(sDate.format("x"));
    setEndDateFormat(eDate.format("x"));
    var queryStr = `startDate=${sDate.format("x")}&endDate=${eDate.format(
      "x"
    )}`;
    setQuery(queryStr);
  };

  useEffect(() => {
    var queryStr = `startDate=${startDateFormat}&endDate=${endDateFormat}`;
    setQuery(queryStr);
  }, []);

  useEffect(() => {
    if (query) {
      loadUserList();
    }
  }, [query]);

  const onSourceofSignupChange = (event) => {
    setSourceofSignupFilter(event.target.value);
  };

  useEffect(() => {
    if (authUser) {
      if (isAdmin || isVolunteer || isSuperAdmin) {
      } else {
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  }, [authUser]);

  const handleDateChange = (fromDate, toDate) => {
    if (fromDate != null) {
      setStartDateFormat(moment(fromDate._d).format("x"));
      setStartDate(fromDate);
      setEndDate(null);
    }
    if (toDate != null) {
      setEndDateFormat(moment(toDate._d).format("x"));
      setEndDate(toDate);
    }
  };

  const classes = useStyles();

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const onKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleApply = () => {
    var queryStr = `keyword=${keyword}&sourceOfSignup=${
      sourceofSignupFilter && sourceofSignupFilter.trim()
    }&startDate=${startDateFormat}&endDate=${endDateFormat}`;
    setQuery(queryStr);
    setUserList([]);
    setPage(0);
  };

  const handlSummary = (
    value,
    option,
    startDateTime = null,
    endDateTime = null
  ) => {
    var queryStr = value;

    if (startDateTime) {
      queryStr = queryStr + `&startDate=${startDateTime.format("x")}`;
    } else {
      startDateTime = moment().subtract(7, "days").startOf("day");
    }

    if (endDateTime) {
      queryStr = queryStr + `&endDate=${endDateTime.format("x")}`;
    } else {
      endDateTime = moment().endOf("day");
    }

    const sourceOfSignUpList = [" ", "talgiving", "talradio", "talscouts"];

    handleDateChange(startDateTime, endDateTime);
    setKeyword("");
    setSourceofSignupFilter(sourceOfSignUpList[option]);
    setSelect(option);
    if (!queryStr) {
      setQuery("");
    } else {
      setQuery(queryStr);
    }
    setUserList([]);
    setPage(0);
  };

  const exportToCSV = () => {
    const JsonFields = [
      "DATE",
      "NAME",
      "INSTITUTION NAME",
      "STUDENT ID",
      "EMAIL",
      "CONTACT NUMBER",
      "SOURCE OF SIGNUP",
      "ID",
      "Email Verified ",
    ];
    var csvStr = JsonFields.join(",") + "\n";
    userList.forEach((element) => {
      let {
        createdAt,
        name,
        institutionName,
        studentId,
        email,
        phone,
        sourceOfSignup,
        email_Verified,
        account_verified,
      } = element;

      if (!name) {
        name = "";
      }
      if (!institutionName) {
        institutionName = "";
      }
      if (!studentId) {
        studentId = "";
      }

      if (!email) {
        email = "";
      }

      if (!phone) {
        phone = "";
      }

      if (!sourceOfSignup) {
        sourceOfSignup = "";
      }

      if (!createdAt) {
        createdAt = "";
      }
      if (!email_Verified) {
        email_Verified = "";
      }
      if (!account_verified) {
        account_verified = "";
      }

      csvStr +=
        moment(createdAt).format(DATE_TIME_FORMAT) +
        "," +
        name.first_name +
        "" +
        name.last_name +
        "," +
        institutionName +
        "," +
        studentId +
        "," +
        email +
        "," +
        phone +
        "," +
        sourceOfSignup +
        "," +
        email_Verified +
        "," +
        "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = `Users-Report-${moment().format(
      `${DATE_FORMAT}-${TIME_FORMAT_SECONDS}`
    )}.csv`;
    hiddenElement.click();
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="barnav">
        <Container MaxWidth="lg">
          <div className="search-right">
            <div style={{ display: "flex" }}>
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4 className="image-title">Users List</h4>
            </div>
            <div
              style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
              className="date_br"
            >
              <FormControl className={classes.mt10}>
                <DateRangePicker
                  className={classes.br}
                  displayFormat={DATE_FORMAT}
                  startDate={startDate}
                  startDateId="start_date_id"
                  endDate={endDate}
                  endDateId="end_date_id"
                  startDatePlaceholderText="From"
                  endDatePlaceholderText="To"
                  onDatesChange={({ startDate, endDate }) =>
                    handleDateChange(startDate, endDate)
                  }
                  isOutsideRange={() => false}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  customClearIcon={true}
                />
              </FormControl>
              <div className="DateRangePickerInput__withBorder">
                <SearchRounded style={{ margin: "0 4px", height: "50px" }} />
                <Tooltip title="Enter Email/Phone/First Name/Last Name/Institution Name">
                  <InputBase
                    placeholder="Enter Email/Phone/First Name/Last Name/Institution Name"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    width="200px"
                    value={keyword}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => onKeywordChange(e)}
                  />
                </Tooltip>
              </div>
              <FormControl
                variant="filled"
                className={classes.formcontrol}
                style={{ width: "150px" }}
              >
                <InputLabel htmlFor="Select Payment mode">
                  Source of Signup
                </InputLabel>
                <Select
                  variant="outlined"
                  name="sourceOfSignup"
                  value={sourceofSignupFilter}
                  label="sourceOfSignup"
                  onChange={(e) => onSourceofSignupChange(e)}
                >
                  <MenuItem value=" ">All</MenuItem>
                  <MenuItem value="talgiving">TALGiving</MenuItem>
                  <MenuItem value="talscouts">TALScouts</MenuItem>
                  <MenuItem value="talradio">TALRadio</MenuItem>
                </Select>
              </FormControl>
              <Button
                style={{ backgroundColor: "#ef6c00", color: "white" }}
                onClick={handleApply}
                ontype="submit"
              >
                Apply
              </Button>
              <Button
                style={{ backgroundColor: "#ef6c00", color: "white" }}
                onClick={initialFilters}
              >
                Clear
              </Button>
              <Button
                startIcon={<FileCopyOutlinedIcon />}
                onClick={exportToCSV}
                style={{
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  height: "55px",
                  margin: "0",
                  backgroundColor: "var(--tal_primary) ",
                }}
              >
                Export Data
              </Button>
            </div>
          </div>
        </Container>
      </div>

      <Container MaxWidth="lg">
        <h5>
          For detailed information please select the respective tab(Total, Year,
          Halfyear, Quarter, Month, Today).
        </h5>
        <Grid className="adminlist" style={{ gridGap: "10px" }}>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            className="tltusers"
            style={{
              border: select === 0 ? "4px solid #7b868c" : "",
            }}
          >
            <div className="tltcnt">
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup`,
                      0,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  Total Users
                </Link>
              </h4>
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup`,
                      0,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  {totalSummary.total}
                </Link>
              </h4>
            </div>
            <table className="tlttable">
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup`,
                        0,
                        moment().subtract(1, "years").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Year : <span>{totalSummary.year}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup`,
                        0,
                        moment().add(-6, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    HalfYear : <span> {totalSummary.halfYear}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup`,
                        0,
                        moment().add(-3, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Quarter :<span>{totalSummary.quarter}</span>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup`,
                        0,
                        moment().startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Today : <span>{totalSummary.today}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup`,
                        0,
                        moment().add(-1, "weeks").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Week : <span>{totalSummary.week}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup`,
                        0,
                        moment().add(-1, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Month : <span>{totalSummary.month}</span>
                  </Link>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            className="tltusers"
            style={{
              border: select === 1 ? "4px solid #7b868c" : "",
            }}
          >
            <div className="tltcnt">
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup=talgiving`,
                      1,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  TALGiving
                </Link>
              </h4>
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup=talgiving`,
                      1,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  {talgivingSummary.total}
                </Link>
              </h4>
            </div>
            <table className="tlttable">
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talgiving`,
                        1,
                        moment().add(-1, "years").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Year : <span>{talgivingSummary.year}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talgiving`,
                        1,
                        moment().add(-6, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    HalfYear : <span> {talgivingSummary.halfYear}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talgiving`,
                        1,
                        moment().add(-3, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Quarter : <span> {talgivingSummary.quarter}</span>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talgiving`,
                        1,
                        moment().startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Today : <span>{talgivingSummary.today}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talgiving`,
                        1,
                        moment().add(-1, "weeks").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Week : <span>{talgivingSummary.week}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talgiving`,
                        1,
                        moment().add(-1, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Month : <span>{talgivingSummary.month}</span>
                  </Link>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            className="tltusers"
            style={{
              border: select === 2 ? "4px solid #7b868c" : "",
            }}
          >
            <div className="tltcnt">
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup=talradio`,
                      2,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  TALRadio
                </Link>
              </h4>
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup=talradio`,
                      2,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  {talRadioSummary.total}
                </Link>
              </h4>
            </div>
            <table className="tlttable">
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talradio`,
                        2,
                        moment().add(-1, "years").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Year : <span>{talRadioSummary.year}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talradio`,
                        2,
                        moment().add(-6, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    HalfYear : <span> {talRadioSummary.halfYear}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talradio`,
                        2,
                        moment().add(-3, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Quarter : <span> {talRadioSummary.quarter}</span>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talradio`,
                        2,
                        moment().startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Today : <span>{talRadioSummary.today}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talradio`,
                        2,
                        moment().add(-1, "weeks").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Week : <span>{talRadioSummary.week}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talradio`,
                        2,
                        moment().add(-1, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Month : <span>{talRadioSummary.month}</span>
                  </Link>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            className="tltusers"
            style={{
              border: select === 3 ? "4px solid #7b868c" : "",
            }}
          >
            <div className="tltcnt">
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup=talscouts`,
                      3,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  TALScouts
                </Link>
              </h4>
              <h4>
                <Link
                  onClick={(e) => {
                    handlSummary(
                      `sourceOfSignup=talscouts`,
                      3,
                      moment().set({ year: 2019, month: 4, date: 29 }),
                      moment().endOf("day")
                    );
                  }}
                >
                  {talScoutsSummary.total}
                </Link>
              </h4>
            </div>
            <table className="tlttable">
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talscouts`,
                        3,
                        moment().add(-1, "years").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Year : <span>{talScoutsSummary.year}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talscouts`,
                        3,
                        moment().add(-6, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    HalfYear : <span> {talScoutsSummary.halfYear}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talscouts`,
                        3,
                        moment().add(-3, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Quarter : <span>{talScoutsSummary.quarter}</span>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talscouts`,
                        3,
                        moment().startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Today : <span>{talScoutsSummary.today}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talscouts`,
                        3,
                        moment().add(-1, "weeks").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Week : <span>{talScoutsSummary.week}</span>
                  </Link>
                </td>
                <td>
                  <Link
                    onClick={(e) => {
                      handlSummary(
                        `sourceOfSignup=talscouts`,
                        3,
                        moment().add(-1, "months").startOf("day"),
                        moment().endOf("day")
                      );
                    }}
                  >
                    Month : <span>{talScoutsSummary.month}</span>
                  </Link>
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Container style={{ marginBottom: "16px", width: "100%" }}>
          <Paper className={classes.search}>
            <h5>Search criteria: </h5>
            Date Range:{" "}
            {`${moment(startDate).format(DATE_FORMAT)} to ${moment(
              endDate
            ).format(DATE_FORMAT)}`}{" "}
            <br />
            Email/Phone/First Name/Last Name/Institution Name : {keyword} <br />
            Source Of Signup: {options[sourceofSignupFilter]}
          </Paper>
        </Container>
      </div>
      <Container MaxWidth="lg">
        <div className="myDonations-container">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            )}
            <div style={{ width: "100%", overflowX: "auto" }}>
              <div className="table-fixed drop-box">
                <Box className={classes.table_tab}>
                  <TableContainer component={Paper}>
                    <Table aria-label="UserActivityLog Table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Institution Name</TableCell>
                          <TableCell>Student ID</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Contact Number</TableCell>
                          <TableCell>Source Of Signup</TableCell>
                          <TableCell>Email Verified ?</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userList &&
                          userList.length > 0 &&
                          userList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow key={row._id}>
                                <TableCell>
                                  {moment(row.createdAt).format(
                                    DATE_TIME_FORMAT
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Link
                                    href={
                                      process.env.REACT_APP_BASE_URL +
                                      "/newUserProfile/" +
                                      row._id
                                    }
                                    target="blank"
                                  >
                                    {row.name.first_name} {row.name.last_name}
                                  </Link>
                                </TableCell>
                                <TableCell> {row.institutionName}</TableCell>
                                <TableCell> {row.studentId}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.phone}</TableCell>

                                <TableCell>
                                  {options[row.sourceOfSignup]}
                                </TableCell>

                                <TableCell>
                                  {row.account_verified === true
                                    ? "Verified"
                                    : "Not Verified"}
                                </TableCell>
                              </TableRow>
                            ))}
                        {isLoading && (
                          <p style={{ textAlign: "center" }}>Loading...</p>
                        )}
                        {!isLoading && userList && userList.length === 0 && (
                          <Typography>No Records Found</Typography>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default withRouter(UserList);

import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import { Grid, Typography, TextField } from "@material-ui/core";
import validateAddress from "./validateAddress";
import useSpecialFormValidation from "../../hooks/useSpecialFormValidation";
import { getAllCountries, getAllStates, getAllCities } from "../../utils/api";
import { appContext } from "../../App";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import "./styles.scss";
import { getCountry } from "../../utils/utils";
import cssstyle from "./NewAddress.module.scss";
const NewAddress = (props) => {
  const { regions, setRegion } = useContext(appContext);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [shouldUpdateValues, setShouldUpdateValues] = useState(true);

  const filter = createFilterOptions();

  const handleChangeSelectedCountry = (event, newValue) => {
    if (newValue) {
      values.country = newValue.name;
      getAllStates(newValue.id)
        .then((result) => {
          setStates(result.data);
        })
        .catch((error) => {
          toastr.error("Problem in getting states: " + error.message);
        });

      if (values.country === "India") {
        setRegion(regions.find((region) => region.name === "India"));
      } else {
        setRegion(regions.find((region) => region.name === "United States"));
      }
    } else {
      values.country = newValue;
    }
    onConfirm();
  };

  const handleChangeSelectedState = (event, newStateValue) => {
    if (typeof newStateValue === "string") {
      values.state = newStateValue;
    } else if (newStateValue && newStateValue.name) {
      // Create a new value from the user input
      values.state = newStateValue.name;
      getAllCities(newStateValue.id)
        .then((result) => {
          setCities(result.data);
        })
        .catch((error) => {
          toastr.error("Problem in getting cities: " + error.message);
        });
    } else {
      values.state = newStateValue;
    }
    onConfirm();
  };

  const handleChangeSelectedCity = (event, newCityValue) => {
    if (typeof newCityValue === "string") {
      values.city = newCityValue;
    } else if (newCityValue && newCityValue.name) {
      // Create a new value from the user input
      values.city = newCityValue.name;
    } else {
      values.city = newCityValue;
    }
    onConfirm();
  };

  useEffect(() => {
    getAllCountries().then((result) => {
      const countryData = getCountry(result);
      countryData && setCountries(countryData);
      if (props.value) {
        //DO NOT DELETE
        // if (props.value.country === "India") {
        //   setRegion(regions.find((region) => region.name === "India"));
        // } else {
        //   setRegion(regions.find((region) => region.name === "United States"));
        // }
        //=====
        if (props.value.country && result.data) {
          getAllStates(
            result.data.filter(
              (country) => country.name === props.value.country
            )[0].id
          )
            .then((result) => {
              setStates(result.data);

              getAllCities(
                result.data.filter(
                  (state) => state.name === props.value.state
                )[0].id
              )
                .then((result) => {
                  setCities(result.data);
                })
                .catch((error) => {
                  //toastr.error("Problem in getting cities: " + error.message);
                });
            })
            .catch((error) => {
              // toastr.error("Problem in getting states: " + error.message);
            });
        }
      }
    });

    if (props && props.value && shouldUpdateValues) {
      setValues({
        line1: props.value.line1,
        line2: props.value.line2,
        city: props.value.city,
        state: props.value.state,
        country: props.value.country,
        zip_code: props.value.zip_code,
      });
      setShouldUpdateValues(false);
    }
  }, [props.value]);

  const initialState = {
    line1: props.value && props.value.line1,
    line2: props.value && props.value.line2,
    city: props.value && props.value.city,
    state: props.value && props.value.state,
    country: props.value && props.value.country,
    zip_code: props.value && props.value.zip_code,
  };

  const onConfirm = () => {
    const address = {
      line1: values.line1,
      line2: values.line2,
      city: values.city,
      state: values.state,
      country: values.country,
      zip_code: values.zip_code,
    };
    props.onChange(address);
  };

  const { values, setValues, errors, changeHandler } = useSpecialFormValidation(
    initialState,
    validateAddress,
    onConfirm
  );

  return (
    <Grid container spacing={2} className="newAddr-container">
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          id="line1"
          name="line1"
          autoComplete="new-password"
          className={
            cssstyle.plasma_input +
            "  " +
            `input-field ${
              props && props.error && !values.line1
                ? cssstyle.bg_red
                : values.line1
                ? cssstyle.bg_green
                : cssstyle.bg_normal
            }`
          }
          placeholder="Address Line1"
          disabled={props.disabled}
          value={values.line1}
          onChange={changeHandler}
          inputProps={{
            maxLength: 50,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          id="line2"
          name="line2"
          autoComplete="new-password"
          className={
            cssstyle.plasma_input +
            "  " +
            `input-field  ${
              props && props.error && !values.line2
                ? cssstyle.bg_red
                : values.line2
                ? cssstyle.bg_green
                : cssstyle.bg_normal
            }`
          }
          placeholder="Address Line2"
          disabled={props.disabled}
          value={values.line2}
          onChange={changeHandler}
          inputProps={{
            maxLength: 50,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <Autocomplete
        className={
          cssstyle.plasma_input +
          "  " +
          `address_input ${
            props && props.error && values.country == null
              ? cssstyle.bg_red
              : values.country
              ? cssstyle.bg_green
              : cssstyle.bg_normal
          }`
        }
          value={values.country}
          onChange={handleChangeSelectedCountry}
          id="country-option"
          options={countries}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.name) {
              return option.name;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(option) => option.name}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Country"
              variant="outlined"
              id="country"
              name="country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <Autocomplete
        className={
          cssstyle.plasma_input +
          "  " +
          `address_input ${
            props && props.error && values.state == null
              ? cssstyle.bg_red
              : values.state
              ? cssstyle.bg_green
              : cssstyle.bg_normal
          }`
        }
          value={values.state}
          onChange={handleChangeSelectedState}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `${params.inputValue}`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="state-option"
          options={states}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.name) {
              return option.name;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(option) => option.name}
          style={{ width: "100%" }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select State"
              variant="outlined"
              id="state"
              name="state"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <Autocomplete
        className={
          cssstyle.plasma_input +
          "  " +
          `address_input ${
            props && props.error && values.city == null
              ? cssstyle.bg_red
              : values.city
              ? cssstyle.bg_green
              : cssstyle.bg_normal
          }`
        }
          value={values.city}
          onChange={handleChangeSelectedCity}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `${params.inputValue}`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="city-option"
          options={cities}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.name) {
              return option.name;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(option) => option.name}
          style={{ width: "100%" }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select City"
              variant="outlined"
              id="city"
              name="city"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <TextField
          style={{ width: "100%" }}
          id="zip_code"
          name="zip_code"
          autoComplete="new-password"
          placeholder="Zip Code"
          variant="outlined"
          disabled={props.disabled}
          className={
            cssstyle.plasma_input +
            "  " +
            `text-field ${
              props && props.error && !values.zip_code
                ? cssstyle.bg_red
                : values.zip_code
                ? cssstyle.bg_green
                : cssstyle.bg_normal
            }`
          }
          value={values.zip_code}
          onChange={changeHandler}
          inputProps={{
            maxLength: 10,
          }}
        />
        {errors && errors.zip_code && (
          <Typography style={{ color: "red" }}>{errors.zip_code}</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          {values.line1} {values.line1 && ", "} {values.line2}{" "}
          {values.line2 && ", "} {values.city} {values.city && ", "}{" "}
          {values.state}
          {values.state && ", "} {values.country}
          {values.country && ", "} {values.zip_code}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NewAddress;

import React, { useEffect, useContext, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Container from "@material-ui/core/Container";
import Organization from "../Organization/Organization";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PodcastVideoPlayer from "../common/PodcastVideoPlayer";
import OrgDetailsSideBar from "./orgDetailsSidebar";
import DonationRequestCard from "../donationRequest/DonationRequestCard";
import AddPhotos from "../common/AddPhotos";
import firebase from "../../firebase/firebase";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import StickyBox from "react-sticky-box";
import {
  getOrgdetails,
  deleteOrganization,
  inviteMember,
  removeUser,
  changeUserRole,
  updateOrganizationStatus,
  getOrgDonationRequests,
} from "../../utils/api";
import { appContext } from "../../App";
import toastr from "toastr";
import PaymentsPopUp from "../donationDetails/PaymentsPopUp";
import validator from "validator";
import "./OrgDetails.scss";
import { ROLE_ADMIN, ROLE_OWNER, TALRADIO_SITE } from "../../utils/utils";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    overflowX: "auto",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  inviteBtn: {
    background: "var(--tal_primary) ",
    margin: "10px 0",
    fontSize: 14,
    color: "#fff",
    fontWeight: 600,
    "&:hover": {
      background: "var(--tal_primary) ",
    },
  },
  editButton: {
    margin: 8,
    "&:hover": {
      backgroundColor: "rgb(33, 44, 111) !important",
    },
  },
  delButton: {
    margin: 8,
  },
}));

const OrgDetails = (props) => {
  const { authUser, region, orgList, setOrgList } = useContext(appContext);
  const classes = useStyles();
  const defaultRequestTypesToGetUserRequests = JSON.stringify([
    { request_type: "board member" },
    { request_type: "podcast" },
    { request_type: "eventSpeaker" },
    { request_type: "mentoring" },
  ]);
  const [topDonationRequests] = useState([]);
  const [donationRequests, setDonationRequests] = useState([]);
  const [showCoverPhoto, setShowCoverPhoto] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [showProfilePhoto, setShowProfilePhoto] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [userData] = useState(null);
  const [editMode] = useState(false);
  const [members, setMembers] = useState(true);
  const [selectedOption, setSelectedOption] = useState("timeline");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isPaymentsPopUpOpen, setIsPaymentsPopUpOpen] = useState(false);
  const [noUser] = useState(false);

  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [type, setType] = useState("Select role");
  const [refresh, setRefresh] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [orgDetails, setOrgDetails] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      handleOrgDetails();
      loadDonationRequests(props.match.params.id);
    }
  }, [props.match.params.id, refresh]);

  const handleOrgDetails = () => {
    getOrgdetails(
      props && props.match && props.match.params && props.match.params.id
    )
      .then((resp) => {
        setOrgDetails(resp);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const loadDonationRequests = (orgId) => {
    getOrgDonationRequests(
      orgId,
      "1,2",
      region && region._id,
      "",
      "",
      defaultRequestTypesToGetUserRequests,
      authUser && authUser.unique_id
    )
      .then((response) => {
        setDonationRequests(response.data);
      })
      .catch((error) => {
        toastr.error("Error getting donation requests:" + error.message);
      });
  };

  const handleuserDetails = (e, user) => {
    user === "email" && setEmail(e.target.value);
    user === "type" && setType(e.target.value);
  };
  const handleInvite = () => {
    const values = {};
    const errors = {};
    values.userId = authUser.unique_id;
    values.role = type;
    values.email = email;
    values.isDefault = true;
    if (email === "") {
      errors.email = "Please enter email";
    }
    if (email && !validator.isEmail(email)) {
      errors.email = "Please enter valid  email";
    }
    if (type === "Select role") {
      errors.type = "Please select role";
    }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      return;
    } else {
      inviteMember(orgDetails[0]._id, values).then((resp) => {
        if (resp.data.statusCode === 200) {
          toastr.success(resp.data.message);
          setType("Select role");
          setEmail("");
          setOpenModal(false);
        }
      });
    }
  };

  const handleDeleteOrg = (org) => {
    if (window.confirm("Are you sure you want to delete the organization?")) {
      const userId = org && org.user_info && org.user_info._id;
      if (userId) {
        deleteOrganization(props.match.params.id)
          .then((resp) => {
            if (resp && resp.data && resp.data.statusCode === 200) {
              setOrgList(
                orgList.filter(
                  (org) =>
                    org && org.orgId && org.orgId._id !== props.match.params.id
                )
              );
              toastr.success(resp.data.message);
              props.history.push(`/newUserProfile/${authUser.unique_id}`);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
      }
    }
  };

  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (
      authUser &&
      orgDetails &&
      orgDetails.users &&
      orgDetails.users.length > 0
    ) {
      orgDetails.users.forEach((user) => {
        if (user.userId._id === authUser.unique_id) {
          if (user.role === ROLE_OWNER || user.role === ROLE_ADMIN) {
            setIsAdmin(true);
          }
        }
      });
    }
    loadImages(orgDetails && orgDetails[0] && orgDetails[0]._id);
    loadVideos(orgDetails && orgDetails[0] && orgDetails[0]._id);
    setCoverImageUrl(
      orgDetails && orgDetails[0] && orgDetails[0].coverImageUrl
    );
    setProfileImageUrl(
      orgDetails && orgDetails[0] && orgDetails[0].defaultImageUrl
    );
  }, [orgDetails, authUser]);

  const loadImages = (uid) => {
    setImages([]);
    const path = `Org/${uid}/images`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          item.getMetadata().then((metadata) => {
            setImages((prevImages) => [
              ...prevImages,
              { url: url, name: metadata.name },
            ]);
          });
        });
      });
    });
  };

  const loadVideos = (uid) => {
    setVideos([]);
    const path = `Org/${uid}/videos`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              setVideos((prevVideos) => [
                ...prevVideos,
                {
                  url: url,
                  name: metadata.name,
                  userGivenName: metadata.customMetadata.userGivenName,
                  note: metadata.customMetadata.note,
                },
              ]);
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        });
      });
    });
  };

  useEffect(() => {
    if (userData && userData.unique_id) {
      if (selectedOption === "timeline") {
      } else if (selectedOption === "photos") {
      } else if (selectedOption === "videos") {
      }
    }
  }, [selectedOption]);

  const updateDefaultImageUrl = (url) => {
    setProfileImageUrl(url);
    const finalValues = {
      defaultImageUrl: url,
    };
    updateOrganizationStatus(
      orgDetails && orgDetails[0] && orgDetails[0]._id,
      finalValues
    )
      .then((response) => {})
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const updateCoverImageUrl = (url) => {
    setCoverImageUrl(url);
    const finalValues = {
      coverImageUrl: url,
    };
    updateOrganizationStatus(
      orgDetails && orgDetails[0] && orgDetails[0]._id,
      finalValues
    )
      .then((response) => {})
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const onPlayVideo = (document) => {
    setIsPlayingVideo(true);
  };

  const onClosePlayVideo = () => {
    setIsPlayingVideo(false);
  };

  const donationRequestClickHandler = (id) => {
    props.history.push(`/donationRequest/${id}`);
  };

  const handleAddMember = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setErrors({});
    setEmail("");
    setType("Select role");
  };

  const handleOrgUser = (type, org) => {
    if (type === "remove") {
      orgDetails &&
        orgDetails[0] &&
        removeUser(orgDetails[0]._id, org.userId._id)
          .then((resp) => {
            if (resp.data.statusCode === 200) {
              toastr.success(resp.data.message);
              setRefresh(!refresh);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
    }
    if (type === "role") {
      const finalValues = {};
      finalValues.userId = org.userId._id;
      if (org.role === "member") {
        finalValues.role = "admin";
      } else {
        finalValues.role = "member";
      }
      finalValues.orgEmailAddress = org.email;
      orgDetails &&
        orgDetails[0] &&
        changeUserRole(orgDetails[0]._id, org.userId._id, finalValues)
          .then((resp) => {
            if (resp.data.statusCode === 200) {
              toastr.success(resp.data.message);
              setRefresh(!refresh);
              if (org && org.userId._id === authUser.unique_id) {
                setSelectedOption("timeline");
                setMembers(false);
              }
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
    }
  };

  const viewAllPhotosHandler = () => {
    setSelectedOption("photos");
  };

  const handleOrganization = () => {
    setOpenOrgModal(true);
    setEdit(true);
  };

  const viewAllVideosHandler = () => {
    setSelectedOption("videos");
  };
  let org = orgDetails && orgDetails[0];
  return (
    <div className="public-profile-container">
      {/* <div class="white"></div> */}
      <div class="public-main">
        {!noUser && (
          <Fragment>
            {/* <div className="public-bg-image"></div> */}
            <Box component="div" className="drop-box">
              <Container maxWidth="lg">
                <div className="public-cover-page">
                  {isAdmin && (
                    <div className="updateBannerBlk">
                      <CameraAltIcon
                        onClick={() => setShowCoverPhoto(true)}
                        className="updateIcon"
                      />
                    </div>
                  )}
                  <div className="head-img">
                    <img
                      src={
                        coverImageUrl
                          ? coverImageUrl
                          : "/images/celeb-profile-bg.jpeg"
                      }
                      className="cover-pic"
                      alt="cover-pic"
                      width="100%"
                    />
                  </div>

                  <CardHeader
                    className="public-card-header"
                    avatar={
                      <div className="profile-pic-container">
                        <div className="profile-main">
                          {isAdmin && (
                            <div className="profileCamIcon">
                              <CameraAltIcon
                                onClick={() => setShowProfilePhoto(true)}
                                className="updateIcon"
                              />
                            </div>
                          )}
                          <div className="pulic-propic">
                            <img
                              src={
                                profileImageUrl
                                  ? profileImageUrl
                                  : "/images/orgLogo.png"
                              }
                              alt="Profile Pic"
                              className="profile-pic"
                            />
                          </div>
                        </div>
                        <h2>
                          {org && org.orgName}{" "}
                          <VerifiedUser
                            style={{ color: "green", fontSize: 18 }}
                          />
                        </h2>
                        {/* <Button
                      className="public-cause-btn"
                      onClick={(e) => setIsPaymentsPopUpOpen(true)}
                    >
                      Donate To My Causes
                    </Button> */}
                      </div>
                    }
                    title={
                      <div className="borderBottom-nav">
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          className={`${classes.root} public-page-navbar`}
                        >
                          <ListItem button className="options">
                            <ListItemText
                              primary="Timeline"
                              style={{
                                color:
                                  selectedOption === "timeline"
                                    ? "#3C95E8"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "timeline"
                                    ? "4px solid #3C95E8"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 16,
                                margin: 0,
                                // paddingLeft: "10px",
                                // paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("timeline")}
                            />
                          </ListItem>
                          <ListItem button className="options">
                            <ListItemText
                              primary="About"
                              style={{
                                color:
                                  selectedOption === "about"
                                    ? "#3C95E8"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "about"
                                    ? "4px solid #3C95E8"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 15,
                                margin: 0,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("about")}
                            />
                          </ListItem>
                          <ListItem button className="options">
                            <ListItemText
                              primary="Photos"
                              style={{
                                textAlign: "center",
                                color:
                                  selectedOption === "photos"
                                    ? "#3C95E8"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "photos"
                                    ? "4px solid #3C95E8"
                                    : "none",
                                paddingBottom: 15,
                                margin: 0,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("photos")}
                            />
                          </ListItem>
                          <ListItem button className="options">
                            <ListItemText
                              primary="Videos"
                              style={{
                                color:
                                  selectedOption === "videos"
                                    ? "#3C95E8"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "videos"
                                    ? "4px solid #3C95E8"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 15,
                                margin: 0,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("videos")}
                            />
                          </ListItem>
                          {isAdmin && members && (
                            <ListItem button className="options">
                              <ListItemText
                                primary="Members"
                                style={{
                                  color:
                                    selectedOption === "members"
                                      ? "#3C95E8"
                                      : "#000",
                                  borderBottom:
                                    selectedOption === "members"
                                      ? "4px solid #3C95E8"
                                      : "none",
                                  textAlign: "center",
                                  paddingBottom: 15,
                                  margin: 0,
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                                onClick={(e) => setSelectedOption("members")}
                              />
                            </ListItem>
                          )}
                        </List>

                        <div button className="share-option">
                          <div>
                            <div className="share-cont">
                              {isAdmin && (
                                <div style={{ fontSize: 14 }} button>
                                  <div style={{ textAlign: "end" }}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={classes.editButton}
                                      startIcon={<EditIcon />}
                                      onClick={handleOrganization}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{ background: "rgb(220, 0, 78)" }}
                                      className={classes.delButton}
                                      onClick={() =>
                                        handleDeleteOrg(orgDetails[0])
                                      }
                                      startIcon={<DeleteIcon />}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </Container>
            </Box>
          </Fragment>
        )}
        {selectedOption === "timeline" && (
          <div className="public-page-body">
            <Container maxWidth="lg">
              {noUser ? (
                <h3 className="no-user-text">
                  No user found with the username...
                </h3>
              ) : (
                <Grid container spacing={2} className="public-body-grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className="public-grid-left"
                  >
                    <StickyBox
                      offsetTop={20}
                      offsetBottom={20}
                      className="drop-box"
                      style={{ padding: "15px" }}
                    >
                      <OrgDetailsSideBar
                        editMode={editMode}
                        orgData={orgDetails}
                        viewAllPhotos={viewAllPhotosHandler}
                        viewAllVideos={viewAllVideosHandler}
                        images={images}
                        videos={videos}
                        isAdmin={isAdmin}
                        onSavingImage={(e) => loadImages(orgDetails[0]._id)}
                        onSavingVideo={(e) => loadVideos(orgDetails[0]._id)}
                      />
                    </StickyBox>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="public-grid-right"
                  >
                    <Fragment>
                      {donationRequests && donationRequests.length > 0 ? (
                        donationRequests.map((donationRequest, index) => {
                          return (
                            <DonationRequestCard
                              donationRequest={donationRequest}
                              setDonationRequests={setDonationRequests}
                              donationRequests={donationRequests}
                              key={index}
                            />
                          );
                        })
                      ) : (
                        <p className="no-req-text">No requests created</p>
                      )}
                    </Fragment>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className="public-grid-news"
                  >
                    <StickyBox
                      offsetTop={20}
                      offsetBottom={20}
                      className="right-panel"
                    >
                      <div>
                        <div
                          className=" no-pad promoBlk"
                          style={{ padding: "0" }}
                        >
                          <div className="promotion drop-box">
                            <Link href={TALRADIO_SITE} target="blank">
                              <img src="/images/radio.png" alt="promotions" />
                            </Link>
                          </div>
                        </div>
                        {topDonationRequests &&
                          topDonationRequests.length > 0 && (
                            <Paper
                              className={
                                classes.paper + " no-pad topReqListBlk"
                              }
                            >
                              <div className="topReqList">
                                <Card className={classes.root} elevation={0}>
                                  <CardContent>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      className="topReqListTitle"
                                    >
                                      Top Requests
                                    </Typography>
                                    <ul>
                                      {topDonationRequests.map(
                                        (donationRequest, index) => {
                                          return (
                                            <li key={index}>
                                              <img
                                                src={
                                                  donationRequest.defaultImageUrl
                                                    ? donationRequest.defaultImageUrl
                                                    : "/images/default-donation-request.jpg"
                                                }
                                                alt="Top Request"
                                                onClick={(e) =>
                                                  donationRequestClickHandler(
                                                    donationRequest._id
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />{" "}
                                              <span
                                                onClick={(e) =>
                                                  donationRequestClickHandler(
                                                    donationRequest._id
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                {donationRequest.title}
                                              </span>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </CardContent>
                                </Card>
                              </div>
                            </Paper>
                          )}
                      </div>
                    </StickyBox>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        )}

        {selectedOption === "about" && (
          <Container>
            <div className="public-page-body about-option">
              <div className="about-section">
                <h2>About</h2>
                <Box component="p" p={2}>
                  {org && org.description}
                </Box>
              </div>
            </div>
          </Container>
        )}

        {selectedOption === "photos" && (
          <Container>
            <div className="public-page-body photos-option">
              <div className="photos-section">
                <h2>Photos</h2>
                <Box component="div" p={2}>
                  {images.length > 0 ? (
                    images.map((image) => (
                      <div className="images-container">
                        <img src={image.url} alt={image.name} width="100%" />
                      </div>
                    ))
                  ) : (
                    <p className="no-req-text">No photos uploaded</p>
                  )}
                </Box>
              </div>
            </div>
          </Container>
        )}

        {selectedOption === "videos" && (
          <Container>
            <div className="public-page-body videos-option">
              <div className="videos-section">
                <Grid container>
                  <Grid md={6} sm={6}>
                    {" "}
                    <h2 style={{ textAlign: "left" }}>Videos</h2>
                  </Grid>
                  <Grid md={6} sm={6}>
                    <Button variant="contained" color="primary">
                      Upload Videos
                    </Button>
                  </Grid>

                  {videos.length > 0 ? (
                    videos.map((video, index) => (
                      <Grid md={3} sm={6} style={{ padding: "10px" }}>
                        <div className="images-container">
                          <PodcastVideoPlayer
                            key={index}
                            isOpen={isPlayingVideo}
                            onClose={onClosePlayVideo}
                            onPlayVideo={(e) => onPlayVideo(video)}
                            url={video.url}
                            userGivenName={video.userGivenName}
                            note={video.note}
                          />
                        </div>
                      </Grid>
                    ))
                  ) : (
                    <Grid md={12}>
                      {" "}
                      <p className="no-req-text">No videos uploaded</p>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </Container>
        )}

        {selectedOption === "members" && (
          <Container>
            <div className="public-page-body videos-option">
              <div className="videos-section">
                <div style={{ marginTop: 15 }}>
                  {isAdmin && (
                    <Button onClick={handleAddMember}>
                      + Add New Member To Organization
                    </Button>
                  )}
                  <TableContainer component="div">
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Profile</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {orgDetails.users &&
                          orgDetails.users.map((orgUser, ind) => (
                            <TableRow key={ind}>
                              <TableCell component="th" scope="row">
                                <img
                                  src={
                                    orgUser.userId && orgUser.userId.image_url
                                      ? orgUser.userId.image_url
                                      : "/images/default-profile-photo.png"
                                  }
                                  alt="user"
                                  style={{ width: 40, borderRadius: "50%" }}
                                />
                              </TableCell>
                              <TableCell>{orgUser.userId.email}</TableCell>
                              <TableCell
                                style={{ textTransform: "capitalize" }}
                              >
                                {orgUser.role}
                              </TableCell>
                              <TableCell>
                                {orgUser.role === "owner" ? (
                                  "-"
                                ) : (
                                  <p style={{ margin: 0 }}>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        borderBottom: "1px solid #283e4a",
                                      }}
                                      onClick={() =>
                                        handleOrgUser("role", orgUser)
                                      }
                                    >
                                      {orgUser.role === "admin"
                                        ? "Revoke Admin"
                                        : "Make admin"}
                                    </span>
                                    {" / "}
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        borderBottom: "1px solid #283e4a",
                                      }}
                                      onClick={() =>
                                        handleOrgUser("remove", orgUser)
                                      }
                                    >
                                      Remove
                                    </span>
                                  </p>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </Container>
        )}

        <AddPhotos
          title="Manage Cover Photo"
          isOpen={showCoverPhoto}
          onClose={() => setShowCoverPhoto(false)}
          uid={orgDetails && orgDetails[0] && orgDetails[0]._id}
          collection="Org"
          folder="coverPictures"
          profilephotoURL={coverImageUrl}
          onProfilephotoURLChange={updateCoverImageUrl}
          canEdit={true}
        />
        <AddPhotos
          title="Manage Profile Photo"
          isOpen={showProfilePhoto}
          onClose={() => setShowProfilePhoto(false)}
          uid={orgDetails && orgDetails[0] && orgDetails[0]._id}
          collection="Org"
          folder="logos"
          profilephotoURL={profileImageUrl}
          onProfilephotoURLChange={updateDefaultImageUrl}
          canEdit={true}
        />
        <PaymentsPopUp
          isOpen={isPaymentsPopUpOpen}
          donationFor="individual"
          toUserInfo={userData && userData.unique_id}
          onClose={(e) => setIsPaymentsPopUpOpen(false)}
        />
      </div>
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={(e) => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
        orgDetails={orgDetails[0]}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper} style={{ width: 620 }}>
            <TextField
              type="email"
              className={`input-field request-title`}
              variant="outlined"
              label="Enter Email Address of the new member"
              name="email"
              value={email}
              onChange={(e) => handleuserDetails(e, "email")}
            />
            {errors && errors.email && (
              <div className="custom-error">{errors.email}</div>
            )}
            <FormControl className={classes.orgType} style={{ width: "100%" }}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{
                  top: 0,
                  left: 25,
                  background: "#fff",
                  paddingRight: 15,
                  zIndex: 9,
                }}
              >
                Select A Role For The New Member
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={type}
                onChange={(e) => handleuserDetails(e, "type")}
                variant="outlined"
                className={`input-field request-amount `}
                name="type"
                label="Organization Type"
                style={{ width: "100%" }}
              >
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"member"}>Member</MenuItem>
              </Select>
            </FormControl>
            {typeError && errors.type && (
              <div className="custom-error">{errors.type}</div>
            )}
            <Button onClick={handleInvite} className={classes.inviteBtn}>
              Invite User To Join The Organization
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default OrgDetails;

import React from "react";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

const PodcastThumbnail = (props) => {
  return (
    <Grid container className="podcast-cont">
      <Grid className="podcast-child-cont">
        <Tooltip title="Click to play">
          <Link to={`/talmedia/${props.fileName}`} target="_blank">
            <img
              src={
                props.thumbnailUrl
                  ? props.thumbnailUrl
                  : "/images/default-thumbnail.jpeg"
              }
              className="player-cont"
              alt="podcast"
            />
          </Link>
        </Tooltip>
        <div className="podcast-detail-cont">
          <Typography component="h2">{props.name}</Typography>
          <Typography component="p">{props.note}</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default PodcastThumbnail;

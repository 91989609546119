import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select, MenuItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Close from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./Payment.scss";
import { appContext } from "../../App";

const Payment = (props) => {
  const { authUser, regions, region, setDonationDetails } = useContext(
    appContext
  );

  const [amount, setAmount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isIndividual, setIsIndividual] = useState(false);
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(false);
  const [currency, setCurrency] = useState("");
  const [shouldChargeProcessingFees, setShouldChargeProcessingFees] = useState(
    false
  );
  const changeHandler = (event) => {
    setAmount(event.target.value);
  };

  const changeHandlerFN = (event) => {
    setFirstName(event.target.value);
  };

  const changeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };

  const changeHandlerLN = (event) => {
    setLastName(event.target.value);
  };

  const restrictCharacters = (event) => {
    const allowedCharacters = /^[0-9]\d*$/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (props) {
      if (props.donationFor === "donationRequest") {
        setCurrency(props.currency);
      } else {
        setCurrency(region && region.currency);
      }

      if (props.donationFor === "individual" && !authUser) {
        setIsIndividual(true);
      } else {
        setIsIndividual(false);
      }
    } else {
      setCurrency(region && region.currency);
    }
    if (props.amt) {
      setAmount(props.amt);
    }
  }, [props]);

  const submitHandler = () => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (props.donationFor === "individual" && !authUser && !firstName) {
      setError("Please enter first name");
    } else if (props.donationFor === "individual" && !authUser && !email) {
      setError("Please enter email");
    } else if (
      props.donationFor === "individual" &&
      !authUser &&
      pattern.test(email) === false
    ) {
      setError("Please enter a valid email");
    } else if (!amount) {
      setError("Please enter Amount");
    } else if (isNaN(amount)) {
      setError("Please enter valid amount");
    } else if (Number(amount) < 1) {
      setError("Please enter valid amount");
    } else {
      const obj = {};
      obj.donationFor = props.donationFor;
      if (obj.donationFor === "donationRequest") {
        obj.donationRequestId = props.donationRequestId;
        obj.currency = props.currency;
        obj.region = regions.find(
          (region) => props.currency === region.currency
        );
      } else if (obj.donationFor === "individual" && props.toUserInfo) {
        obj.toUserInfo = props.toUserInfo;
        obj.currency = region.currency;
        obj.region = region;
        obj.email = email;
        obj.name = {
          first_name: firstName,
          last_name: lastName,
        };
      } else {
        obj.amount = 100 * Number(amount);
        obj.currency = region.currency;
        obj.region = region;
      }
      if (shouldChargeProcessingFees) {
        obj.donatedAmount = Number(amount);
        obj.processingFees = Number(
          ((obj.region.processingFees / 100.0) * obj.donatedAmount).toFixed(2)
        );
        obj.amount = Math.round(100 * (obj.donatedAmount + obj.processingFees));
      } else {
        obj.amount = 100 * Number(amount);
        obj.donatedAmount = Number(
          (amount / (1 + obj.region.processingFees / 100.0)).toFixed(2)
        );
        obj.processingFees = amount - obj.donatedAmount;
      }
      obj.userId = (authUser && authUser.unique_id) || null;
      obj.customer_id = localStorage.getItem("stripeCustomerId");
      obj.isAnonymous = checked;
      setDonationDetails(obj);
      setAmount("");
      props.history.push("/billingInfo");
      props.onClose();
    }
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const processingFeesChangeHandler = (event) => {
    setShouldChargeProcessingFees(event.target.checked);
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className="payment-modal"
        open={props.isOpen}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isOpen}>
          <div className="payment-container">
            <div className="payment-page">
              <div>
                <div className="pay-headername">
                  <h2>
                    Donate Now!
                    <span
                      className="close-payment"
                      onClick={props.onClose}
                      style={{ cursor: "pointer" }}
                    >
                      <Close />
                    </span>
                  </h2>
                  <div className="avatar-cont">
                    <Avatar
                      alt="Remy Sharp"
                      className="avatar"
                      src="/images/donate-icon.svg"
                    />
                  </div>
                  <p className="payment-text">
                    One step away to Spread kindness :)
                  </p>
                  <p className="payment-text">Make a contribution now!</p>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Donate as anonymous"
                  />
                </div>
                {
                  /*
                  This block is only for non-loggedin user
                  */
                  isIndividual && (
                    <>
                      <div className="payment-textfield-container">
                        <TextField
                          type="text"
                          className="full-width border-radius payment-input"
                          placeholder="First Name"
                          name="firstName"
                          value={firstName}
                          onChange={changeHandlerFN}
                        />
                        <TextField
                          type="text"
                          className="full-width border-radius payment-input"
                          placeholder="Last Name"
                          name="lastName"
                          value={lastName}
                          onChange={changeHandlerLN}
                        />
                      </div>
                      <div className="payment-textfield-container">
                        <TextField
                          type="text"
                          className="full-width border-radius payment-input"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={changeHandlerEmail}
                        />
                      </div>
                    </>
                  )
                }
                <div className="payment-textfield-container">
                  <TextField
                    type="number"
                    className="full-width border-radius payment-input"
                    placeholder="Donation Amount"
                    name="amount"
                    value={amount}
                    onChange={changeHandler}
                    onKeyPress={restrictCharacters}
                  />

                  <FormControl className="inr">
                    <Select
                      name="currency"
                      value={currency}
                      className="input-field currency"
                      disabled
                    >
                      {regions &&
                        regions.length > 0 &&
                        regions.map((region, index) => {
                          return (
                            <MenuItem value={region.currency} key={index}>
                              {region.currency}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <span>
                  {error && (
                    <div style={{ color: "red", "font-size": "12px" }}>
                      {error}
                    </div>
                  )}
                </span>

                <div className="donate-now">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shouldChargeProcessingFees}
                        onChange={processingFeesChangeHandler}
                        name="processingFees"
                        color="primary"
                      />
                    }
                    label={`I would like to pay the extra bank transaction charges of ${
                      region && region.processingFees
                    }%  so that the beneficiary receives the full amount that I am donating.`}
                  />
                </div>

                <div className="payment-btn">
                  <Button
                    color="primary"
                    className="pay-btn"
                    variant="contained"
                    type="submit"
                    onClick={submitHandler}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default withRouter(Payment);

import React, { Fragment, useContext, useEffect, useState } from "react";
import { appContext } from "../../App";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import firebase from "../../firebase/firebase";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideMenu from "../SideMenu/Sidemenu";
import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableCell,
} from "@material-ui/core";
import RadioButton from "@material-ui/core/Radio";
import {
  getAccountInfo,
  getDonationDetails,
  getDonationRequest,
  getUserData,
  getWithdrawRequests,
} from "../../utils/api";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import ValidateWithdrawal from "./ValidateWithdrawal";
import "./withdraw.scss";
import NewAddress from "../common/NewAddress";
import WithdrawalCard from "./WithdrawalCard";
import DocumentRow from "../common/DocumentRow";
import LoginVerify from "./LoginVerify";
import { getObjectValuesInStringFormat, isImage } from "../../utils/utils";
import AddDocument from "../common/AddDocument";
import DescriptionIcon from "@material-ui/icons/Description";

function WithdrawForm(props) {
  const { authUser, region } = useContext(appContext);
  const initialState = {
    userName: "",
    label: "",
    accountNumber: null,
    routingNumber: null,
    isDefault: true,
    notes: props.location.state && props.location.state.notes,
    amount: 0,
  };

  const [withdrawType, setWithDrawType] = useState("online");
  const [tempAddress, setTempAddress] = useState({});
  const [address, setAddress] = useState({});
  const [addressError, setAddressError] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountError, setAmountError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [collection, setCollection] = useState("");
  const [id, setId] = useState("");
  const [isOrg, setIsOrg] = useState(false);
  const [error, setError] = useState("");
  const [imageDocuments, setImageDocuments] = useState([]);
  const [nonImageDocuments, setNonImageDocuments] = useState([]);
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [finalValues, setFinalValues] = useState({});

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const loadDonationDetails = (donationRequestId) => {
    getDonationDetails(donationRequestId)
      .then((response) => {
        setTotalAmount(
          response.data.donated_quantity - response.data.withdrawAmount
        );
        setValues((currentValues) => {
          return {
            ...currentValues,
            amount:
              response.data.donated_quantity - response.data.withdrawAmount,
          };
        });
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const loadWithdrawals = (donationRequestId) => {
    setIsLoading(true);
    getWithdrawRequests(authUser.unique_id, donationRequestId)
      .then((response) => {
        setIsLoading(false);
        if (response && response instanceof Array && response.length >= 0) {
          response.map((data) => {
            if (
              data.requestId &&
              data.requestId._id === donationRequestId &&
              data.status === 0
            ) {
              setIsPending(true);
              return;
            }
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  useEffect(() => {
    if (props.match.params.id) {
      loadDonationRequest(props.match.params.id);
      loadDonationDetails(props.match.params.id);
      loadWithdrawals(props.match.params.id);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      getUserData(authUser.unique_id)
        .then((response) => {
          const userData = response;
          setValues((currentValues) => {
            return {
              ...currentValues,
              userName: userData.username,
            };
          });
          if (userData.address) {
            setTempAddress({
              line1: userData.address.hasOwnProperty("line1")
                ? userData.address.line1
                : "",
              line2: userData.address.hasOwnProperty("line2")
                ? userData.address.line2
                : "",
              city: userData.address.hasOwnProperty("city")
                ? userData.address.city
                : "",
              state: userData.address.hasOwnProperty("state")
                ? userData.address.state
                : "",
              zip: userData.address.hasOwnProperty("zip")
                ? userData.address.zip
                : "",
              country: userData.address.hasOwnProperty("country")
                ? userData.address.country
                : "",
            });
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
      getAccountInfo(authUser.unique_id)
        .then((response) => {
          setValues((currentValues) => {
            return {
              ...currentValues,
              accountNumber: response.data.data[0]
                ? response.data.data[0].accountNumber
                : "",
              isDefault: response.data.data[0]
                ? response.data.data[0].isDefault
                : true,
              label: response.data.data[0] ? response.data.data[0].label : "",
              routingNumber: response.data.data[0]
                ? response.data.data[0].routingNumber
                : "",
              // _id: response.data.data[0] ? response.data.data[0]._id : "",
            };
          });
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  }, [authUser]);

  useEffect(() => {
    setValues((currentValues) => {
      return {
        ...currentValues,
        withdrawType,
      };
    });
  }, []);

  const handleWithdrawal = (event) => {
    setValues((currentValues) => {
      return {
        ...currentValues,
        withdrawType: event.target.value,
      };
    });

    setWithDrawType(event.target.value);
  };
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handleContinueStep1 = () => {
    if (values.amount > totalAmount) {
      setAmountError(`Please enter amount lessthan ${totalAmount}`);
      return;
    }

    finalValues.requestType = withdrawType;
    finalValues.notes = values.notes;
    finalValues.amount = values.amount;
    finalValues.payeeName = values.userName;
    finalValues.userId = authUser.unique_id;
    const bankInfo = {};
    bankInfo.label = values.label;
    bankInfo.isDefault = values.isDefault;
    finalValues.bankInfo = bankInfo;

    if (withdrawType === "online") {
      if (Object.keys(errors).length > 0) {
        return;
      }

      bankInfo.accountNumber = values.accountNumber;
      bankInfo.routingNumber = values.routingNumber;
      bankInfo.isDefault = values.isDefault;
      finalValues.bankInfo = bankInfo;
    } else {
      console.log("Tempaddress", tempAddress);
      if (
        !tempAddress ||
        (!tempAddress.line1 && !tempAddress.line2) ||
        !tempAddress.state ||
        !tempAddress.country ||
        !tempAddress.zip
      ) {
        setAddressError("please enter the complete address");
        return;
      }
      finalValues.address = tempAddress ? tempAddress : address;
    }
    setActiveStep(2);
  };
  const loadDonationRequest = (donationRequestId) => {
    getDonationRequest(donationRequestId)
      .then((response) => {
        if (response.orgId && response.orgId._id) {
          setCollection("Org");
          setId(response.orgId._id);
          setIsOrg(true);
        } else {
          setCollection("User");
          setId(response.user_info._id);
          setIsOrg(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(error.message);
      });
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };
  const onSavingDocument = (message, url) => {
    toastr.success(message);
  };
  const handleContinueStep2 = () => {
    const path = `${collection}/${id}/documents`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);

    pathRef.listAll().then((result) => {
      const filesCount = result.items.length;

      if (filesCount === 0) {
        setError("Please upload atleast one document");
        return;
      } else {
        setError(null);
        setActiveStep(3);
      }
    });
  };
  useEffect(() => {
    if (activeStep === 3) {
      const path = `${collection}/${id}/documents`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        setImageDocuments([]);
        setNonImageDocuments([]);
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            const itemPathRef = storageRef.child(path + "/" + item.name);
            itemPathRef
              .getMetadata()
              .then((metadata) => {
                if (isImage(metadata.contentType)) {
                  setImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                } else {
                  setNonImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                }
              })
              .catch(() => {
                //toastr.error(error.message);
              });
          });
        });
      });
    }
  }, [activeStep]);

  const handleContinueStep3 = () => {
    setOpenLogin(true);
  };
  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, ValidateWithdrawal, handleContinueStep1);

  if (isPending) {
    return (
      <Container maxWidth="sm">
        <Box
          component="p"
          p={2}
          className="drop-box"
          display="grid"
          placeContent="center"
        >
          You already have a pending withdrawal request. Please visit 'My
          Withdrawals' to see the existing pending request.
        </Box>
      </Container>
    );
  } else
    return (
      <Box>
        <div className="barnav">
          <Container MaxWidth="lg">
            <div style={{ display: "flex" }}>
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
            </div>
          </Container>
        </div>

        <Container maxWidth="lg">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            )}
            <Grid container spacing={2}>
              {activeStep === 1 && (
                <Grid item md={8}>
                  <Box p={2} className="bai drop-box">
                    <Typography>Step 1/3</Typography>
                    <h2>Bank Account Information</h2>

                    <Box mt={2}>
                      <Grid container spacing={2} >
                        <Grid item xs={12} >
                          <InputLabel className="secFormLabel">
                            Withdraw Amount
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            style={{ width: "100%" ,marginBottom:"7px"}}
                            name="amount"
                            // disabled={!editMode}
                            value={values.amount}
                            onChange={changeHandler}
                          />
                          {errors && errors.amount && (
                            <Typography className="custom-error">
                              {errors.amount}
                            </Typography>
                          )}
                          <Typography className="custom-error">
                            {amountError}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                          <div
                            className={
                              withdrawType === "online"
                                ? "withdrawal-selected"
                                : "withdrawal"
                            }
                          >
                            <RadioButton
                              value="online"
                              label="online"
                              onChange={handleWithdrawal}
                              checked={withdrawType === "online"}
                              style={{
                                color:
                                  withdrawType === "online"
                                    ? "#ef6c00"
                                    : "#f44336",
                                borderColor:
                                  withdrawType === "online"
                                    ? "#ef6c00"
                                    : "#f44336",
                              }}
                            />

                            <Typography
                              variant="span"
                              style={{
                                color:
                                  withdrawType === "online"
                                    ? "#ef6c00"
                                    : "black",
                              }}
                              gutterBottom
                            >
                              Bank Account
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <div
                            className={
                              withdrawType === "cheque"
                                ? "withdrawal-selected"
                                : "withdrawal"
                            }
                          >
                            <RadioButton
                              value="cheque"
                              label="cheque"
                              onChange={handleWithdrawal}
                              checked={withdrawType === "cheque"}
                              style={{
                                color:
                                  withdrawType === "cheque"
                                    ? "#ef6c00"
                                    : "#f44336",
                                borderColor:
                                  withdrawType === "cheque"
                                    ? "#ef6c00"
                                    : "#f44336",
                              }}
                            />
                            <Typography
                              variant="span"
                              style={{
                                color:
                                  withdrawType === "cheque"
                                    ? "#ef6c00"
                                    : "black",
                              }}
                              gutterBottom
                            >
                              Cheque Payment
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}></Grid>

                        {withdrawType === "online" ? (
                          <>
                            <Grid item xs={12}>
                              <InputLabel className="secFormLabel">
                                Account Name
                              </InputLabel>
                              <TextField
                                type="text"
                                className="profile-fields user name"
                                variant="outlined"
                                style={{ width: "100%" }}
                                name="userName"
                                // disabled={!editMode}
                                value={values.userName}
                                onChange={changeHandler}
                              />
                              {errors && errors.userName && (
                                <Typography className="custom-error">
                                  {errors.userName}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box className="noEdit">
                                <InputLabel className="secFormLabel">
                                  Account Number
                                </InputLabel>
                                <TextField
                                  variant="outlined"
                                  className="profile-fields password"
                                  style={{ width: "100%" ,marginBottom:"7px"}}
                                  name="accountNumber"
                                  // disabled={!editMode}
                                  onChange={changeHandler}
                                  type="number"
                                  value={values.accountNumber}
                                />
                                {errors && errors.accountNumber && (
                                  <Typography
                                    variant="span"
                                    className="custom-error"
                                  >
                                    {errors.accountNumber}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box className="noEdit">
                                <InputLabel className="secFormLabel">
                                  {region && region._id === "IN"
                                    ? "IFSC Code"
                                    : region &&
                                      region._id === "US" &&
                                      "Routing Number"}
                                </InputLabel>
                                <TextField
                                  variant="outlined"
                                  style={{ width: "100%" ,marginBottom:"7px"}}
                                  name="routingNumber"
                                  //  disabled={!editMode}
                                  // value={accountValues && accountValues.routingNumber}
                                  onChange={changeHandler}
                                  className="profile-fields changepassword"
                                  type="text"
                                  value={values.routingNumber}
                                />
                                {errors && errors.routingNumber && (
                                  <Typography className="custom-error">
                                    {errors.routingNumber}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} md={12}>
                              <InputLabel className="secFormLabel">
                                Cheque Name (In Favour of)
                                <span style={{ color: "red" }}>*</span>
                              </InputLabel>
                              <TextField
                                type="text"
                                className="profile-fields user name"
                                variant="outlined"
                                style={{ width: "100%" }}
                                name="userName"
                                // disabled={!editMode}
                                value={values.userName}
                                onChange={changeHandler}
                              />
                              {errors && errors.userName && (
                                <Typography className="custom-error">
                                  {errors.userName}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Box className="address">
                                <InputLabel
                                  style={{ margin: "10px 0 0 0" }}
                                  className="secFormLabel"
                                >
                                  Address
                                </InputLabel>
                                <NewAddress
                                  value={tempAddress}
                                  onChange={handleChangeAddress}
                                  //disabled={!editMode}
                                />
                                {addressError && (
                                  <div className="custom-error">
                                    {addressError}
                                  </div>
                                )}
                              </Box>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12}>
                          <InputLabel className="secFormLabel">
                            User notes
                          </InputLabel>
                          <TextField
                            multiline
                            rows={3}
                            type="text"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="notes"
                            value={values.notes}
                            onChange={changeHandler}
                          />
                          {errors && errors.notes && (
                            <Typography className="custom-error">
                              {errors.notes}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Box textAlign="right">
                            <Button
                              onClick={() => props.history.push("/")}
                              variant="contained"
                              style={{
                                background: "white",
                                borderRadius: "4px",
                                textTransform: "capitalize",
                                padding: "10px",
                                boxShadow: "none",
                              }}
                              aria-label="back"
                            >
                              Back
                            </Button>
                            <Button
                              onClick={submitHandler}
                              variant="contained"
                              style={{
                                background: "var(--tal_primary) ",
                                color: "white",
                                textTransform: "capitalize",
                                marginLeft: "10px",
                                padding: "10px",
                              }}
                              aria-label="continue withdrawal"
                            >
                              Continue
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              )}
              {activeStep === 2 && (
                <Grid item md={8}>
                  <Box className=" bai drop-box" p={2}>
                    <Typography>Step 2/3</Typography>
                    <h2> KYC Verification</h2>

                    <Typography>
                      Our payment processor will need extra information to
                      verify your banking information. We will pause your
                      withdrawals until we get the below-listed documents.
                    </Typography>
                    <Typography variant="h6" component="h6">
                      Documents that may be required include, but are not
                      limited to:
                    </Typography>
                    {isOrg && (
                      <ul>
                        <li>Address proof</li>
                        <li>
                          a government-issued ID for the beneficial owner and/or
                          anyone with a 25% stake or greater in the organization
                        </li>
                        <li>
                          a copy of the organization’s governmental
                          registration; such as articles of incorporation,
                          articles of association, certificate of incorporation,
                          or applicable
                        </li>
                        <li>a The organization’s tax ID number</li>
                        <li>
                          a IRS 501(c) (3) determination letter (only applicable
                          to US non-profit organizations)
                        </li>
                        <li>
                          a bank statement for the organization OR cancelled
                          check
                        </li>
                      </ul>
                    )}
                    {!isOrg && (
                      <ul>
                        <li>
                          {region && region._id === "IN"
                            ? "2 government-issued ID proof copy  (Driver's License , Aadhar Card, Passport)"
                            : region &&
                              region._id === "US" &&
                              "2 government-issued ID proof copy (Driver's license,Passport,social security card)"}
                        </li>
                        <li>A bank statement/Void/cancelled Check.</li>
                        <li>
                          If withdrawing for someone, then letter from a
                          benificary with benificary ID amd Bank statement as
                          supportive document.
                        </li>
                        <li>
                          If the donations amount goes to the institution, then
                          provide the documents from institutions
                          (Hospital,Schools,Organization, etc...). Required
                          documents are cause receipt from the institution,
                          details information documents about the cause, Bank
                          details and address proof.
                        </li>
                      </ul>
                    )}
                    <div>
                      <Button
                        className="document-btn"
                        onClick={() => setShowingAddDocumentModal(true)}
                        style={{
                          textAlign: "left",
                          padding: "20px 30px",
                          background: "transparent",
                          color: "#283E4A",
                          border: "1px solid #283E4A",
                        }}
                      >
                        <DescriptionIcon
                          style={{ fontSize: 14, marginRight: 6 }}
                        />{" "}
                        View/Upload supporting document(s)
                      </Button>
                      <Typography
                        className="custom-error"
                        style={{ marginTop: "5px" }}
                      >
                        {error}
                      </Typography>
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        margin: "10px 0px",
                      }}
                    >
                      <Button
                        onClick={() => setActiveStep(1)}
                        variant="contained"
                        style={{
                          background: "wgite",
                          borderRadius: "4px",
                          textTransform: "capitalize",
                          boxShadow: "none",
                          background: "transparent",
                          padding: "10px",
                        }}
                        aria-label="back"
                      >
                        Back
                      </Button>
                      <Button
                        onClick={handleContinueStep2}
                        variant="contained"
                        style={{
                          background: "#ef6c00",
                          color: "white",
                          textTransform: "capitalize",
                          padding: "10px",
                        }}
                        aria-label="continue withdrawal"
                      >
                        Continue
                      </Button>
                    </div>

                    <AddDocument
                      title="Add Supporting Documents"
                      isOpen={showingAddDocumentModal}
                      onClose={onCloseAddDocument}
                      uid={id}
                      collection={collection}
                      onSavingDocument={onSavingDocument}
                      canEdit={true}
                    />
                  </Box>
                </Grid>
              )}
              {activeStep === 3 && (
                <Grid item md={8}>
                  <Box className="bai drop-box" p={2}>
                    <Typography> Step 3/3</Typography>
                    <h2> Confirm Withdrawal </h2>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        Withdraw Type
                      </Grid>
                      <Grid item xs={6}>
                        {finalValues.requestType}
                      </Grid>
                      <Grid item xs={6}>
                        Withdraw Amount
                      </Grid>
                      <Grid item xs={6}>
                        {finalValues.amount}
                      </Grid>

                      {finalValues.requestType === "online" ? (
                        <>
                          <Grid item xs={6}>
                            Account Name
                          </Grid>
                          <Grid item xs={6}>
                            {finalValues.payeeName}
                          </Grid>
                          <Grid item xs={6}>
                            Account Number
                          </Grid>
                          <Grid item xs={6}>
                            {finalValues.bankInfo.accountNumber}
                          </Grid>
                          <Grid item xs={6}>
                            {region.name === "India"
                              ? "IFSC Code"
                              : "Routing Number"}
                          </Grid>
                          <Grid item xs={6}>
                            {finalValues.bankInfo.routingNumber}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={6}>
                            Cheque Name (In Favour of)
                          </Grid>
                          <Grid item xs={6}>
                            {finalValues.payeeName}
                          </Grid>
                          <Grid item xs={6}>
                            Address
                          </Grid>
                          <Grid item xs={6}>
                            {getObjectValuesInStringFormat(finalValues.address)}
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider
                      variant="fullWidth"
                      className="postDividerIn"
                      style={{ margin: "10px 0" }}
                    />
                    <Grid item xs={12}>
                      <Typography variant="h6">Documents Uploaded</Typography>
                      {imageDocuments &&
                        (imageDocuments.length > 0 ||
                          nonImageDocuments.length > 0) && (
                          <Fragment>
                            <Typography
                              className="donation-header"
                              style={{ marginBottom: "10px" }}
                            >
                              Image Documents:
                            </Typography>
                            <Box display="flex" flexWrap="wrap">
                              {imageDocuments.map((document, index) => {
                                return (
                                  <Fragment key={index}>
                                    <figure
                                      className="drop-box"
                                      style={{
                                        display: "grid",
                                        placeContent: "center",
                                        padding: "5px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <img
                                        alt={document && document.userGivenName}
                                        src={document && document.url}
                                        style={{
                                          width: "200px",
                                          heigth: "200px",
                                        }}
                                      ></img>
                                    </figure>
                                  </Fragment>
                                );
                              })}
                            </Box>
                            {nonImageDocuments.length > 0 && (
                              <Grid container>
                                <Table celled striped>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Document Name</TableCell>
                                      <TableCell>Note</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {nonImageDocuments.map(
                                      (document, index) => {
                                        return (
                                          <Fragment key={index}>
                                            <DocumentRow
                                              key={index}
                                              document={document}
                                              canEdit={false}
                                            />
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              </Grid>
                            )}
                          </Fragment>
                        )}
                    </Grid>

                    <Box textAlign="right" pt={1}>
                      <Button
                        onClick={() => setActiveStep(2)}
                        variant="contained"
                        style={{
                          background: "white",
                          borderRadius: "4px",
                          textTransform: "capitalize",
                          boxShadow: "none",
                        }}
                        aria-label="back"
                      >
                        Back
                      </Button>
                      <Button
                        onClick={handleContinueStep3}
                        variant="contained"
                        style={{
                          background: "#ef6c00",
                          color: "white",
                          textTransform: "capitalize",
                          marginLeft: "10px",
                        }}
                        aria-label="withdraw funds"
                      >
                        Withdraw Funds
                      </Button>
                    </Box>
                  </Box>
                  <LoginVerify
                    open={openLogin}
                    finalValues={finalValues}
                    donationRequestId={props.match.params.id}
                    handleClose={() => setOpenLogin(false)}
                  />
                </Grid>
              )}
              <Grid item md={4}>
                <Box className="drop-box">
                  <WithdrawalCard donationRequestId={props.match.params.id} />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Box>
    );
}

export default WithdrawForm;

import React, { useState, useEffect, useContext } from "react";
import { Grid, Hidden, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "./EmailEdit.scss";
import SideMenu from "../SideMenu/Sidemenu";
import EmailContactGroupsTable from "./EmailContactGroupsTable";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EmailAddContactsModal from "./EmailAddContactsModal";
import { getContactsByGroupId } from "../../utils/api";

import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { appContext } from "../../App";
import Box from "@material-ui/core/Box";
import EmailPreviousDonors from "./EmailPreviousDonors";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EmailContactGroups = (props) => {
  const {
    contacts,
    contactGroups,
    selectedGroups,
    emailContactType,
    talContacts,
    selectedContactsForEmailCampaign,
    contactsByGroupName,
  } = useContext(appContext);

  const [openModal, setOpenModal] = useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  //setSelectedContactGroups(contactGroups);
  const handleBack = () => {
    props.history.push(
      `/startContactEmailCampaign/EmailEdit/${props.match.params.id}`
    );
  };
  const handleSaveContinue = () => {
    if (selectedContactsForEmailCampaign.length > 0) {
      // setSelectedContactsForEmailCampaign([]);
      props.history.push(
        `/startContactEmailCampaign/EmailEditConfirmation/${props.match.params.id}`
      );
    }
  };
  //const contactsByGroupName = {};
  useEffect(() => {
    if (props.match.params.id) {
      //setSelectedContactGroups(contactGroups.filter((cg) => cg.name));
      // if (!selectedGroups)
      //     setSelectedGroups(contactGroups.map((cg) => cg.name));
      let scec = [];
      if (
        emailContactType === "allcontacts" ||
        emailContactType === "prevdonors"
      ) {
        contactsByGroupName["Previous Donors"] = talContacts;
      }

      contactGroups.forEach((cg) => {
        getContactsByGroupId(authUser.unique_id, cg._id).then((data) => {
          const prevContactList = [];
          data.forEach((item) => {
            scec = [...scec, item.contactId._id];
            const contact = contacts.find((c) => c._id === item.contactId._id);
            prevContactList.push(contact);
          });
          contactsByGroupName[cg.name] = prevContactList;
        });
      });
    }
  }, [contactGroups]);

  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const { authUser } = useContext(appContext);
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="edit-container">
      <Hidden mdUp>
        {" "}
        {authUser && (
          <Button
            onClick={handleCloseSideMenu}
            style={{
              color: "#ef6c00",
              minWidth: "50px",
              height: "50px",
              borderRadius: "0px 50px 50px 0",
              marginRight: "10px",
              background: "white",
              position: "fixed",
              zIndex: "3",
              top: "40%",
              boxShadow: "0 0 5px #0000003b",
            }}
          >
            {!closeSideMenu && <MenuIcon />}
            {closeSideMenu && <ArrowBackIcon />}
          </Button>
        )}
      </Hidden>

      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          </Hidden>

          <div className="drop-box" style={{ width: "100%" }}>
            <div className="email-header">
              <Box className="back-button">
                <Button
                  onClick={handleBack}
                  aria-label="back"
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </Button>
              </Box>

              <h4> Choose your contact groups</h4>

              <Box className="save-button">
                <Button
                  onClick={handleSaveContinue}
                  variant="contained"
                  style={{
                    background:
                      selectedGroups.length === 0 ? "#9d9d9d" : "#EF6C00",
                    color: "white",
                  }}
                  aria-label="save"
                >
                  Save &amp; Continue
                </Button>
              </Box>
            </div>
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs={12}>
                {emailContactType === "allcontacts" && (
                  <>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="All Contacts Selection"
                    >
                      <Tab label="My Contacts" {...a11yProps(0)} />
                      <Tab label="Previous Donors" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <EmailContactGroupsTable />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <EmailPreviousDonors currentContactGroup="Previous Donors" />
                    </TabPanel>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                {emailContactType === "mycontacts" && (
                  <EmailContactGroupsTable />
                )}
              </Grid>
              <Grid item xs={12}>
                {emailContactType === "prevdonors" && (
                  <EmailPreviousDonors currentContactGroup="Previous Donors" />
                )}
              </Grid>
            </Grid>
          </div>
          <EmailAddContactsModal
            openModal={openModal}
            onModalClose={handleModalClose}
            onContactGroupSave={() => {
              setOpenModal(false);
            }}
          ></EmailAddContactsModal>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(EmailContactGroups);

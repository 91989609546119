import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PublicIcon from "@material-ui/icons/Public";
import InfoIcon from "@material-ui/icons/Info";
import AddPhotos from "../common/AddPhotos";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddVideo from "../common/AddVideo";
import NonModalVideoPlayer from "../common/NonModalVideoPlayer";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import Divider from "@material-ui/core/Divider";
import { formatScore, numberWithCommas, validateUrl } from "../../utils/utils";

import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const PublicProfileSideBar = (props) => {
  const classes = useStyles();
  const { editMode, userData, images, videos } = props;
  const [showPhotos, setShowPhotos] = useState(false);
  const [showVideos, setShowVideos] = useState(false);

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} className="introBlk">
        <div>
          <PublicIcon className="secMainTitleImg" />
          <h3 className="secMainTitle">Intro</h3>
        </div>
        <div className="textBlk">
          {userData && userData.title && (
            <p>
              <InfoIcon style={{ color: "#8C939C", marginRight: 10 }} />{" "}
              {userData && userData.title}
            </p>
          )}
          <p>
            <EmojiEventsIcon style={{ color: "#8C939C", marginRight: 10 }} />{" "}
            Kindness Points:{" "}
            {userData && numberWithCommas(userData.kindness_score)}
          </p>
          {userData &&
            (userData.facebookProfileUrl ||
              userData.instagramProfileUrl ||
              userData.twitterProfileUrl ||
              userData.linkedInProfileUrl) && (
              <div className="public-social-links">
                <h4>Contact Info: </h4>
                <p>
                  {validateUrl(userData.facebookProfileUrl) && (
                    <a
                      href={userData.facebookProfileUrl}
                      target="blank"
                      style={{
                        width: "50px",
                        display: "inline-block",
                        opacity:
                          userData && userData.facebookProfileUrl ? 1 : 0.2,
                        pointerEvents:
                          userData && userData.facebookProfileUrl
                            ? "auto"
                            : "none",
                      }}
                    >
                      <img
                        src="/images/public_facebook.png"
                        alt="fb"
                        style={{ width: "75%", marginRight: 6 }}
                      />
                    </a>
                  )}
                  {validateUrl(userData.instagramProfileUrl) && (
                    <a
                      href={userData.instagramProfileUrl}
                      target="blank"
                      style={{
                        width: "50px",
                        display: "inline-block",
                        opacity:
                          userData && userData.instagramProfileUrl ? 1 : 0.2,
                        pointerEvents:
                          userData && userData.instagramProfileUrl
                            ? "auto"
                            : "none",
                      }}
                    >
                      <img
                        src="/images/public_instagram.png"
                        alt="fb"
                        style={{ width: "75%", marginRight: 6 }}
                      />
                    </a>
                  )}
                  {validateUrl(userData.twitterProfileUrl) && (
                    <a
                      href={userData.twitterProfileUrl}
                      target="blank"
                      style={{
                        width: "50px",
                        display: "inline-block",
                        opacity:
                          userData && userData.twitterProfileUrl ? 1 : 0.2,
                        pointerEvents:
                          userData && userData.twitterProfileUrl
                            ? "auto"
                            : "none",
                      }}
                    >
                      <img
                        src="/images/public_twitter.png"
                        alt="fb"
                        style={{ width: "75%", marginRight: 6 }}
                      />
                    </a>
                  )}
                  {validateUrl(userData.linkedInProfileUrl) && (
                    <a
                      href={userData.linkedInProfileUrl}
                      target="blank"
                      style={{
                        width: "50px",
                        display: "inline-block",
                        opacity:
                          userData && userData.linkedInProfileUrl ? 1 : 0.2,
                        pointerEvents:
                          userData && userData.linkedInProfileUrl
                            ? "auto"
                            : "none",
                      }}
                    >
                      <img
                        src="/images/public_linkedin.webp"
                        alt="fb"
                        style={{ width: "75%", marginRight: 6 }}
                      />
                    </a>
                  )}
                </p>
              </div>
            )}
        </div>
        {/* <div className="textBlk">
          <p className="secTitle">Gender</p>
          <p className="gender">Female</p>
        </div>
        <div className="textBlk">
          <StarsIcon className="artistIco" /> <p>Artist</p>
        </div> */}
        <div className="mediaBlk">
          <p style={{ font: "600 16px Roboto" }}>
            <span
              style={{
                display: "inline-block",
                width: "80%",
              }}
            >
              Photos{" "}
              {editMode && (
                <AddCircleIcon
                  onClick={() => setShowPhotos(true)}
                  style={{ cursor: "pointer", color: "#000", fontSize: 16 }}
                />
              )}
            </span>
            <span onClick={props.viewAllPhotos} className="public-view-all">
              View All
            </span>
          </p>
          <Divider className="mediaHeadDivide" />
          <ul className="photosList">
            {images && images.length > 0 ? (
              images.slice(images.length - 4).map((image, index) => {
                return (
                  <li key={index}>
                    <img src={image.url} alt="" />
                  </li>
                );
              })
            ) : (
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                No photos to show
              </p>
            )}
          </ul>
        </div>
        <div className="mediaBlk">
          <p
            style={{
              font: "600 16px Roboto",
              borderBottom: "1px solid #ccc",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "80%",
              }}
            >
              Videos
              {editMode && (
                <AddCircleIcon
                  onClick={() => setShowVideos(true)}
                  style={{ cursor: "pointer", color: "#000", fontSize: 16 }}
                />
              )}
            </span>
            <span onClick={props.viewAllVideos} className="public-view-all">
              View All
            </span>
          </p>
          {videos && videos.length > 0 ? (
            <Fragment>
              <NonModalVideoPlayer
                name={videos[0].userGivenName}
                note={videos[0].note}
                url={videos[0].url}
                key={1}
              />
            </Fragment>
          ) : (
            <p style={{ textAlign: "center" }}>No videos to show</p>
          )}
        </div>
      </Grid>

      <AddPhotos
        title="Manage Images"
        isOpen={showPhotos}
        onClose={() => setShowPhotos(false)}
        uid={userData && userData.unique_id}
        collection="UserPictures"
        canEdit={editMode}
        profileRequired={false}
        onSavingPhoto={props.onSavingImage}
      />
      <AddVideo
        title="Add Videos"
        isOpen={showVideos}
        onClose={() => setShowVideos(false)}
        uid={userData && userData.unique_id}
        collection="UserVideos"
        onSavingDocument={props.onSavingVideo}
        canEdit={editMode}
      />
    </Grid>
  );
};

export default PublicProfileSideBar;

import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
  Typography,
  Button,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { SearchRounded, HighlightOff, Label } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import {
  HOME_PAGE,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT_SECONDS,
} from "../../utils/utils";
import {
  getAllDonationRequests,
  deleteDonationRequest,
  getAllDonationRequestsByFilter,
} from "../../utils/api";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import DonationRequestForm from "../donationRequest/DonationRequestForm";
import Judgesallocation from "./Judgesallocation";
import "./Admin.scss";
import Container from "@material-ui/core/Container";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import CheckIcon from "@material-ui/icons/Check";
import PersonIcon from "@material-ui/icons/Person";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
  },

  table: {
    "& td:last-child": {
      whiteSpace: "nowrap",
    },
    "& td:nth-child(3)": {
      wordBreak: "break-word",
    },
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const colors = [
  { key: "0", color: "#00a152", status: "Submitted" },
  { key: "-2", color: "#ffac33", status: "Saved" },
];
const SubmittedIdeas = (props) => {
  const classes = useStyles();
  const { authUser, isAdmin, isVolunteer, isSuperAdmin } =
    useContext(appContext);
  const [donationRequests, setDonationRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDonationRequestModal, setIsOpenDonationRequestModal] =
    useState(false);
  const [donationRequestId, setDonationRequestId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sdgs, setSdgs] = useState("");
  const [status, setStatus] = useState("");
  const [allocationfilter, setAllocationfilter] = useState("");
  const [country, setCountry] = useState("");
  const [newest, setNewest] = useState("");
  const [radioMenu, setRadioMenu] = useState("");
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sourceTrackFilter, setSourceTrackFilter] = useState("");
  const [term1, setTerm1] = useState(false);
  const handleTerm1 = (event) => {
    if (event.target.checked) {
      setTerm1(true);
      setRadioMenu("");
    } else {
      setTerm1(false);
    }
  };
  const getStatus = (key) => {
    let obj = colors.filter((item) => item.key === key + "");
    if (obj.length > 0) {
      return obj[0].status;
    }
  };
  const getColor = (key) => {
    let obj = colors.filter((item) => item.key === key + "");
    if (obj.length > 0) {
      return obj[0].color;
    }
  };

  const onChangeOrder = (event) => {
    setPage(0);
    setTotalRecords([]);
    setOffset(0);
    setNewest(event.target.value);
  };
  const onTrackChange = (e) => {
    setPage(0);
    setTotalRecords([]);
    setOffset(0);
    setSourceTrackFilter(e.target.value);
  };

  const onstatuschnge = (event) => {
    setPage(0);
    setTotalRecords([]);
    setOffset(0);
    setStatus(event.target.value);
  };
  const onSdgsChange = (event) => {
    setPage(0);
    setTotalRecords([]);
    setOffset(0);
    setSdgs(event.target.value);
  };
  const oncountrychange = (event) => {
    setPage(0);
    setTotalRecords([]);
    setOffset(0);
    setCountry(event.target.value);
  };
  const loadDonationRequests = (page = 0) => {
    setIsLoading(true);
    getAllDonationRequests(
      rowsPerPage,
      page * rowsPerPage,
      JSON.stringify([
        { request_type: "social advocacy" },
        { request_type: "social entrepreneurship" },
        { request_type: "cash", sourceOfCreation: "talscouts" },
      ])
    )
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);
          const donationRequestArray = [];
          donationRequests.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
            }
            donationRequestArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
            }
            responseArray.push(obj);
            return null;
          });
          setDonationRequests([...donationRequestArray, ...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  const loadDonationRequestsBasedOnKeyword = (
    keyword,
    page,
    donationRequests
  ) => {
    getAllDonationRequestsByFilter(
      rowsPerPage,
      page * rowsPerPage,
      JSON.stringify([
        { request_type: "social advocacy" },
        { request_type: "social entrepreneurship" },
        { request_type: "cash", sourceOfCreation: "talscouts" },
      ]),
      newest,
      status,
      sdgs,
      country,
      sourceTrackFilter
    )
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);
          const donationRequestArray = [];
          donationRequests.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
            }
            donationRequestArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
            }
            responseArray.push(obj);
            return null;
          });
          setDonationRequests([...donationRequestArray, ...responseArray]);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  useEffect(() => {
    loadDonationRequestsBasedOnKeyword(keyword, 0, []);
  }, [sourceTrackFilter, status, sdgs, newest, country, rowsPerPage, page]);

  const onAllocation = (event) => {
    setAllocationfilter(event.target.value);
  };

  useEffect(() => {
    if (authUser) {
      if (isAdmin || isVolunteer || isSuperAdmin) {
      } else {
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  }, [authUser]);

  useEffect(() => {
    loadDonationRequests();
  }, []);

  const handleChangePage = (event, newPage) => {
    loadDonationRequests(newPage);
    loadDonationRequestsBasedOnKeyword(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearState = () => {
    setStatus("");
    setCountry("");
    setSdgs("");
    setSourceTrackFilter("");
    setNewest("");
    setPage(0);
    setTotalRecords([]);
    setOffset(0);
  };

  const exportToCSV = () => {
    const JsonFields = [
      "Submitted By",
      "Submitted Email",
      "Submitted On",
      "Status",
      "Institution",
      "Track",
      "Assigned To",
      "Avg. Rating",
      "Title",
      "SDGs",
      "Proposed Solution",
      "Team Name",
      "Team Members",
      "Mobile Number",
    ];
    var csvStr = JsonFields.join(",") + "\n";
    donationRequests.forEach((element) => {
      let submittedby = "";
      let Title = "";
      let status = "";
      let Track = "";
      let email = "";
      let createdAt = "";
      let institutionName = "";
      let assignedTo = "";
      let avgRating = "";
      let supportedSDGs = "";
      let solutionProposing = "";
      let teamId = "";
      let team = "";
      let phone = "";

      submittedby =
        element.user_info.name.first_name + element.user_info.name.last_name;
      status = getStatus(element.status);
      Track = element.request_type;
      Title = element.title;
      email = element.user_info.email;
      createdAt = element.createdAt;
      institutionName = element.user_info.institutionName;
      assignedTo = element.assignedTo;
      avgRating = element.avgRating;
      supportedSDGs = element.supportedSDGs;
      solutionProposing = element.solutionProposing;
      teamId = element.teamId && teamId.name;
      team = element.team;
      phone = element.user_info.phone;

      if (!status) {
        status = "-N.A-";
      }
      if (!submittedby) {
        submittedby = "";
      }

      if (!Track) {
        Track = "";
      }
      if (!Title) {
        Title = "";
      }
      if (!email) {
        email = "";
      }
      if (!createdAt) {
        createdAt = "";
      }
      if (!institutionName) {
        institutionName = "";
      }
      if (!assignedTo) {
        assignedTo = "";
      }
      if (!avgRating) {
        avgRating = "";
      }
      if (!supportedSDGs) {
        supportedSDGs = "";
      }
      if (!solutionProposing) {
        solutionProposing = "";
      }
      if (!teamId) {
        teamId = "";
      }
      if (!team) {
        team = "";
      }
      if (!phone) {
        phone = "";
      }

      csvStr +=
        submittedby +
        "," +
        email +
        "," +
        createdAt +
        "," +
        status +
        "," +
        institutionName +
        "," +
        Track +
        "," +
        assignedTo +
        "," +
        avgRating +
        "," +
        Title +
        "," +
        supportedSDGs +
        "," +
        solutionProposing +
        "," +
        teamId +
        "," +
        team +
        "," +
        phone +
        "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = `Donations-${moment().format(
      `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`
    )}.csv`;
    hiddenElement.click();
  };
  const onDeleteDonationRequest = (donationRequestId) => {
    if (
      window.confirm("Are you sure you want to delete the donation request?")
    ) {
      deleteDonationRequest(donationRequestId, authUser.unique_id)
        .then(() => {
          // Update the list of donation requests
          setDonationRequests((donationRequests) => {
            return donationRequests.filter(
              (donationRequest) => donationRequest._id !== donationRequestId
            );
          });
          toastr.success("DonationRequest is deleted successfully.");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const updateSavedDonationRequest = (updatedDonationRequest) => {
    setDonationRequests((donationRequests) => {
      return donationRequests.map((donationRequest) => {
        if (donationRequest._id === updatedDonationRequest._id) {
          return {
            ...donationRequest,
            status: updatedDonationRequest.status,
            title: updatedDonationRequest.title,
            rank: updatedDonationRequest.rank,
          };
        }
        return donationRequest;
      });
    });
    setIsOpenDonationRequestModal(false);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (event.target.value) {
        event.target.blur();
        setKeyword(event.target.value);
      }
    }
  };

  const clearSearchResultsHandler = () => {
    setDonationRequests([]);
    setIsSearchMode(false);
    setKeyword("");
    setPage(0);
  };
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const handleJudgeallocation = () => {
    setRadioMenu("");
    setOpenOrgModal(true);
    setDonationRequestId(donationRequestId);
  };
  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    setRadioMenu("");
    props.history.push(`/organization/${orgId}`);
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="barnav">
        <Container MaxWidth="lg">
          <div className="search-right">
            <div className="admin-head">
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h2>Manage Submitted Ideas</h2>
            </div>
            <div className="admin-head">
              <FormControl
                variant="filled"
                className={classes.formcontrol}
                style={{ width: "150px" }}
              ></FormControl>

              <div className="search-admin">
                <InputBase
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onKeyPress={(e) => handleEnterClick(e)}
                />
                <SearchRounded style={{ margin: "0 4px", height: "30px" }} />
              </div>

              {isSearchMode && (
                <p className="result" style={{ marginLeft: "20px" }}>
                  Search Results for
                  <span
                    style={{
                      padding: "3px 10px 5px 10px",
                      marginLeft: "10px",
                      fontWeight: " bold",
                      color: "#686868",
                      background: "#f8f8f8",
                    }}
                  >
                    {keyword}
                  </span>
                  <IconButton
                    edge="start"
                    aria-label="close"
                    style={{
                      padding: "3px",
                      color: "rgb(205, 38, 39)",
                      position: "relative",
                      top: "-2px",
                      margin: "0 0 0 10px",
                      border: "1px solid #ededed",
                    }}
                  >
                    <Tooltip title="Clear Search Results">
                      <CloseIcon onClick={clearSearchResultsHandler} />
                    </Tooltip>
                  </IconButton>
                </p>
              )}
            </div>
          </div>
          <div className="search-admin-all">
            {" "}
            <FormControl variant="filled" className="admin-select">
              <InputLabel htmlFor="Select Payment mode">
                Select Order
              </InputLabel>
              <Select
                variant="outlined"
                name="newest"
                value={newest}
                label="Select Order"
                onChange={onChangeOrder}
              >
                <MenuItem value="-1">Newest First</MenuItem>
                <MenuItem value="1">Oldest First </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" className="admin-select">
              <InputLabel htmlFor="Select Payment mode">
                Select Track
              </InputLabel>
              <Select
                variant="outlined"
                name="sourceTrackFilter"
                value={sourceTrackFilter}
                label="Select Track"
                onChange={onTrackChange}
              >
                <MenuItem value=" ">All</MenuItem>
                <MenuItem value="social advocacy">Social Advocacy</MenuItem>
                <MenuItem value="social entrepreneurship">
                  Social Entreprenuership
                </MenuItem>
                <MenuItem value="cash">Social Philonthraphy</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              className={classes.formcontrol}
              style={{ width: "150px" }}
            >
              <InputLabel htmlFor="Select Payment mode">Select SDG</InputLabel>
              <Select
                variant="outlined"
                name="sdgs"
                value={sdgs}
                label="Select sdgs"
                onChange={onSdgsChange}
              >
                <MenuItem value=" ">All</MenuItem>
                <MenuItem value="No Poverty">1-No Poverty</MenuItem>
                <MenuItem value="No Hunger">2-No Hunger</MenuItem>
                <MenuItem value="Good Health">3-Good Health</MenuItem>
                <MenuItem value="Innovation and Infrastructure">
                  4-Innovation and Infrastructure
                </MenuItem>
                <MenuItem value="Renewable Energy">5-Renewable Energy</MenuItem>
                <MenuItem value="Good Jobs and Economic Growth">
                  6-Good Jobs and Econamic Growth
                </MenuItem>
                <MenuItem value="Gender Equality">7-Gender Equality</MenuItem>
                <MenuItem value="Clean Water and Sanitization">
                  8-Clean Water and Sanitization
                </MenuItem>
                <MenuItem value="Responsible Consumption">
                  9-Responsible Consumption{" "}
                </MenuItem>
                <MenuItem value="Climate Action">10-Climate Action</MenuItem>
                <MenuItem value="Life Below Water">
                  11-Life Below Water
                </MenuItem>
                <MenuItem value="Reduced Inequalities">
                  12-Reduced Inequalities
                </MenuItem>
                <MenuItem value="Partnerships For the Goals">
                  13-Partnership For the Goals
                </MenuItem>
                <MenuItem value="Sustainable Cities and Communities">
                  14-Sustainable Cities and Communities
                </MenuItem>
                <MenuItem value="Life on Lands">15-Life on Land</MenuItem>
                <MenuItem value="Peace and Justice">
                  16-Peace and Justice
                </MenuItem>
                <MenuItem value="Quality Education">
                  17-Quality Education
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" className="admin-select">
              <InputLabel htmlFor="Select Payment mode">
                Select Status
              </InputLabel>
              <Select
                variant="outlined"
                name="status"
                value={status}
                label=" Select status"
                onChange={onstatuschnge}
              >
                <MenuItem value=" ">All</MenuItem>
                <MenuItem value="-2">SAVED</MenuItem>
                <MenuItem value="0">SUBMITTED </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" className="admin-select">
              <InputLabel htmlFor="Select Payment mode">
                Select Judge
              </InputLabel>
              <Select
                variant="outlined"
                name="sourceOfSignup"
                // value={sourceofSignupFilter}
                label="Select judge"
                // onChange={(e) => onSourceofSignupChange(e)}
              >
                <MenuItem value=" ">A</MenuItem>
                <MenuItem value="-2">B</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" className="admin-select">
              <InputLabel htmlFor="Select Payment mode">
                Select Allocation Status
              </InputLabel>
              <Select
                variant="outlined"
                name="allocationfilter"
                value={allocationfilter}
                label="allocationfilter"
                onChange={(e) => onAllocation(e)}
              >
                <MenuItem value=" ">Judge Un-Allocated</MenuItem>
                <MenuItem value=" ">Judge Allocated/All Assigned</MenuItem>
                <MenuItem value="">Mentor Allocated</MenuItem>
                <MenuItem value="">Mentor UnAllocated</MenuItem>
                <MenuItem value="-2">Only Reviewed</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" className="admin-select">
              <InputLabel htmlFor="Select Payment mode">
                Select Country
              </InputLabel>
              <Select
                variant="outlined"
                name="country"
                value={country}
                label="Select country"
                onChange={oncountrychange}
              >
                <MenuItem value="India">India</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
              </Select>
            </FormControl>
            <div>
              {term1 && (
                <Button
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    color: "white",
                    height: "55px",
                    margin: "0",
                    backgroundColor: "var(--tal_primary) ",
                  }}
                  onClick={handleJudgeallocation}
                >
                  <CheckIcon />
                  Allocate Judge
                </Button>
              )}
            </div>
            <div>
              {" "}
              <Button
                startIcon={<FileCopyOutlinedIcon />}
                onClick={exportToCSV}
                style={{
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  height: "55px",
                  margin: "0",
                  backgroundColor: "var(--tal_primary) ",
                }}
              >
                Export Data
              </Button>
            </div>
            <FormControl style={{ cursor: "pointer" }}>
              <Button
                style={{
                  borderRadius: "5px",
                  marginRight: "0",
                  height: "55px",
                }}
              >
                <HighlightOff onClick={clearState} />
                Clear All Filters
              </Button>
            </FormControl>
          </div>
        </Container>
      </div>

      <Container MaxWidth="lg">
        <div className="myDonations-container">
          <div className="menu-container">
            <div style={{ width: "100%", overflowX: "auto" }}>
              {closeSideMenu && (
                <div style={{ marginRight: "10px" }}>
                  <SideMenu />
                </div>
              )}
              <div className="table-fixed drop-box">
                <TableContainer className="admin-container">
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="sticky table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow style={{ background: "#000", color: "#fff" }}>
                        <TableCell align={"left"} className="table-header-text">
                          #
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Submitted By
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Submitted Email
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Submitted On
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Status
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Institution
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Track
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Assigned To
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Avg. Rating
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Mentor Information
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Title
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          SDGs
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Proposed Solution
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Team Name
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Team Members
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Mobile Number
                        </TableCell>
                        <TableCell align={"left"} className="table-header-text">
                          Location
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                    //ref={infiniteRef}
                    >
                      {donationRequests
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell>
                                <div className="adminsrn">
                                  <RemoveRedEyeOutlinedIcon />
                                  <Tooltip title="Delete">
                                    <IconButton
                                      className="del-btn"
                                      onClick={() =>
                                        onDeleteDonationRequest(row._id)
                                      }
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {/* {judgeallocation === true && ( )} */}
                                  <Tooltip title="Allocate judges">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="term1"
                                          // checked={term1}
                                          onChange={handleTerm1}
                                          onClick={() =>
                                            setDonationRequestId(row._id)
                                          }
                                        />
                                      }
                                    />
                                    {/* <Checkbox
                                      onChange={handleJudgeallocation}
                                      onClick={() =>
                                        setDonationRequestId(row._id)
                                      }
                                    /> */}
                                  </Tooltip>

                                  <Judgesallocation
                                    donationRequestId={donationRequestId}
                                    openOrgModal={openOrgModal}
                                    onCloseModal={(e) => setOpenOrgModal(false)}
                                    onSavingOrg={onSavingOrg}
                                  />
                                  {/* {judgeallocation === false && <Checkbox />} */}
                                </div>
                              </TableCell>
                              <TableCell>
                                {row.user_info.name.first_name}
                                {row.user_info.name.last_name}
                              </TableCell>
                              <TableCell>{row.user_info.email}</TableCell>
                              <TableCell>
                                {" "}
                                {moment(row.createdAt).format(DATE_TIME_FORMAT)}
                              </TableCell>

                              <TableCell className="admin-status">
                                {" "}
                                {
                                  <div>
                                    <Chip
                                      label={
                                        getStatus(row.status) ||
                                        (!getStatus(row.status) && "-N.A-")
                                      }
                                      style={{
                                        background: getColor(row.status),
                                      }}
                                    />
                                  </div>
                                }
                                <div>
                                  <Tooltip title="Keep in Hold">
                                    <SubdirectoryArrowRightIcon />
                                  </Tooltip>
                                  <Tooltip title="Move to Final">
                                    <CheckIcon />
                                  </Tooltip>
                                  <Tooltip title="Mnage Judges">
                                    <PersonIcon />
                                  </Tooltip>
                                </div>
                              </TableCell>

                              <TableCell>
                                {row.user_info.institutionName}
                              </TableCell>
                              <TableCell>{row.request_type}</TableCell>
                              <TableCell>
                                {" "}
                                <Grid item md={8} xs={12}>
                                  <>
                                    <Grid container style={{ gap: "5px" }}>
                                      {row.assignedJudges.map((data) => {
                                        return <p>{data.email} </p>;
                                      })}
                                    </Grid>
                                  </>
                                </Grid>
                              </TableCell>

                              <TableCell>{row.avgRating}</TableCell>
                              {row &&
                              row.user_info &&
                              row.user_info.isAssignMentor &&
                              row.user_info.isAssignMentor === true ? (
                                <TableCell>
                                  Requested for Mentor Allocation
                                </TableCell>
                              ) : (
                                <TableCell>
                                  {row.user_info.mentorInfo.firstName}
                                  <br />
                                  {row.user_info.mentorInfo.lastName}
                                  <br />
                                  {row.user_info.mentorInfo.phone}
                                  <br />
                                  {row.user_info.mentorInfo.email}
                                </TableCell>
                              )}
                              <TableCell>{row.title}</TableCell>
                              <TableCell>
                                {" "}
                                <Grid
                                  item
                                  md={8}
                                  xs={12}
                                  className="text-ucase"
                                >
                                  <>
                                    <Grid container style={{ gap: "5px" }}>
                                      {row.supportedSDGs.map((data) => {
                                        return (
                                          <Chip
                                            className=" bg-secondary"
                                            label={data}
                                            style={{ color: "#fff" }}
                                            variant="outlined"
                                          />
                                        );
                                      })}
                                    </Grid>
                                  </>
                                </Grid>
                              </TableCell>
                              <TableCell>{row.solutionProposing}</TableCell>

                              {row && row.teamId && row.teamId.name ? (
                                <TableCell>{row.teamId.name}</TableCell>
                              ) : (
                                <TableCell> {""} </TableCell>
                              )}
                              <TableCell>{row.team}</TableCell>
                              <TableCell>{row.user_info.phone}</TableCell>
                              <TableCell>
                                {row.shipping_address.country},
                                {row.shipping_address.state},
                                {row.shipping_address.city}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {isLoading && (
                        <p style={{ textAlign: "center" }}>Loading...</p>
                      )}
                      {donationRequests && donationRequests.length === 0 && (
                        <Typography className="no-req-text">
                          No Donation Requests Found
                        </Typography>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>

              <DonationRequestForm
                donationRequestId={donationRequestId}
                open={isOpenDonationRequestModal}
                close={() => setIsOpenDonationRequestModal(false)}
                onSave={updateSavedDonationRequest}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SubmittedIdeas;

import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import toastr from "toastr";
import DeleteIcon from "@material-ui/icons/Delete";
import { appContext } from "../../App";
import { getUsers, updateProfile } from "../../utils/api";
import RJDetails from "./RJDetails";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },

    "& figure": {
      textAlign:"center",
      "& img": {
        borderRadius: "50%",
        padding: "10px",
        width: "100px",
        height: "100px",
        background:" #f8f8f8",
      },
    },
  },
  rj: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
    paddingRight: "5px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      flexDirection: "column",
    },

    "& h2": {
      fontSize: "22px",
      fontWaite: "bold",
      color: "#ef6c00",
    },
    "& Button": {
      color: "white",
      backgroundColor: "rgb(205, 38, 39)",
      "&:hover": {
        backgroundColor: "rgb(205, 38, 39)",
      },
    },
  },
}));

function TotalRJs() {
  const { authUser } = useContext(appContext);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const confirmDelete = (recordId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      updateProfile(recordId, { $pull: { roles: { $in: ["radiojockey"] } } })
        .then((delResp) => {
          if (delResp.data.statusCode === 200) {
            var oldRows = rows.filter((item) => {
              return item.id !== recordId;
            });
            setRows([...oldRows]);

            toastr.success(
              "Removed radio jockey - " + delResp.data.data.name.first_name
            );
          } else {
            toastr.error("Error while removing the Radio Jockey");
          }
        })
        .catch((e) => toastr.error("Failed to remove the Radio Jockey"));
    }
  };

  const loadTotalRJs = (id) => {
    setIsLoading(true);
    getUsers("radiojockey")
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          response.data.map((details) => {
            let lan = "";
            details.languages &&
              details.languages.map((language, index) => {
                if (index > 0) lan += " - ";
                lan += language;
                return lan;
              });

            rows.push({
              id: details._id,
              name: details.name.first_name,
              image: details.image_url
                ? details.image_url
                : "/images/no-photo-available.png",
              languages: lan,
            });
            return rows;
          });
        }
        setIsLoading(false);
        return rows;
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching radio jockey: " + error.message);
      });
  };

  useEffect(() => {
    if (authUser) {
      loadTotalRJs(authUser.unique_id);
    }
  }, [authUser]);

  const classes = useStyles();
  return (
    <Box>
      <Box className="drop-box" p={1} mb={1}>
        <Box className={classes.rj}>
          <h2>TAL Radio RJ's </h2>
          <Button variant="contained" onClick={() => setOpen(true)}>
            + Invite Radio Jockey
          </Button>
        </Box>
      </Box>
      <div
        style={{
       display:"grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "10px",
        }}
      >
        {rows &&
          rows.length > 0 &&
          rows.map((row, index) => (
            <Card key={index} className={classes.card}>

              <Box minHeight="183px" maxHeight="183px" my={2}>
                  <figure>
                    <img src={row.image} alt="rj" />
                  </figure>
                  <CardHeader
                    title={row.name}
                    subheader={row.languages}
                    style={{ textAlign: "center", padding: "0 10px" }}
                  />
              </Box>

              <CardActions
                disableSpacing
                style={{
                  borderTop: "1px solid rgb(246, 247, 251)",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={() => confirmDelete(row.id)}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        {isLoading && (
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              padding: "10px 0",
            }}
          >
            Loading...
          </Typography>
        )}
        {(!rows || rows.length === 0) && !isLoading && (
          <Typography
            style={{
              textAlign: "center",
            }}
          >
            No records found
          </Typography>
        )}
      </div>
      <RJDetails open={open} handleClose={() => setOpen(false)} />
    </Box>
  );
}
export default TotalRJs;

import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import toastr from "toastr";
// import cssstyles from "./TalHeroProfile.module.scss";
import cssstyles from "./TalLeaderProfile.module.scss";
import { withRouter } from "react-router-dom";
import { checkUrlWithHttp, getUserData, updateProfile } from "../../utils/api";
import PhoneInput from "react-phone-number-input";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import { TAL_HEROS_COUNTRIES } from "../../utils/utils";
import { appContext } from "../../App";
import Loader from "../common/Loader";
import validator from "validator";
import RejectorApproveTALLeaders from "./RejectorApproveTALLeaders";
import { isPossiblePhoneNumber } from "react-phone-number-input";
const TalLeaderProfile = (props) => {
  const { region } = useContext(appContext);

  const { usersData, setUserData, edit } = props;
  const [city, setCity] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyNmae] = useState("");
  const [mobile, setMobile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [email, setEmail] = useState("");
  const [regionFilter, setRegionFilter] = useState(" ");
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState("");
  const [otherErrors, setOtherErrors] = useState({});

  const onChnageFirstname = (event) => {
    setFirstName(event.target.value);
  };
  const onChnageLastname = (event) => {
    setLastName(event.target.value);
  };
  const onChangeCompanyName = (event) => {
    setCompanyNmae(event.target.value);
  };
  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
    }
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleRegionFilter = (event) => {
    setRegionFilter(event.target.value);
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangeMobile = (phone) => {
    setMobile(phone);
  };
  const updateUserProfile = () => {
    const finalValues = {};
    let errors = {};
    finalValues.currentCompanyName = companyName;
    finalValues.address = {
      state: city,
      country: regionFilter,
    };
    finalValues.name = {
      ...finalValues.name,
      first_name: firstName,
    };
    finalValues.name = {
      ...finalValues.name,
      last_name: lastName,
    };
    if (!mobile || !isPossiblePhoneNumber(mobile)) {
      errors.mobile = "Please enter a valid phone number";
    } else {
      finalValues.phone = mobile;
    }
    if (!email) {
      errors.email = "Please enter a  email address";
    } else if (!validator.isEmail(email)) {
      errors.email = "Please enter a valid email address";
    } else {
      finalValues.email = email;
    }
    if (Object.keys(errors).length > 0) {
      setOtherErrors(errors);
    } else {
      updateProfile(usersData && usersData.unique_id, finalValues)
        .then((response) => {
          toastr.success("Profile updated successfully");
          setUserData(response.data.data);
          setOtherErrors({});
        })
        .catch((error) => {
          toastr.error("Failed to update the userData");
        });
      props.setEdit();
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (usersData && usersData.unique_id) {
      setFirstName(usersData && usersData.name && usersData.name.first_name);
      setLastName(usersData && usersData.name && usersData.name.last_name);
      setMobile(usersData && usersData.phone);
      setEmail(usersData && usersData.email);

      setRegionFilter(
        usersData && usersData.address && usersData.address.country
      );
      setCity(usersData && usersData.address && usersData.address.state);
      setCompanyNmae(usersData && usersData.currentCompanyName);
      setIsLoading(false);
    }
  }, [usersData]);

  const handleActions = (statusFromAction) => {
    setStatus(statusFromAction);
    setOpenModal(true);
  };

  const handleDiscord = () => {
    props.toggleDrawer();
  };

  return (
    <div>
      <div>
        <div className={cssstyles.Fade_style}>
          {/* <h1 className={classes.orgTitle}>TALHero profile</h1> */}

          <div className={cssstyles.fade}>
            <div className={cssstyles.Fad_style}>
              <div className={cssstyles.img_style}>
                <img
                  style={{
                    width: "105px",
                    height: "105px",
                    borderRadius: "17px",
                    border: "4px solid #FFF",
                  }}
                  src={
                    usersData && usersData.profile_image_url
                      ? usersData && usersData.profile_image_url
                      : "../images/tal-leader-icon.svg"
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div className={cssstyles.Fad_style}>
                  <div className={cssstyles.img_style}></div>
                  {!edit && !editMode && (
                    <div className={cssstyles.name_text}>
                      <span className={cssstyles.name_title}>
                        {usersData &&
                          usersData.name &&
                          usersData.name.first_name}{" "}
                        {usersData &&
                          usersData.name &&
                          usersData.name.last_name}{" "}
                      </span>
                      <p>
                        {usersData && usersData.currentRole}
                        <strong>
                          {" "}
                          {usersData && usersData.currentCompanyName && (
                            <>
                              <span style={{ fontWeight: "normal" }}>At </span>
                              {usersData.currentCompanyName}
                            </>
                          )}
                        </strong>
                      </p>
                      <span>
                        {usersData &&
                          usersData.address &&
                          usersData.address.state}

                        <span>
                          {usersData &&
                            usersData.address &&
                            usersData.address.country &&
                            ", " + usersData.address.country}
                        </span>
                      </span>
                    </div>
                  )}
                  {(edit || editMode) && (
                    <div>
                      <div>
                        <TextField
                          variant="filled"
                          placeholder="Edit Frist Name"
                          name="firstName"
                          className="input-fields edit-usernames"
                          style={{ background: "#fff !important" }}
                          value={firstName}
                          onChange={onChnageFirstname}
                        />
                      </div>
                      <div>
                        <TextField
                          variant="filled"
                          placeholder="Edit Last Name"
                          name="lastName"
                          className="input-fields edit-usernames"
                          style={{ background: "#fff !important" }}
                          value={lastName}
                          onChange={onChnageLastname}
                        />
                      </div>
                      <div>
                        <TextField
                          variant="filled"
                          placeholder="company Name"
                          name="companyName"
                          className="input-fields edit-usernames"
                          style={{ background: "#fff !important" }}
                          value={companyName}
                          onChange={onChangeCompanyName}
                        />
                      </div>
                      <InputLabel
                        name="region"
                        placeholder="Edit Location"
                        value={regionFilter}
                      >
                        <Select
                          className={cssstyles.location_aglin}
                          variant="outlined"
                          name="region"
                          placeholder="Edit Location"
                          value={regionFilter}
                          onChange={(event) => handleRegionFilter(event)}
                        >
                          <MenuItem value=" ">All Countries</MenuItem>
                          {TAL_HEROS_COUNTRIES &&
                            TAL_HEROS_COUNTRIES.length > 0 &&
                            TAL_HEROS_COUNTRIES.map((country, index) => {
                              return (
                                <MenuItem value={country.key} key={index}>
                                  {country && country.value}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </InputLabel>
                      <div>
                        <TextField
                          value={city}
                          name="city"
                          onChange={handleChangeCity}
                          placeholder="Edit City Name"
                          variant="filled"
                          className="input-fields edit-usernames"
                          style={{ background: "#fff !important" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <span className={cssstyles.drop_box_close_organizatin}>
                    <Button>
                      <a
                        href={
                          usersData &&
                          checkUrlWithHttp(usersData.linkedInProfileUrl)
                        }
                        target="_blank"
                      >
                        <Button>
                          Visit profile
                          <img
                            className={cssstyles.profile_lenth}
                            src="../images/external-link.svg"
                            alt="mail"
                          />
                        </Button>
                      </a>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={cssstyles.Veiw_align}>
            <CloseIcon onClick={props.toggleDrawer} />
          </div>
        </div>

        {/* <div className={cssstyles.Veiw_align}>
          <CloseIcon />
        </div> */}

        <div className={cssstyles.plasma} style={{ position: "relative" }}>
          <div className={cssstyles.profile_mrg}>
            <Typography className={cssstyles.profile_p}>Contact:</Typography>
          </div>
          {!edit && !editMode && (
            <div className={cssstyles.text_flex}>
              <div className={cssstyles.text_mrg}>
                <div>
                  <img
                    className={cssstyles.profile_img}
                    src="../images/phone-profile.svg"
                    alt="phone"
                  ></img>
                </div>
                <div style={{ marginRight: "30px" }}>
                  <p>{usersData && usersData.phone}</p>
                </div>
              </div>
              <div className={cssstyles.text_mrg}>
                <div>
                  {" "}
                  <img
                    className={cssstyles.profile_img}
                    src="../images/mail.svg"
                    alt="mail"
                  ></img>
                </div>
                <div>
                  {" "}
                  <p>{usersData && usersData.email}</p>
                </div>
              </div>
            </div>
          )}
          {(edit || editMode) && (
            <div className={cssstyles.text_flex}>
              <div className={cssstyles.text_mrg}>
                <div style={{ position: "relative" }}>
                  <PhoneInput
                    variant="outlined"
                    fullWidth
                    className={cssstyles.phone_input}
                    placeholder=" Phone number"
                    name="mobile"
                    defaultCountry={""}
                    value={mobile}
                    onChange={handleChangeMobile}
                    // onKeyPress={(e) => handleEnterClick(e)}
                    label="Phone number"
                  />
                  {otherErrors && otherErrors.mobile && (
                    <Typography style={{ color: "red" }}>
                      {" "}
                      {otherErrors.mobile}
                    </Typography>
                  )}
                </div>
              </div>

              <div className={cssstyles.text_mrg}>
                <div style={{ position: "relative", marginLeft: "15px" }}>
                  <TextField
                    variant="outlined"
                    value={email}
                    name="email"
                    onChange={onChangeEmail}
                    // className={classes.margin}
                    className="Edit-profile"
                    id="input-with-icon-textfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div>
                            <img
                              className={cssstyles.profile_img}
                              src="../images/mail.svg"
                              alt="mail"
                            ></img>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div>
                    {" "}
                    {otherErrors && otherErrors.email && (
                      <Typography style={{ color: "red" }}>
                        {" "}
                        {otherErrors.email}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={cssstyles.profile_mrg}>
            <Typography className={cssstyles.profile_p}>
              Areas of Interest
            </Typography>
          </div>

          <div>
            <div className={cssstyles.chip_design}>
              {usersData &&
                usersData.areasOfInterest &&
                usersData.areasOfInterest.length > 0 &&
                usersData.areasOfInterest.map((item) => {
                  return (
                    <Button className={cssstyles.tabs_chip} label={item}>
                      {item}
                    </Button>
                  );
                })}
            </div>
          </div>

          <div className={cssstyles.profile_mrg}>
            <Typography className={cssstyles.profile_p}>
              Are you willing to give at least one hour per week ?
            </Typography>
          </div>
          <div>
            <Typography className="chip-design">
              {" "}
              <Chip
                label={usersData && usersData.oneHourPerWeek ? "Yes" : "No"}
              >
                {" "}
              </Chip>
            </Typography>
          </div>
          <div className={cssstyles.profile_mrg}>
            <Typography className={cssstyles.profile_p}>
              Are you willing to be management/Leadership position in
              Touch-A-Life ?
            </Typography>
          </div>
          <div>
            <Typography className="chip-design">
              {" "}
              <Chip
                label={usersData && usersData.managementPosition ? "Yes" : "No"}
              >
                {" "}
              </Chip>
            </Typography>
          </div>

          <div className={cssstyles.profile_mrg}>
            <Typography className={cssstyles.profile_p}>
              Please provide TALLeaders reference(TALLeaders Name)?
            </Typography>
          </div>
          <div>
            <Typography> {usersData && usersData.referenceName} </Typography>
          </div>
          <div className={cssstyles.profile_mrg}>
            <Typography className={cssstyles.profile_p}>
              Employer/Employee Reference
            </Typography>
          </div>
          <div className={cssstyles.profile_mrg}>
            <Typography>{usersData && usersData.empReference}</Typography>
          </div>
          <div style={{ position: "relative" }}>
            <div className={cssstyles.profile_border}>
              <Typography className={cssstyles.profile_p}>
                What is impact would you like to make on society and causes you
                are interested in serving
              </Typography>
              <div>
                <p>{usersData && usersData.serveAndMakeImpact}</p>
              </div>
            </div>
            <div className={cssstyles.profile_border}>
              <Typography className={cssstyles.profile_p}>
                How would you like to serve and make an impact in causes you are
                interested in? e.g. Become a Board member of a charity, mentor
                charitable organizations and leadership, become involved as a
                leader in a particular cause
              </Typography>
              <div>
                <p>{usersData && usersData.serveAndMakeImpact}</p>
              </div>
            </div>
            <div className={cssstyles.profile_border}>
              <Typography className={cssstyles.profile_p}>
                How did you hear about TALLeaders?
              </Typography>

              <div>
                <p>
                  <Button>{usersData && usersData.howDidYouHear}</Button>
                </p>
              </div>
            </div>
          </div>
          <div className={cssstyles.edit_reject_Short_btn}>
            {!edit && !editMode && (
              <div className={cssstyles.edit_profile}>
                <Button
                  className={cssstyles.Approve_clr}
                  onClick={(e) => setEditMode(true)}
                >
                  {" "}
                  <img
                    className={cssstyles.profile_img}
                    src="../images/edit-profile.svg"
                    alt="mail"
                  ></img>
                  Edit
                </Button>
              </div>
            )}

            {(edit || editMode) && (
              <div className={cssstyles.save_Button}>
                <div className={cssstyles.rejects_color}>
                  <Button
                    onClick={(e) => handleDiscord()}
                    className={cssstyles.Approve_clr}
                  >
                    Discard
                  </Button>
                </div>
                <div className={cssstyles.Approves_color}>
                  <Button
                    className={cssstyles.Approve_clr}
                    onClick={updateUserProfile}
                  >
                    Update
                  </Button>
                </div>
              </div>
            )}
            {!edit && !editMode && props.showRejectAndAcceptButtons && (
              <div style={{ display: "flex" }}>
                <div className={cssstyles.reject_color}>
                  <Button onClick={() => handleActions(-1)}> Reject</Button>
                </div>
                <div className={cssstyles.Approve_color}>
                  <Button onClick={() => handleActions(1)}>Approve</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>{" "}
      <RejectorApproveTALLeaders
        id={usersData && usersData.unique_id}
        status={status}
        openModal={openModal}
        onCloseModal={() => setOpenModal(false)}
        setTalLeadersList={props.setTalLeadersList}
      />
    </div>
  );
};

export default withRouter(TalLeaderProfile);

import firebase from "../../../firebase/firebase";


const getAll = (collection) => {
  const db = firebase.db.collection(collection);
  return db;
};

const create = (collection, data) => {
  const db = firebase.db.collection(collection);
  return db.add(data);
};
const update = (collection, id, value) => {
  const db = firebase.db.collection(collection);
  return db.doc(id).update(value);
};
const remove = (collection, id) => {
  const db = firebase.db.collection(collection);
  return db.doc(id).delete();
};

const FirestoreService = {
  getAll,
  create,
  update,
  remove,
};

export default FirestoreService;

import React, { useEffect, useState } from "react";
import { getChannels, deleteChannel, updateChannel } from "../../utils/api";
import toastr from "toastr";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, IconButton, Switch, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ChannelsForm from "./ChannelsForm";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
    "& td:nth-of-type(5)": {
      whiteSpace: "nowrap",
    },
    "& td:nth-of-type(6)": {
      whiteSpace: "nowrap",
    },
  },

  rightmenu: {
    "& Button": {
      marginLeft: "5px",
    },

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  channels: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    lineHeight: "30px",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      height: "12px",
      backgroundColor: "#31A93D",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "#ef6c00",
      fontSize: "22px",
      fontWaite: "bold",
      marginRight: "5px",
    },
    "& h4": {
      fontSize: "16px",
      color: "#575757",
      margin: "0",
      lineHeight: "35px",
    },

    "&  Button:nth-of-type(1)": {
      border: "#CD2729 2px solid",
      backgroundColor: "white",
      color: "#CD2729",
      borderRadius: "5px",
      padding: "5px",
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "#CD2729",
      color: "white",
    },
  },
}));
function Channels(props) {
  const [channels, setChannels] = useState([]);
  const [isLoading] = useState(false);
  const [isEnableEdit, setIsEnableEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isDisplayChannels, setIsDisplayChannels] = useState(true);

  const loadChannels = () => {
    getChannels(true)
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setChannels(response.data);
        }
      })
      .catch((error) => {
        toastr.error("Problem in  Channels:" + error.message);
      });
  };

  const handleChannels = (id) => {
    setIsDisplayChannels(false);
    if (id) {
      setIsEnableEdit(true);
      setSelectedId(id);
    }
  };

  const confirmDelete = (channel) => {
    if (window.confirm(`Are you sure you want to delete - ${channel.name}?`)) {
      const channelId = channel._id;
      deleteChannel(channelId)
        .then((delResp) => {
          if (delResp.statusCode === 200) {
            const newRows = channels.filter((item) => {
              if (item._id != channelId) {
                return item;
              }
            });
            setChannels(newRows);

            toastr.success(delResp.message);
          } else {
            toastr.error("Error while deleting the channels");
          }
        })
        .catch((e) => {
          toastr.error("Failed to delete the channels", e.message);
        });
    }
  };

  const toggleIsDefault = (event, index, channelId) => {
    const checkedStatus = event.target.checked;
    updateChannel(channelId, { isDefault: checkedStatus })
      .then((updateResp) => {
        var list = [];
        if (updateResp.statusCode === 200) {
          var filterRows = channels;
          filterRows.map((item) => {
            if (item._id === channelId && checkedStatus) {
              item.isDefault = true;
            } else {
              item.isDefault = false;
            }
            list.push(item);
          });
          setChannels(list);

          toastr.success(updateResp.message);
        } else {
          toastr.error("Error while updating the channels");
        }
      })
      .catch(() => {
        toastr.error("Editor Pick: Failed to update the channels");
      });
  };

  useEffect(() => {
    loadChannels();
  }, []);

  const classes = useStyles();

  if (!isDisplayChannels) {
    return (
      <ChannelsForm
        setIsDisplayChannels={setIsDisplayChannels}
        channels={channels}
        isEnableEdit={isEnableEdit}
        setIsEnableEdit={setIsEnableEdit}
        selectedId={selectedId}
        setChannels={setChannels}
        rows={channels}
        setRows={setChannels}
      />
    );
  } else
    return (
      <Box>
        <Box className="drop-box" p={1} mb={1}>
          <Box className={classes.Channels}>
            <Grid container>
              <Grid item xs={12} sm={3} md={3}>
                <Box display="flex" alignItems="center">
                  <h2>Manage Channels</h2>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <Box className={classes.rightmenu}>
                  <Button variant="contained" onClick={() => handleChannels()}>
                    {" "}
                    + Add Channels{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={classes.table_tab}>
          <TableContainer component={Paper}>
            <Table aria-label="all channels table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Default</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {channels &&
                  channels.length > 0 &&
                  channels.map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>
                        <Switch
                          checked={row.isDefault}
                          name="isDefault"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onClick={(event) =>
                            toggleIsDefault(event, index, row._id)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleChannels(row._id)}
                        >
                          <EditIcon
                            style={{
                              color: "green",
                              padding: "5px",
                              margin: "5px",
                              borderRadius: "50px",
                              fontSize: "30px",
                              boxShadow: "0 0 9px #e8eaf5",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        </IconButton>
                        <IconButton aria-label="delete">
                          <DeleteIcon
                            style={{
                              color: "red",
                              padding: "5px",
                              margin: "5px",
                              borderRadius: "50px",
                              fontSize: "30px",
                              boxShadow: "0 0 9px #e8eaf5",
                              backgroundColor: "#ffffff",
                            }}
                            onClick={() => confirmDelete(row)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {isLoading && (
                  <TableRow align="center">
                    <TableCell colSpan="10">
                      __
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          padding: "10px 0",
                        }}
                      >
                        Loading...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {channels && channels.length === 0 && !isLoading && (
                  <TableRow align="center">
                    <TableCell colSpan="10">
                      <Typography
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
}
export default Channels;

import React from "react";
import { TableRow, TableCell, IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

import "../common/styles.scss";

const VideoRow = (props) => {
  const { name, description, language } = props.document;
  return (
    <TableRow>
      <TableCell>
        <Typography>
          {name}{" "}
          <Tooltip title="Play This Video">
            <IconButton className="del-btn" onClick={props.onPlayHandler}>
              <PlayCircleFilledIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </TableCell>
      <TableCell>{language}</TableCell>
      <TableCell>{description}</TableCell>
      {props.canEdit && (
        <TableCell>
          <Tooltip title="Edit This Document">
            <IconButton className="del-btn" onClick={props.onEditHandler}>
              <EditIcon />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Delete This Document">
            <IconButton className="del-btn" onClick={props.onDeleteHandler}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default VideoRow;

import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Signup from "./components/auth/signup/Signup";
import ScrollToTop from "./components/ScrollTop";
import Login from "./components/auth/login/Login";
import ForgotPassword from "./components/auth/forgotPassword/ForgotPassword";
import ChangePassword from "./components/auth/changePassword/ChangePassword";
import OtpValidation from "./components/auth/OTPValidation/OtpValidation";
import Authorize from "./components/auth/login/Authorize";
import Topbar from "./components/Topbar/Topbar";
import Footer from "./components/footer/Footer";
import DonationRequest from "./components/donationRequest/DonationRequest";
import Payment from "./components/donationDetails/PaymentsPopUp";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Home2 from "./components/FBLayout/Home2";
import Notifications from "./components/Notifications/Notifications";
import ChatRoomForm from "./components/ChatRooms/ChatRoomForm";
import ChatTabs from "./components/ChatRooms/ChatTabs";
import Podcast from "./components/common/Podcast";
import Feedback from "./components/Feedback/Feedback";
import NewUserProfile from "./components/userProfile/newUserProfile";
import VolunteerProfile from "./components/userProfile/volunteerProfile";
import AdminDonationRequests from "./components/Admin/AdminDonationRequests";
import MyFavourites from "./components/donationRequest/MyFavourites";
import ReadingEventCertificate from "./components/MyCertificates/readingEventCeritifcate";
import VolunteerApplications from "./components/Admin/VolunteerApplications";
import ThankYou from "./components/thankyou/Thankyou";
import MyDonations from "./components/donationDetails/MyDonations";
import PublicProfile from "./components/PublicProfile/PublicProfile";
import NewRequest from "./components/NewRequest/NewRequest";
import EditUserName from "./components/EditUsername";
import FAQ from "./components/FAQ/Faq";
import StripePayment from "./components/paymentGateway/stripe/components/StripePayment";
import PublicPayment from "./components/donationDetails/PublicPayment";
import AdminDonations from "./components/Admin/AdminDonations";
import AdminOrganizations from "./components/Admin/AdminOrganizations";
import BecomeAVolunteer from "./components/userProfile/BecomeAVolunteer";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Tooltip } from "@material-ui/core";
import TalRadioPlayer from "./components/common/TalRadioPlayer";
import Organization from "./components/Organization/Organization";
import OrgDetails from "./components/OrgDetails/OrgDetails";
import PlasmaRequest from "./components/PlasmaRequest/PlasmaRequest";
import PlasmaForms from "./components/Plasma/PlasmaForms";
import CareerHelp from "./components/CareerHelp/CareerHelp";
import InviteUser from "./components/InviteUser/InviteUser";
import InviteTeamMember from "./components/InviteUser/inviteTeamMember";
import InviteGeneralUser from "./components/InviteUser/InviteGeneralUser";
import VerifyOrganizationEmail from "./components/InviteUser/verifyOrganizationEmail";
import PlasmaDonorReq from "./components/PlasmaDonorReq/PlasmaDonor";

import ContactEmailCampaign from "./components/donationRequest/ContactEmailCampaign";
import EmailEdit from "./components/donationRequest/EmailEdit";
import EmailImportFiles from "./components/donationRequest/EmailImportFiles";
import EmailEditConfirmation from "./components/donationRequest/EmailEditConfirmation";
import EmailContactGroups from "./components/donationRequest/EmailContactGroups";
import BillingInfo from "./components/donationDetails/BillingInfo";
import DonorEmailCampaign from "./components/donationRequest/DonorEmailCampaign";
import TALRadio from "./components/TALRadio/TALRadio";
import RequestRaised from "./components/withdrawals/RequestRaised";
import MyWithdrawals from "./components/withdrawals/MyWithdrawals";
import AdminWithdrawalRequests from "./components/Admin/AdminWithdrawalRequests";
import CovidHelpRequestForm from "./components/HomeWork/HomeWorkRequest";
import UserActivityLog from "./components/ActivityLogs/UserActivityLog";
import UserList from "./components/UserList";
import SubmittedIdeas from "./components/Admin/SubmittedIdeas";
import ProtectedRoute from "./ProtectedRoute";
import WithdrawForm from "./components/withdrawals/WithdrawForm";
import Channels from "./components/TALRadio/Channels";
import Internship from "./components/Internship/Internship";
import HomeWorkRequest from "./components/HomeWork/HomeWorkRequest";
import PostDetails from "./components/Post/PostDetails";
import Volunteering from "./components/Volunteerings/Volunteerings";
import TalMediaForm from "./components/TALMedia/TalMediaForm";
import InviteJudge from "./components/InviteUser/inviteJudge";
import UserInterests from "./components/auth/UserInterests/UserInterests";
import InviteTALLeader from "./components/InviteUser/inviteTALLeader";
import InvitingATALLeader from "./components/Admin/InvitingATALLeader";
import BoardMemberRequest from "./components/TALLeaders/BoardMemberRequest";
import PodcastRequest from "./components/TALLeaders/PodcastRequest";
import Audio from "./components/liveStreaming/Audio";
import TalLeaders from "./components/TALLeaders/TalLeaders";
import AdminTalLeaders from "./components/TALLeaders/AdminTalLeaders";
import FeedbackTalLeaders from "./components/TALLeaders/FeedbackTalLeaders";
import AdminTALHerosAwards from "./components/TALLeaders/AdminTALHerosAwards";
import TalHeros from "./components/TALLeaders/TalHeros";
import FeedbackTalAwards from "./components/TALLeaders/FeedbackTalAwards";
import AdminAllLeaders from "./components/TALLeaders/AdminAllLeaders";
import AdminNewLeaders from "./components/TALLeaders/AdminNewLeaders";
import MyDashboard from "./components/TALLeaders/MyDashboard";
import EventParticipation from "./components/EventParticipation/EventParticipation";
import MentoringRequest from "./components/Mentoring/MentoringRequest.js";
import ViewApplications from "./components/donationRequest/ViewApplications";
const Routes = () => {
  const [showArrow, setShowArrow] = useState(0);

  useScrollPosition(({ currPos }) => {
    setShowArrow(currPos.y);
  });
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <BrowserRouter>
      <ScrollToTop>
        {window.location.pathname.indexOf("/talradiowidget") < 0 && <Topbar />}
        <div style={{ minHeight: "25px", width: "100%" }} />
        <Box width="100%" minHeight="100vh">
          <Switch>
            <ProtectedRoute exact path="/" component={Home2} />
            <ProtectedRoute path="/search/:keyword" exact component={Home2} />
            <ProtectedRoute path="/search/" exact component={Home2} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/authorize" component={Authorize} />
            <ProtectedRoute
              exact
              path="/downloads/myCertificates"
              component={ReadingEventCertificate}
            />
            <ProtectedRoute
              exact
              path="/newUserProfile/:id"
              component={NewUserProfile}
            />
            <ProtectedRoute exact path="/new-request" component={NewRequest} />
            <ProtectedRoute
              exact
              path="/notifications"
              component={Notifications}
            />
            <ProtectedRoute exact path="/myDashboard" component={MyDashboard} />
            <ProtectedRoute
              exact
              path="/myfavourites"
              component={MyFavourites}
            />
            <ProtectedRoute
              exact
              path="/plasma-donee-donor"
              component={PlasmaForms}
            />
            <ProtectedRoute
              exact
              path="/eventspeaker-request/:id"
              component={EventParticipation}
            />
            <ProtectedRoute
              exact
              path="/eventspeaker-request"
              component={EventParticipation}
            />
            <ProtectedRoute
              exact
              path="/mentoring-request"
              component={MentoringRequest}
            />
            <ProtectedRoute
              exact
              path="/mentoring-request/:id"
              component={MentoringRequest}
            />
            <ProtectedRoute
              exact
              path="/boardmember-request/:id"
              component={BoardMemberRequest}
            />
            <ProtectedRoute
              exact
              path="/boardmember-request"
              component={BoardMemberRequest}
            />
            <ProtectedRoute
              exact
              path="/podcast-request/:id"
              component={PodcastRequest}
            />
            <ProtectedRoute
              exact
              path="/podcast-request"
              component={PodcastRequest}
            />
            <ProtectedRoute
              exact
              path="/viewApplicants/:id"
              component={ViewApplications}
            />
            <ProtectedRoute
              exact
              path="/plasma-donee-request"
              component={PlasmaRequest}
            />
            <ProtectedRoute
              exact
              path="/plasma-donor-request"
              component={PlasmaDonorReq}
            />
            <ProtectedRoute
              exact
              path="/careerhelp-request"
              component={CareerHelp}
            />
            <ProtectedRoute
              exact
              path="/homework-request"
              component={HomeWorkRequest}
            />
            <ProtectedRoute
              exact
              path="/internship-request"
              component={Internship}
            />
            <ProtectedRoute
              exact
              path="/volunteer-request"
              component={Volunteering}
            />
            <ProtectedRoute
              exact
              path="/covidhelp-request"
              component={CovidHelpRequestForm}
            />

            <ProtectedRoute
              exact
              path="/myWithdrawals"
              component={MyWithdrawals}
            />
            <ProtectedRoute exact path="/chatTabs" component={ChatTabs} />

            <ProtectedRoute
              exact
              path="/createAudioRoom"
              component={ChatRoomForm}
            />
            <ProtectedRoute
              exact
              path="/volunteerProfile/:id"
              component={VolunteerProfile}
            />
            <ProtectedRoute
              exact
              path="/become-a-volunteer"
              component={BecomeAVolunteer}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <ProtectedRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />
            <ProtectedRoute
              exact
              path="/admin/donationRequests"
              component={AdminDonationRequests}
            />
            <ProtectedRoute
              exact
              path="/donationRequest/:id"
              component={DonationRequest}
            />
            <ProtectedRoute
              exact
              path="/admin/withdrawalRequests"
              component={AdminWithdrawalRequests}
            />
            <ProtectedRoute
              exact
              path="/withdrawForm/:id"
              component={WithdrawForm}
            />
            <ProtectedRoute
              exact
              path="/withdrawfunds/request-raised/:id"
              component={RequestRaised}
            />
            <ProtectedRoute
              exact
              path="/myWithdrawals"
              component={MyWithdrawals}
            />
            <ProtectedRoute
              exact
              path="/admin/volunteers"
              component={VolunteerApplications}
            />

            <ProtectedRoute
              exact
              path="/talRadioAdmin/manageTALRadio/tal-radio/:id"
              component={TALRadio}
            />
            <ProtectedRoute
              exact
              path="/post-details/:id"
              component={PostDetails}
            />
            <ProtectedRoute
              path="/startDonorEmailCampaign/:id"
              exact
              component={DonorEmailCampaign}
            />
            <ProtectedRoute
              exact
              path="/startContactEmailCampaign/EmailEditConfirmation/:id"
              component={EmailEditConfirmation}
            />
            <ProtectedRoute
              exact
              path="/startContactEmailCampaign/EmailContactGroups/:id"
              component={EmailContactGroups}
            />
            <ProtectedRoute
              exact
              path="/startContactEmailCampaign/EmailEdit/:id"
              component={EmailEdit}
            />
            <ProtectedRoute
              exact
              path="/startContactEmailCampaign/EmailImportFiles/:id"
              component={EmailImportFiles}
            />
            <ProtectedRoute
              exact
              path="/startContactEmailCampaign/:id"
              component={ContactEmailCampaign}
            />
            <Route exact path="/otp-validation" component={OtpValidation} />
            <Route exact path="/otp-validation/:id" component={OtpValidation} />
            <Route exact path="/publicPayment" component={PublicPayment} />
            <Route exact path="/payment" component={Payment} />
            <ProtectedRoute
              exact
              path="/add-organization"
              component={Organization}
            />
            <Route exact path="/organization/:id" component={OrgDetails} />
            <Route exact path="/billingInfo" component={BillingInfo} />
            <Route exact path="/StripePayment" component={StripePayment} />
            <Route exact path="/thankyou" component={ThankYou} />
            <Route exact path="/invitation" component={InviteTALLeader} />
            <Route
              exact
              path="/user/interestsAndExpertise"
              component={UserInterests}
            />
            <ProtectedRoute
              exact
              path="/admin/invitingATALLeader"
              component={InvitingATALLeader}
              role="admin"
            />
            <ProtectedRoute exact path="/myDonations" component={MyDonations} />
            <ProtectedRoute
              exact
              path="/editUsername"
              component={EditUserName}
            />
            <ProtectedRoute
              exact
              path="/admin/managePodcast"
              component={TalMediaForm}
            />
            <Route exact path="/talmedia/:file" component={TalRadioPlayer} />
            <Route exact path="/talmedia" component={Podcast} />
            <Route
              exact
              path="/talradiowidget/:defaultChannel"
              component={Audio}
            />
            <ProtectedRoute
              exact
              path="/myfavourites"
              component={MyFavourites}
            />
            <ProtectedRoute exact path="/invitation" component={InviteUser} />
            <ProtectedRoute
              exact
              path="/teamMemberInvitation"
              component={InviteTeamMember}
            />
            <ProtectedRoute
              exact
              path="/judgeInvitation"
              component={InviteJudge}
            />
            <ProtectedRoute
              exact
              path="/inviteGeneralUser"
              component={InviteGeneralUser}
            />
            <Route
              exact
              path="/verifyOrganizationEmail/:orgId/:userId"
              component={VerifyOrganizationEmail}
            />
            <Route exact path="/:username" component={PublicProfile} />
            <Route exact path="/user/help-desk" component={FAQ} />
            <ProtectedRoute
              exact
              path="/admin/TalLeaders"
              component={TalLeaders}
            />
            <ProtectedRoute
              exact
              path="/admin/AdminTalLeaders"
              component={AdminTalLeaders}
              role="admin"
            />
            <ProtectedRoute
              exact
              path="/admin/FeedbackTalLeaders"
              component={FeedbackTalLeaders}
              role="admin"
            />
            <ProtectedRoute
              exact
              path="/admin/AdminTALHerosAwards"
              component={AdminTALHerosAwards}
              role="admin"
            />
            <ProtectedRoute
              exact
              path="/admin/FeedbackTalAwards"
              component={FeedbackTalAwards}
              role="admin"
            />
            <ProtectedRoute
              exact
              path="/admin/AdminAllLeaders"
              component={AdminAllLeaders}
              role="admin"
            />
            <ProtectedRoute
              exact
              path="/admin/AdminNewLeaders"
              component={AdminNewLeaders}
              role="admin"
            />

            <ProtectedRoute exact path="/admin/TalHeros" component={TalHeros} />

            <ProtectedRoute
              exact
              path="/admin/donations"
              component={AdminDonations}
            />
            <ProtectedRoute
              exact
              path="/admin/organization"
              component={AdminOrganizations}
            />
            <Route exact path="/user/feedback" component={Feedback} />
            <ProtectedRoute
              exact
              path="/admin/userActivityLog"
              component={UserActivityLog}
            />
            <ProtectedRoute
              exact
              path="/admin/userslist"
              component={UserList}
            />
            <ProtectedRoute
              exact
              path="/admin/submittedideas"
              component={SubmittedIdeas}
            />
            <ProtectedRoute exact path="/admin/Channels" component={Channels} />
          </Switch>
        </Box>
        {showArrow < -500 && (
          <div
            style={{
              position: "fixed",
              bottom: 20,
              right: 10,
              background: "var(--tal_primary)",
              padding: "6px 8px",
              borderRadius: "50%",
              color: "#fff",
              cursor: "pointer",
              zIndex: "99",
            }}
            onClick={scrollToTop}
          >
            <Tooltip title="Scroll To Top">
              <ArrowUpwardIcon />
            </Tooltip>
          </div>
        )}
        {window.location.pathname.indexOf("/talradiowidget") < 0 && <Footer />}
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;

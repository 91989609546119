import React, { useEffect, useContext, useState } from "react";
import Loader from "../common/Loader";
import jwt_decode from "jwt-decode";
import { Grid } from "@material-ui/core";
import { appContext } from "../../App";
import toastr from "toastr";
import { updateProfile } from "../../utils/api";
import Container from "@material-ui/core/Container";

const InviteTALLeader = (props) => {
  const { authUser, setTALLeaderPaylaod, setTALLeaderUnRegistered } =
    useContext(appContext);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  const validateTALLeader = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    const decoded = jwt_decode(token);
    setTALLeaderPaylaod(decoded);
    if (decoded && decoded.email) {
      const { firstName, lastName, email, phone, secondaryEmail, userId } =
        decoded;
      updateProfile(userId, {
        talLeaderInvitationStatus: "accepted",
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(
              "TALLeader verified successfully. Please complete the User Profile steps."
            );
            if (
              authUser !== null &&
              authUser.unique_id === decoded.userId &&
              response.data.data.isTALLeader === true
            ) {
              props.history.push("/");
            } else if (
              response.data.data.isTALLeader !== true &&
              response.data.data.account_verified === true
            ) {
              let isCapturePassword = false;
              if (!response.data.data.password) {
                isCapturePassword = true;
              }
              props.history.push("/user/interestsAndExpertise", {
                isCapturePassword: isCapturePassword,
              });
            } else if (
              response.data.data.account_verified === true &&
              response.data.data.isTALLeader === true
            ) {
              props.history.push("/login");
            } else {
              props.history.push("/signup");
            }
          }
        })
        .catch((error) => {
          toastr.error(
            error.response && error.response.data && error.response.data.message
          );
          if (authUser !== null && authUser.unique_id === decoded.userId) {
            props.history.push("/");
          } else {
            props.history.push("/login");
          }
        });
    } else {
      toastr.warning(
        "Please complete the signup process to become a TALLeader."
      );
      props.history.push("/signup");
      setTALLeaderUnRegistered(true);
    }
  };
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  useEffect(() => {
    isComponentLoaded && validateTALLeader();
  }, [isComponentLoaded]);

  return (
    <div className="myDonations-container">
      <Container maxWidth="lg">
        <Grid container>
          <Grid xs={9}>
            <Loader isOpen={true} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default InviteTALLeader;

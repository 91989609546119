import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import { withRouter } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

const options = [
  { label: "Donate as a Guest", key: "guest" },
  {
    label: "Login/Signup to donate (Donation will be saved in your profile)",
    key: "login",
  },
];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function DonationRequestNotLoggedInDialog(props) {
  const classes = useStyles();
  const { onClose, donationRequest, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (key) => {
    if (key === "guest") {
      props.history.push(
        `/publicPayment?donationfor=donationRequest&donationid=${
          donationRequest._id
        }&currency=${
          donationRequest.units && donationRequest.units.toUpperCase()
        }`
      );
    } else if (key === "login") {
      localStorage.setItem(
        "redirectUri",
        `/donationRequest/${donationRequest._id}`
      );
      localStorage.setItem("donateNowButtonClicked", "true");
      props.history.push("/login");
    }
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box
        component="button"
        onClick={handleClose}
        className="drop-box"
        border="none"
        color="var(--tal_primary)"
        margin="5px"
        color=" rgb(205, 38, 39)"
        padding="5px"
        borderRadius="50%"
        position="absolute"
        right="0"
      >
        <CloseIcon />
      </Box>
      <DialogTitle
        id="doantion-options"
        style={{
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        You are not logged in. Please choose one of the options below.
      </DialogTitle>
      <List style={{ display: "flex", gap: "10px", padding: "10px" }}>
        {options.map((option) => (
          <ListItem
            style={{
              background: "var(--tal_primary) ",
              borderRadius: "5px",
            }}
            button
            onClick={() => handleListItemClick(option.key)}
            key={option.key}
          >
            <ListItemText
              primary={option.label}
              style={{ color: "white", textAlign: "center" }}
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
export default withRouter(DonationRequestNotLoggedInDialog);

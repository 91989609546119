import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { Grid, Typography, Tooltip, IconButton } from "@material-ui/core";
import validator from "validator";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import useFormValidation from "../../hooks/useFormValidation";
import {
  inviteJudge,
  deleteJudge,
  getJudges,
} from "../../utils/api";
import toastr from "toastr";
import "./Admin.scss";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    " & > div": {
      "&:first-child":{
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      },
    },
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const JudgeAllocation = (props) => {
  const classes = useStyles();
  const [judges, setJudges] = useState([]);
  const initialState = {
    email: "",
    ideaId: null,
  };

  const { donationRequestId } = props;

  const handleCreateJudges = () => {
    const finalValues = {};
    const errors = {};
    finalValues.email = values.judges && values.judges.trim();
    if (finalValues.email && !validator.isEmail(finalValues.email.trim())) {
      toastr.error("Please enter a valid email");
    } else if (!finalValues.email) {
      toastr.error("Please enter E-mail");
    } else {
      inviteJudge({ ...finalValues, ideaId: donationRequestId })
        .then((response) => {
          if (response.statusCode === 200) {
            setJudges((previousValues) => [
              {
                _id: response.data.ideaId,
                email: values.judges,
                status: "pending",
                role: "judge",
              },
              ...previousValues,
            ]);
            toastr.success("Invitation sent successfully");
            setErrors({});
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrors((currentValues) => {
              return {
                ...currentValues,
                title: "This Judge already exists ",
              };
            });
            toastr.error("This Judge already exists ");
          } else {
            toastr.error(
              error.response &&
                error.response.data &&
                error.response.data.message
            );
          }
        });
      setValues((currentValues) => {
        return {
          ...currentValues,
          judges: " ",
        };
      });
    }
  };

  const loadJudges = () => {
    getJudges(donationRequestId)
      .then((response) => {
        if (response) {
          const respData = response.data;
          setJudges([...respData]);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Judges: " + error.message);
      });
  };
  useEffect(() => {
    loadJudges();
  }, [donationRequestId]);
  const handleDeleteJudge = (inputData) => {
    if (!inputData || !inputData._id) {
      toastr.error("Invalid Judge details");
    } else if (
      window.confirm(
        "Are you sure you want to delete Judge - " + inputData.email
      )
    ) {
      deleteJudge(inputData._id)
        .then(() => {
          setJudges(judges.filter((item) => item._id != inputData._id));
          toastr.success("The Judge is deleted successfully");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState,handleCreateJudges);

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openOrgModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openOrgModal} >
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px ",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.orgTitle}>Judge Allocation</h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  display: "grid",
                  placeContent: "center",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onCloseModal} />
              </span>
            </div>
            <Grid style={{padding:"20px"}}>
            <Grid item xs={12} className="subtitle">
              Add Judge <span style={{ color: "red" }}>*</span>
            </Grid>
            <Grid lg={6} xs={12} className="crt-form">
              <TextField
                variant="outlined"
                className="f100"
                placeholder="Enter Judge E-mail"
                name="judges"
                type="email"
                value={values.judges}
                onChange={changeHandler}
              />
              {errors && errors.judges && (
                <Typography className="custom-error">
                  {errors.judges}
                </Typography>
              )}
              <Button
                class="btn btn-danger w-25"
                type="button"
                id="validate_team"
                onClick={handleCreateJudges}
              >
                Add
              </Button>
            </Grid>

            <Grid item lg={6} xs={12} className="subtitle">
              Your Judges
              <div className="tal-team-members">
                {judges &&
                  judges.length > 0 &&
                  judges.map((judge, index) => {
                    return (
                      <>
                        <InputLabel name="judge" value={judge && judge.email}>
                          <div> {judge && judge.email}</div>
                          <div>
                            {judge && judge.status === "accepted" ? (
                              <Tooltip title="Accepted">
                                <IconButton className="del-btn">
                                  <CheckCircleOutlineIcon
                                    style={{ color: "green" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Waiting for Acceptance">
                                <IconButton className="del-btn">
                                  <ErrorOutlineIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            )}

                            {judge && judge.role && judge.role != "owner" && (
                              <Tooltip title="Click to Delete Judge">
                                <IconButton
                                  className="del-btn"
                                  onClick={() => handleDeleteJudge(judge)}
                                >
                                  <CancelIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </InputLabel>
                      </>
                    );
                  })}
              </div>
            </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(JudgeAllocation);

import React, { useState, useEffect, useContext, Fragment } from "react";
import toastr from "toastr";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import SideMenu from "../SideMenu/Sidemenu";
import Drawer from "@material-ui/core/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "@material-ui/core/Container";
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faRupeeSign,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";

// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

import Avatar from "@material-ui/core/Avatar";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

import { appContext } from "../../App";

// import "./style.scss";
import "./volunteerProfile.scss";

import { updateProfile, getUserData, getUserSummary } from "../../utils/api";
import { formatScore, HOME_PAGE, numberWithCommas } from "../../utils/utils";
import useFormValidation from "../../hooks/useFormValidation";
import ValidateVolunteerProfile from "./ValidateVolunteerProfile";
import AddPhotos from "../common/AddPhotos";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const VolunteerProfile = (props) => {
  const {
    authUser,
    setAuthUser,
    isSuperAdmin,
    isAdmin,
    setIsVolunteerApplicationFlow,
    userSummary,
  } = useContext(appContext);
  const classes = useStyles();
  const initialState = {
    userName: "",
    firstName: "",
    lastName: "",
    summary: "",
  };
  const [editMode, setEditMode] = useState(false);
  const [isVolunteer, setIsVolunteer] = useState(false);
  const [volunteerApplicationStatus, setVolunteerApplicationStatus] =
    useState("0");
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [mySummary, setMySummary] = useState([]);
  const [phone, setPhone] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState(
    "https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/company/Ce2a424ef4ceda0e290183969f80b8bf3/projects/M5a82fbbd9de4261e887571c42aefeae91571702605323/images/M2f21aa2c1aae080d85ba324736f6e4d91586935513274"
  );
  const [hamburger, setHamburger] = useState(false);

  const handleHamburger = () => {
    setHamburger(true);
  };

  const handleDrawerClose = () => {
    setHamburger(false);
  };

  useEffect(() => {
    if (props.match.params.id) {
      // setUserId(props.match.params.id);
      if (authUser && authUser.unique_id) {
        setEditMode(
          props.match.params.id === authUser.unique_id ||
            isSuperAdmin ||
            isAdmin ||
            isVolunteer
        );
      } else {
        setEditMode(false);
      }
      loadUserData(props.match.params.id);
    }
  }, [props.match.params.id, authUser]);

  useEffect(() => {
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (role === "volunteer") {
          setIsVolunteer(true);
        }
      });
    }

    handleUserSummary();
  }, [roles, userSummary]);

  const handleUserSummary = () => {
    if (authUser) {
      getUserSummary(authUser.unique_id).then((response) => {
        setMySummary(response);
      });
    }
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setRoles(userData.roles);
        setPhone(userData.phone);
        setValues((currentValues) => {
          const birthDate = moment(userData.dob).format("DD-MM-YYYY");
          return {
            ...currentValues,
            userName: userData.username,
            firstName: userData.name.first_name,
            lastName: userData.name.last_name,
            summary: userData.summary,
            gender: userData.gender,
            dob: birthDate,
            experienceSummary: userData.volunteerInfo
              ? userData.volunteerInfo.experienceSummary
              : "",
            hoursAvailablePerWeek: userData.volunteerInfo
              ? userData.volunteerInfo.hoursAvailablePerWeek
              : 0,
            whyWorkWithUs: userData.volunteerInfo
              ? userData.volunteerInfo.whyWorkWithUs
              : "",
          };
        });
        if (userData.profile_image_url) {
          setProfileImageUrl(userData.profile_image_url);
        }
        if (userData.volunteerApplicationStatus) {
          setVolunteerApplicationStatus(userData.volunteerApplicationStatus);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const updateUserProfile = () => {
    const finalValues = {};
    finalValues.volunteerInfo = {};
    finalValues.volunteerInfo.hoursAvailablePerWeek =
      values.hoursAvailablePerWeek;
    finalValues.volunteerInfo.experienceSummary = values.experienceSummary;
    finalValues.volunteerInfo.whyWorkWithUs = values.whyWorkWithUs;
    finalValues.volunteerApplicationStatus = 0;
    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        setAuthUser(response.data.data);
        toastr.success("TALScout Info is Updated Successfully");
        setIsVolunteerApplicationFlow(false);
      })
      .catch((error) => {
        toastr.error(error.message);
        setIsVolunteerApplicationFlow(false);
      });
  };

  const changeProfileImageClickHandler = () => {
    setShowingAddPhotoModal(true);
  };

  const updateDefaultImageUrl = (url) => {
    const finalValues = {
      image_url: url,
    };
    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setProfileImageUrl(url);
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              profile_image_url: url,
            };
          });
          return true;
        } else {
          toastr.error(response.data.message);
          return false;
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        return false;
      });
  };

  const onConfirm = () => {
    updateUserProfile();
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, ValidateVolunteerProfile, onConfirm);

  return (
    <div>
      <Hidden smDown>
        <div className="profile-left">
          <IconButton
            className={`${clsx(hamburger && classes.hide)} hamburger-icon`}
            onClick={handleHamburger}
            aria-label="show no. of items in inbox"
            color="inherit"
          >
            <FontAwesomeIcon size="1x" icon={faAngleDoubleRight} />
          </IconButton>
        </div>
      </Hidden>

      <Container>
        <div className="volunteerProfile">
          <div className={classes.root}>
            <div className="profile-container-main">
              <Box className={`${classes.paper} header`} elevation={0}>
                <Typography
                  variant="h6"
                  component="h6"
                  className=" brop-box title"
                >
                  {volunteerApplicationStatus === "0"
                    ? "Become a TALScout"
                    : "Update your TALScout profile"}
                </Typography>

                <Typography
                  variant="subtitle1"
                  component="subtitle1"
                  className="sub-title"
                >
                  Create your TALScout profile
                </Typography>
              </Box>

              <Grid container spacing={2} className="profile-right">
                {/*<div className="header">
                <p className="title">User Profile</p>
                <p className="sub-title">User profile settings</p>
              </div>*/}
                <Grid item xs={12} sm={4} className="center">
                  <Grid item xs={12} className="avatar-blk">
                    <Avatar
                      alt="Profile Photo"
                      className="avatar"
                      src={profileImageUrl}
                    />
                    {editMode &&
                      authUser &&
                      authUser.login_provider === "local" && (
                        <Fragment>
                          <CameraAltIcon
                            className="changeProfileImg"
                            onClick={changeProfileImageClickHandler}
                            style={{ cursor: "pointer" }}
                          />
                          <AddPhotos
                            title="Manage Profile Photo"
                            isOpen={showingAddPhotoModal}
                            onClose={onCloseAddPhoto}
                            uid={authUser && authUser.unique_id}
                            collection="ProfilePictures"
                            profilephotoURL={profileImageUrl}
                            onProfilephotoURLChange={updateDefaultImageUrl}
                            canEdit={true}
                          />
                        </Fragment>
                      )}
                    <p className="profileName">
                      {values.firstName + " " + values.lastName}
                    </p>
                  </Grid>
                  <div className="userActivity">
                    <Grid container spacing={2}>
                      <ul>
                        <li>
                          <span>
                            {mySummary && mySummary.donationRequests
                              ? mySummary.donationRequests.total
                              : 0}
                          </span>
                          <p>My Requests</p>
                        </li>
                        <li>
                          <span>
                            {mySummary &&
                            mySummary.donations &&
                            mySummary.donations.currencies.length > 0
                              ? mySummary.donations.currencies.map(
                                  (summary, i) => (
                                    <span>
                                      {summary.currency === "INR" ? (
                                        <FontAwesomeIcon
                                          color="#fff"
                                          size="1x"
                                          icon={faRupeeSign}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          color="#fff"
                                          size="1x"
                                          icon={faDollarSign}
                                        />
                                      )}{" "}
                                      {summary.amountDonated}
                                      {mySummary.donations.currencies.length >
                                        1 && (
                                        <span className={`seperator-${i}`}>
                                          {" "}
                                          |{" "}
                                        </span>
                                      )}
                                    </span>
                                  )
                                )
                              : "$0"}
                          </span>
                          <p>Amount Donated</p>
                        </li>
                        <li>
                          <span>
                            {mySummary &&
                            mySummary.donationRequests &&
                            mySummary.donationRequests.currencies.length > 0
                              ? mySummary.donationRequests.currencies.map(
                                  (summary, i) => (
                                    <span>
                                      {summary.currency === "INR" ? (
                                        <FontAwesomeIcon
                                          color="#fff"
                                          size="1x"
                                          icon={faRupeeSign}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          color="#fff"
                                          size="1x"
                                          icon={faDollarSign}
                                        />
                                      )}{" "}
                                      {summary.raisedAmount}
                                      {mySummary.donationRequests.currencies
                                        .length > 1 && (
                                        <span className={`seperator-${i}`}>
                                          {" "}
                                          |{" "}
                                        </span>
                                      )}
                                    </span>
                                  )
                                )
                              : "$0"}
                          </span>
                          <p>Total Amount Raised</p>
                        </li>
                        <li>
                          <span>
                            {mySummary && mySummary.favorites
                              ? mySummary.favorites.total
                              : 0}
                          </span>
                          <p>My Favourites</p>
                        </li>
                      </ul>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} className="right">
                  <Box className="drop-box" p={2}>
                    <Box
                      className={`${classes.paper} prfDetails`}
                      elevation={0}
                    >
                      <Typography
                        variant="h6"
                        component="h6"
                        className="prf-name"
                      >
                        {values.firstName + " " + values.lastName}
                      </Typography>
                    </Box>

                    <Box
                      className={`${classes.paper} userPointsBlk`}
                      elevation={0}
                    >
                      <Typography variant="h6" component="h6">
                        <b>Kindness Points:</b>
                        {authUser && numberWithCommas(authUser.kindness_score)}
                      </Typography>
                    </Box>

                    <Box
                      className={`${classes.paper} userprofileFormBlk`}
                      elevation={0}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} className="secHeader volunteerHead">
                          <Box component="p" pt={1}>
                            TALScout Information
                          </Box>
                          <p>Please fill the details</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <InputLabel className="secFormLabel">
                            How many hours can you spare in a week for TALScout
                            activities?
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="hoursAvailablePerWeek"
                            value={values.hoursAvailablePerWeek}
                            onChange={changeHandler}
                            className="profile-fields hours"
                            type="number"
                            placeholder="Number of Hours"
                          />
                          {errors && errors.hoursAvailablePerWeek && (
                            <Typography className="custom-error">
                              {errors.hoursAvailablePerWeek}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} className="secHeader volunteerHead">
                          <p>Past Experience</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <InputLabel className="secFormLabel">
                            Do you have past volunteering experience? If so,
                            could you please tell us more?
                          </InputLabel>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={6}
                            // className="volunteerDesc"
                            className="profile-fields volunteerdesc"
                            name="experienceSummary"
                            value={values.experienceSummary}
                            onChange={changeHandler}
                          />
                        </Grid>
                        <Grid item xs={12} className="secHeader volunteerHead">
                          <p>Why do you want to work with us?</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <InputLabel className="secFormLabel">
                            Please tell us why do you want to work with us?
                          </InputLabel>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={6}
                            // className="volunteerDesc"
                            className="profile-fields volunteerdesc"
                            name="whyWorkWithUs"
                            value={values.whyWorkWithUs}
                            onChange={changeHandler}
                          />
                          {errors && errors.whyWorkWithUs && (
                            <Typography className="custom-error">
                              {errors.whyWorkWithUs}
                            </Typography>
                          )}
                        </Grid>
                        {editMode && (
                          <Fragment>
                            <Grid item xs={12} lg={12}>
                              <FormControlLabel
                                style={{ marginLeft: 0 }}
                                control={<span />}
                                label="By clicking on Save button, I agree that I have read and accepted the Terms of Use."
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                className="saveBtn"
                                onClick={submitHandler}
                              >
                                Save
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                className="saveBtn"
                                onClick={(e) => props.history.push(HOME_PAGE)}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Container>
      <Drawer
        className={`${classes.drawer} profile-drawer-container`}
        variant="persistent"
        anchor="left"
        open={hamburger}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <IconButton
          style={{ textalign: "right" }}
          className="chevor-icon"
          onClick={handleDrawerClose}
        >
          <FontAwesomeIcon size="1x" icon={faAngleDoubleLeft} />
        </IconButton>
        <hr />
        <SideMenu />
      </Drawer>
    </div>
  );
};
export default VolunteerProfile;

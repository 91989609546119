import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import toastr from "toastr";
import Container from "@material-ui/core/Container";
import "./BillingInfo.scss";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";

import {
  updateProfile,
  getUserData,
  getPayUHash,
  PayUPaymentSuccess,
  saveDonation,
} from "../../utils/api";
import useFormValidation from "../../hooks/useFormValidation";
import NewAddress from "../common/NewAddress";
import { CURRENCY_INR } from "../../utils/utils";
import cuid from "cuid";
import PhoneNumber from "../Phone/PhoneNumber";
import BillingAddress from "../common/BillingAddress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "start",
    color: theme.palette.text.secondary,
  },
}));

const BillingInfo = (props) => {
  const classes = useStyles();
  const {
    authUser,
    regions,
    setPaymentRegion,
    donationDetails,
    setDonationDetails,
  } = useContext(appContext);

  const initialState = {
    userName: "",
    firstName: "",
    lastName: "",
  };

  const [phone, setPhone] = useState("");
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  const [billingAddress, setBillingAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);

  const [otherErrors, setOtherErrors] = useState({});

  const redirectToPayU = (pd) => {
    const finalValues = pd.finalValues || null;
    pd.finalValues = null;
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(pd, {
      responseHandler: function (response) {
        const boltResp = response.response;

        if (boltResp.txnStatus && boltResp.txnStatus === "SUCCESS") {
          PayUPaymentSuccess(response.response)
            .then((txnResp) => {
              // your payment response Code goes here
              if (txnResp && txnResp.data && txnResp.data.statusCode === 200) {
                var payload = {};
                payload.paymentMethod = "payu";
                payload.units = boltResp.udf4 && boltResp.udf4.toLowerCase();
                payload.user_id = donationDetails.userId;
                payload.donationFor = donationDetails.donationFor;
                if (donationDetails.donationFor === "donationRequest") {
                  payload.donation_request_id =
                    donationDetails.donationRequestId;
                } else if (donationDetails.donationFor === "individual") {
                  payload.toUserInfo = donationDetails.toUserInfo;
                }
                payload.externalUserInfo =
                  donationDetails.externalUserInfo || null;
                payload.quantity = donationDetails.donatedAmount;
                payload.transactionId = boltResp.txnid;
                payload.type = "cash";
                payload.anonymous = donationDetails.isAnonymous;
                payload.cardInfo = boltResp.bankcode;
                payload.billingAddress = finalValues.billingAddress;
                saveDonation(payload)
                  .then((response) => {
                    response.data.currency = payload.units || "";
                    var thankyouResponse = response.data;
                    props.history.push("/thankyou", {
                      thankyouResponse,
                    });
                  })
                  .catch((error) => {
                    toastr.error(error.message);
                  });
              } else {
                toastr.error("Payment failed.");
              }
            })
            .catch(() => {
              toastr.error("Transaction failed. Please try again.");
            });
        } else {
          toastr.error("Payment failed. Please try again.");
        }
      },
      catchException: function (response) {
        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
        toastr.error("Payment failed. " + response);
      },
    });
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const onConfirm = () => {
    updateUserProfile();
  };

  const goToNextStep = (finalValues) => {
    let region;
    if (
      donationDetails.currency === CURRENCY_INR &&
      finalValues.billingAddress.country === "India"
    ) {
      region = regions.find((region) => region.name === "India");
    } else {
      region = regions.find((region) => region.name === "United States");
    }
    setPaymentRegion(region);
    setDonationDetails((currentValues) => {
      return {
        ...currentValues,
        settlementCurrency: region.currency,
        billingAddress: finalValues.billingAddress,
      };
    });
    if (
      donationDetails.currency === CURRENCY_INR &&
      finalValues.billingAddress.country === "India"
    ) {
      var firstName = values.firstName;
      var lastName = values.lastName;
      var email = values.email;

      //if user is not loggedin, get name & email from donationDetails.externalUserInfo
      if (!donationDetails.userId && donationDetails.externalUserInfo) {
        firstName = donationDetails.externalUserInfo.name;
        lastName = "";
        email = donationDetails.externalUserInfo.email;
      }

      var productDescription;
      if (donationDetails.donationFor === "tal") {
        productDescription = "To Touch-A-Life";
      } else if (donationDetails.donationFor === "donationRequest") {
        productDescription =
          "Donation Request - " + donationDetails.donationRequestId;
      } else if (donationDetails.donationFor === "individual") {
        productDescription = "Individual - " + donationDetails.toUserInfo;
      } else {
        //if invalid 'donationFor' then redirect to home page with error
        toastr.error(
          "Invalid donation type - " +
            (donationDetails.donationFor
              ? donationDetails.donationFor
              : "unknown")
        );
        props.history.push("/");
      }

      //this condition is only for PayU, for PayU name & emails are mandatory.
      if (donationDetails.isAnonymous === true) {
        firstName = "anonymous";
        email = "admin@touchalife.org";
      }

      var pd = {
        key: "",
        txnid: cuid(),
        amount:
          donationDetails.amount && (donationDetails.amount / 100).toFixed(2),
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
        productinfo: productDescription,
        udf1: donationDetails.donationRequestId || " ",
        udf2: donationDetails.isAnonymous || " ",
        udf3: (donationDetails.region && donationDetails.region._id) || " ",
        udf4: donationDetails.currency || " ",
        udf5: donationDetails.userId || " ",
        surl: process.env.REACT_APP_API_BASE_URL + "/payu/payment/success",
        furl: process.env.REACT_APP_API_BASE_URL + "?status=error",
        hash: "",
      };

      getPayUHash(pd)
        .then((response) => {
          pd.hash = response.data.data.hash;
          pd.finalValues = finalValues;
          if (finalValues.billingAddress) {
            pd.address1 = finalValues.billingAddress.line1;
            pd.address2 = finalValues.billingAddress.line2;
            pd.city = finalValues.billingAddress.city;
            pd.state = finalValues.billingAddress.state;
            pd.country = finalValues.billingAddress.country;
            pd.zipcode = finalValues.billingAddress.zip_code;
          }
          redirectToPayU(pd);
        })
        .catch((error) => {
          toastr.error("Payment processing. " + error.message);
        });
    } else {
      props.history.push("/StripePayment");
    }
  };
  const updateUserProfile = () => {
    const finalValues = {};
    if (
      !tempAddress ||
      (!tempAddress.line1 && !tempAddress.line2) ||
      !tempAddress.state ||
      !tempAddress.country ||
      !tempAddress.zip
    ) {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          billingAddress: "Please enter complete billing address",
        };
      });
      return;
    } else {
      const newAddress = {};
      newAddress.line1 = tempAddress.line1;
      newAddress.line2 = tempAddress.line2;
      newAddress.city = tempAddress.city;
      newAddress.state = tempAddress.state;
      newAddress.country = tempAddress.country;
      newAddress.zip_code = tempAddress.zip;
      finalValues.billingAddress = newAddress;
    }
    if (donationDetails && donationDetails.userId) {
      finalValues.name = {
        first_name: values.firstName,
        last_name: values.lastName,
      };
      updateProfile(donationDetails.userId, finalValues)
        .then(() => {
          goToNextStep(finalValues);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              toastr.error(error.response.data.errors[0].msg);
            } else {
              toastr.error(error.response.message);
            }
          } else {
            toastr.error(error.message);
          }
        });
    } else {
      goToNextStep(finalValues);
    }
  };

  const validateProfile = (values) => {
    if (donationDetails && donationDetails.userId) {
      let errors = {};
      if (!values.firstName) {
        errors.firstName = "Please enter First Name";
      }
      if (!values.lastName) {
        errors.lastName = "Please enter Last Name";
      }
      return errors;
    } else {
      return {};
    }
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateProfile, onConfirm);

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setPhone(userData.phone);
        setIsPhoneVerified(userData.phone_verified);
        setValues((currentValues) => {
          return {
            ...currentValues,
            userName: userData.username,
            firstName: userData.name.first_name,
            lastName: userData.name.last_name,
            summary: userData.summary,
            email: userData.email,
          };
        });
        if (userData.billingAddress) {
          setBillingAddress({
            line1: userData.billingAddress.hasOwnProperty("line1")
              ? userData.billingAddress.line1
              : "",
            line2: userData.billingAddress.hasOwnProperty("line2")
              ? userData.billingAddress.line2
              : "",
            city: userData.billingAddress.hasOwnProperty("city")
              ? userData.billingAddress.city
              : "",
            state: userData.billingAddress.hasOwnProperty("state")
              ? userData.billingAddress.state
              : "",
            zip: userData.billingAddress.hasOwnProperty("zip_code")
              ? userData.billingAddress.zip_code
              : "",
            country: userData.billingAddress.hasOwnProperty("country")
              ? userData.billingAddress.country
              : "",
          });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    if (donationDetails && donationDetails.userId) {
      loadUserData(donationDetails.userId);
    }
  }, [donationDetails]);

  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  return (
    <div className="billingInfo">
      <div className="new-profile-container">
        <Container maxWidth="lg">
          <div className="menu-container">
            <div className="profile-right" style={{ width: "100%" }}>
              <div className="right">
                <div className="drop-box">
                  <div
                    className={`${classes.paper} userprofileFormBlk`}
                    elevation={0}
                  >
                    <h4>Billing Address</h4>

                    <Grid container spacing={2} style={{ padding: "20px" }}>
                      {donationDetails && donationDetails.userId && (
                        <Fragment>
                          <Grid item xs={12} md={6}>
                            <InputLabel className="secFormLabel">
                              First Name <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <TextField
                              type="text"
                              className="profile-fields fname"
                              variant="outlined"
                              style={{ width: "100%" }}
                              name="firstName"
                              value={values.firstName}
                              onChange={changeHandler}
                            />
                            {errors && errors.firstName && (
                              <Typography className="custom-error">
                                {errors.firstName}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel className="secFormLabel">
                              Last Name <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <TextField
                              type="text"
                              variant="outlined"
                              className="profile-fields lname"
                              style={{ width: "100%" }}
                              name="lastName"
                              value={values.lastName}
                              onChange={changeHandler}
                            />
                            {errors && errors.lastName && (
                              <Typography className="custom-error">
                                {errors.lastName}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel className="secFormLabel">
                              Email
                            </InputLabel>
                            <Typography className="profile-email billing-input">
                              {values.email}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel className="secFormLabel">
                              Phone
                            </InputLabel>
                            <div
                              className="phone_bild"
                              style={{ lineHeight: "55px" }}
                            >
                              <PhoneNumber
                                phone={phone}
                                isPhoneVerified={isPhoneVerified}
                              />
                            </div>
                          </Grid>
                        </Fragment>
                      )}
                      <Grid item xs={12} className="address">
                        <InputLabel
                          style={{ margin: "10px 0 0 0" }}
                          className="secFormLabel"
                        >
                          Billing Address
                        </InputLabel>
                        <BillingAddress
                          value={billingAddress}
                          onChange={handleChangeAddress}
                        />
                        {otherErrors && otherErrors.billingAddress && (
                          <Typography className="custom-error">
                            {otherErrors.billingAddress}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disableElevation
                          className="saveBtn"
                          onClick={submitHandler}
                        >
                          Continue
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default BillingInfo;

import validator from "validator";

const validateInvitation = (values) => {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = "Please enter your first name.";
  }

  if (!values.lastName) {
    errors.lastName = "Please enter your last name.";
  }

  if (
    values.secondaryEmail &&
    !validator.isEmail(values.secondaryEmail.trim())
  ) {
    errors.secondaryEmail = "Please enter a valid email address.";
  }

  if (!values.email) {
    errors.email = "@touchalife.org email is required";
  } else if (!values.email.match(/^[A-Za-z0-9._\-]{1,}@(touchalife.org)$/)) {
    errors.email =
      "Please enter a valid @touchalife.org email address. (Eg: abc@touchalife.org)";
  }
  return errors;
};

export default validateInvitation;

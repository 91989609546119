import React, { useState, useEffect, Fragment, useContext } from "react";
import toastr from "toastr";
import cuid from "cuid";
import firebase from "../../firebase/firebase";
import useFormValidation from "../../hooks/useFormValidation";
import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { SearchRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextareaAutosize,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  InputBase,
  MenuItem,
  Select,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import validateAddMediaFile from "./validateAddMediaFile";
import VideoRow from "./VideoRow";
import { appContext } from "../../App";
import { HOME_PAGE, convertFirstCharacterToUppercase } from "../../utils/utils";

import "./TalMediaForm.scss";

import SideMenu from "../SideMenu/Sidemenu";
import { mergeAudioFiles } from "../../utils/utils";
import {
  sendPushNotificationToAll,
  getTALRadioCategories,
} from "../../utils/api";
import DropzoneInput from "../common/DropzoneInput";
import VideoPlayer from "../common/VideoPlayer";
//import FirestoreService from "../common/Firestore/FirestoreService";

import {
  getPodcasts,
  savePodcast,
  updatePodcast,
  deletePodcast,
} from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
  },
}));

const TalMediaForm = (props) => {
  const { isTALRadioAdmin, talmediaLanguage, setTalmediaLanguage } =
    useContext(appContext);
  const initialFormState = {
    name: "",
    language: "",
    description: "",
  };
  const path = `podcast/media`;
  const collection = "/podcasts";
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [thumbnailFileError, setThumbnailFileError] = useState("");
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useContext(appContext);
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const [offset, setOffset] = useState(0);
  const [language, setLanguage] = useState(documents.language);
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const loadCategories = () => {
    getTALRadioCategories()
      .then((response) => {
        if (response && response.data) {
          // setRow(response.data);
          setCategories(response.data);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Categories:" + error.message);
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const saveOrUploadDocument = () => {
    if (editMode) {
      saveDocument();
    } else {
      uploadFile();
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setOffset(0);
    setDocuments([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(
      initialFormState,
      validateAddMediaFile,
      saveOrUploadDocument
    );

  useEffect(() => {
    if (!!files) {
      return URL.revokeObjectURL(files.preview);
    }
  }, [files]);

  useEffect(() => {
    if (!!thumbnailFile) {
      return URL.revokeObjectURL(thumbnailFile.preview);
    }
  }, [thumbnailFile]);
  const onDataChange = (items) => {
    let data = [];
    items.docs.forEach((item) => {
      let id = item.id;
      data.push({
        id: id,
        ...item.data(),
      });
    });
    setDocuments(data);
    setIsLoading(false);
  };

  const loadMediaDocumentsKeyword = (keyword) => {
    setIsLoading(true);
    getPodcasts(
      (keyword && keyword.length > 0) || talmediaLanguage === "all"
        ? ""
        : talmediaLanguage,
      "",
      rowsPerPage,
      offset,
      keyword
    )
      .then((response) => {
        if (response) {
          setDocuments([...response.data]);
          setTotalCountOfRecords(response.totalCountOfRecords);
          setOffset(offset + rowsPerPage);
        } else {
          setTotalCountOfRecords(response.totalCountOfRecords);
          setOffset(0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Podcast:" + error);
      });
  };

  const changeHandlerLanguage = (e) => {
    setPage(0);
    setDocuments([]);
    setOffset(0);
    setTalmediaLanguage(e && e.target.value);
  };

  useEffect(() => {
    loadMediaDocumentsKeyword(keyword);
  }, [talmediaLanguage, rowsPerPage, page]);

  const saveDocument = () => {
    let metadata = {
      name: values.name,
      language: values.language,
      categories: selectedCategories,
      description: values.description,
    };
    updatePodcast(document._id, metadata)
      .then(() => {
        setDocuments(
          documents.map((doc) => {
            if (doc._id === document._id) {
              return { ...doc, ...metadata };
            } else {
              return doc;
            }
          })
        );
        setValues(initialFormState);
        setSelectedCategories([]);
        setEditMode(false);
        toastr.success("Video properties are updated successfully.");
      })
      .catch(function (error) {
        toastr.error("Problem updating the Video properties:" + error.message);
      });
  };

  const startAudioFileMerge = () => {
    if (files.length > 0) {
      mergeAudioFiles(files)
        .then((finaloutput) => {
          saveFileToCloud(finaloutput.file);
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Problem merging the audio files:" + error.message);
        });
    }
  };

  async function uploadFile() {
    if (files.length === 0) {
      setFileError("Please select an audio or video file to upload");
      return;
    } else {
      setFileError("");
    }
    if (!editMode && thumbnailFile && thumbnailFile.length === 0) {
      setThumbnailFileError(
        "Please select a thumbnail image file to set a thumbnail for this audio or video file."
      );
      return;
    } else {
      setThumbnailFileError("");
    }
    setIsLoading(true);
    if (files.length === 1) {
      saveFileToCloud(files[0]);
    } else if (files.length > 1) {
      startAudioFileMerge();
    }
  }

  const saveFileToCloud = (theFile) => {
    let name;
    if (theFile && theFile.name) {
      name = cuid() + theFile.name.substring(theFile.name.lastIndexOf("."));
    } else {
      name = cuid() + ".mp3";
    }
    const bucket1 = path + "/" + name;
    const bucket2 = path + "/thumbnails/" + name;
    Promise.all([getUrl(theFile, bucket1), getUrl(thumbnailFile[0], bucket2)])
      .then((response) => {
        let newDocument = {
          url: [response[0]],
          fileName: name,
          contentType: theFile.type,
          thumbnailImageUrl: [response[1]],
          name: values.name,
          language: values.language,
          description: values.description,
          categories: selectedCategories,
          time: new Date().getTime(),
        };
        savePodcast(newDocument)
          .then((response) => {
            setDocuments([response.data, ...documents]);
            setThumbnailFile([]);
            setFiles([]);
            setSelectedCategories([]);
            setValues(initialFormState);
            // send push notification to all devices
            let language = values.language;
            if (language === "none") {
              language = "other";
            }

            sendPushNotificationToAll({
              title: `New Podcast - ${convertFirstCharacterToUppercase(
                language
              )}`,
              body: values.name,
              image: response[0],
              click_action: `${process.env.REACT_APP_BASE_URL}/talmedia/${name}`,
              data: {
                type: "podcast",
                uniqueId: name,
                path: `podcast/media/${name}`,
                contentType: theFile.type,
                ...values,
              },
            });
            toastr.success("The file is uploaded succesfully");
            setIsLoading(false);
          })
          .catch(function (error) {
            toastr.error(error);
            setIsLoading(false);
          });
      })
      .catch(function (error) {
        toastr.error("Problem in uploading the Files:" + error);
        setIsLoading(false);
      });
  };
  const getUrl = (file, bucket) => {
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(bucket);
    return new Promise((resolve, reject) => {
      pathRef
        .put(file)
        .then(() => {
          storageRef
            .child(bucket)
            .getDownloadURL()
            .then((url) => {
              URL.revokeObjectURL(file.preview);
              resolve(url);
            })
            .catch(function (error) {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const deleteDocument = (document) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      deletePodcast(document._id)
        .then((status) => {
          setDocuments(documents.filter((doc) => doc._id !== document._id));
          setEditMode(false);
          setValues(initialFormState);
          toastr.success("The file is successfully deleted from the database");
        })
        .catch((error) => {
          toastr.error("Error deleting the file:" + error.message);
        });
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const editingDocument = (document) => {
    setDocument(document);
    setEditMode(true);
    setValues({
      name: document.name,
      language: document.language,
      description: document.description,
    });
    // setSelectedCategories(
    //   categories.filter(
    //     (category) => document.categories.indexOf(category._id) > -1
    //   )
    // );
    setSelectedCategories(document.categories);
    scrollToTop();
  };

  const onPlayVideo = (document) => {
    setDocument(document);
    setIsPlayingVideo(true);
  };

  const songsSearch = (e) => {
    setKeyword(e && e.target.value);
    setOffset(0);
    setPage(0);
    setIsSearchMode(true);
  };

  const onClosePlayVideo = () => {
    setDocument({});
    setIsPlayingVideo(false);
  };

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const onSubmit = (e) => {
    if (e.keyCode === 13) {
      loadMediaDocumentsKeyword(keyword);
    }
  };

  const cancelEditHandler = () => {
    setEditMode(false);
    setSelectedCategories([]);
    setValues(initialFormState);
  };

  const isAllSelected =
    categories.length > 0 && selectedCategories.length === categories.length;

  const handleChangeCategory = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedCategories(
        selectedCategories.length === categories.length ? [] : categories
      );
      return;
    }
    setSelectedCategories(value);
  };
  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <div className="manage_podcast_media">
        <div className="barnav">
          <Container MaxWidth="lg">
            <div style={{ display: "flex" }}>
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4 className="image-title">Manage Podcast Media</h4>
            </div>
          </Container>
        </div>

        <Container maxWidth="lg">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            )}

            <div className="add-image-container">
              <Fragment>
                <div className="top-box">
                  <Grid container spacing={2}>
                    {!editMode && (
                      <Grid item xs={12} md={4} sm={12}>
                        <div className="main-1 drop-box">
                          <Typography variant="h4">
                            Select Audio or Video File
                          </Typography>

                          <DropzoneInput
                            setFiles={setFiles}
                            accept="video/*,.mp4,.mkv,.dat,.avi, audio/*"
                            multiple={false}
                          />
                          {fileError && (
                            <Typography style={{ color: "red" }}>
                              {fileError}
                            </Typography>
                          )}
                        </div>
                      </Grid>
                    )}
                    {!editMode && (
                      <Grid item xs={12} md={4} sm={12}>
                        <div className="main-2 drop-box">
                          <div className="select">
                            <Typography variant="h4">
                              Select Thumbnail Image File
                            </Typography>
                            <h5>
                              (Ensure width and height are equal. For eg. 300 x
                              300)
                            </h5>
                            <DropzoneInput
                              setFiles={setThumbnailFile}
                              accept="image/*"
                              multiple={false}
                            />
                            {thumbnailFileError && (
                              <Typography style={{ color: "red" }}>
                                {thumbnailFileError}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12} md={4} sm={12}>
                      <div className="main-3 drop-box">
                        <div className="cropper child-container">
                          {files && files.length > 0 && (
                            <Fragment>
                              <Typography>Selected Files:</Typography>
                              {files.map((eachFile, index) => {
                                return (
                                  <Typography key={index}>
                                    {eachFile.name}
                                  </Typography>
                                );
                              })}
                            </Fragment>
                          )}
                          <div>
                            <TextField
                              required
                              variant="filled"
                              className="input-field"
                              placeholder="Give a title"
                              name="name"
                              value={values.name}
                              onChange={changeHandler}
                            />
                            {errors.name && (
                              <Typography style={{ color: "red" }}>
                                {errors.name}
                              </Typography>
                            )}
                          </div>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel
                              style={{ paddingLeft: "10px", marginTop: "-3px" }}
                            >
                              Select Language
                            </InputLabel>
                            <Select
                              variant="outlined"
                              name="language"
                              value={values.language}
                              label="Select Language"
                              onChange={changeHandler}
                            >
                              <MenuItem value="english">English</MenuItem>
                              <MenuItem value="hindi">Hindi</MenuItem>
                              <MenuItem value="telugu">Telugu</MenuItem>
                              <MenuItem value="none">
                                Other language (Or may be more than one of the
                                above languages)
                              </MenuItem>
                            </Select>{" "}
                            {errors && errors.language && (
                              <div className="custom-error">
                                {errors.language}
                              </div>
                            )}
                          </FormControl>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel
                              style={{ paddingLeft: "10px", marginTop: "-3px" }}
                            >
                              Select Categories
                            </InputLabel>
                            <Select
                              fullWidth
                              variant="outlined"
                              label="Categories"
                              multiple
                              value={selectedCategories}
                              onChange={handleChangeCategory}
                              renderValue={(selectedCategories) =>
                                selectedCategories
                                  .map(
                                    (selectedCategory) =>
                                      categories.find(
                                        (category) =>
                                          selectedCategory._id === category._id
                                      ).name
                                  )
                                  .join(",")
                              }
                            >
                              <MenuItem
                                key="all"
                                value="all"
                                classes={{
                                  root: isAllSelected
                                    ? "rgba(0, 0, 0, 0.08)"
                                    : "",
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    classes={{ indeterminate: "#f50057" }}
                                    checked={isAllSelected}
                                    indeterminate={
                                      selectedCategories.length > 0 &&
                                      selectedCategories.length <
                                        categories.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText primary="Select All" />
                              </MenuItem>
                              {categories.map((category) => (
                                <MenuItem key={category._id} value={category}>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={
                                        selectedCategories.findIndex(
                                          (item) => item._id === category._id
                                        ) > -1
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={category.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div>
                            <TextareaAutosize
                              name="description"
                              multiline
                              rows="4"
                              className="input-field"
                              placeholder="Enter a description"
                              value={values.description}
                              onChange={changeHandler}
                            />
                            {errors.description && (
                              <Typography>{errors.description}</Typography>
                            )}
                          </div>
                          {thumbnailFile && thumbnailFile[0] && (
                            <Fragment>
                              <Typography>Thumbnail Image File</Typography>
                              <Typography>{thumbnailFile[0].name}</Typography>
                            </Fragment>
                          )}

                          <div className="save-btn">
                            <Button
                              className="upload-btn"
                              onClick={submitHandler}
                              disabled={isLoading}
                            >
                              {editMode ? "Save" : "Upload"}
                              {isLoading && (
                                <CircularProgress
                                  size={24}
                                  className="buttonProgress"
                                />
                              )}
                            </Button>
                            {editMode && (
                              <Button
                                className="upload-btn"
                                onClick={cancelEditHandler}
                              >
                                Cancel Edit
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Fragment>

              <FormControl
                className="drop-box"
                style={{ width: "100%", margin: "10px 0", padding: "5px" }}
              >
                <InputLabel style={{ marginLeft: "18px" }}>
                  Select Language
                </InputLabel>
                <Select
                  variant="outlined"
                  name="language"
                  value={talmediaLanguage}
                  label="Select Language"
                  onChange={changeHandlerLanguage}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="all">All Languages</MenuItem>
                  <MenuItem value="english">English</MenuItem>
                  <MenuItem value="hindi">Hindi</MenuItem>
                  <MenuItem value="telugu">Telugu</MenuItem>
                  <MenuItem value="none">
                    Items not tagged to any Language
                  </MenuItem>
                </Select>
              </FormControl>
              <Box
                className="searchbar-dr boder4"
                whiteSpace="noWrap"
                style={{ marginLeft: "10px" }}
              >
                <SearchRounded style={{ margin: "0 4px", height: "46px" }} />
                <InputBase
                  placeholder="Enter search keyword"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={keyword}
                  inputProps={{ "aria-label": "search" }}
                  onChange={songsSearch}
                  onKeyDown={(e) => onSubmit(e)}
                />

                {keyword && (
                  <p className="result" style={{ marginLeft: "20px" }}>
                    Search Results for
                    <span
                      style={{
                        // boxShadow: "rgb(239 239 239) 0px 0px 7px",
                        // borderRadius: "5px",
                        padding: "3px 10px 5px 10px",
                        marginLeft: "10px",
                        fontWeight: " bold",
                        color: "#686868",
                        background: "#f8f8f8",
                      }}
                    >
                      {keyword}
                    </span>
                    <IconButton
                      edge="start"
                      aria-label="close"
                      style={{
                        padding: "3px",
                        color: "rgb(205, 38, 39)",
                        position: "relative",
                        top: "-2px",
                        margin: "0 0 0 10px",
                        border: "1px solid #ededed",
                      }}
                    >
                      <Tooltip title="Clear Search Results">
                        <CloseIcon
                          onClick={() => {
                            setKeyword("");
                            loadMediaDocumentsKeyword("");
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                  </p>
                )}
              </Box>
              <Box className="table-responsive drop-box " mt={1}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="podcast-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Video Name</TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Edit/Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoading &&
                        documents &&
                        documents.length > 0 &&
                        documents
                          // .slice(
                          //   page * rowsPerPage,
                          //   page * rowsPerPage + rowsPerPage
                          // )
                          .map((document, index) => {
                            return (
                              <VideoRow
                                key={index}
                                document={document}
                                onPlayHandler={() => onPlayVideo(document)}
                                onDeleteHandler={() => deleteDocument(document)}
                                onEditHandler={() => editingDocument(document)}
                                canEdit={true}
                              />
                            );
                          })}
                      {!isLoading && documents && documents.length === 0 && (
                        <TableRow>
                          <TableCell>No Records Found</TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell>
                            <Typography> Loading...</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCountOfRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
                <VideoPlayer
                  isOpen={isPlayingVideo}
                  onClose={onClosePlayVideo}
                  name={document && document.name}
                  url={document && document.url}
                  note={document && document.description}
                  document={document}
                />
              </Box>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default TalMediaForm;

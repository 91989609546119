import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { appContext } from "../../App";
import "../Organization/Organization.scss";
import "./EmailContactGroupsModal.scss";

import { withRouter } from "react-router-dom";
import EmailContactTypeBox from "./EmailContactTypeBox";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Organization from "../Organization/Organization";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { getTALDonorsContacts } from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    maxWidth: "280px",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    heigth: "auto",
    maxHeight: "95vh",
    boxShadow: theme.shadows[5],
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  contactGroups: {
    width: " 100%",
    display: "flex",
    padding: "10px 15px",
    gap: "12px",
    direction: "rtl",
    position: "sticky",
    bottom: "0",
    background: "white",
  },

  title: {
    fontSize: "16px",
    fontWeight: "500",
    paddingTop: 15,
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  continueBtn: {
    background: "#F44234",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
    float: "right",
  },
  cancelBtn: {
    float: "right",
  },
  listContainer: {
    overflowX: "hidden",
    height: "auto",
    alignItems: "center",

    "& h6": {
      fontSize: "1.1rem",
      margin: "0",
    },
  },
}));

const EmailContactGroupsModal = (props) => {
  const classes = useStyles();
  const {
    authUser,
    contacts,
    talContacts,
    setTalContacts,
    emailContactType,
    setEmailContactType,
    selectedOrganization,
    setSelectedOrganization,
    setSelectedContactsForEmailCampaign,
  } = useContext(appContext);

  const handleContinue = () => {
    //setCurrentDonationRequest(props.donationRequest);
    props.history.push(
      `/startContactEmailCampaign/${props.donationRequest._id}`
    );
  };
  const [value, setValue] = useState(false);
  const [selectedContactsCount, setSelectedContactsCount] = useState(
    contacts.length
  );
  const { orgList } = useContext(appContext);

  const handleSelect = () => {
    setValue(true);
  };

  const handleSelectOrganization = (event) => {
    setSelectedOrganization(event.target.value);
  };
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };

  const onSavingOrg = () => {
    setOpenOrgModal(false);
  };
  const handleIndividual = () => {
    setValue(false);
    setSelectedOrganization(null);
  };
  useEffect(() => {
    if (authUser && authUser.unique_id) {
      getTALDonorsContacts(
        authUser && authUser.unique_id,
        selectedOrganization && selectedOrganization._id
      ).then((resp) => {
        setTalContacts(resp);
      });
    }
    setSelectedContactsForEmailCampaign([]);
  }, [authUser, selectedOrganization]);

  const handleEmailContactType = (e, ect) => {
    setEmailContactType(ect);
  };
  useEffect(() => {
    if (emailContactType === "allcontacts") {
      setSelectedContactsCount(contacts.length + talContacts.length);
    } else if (emailContactType === "mycontacts") {
      setSelectedContactsCount(contacts.length);
    } else if (emailContactType === "prevdonors") {
      setSelectedContactsCount(talContacts.length);
    } else setSelectedContactsCount(0);
  }, [emailContactType, talContacts]);
  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper}>
            <div className="email-body">
              <div className="email-groups">
                <h4>Send As</h4>
                <div classsName="button-groups">
                  <Button
                    onClick={handleIndividual}
                    style={{
                      background: !value ? "#F44234" : "",
                      textTransform: "capitalize",
                      borderRadius: " 5px 5px 0px 0px",
                    }}
                  >
                    Individual
                  </Button>
                  <Button
                    onClick={handleSelect}
                    style={{
                      background: value ? "#F44234" : "",
                      textTransform: "capitalize",
                      borderRadius: " 5px 5px 0px 0px",
                    }}
                  >
                    Organization
                  </Button>
                </div>
              </div>
              {value && (
                <div className={classes.modal} style={{ background: "white" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="organization-select-label">
                      Select Organization
                    </InputLabel>
                    <Select
                      labelId="organization-select-label"
                      id="organization-select"
                      value={selectedOrganization}
                      onChange={handleSelectOrganization}
                    >
                      {orgList &&
                        orgList.length > 0 &&
                        orgList.map((org) => {
                          return (
                            <MenuItem
                              key={org && org.orgId && org.orgId._id}
                              value={org && org.orgId}
                            >
                              <img
                                src={
                                  org && org.orgId && org.orgId.defaultImageUrl
                                    ? org.orgId.defaultImageUrl
                                    : "/images/orgLogo.png"
                                }
                                alt="orgLogo"
                                width="20px"
                                style={{ marginRight: 8 }}
                              />{" "}
                              {org.orgId.orgName}{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                  textAlign: "end",
                                }}
                              >
                                {org.orgId.status === 1 ? (
                                  <CheckCircleOutlineIcon
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <ErrorOutlineIcon style={{ color: "red" }} />
                                )}
                              </span>
                            </MenuItem>
                          );
                        })}
                      <MenuItem value="add" onClick={handleOrganization}>
                        Add Organization
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              <Organization
                openOrgModal={openOrgModal}
                onCloseModal={() => setOpenOrgModal(false)}
                onSavingOrg={onSavingOrg}
              />
            </div>
            <div>
              <h1 className={classes.title}>Select the contact groups</h1>
            </div>
            <div className={classes.listContainer}>
              <Button
                style={{ textTransform: "capitalize", width: "100%" }}
                onClick={(e) => handleEmailContactType(e, "allcontacts")}
              >
                <EmailContactTypeBox
                  count={contacts.length + talContacts.length}
                  type="All Contacts List"
                  value={emailContactType === "allcontacts"}
                  text=" Select all the contacts My Contacts + Previous Donors"
                />
              </Button>
              <Button
                style={{ textTransform: "capitalize", width: "100%" }}
                onClick={(e) => handleEmailContactType(e, "mycontacts")}
              >
                <EmailContactTypeBox
                  count={contacts.length}
                  type="My Contact List"
                  text=" Select only contacts which is added by you"
                  value={emailContactType === "mycontacts"}
                />
              </Button>
              <Button
                style={{ textTransform: "capitalize", width: "100%" }}
                onClick={(e) => handleEmailContactType(e, "prevdonors")}
              >
                <EmailContactTypeBox
                  count={talContacts.length}
                  type="Previous Donors List"
                  text="Select only Previous Donors"
                  value={emailContactType === "prevdonors"}
                />
              </Button>
            </div>
            <div className={classes.contactGroups}>
              <Button
                className={classes.continueBtn}
                onClick={handleContinue}
                disabled={selectedContactsCount === 0}
              >
                Continue
              </Button>
              <Button
                className={classes.cancelBtn}
                onClick={props.onModalClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(EmailContactGroupsModal);

import React, { useState, useEffect, useContext } from "react";
import { Grid, Hidden, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { appContext } from "../../App";
import "./EmailEdit.scss";
import SideMenu from "../SideMenu/Sidemenu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReactHtmlParser from "react-html-parser";
import { render } from "mustache";

const EmailEdit = (props) => {
  const { selectedTemplate, authUser } = useContext(appContext);

  const [uname, setUname] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [subject, setSubject] = useState("");
  useEffect(() => {
    if (selectedTemplate) {
      setUname(
        selectedTemplate.senderName
          ? selectedTemplate.senderName
          : authUser.name.first_name + " " + authUser.name.last_name
      );
      setFromEmail(selectedTemplate.previewGlossaryValues.fromEmail);
      const data = {
        donationRequestURL:
          selectedTemplate.previewGlossaryValues.donationRequestURL,
        donationRequestTitle:
          selectedTemplate.previewGlossaryValues.donationRequestTitle,
        senderName: selectedTemplate.previewGlossaryValues.senderName,
      };
      setSubject(selectedTemplate.subject);
      selectedTemplate.htmlText = render(selectedTemplate.htmlText, data);
    }
  });
  const handleBack = () => {
    props.history.push(`/startContactEmailCampaign/${props.match.params.id}`);
  };
  const handleSaveContinue = () => {
    if (uname.length !== 0 && subject.length !== 0) {
      selectedTemplate.senderName = uname;
      selectedTemplate.subject = subject;
      props.history.push(
        `/startContactEmailCampaign/EmailContactGroups/${props.match.params.id}`
      );
    }
  };
  const handleChange = (e) => {
    setUname(e.target.value);
  };
  const handleSubject = (e) => {
    setSubject(e.target.value);
  };
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const nameError = uname.length === 0;
  const subError = subject.length === 0;
  return (
    <div className="edit-container">
      <Hidden mdUp>
        {authUser && (
          <Button
            onClick={handleCloseSideMenu}
            style={{
              color: "#ef6c00",
              minWidth: "50px",
              height: "50px",
              borderRadius: "0px 50px 50px 0",
              marginRight: "10px",
              background: "white",
              position: "fixed",
              zIndex: "3",
              top: "40%",
              boxShadow: "0 0 5px #0000003b",
            }}
          >
            {!closeSideMenu && <MenuIcon />}
            {closeSideMenu && <ArrowBackIcon />}
          </Button>
        )}
      </Hidden>

      <Container MaxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          </Hidden>

          <div className="drop-box" style={{ width: "100%" }}>
            <div className="email-header">
              <div className="back-button">
                <Button
                  onClick={handleBack}
                  aria-label="back"
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </Button>
              </div>

              <h4>Edit Content</h4>

              <div className="save-button">
                <Button
                  onClick={handleSaveContinue}
                  variant="contained"
                  style={{ background: "#EF6C00", color: "white" }}
                  aria-label="save"
                >
                  Save &amp; Continue
                </Button>
              </div>
            </div>

            <div className="email-body">
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="user-name">
                    <TextField
                      id="outlined-full-width"
                      label="User Name"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={uname}
                      helperText={nameError ? "Please enter sendername" : ""}
                      error={nameError}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <div className="from">
                    <TextField
                      id="outlined-full-width"
                      label="From"
                      value={fromEmail}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <div className="close-image-popup">
                    <TextField
                      id="outlined-full-width"
                      label="Subject"
                      value={subject}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      helperText={subError ? "Please enter subject" : ""}
                      error={subError}
                      onChange={(e) => handleSubject(e)}
                    />
                  </div>
                </Grid>
              </Grid>
              <br />
              <Grid container>
                <Grid item xs={12}>
                  <div
                    className="close-image-popup"
                    style={{ border: "1px solid rgb(246, 247, 251)" }}
                  >
                    {ReactHtmlParser(
                      selectedTemplate && selectedTemplate.htmlText
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(EmailEdit);

import React, { useState, useContext, useEffect } from "react";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { Modal, IconButton } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import useFormValidation from "../../hooks/useFormValidation";
import validateKindTimeDonation from "./validateKindTimeDonation";
import "./Payment.scss";
import { appContext } from "../../App";
import { saveDonation } from "../../utils/api";

const KindTimeDonation = (props) => {
  const { donationRequest } = props;
  const { authUser } = useContext(appContext);
  const initialState = {};
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [otherErrors, setOtherErrors] = useState({});

  useEffect(() => {
    if (donationRequest) {
      setMaxQuantity(
        donationRequest.quantity - donationRequest.donated_quantity
      );
    }
  }, [donationRequest]);

  const saveDonationHandler = () => {
    const finalValues = values;
    finalValues.donation_request_id = donationRequest._id;
    finalValues.user_id = authUser.unique_id;
    finalValues.donationFor = "donationRequest";
    finalValues.units = donationRequest.units;
    finalValues.type = donationRequest.request_type;
    if (values.quantity > maxQuantity) {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          quantity: `Donee requires only a max. quantity of ${maxQuantity}`,
        };
      });
      return;
    }
    saveDonation(finalValues)
      .then((response) => {
        props.onSavingDonation(values.quantity);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    validateKindTimeDonation,
    saveDonationHandler
  );

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className="payment-modal"
        open={props.isOpen}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isOpen}>
          <div className="payment-container">
            <div className="payment-page">
              <div>
                <div className="pay-headername">
                  <h2>
                    Donate Now!
                    <IconButton
                      edge="start"
                      color="inherit"
                      style={{ float: "right" }}
                      onClick={props.onClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </h2>
                  <div className="avatar-cont">
                    <Avatar
                      alt="Remy Sharp"
                      className="avatar"
                      src="/images/donate-icon.svg"
                    />
                  </div>
                  <p className="payment-text">
                    One step away to Spread kindness :)
                  </p>
                  <p className="payment-text">Make a contribution now!</p>
                  <p className="payment-text">
                    The donee still needs {maxQuantity} of{" "}
                    {donationRequest &&
                      donationRequest.quantity +
                        " " +
                        (donationRequest.request_type === "kind"
                          ? donationRequest.requested_for + " "
                          : "") +
                        (donationRequest.units &&
                          donationRequest.units.toUpperCase())}
                  </p>
                </div>
                <div className="payment-textfield-container">
                  <TextField
                    className="full-width border-radius payment-input"
                    placeholder="Quantity you would like to donate"
                    type="number"
                    name="quantity"
                    values={values.quantity}
                    onChange={changeHandler}
                  />
                  {errors && errors.quantity && (
                    <Typography className="custom-error">
                      {errors.quantity}
                    </Typography>
                  )}
                  {otherErrors && otherErrors.quantity && (
                    <Typography className="custom-error">
                      {otherErrors.quantity}
                    </Typography>
                  )}
                  <TextareaAutosize
                    placeholder="Please leave a note"
                    multiline="true"
                    rows="4"
                    className="input-field desc"
                    variant="filled"
                    name="notes"
                    value={values.notes}
                    onChange={changeHandler}
                  />
                </div>
                <div className="payment-btn">
                  <Button
                    color="primary"
                    className="pay-btn"
                    variant="contained"
                    type="submit"
                    onClick={submitHandler}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default withRouter(KindTimeDonation);

import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import RadioButton from "@material-ui/core/Radio";
import "./Internship.scss";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ShortAddress from "../common/ShortAddress";
import { withStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import { createDonationRequest, getOrganizationList } from "../../utils/api";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  FormGroup,
  Checkbox,
  Switch,
  Chip,
  OutlinedInput,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  faRupeeSign,
  faDollarSign,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    width: "100%",
    padding: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  fieldlength: {},
}));
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(skill, skillSet, theme) {
  return {
    fontWeight:
      skillSet.indexOf(skill) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const Internship = (props) => {
  const { authUser, setAuthUser, region, regions, setRegion,orgList } =
    useContext(appContext);
  const theme = useTheme();
  const initialState = {
    cashQuantity: "",
    title: "",
    requestType: "regular",
    cashUnit: "USD",
    status: "-2",
    fundsRecipient: "",
    profile: "",
    numberOfOpenings: "",
    startDate: moment(),
    endDate: moment(),
    duration: "",
    monthlyStipend: "",
    educationalQualification: "",
  };
  const [closeSideMenu] = useState(false);
 // const [orgList, setOrgList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [interviewDeclaration, setInterviewDeclaration] = useState(false);
  const [neighboringCities, setNeighboringCities] = useState(false);
  const [trainingMode, setTrainingMode] = useState("");
  const classes = useStyles();

  const [partTime, setPartTime] = React.useState(false);
  const [preplacement, setPreplacement] = React.useState(false);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [description, setDescription] = useState("");
  const [internResponsibilities, setInternResponsibilities] = useState("");
  const [internRequirements, setInternRequirements] = useState("");

  const [profile, setProfile] = useState("");

  const [selectedPerks, setSelectedPerks] = useState([]);

  const [skillSet, setSkillSet] = React.useState([]);
  const [perks, setPerks] = React.useState({
    "Certificate": false,
    "Flexible Work Hours": false,
    "Informal Dress Code": false,
    "Letter Of Recommendation": false,
    "5 days a week": false,
    "Free Snacks and Beverages": false,
  });
  

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const changeRequirementsHandler = (e, editor) => {
    setInternRequirements(editor.getData());
  };

  const changeResponsibilitiesHandler = (e, editor) => {
    setInternResponsibilities(editor.getData());
  };

  const handleChangePerks = (event) => {
    setPerks({
      ...perks,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      setSelectedPerks([...selectedPerks, event.target.name]);
    } else {
      setSelectedPerks(
        selectedPerks.filter((perk) => perk !== event.target.name)
      );
    }
  };

  const handleChangeTrainingMode = (event) => {
    setTrainingMode(event.target.value);
  };
  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
  };

  const {
    certificate,
    flexibleWorkHours,
    informalDressCode,
    letterOfRecommendation,
    fivedaysaweek,
    freeSnacksandBeverages,
  } = perks;
  const profiles = [
    "Business Development (Sales)",
    "Web Development",
    "Graphic Design",
    "Content Writing",
    "Social Media Marketing",
    "Marketing",
    "Operations",
    "Mobile App Development",
    "Digital Marketing",
    "Human Resources (HR)",
    "Law/Legal",
    "Campus Ambassador",
    "Other",
  ];
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handlePartTime = (event) => {
    setPartTime(event.target.checked);
  };

  const handlePrePlacement = (event) => {
    setPreplacement(event.target.checked);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleChangeProfile = (event) => {
    setProfile(event.target.value);
  };

  const handleCreatePost = () => {
    const finalValues = {};
    const errors = {};
    if (values.fundsRecipient.length === 0) {
      errors.fundsRecipient = "Please Select or Add an Organization";
    }
    if (values.profile.length === 0) {
      errors.selectProfile = "Please Select Profile";
    }

    if (description.length === 0) {
      errors.description = "Please enter Description";
    } else if (description && description.length <= 10) {
      errors.description = "Please enter atleast 10 characters";
    }
    if (
      tempAddress === null ||
      tempAddress.city === null ||
      tempAddress.state === null ||
      tempAddress.country === null
    ) {
      errors.address = "Please Select your Location";
    }

    if (startDate === null) {
      errors.startDate = "Please Select Start Date";
    }

    if (dueDate === null) {
      errors.dueDate = "Please Select Due Date";
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      finalValues.request_type = "internship";
      if (values.profile === "Other") {
        finalValues.title = profile;
      } else {
        finalValues.title = values.profile;
      }

      finalValues.start_date = startDate.getTime();
      finalValues.creatorType = "employer";
      finalValues.due_date = dueDate.getTime();
      finalValues.skills = skillSet;
      finalValues.units = values.cashUnit;
      finalValues.brief="";
      //finalValues.tags = ["Internship", "Employer"];
      if (values.fundsRecipient === "self") {
        finalValues.orgId = "Self";
      } else {
        finalValues.orgId = values.fundsRecipient;
      }
      finalValues.user_id = authUser.unique_id;
      finalValues.fundsRecipient = values.fundsRecipient;
      // finalValues.whyYouNeedHelp = "justifyToOthers";
      finalValues.description = description;
      finalValues.region = region._id;
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
      finalValues.quantity = "0";

      const additionalInfo = {
        trainingMode: trainingMode,
        onlineInterviewAvailable: interviewDeclaration,
        partTimeAllowed: partTime,
        numberOfOpenings: values.numberOfOpenings,
        duration: values.duration,
        responsibilities: internResponsibilities,
        requirements: internRequirements,
        monthlyStipend: values.monthlyStipend,
        monthlyStipendUnits: values.cashUnit,
        perks: selectedPerks,
        placementOffer: preplacement,
        educationalQualification: values.educationalQualification,
      };
      finalValues.additionalInfo = additionalInfo;

      createDonationRequest(finalValues)
        .then((response) => {
          if (response.statusCode === 200) {
            props.history.push(`/donationRequest/${response.data.name}`);
            toastr.success(
              "Your Internship Opportunity is successfully created"
            );
            setErrors({});
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrors((currentValues) => {
              return {
                ...currentValues,
                title:
                  "This title is already taken. Please change the title and try again.",
              };
            });
          } else {
            toastr.error(
              error.response &&
                error.response.data &&
                error.response.data.message
            );
          }
        });
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState);
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };
  const handleInterviewDeclaration = (event) => {
    if (event.target.checked) {
      setInterviewDeclaration(true);
    } else {
      setInterviewDeclaration(false);
    }
  };

  const handleNeighboringCities = (event) => {
    if (event.target.checked) {
      setNeighboringCities(true);
    } else {
      setNeighboringCities(false);
    }
  };
  const [openOrgModal, setOpenOrgModal] = useState(false);

  // useEffect(() => {
  //   if (authUser && authUser.unique_id) {
  //     getOrganizationList(authUser.unique_id)
  //       .then((resp) => {
  //         setOrgList(resp);
  //       })
  //       .catch((error) => {
  //         toastr.error(error.message);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <SideMenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/internship.png"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "10px",
                      borderRadius: "75%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Internship
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Bridging the gap between Employers and Applicants to improve
                    the workforce.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid
              className={classes.root}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              container
            >
              <div className="field-length">
                <Grid item xs={12}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      lineHeight: "37px",
                      color: "#000",
                      padding: "10px",
                      textAlign: "start",
                    }}
                    gutterBottom
                  >
                    Internship Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    className="recipient"
                    error={errors && errors.fundsRecipient}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{ padding: "8px 35px" }}
                    >
                      Select/Add Organization
                    </InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.fundsRecipient}
                      onChange={changeHandler}
                      variant="outlined"
                      placeholder="Organization"
                      // className={`input-field request-amount ${
                      //   errors && errors.fundsRecipient
                      //     ? "bg-red"
                      //     : values.fundsRecipient
                      //     ? "bg-green"
                      //     : "bg-normal"
                      // }`}
                      name="fundsRecipient"
                      label="Recipient"
                    >
                      <MenuItem value={"self"}>Self</MenuItem>
                      {orgList &&
                        orgList.length > 0 &&
                        orgList.map((org) => {
                          return (
                            <MenuItem value={org && org.orgId && org.orgId._id}>
                              <img
                                src={
                                  org && org.orgId && org.orgId.defaultImageUrl
                                    ? org.orgId.defaultImageUrl
                                    : "/images/orgLogo.png"
                                }
                                alt="orgLogo"
                                width="20px"
                                style={{ marginRight: 8 }}
                              />{" "}
                              {org && org.orgId && org.orgId.orgName}{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                  textAlign: "end",
                                }}
                              >
                                {org && org.orgId && org.orgId.status === 1 ? (
                                  <CheckCircleOutlineIcon
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <ErrorOutlineIcon style={{ color: "red" }} />
                                )}
                              </span>
                            </MenuItem>
                          );
                        })}
                      <MenuItem value="add" onClick={handleOrganization}>
                        Add Organization
                      </MenuItem>
                    </Select>{" "}
                    <FormHelperText>{errors.fundsRecipient}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    error={errors && errors.selectProfile}
                  >
                    <InputLabel
                      style={{ padding: "10px 30px" }}
                      id="demo-simple-select-outlined-label"
                    >
                      Select Profile
                    </InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.profile}
                      onChange={changeHandler}
                      variant="outlined"
                      name="profile"
                      label="Select Profile"
                    >
                      <MenuItem value={"select profile"}>
                        Select Profile
                      </MenuItem>
                      {profiles.map((profile) => {
                        return <MenuItem value={profile}>{profile}</MenuItem>;
                      })}
                    </Select>
                    {values.profile === "Other" && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        style={{
                          paddingTop: "30px",
                          paddingLeft: "1px",
                          fontStyle: "bold",
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#000000",
                          width: "100%",
                        }}
                        name="profile"
                        placeholder="Enter Profile"
                        value={profile}
                        onChange={handleChangeProfile}
                      />
                    )}
                    <FormHelperText>{errors.selectProfile}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "500",
                      fontSize: "15px",
                      color: "#263238",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                      textAlign: "start",
                    }}
                    gutterBottom
                  >
                    Description for Internship
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    className="recipient"
                    error={errors && errors.description}
                  >
                    <CKEditor
                      style={{ padding: "10px 20px" }}
                      placeholder=""
                      data={description}
                      name="description"
                      editor={ClassicEditor}
                      onChange={changeDescriptionHandler}
                      config={{
                        toolbar: [
                          "Heading",
                          "bold",
                          "italic",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                          "Link",
                        ],
                        placeholder:
                          "Give description about this Internship opportunity",
                      }}
                    />

                    {errors && errors.description && (
                      <FormHelperText>{errors.description}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    className="recipient"
                  >
                    <Typography
                      component="p"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#000",
                        textAlign: "start",
                      }}
                      gutterBottom
                    >
                      Internship type
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", paddingLeft: "10px" }}
                    error={errors && errors.trainingMode}
                  >
                    <RadioGroup
                      row
                      value={trainingMode}
                      onChange={handleChangeTrainingMode}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="offline"
                        control={<Radio />}
                        label="Regular (In-office/On-field)"
                      />
                      <FormControlLabel
                        value="online"
                        control={<Radio />}
                        label="Remote/WFH (Recommended due to COVID-19 situation)"
                      />
                    </RadioGroup>
                    <FormHelperText>{errors.trainingMode}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    component="fieldset"
                    error={errors && errors.interviewDeclare}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <>
                            <Checkbox
                              checked={interviewDeclaration}
                              onChange={handleInterviewDeclaration}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              style={{
                                marginTop: "0px",
                                paddingTop: "0px",
                                alignItems: "start",
                              }}
                            />
                            <span
                              className="checkaglin"
                              variant="body1"
                              gutterBottom
                            >
                              I am open to interviewing candidates online and
                              allowing them to work from home or defer the
                              joining date till current COVID-19 situation
                              improves
                            </span>
                          </>
                        }
                      />
                    </FormGroup>
                    <FormHelperText>{errors.interviewDeclare}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    className=" recipient"
                    error={errors && errors.address}
                  >
                    <Typography
                      component="p"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#000",
                        textAlign: "start",
                      }}
                      gutterBottom
                    >
                      Select your location
                    </Typography>
                    <ShortAddress
                      value={address}
                      onChange={handleChangeAddress}
                    />
                    <FormHelperText>{errors.address}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ padding: "10px" }}
                    component="fieldset"
                    error={errors && errors.partTime}
                  >
                    <FormGroup>
                      <FormControlLabel
                        style={{
                          fontWeight: "bold",
                        }}
                        className="labelpad"
                        label="Is part-time allowed?"
                        labelPlacement="start"
                        control={
                          <>
                            <Switch
                              checked={partTime}
                              onChange={handlePartTime}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </>
                        }
                      />
                    </FormGroup>
                    <FormHelperText>{errors.partTime}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    error={errors && errors.numberOfOpenings}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        paddingLeft: "1px",
                        fontStyle: "bold",
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#000000",
                        width: "100%",
                      }}
                      name="numberOfOpenings"
                      label="Enter No. of. Openings"
                      placeholder="No. of. Openings"
                      value={values.numberOfOpenings}
                      onChange={changeHandler}
                    />
                    <FormHelperText>{errors.numberOfOpenings}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "20px",
                      textAlign: "start",
                    }}
                    gutterBottom
                  >
                    Internship start date
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    className="recipient"
                    error={errors && errors.startDate}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        showTodayButton
                        format="dd-MMM-yyyy"
                        style={{ width: "100%" }}
                        margin="normal"
                        className={`input-field dob`}
                        // ${
                        // //   extraErrors && extraErrors.dueDate
                        // //     ? "bg-red"
                        // //     : dueDate
                        // //     ? "bg-green"
                        // //     : "bg-normal"
                        // // }`}
                        inputVariant="outlined"
                        label="Start Date"
                        name="startDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                        disablePast
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormHelperText>{errors.startDate}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "20px",
                      textAlign: "start",
                    }}
                    gutterBottom
                  >
                    Internship duration (Days)
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl
                      style={{
                        width: "100%",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "20px",
                      }}
                      error={errors && errors.duration}
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#000000",
                          width: "100%",
                        }}
                        name="duration"
                        label="Duration"
                        placeholder="Enter duration"
                        value={values.duration}
                        onChange={changeHandler}
                      />
                      <FormHelperText>{errors.duration}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                      textAlign: "start",
                    }}
                    gutterBottom
                  >
                    Intern’s responsibilities
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{
                      width: "100%",
                      padding: "10px",
                      paddingLeft: "10px",
                    }}
                    className="recipient"
                    error={errors && errors.responsibilities}
                  >
                    <CKEditor
                      style={{ padding: "10px 20px" }}
                      maxLength={300}
                      data={internResponsibilities}
                      name="internResponsibilities"
                      editor={ClassicEditor}
                      onChange={changeResponsibilitiesHandler}
                      config={{
                        toolbar: [
                          "Heading",
                          "bold",
                          "italic",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                          "Link",
                        ],
                        placeholder:
                          "Selected intern's day-to-day responsibilities include:",
                      }}
                    />
                    <FormHelperText>{errors.responsibilities}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                      textAlign: "start",
                    }}
                    gutterBottom
                  >
                    Application last date
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{
                      width: "100%",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    className="recipient"
                    error={errors && errors.dueDate}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        showTodayButton
                        format="dd-MMM-yyyy"
                        style={{ width: "100%" }}
                        margin="normal"
                        className={`input-field dob`}
                        // ${
                        // //   extraErrors && extraErrors.dueDate
                        // //     ? "bg-red"
                        // //     : dueDate
                        // //     ? "bg-green"
                        // //     : "bg-normal"
                        // // }`}
                        inputVariant="outlined"
                        label="End Date"
                        name="endDate"
                        value={dueDate}
                        onChange={handleDueDateChange}
                        disablePast
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormHelperText>{errors.dueDate}</FormHelperText>
                  </FormControl>
                </Grid>

                <hr></hr>

                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                    gutterBottom
                  >
                    Internship Requirements
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                    gutterBottom
                  >
                    Who can apply
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    style={{
                      width: "100%",
                      padding: "10px",
                      paddingLeft: "10px",
                    }}
                    className="recipient"
                    error={errors && errors.requirements}
                  >
                    <CKEditor
                      style={{ padding: "10px 20px" }}
                      maxLength={300}
                      data={internRequirements}
                      name="internRequirements"
                      editor={ClassicEditor}
                      onChange={changeRequirementsHandler}
                      config={{
                        toolbar: [
                          "Heading",
                          "bold",
                          "italic",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                          "Link",
                        ],
                        placeholder:
                          "Please describe who can apply for this Internship Opprtunity",
                      }}
                    />
                    <FormHelperText>{errors.requirements}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                    gutterBottom
                  >
                    Skills
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    className="recipient"
                    error={errors && errors.skills}
                  >
                    <Autocomplete
                      multiple
                      freeSolo
                      id="tags-outlined"
                      options={[]}
                      value={skillSet}
                      onChange={(e, newval) => {
                        setSkillSet(newval);
                      }}
                      defaultValue={[]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Skills"
                          placeholder="Type a skill and press Enter key to enter more skills"
                        />
                      )}
                    />
                    <FormHelperText>{errors.skills}</FormHelperText>
                  </FormControl>
                </Grid>

                <hr style={{ paddingLeft: "10px" }}></hr>

                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                    gutterBottom
                  >
                    Internship Benefits
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                    gutterBottom
                  >
                    Stipend
                  </Typography>
                </Grid>

                <Grid container spacing={2} style={{ marginLeft: "0px" }}>
                  <Grid
                    item
                    sm={2}
                    xs={12}
                    style={{
                      marginTop: "8px",

                      // paddingTop: "15px",
                      // paddingLeft: "10px",
                      // paddingBottom:"0px",
                    }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <Select
                        variant="outlined"
                        style={{
                          paddingTop: "2px",
                          paddingLeft: "5px",
                          paddingBottom: "0px",
                          height: "55px",
                        }}
                        name="cashUnit"
                        value={region && region.currency}
                        className="currency"
                        onChange={currencyChangeHandler}
                      >
                        {regions &&
                          regions.length > 0 &&
                          regions.map((region) => {
                            return (
                              <MenuItem value={region.currency}>
                                {region.currency === "INR" ? (
                                  <FontAwesomeIcon
                                    color="#059585"
                                    size="2x"
                                    icon={faRupeeSign}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    color="#059585"
                                    size="2x"
                                    icon={faDollarSign}
                                  />
                                )}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={10}>
                    <FormControl
                      style={{ width: "100%", paddingTop: "10px" }}
                      error={errors && errors.monthlyStipend}
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        style={{
                          fontWeight: "300",
                          fontSize: "20px",
                          color: "#000000",
                          width: "100%",
                        }}
                        name="monthlyStipend"
                        label="Monthly Stipend"
                        placeholder="Enter Monthly Stipend"
                        value={values.monthlyStipend}
                        onChange={changeHandler}
                      />
                      <FormHelperText>{errors.monthlyStipend}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "20px",
                    }}
                    gutterBottom
                  >
                    Perks
                  </Typography>
                  {/* <Typography
                    component="p"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                    }}
                    
                  >
                    {"Optional"}
                  </Typography> */}
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    className="recipient"
                    error={errors && errors.perks}
                  >
                    <Box sx={{ display: "flex" }}>
                      <FormControl
                        style={{ paddingRight: "20px" }}
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={certificate}
                                onChange={handleChangePerks}
                                name="Certificate"
                              />
                            }
                            label="Certificate"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={flexibleWorkHours}
                                onChange={handleChangePerks}
                                name="Flexible Work Hours"
                              />
                            }
                            label="Flexible Work Hours"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={informalDressCode}
                                onChange={handleChangePerks}
                                name="Informal Dress Code"
                              />
                            }
                            label="Informal Dress Code"
                          />
                        </FormGroup>
                      </FormControl>
                      <FormControl
                        style={{ paddingLeft: "-1px" }}
                        component="fieldset"
                        sx={{ m: 3 }}
                        variant="standard"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={letterOfRecommendation}
                                onChange={handleChangePerks}
                                name="Letter Of Recommendation"
                              />
                            }
                            label="Letter Of Recommendation"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={fivedaysaweek}
                                onChange={handleChangePerks}
                                name="5 days a week"
                              />
                            }
                            label="5 days a week"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={freeSnacksandBeverages}
                                onChange={handleChangePerks}
                                name="Free Snacks and Beverages"
                              />
                            }
                            label="Free Snacks and Beverages"
                          />
                        </FormGroup>
                      </FormControl>
                    </Box>
                    <FormHelperText>{errors.perks}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ padding: "10px" }}
                    component="fieldset"
                    error={errors && errors.preplacement}
                  >
                    <FormGroup>
                      <FormControlLabel
                        label="Does this internship come with a pre-placement offer (PPO)? (Optional)"
                        labelPlacement="start"
                        control={
                          <>
                            <Switch
                              checked={preplacement}
                              onChange={handlePrePlacement}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </>
                        }
                      />
                    </FormGroup>
                    <FormHelperText>{errors.preplacement}</FormHelperText>
                  </FormControl>
                </Grid>

                <hr></hr>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                    gutterBottom
                  >
                    Education
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    error={errors && errors.educationalQualification}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        fontWeight: "bold",
                        fontWeight: "400",
                        fontSize: "20px",
                        color: "#000000",
                        width: "100%",
                      }}
                      name="educationalQualification"
                      label="Enter Education Qualification"
                      placeholder="Enter Education Qualification"
                      value={values.educationalQualification}
                      inputProps={{
                        maxLength: 20,
                      }}
                      onChange={changeHandler}
                    />
                    <span
                      className="title-length"
                      style={{ top: 20, padding: "0 15px", background: "none" }}
                    >
                      {" "}
                      {values.educationalQualification
                        ? values.educationalQualification.length
                        : 0}
                      /20
                    </span>
                    <FormHelperText>
                      {errors.educationalQualification}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid>
                  <div>
                    <Button
                      style={{
                        background: "#f44336",
                        margin: "30px 10px 30px 0",
                        float: "right",
                      }}
                      className="submit-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleCreatePost}
                    >
                      Create
                    </Button>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(Internship);

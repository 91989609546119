import React, { useState, useEffect, useContext } from "react";

import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "./EmailImportFiles.scss";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Container from "@material-ui/core/Container";
import { appContext } from "../../App";
import Box from "@material-ui/core/Box";
import { useDropzone } from "react-dropzone";
import FileCard from "./FileCard";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import toastr from "toastr";
import {
  addContacts,
  getMyContacts,
  getMyContactGroups,
} from "../../utils/api";
const Papa = require("papaparse");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const EmailImportFiles = (props) => {
  const {
    authUser,
    contacts,
    setContacts,
    setContactGroups,
    setSelectedUplodingFileContacts,
    selectedUplodingFileContacts,
  } = useContext(appContext);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept:
      ".csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
  });
  const [files, setFiles] = useState([]);
  const [filesData, setFilesData] = useState({});

  useEffect(() => {
    setFiles(acceptedFiles);
    if (acceptedFiles.length === 0) {
      // setSelectedUplodingFileContacts([]);
    }
    for (let file of acceptedFiles) {
      Papa.parse(file, {
        download: true,
        header: true,
        skipEmptyLines: true,
        error: function () {
          /* handle*/
        },
        complete: function (results) {
          const cts = results.data.map((contact) => {
            return {
              firstName: contact.firstname ? contact.firstname : "",
              lastName: contact.lastname ? contact.lastname : "",
              email: contact.email ? contact.email : "",
              phoneNumber: contact.phonenumber ? contact.phonenumber : "",
              userId: authUser.unique_id,
            };
          });
          //let path = file.path;
          //setFilesData({ ...filesData, [file.path]: cts });
          filesData[file.path] = cts;
          //const ctsemails = results.data.map((contact) => contact.email);

          selectedUplodingFileContacts.push(...cts);

          //setData(cts);
          //  toastr.success("Number of contacts found: " + props.data.length);
        },
      });
    }
  }, [acceptedFiles]);

  const deleteFile = (path) => {
    const newFiles = files.filter((file) => file.path !== path);
    setFiles(newFiles);
  };
  //const handleFileCard = () => {};
  const acceptedFileCards = files.map((file, index) => (
    <FileCard
      key={index}
      file={file}
      data={filesData}
      deleteFile={deleteFile}
      // onClick={handleFileCard}
    />
  ));
  const handleClose = () => {
    props.history.push(
      `/startContactEmailCampaign/EmailContactGroups/${props.match.params.id}`
    );
  };
  const handleImportContacts = async () => {
    console.log("-----", selectedUplodingFileContacts);

    try {
      let data = await addContacts(authUser.unique_id, {
        contacts: [...selectedUplodingFileContacts],
      });
      setContacts([...selectedUplodingFileContacts, ...contacts]);

      getMyContacts(authUser.unique_id)
        .then((resp) => {
          setContacts(resp);
        })
        .catch((error) => {
          toastr.error(error.message);
        });

      const groups = await getMyContactGroups(authUser.unique_id);
      setContactGroups(groups);
      toastr.success(data.message);
      props.history.push(
        `/startContactEmailCampaign/EmailContactGroups/${props.match.params.id}`
      );
    } catch (error) {
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message);
      } else {
        toastr.error(error.message);
      }
    }
  };

  const handleBack = () => {
    setSelectedUplodingFileContacts([]);
    setFilesData({});
    setFiles([]);
  };
  return (
    <div className="file-edit-container">
      <Container>
        <div className="drop-box">
          <div className="file-upload-header">
            <Typography variant="h5" gutterBottom>
              Import Contacts
            </Typography>
            <Button onClick={handleClose} variant="outlet" aria-label="close">
              <Typography variant="h5" className="close-btn-text" gutterBottom>
                <CloseIcon />
              </Typography>
            </Button>
          </div>
          {files.length === 0 ? (
            <>
              <div className="file-upload-header">
                <Typography variant="h6" gutterBottom>
                  Upload your contacts
                </Typography>
                <Typography variant="h6" color="primary" gutterBottom>
                  <GetAppIcon />
                  Download an example .csv file
                </Typography>
              </div>
              <div className="file-upload-body">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <CloudUploadIcon color="disabled" style={{ fontSize: 100 }} />
                  <Typography variant="body1" gutterBottom>
                    Select your file or drag and drop some here
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    .csv
                  </Typography>
                </div>
              </div>
              <Typography variant="body1" align="center" gutterBottom>
                We don't sell, rent or use your database for any commercial
                purposes
              </Typography>
            </>
          ) : (
            <>
              <div className="file-upload-header">
                <Typography variant="h6" gutterBottom>
                  Your files has been uploaded
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {acceptedFileCards.length} Files Selected
                </Typography>
              </div>
              <div className="file-cards">{acceptedFileCards}</div>
              <div
                className="back-import-buttons"
                style={{
                  textAlign: "right",
                  margin: "10px 0px",
                }}
              >
                <Button
                  onClick={handleBack}
                  variant="contained"
                  style={{
                    background: "#EFEFEF",
                    borderRadius: "4px",
                    textTransform: "capitalize",
                  }}
                  // className={classes.button}
                  aria-label="back"
                >
                  Back
                </Button>
                <Button
                  onClick={handleImportContacts}
                  variant="contained"
                  style={{
                    background: "#A5A5A5",
                    color: "white",
                    textTransform: "capitalize",
                    marginLeft: "10px",
                  }}
                  //  className={classes.button}
                  aria-label="import contacts"
                >
                  Import
                </Button>
              </div>
              {/* <div className="contact-types">
                  <Typography component="span" variant="body2" gutterBottom>
                    Contact type:
                  </Typography>
                  <Radio
                    checked={selectedValue === "nc"}
                    onChange={handleChange}
                    color="#ff0000"
                    value="nc"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "New Contacts" }}
                  />
                  <Typography component="span" variant="body1" gutterBottom>
                    New Contacts
                  </Typography>
                  <Radio
                    checked={selectedValue === "ed"}
                    onChange={handleChange}
                    value="ed"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "Existing Donors" }}
                  />
                  <Typography component="span" variant="body1" gutterBottom>
                    Existing Donors
                  </Typography>
                </div>
                <div>
                  {selectedValue !== "" && (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Select a group
                      </Typography>
                      {selectedValue === "nc" ? (
                        <div>
                          <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="simple tabs example"
                          >
                            <Tab label="Select a group" {...a11yProps(0)} />
                            <Tab label="Create a group" {...a11yProps(1)} />
                          </Tabs>
                          <TabPanel value={tabValue} index={0}>
                            <FileContactsImportTable setFiles={setFiles} />
                          </TabPanel>
                          <TabPanel value={tabValue} index={1}>
                            Item Two
                          </TabPanel>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  )}
                </div> */}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default withRouter(EmailImportFiles);

import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import "./TalLeaders.scss";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  CircularProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import { SearchRounded } from "@material-ui/icons";
import cssStyle from "./AdminTalLeaders.module.scss";
import AdminAllLeaders from "./AdminAllLeaders";
import AdminNewLeaders from "./AdminNewLeaders";
import { getUsers } from "../../utils/api";
import toastr from "toastr";
import AdminRejectedTalLeaders from "./AdminRejectedTalLeaders";
import Loader from "../common/Loader";
import { TAL_HEROS_COUNTRIES } from "../../utils/utils";

const AdminTalLeaders = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [showNewTalLeaders, setShowNewTalLeaders] = useState(true);
  const [showRejectedLeaders, setShowRejectedLeaders] = useState(false);
  const [showAllTalLeaders, setShowAllTalLeaders] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [regionFilter, setRegionFilter] = useState(" ");
  const [tabStatus, setTabStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [talLeadersList, setTalLeadersList] = useState([]);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const [isFileDownload, setIsFileDownload] = useState(false);

  const handleNewTalLeaders = (status) => {
    if (status !== tabStatus) {
      setTabStatus(status);
      setShowAllTalLeaders(false);
      setShowNewTalLeaders(true);
      setShowRejectedLeaders(false);
    }
  };
  const handleAllTalLeaders = (status) => {
    if (status !== tabStatus) {
      setTabStatus(status);
      setShowNewTalLeaders(false);
      setShowAllTalLeaders(true);
      setShowRejectedLeaders(false);
    }
  };
  const handleRejectedLeaders = (status) => {
    if (status !== tabStatus) {
      setTabStatus(status);
      setShowNewTalLeaders(false);
      setShowAllTalLeaders(false);
      setShowRejectedLeaders(true);
    }
  };

  const loadTALLeaders = (query, tabStatus, talLeadersList, page = 0) => {
    setIsLoading(true);
    getUsers(query, rowsPerPage, page * rowsPerPage, "", tabStatus, "")
      .then((response) => {
        setIsLoading(false);
        setTotalCountOfRecords(response.totalCountOfRecords);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          const LeadersListArray = [];
          talLeadersList.map((data) => {
            const obj = data;
            LeadersListArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            responseArray.push(obj);
            return null;
          });
          setTalLeadersList([...LeadersListArray, ...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching users:" + error.message);
      });
  };

  useEffect(() => {
    if (query) {
      setPage(0);
      setTalLeadersList([]);
      loadTALLeaders(query, tabStatus, [], 0);
    }
  }, [query, tabStatus, rowsPerPage]);

  useEffect(() => {
    var query = "";
    if ((regionFilter == " " ? "" : regionFilter) || searchKeyword) {
      query = `sourceOfSignup=talleaders&country=${regionFilter.trim()}&keyword=${searchKeyword}`;
      setQuery(query);
      setTalLeadersList([]);
    } else {
      query = `sourceOfSignup=talleaders`;
      setQuery(query);
    }
  }, [tabStatus, regionFilter, searchKeyword]);

  const handleSearchFilter = (event) => {
    let keyword = event.target.value;
    if (keyword === "") {
      setSearchKeyword("");
    }
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      setSearchKeyword(event.target.value);
    }
  };

  const exportToCSV = () => {
    setIsFileDownload(true);
    let download = true;
    getUsers(query, "", "", "", tabStatus, download).then((response) => {
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
      hiddenElement.target = "_blank";
      hiddenElement.download = `Tal_Leaders_Data-${new Date().getTime()}.csv`;
      hiddenElement.click();
      setIsFileDownload(false);
    });
  };

  const oncountrychange = (event) => {
    setRegionFilter(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // loadTALHeros(newPage, query, userList);
    loadTALLeaders(query, tabStatus, talLeadersList, newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTalLeadersList([]);
  };
  return (
    <>
      <Grid className={cssStyle.main_box}>
        <Container>
          <Grid container>
            <Grid item md={12}>
              <h2>TALLeaders</h2>
              <p>
                A global community of TALLeaders™ united by the common goal of
                utilizing their professional success for social good.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid style={{ background: "white" }}>
        <Container>
          <Box className={cssStyle.Admin_statusBar}>
            <Box>
              <Button
                className={
                  showNewTalLeaders ? cssStyle.Btn_newLed : cssStyle.Btn_new
                }
                onClick={() => handleNewTalLeaders(0)}
              >
                New Leaders
              </Button>

              <Button
                className={
                  showAllTalLeaders ? cssStyle.Btn_newLed : cssStyle.Btn_new
                }
                onClick={() => handleAllTalLeaders(1)}
              >
                Approved
              </Button>

              <Button
                // className={cssStyle.Btn_new}
                className={
                  showRejectedLeaders ? cssStyle.Btn_newLed : cssStyle.Btn_new
                }
                onClick={() => handleRejectedLeaders(-1)}
              >
                Rejected
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid>
      <Container>
        <Grid container style={{ margin: "15px" }}>
          <Grid item md={6} className={cssStyle.Sort_slelct}>
            <Box className={cssStyle.btn_Export}>
              <SearchRounded style={{ margin: "0 4px", height: "50px" }} />
              <InputBase
                placeholder=" Search TALLeaders"
                width="200px"
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => handleSearchFilter(event)}
                onKeyPress={(event) => handleEnterClick(event)}
              />
            </Box>
          </Grid>
          <Grid item md={6} className={cssStyle.Srch_Export}>
            <span>Sort By :</span>
            <Select
              className={cssStyle.Select_btn}
              variant="outlined"
              name="region"
              placeholder="Select country"
              value={regionFilter}
              onChange={oncountrychange}
            >
              <MenuItem value=" ">All</MenuItem>
              {TAL_HEROS_COUNTRIES &&
                TAL_HEROS_COUNTRIES.map((country, index) => {
                  return (
                    <MenuItem value={country.key} key={index}>
                      {country.value}
                    </MenuItem>
                  );
                })}
            </Select>
            <Box>
              <Button
                startIcon={<FileCopyOutlinedIcon />}
                onClick={exportToCSV}
                style={{
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  height: "55px",
                  margin: "0",
                  backgroundColor: "var(--tal_primary) ",
                }}
              >
                {" "}
                {!isFileDownload && "Export Data"}{" "}
                {isFileDownload && (
                  <CircularProgress
                    style={{ color: "white", lineHeight: "10px" }}
                  />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Container>

      {showNewTalLeaders && (
        <AdminNewLeaders
          talLeadersList={talLeadersList}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTalLeadersList={setTalLeadersList}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          page={page}
          totalCountOfRecords={totalCountOfRecords}
          setTotalCountOfRecords={() =>
            setTotalCountOfRecords(totalCountOfRecords - 1)
          }
        />
      )}
      {showAllTalLeaders && (
        <AdminAllLeaders
          rowsPerPage={rowsPerPage}
          page={page}
          setTalLeadersList={setTalLeadersList}
          talLeadersList={talLeadersList}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          totalCountOfRecords={totalCountOfRecords}
        />
      )}

      {showRejectedLeaders && (
        <AdminRejectedTalLeaders
          talLeadersList={talLeadersList}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          page={page}
          totalCountOfRecords={totalCountOfRecords}
        />
      )}
      <Loader isOpen={isLoading} onClose={() => setIsLoading(false)} />
    </>
  );
};

export default withRouter(AdminTalLeaders);

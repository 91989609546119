const ValidateBank = (values) => {
  let errors = {};
  if (!values.accountNumber) {
    errors.accountNumber = "Please enter Account Number";
  }

  if (!values.label) {
    errors.label = "Please enter bank name";
  }

  if (!values.routingNumber) {
    errors.routingNumber = "Please enter IFSC code";
  }

  return errors;
};

export default ValidateBank;

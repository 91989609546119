import React, { useState, useContext, useEffect } from "react";
import Container from "@material-ui/core/Container";
import {
  Grid,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControl,
} from "@material-ui/core";
import "react-phone-number-input/style.css";
import "./user.scss";

import toastr from "toastr";
import { appContext } from "../../../App";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  getUserInterestedCategories,
  updateProfile,
  getUserData,
  getCategories,
} from "../../../utils/api";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Alert } from "@material-ui/lab";
import Loader from "../../common/Loader";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    width: "100%",
    padding: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  fieldlength: {},
}));

const UserInterests = (props) => {
  const { authUser, setAuthUser, TALLeaderPayload, regions } =
    useContext(appContext);
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [term, setTerm] = useState(false);
  const handleTerm = (event) => {
    if (event.target.checked) {
      setTerm(true);
    } else {
      setTerm(false);
    }
  };
  const [userInterestedCategories, setUserInterestedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const handleChangeCountry = (event) => {
    const value = event.target.value;
    setSelectedCountries(value);
  };

  const loadUserInterestsCategories = () => {
    setIsLoading(true);
    getUserInterestedCategories("")
      .then((response) => {
        setIsLoading(false);
        if (response && response.data) {
          setUserInterestedCategories(response.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Categories:" + error.message);
      });
  };
  useEffect(() => {
    loadUserInterestsCategories();
    loadSupportedSDGs();
  }, []);

  const loadSupportedSDGs = () => {
    setIsWaiting(true);
    getCategories()
      .then((response) => {
        if (response && response.data) {
          setSupportedSDGs(response.data.data);
        }
        setIsWaiting(false);
      })

      .catch((error) => {
        setIsWaiting(false);
        toastr.error("Problem in fetching Categories:" + error.message);
      });
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      loadUserData(authUser.unique_id);
    }
  }, [authUser]);

  const loadUserData = (userId) => {
    setIsLoading(true);
    getUserData(userId)
      .then((response) => {
        setIsLoading(false);
        if (
          response &&
          response.areasOfInterest.length > 0 &&
          response.categoriesOfInterest.length > 0 &&
          response.typeOfHelpAndInvolvement.length > 0
        ) {
          setEditMode(true);
        }
        setUserCategories(response.areasOfInterest);
        response &&
          response.categoriesOfInterest.map((item) => {
            supportedSDGs[item] = true;
          });
        const supportedSDGsAsArray = getKeyByValue(supportedSDGs, true);
        setSupportedSDGs(supportedSDGs);
        setSelectedSupportedSDGs(supportedSDGsAsArray);
        response &&
          response.typeOfHelpAndInvolvement.map((item) => {
            helpAndInvolvement[item] = true;
          });
        const helpAndInvolvementAsArray = getKeyByValue(
          helpAndInvolvement,
          true
        );
        setHelpAndInvolvement(helpAndInvolvement);
        setSelectedHelpAndInvolvement(helpAndInvolvementAsArray);
        setHoursPerMonth(response.hoursPerMonth);
        setSelectedCountries(response.interestedRegions);
        response &&
          response.interestedTypeOfOrgs.map((item) => {
            typeOfInteraction[item] = true;
          });
        const typeOfInteractionAsArray = getKeyByValue(typeOfInteraction, true);
        setTypeOfInteraction(typeOfInteraction);
        setSelectedTypeOfInteraction(typeOfInteractionAsArray);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(error.response.data.message);
      });
  };

  const [isCapturePassword, setIsCapturePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [userCategories, setUserCategories] = useState([]);
  const [hoursPerMonth, setHoursPerMonth] = useState(0);
  const [selectedSupportedSDGs, setSelectedSupportedSDGs] = useState([]);
  const [selectedHelpAndInvolvement, setSelectedHelpAndInvolvement] = useState(
    []
  );
  const [selectedTypeOfInteraction, setSelectedTypeOfInteraction] = useState(
    []
  );

  const [supportedSDGs, setSupportedSDGs] = useState([]);

  const typeOfHelpAndInvolvement = [
    { id: "Board Seats", title: "Board Seats" },
    { id: "Organizational mentoring", title: "Organizational mentoring" },
    {
      id: "Individual mentoring",
      title: "Individual mentoring",
    },
    {
      id: "Topical engagement in an area of expertise",
      title: "Topical engagement in an area of expertise",
    },
    {
      id: "Financial contribution",
      title: "Financial contribution",
    },
    {
      id: "Helping with networking, strategy and business decisions",
      title: "Helping with networking, strategy and business decisions",
    },
  ];
  const [helpAndInvolvement, setHelpAndInvolvement] = useState([]);
  const interestedTypeOfOrgs = [
    {
      id: "Certified charities (eg. 501-C3)",
      title: "Certified charities (eg. 501-C3)",
    },
    {
      id: "Non certified charities",
      title: "Non certified charities",
    },
    {
      id: "Individuals",
      title: "Individuals",
    },
  ];
  const [typeOfInteraction, setTypeOfInteraction] = useState([]);
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] == value);
  }

  const handleUserCategories = (value) => {
    const index = userCategories.indexOf(value);
    if (index === -1) {
      setUserCategories([...userCategories, value]);
    } else {
      const newValues = [...userCategories];
      newValues.splice(index, 1);
      setUserCategories(newValues);
    }
  };

  const handleChangeUserInterest = (value) => {
    const index = selectedSupportedSDGs.indexOf(value);
    if (index === -1) {
      setSelectedSupportedSDGs([...selectedSupportedSDGs, value]);
    } else {
      const newValues = [...selectedSupportedSDGs];
      newValues.splice(index, 1);
      setSelectedSupportedSDGs(newValues);
    }
  };

  const handleChangeHelpAndInvolvement = (value) => {
    const index = selectedHelpAndInvolvement.indexOf(value);
    if (index === -1) {
      setSelectedHelpAndInvolvement([...selectedHelpAndInvolvement, value]);
    } else {
      const newValues = [...selectedHelpAndInvolvement];
      newValues.splice(index, 1);
      setSelectedHelpAndInvolvement(newValues);
    }
  };

  const handleChangeInterestedTypeOfOrgs = (value) => {
    const index = selectedTypeOfInteraction.indexOf(value);
    if (index === -1) {
      setSelectedTypeOfInteraction([...selectedTypeOfInteraction, value]);
    } else {
      const newValues = [...selectedTypeOfInteraction];
      newValues.splice(index, 1);
      setSelectedTypeOfInteraction(newValues);
    }
  };

  const handleChangeHoursPerMonth = (e) => {
    setHoursPerMonth(e.target.value);
  };

  const restrictCharacters = (event) => {
    const allowedCharacters = /^[0-9]\d*$/;
    if (!allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleNext = () => {
    const finalValues = {};
    const errors = {};
    if (isCapturePassword) {
      if (!password) {
        errors.password = "Please enter password";
      } else {
        if (
          !password.match(/[a-z]/) ||
          !password.match(/[A-Z]+/) ||
          !password.match(/\d/) ||
          !password.match(/[^a-zA-Z0-9\-/]/) ||
          password.length < 6
        ) {
          errors.password =
            "The password does not meet all the below requirements";
        } else {
          finalValues.password = password;
        }
      }
    }
    if (userCategories && userCategories.length < 1) {
      errors.whatinterestsyou = "Please select at least One";
    }
    if (selectedSupportedSDGs && selectedSupportedSDGs.length < 1) {
      errors.supportedSDGs = "Please select at least One";
    }
    if (selectedHelpAndInvolvement && selectedHelpAndInvolvement.length < 1) {
      errors.helpAndInvolvement = "Please select at least One";
    }
    if (isNaN(parseInt(hoursPerMonth))) {
      errors.hoursPerMonth = "Please provide the value as a number";
    }
    // if (selectedCountries && selectedCountries.length < 1) {
    //   errors.country = "Please select at least any one of the region";
    // }
    // if (selectedTypeOfInteraction && selectedTypeOfInteraction.length < 1) {
    //   errors.typeOfInteraction =
    //     "Please select at least a type of interaction you want";
    // }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      toastr.error(
        "Please check and clear all the errors on this form and retry!"
      );
      return;
    }
    finalValues.user_id =
      (TALLeaderPayload && TALLeaderPayload.userId) ||
      (authUser && authUser.unique_id);

    finalValues.isTALLeader = true;
    finalValues.areasOfInterest = userCategories;
    finalValues.categoriesOfInterest = selectedSupportedSDGs;
    finalValues.typeOfHelpAndInvolvement = selectedHelpAndInvolvement;
    finalValues.hoursPerMonth = hoursPerMonth ? parseInt(hoursPerMonth) : 0;
    finalValues.interestedRegions = selectedCountries;
    finalValues.interestedTypeOfOrgs = selectedTypeOfInteraction;
    if (Object.keys(errors).length == 0) {
      updateProfile(
        TALLeaderPayload ? TALLeaderPayload.userId : authUser.unique_id,
        finalValues
      )
        .then((response) => {
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
          setAuthUser(response.data.data);
          toastr.success("User Profile Updated Successfully");
          props.history.push("/");
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              toastr.error(error.response.data.errors[0].msg);
            } else {
              toastr.error(error.response.message);
            }
          } else {
            toastr.error(error.message);
          }
        });
    }
  };

  useEffect(() => {
    if (props.location.state && props.location.state.isCapturePassword) {
      setIsCapturePassword(props.location.state.isCapturePassword);
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
    // Let's validate the password
    const password = event.target.value;
    const errors = {};
    if (!password) {
      errors.password = "Please enter password";
    } else {
      if (
        !password.match(/[a-z]/) ||
        !password.match(/[A-Z]+/) ||
        !password.match(/\d/) ||
        !password.match(/[^a-zA-Z0-9\-/]/) ||
        password.length < 6
      ) {
        errors.password =
          "The password does not meet all the below requirements";
      } else {
        errors.password = "";
      }
      setErrors((currentValue) => {
        return { ...currentValue, ...errors };
      });
    }
  };

  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container" style={{ display: "unset" }}>
          <Grid
            md={12}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="field-length-in">
              <Grid item xs={12}>
                <Typography
                  component="p"
                  style={{
                    fontWeight: "bold",
                    fontSize: "30px",
                    color: "#000",
                    paddingLeft: "10px",
                    paddingTop: "20px",
                  }}
                  gutterBottom
                >
                  Please Complete Your User Profile Setup:
                </Typography>
              </Grid>
            </div>
            {isCapturePassword && (
              <Grid className={classes.root}>
                <div className="field-length-in">
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#000",
                        paddingLeft: "10px",
                        paddingTop: "20px",
                      }}
                      gutterBottom
                    >
                      Please create a password for your account{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="flname">
                      <div className="pass-container">
                        <div className="names pass-field">
                          <Box component="div" position="relative">
                            <TextField
                              className={`full-width border-radius signup-input ${
                                errors.password && "error-text"
                              }`}
                              variant="outlined"
                              fullWidth
                              placeholder="Password *"
                              type={showPassword ? "password" : "text"}
                              name="something"
                              onChange={passwordChangeHandler}
                              value={password}
                              autoComplete="new-password"
                            />
                            <InputAdornment
                              fullWidth
                              position="end"
                              className="showpassword"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                style={{
                                  top: "3px",
                                  position: "absolute",
                                  right: "10px",
                                }}
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Tooltip title="Click to Show the Password">
                                    <VisibilityOff />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Click to hide the Password">
                                    <Visibility />
                                  </Tooltip>
                                )}
                              </IconButton>
                            </InputAdornment>
                          </Box>
                        </div>

                        {(errors ||
                          !password.match(/[a-z]/) ||
                          !password.match(/[A-Z]+/) ||
                          !password.match(/\d/) ||
                          !password.match(/[^a-zA-Z0-9\-/]/) ||
                          password.length < 6) && (
                          <div className="custom-error">{errors.password}</div>
                        )}
                        {password &&
                          (!password.match(/[a-z]/) ||
                            !password.match(/[A-Z]+/) ||
                            !password.match(/\d/) ||
                            !password.match(/[^a-zA-Z0-9\-/]/) ||
                            password.length < 6) && (
                            <div className="password-strength">
                              <ul className="password-error">
                                <li>
                                  {password.match(/[a-z]/) ? (
                                    <Alert severity="success">
                                      Contains at least{" "}
                                      <strong>one letter</strong>
                                    </Alert>
                                  ) : (
                                    <Alert severity="error">
                                      Contains at least{" "}
                                      <strong>one letter</strong>
                                    </Alert>
                                  )}
                                </li>
                                <li>
                                  {password.match(/[A-Z]+/) ? (
                                    <Alert severity="success">
                                      Contains at least{" "}
                                      <strong>one capital letter</strong>
                                    </Alert>
                                  ) : (
                                    <Alert severity="error">
                                      Contains at least{" "}
                                      <strong>one capital letter</strong>
                                    </Alert>
                                  )}
                                </li>
                                <li>
                                  {password.match(/\d/) ? (
                                    <Alert severity="success">
                                      Contains at least{" "}
                                      <strong>one digit</strong>
                                    </Alert>
                                  ) : (
                                    <Alert severity="error">
                                      Contains at least{" "}
                                      <strong>one digit</strong>
                                    </Alert>
                                  )}
                                </li>
                                <li>
                                  {password.match(/[^a-zA-Z0-9\-/]/) ? (
                                    <Alert severity="success">
                                      Contains at least{" "}
                                      <strong>one special character</strong>
                                    </Alert>
                                  ) : (
                                    <Alert severity="error">
                                      Contains at least{" "}
                                      <strong>one special character</strong>
                                    </Alert>
                                  )}
                                </li>
                                <li
                                  className={
                                    password.length >= 6 ? "valid" : "invalid"
                                  }
                                  style={{ "text-align": "start" }}
                                >
                                  {password.length >= 6 ? (
                                    <Alert severity="success">
                                      Contains at least{" "}
                                      <strong>6 characters</strong>
                                    </Alert>
                                  ) : (
                                    <Alert severity="error">
                                      Contains at least{" "}
                                      <strong>6 characters</strong>
                                    </Alert>
                                  )}
                                </li>
                              </ul>
                            </div>
                          )}
                      </div>
                    </div>
                  </Grid>
                </div>
              </Grid>
            )}
            <Grid className={classes.root}>
              <div className="field-length-in">
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "20px",
                    }}
                    gutterBottom
                  >
                    Please select your areas of interest{" "}
                    <span style={{ color: "red" }}>*</span> (Select at least
                    one)
                  </Typography>
                </Grid>
                <div>
                  {!isLoading &&
                    userInterestedCategories &&
                    userInterestedCategories.length > 0 &&
                    userInterestedCategories.map((category, index) => (
                      <Button
                        value={userCategories}
                        onClick={() => handleUserCategories(category.name)}
                        id={userCategories}
                        className={
                          `user_btn ${userCategories.includes(category.name)
                            ? "active-btn "
                            : "btn-render"
                          }`}
                      >
                        {category.name}
                        {userCategories.includes(category.name) ? (
                          <CheckIcon />
                        ) : (
                          <AddIcon />
                        )}
                      </Button>
                    ))}
                </div>
                {errors && errors.whatinterestsyou && (
                  <div className="custom-error">{errors.whatinterestsyou}</div>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#000",
                        paddingTop: "25px",
                        paddingLeft: "10px",
                      }}
                      gutterBottom
                    >
                      What general areas are you passionate about?{" "}
                      <span style={{ color: "red" }}>*</span> (Select at least
                      one)
                    </Typography>
                  </Grid>
                  <div>
                    {!isLoading &&
                      supportedSDGs &&
                      supportedSDGs.length > 0 &&
                      supportedSDGs.map((category, index) => (
                        <Button
                          value={selectedSupportedSDGs}
                          onClick={() =>
                            handleChangeUserInterest(category.name)
                          }
                          id={selectedSupportedSDGs}
                          className={
                            `user_btn ${ selectedSupportedSDGs.includes(category.name)
                              ? "active-btn "
                              : "btn-render"
                          }`}
                        >
                          {category.name}
                          {selectedSupportedSDGs.includes(category.name) ? (
                            <CheckIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </Button>
                      ))}
                  </div>
                </Grid>
                <Grid container >
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#000",
                        paddingTop: "20px",
                        paddingLeft: "10px",
                      }}
                      gutterBottom
                    >
                      What type of help and involvement would you like to
                      provide? <span style={{ color: "red" }}>*</span> (Select
                      at least one)
                    </Typography>
                  </Grid>

                  <div>
                    <>
                      {typeOfHelpAndInvolvement.map((item) => {
                        return (
                          <Button
                            className={
                              `user_btn ${selectedHelpAndInvolvement.includes(item.id)
                                ? "active-btn "
                                : "btn-render"
                            }`}
                            key={item.id}
                            value={selectedHelpAndInvolvement}
                            id={selectedHelpAndInvolvement}
                            onClick={() =>
                              handleChangeHelpAndInvolvement(item.id)
                            }
                            name="selectedHelpAndInvolvement"
                          >
                            {item.title}

                            {selectedHelpAndInvolvement.includes(item.id) ? (
                              <CheckIcon />
                            ) : (
                              <AddIcon />
                            )}
                          </Button>
                        );
                      })}
                    </>
                  </div>

                  <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    {errors && errors.helpAndInvolvement && (
                      <Typography className="custom-error">
                        {errors.helpAndInvolvement}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#000",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                      gutterBottom
                    >
                      How many hours per month can you contribute?{" "}
                    </Typography>
                  </Grid>
                  <TextField
                    fullWidth
                    type="number"
                    min="1"
                    max="100"
                    variant="outlined"
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      color: "#000000",
                      width: "100%",
                      paddingLeft: "10px",
                    }}
                    name="numberOfHoursPerMonth"
                    value={hoursPerMonth}
                    onChange={handleChangeHoursPerMonth}
                    onKeyPress={restrictCharacters}
                  />
                  {errors && errors.hoursPerMonth && (
                    <div className="custom-error">{errors.hoursPerMonth}</div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#000",
                      paddingLeft: "10px",
                      paddingTop: "20px",
                      paddingBottom: "10px",
                    }}
                    gutterBottom
                  >
                    Do you have a geographic need? If so, please select the
                    geographic area.
                  </Typography>
                </Grid>
                <FormControl style={{ width: "100%", paddingLeft: "10px" }}>
                  <InputLabel
                    htmlFor="Select Region"
                    style={{ paddingLeft: "33px", marginTop: "-6px" }}
                  >
                    Select Region
                  </InputLabel>
                  <Select
                    fullWidth
                    variant="outlined"
                    name="selectRegion"
                    label="Select Region"
                    multiple
                    value={selectedCountries}
                    onChange={handleChangeCountry}
                    renderValue={(selectedCountries) =>
                      selectedCountries.join(",")
                    }
                  >
                    {regions.map((region) => (
                      <MenuItem key={region._id} value={region.name}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              selectedCountries.indexOf(region.name) !== -1
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={region.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {errors && errors.country && (
                    <div className="custom-error">{errors.country}</div>
                  )} */}
                </FormControl>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#000",
                        paddingTop: "20px",
                        paddingLeft: "10px"
                      }}
                      gutterBottom
                    >
                      What type of interaction do you want to have?{" "}
                    </Typography>
                  </Grid>
                  <div>
                    <>
                      {interestedTypeOfOrgs.map((item) => {
                        return (
                          <Button
                            className={
                              `user_btn ${selectedTypeOfInteraction.includes(item.id)
                                ? "active-btn "
                                : "btn-render"
                            }`}
                            key={item.id}
                            value={selectedTypeOfInteraction}
                            id={selectedTypeOfInteraction}
                            onClick={() =>
                              handleChangeInterestedTypeOfOrgs(item.id)
                            }
                            name="selectedTypeOfInteraction"
                          >
                            {item.title}

                            {selectedTypeOfInteraction.includes(item.id) ? (
                              <CheckIcon />
                            ) : (
                              <AddIcon />
                            )}
                          </Button>
                        );
                      })}
                    </>
                  </div>
                </Grid>
              </div>
            </Grid>
            <div className="field-length-in">
              {!editMode && (
                <Grid item xs={12} style={{ marginLeft: "10px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="term"
                        checked={term}
                        onChange={handleTerm}
                        required="required"
                      />
                    }
                    label="I thank you for inviting me to become a TALLeader. I pledge that I will contribute my time, efforts and energy in improving the Touch-A-Life platform."
                  />
                </Grid>
              )}

              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <Button
                    style={{
                      marginBottom: "10px",
                      opacity:
                        !editMode && term ? 1 : editMode && !term ? 1 : 0.5,
                    }}
                    className="submit-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      !editMode && term
                        ? false
                        : editMode && !term
                        ? false
                        : true
                    }
                    onClick={handleNext}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>
        </div>
      </Container>
      <Loader
        isOpen={isLoading || isWaiting}
        onClose={() => {
          isLoading ? setIsLoading(false) : setIsWaiting(false);
        }}
      />
    </div>
  );
};

export default UserInterests;

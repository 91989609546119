import React, { useRef, useContext } from "react";
import { Box } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import "./custom-styles.css";
import { appContext } from "../../App";
import PrintCertificate from "./printCertificate";

const ReadingEventCertificate = () => {
  const { authUser } = useContext(appContext);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div className="text-center mb-3">
     
      <Box
        component="Button"
        color="rgb(243, 90, 87)"
        bgcolor="white"
        borderRadius="50px"
        width="40px"
        height="40px"        
        border="1px solid rgb(243, 90, 87)"
        marginBottom="20px"
        onClick={handlePrint}
      >
        <PrintIcon />
      </Box>
      <PrintCertificate ref={printRef} authUser={authUser} />
    </div>
  );
};

export default ReadingEventCertificate;

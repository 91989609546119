import axios from "axios";
const createPaymentIntent = (options) => {
  if (!options) {
    options = {};
  }
  options.dontShowLoader = true;
  return axios
    .post(`/create/payment/intent`, options)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
        return data;
      }
    });
};
const getAllSavedCards = (options) => {
  if (!options) {
    options = {};
  }
  return axios
    .get(`/saved/cards/${options.customerId}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
        return data;
      }
    });
};

const chargeSavedCard = (options) => {
  if (!options) {
    options = {};
  }
  return axios
    .post(`/charge/card`, options)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    })
    .then((data) => {
      if (data.error) {
        return data;
      } else {
        return data;
      }
    });
};

const getProductDetails = (options) => {
  return axios
    .get(`/product/details`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    })
    .then((data) => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const api = {
  createPaymentIntent,
  getProductDetails: getProductDetails,
  getAllSavedCards : getAllSavedCards,
  chargeSavedCard : chargeSavedCard
};

export default api;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ContactModal from "../Contacts/ContactModal";
import AddContactsModal from "../Contacts/AddContactsModal";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import { withRouter } from "react-router-dom";

import "../Organization/Organization.scss";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],
    // paddingBottom: "20px",
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    padding: 10,
    display: "inline-block",
    textAlign: "center",
    borderTop: "1px solid grey",
    borderBottom: "1px solid grey",
  },

  title: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },

  btnCntr: {
    // width: "40%",
    padding: "20px",
    margin: "auto",
  },
  contactListCntr: {
    // width: "60%",
    margin: "auto",
    overflowX: "auto",
    height: "auto",
  },
  list: {
    borderBottom: "1px solid rgb(246, 247, 251)",
  },
  contactsAddButton: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    "& button": {
      width: "180px",
      height: "150px",
      background: "#F4F4F4",
      border: " 1px solid #ADADAD",
      borderRadius: "4px",
      margin: "10px",
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "0",
        padding: "0",
      },
      "& span": {
        display: "block",
        fontSize: "16px",
        "& h5": {
          fontSize: "14px",
          lineHeight: "25px",
        },
      },
    },
  },
}));

const EmailAddContactsModal = (props) => {
  const { onContactGroupSave } = props;
  const classes = useStyles();
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openCSVModal, setOpenCSVModal] = useState(false);
  const [currentContact] = useState(null);
  const handleUploadContacts = () => {
    props.history.push(
      `/startContactEmailCampaign/EmailImportFiles/${props.match.params.id}`
    );
    //onContactGroupSave();
    //setOpenCSVModal(true);
  };
  const handleAddContact = () => {
    onContactGroupSave();
    setOpenContactModal(true);
  };
  const handleContactModalClose = () => {
    setOpenContactModal(false);
  };

  const handleCSVModalClose = () => {
    setOpenCSVModal(false);
  };

  const contactSaveHandler = () => {
    setOpenContactModal(false);
    //window.location.reload();
  };

  const contactsSaveHandler = () => {
    setOpenCSVModal(false);
    //window.location.reload();
  };
  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 15px 0px 15px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.title}>Add Contacts</h1>
              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  position: "relative",
                  top: "-7px",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onModalClose} />
              </span>
            </div>
            <div className={classes.plasma}>
              <div className={classes.contactListCntr}>
                <span className={classes.contactsAddButton}>
                  <Button
                    onClick={handleUploadContacts}
                    variant="contained"
                    className={classes.button}
                    startIcon={<OpenInBrowserIcon />}
                    aria-label="upload your contacts"
                  >
                    <h5>Upload your contacts(.formats)</h5>
                  </Button>
                </span>
                <b> or</b>
                <span className={classes.contactsAddButton}>
                  <Button
                    onClick={handleAddContact}
                    variant="contained"
                    className={classes.button}
                    startIcon={<AddCircleOutlineIcon />}
                    aria-label="add contact manually"
                  >
                    <h5>Add them manually</h5>
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <AddContactsModal
        openModal={openCSVModal}
        onModalClose={handleCSVModalClose}
        onContactsSave={contactsSaveHandler}
      ></AddContactsModal>
      <ContactModal
        currentContact={currentContact}
        openModal={openContactModal}
        onModalClose={handleContactModalClose}
        onContactSave={contactSaveHandler}
      ></ContactModal>
    </div>
  );
};

export default withRouter(EmailAddContactsModal);

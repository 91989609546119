import React, { useEffect, useState, useContext } from "react";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { appContext } from "../../App";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, TextareaAutosize } from "@material-ui/core";
import cssStyle from "./AdminTalLeaders.module.scss";
import { updateProfile } from "../../utils/api";
import toastr from "toastr";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    " & > div": {
      "&:first-child": {
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      },
    },
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "450px",
    overflowX: "auto",
    height: "351px",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  paper2: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "450px",
    overflowX: "auto",
    height: "220px",
    boxShadow: theme.shadows[5],
    paddingBottom: "10px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },

  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));
const RejectorApproveTALLeaders = (props) => {
  const classes = useStyles();
  const { authUser } = useContext(appContext);
  const [rejectionNotes, setRejectionNotes] = useState("");
  const [accept, setAccept] = useState(false);

  const handleRejectionNotes = (event) => {
    setRejectionNotes(event.target.value);
  };
  const handleActions = () => {
    setAccept(true);
    props.onCloseModal();
  };
  const updateUserProfile = () => {
    const finalValues = {};
    if (props.status == -1) {
      finalValues.notes = rejectionNotes;
      finalValues.reviewedBy = authUser && [{ userId: authUser.unique_id }];
    }
    finalValues.profileVerificationStatus = props.status;
    updateProfile(props.id, finalValues)
      .then((response) => {
        props.setTalLeadersList((donationRequests) => {
          return donationRequests.filter(
            (donationRequest) => donationRequest._id !== props.id
          );
        });
        props.TotalCountOfRecords();
        toastr.success("Action updated successfully");
        setAccept(false);
      })
      .catch((error) => {
        if (error.response) {
          toastr.error("Failed to update userdata");
        }
      });
  };
  useEffect(() => {
    accept && updateUserProfile();
  }, [accept]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div
            className={`${
              props.status == 1 ? classes.paper2 : classes.paper
            } careerhelp-modal`}
          >
            <Box className={cssStyle.box_model}>
              <div className={cssStyle.tab_bar}>
                <span className={cssStyle.Rejct_span}>
                  {" "}
                  <CloseIcon onClick={props.onCloseModal} />
                </span>
              </div>
              {props.status == -1 && (
                <div style={{ marginTop: "-18px" }}>
                  <div>
                    <p style={{ fontWeight: "500" }}>
                      {" "}
                      Provide a reason for rejection
                    </p>
                  </div>
                  <hr />
                  <TextareaAutosize
                    variant="outlined"
                    rows="6"
                    fullWidth
                    label="Rejection Notes"
                    placeholder="Rejection Notes"
                    name="rejectionNotes"
                    value={rejectionNotes}
                    maxLength={1200}
                    className={cssStyle.Rejct_Area}
                    onChange={(event) => handleRejectionNotes(event)}
                  />
                </div>
              )}
              {props.status == 1 && (
                <>
                  {" "}
                  <div style={{ marginTop: "-15px" }}>
                    <p style={{ fontWeight: "500" }}>
                      {" "}
                      Are you sure you want to approve this profile?
                    </p>
                    <p style={{ fontWeight: "400" }}>
                      {" "}
                      Once you approve the profile, this profile will be moved
                      to TALLeaders section.{" "}
                    </p>
                  </div>
                </>
              )}
              <hr />
              <div className={cssStyle.Rejct_text} style={{ display: "flex" }}>
                <div className={cssStyle.cancle_btn}>
                  <Button onClick={props.onCloseModal}>
                    {" "}
                    {props.status == 1 ? "Close" : "Cancel"}{" "}
                  </Button>
                </div>
                <div
                  className={
                    props.status == 1
                      ? cssStyle.Approv_btn
                      : cssStyle.reject_btn
                  }
                >
                  <Button  onClick={handleActions}>
                    {props.status == 1 ? "Approve" : "Reject"}
                  </Button>
                </div>
              </div>{" "}
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default RejectorApproveTALLeaders;

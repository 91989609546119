import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  RadioGroup,
  Radio,
  Input,
  InputAdornment,
  FilledInput,
  ButtonGroup,
} from "@material-ui/core";
import Sidemenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import { useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createDonationRequest,
  updateDonationRequest,
  getDonationRequest,
} from "../../utils/api";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DescriptionIcon from "@material-ui/icons/Description";
import { HOME_PAGE, validateUrl } from "../../utils/utils";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  FormControlLabel,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Img from "react-cool-img";
import {
  faFileUpload,
  faRupeeSign,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import ShortAddress from "../common/ShortAddress";
import "../TALLeaders/TalLeaders.scss";
import styles from "./EventParticipation.module.scss";
import "./EventParticipation.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },

  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  fieldlength: {},
}));
const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getSteps() {
  return ["Event Info", "Participation Info", "Uploads"];
}
const EventParticipation = (props) => {
  const { authUser, region, orgList, regions, setRegion } =
    useContext(appContext);
  const eventTypes = ["Conference", "Seminar", "Workshop", "Others"];
  const initialState = {
    eventName: "",
    eventVenue: "",
    websiteLink: "",
    requirementTitle: "",
    comments: "",
    targetAudience: "",
    expectedAudience: "",
    speakerTopic: "",
    speakerFee: "",
    fundsRecipient: "",
    cashUnit: "USD",
  };
  const [eventStartDate, setEventStartDate] = useState(null);
  const [otherEvent, setOtherEvent] = useState("");
  const [eventEndDate, setEventEndDate] = useState(null);
  const [eventMode, setEventMode] = useState("");
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [days, setDays] = useState("");
  const [minutes, setMinutes] = useState("");
  const [hours, setHours] = useState("");

  const [closeSideMenu] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [orgEdit, setOrgEdit] = useState(false);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const classes = useStyles();
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");

  const [eventType, setEventType] = useState("");
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [id, setId] = useState("");
  const [imageUpload, setImageUpload] = useState(false);
  const [isOrgMember, setIsOrgMember] = useState(false);
  const [showStartDateCalendar, setShowStartDateCalendar] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalendar] = useState(false);

  const handleClickStartDateCalendar = () => {
    setShowStartDateCalendar(true);
  };
  const handleCloseStartDateCalendar = () => {
    setShowStartDateCalendar(false);
  };

  const handleClickEndDateCalendar = () => {
    setShowEndDateCalendar(true);
  };
  const handleCloseEndDateCalendar = () => {
    setShowEndDateCalendar(false);
  };

  const changeOtherEvent = (e) => {
    setOtherEvent(e.target.value);
  };

  const handleChangeEventType = (event) => {
    setEventType(event.target.value);
  };

  const handleChangeEventMode = (event) => {
    setEventMode(event.target.value);
  };

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
      pitchDeckVideoUrl: values.pitchDeckVideoUrl,
      status: 0,
    };
    if (
      authUser &&
      authUser.unique_id &&
      donationRequest &&
      donationRequest.user_info &&
      authUser.unique_id == donationRequest.user_info._id
    ) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    } else if (!editMode) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    }
    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the event speaker request. " +
            error.message
        );
      });
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const handleChangeEventStartDate = (date) => {
    const roundedDate = date && moment(date.getTime()).toDate();
    setEventStartDate(roundedDate);
  };

  const handleChangeEventEndDate = (date) => {
    const roundedDate = date && moment(date.getTime()).toDate();
    setEventEndDate(roundedDate);
  };
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };
  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };
  const createEventSpeakerRequest = (e) => {
    setAddress(tempAddress);
    e.preventDefault();
    const errors = {};
    if (activeStep === 0) {
      const errors = {};
      const finalValues = {};
      const endDate =
        eventEndDate === donationRequest.due_date
          ? eventEndDate
          : eventEndDate && eventEndDate.getTime();
      const startDate =
        eventStartDate === donationRequest.start_date
          ? eventStartDate
          : eventStartDate && eventStartDate.getTime();
      if (!values.fundsRecipient || values.fundsRecipient === "add") {
        errors.fundsRecipient = "Please select or add an organization";
      }
      if (values.eventName.length === 0) {
        errors.eventName = "Please enter event name";
      } else if (values.eventName && values.eventName.length > 100) {
        errors.eventName =
          "Please enter Event Name less than or equal to 100 characters";
      }
      if (eventType.length === 0) {
        errors.eventType = "Please select event type";
      }
      if (eventType === "Others" && otherEvent.length === 0) {
        errors.otherEvent = "Please enter event type";
      }
      if (eventStartDate === null) {
        errors.eventStartDate = "Please select event start date";
      }
      if (values.requirementTitle.length === 0) {
        errors.requirementTitle = "Please enter title";
      } else if (
        values.requirementTitle &&
        values.requirementTitle.length > 70
      ) {
        errors.requirementTitle =
          "Please enter Title less than or equal to 70 characters";
      }
      if (eventEndDate === null) {
        errors.eventEndDate = "Please select event end date";
      }

      if (endDate < startDate) {
        errors.eventEndDate = "Please select end date greater than start date";
      }
      if (days.length === 0) {
        errors.days = "Please enter Days";
      } else if (Number(days) <= 0 || Number(days) > 100) {
        errors.days = "Please enter days between 1 to 100";
      }
      if (hours.length === 0) {
        errors.hours = "Please enter Hours";
      } else if (Number(hours) <= 0 || Number(hours) > 100) {
        errors.hours = "Please enter Hours between 1 to 100";
      }
      if (
        tempAddress === null ||
        tempAddress.city === null ||
        tempAddress.state === null ||
        tempAddress.country === null
      ) {
        errors.address = "Please select your location";
      } else if (tempAddress) {
        if (
          tempAddress.city.length === 0 ||
          tempAddress.state.length === 0 ||
          tempAddress.country.length === 0
        ) {
          errors.address = "Please select your location";
        }
      }
      if (values.websiteLink && !validateUrl(values.websiteLink)) {
        errors.websiteLink = "Please enter valid URL";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      finalValues.request_type = "eventSpeaker";
      finalValues.region = region && region._id;
      if (values.fundsRecipient && values.fundsRecipient !== "self") {
        finalValues.orgId = values.fundsRecipient;
        finalValues.fundsRecipient = "";
      } else {
        finalValues.fundsRecipient = values.fundsRecipient;
        finalValues.orgId = null;
      }
      finalValues.eventName = values.eventName;
      finalValues.requested_for =
        eventType === "Others" ? otherEvent : eventType;
      if (donationRequest.start_date === eventStartDate) {
        finalValues.start_date = eventStartDate;
      } else {
        finalValues.start_date = eventStartDate.getTime();
      }
      if (donationRequest.due_date === eventEndDate) {
        finalValues.end_date = eventEndDate;
      } else {
        finalValues.due_date = eventEndDate.getTime();
      }
      finalValues.quantity =
        (donationRequest.quantity && donationRequest.quantity.length > 0) ||
        donationRequest.quantity > 0
          ? donationRequest.quantity
          : 0;
      finalValues.user_id = authUser.unique_id;
      finalValues.region = tempAddress.country ? region && region._id : null;
      if (id !== "") {
        finalValues.shipping_address = {
          city: tempAddress.city,
          state: tempAddress.state,
          country: tempAddress.country,
          line1: values.eventVenue,
        };
      } else {
        finalValues.city = tempAddress.city;
        finalValues.state = tempAddress.state;
        finalValues.country = tempAddress.country;
        finalValues.line1 = values.eventVenue;
      }

      const duration = {
        days: days,
        hours: hours,
        minutes: minutes,
      };
      finalValues.duration = duration;
      finalValues.websiteUrl = values.websiteLink;
      finalValues.title = values.requirementTitle;

      if (Object.keys(errors).length == 0) {
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Event Participation request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
              // setActiveStep(0);
            });
        }
      }
    } else if (activeStep === 1) {
      const errors = {};
      const finalValues = {};
      if (description.length === 0) {
        errors.description = "Please enter a brief description";
      }

      if (eventMode.length === 0) {
        errors.eventMode = "Please select  one";
      }
      if (values.targetAudience.length === 0) {
        errors.targetAudience = "Please enter target audience";
      }
      if (values.expectedAudience.length === 0) {
        errors.expectedAudience = "Please enter approx target audience";
      }
      if (values.speakerTopic.length === 0) {
        errors.speakerTopic = "Please enter topic / Theme for the speaker";
      }

      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }

      finalValues.user_id = authUser.unique_id;
      finalValues.description = description;
      finalValues.preferredConsultationMode = eventMode;
      finalValues.targetPartners = values.targetAudience;
      finalValues.size = values.expectedAudience;
      finalValues.brief = values.speakerTopic;
      finalValues.quantity =
        (values.speakerFee && values.speakerFee.length > 0) || values.speakerFee
          ? values.speakerFee
          : 0;
      finalValues.units = address.country === "India" ? "INR" : "USD";
      finalValues.notes = values.comments;
      if (Object.keys(errors).length == 0) {
        if (id !== "") {
          setIsLoading(true);
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
              setIsLoading(false);
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the event speaker request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          setIsLoading(true);
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
                setIsLoading(false);
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
            });
        }
      }
    } else if (activeStep === 2) {
      if (!defaultImageUrl) {
        errors.image = "Please upload at least one image";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
        setErrors({});
        saveDonationRequest(defaultImageUrl);
        if (imageUpload) {
          toastr.success(
            "Your Event Speaker is successfully" +
              (props.match.params.id ? " updated" : " created ")
          );
          props.history.push(`/donationRequest/${name}`);
        }
      }
    }
  };

  const handleInDays = () => {
    if (days === "e" || days === "" || Number(days) < 0) setDays(0);
    else if (Number(days) >= 100) setDays(100);
    else setDays(days + 1);
  };
  const handleDeDays = () => {
    if (days === "e" || days === "" || Number(days) < 0) setDays(0);
    else if (Number(days) > 100) setDays(100);
    else setDays(parseInt(parseInt(days) - 1));
  };
  const handleInHours = () => {
    if (hours === "e" || hours === "" || Number(hours) < 0) setHours(0);
    else if (Number(hours) >= 100) setHours(100);
    else setHours(hours + 1);
  };
  const handleDeHours = () => {
    if (hours === "e" || hours === "" || Number(hours) < 0) setHours(0);
    else if (Number(hours) > 100) setHours(100);
    else setHours(parseInt(parseInt(hours) - 1));
  };
  const handleInMinutes = () => {
    if (minutes === "e" || minutes === "" || Number(minutes) < 0) setMinutes(0);
    else if (Number(minutes) >= 100) setMinutes(100);
    else setMinutes(minutes + 1);
  };
  const handleDeMinutes = () => {
    if (minutes === "e" || minutes === "" || Number(minutes) < 0) setMinutes(0);
    else if (Number(minutes) > 100) setMinutes(100);
    else setMinutes(parseInt(parseInt(minutes) - 1));
  };

  function handleChangeDays(event) {
    const check = event.target.value;
    if (check === "e" || Number(check) < 0) setDays(0);
    else if (Number(check) >= 100) {
      setDays(100);
    } else {
      setDays(parseInt(event.target.value));
    }
  }
  function handleChangeHours(event) {
    const check = event.target.value;
    if (check === "e" || Number(check) < 0) setHours(0);
    else if (Number(check) >= 100) {
      setHours(100);
    } else {
      setHours(parseInt(event.target.value));
    }
  }
  function handleChangeMinutes(event) {
    const check = event.target.value;
    if (check === "e") setMinutes(0);
    setMinutes(parseInt(event.target.value));
  }

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleNext);

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
    saveDonationRequest();
  };
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  const onCloseModal = () => {
    setOpenOrgModal(false);
  };

  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
    setValues((currentValues) => {
      return {
        ...currentValues,
        cashUnit: event.target.value,
      };
    });
  };
  const [openOrgModal, setOpenOrgModal] = useState(false);

  const loadRequestData = () => {
    const newValues = {};
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          setDonationRequest(response);
          setId(response._id);
          setDefaultImageUrl(response.defaultImageUrl);
          newValues.fundsRecipient =
            response.orgId &&
            response.orgId._id &&
            response.fundsRecipient !== "self"
              ? response.orgId && response.orgId._id
              : "self";
          newValues.eventName = response.eventName;
          newValues.requirementTitle = response.title;
          newValues.eventVenue =
            response.shipping_address && response.shipping_address.line1;
          setDays(response.duration && response.duration.days);
          setHours(response.duration && response.duration.hours);
          setMinutes(response.duration && response.duration.minutes);
          newValues.websiteLink = response.websiteUrl;
          newValues.targetAudience = response.targetPartners
            ? response.targetPartners
            : "";
          newValues.expectedAudience = response.size ? response.size : "";
          newValues.speakerTopic = response.brief ? response.brief : "";
          newValues.cashUnit = response.units
            ? response.units
            : response.region === "IN"
            ? "INR"
            : "USD";
          newValues.speakerFee = response.quantity ? response.quantity : "";
          newValues.comments = response.notes ? response.notes : "";
          setName(response.name);
          setEventType(
            eventTypes.includes(response.requested_for)
              ? response.requested_for
              : "Others"
          );
          setOtherEvent(
            eventTypes.includes(response.requested_for)
              ? ""
              : response.requested_for
          );
          setEventEndDate(response.due_date);
          setEventStartDate(response.start_date);
          setDescription(
            response.description && response.description !== ""
              ? response.description
              : ""
          );
          setEventMode(
            response.preferredConsultationMode
              ? response.preferredConsultationMode
              : ""
          );
          setActiveStep(0);
          setImageUpload(true);
          ((response.orgId && response.orgId.user_info) ||
            response.fundsRecipient === "self") &&
            setOrgEdit(authUser.unique_id === response.user_info._id);
          setValues((currentValues) => {
            return {
              ...currentValues,
              ...newValues,
            };
          });
          if (response.shipping_address) {
            setTempAddress({
              line1: response.shipping_address.line1,
              line2: response.shipping_address.line2,
              city: response.shipping_address.city,
              state: response.shipping_address.state,
              zip_code: response.shipping_address.zip_code,
              country: response.shipping_address.country,
            });
            setAddress({
              line1: response.shipping_address.line1,
              line2: response.shipping_address.line2,
              city: response.shipping_address.city,
              state: response.shipping_address.state,
              zip_code: response.shipping_address.zip_code,
              country: response.shipping_address.country,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };

  useEffect(() => {
    if (props.match.params.id) {
      setEditMode(true);
      setIsLoading(true);
      loadRequestData(props.match.params.id);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit:
            donationRequest && donationRequest.units
              ? donationRequest.units
              : region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <Sidemenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px", width: "100%" }}
          >
            <div className="header" style={{ margin: "auto" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/eventparticipation.png"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "10px",
                      borderRadius: "75%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="Event Participation"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Event Speaker
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Connecting Events with Impactful Participation
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid className="informatic-layout">
              <div>
                <Stepper
                  activeStep={activeStep}
                  style={{ background: "transparent" }}
                  alternativeLabel
                >
                  {getSteps().map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div className={styles.field_length}>
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "37px",
                            color: "#000",
                            // padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Event Info
                        </Typography>
                      </Grid>
                      {(props.match.params.id ? orgEdit : true) && (
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            className={styles.blood_grp}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select/Add Organization *
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.fundsRecipient}
                              onChange={changeHandler}
                              placeholder="Organization"
                              name="fundsRecipient"
                              label="Select/Add Organization"
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-amount ${
                                  errors.fundsRecipient
                                    ? styles.bg_red
                                    : values.fundsRecipient
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                            >
                              <MenuItem value={"self"}>Self</MenuItem>
                              {orgList &&
                                orgList.length > 0 &&
                                orgList.map((org) => {
                                  return (
                                    <MenuItem
                                      value={org && org.orgId && org.orgId._id}
                                    >
                                      <img
                                        src={
                                          org &&
                                          org.orgId &&
                                          org.orgId.defaultImageUrl
                                            ? org.orgId.defaultImageUrl
                                            : "/images/orgLogo.png"
                                        }
                                        alt="orgLogo"
                                        width="20px"
                                        style={{ marginRight: 8 }}
                                      />{" "}
                                      {org && org.orgId && org.orgId.orgName}{" "}
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          textAlign: "end",
                                        }}
                                      >
                                        {org &&
                                        org.orgId &&
                                        org.orgId.status === 1 ? (
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <ErrorOutlineIcon
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </span>
                                    </MenuItem>
                                  );
                                })}
                              <MenuItem
                                value="add"
                                onClick={handleOrganization}
                              >
                                Add Organization
                              </MenuItem>
                            </Select>{" "}
                            <div className="custom-error">
                              {errors.fundsRecipient}
                            </div>
                          </FormControl>
                        </Grid>
                      )}
                      {editMode && !orgEdit && (
                        <Grid item xs={12} md={12}>
                          <InputLabel className="secFormLabel">
                            Recipient
                          </InputLabel>
                          <div className={styles.recipient_e}>
                            <Avatar
                              aria-label="recipe"
                              className={classes.avatar}
                            >
                              <Img
                                src={
                                  donationRequest &&
                                  donationRequest.orgId &&
                                  donationRequest.orgId._id
                                    ? donationRequest.orgId.defaultImageUrl
                                      ? donationRequest.orgId.defaultImageUrl
                                      : "/images/orgLogo.png"
                                    : donationRequest &&
                                      donationRequest.user_info &&
                                      donationRequest.user_info.image_url
                                    ? donationRequest.user_info.image_url
                                    : "/images/default-profile-photo1.png"
                                }
                                alt="user-profile"
                                className="user-profile-img"
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                onClick={
                                  donationRequest && donationRequest.orgId
                                    ? () =>
                                        props.history.push(
                                          `/organization/${donationRequest.orgId._id}`
                                        )
                                    : () =>
                                        props.history.push(
                                          `/${donationRequest.user_info.username}`
                                        )
                                }
                              />
                            </Avatar>

                            <TextField
                              type="text"
                              variant="outlined"
                              className="profile-fields lnames recipient-bdr "
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              name="fundsRecipient"
                              disabled
                              value={values.fundsRecipient}
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.eventName}
                        >
                          <TextField
                            label="Event Name*"
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.eventName
                                  ? styles.bg_red
                                  : values.eventName
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="eventName"
                            value={values.eventName}
                            onChange={changeHandler}
                            inputProps={{
                              maxLength: 100,
                            }}
                          />
                          <span
                            style={{
                              fontSize: "18px",
                              marginLeft: "5px",
                              position: "absolute",
                              top: "5px",
                              color: "#ccc",
                              right: "20px",
                              padding: "20px",
                              paddingRight: "30px",
                            }}
                          >
                            {" "}
                            {values.eventName ? values.eventName.length : 0}
                            /100
                          </span>
                          <Typography className="custom-error">
                            {errors.eventName}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          className={styles.blood_grp}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Event Type *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={eventType}
                            onChange={handleChangeEventType}
                            name="eventType"
                            label="Select Event Type"
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors.eventType
                                  ? styles.bg_red
                                  : eventType
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          >
                            <MenuItem value={""}>Select Event Type</MenuItem>
                            {eventTypes.map((eventType) => {
                              return (
                                <MenuItem value={eventType}>
                                  {eventType}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <Grid
                            item
                            xs={12}
                            className="blood_Event"
                            // style={{ padding: "9px 10px" }}
                          >
                            {eventType === "Others" && (
                              <TextField
                                label="Enter Event Type*"
                                variant="outlined"
                                style={{
                                  marginTop: "10px",
                                  color: "#000000",
                                  width: "100%",
                                }}
                                className={
                                  styles.plasma_input +
                                  " " +
                                  `input-field request-title  ${
                                    errors.otherEvent
                                      ? styles.bg_red
                                      : otherEvent
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                name="eventType"
                                value={otherEvent}
                                onChange={changeOtherEvent}
                              />
                            )}

                            <Typography className="custom-error">
                              {errors.otherEvent}
                            </Typography>
                          </Grid>
                          <Typography className="custom-error">
                            {errors.eventType}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                            marginBottom: "10px",
                          }}
                          error={errors && errors.requirementTitle}
                        >
                          <TextField
                            label="Title of the request*"
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.requirementTitle
                                  ? styles.bg_red
                                  : values.requirementTitle
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="requirementTitle"
                            value={values.requirementTitle}
                            onChange={changeHandler}
                            inputProps={{
                              maxLength: 70,
                            }}
                          />
                          <span
                            style={{
                              fontSize: "18px",
                              marginLeft: "5px",
                              position: "absolute",
                              top: "5px",
                              color: "#ccc",
                              right: "20px",
                              padding: "20px",
                              paddingRight: "30px",
                            }}
                          >
                            {" "}
                            {values.requirementTitle
                              ? values.requirementTitle.length
                              : 0}
                            /70
                          </span>
                          <Typography className="custom-error">
                            {errors.requirementTitle}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        spacing={2}
                        style={{ width: "100%" }}
                      >
                        {" "}
                        <Grid item xs={6}>
                          <FormControl
                            style={{
                              width: "100%",
                              // paddingRight: "10px",
                            }}
                            className="recipient"
                            error={errors && errors.eventStartDate}
                          >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                showTodayButton
                                format="dd-MMM-yyyy"
                                className={
                                  styles.plasma_input +
                                  " " +
                                  styles.date_b +
                                  `input-field dob ${
                                    errors.eventStartDate
                                      ? styles.bg_red
                                      : eventStartDate
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                variant="dialog"
                                value={eventStartDate}
                                inputVariant="outlined"
                                label="Event Start Date*"
                                placeholder="dd-MMM-yyyy"
                                onChange={(newDate) => {
                                  handleChangeEventStartDate(newDate);
                                }}
                                onClick={handleClickStartDateCalendar}
                                open={showStartDateCalendar}
                                onClose={handleCloseStartDateCalendar}
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                            <Typography className="custom-error">
                              {errors.eventStartDate}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            style={{
                              width: "103%",
                              // paddingRight: "10px",
                            }}
                            className="recipient"
                            error={errors && errors.eventEndDate}
                          >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                showTodayButton
                                format="dd-MMM-yyyy"
                                className={
                                  styles.plasma_input +
                                  " " +
                                  styles.date_b +
                                  `input-field dob ${
                                    errors.eventEndDate
                                      ? styles.bg_red
                                      : eventEndDate
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                variant="dialog"
                                value={eventEndDate}
                                inputVariant="outlined"
                                label="Event End Date*"
                                placeholder="dd-MMM-yyyy"
                                onChange={(newDate) => {
                                  handleChangeEventEndDate(newDate);
                                }}
                                onClick={handleClickEndDateCalendar}
                                open={showEndDateCalendar}
                                onClose={handleCloseEndDateCalendar}
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                            <Typography className="custom-error">
                              {errors.eventEndDate}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#000",
                              // paddingLeft: "10px",
                              paddingTop: "20px",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            Event Location{" "}
                            <span style={{ color: "red", fontSize: "1rem" }}>
                              {" "}
                              *
                            </span>
                          </Typography>
                        </Grid>

                        <ShortAddress
                          value={address}
                          onChange={handleChangeAddress}
                          error={errors && errors.address}
                        />
                        <Typography className="custom-error">
                          {errors.address}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                        >
                          <TextField
                            label="Venue Name "
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input + " " + styles.bg_normal
                            }
                            name="eventVenue"
                            value={values.eventVenue}
                            onChange={changeHandler}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ justifyContent: "space-between" }}
                      >
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            // paddingLeft: "10px",
                            paddingTop: "20px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Duration
                        </Typography>
                      </Grid>
                      <Grid container spacing={4}>
                        {" "}
                        <Grid item xs={4}>
                          <InputLabel
                            className="secFormLabel"
                            style={{ paddingLeft: "10px", paddingTop: "10px" }}
                          >
                            Days <span style={{ color: "red" }}> *</span>
                          </InputLabel>

                          <FormControl
                            error={errors && errors.days}
                            style={{ width: "100%" }}
                          >
                            <ButtonGroup style={{ marginBottom: "5px" }}>
                              <Button
                                variant="outlined"
                                onClick={handleDeDays}
                                className="btn_vist"
                              >
                                -
                              </Button>
                              <TextField
                                style={{ width: "80%" }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                type="number"
                                name="days"
                                onChange={handleChangeDays}
                                value={days}
                                onKeyPress={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "e"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              >
                                {days}
                              </TextField>
                              <Button
                                variant="outlined"
                                onClick={handleInDays}
                                className="btn_vist"
                              >
                                +
                              </Button>
                            </ButtonGroup>
                            <Typography className="custom-error">
                              {errors && errors.days}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel
                            className="secFormLabel"
                            style={{ paddingLeft: "10px", paddingTop: "10px" }}
                          >
                            Hours<span style={{ color: "red" }}> *</span>
                          </InputLabel>

                          <FormControl
                            error={errors && errors.hours}
                            style={{ width: "100%" }}
                          >
                            <ButtonGroup style={{ marginBottom: "5px" }}>
                              <Button
                                variant="outlined"
                                onClick={handleDeHours}
                                className="btn_vist"
                              >
                                -
                              </Button>
                              <TextField
                                style={{ width: "80%" }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                type="number"
                                name="hours"
                                onChange={handleChangeHours}
                                value={hours}
                                onKeyPress={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "e"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              >
                                {hours}
                              </TextField>
                              <Button
                                variant="outlined"
                                onClick={handleInHours}
                                className="btn_vist"
                              >
                                +
                              </Button>
                            </ButtonGroup>
                            <Typography className="custom-error">
                              {errors && errors.hours}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel
                            className="secFormLabel"
                            style={{ paddingLeft: "10px", paddingTop: "10px" }}
                          >
                            Minutes
                          </InputLabel>

                          <FormControl
                            error={errors && errors.minutes}
                            style={{ width: "100%" }}
                          >
                            <ButtonGroup style={{ marginBottom: "5px" }}>
                              <Button
                                variant="outlined"
                                onClick={handleDeMinutes}
                                className="btn_vist"
                              >
                                -
                              </Button>
                              <TextField
                                style={{ width: "80%" }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                type="number"
                                name="minutes"
                                onChange={handleChangeMinutes}
                                value={minutes}
                                onKeyPress={(event) => {
                                  if (
                                    event?.key === "-" ||
                                    event?.key === "+" ||
                                    event?.key === "e"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              >
                                {minutes}
                              </TextField>
                              <Button
                                variant="outlined"
                                onClick={handleInMinutes}
                                className="btn_vist"
                              >
                                +
                              </Button>
                            </ButtonGroup>
                            <Typography className="custom-error">
                              {errors && errors.minutes}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                        >
                          <TextField
                            label="Website Link"
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.websiteLink
                                  ? styles.bg_red
                                  : values.websiteLink
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="websiteLink"
                            value={values.websiteLink}
                            onChange={changeHandler}
                          />
                          <Typography className="custom-error">
                            {errors.websiteLink}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Box className="pod_buttons">
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "160px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => createEventSpeakerRequest(e)}
                          >
                            Save and continue
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "23px",
                          lineHeight: "37px",
                          color: "#000",
                          marginLeft: "10px",
                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Participation Info
                      </Typography>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#000",
                              paddingLeft: "10px",
                              paddingTop: "20px",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            {`Brief Description of the Speaker Requirement`}
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "20px",
                            }}
                            error={errors && errors.description}
                          >
                            <Grid className="ck-editor-sc">
                              <CKEditor
                                style={{ padding: "10px 20px" }}
                                maxLength={300}
                                data={description}
                                name="description"
                                editor={ClassicEditor}
                                onChange={changeDescriptionHandler}
                                config={{
                                  toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "Link",
                                  ],
                                  placeholder: "Enter Description",
                                }}
                              />
                            </Grid>
                            <Typography className="custom-error">
                              {errors.description}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginLeft: "10px" }}>
                          <Typography>Requirement Type </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{ width: "100%", paddingLeft: "10px" }}
                            error={errors && errors.eventMode}
                          >
                            <RadioGroup
                              row
                              value={eventMode}
                              onChange={handleChangeEventMode}
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="online"
                                control={<Radio />}
                                label="Virtual"
                              />
                              <FormControlLabel
                                value="offline"
                                control={<Radio />}
                                label="Physical Presence"
                              />
                            </RadioGroup>
                            <Typography className="custom-error">
                              {errors.eventMode}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingRight: "10px",
                            }}
                            error={errors && errors.targetAudience}
                          >
                            <TextField
                              label="Target Audience*"
                              type="text"
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-title  ${
                                  errors.targetAudience
                                    ? styles.bg_red
                                    : values.targetAudience
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              name="targetAudience"
                              value={values.targetAudience}
                              onChange={changeHandler}
                            />
                            <Typography className="custom-error">
                              {errors.targetAudience}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingRight: "10px",
                            }}
                            error={errors && errors.expectedAudience}
                          >
                            <TextField
                              label="Expected Audience Count*"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-title  ${
                                  errors.expectedAudience
                                    ? styles.bg_red
                                    : values.expectedAudience
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              name="expectedAudience"
                              value={values.expectedAudience}
                              onChange={changeHandler}
                            />
                            <Typography className="custom-error">
                              {errors.expectedAudience}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingRight: "10px",
                            }}
                            error={errors && errors.speakerTopic}
                          >
                            <TextField
                              label="Topic / Theme for the Speaker*"
                              type="text"
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-title  ${
                                  errors.speakerTopic
                                    ? styles.bg_red
                                    : values.speakerTopic
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              name="speakerTopic"
                              value={values.speakerTopic}
                              onChange={changeHandler}
                            />
                            <Typography className="custom-error">
                              {errors.speakerTopic}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingTop: "10px",
                              background: "#fff",
                            }}
                            error={errors && errors.speakerFee}
                            className="bgcolor-white"
                          >
                            <InputLabel>Speaker Fee</InputLabel>
                            <FilledInput
                              label="Speaker Fee"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-title  ${
                                  errors.speakerFee
                                    ? styles.bg_red
                                    : values.speakerFee
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              name="speakerFee"
                              value={values.speakerFee}
                              onChange={changeHandler}
                              startAdornment={
                                <InputAdornment position="start">
                                  {address && address.country === "India" ? (
                                    <FontAwesomeIcon
                                      color="#059585"
                                      size="2x"
                                      icon={faRupeeSign}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      color="#059585"
                                      size="2x"
                                      icon={faDollarSign}
                                    />
                                  )}
                                </InputAdornment>
                              }
                            />
                            <FormHelperText>{errors.speakerFee}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.comments}
                        >
                          <TextField
                            label="Additional Comments"
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.comments
                                  ? styles.bg_red
                                  : values.comments
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="comments"
                            value={values.comments}
                            onChange={changeHandler}
                          />
                          <Typography className="custom-error">
                            {errors.comments}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Box className="pod_buttons">
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "160px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => createEventSpeakerRequest(e)}
                          >
                            Save and continue
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "23px",
                          lineHeight: "37px",
                          color: "#000",

                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Uploads
                      </Typography>
                      <Grid item xs={12}></Grid>
                      <div className="request-step-two">
                        <div className="request-two-step">
                          <h3 className="step-two-title">
                            Add a cover photo or video
                          </h3>
                        </div>
                        <p className="image-desc">
                          This is the image that will feature right on top of
                          your page. Make it impactful!
                        </p>
                        <div className="fund-img-txt">
                          <p> A picture is worth a thousand words</p>
                          <ul>
                            <li>
                              <span>Keep it relevant</span>
                            </li>
                            <li>
                              <span>Keep it clear</span>
                            </li>
                            <li>
                              <span>Types: .PNG, .JPG, .BMP, .MP4</span>
                            </li>
                            <li>
                              <span>Size: 700px X 400px</span>
                            </li>
                          </ul>
                        </div>
                        <MuiThemeProvider
                          theme={theme2}
                          className="req-tooltip-img"
                        >
                          <img
                            src={
                              defaultImageUrl
                                ? defaultImageUrl
                                : "/images/default-req-img.gif"
                            }
                            alt="default"
                            style={{ width: "100%" }}
                          />
                        </MuiThemeProvider>
                        {errors && errors.image && (
                          <Typography className="custom-error">
                            {errors.image}
                          </Typography>
                        )}
                        <div className={styles.add_btn_fund}>
                          <Button onClick={() => setShowingAddPhotoModal(true)}>
                            <FontAwesomeIcon
                              color="#283E4A"
                              size="1x"
                              style={{ marginRight: 6 }}
                              icon={faFileUpload}
                            />{" "}
                            Add Images
                          </Button>
                          <Button
                            className="document-btn"
                            onClick={() => setShowingAddDocumentModal(true)}
                          >
                            <DescriptionIcon
                              style={{ fontSize: 14, marginRight: 6 }}
                            />{" "}
                            Upload supporting document(s)
                          </Button>
                        </div>
                      </div>

                      <Box display="flex" justifyContent="flex-end">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={createEventSpeakerRequest}
                          >
                            {editMode ? "Update" : "Submit"}
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
        multiple={true}
      />
      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={id}
        collection="DonationRequest"
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={onCloseModal}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(EventParticipation);

import React, { useContext, useState, useEffect } from "react";
import toastr from "toastr";
import firebase from "../../firebase/firebase";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Avatar from "@material-ui/core/Avatar";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";
import Img from "react-cool-img";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import Payments from "../donationDetails/PaymentsPopUp";
import KindTimeDonation from "../donationDetails/KindTimeDonation";
import LocationOn from "@material-ui/icons/LocationOn";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRupeeSign,
  faDollarSign,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import CloseIcon from "@material-ui/icons/Close";
import { appContext } from "../../App";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  favourite,
  likeOrUnlike,
  updateDonationRequest,
  CareerHelpShareDetails,
  getUserData,
  updateDonationRequestAnalytics,
} from "../../utils/api";
import "../FBLayout/home2.scss";
import Comments from "./Comments";
import {
  formatNumber,
  DATE_FORMAT,
  DONATION_REQUEST_STATUS_MAP,
  getDonationRequestSharedUrl,
  getShortUrl,
  convertFirstCharacterToUppercase,
  STATUS_APPROVED,
  STATUS_EXPIRED,
  REQUEST_TYPES,
} from "../../utils/utils";
import DonationRequestNotLoggedInDialog from "./DonationRequestNotLoggedInDialog";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";
import ShareOnSocialMedia from "../share/ShareOnSocialMedia";
import { CommentOutlined } from "@material-ui/icons";
import Forum from "../ForumConversations/Forum";
import ApplyPost from "../ApplyPost/ApplyPost";
import cssstyles from "./DonationRequestCard.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
  },
  paper: {
    // padding: theme.spacing(2),
    margin: "10px 0",
    textAlign: "center",
    color: theme.palette.text.secondary,
    background: "#f5f5f5",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: "10px",
    borderRadius: "5px",
    width: "450px",
    [theme.breakpoints.down("xs")]: {
      width: "280px",
    },
  },
}));

const DonationRequestCard = (props) => {
  const { donationRequest, donationRequests, setDonationRequests } = props;
  const classes = useStyles();
  const { authUser, setAuthUser, setCurrentDonationRequestName, avatar } =
    useContext(appContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false);
  const [shareIcons, setShareIcons] = useState(false);
  const [isKindTimeDonationOpen, setIsKindTimeDonationOpen] = useState(false);
  const [donatedQuantity, setDonatedQuantity] = useState(0);
  const [donatedperc, setDonatedperc] = useState(0);
  const [, setInAppr] = useState("");
  const [showFavorited, setShowFavorited] = useState(
    donationRequest && donationRequest.isFavourite
      ? donationRequest && donationRequest.isFavourite
      : donationRequest &&
          donationRequest.favourites.includes(authUser && authUser.unique_id)
  );
  const [showLiked, setShowLiked] = useState(
    donationRequest && donationRequest.isLike == true
      ? true
      : donationRequest &&
        donationRequest.likes &&
        donationRequest.likes.length > 0
      ? donationRequest.likes.includes(authUser && authUser.unique_id)
      : false
  );
  const openPostMenu = Boolean(anchorEl);
  const ITEM_HEIGHT = 260;
  const [isCommentsDialogOpen, setIsCommentsDialogOpen] = useState(false);
  const [isForumDialogOpen, setIsForumDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [careerHelpModalOpen, setCareerHelpModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentsCount, setCommentsCount] = useState("");
  const [openForum, setOpenForum] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [openIntern, setOpenIntern] = useState(false);
  const [shortUrl, setShortUrl] = useState("");
  const [buttonClicks, setButtonClicks] = useState(0);
  const [sharesCount, setSharesCount] = useState(0);

  const handleClose = (isCarrerModal = false) => {
    if (isCarrerModal) {
      return setCareerHelpModalOpen(false);
    }
    setOpen(false);
  };

  const handlePostMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePostMenuClose = () => {
    setAnchorEl(null);
  };

  const donationRequestClickHandler = (name) => {
    setCurrentDonationRequestName(name);
    window.open(`/donationRequest/${name}`, "blank");
  };

  const handleInappropriate = (inAppr) => {
    setInAppr(inAppr);
    const reportValues = {};
    if (authUser && authUser.unique_id) {
      reportValues.user_id = authUser.unique_id;
    }
    reportValues.status = -3;
    reportValues.flagReason = inAppr;
    updateDonationRequest(reportValues, donationRequest._id)
      .then(() => {
        props.removeFromList();
        toastr.success(
          "Thanks for letting us know. Your feedback is important in helping us keep the TALGiving community safe."
        );
      })
      .catch(() => {});
    setOpen(false);
  };

  const handleReport = (report) => {
    const reportValues = {};
    if (report === "spam") {
      if (
        window.confirm(
          "Are you sure you want to report this fundraiser as Spam?"
        )
      ) {
        if (authUser && authUser.unique_id) {
          reportValues.user_id = authUser.unique_id;
        }
        reportValues.status = -3;
        reportValues.flagReason = "spam";
        updateDonationRequest(reportValues, donationRequest._id)
          .then(() => {
            props.removeFromList();
            toastr.success(
              "Thanks for letting us know. Your feedback is important in helping us keep the TALGiving community safe."
            );
          })
          .catch(() => {});
        setAnchorEl(null);
      }
    } else {
      setOpen(true);
      setAnchorEl(null);
    }
  };

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDonateNow = async (donationRequest) => {
    updateDonationRequestAnalytics(
      donationRequest && donationRequest._id,
      "clickbutton"
    )
      .then((response) => {
        setButtonClicks(response.data.noOfButtonClicks);
        // Updating clicks count;
      })
      .catch((error) => {
        toastr.error(error);
      });
    if (authUser && authUser.unique_id) {
      if (donationRequest && donationRequest.request_type === "cash") {
        setIsPaymentsOpen(true);
      } else if (
        donationRequest &&
        (donationRequest.request_type === "plasma" ||
          donationRequest.request_type === "help")
      ) {
        props.history.push(`/donationRequest/${donationRequest.name}`);
      } else if (donationRequest && donationRequest.request_type === "career") {
        getUserData(authUser.unique_id)
          .then((response) => {
            const localAuthUser = authUser;
            localAuthUser.isMentor = response.isMentor;
            localStorage.setItem("authUser", JSON.stringify(localAuthUser));
            setAuthUser(localAuthUser);
            if (
              localAuthUser.isMentor &&
              donationRequest.creatorType === "mentee"
            ) {
              setCareerHelpModalOpen(true);
              let Obj = {
                mentorName:
                  authUser.name.first_name + " " + authUser.name.last_name,
                mentorEmail: authUser.email,
                menteeName:
                  donationRequest.user_info.name.first_name +
                  " " +
                  donationRequest.user_info.name.last_name,
                menteeEmail: donationRequest.user_info.email,
                menteePhone: donationRequest.user_info.phone,
              };
              setCareerHelpModalOpen(true);
              CareerHelpShareDetails(Obj)
                .then(() => {
                  setIsLoading(true);
                })
                .catch(() => {
                  setIsLoading(true);
                });
            } else {
              props.history.push("/careerhelp-request");
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else if (
        donationRequest &&
        (donationRequest.request_type === "internship" ||
          donationRequest.request_type === "homework help" ||
          donationRequest.request_type === "career" ||
          donationRequest.request_type === "volunteering" ||
          donationRequest.request_type === "board member" ||
          donationRequest.request_type === "podcast" ||
          donationRequest.request_type === "eventSpeaker" ||
          donationRequest.request_type === "mentoring")
      ) {
        setOpenIntern(true);
      } else {
        setIsKindTimeDonationOpen(true);
      }
    } else {
      if (donationRequest && donationRequest.request_type === "cash") {
        setOpenOptions(true);
      } else {
        localStorage.setItem(
          "redirectUri",
          `/donationRequest/${donationRequest._id}`
        );
        props.history.push("/login");
      }
    }
  };

  const saveDonationHandler = (donatedQuantity) => {
    setIsKindTimeDonationOpen(false);
    setIsPaymentsOpen(false);
    setDonatedQuantity((currentValue) => {
      return currentValue + Number(donatedQuantity);
    });
    toastr.success("Thank you for making a contribution.");
  };

  useEffect(() => {
    if (donationRequest) {
      setDonatedQuantity(donationRequest.donated_quantity);
    }
    let donatedPercentage = 0;
    (donationRequest.donated_quantity * 100) / donationRequest.quantity >= 100
      ? (donatedPercentage = 100)
      : (donatedPercentage = Math.round(
          (donationRequest.donated_quantity * 100) / donationRequest.quantity
        ));
    setDonatedperc(donatedPercentage);

    //get comments count
    const ref = firebase.realtimeDb.ref(
      "/conversations/" + donationRequest?._id
    );

    ref.on("value", (snapshot) => {
      const comments = snapshot.val();
      const commentsArray = comments ? comments : [];
      setCommentsCount(
        commentsArray ? commentsArray && Object.keys(commentsArray).length : "0"
      );
    });
  }, [donationRequest]);

  const BorderLinearProgress = withStyles({
    root: {
      height: 8,
      backgroundColor: lighten("#aaa", 0.2),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: "#5BCA7A",
    },
  })(LinearProgress);

  const handleLikeDonation = () => {
    setShowLiked((currentValue) => {
      return !currentValue;
    });
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.requestId = donationRequest["_id"];
      obj.userId = authUser.unique_id;
      if (showLiked === false) {
        if (donationRequest.likesCount >= 0) {
          donationRequest.likesCount = donationRequest.likesCount + 1;
        } else {
          donationRequest.likeCount = donationRequest.likeCount + 1;
        }
        obj.type = "like"; // like or unlike
      } else if (showLiked === true) {
        if (donationRequest.likesCount) {
          donationRequest.likesCount = donationRequest.likesCount - 1;
        } else {
          donationRequest.likeCount = donationRequest.likeCount - 1;
        }
        obj.type = "unlike"; // like or unlike
      }
      likeOrUnlike(obj);
    } else {
      toastr.warning("Please signup or login to complete this action");
      props.history.push("/login");
      setShowLiked(donationRequest && donationRequest.isLike);
    }
  };
  const makeFavourite = () => {
    setShowFavorited((currentValue) => {
      return !currentValue;
    });
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.requestId = donationRequest["_id"];
      obj.userId = authUser.unique_id;
      if (showFavorited === false) {
        obj.type = "favourite"; // favourite or unfavourite
      } else if (showFavorited === true) {
        obj.type = "unfavourite";
      }
      favourite(obj);
    } else {
      toastr.warning("Please signup or login to complete this action");
      props.history.push("/login");
      setShowFavorited(donationRequest && donationRequest.isFavourite);
    }
  };

  const handleUpdate = () => {
    props.history.push(`/donationRequest/${donationRequest._id}`, {
      isUpdate: true,
    });
  };
  const handleWithdrawFunds = () => {
    props.history.push(`/withdrawForm/${donationRequest._id}`);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const handleClickOpenShare = () => {
    // Get shortUrl from the API for sharing the donation request
    getShortUrl(
      getDonationRequestSharedUrl(donationRequest),
      "donationRequest",
      donationRequest._id
    )
      .then((shortUrl) => {
        setShortUrl(shortUrl);
        setOpenShare(true);
      })
      .catch((errorMessage) => {
        toastr.error(errorMessage);
      });
    updateDonationRequestAnalytics(donationRequest._id, "share")
      .then((response) => {
        setSharesCount(response.data.shareCount);
      })
      .catch((error) => {
        toastr.error(error);
      });
  };

  const handleAudioRooms = () => {
    props.history.push(`/donationRequest/${donationRequest._id}`, {
      isAudioRoom: true,
    });
  };

  const handleCloseShare = () => {
    setOpenShare(false);
  };
  return (
    <section
      className=" postCardPaper"
      elevation={0}
      style={{ position: "relative" }}
    >
      <div className="postCard drop-box">
        <Card className={classes.root}>
          <div className="box-model">
            <div
              className={
                donationRequest &&
                donationRequest.status &&
                donationRequest.status !== null &&
                DONATION_REQUEST_STATUS_MAP[donationRequest.status]
                  .ribbonBgColor
              }
            ></div>
            <div
              className={
                donationRequest &&
                donationRequest.status &&
                donationRequest.status !== null &&
                DONATION_REQUEST_STATUS_MAP[donationRequest.status]
                  .ribbonTextColor
              }
            >
              {donationRequest &&
                donationRequest.status &&
                donationRequest.status !== null &&
                DONATION_REQUEST_STATUS_MAP[donationRequest.status].shortLabel}
            </div>
          </div>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <Img
                  src={
                    donationRequest &&
                    donationRequest.orgId &&
                    donationRequest.orgId._id
                      ? donationRequest.orgId.defaultImageUrl
                        ? donationRequest.orgId.defaultImageUrl
                        : "/images/orgLogo.png"
                      : donationRequest &&
                        donationRequest.user_info &&
                        donationRequest.user_info.image_url
                      ? donationRequest.user_info.image_url
                      : "/images/default-profile-photo1.png"
                  }
                  alt="user-profile"
                  className="user-profile-img"
                  style={{ cursor: "pointer" }}
                  onClick={
                    donationRequest &&
                    donationRequest.orgId &&
                    donationRequest.fundsRecipient !== "self"
                      ? () =>
                          window.open(
                            `/organization/${donationRequest.orgId._id}`
                          )
                      : () =>
                          window.open(`/${donationRequest.user_info.username}`)
                  }
                />
                alt="user-profile" width="100%"
              </Avatar>
            }
            title={
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: " 14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                    onClick={
                      donationRequest &&
                      donationRequest.orgId &&
                      donationRequest.fundsRecipient !== "self"
                        ? () =>
                            window.open(
                              `/organization/${donationRequest.orgId._id}`
                            )
                        : () =>
                            window.open(
                              `/${donationRequest.user_info.username}`
                            )
                    }
                  >
                    {donationRequest &&
                    donationRequest.orgId &&
                    donationRequest.orgId.orgName
                      ? donationRequest.orgId.orgName
                      : donationRequest &&
                        donationRequest.user_info &&
                        donationRequest.user_info.name &&
                        donationRequest.user_info.name.first_name +
                          " " +
                          donationRequest.user_info.name.last_name}
                  </span>
                  {donationRequest &&
                    donationRequest.orgId &&
                    donationRequest.orgId.status === 1 && (
                      <span>
                        &nbsp;&nbsp;
                        <VerifiedUser
                          style={{ color: "green", fontSize: 14 }}
                        />
                      </span>
                    )}

                  {donationRequest && !donationRequest.orgId && (
                    <span>
                      &nbsp;&nbsp;
                      <VerifiedUser style={{ color: "green", fontSize: 14 }} />
                    </span>
                  )}
                </div>

                <div style={{ whiteSpace: "nowrap" }}>
                  <span
                    style={{
                      width: "5%",
                      // float: "right",
                    }}
                  >
                    {showFavorited === false ? (
                      <FavoriteBorderIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => makeFavourite()}
                      />
                    ) : (
                      <FavoriteIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => makeFavourite()}
                      />
                    )}
                  </span>

                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handlePostMenuClick}
                    style={{ padding: "0" }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    style={{ zIndex: "1" }}
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={openPostMenu}
                    onClose={handlePostMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    <MenuItem
                      style={{ fontSize: 12 }}
                      onClick={() => handleReport("spam")}
                    >
                      Report as Spam
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 12 }}
                      onClick={() => handleReport("inappropriate")}
                    >
                      Report as Inappropriate
                    </MenuItem>
                  </Menu>
                </div>
              </span>
            }
            subheader={
              <div style={{ color: "#aaa", font: "400 12px Roboto" }}>
                <span>
                  {donationRequest &&
                    moment(donationRequest.createdAt).format(DATE_FORMAT)}
                </span>
                <span> | </span>
                <span>
                  {donationRequest &&
                    donationRequest.createdAt &&
                    moment(donationRequest.createdAt)
                      .startOf("seconds")
                      .fromNow()}
                </span>
              </div>
            }
            className="postBy"
          />
          {(donationRequest.request_type === "board member" ||
            donationRequest.request_type === "podcast" ||
            donationRequest.request_type === "eventSpeaker" ||
            donationRequest.request_type === "mentoring") && (
            <div className={classes.volun}>
              <Typography>
                <span className="req-name">
                  <Chip
                    style={{
                      backgroundColor: "#f35a57",
                      color: "white",
                      height: "25px",
                      margin: "10px 15px",
                    }}
                    label={REQUEST_TYPES.map((requestType) => {
                      if (requestType.key === donationRequest.request_type) {
                        return requestType.value;
                      }
                    })}
                  />
                </span>
                {/* <span className="req-name">
                  <Chip
                    style={{
                      backgroundColor: "light grey",
                      height: "25px",
                      margin: "10px 5px",
                    }}
                    label={donationRequest.creatorType}
                  />
                </span> */}
              </Typography>
            </div>
          )}
          {donationRequest && donationRequest.title && (
            <Typography
              style={{
                cursor: "pointer",
                marginBottom: "20px",
                marginLeft: "15px",
                fontWeight: "bold",
              }}
              onClick={() => donationRequestClickHandler(donationRequest.name)}
            >
              <div className="postTitle-text">
                {donationRequest && donationRequest.title}
              </div>
            </Typography>
          )}
          {donationRequest &&
            donationRequest.request_type !== "eventSpeaker" &&
            donationRequest.brief && (
              <Typography
                style={{
                  cursor: "pointer",
                  marginBottom: "20px",
                  marginLeft: "15px",
                }}
                onClick={() =>
                  donationRequestClickHandler(donationRequest.name)
                }
              >
                <div className="postTitle-text">
                  {donationRequest && donationRequest.brief
                    ? donationRequest.brief
                    : ""}
                </div>
              </Typography>
            )}
          {donationRequest.request_type === "internship" && (
            <div className="du-app">
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  DURATION
                </Typography>
                {donationRequest &&
                  donationRequest.additionalInfo &&
                  donationRequest.additionalInfo.duration && (
                    <div style={{ textTransform: "none" }}>
                      {donationRequest.additionalInfo.duration + " days"}
                    </div>
                  )}
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  APPLY BY
                </Typography>
                {donationRequest && donationRequest.due_date && (
                  <div style={{ textTransform: "none" }}>
                    {moment(donationRequest.due_date).format(DATE_FORMAT)}
                  </div>
                )}
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  MONTHLY STIPEND
                </Typography>
                {donationRequest &&
                  donationRequest.additionalInfo &&
                  donationRequest.additionalInfo.monthlyStipend && (
                    <div style={{ textTransform: "none" }}>
                      {donationRequest.additionalInfo.monthlyStipendUnits &&
                      donationRequest.additionalInfo.monthlyStipendUnits.toUpperCase() ===
                        "INR" ? (
                        <FontAwesomeIcon
                          color="#000"
                          size="1x"
                          icon={faRupeeSign}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color="#000"
                          size="1x"
                          icon={faDollarSign}
                        />
                      )}{" "}
                      {donationRequest.additionalInfo.monthlyStipend
                        ? donationRequest.additionalInfo.monthlyStipend
                        : "No Stipend"}
                    </div>
                  )}
              </div>
            </div>
          )}
          {donationRequest.request_type === "eventSpeaker" && (
            <div className={cssstyles.du_app}>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  Event Name
                </Typography>
                {donationRequest && donationRequest.eventName && (
                  <div style={{ textTransform: "none" }}>
                    {donationRequest.eventName}
                  </div>
                )}
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  Event Type
                </Typography>
                {donationRequest && donationRequest.requested_for && (
                  <div style={{ textTransform: "none" }}>
                    {donationRequest.requested_for}
                  </div>
                )}
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  Event Date
                </Typography>
                {donationRequest && donationRequest.start_date && (
                  <div style={{ textTransform: "none" }}>
                    {moment(donationRequest.start_date).format(DATE_FORMAT)}
                  </div>
                )}
              </div>
            </div>
          )}
          {donationRequest.request_type === "mentoring" && (
            <div className="du-app">
              {donationRequest && donationRequest.preferredLanguage && (
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className="postDescs"
                  >
                    Preferred Language
                  </Typography>
                  <div style={{ textTransform: "none" }}>
                    {convertFirstCharacterToUppercase(
                      donationRequest.preferredLanguage
                    )}
                  </div>
                </div>
              )}
              {donationRequest && donationRequest.requested_for && (
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className="postDescs"
                  >
                    Preferred Industry
                  </Typography>

                  <div style={{ textTransform: "none" }}>
                    {donationRequest.requested_for}
                  </div>
                </div>
              )}
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDescs"
                >
                  Mentoring Format
                </Typography>
                <div>
                  <Chip label="Virtual"></Chip>
                </div>
              </div>
            </div>
          )}
          {donationRequest.request_type === "volunteering" && (
            <div className="du-app">
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  DURATION
                </Typography>
                {donationRequest &&
                  donationRequest.additionalInfo &&
                  donationRequest.additionalInfo.duration && (
                    <div style={{ textTransform: "none" }}>
                      {donationRequest.additionalInfo.duration} days
                    </div>
                  )}
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  APPLY BY
                </Typography>
                {donationRequest && donationRequest.due_date && (
                  <div style={{ textTransform: "none" }}>
                    {moment(donationRequest.due_date).format(DATE_FORMAT)}
                  </div>
                )}
              </div>
            </div>
          )}
          {donationRequest &&
            donationRequest.request_type &&
            donationRequest.request_type === "podcast" && (
              <div className="du-app">
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className="postDesc"
                  >
                    Podcast Date
                  </Typography>
                  {donationRequest &&
                    donationRequest.additionalInfo &&
                    donationRequest.additionalInfo.podcastDate && (
                      <div style={{ textTransform: "none" }}>
                        {moment(
                          donationRequest.additionalInfo.podcastDate
                        ).format(DATE_FORMAT)}
                      </div>
                    )}
                </div>
                <div>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className="postDesc"
                  >
                    Host Name
                  </Typography>
                  {donationRequest &&
                    donationRequest.additionalInfo &&
                    donationRequest.additionalInfo.hostName && (
                      <div style={{ textTransform: "none" }}>
                        {donationRequest.additionalInfo.hostName}
                      </div>
                    )}
                </div>
                {donationRequest &&
                donationRequest.shipping_address &&
                donationRequest.shipping_address.city !== null ? (
                  <div>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className="postDesc"
                    >
                      Location
                    </Typography>
                    {donationRequest && donationRequest.shipping_address && (
                      <div style={{ textTransform: "none" }}>
                        {donationRequest.shipping_address.city}
                        {","}
                        {donationRequest.shipping_address.state}
                        {","}
                        {donationRequest.shipping_address.country}
                      </div>
                    )}
                  </div>
                ) : (
                  " "
                )}
              </div>
            )}
          {donationRequest.request_type === "homework help" && (
            <div className="du-app">
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  OCCUPATION
                </Typography>
                {donationRequest && donationRequest.additionalInfo && (
                  <div style={{ textTransform: "none" }}>
                    {donationRequest.additionalInfo.occupation}
                  </div>
                )}
              </div>
              <div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="postDesc"
                >
                  APPLY BY
                </Typography>
                {donationRequest && donationRequest.due_date && (
                  <div style={{ textTransform: "none" }}>
                    {moment(donationRequest.due_date).format(DATE_FORMAT)}
                  </div>
                )}
              </div>
            </div>
          )}
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className="postDesc-full"
            style={{ cursor: "pointer" }}
            onClick={() => donationRequestClickHandler(donationRequest.name)}
          >
            {donationRequest &&
              donationRequest.request_type &&
              (donationRequest.request_type === "internship" ||
                donationRequest.request_type === "volunteering" ||
                donationRequest.request_type === "homework help") &&
              ReactHtmlParser(donationRequest.description)}
          </Typography>
          {(donationRequest.request_type === "board member" ||
            donationRequest.request_type === "podcast" ||
            donationRequest.request_type === "eventSpeaker" ||
            donationRequest.request_type === "mentoring") &&
          !donationRequest.defaultImageUrl ? (
            ""
          ) : (
            <CardMedia
              className={classes.media + " postImg"}
              style={{ cursor: "pointer" }}
              onClick={() => donationRequestClickHandler(donationRequest.name)}
            >
              <figure>
                <img
                  src={
                    donationRequest && donationRequest.defaultImageUrl
                      ? donationRequest.defaultImageUrl
                      : "/images/default-donation-request.jpg"
                  }
                  alt="donationrequest"
                />
              </figure>
            </CardMedia>
          )}
          <CardContent className="post-card-content">
            <Typography>
              {donationRequest && donationRequest.categoryId && (
                <span className="req-name">
                  <Chip
                    label={donationRequest.categoryId.name}
                    onClick={handleClick}
                  />
                </span>
              )}
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              gutterBottom
              className="remaining-amt"
            >
              {donationRequest &&
              (donationRequest.request_type === "plasma" ||
                donationRequest.request_type === "help") ? (
                <b className="blood-space">
                  {donationRequest.request_type === "plasma"
                    ? "Blood Group:"
                    : "Covid Help:"}
                  <span
                    style={{
                      // textTransform: "uppercase",
                      marginLeft: 3,
                      color: "rgb(205, 38, 39)",
                      display: "inline-block",
                    }}
                  >
                    {donationRequest && donationRequest.creatorType === "donee"
                      ? donationRequest.requested_for
                      : donationRequest.requested_for}
                  </span>
                  {donationRequest.status === 2 ? (
                    donationRequest &&
                    donationRequest.creatorType === "donor" ? (
                      donationRequest.request_type === "plasma" ? (
                        <span>The Donor has completed Plasma donation</span>
                      ) : (
                        <span>The Donor has completed Covid Help</span>
                      )
                    ) : donationRequest.request_type === "plasma" ? (
                      <span>The Donee has received Plasma donation</span>
                    ) : (
                      <span>The Donee has received Covid Help</span>
                    )
                  ) : (
                    <span>
                      {donationRequest.additionalInfo &&
                      donationRequest.additionalInfo.contactByPhone === true ? (
                        <p style={{ margin: "2px 0", fontWeight: "400" }}>
                          {" "}
                          <PhoneIcon />{" "}
                          {donationRequest.user_info &&
                            donationRequest.user_info.phone}
                        </p>
                      ) : (
                        ""
                      )}
                      {donationRequest.additionalInfo &&
                      donationRequest.additionalInfo.contactByEmail === true ? (
                        <p
                          style={{
                            margin: "2px 0",
                            fontWeight: "400",
                            textTransform: "lowercase",
                          }}
                        >
                          <MailOutlineIcon />{" "}
                          {donationRequest.user_info &&
                            donationRequest.user_info.email}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  )}
                </b>
              ) : (
                <b>
                  {donationRequest &&
                    donationRequest.request_type === "board member" && (
                      <>
                        <span>
                          {donationRequest &&
                            donationRequest.additionalInfo &&
                            donationRequest.additionalInfo.languages && (
                              <Typography
                                className="postDesc"
                                style={{ margin: "2px 0", fontWeight: "400" }}
                              >
                                <strong> Languages :</strong>

                                {Array.isArray(
                                  donationRequest.additionalInfo.languages
                                )
                                  ? donationRequest.additionalInfo.languages.join(
                                      ","
                                    )
                                  : donationRequest.additionalInfo.languages}
                              </Typography>
                            )}
                        </span>
                        <span>
                          {donationRequest &&
                            donationRequest.additionalInfo &&
                            donationRequest.additionalInfo
                              .functionalExpertise && (
                              <Typography
                                className="postDesc"
                                style={{ margin: "2px 0", fontWeight: "400" }}
                              >
                                <strong>Functional Expertise : </strong>

                                {donationRequest &&
                                  donationRequest.additionalInfo &&
                                  donationRequest.additionalInfo
                                    .functionalExpertise &&
                                  donationRequest.additionalInfo.functionalExpertise.join(
                                    ","
                                  )}
                              </Typography>
                            )}
                        </span>
                        <span>
                          {donationRequest &&
                            donationRequest.shipping_address && (
                              <Typography
                                className="postDesc"
                                style={{ margin: "2px 0", fontWeight: "400" }}
                              >
                                <strong>Location : </strong>

                                {donationRequest &&
                                  donationRequest.shipping_address && (
                                    <>
                                      {donationRequest.shipping_address.city}
                                      {","}
                                      {donationRequest.shipping_address.state}
                                      {","}
                                      {donationRequest.shipping_address.country}
                                    </>
                                  )}
                              </Typography>
                            )}
                        </span>
                      </>
                    )}
                  {donationRequest &&
                  (donationRequest.request_type === "plasma" ||
                    donationRequest.request_type === "career" ||
                    donationRequest.request_type === "internship" ||
                    donationRequest.request_type === "volunteering" ||
                    donationRequest.request_type === "homework help" ||
                    donationRequest.request_type === "help" ||
                    donationRequest.request_type === "board member" ||
                    donationRequest.request_type === "podcast" ||
                    donationRequest.request_type === "eventSpeaker" ||
                    donationRequest.request_type === "mentoring") ? (
                    ""
                  ) : donationRequest.units &&
                    donationRequest.units.toUpperCase() === "INR" ? (
                    <FontAwesomeIcon
                      color="#000"
                      size="1x"
                      icon={faRupeeSign}
                    />
                  ) : (
                    <FontAwesomeIcon
                      color="#000"
                      size="1x"
                      icon={faDollarSign}
                    />
                  )}
                  {(donationRequest &&
                    donationRequest.request_type === "plasma") ||
                  donationRequest.request_type === "career" ||
                  donationRequest.request_type === "internship" ||
                  donationRequest.request_type === "volunteering" ||
                  donationRequest.request_type === "homework help" ||
                  donationRequest.request_type === "help" ||
                  donationRequest.request_type === "board member" ||
                  donationRequest.request_type === "podcast" ||
                  donationRequest.request_type === "eventSpeaker" ||
                  donationRequest.request_type === "mentoring"
                    ? ""
                    : `${formatNumber(donatedQuantity)}  raised of `}
                  {(donationRequest &&
                    donationRequest.request_type === "plasma") ||
                  donationRequest.request_type === "career" ||
                  donationRequest.request_type === "internship" ||
                  donationRequest.request_type === "volunteering" ||
                  donationRequest.request_type === "homework help" ||
                  donationRequest.request_type === "help" ||
                  donationRequest.request_type === "board member" ||
                  donationRequest.request_type === "podcast" ||
                  donationRequest.request_type === "eventSpeaker" ||
                  donationRequest.request_type === "mentoring" ? (
                    ""
                  ) : donationRequest &&
                    donationRequest.units &&
                    donationRequest.units.toUpperCase() === "INR" ? (
                    <FontAwesomeIcon
                      color="#000"
                      size="1x"
                      icon={faRupeeSign}
                    />
                  ) : (
                    <FontAwesomeIcon
                      color="#000"
                      size="1x"
                      icon={faDollarSign}
                    />
                  )}{" "}
                  {donationRequest.request_type === "board member" ||
                  donationRequest.request_type === "podcast" ||
                  donationRequest.request_type === "plasma" ||
                  donationRequest.request_type === "career" ||
                  donationRequest.request_type === "internship" ||
                  donationRequest.request_type === "volunteering" ||
                  donationRequest.request_type === "homework help" ||
                  donationRequest.request_type === "help" ||
                  donationRequest.request_type === "eventSpeaker" ||
                  donationRequest.request_type === "mentoring"
                    ? ""
                    : donationRequest &&
                      formatNumber(donationRequest.quantity)}{" "}
                </b>
              )}
              {/* {donationRequest && donationRequest.request_type === "kind"
                ? donationRequest.requested_for
                : ""}{" "} */}
              {(donationRequest && donationRequest.request_type === "plasma") ||
              donationRequest.request_type === "career" ||
              donationRequest.request_type === "internship" ||
              donationRequest.request_type === "volunteering" ||
              donationRequest.request_type === "homework help" ||
              donationRequest.request_type === "help" ||
              donationRequest.request_type === "board member" ||
              donationRequest.request_type === "podcast" ||
              donationRequest.request_type === "eventSpeaker" ||
              donationRequest.request_type === "mentoring" ? (
                ""
              ) : (
                <span style={{ float: "right" }}>{donatedperc}%</span>
              )}
            </Typography>
            {donationRequest &&
            (donationRequest.request_type === "plasma" ||
              donationRequest.request_type === "career" ||
              donationRequest.request_type === "internship" ||
              donationRequest.request_type === "volunteering" ||
              donationRequest.request_type === "homework help" ||
              donationRequest.request_type === "help" ||
              donationRequest.request_type === "board member" ||
              donationRequest.request_type === "podcast" ||
              donationRequest.request_type === "eventSpeaker" ||
              donationRequest.request_type === "mentoring") ? (
              ""
            ) : (
              <BorderLinearProgress
                className="prgBar"
                variant="determinate"
                color="secondary"
                value={
                  donationRequest &&
                  donationRequest.quantity &&
                  donationRequest.donated_quantity &&
                  (donationRequest.donated_quantity * 100) /
                    donationRequest.quantity >
                    100
                    ? 100
                    : (donationRequest.donated_quantity * 100) /
                      donationRequest.quantity
                }
                style={{ cursor: "pointer" }}
                onClick={() =>
                  donationRequestClickHandler(donationRequest.name)
                }
              />
            )}
            <Typography
              component="p"
              variant="subtitle1"
              gutterBottom
              className="postSubTitle"
            >
              {donationRequest &&
                donationRequest.shipping_address &&
                donationRequest.shipping_address.city && (
                  <span>
                    <LocationOn style={{ fontSize: 14, color: "green" }} />
                    {donationRequest.shipping_address.city}
                  </span>
                )}
              {donationRequest &&
                donationRequest.due_date &&
                moment(donationRequest.due_date).diff(moment().toDate()) >
                  0 && (
                  <span style={{ float: "right", textTransform: "none" }}>
                    {moment(donationRequest.due_date).diff(
                      moment().toDate(),
                      "days"
                    )}{" "}
                    days to go
                  </span>
                )}
            </Typography>

            <div className="like-app">
              <Typography
                className="w48"
                style={{
                  color: "#aaa",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  flexGrow: "1",
                }}
              >
                <FontAwesomeIcon
                  color="#1180B8"
                  size="1x"
                  icon={faThumbsUp}
                  style={{ marginRight: "5px" }}
                />{" "}
                {donationRequest && donationRequest.likesCount > 0
                  ? donationRequest.likesCount
                  : donationRequest.likeCount > 0
                  ? donationRequest.likeCount
                  : 0}{" "}
                <span style={{ display: "inline-block", width: 20 }} />{" "}
                <span onClick={() => setIsForumDialogOpen(!isForumDialogOpen)}>
                  {commentsCount} {commentsCount > 1 ? "Comments" : "Comment"}
                </span>
              </Typography>

              <div className="app-btns">
                <Tooltip title="Audio Rooms">
                  <Button onClick={handleAudioRooms} className="audio-room-btn">
                    <RecordVoiceOverOutlinedIcon /> &nbsp; Audio Rooms
                  </Button>
                </Tooltip>

                {donationRequest &&
                  (!authUser ||
                    (authUser &&
                      authUser.unique_id !== donationRequest &&
                      donationRequest.user_info &&
                      donationRequest.user_info._id)) && (
                    <div
                      className="post-action-right"
                      style={{
                        cursor:
                          donationRequest &&
                          (donatedQuantity * 100) / donationRequest.quantity >=
                            100
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      {donationRequest?.creatorType !== "mentor" && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDonateNow(donationRequest)}
                          disableElevation
                          className="donateNowBtn"
                          style={{
                            opacity:
                              donationRequest.status ===
                                Number(STATUS_EXPIRED) ||
                              ((donationRequest.request_type === "podcast" ||
                                donationRequest.request_type ===
                                  "board member" ||
                                donationRequest.request_type ===
                                  "eventSpeaker" ||
                                donationRequest.request_type === "mentoring") &&
                                (window.location.pathname === "/myDashboard" ||
                                  donationRequest.isScholarshipApplied))
                                ? "0.5"
                                : 1,
                          }}
                          disabled={
                            (donationRequest &&
                              donationRequest.status ===
                                Number(STATUS_EXPIRED)) ||
                            ((donationRequest.request_type === "podcast" ||
                              donationRequest.request_type === "board member" ||
                              donationRequest.request_type === "eventSpeaker" ||
                              donationRequest.request_type === "mentoring") &&
                              (window.location.pathname === "/myDashboard" ||
                                donationRequest.isScholarshipApplied))
                              ? true
                              : false
                          }
                        >
                          {donationRequest &&
                          donationRequest.request_type !== "eventSpeaker"
                            ? (donationRequest.request_type === "podcast" ||
                                donationRequest.request_type ===
                                  "board member" ||
                                donationRequest.request_type === "mentoring") &&
                              (window.location.pathname === "/myDashboard" ||
                                donationRequest.isScholarshipApplied)
                              ? "Interest sent"
                              : "I'm Interested"
                            : donationRequest.request_type === "eventSpeaker" &&
                              (window.location.pathname === "/myDashboard" ||
                                donationRequest.isScholarshipApplied)
                            ? "Already registered"
                            : "Register now"}
                        </Button>
                      )}
                    </div>
                  )}
                {donationRequest &&
                  authUser &&
                  authUser.unique_id === donationRequest &&
                  donationRequest.user_info &&
                  donationRequest.user_info._id &&
                  donationRequest.request_type &&
                  (donationRequest.request_type === "eventSpeaker" ||
                    donationRequest.request_type === "mentoring" ||
                    donationRequest.request_type === "board member" ||
                    donationRequest.request_type === "podcast") &&
                  ((donationRequest &&
                    donationRequest.status === Number(STATUS_APPROVED)) ||
                    (donationRequest &&
                      donationRequest.status === Number(STATUS_EXPIRED))) && (
                    <>
                      {donationRequest &&
                        donationRequest.creatorType !== "mentor" && (
                          <div
                            className="post-action-right"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                props.history.push(
                                  `/viewApplicants/${donationRequest?._id}`
                                )
                              }
                              disableElevation
                              className="donateNowBtn padding-svg"
                            >
                              {donationRequest &&
                              donationRequest.request_type === "mentoring"
                                ? "View Mentors"
                                : donationRequest.request_type ===
                                  "eventSpeaker"
                                ? "View Registrants"
                                : donationRequest.request_type === "podcast"
                                ? "View Speakers"
                                : "View Applicants"}
                            </Button>
                          </div>
                        )}
                    </>
                  )}
              </div>
            </div>
            <Divider variant="fullWidth" className="postDividerIn" />
          </CardContent>
          <CardActions disableSpacing className="card-actions">
            <div className="post-actions-left share-app">
              <IconButton aria-label="like" onClick={handleLikeDonation}>
                <ThumbUpOutlinedIcon
                  style={{ color: showLiked ? "blue" : "#555" }}
                />
                <span
                  className="action-text"
                  style={{ color: showLiked ? "blue" : "#555" }}
                >
                  Like
                </span>
              </IconButton>

              <Tooltip title="Add or view comments">
                <IconButton
                  aria-label="comment"
                  onClick={() => setIsForumDialogOpen(!isForumDialogOpen)}
                >
                  <ChatBubbleOutlineOutlinedIcon />{" "}
                  <span className="action-text">Comment</span>
                </IconButton>
              </Tooltip>

              <div
                style={{ position: "relative", display: "inline-block" }}
                onMouseOver={() => setShareIcons(true)}
              >
                <Tooltip title="Share request">
                  <Button color="primary" onClick={handleClickOpenShare}>
                    <IconButton aria-label="share" className="share-req-icon">
                      <ShareIcon />{" "}
                      <span className="action-text">
                        Share
                        {/* {donationRequest.sharesCount > 1 ? "Shares" : "Share"} (
                      {donationRequest.sharesCount}) */}
                      </span>
                    </IconButton>
                  </Button>
                </Tooltip>

                {shareIcons && donationRequest && (
                  <ShareOnSocialMedia
                    open={openShare}
                    handleClose={() => setOpenShare(false)}
                    url={shortUrl}
                    quote={donationRequest.title}
                    subject={`[Touch-A-Life] Have you seen the ${
                      donationRequest.request_type === "cash"
                        ? "fundraiser"
                        : "request"
                    } "${donationRequest.title}"?`}
                    content={`Hello,<br /><br />I thought you might be interested in supporting this ${
                      donationRequest.request_type
                    } request:<br /> ${shortUrl} <br /><br /><img width="400" id=${
                      donationRequest.name
                    } src=${donationRequest.defaultImageUrl}/><br/><br /> ${
                      donationRequest.request_type === "cash"
                        ? " Even a small donation could help and reach their fundraising goal. And if you can't make a donation, it would be great if you could share the fundraiser to help spread the word. Please contribute to the fundraiser that I am sharing with you."
                        : ""
                    } <br /><br /> Thanks, <br />Touch-A-Life Foundation`}
                  />
                )}
              </div>

              <Tooltip title="Forum discussion">
                <IconButton onClick={() => setOpenForum(true)}>
                  <CommentOutlined />{" "}
                  <span className="action-text">Chat Forum</span>
                </IconButton>
              </Tooltip>
            </div>
          </CardActions>
          {isForumDialogOpen && (
            <Forum
              openForum={isForumDialogOpen}
              commentType={"comment"}
              onClose={() => setIsForumDialogOpen(false)}
              path={"/conversations/" + donationRequest._id}
            />
          )}
          <Divider variant="fullWidth" className="postDividerIn" />
          {donationRequest.request_type === "cash" &&
            authUser &&
            donationRequest.user_info._id === authUser.unique_id && (
              <CardActions disableSpacing className="card-actions">
                <div
                  className="post-actions-left"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className={cssstyles.add_update_btn}
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    Add Update
                  </Button>
                  <Button
                    className={cssstyles.add_update_btn}
                    variant="contained"
                    onClick={handleWithdrawFunds}
                  >
                    Withdraw Funds
                  </Button>
                </div>
              </CardActions>
            )}
        </Card>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classes.modalpaper} report-modal`}>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              padding="10px"
            >
              <Box
                component="h2"
                id="transition-modal-title"
                fontSize="18px"
                color="rgb(205, 38, 39)"
              >
                Report as:{" "}
              </Box>
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CloseIcon />
              </Box>
            </Box>
            <p id="transition-modal-description">
              <span
                onClick={() => handleInappropriate("Nudity or sexaul activity")}
              >
                Nudity or sexaul activity
              </span>
              <span
                onClick={() => handleInappropriate("Hate speech or symbols")}
              >
                Hate speech or symbols
              </span>
              <span
                onClick={() =>
                  handleInappropriate("Violence or dangerous information")
                }
              >
                Violence or dangerous information
              </span>
              <span onClick={() => handleInappropriate("Scam or fraud")}>
                Scam or fraud
              </span>
              <span onClick={() => handleInappropriate("False information")}>
                False information
              </span>
              <span
                onClick={() => handleInappropriate("Bullying or harassment")}
              >
                Bullying or harassment
              </span>
              <span
                onClick={() =>
                  handleInappropriate("Intellectul property violation")
                }
              >
                Intellectul property violation
              </span>
              <span onClick={() => handleInappropriate("Unauthorised Content")}>
                Unauthorised Content
              </span>
              <span onClick={() => handleInappropriate("Phishing or Malware")}>
                Phishing or Malware
              </span>
              <span onClick={() => handleInappropriate("I just dont like it")}>
                I just don't like it
              </span>
            </p>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={careerHelpModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={careerHelpModalOpen}>
          <div className={`${classes.modalpaper} careerhelp-modal`}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  <Img
                    src={
                      donationRequest &&
                      donationRequest.orgId &&
                      donationRequest.orgId._id
                        ? donationRequest.orgId.defaultImageUrl
                          ? donationRequest.orgId.defaultImageUrl
                          : "/images/orgLogo.png"
                        : donationRequest &&
                          donationRequest.user_info &&
                          donationRequest.user_info.image_url
                        ? donationRequest.user_info.image_url
                        : "https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/default/user_male_circle.svg"
                    }
                    alt="user-profile"
                    className="user-profile-img"
                  />
                  alt="user-profile" width="100%"
                </Avatar>
              }
              title={
                <span>
                  <span
                    style={{
                      font: "600 14px Roboto",
                      textTransform: "capitalize",
                    }}
                  >
                    {donationRequest &&
                    donationRequest.orgId &&
                    donationRequest.orgId.orgName
                      ? donationRequest.orgId.orgName
                      : donationRequest &&
                        donationRequest.user_info &&
                        donationRequest.user_info.name &&
                        donationRequest.user_info.name.first_name +
                          " " +
                          donationRequest.user_info.name.last_name}
                  </span>
                  {donationRequest &&
                    donationRequest.orgId &&
                    donationRequest.orgId.status === 1 && (
                      <span>
                        &nbsp;&nbsp;
                        <VerifiedUser
                          style={{ color: "green", fontSize: 14 }}
                        />
                      </span>
                    )}
                  {donationRequest && !donationRequest.orgId && (
                    <span>
                      &nbsp;&nbsp;
                      <VerifiedUser style={{ color: "green", fontSize: 14 }} />
                    </span>
                  )}
                  <span style={{ float: "right" }}>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <h5 onClick={() => handleClose(true)}>X</h5>
                    </span>
                  </span>
                </span>
              }
              subheader={
                <div style={{ color: "#aaa", font: "400 12px Roboto" }}>
                  <span>
                    {donationRequest &&
                      moment(donationRequest.createdAt).format(DATE_FORMAT)}
                  </span>
                  <span> | </span>
                  <span>
                    {donationRequest &&
                      donationRequest.createdAt &&
                      moment(donationRequest.createdAt)
                        .startOf("seconds")
                        .fromNow()}
                  </span>
                </div>
              }
              className="postBy"
            ></CardHeader>
            <div style={{ padding: "16px" }}>
              <span style={{ color: "grey" }}>Contact Details</span>
              <p
                style={{
                  color: "#5f83c0",
                  fontWeight: "500",
                  marginTop: "5px",
                  lineHeight: "22px",
                  marginBottom: "40px",
                }}
              >
                {donationRequest?.user_info?.phone} <br />
                {donationRequest?.user_info?.email}
              </p>
              <p style={{ color: "grey", marginBottom: "5px" }}>
                You are interested in mentoring for
              </p>
              <h3 stle={{ fontSize: "18px", fontWeight: "500" }}>
                {donationRequest?.title}
              </h3>
              <p stle={{ fontSize: "12px", color: "grey" }}>
                {donationRequest?.brief}
              </p>
              {!isLoading && (
                <p className="confirmText-Career">
                  Please Wait &nbsp;&nbsp;
                  <CircularProgress size={24} className="buttonProgress" />
                </p>
              )}
              {isLoading && (
                <p className="confirmText-Career test">
                  <CheckCircleOutlineIcon style={{ color: "green" }} /> Mentee
                  contact details is also sent to your e-mail.
                </p>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      {donationRequest && donationRequest.request_type === "cash" && (
        <Payments
          isOpen={isPaymentsOpen}
          donationFor="donationRequest"
          currency={
            donationRequest &&
            donationRequest.units &&
            donationRequest.units.toUpperCase()
          }
          donationRequestId={donationRequest && donationRequest._id}
          onClose={() => setIsPaymentsOpen(false)}
        />
      )}
      {donationRequest && donationRequest.request_type !== "cash" && (
        <KindTimeDonation
          isOpen={isKindTimeDonationOpen}
          requestType={donationRequest.request_type}
          donationFor="donationRequest"
          donationRequest={donationRequest}
          onClose={() => setIsKindTimeDonationOpen(false)}
          onSavingDonation={saveDonationHandler}
        />
      )}
      <DonationRequestNotLoggedInDialog
        open={openOptions}
        donationRequest={donationRequest}
        onClose={() => setOpenOptions(false)}
      />
      <Forum
        openForum={openForum}
        commentType={"forum"}
        onClose={() => setOpenForum(false)}
        path={"/forumConversations/" + donationRequest._id}
      />
      <ApplyPost
        post={donationRequest}
        setDonationRequests={setDonationRequests}
        donationRequests={donationRequests}
        open={openIntern}
        onClose={() => setOpenIntern(false)}
      />
    </section>
  );
};

export default withRouter(DonationRequestCard);

import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  RadioGroup,
  Radio,
  Chip,
} from "@material-ui/core";
import Sidemenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createDonationRequest,
  updateDonationRequest,
  getUserData,
  getDonationRequest,
  updateProfile,
} from "../../utils/api";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddPhotos from "../common/AddPhotos";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { HOME_PAGE } from "../../utils/utils";
import {
  FormControlLabel,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Img from "react-cool-img";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import "../TALLeaders/TalLeaders.scss";
import styles from "./MentoringRequest.module.scss";
import "./MentoringRequest.scss";
import DescriptionIcon from "@material-ui/icons/Description";
import AddDocument from "../common/AddDocument";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },

  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  fieldlength: {},
}));
const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});

function getSteps() {
  return ["Basic Info", "Additional Information", "Uploads"];
}
const MentoringRequest = (props) => {
  const { authUser, region, regions, setRegion, orgList, setAuthUser } =
    useContext(appContext);
  const educationTypes = [
    "School",
    "Under Grad",
    "Graduation",
    "Engineering",
    "Medicine",
    "Diploma",
    "B.Pharmacy",
    "LLB",
    "Integrated 5-Year Dual-Degree M.Tech",
    "3 Year Degree",
    "Masters",
    "MBA",
    "PhD",
    "MTech",
  ];
  const industries = [
    "Technology/IT",
    "Finance/Investment",
    "Marketing/Advertising",
    "Entrepreneurship/Startups",
    "Healthcare/Medical",
    "Education/Teaching",
    "Non-profit/NGO",
    "Arts/Creative Industries",
    "Consulting/Management",
    "Engineering/Manufacturing",
    "Retail/E-commerce",
    "Hospitality/Tourism",
    "Legal/Law",
    "Media/Entertainment",
    "Social Services",
    "Others",
  ];
  const yearsExperience = Array.from(Array(50 + 1).keys()).slice(1);
  const initialState = {
    fieldofStudy: "",
    currentjob: "",
    yearsofExperience: "",
    title: "",
    fundsRecipient: "",
    seekMentor: "",
    challenges: "",
    topic: "",
    industry: "",
  };
  const [closeSideMenu] = useState(false);
  const [language, setLanguage] = useState("");
  const [industry, setIndustry] = useState("");

  const [refresh, setRefresh] = useState(false);
  const [orgEdit, setOrgEdit] = useState(false);
  const [address, setAddress] = useState(null);
  const classes = useStyles();
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");
  const [educationType, setEducationType] = useState("");
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [id, setId] = useState("");
  const [imageUpload, setImageUpload] = useState(false);
  const [isOrgMember, setIsOrgMember] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };
  const handleChangeEducationType = (event) => {
    setEducationType(event.target.value);
  };

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleChangeIndustry = (event) => {
    setIndustry(event.target.value);
  };

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
  };

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
      pitchDeckVideoUrl: values.pitchDeckVideoUrl,
      status: 0,
    };
    if (
      authUser &&
      authUser.unique_id &&
      donationRequest &&
      donationRequest.user_info &&
      authUser.unique_id == donationRequest.user_info._id
    ) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    } else if (!editMode) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    }
    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the Mentoring request. " +
            error.message
        );
      });
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };
  const createMentoringRequest = (e) => {
    e.preventDefault();
    const errors = {};
    if (activeStep === 0) {
      const errors = {};
      const finalValues = {};
      if (!values.fundsRecipient || values.fundsRecipient === "add") {
        errors.fundsRecipient = "Please Select or Add an Organization";
      }
      if (!educationType || educationType.length === 0) {
        errors.educationType = "Please select one";
      }
      if (values.currentjob.length === 0) {
        errors.currentjob = "Please enter your current job title";
      }
      if (values.title.length === 0) {
        errors.title = "Please enter Title";
      } else if (values.title && values.title.length > 70) {
        errors.title = "Please enter Title less than or equal to 70 characters";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      finalValues.request_type = "mentoring";
      finalValues.user_id = authUser.unique_id;
      finalValues.quantity = 0;
      finalValues.region = address.country ? region && region._id : null;
      finalValues.city = address.city;
      finalValues.state = address.state;
      finalValues.country = address.country;
      finalValues.title = values.title;
      finalValues.shipping_address = address;
      if (values.fundsRecipient && values.fundsRecipient !== "self") {
        finalValues.orgId = values.fundsRecipient;
        finalValues.fundsRecipient = "";
      } else {
        finalValues.fundsRecipient = values.fundsRecipient;
        finalValues.orgId = null;
      }
      const userValues = {};
      userValues.educationalQualification = educationType;
      userValues.functionalExpertise = values.fieldofStudy;
      userValues.professionalExperience = values.currentjob;
      userValues.yearsOfExperience = values.yearsofExperience;
      if (Object.keys(errors).length == 0) {
        updateProfile(authUser.unique_id, userValues)
          .then((response) => {
            setAuthUser(response.data.data);
            if (authUser && authUser.unique_id) {
              localStorage.setItem(
                "authUser",
                JSON.stringify(response.data.data)
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &
                  error.response.data.message &
                  (error.response.data.errors.length > 0)
              ) {
                toastr.error(error.response.data.errors[0].msg);
              } else {
                toastr.error(error.response.data.message);
              }
            } else {
              toastr.error(error.response.data.message);
            }
          });
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Scholarship request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
              // setActiveStep(0);
            });
        }
      }
    } else if (activeStep === 1) {
      const errors = {};
      const finalValues = {};
      if (description.length === 0) {
        errors.description = "Please enter a brief description";
      }
      if (values.seekMentor.length === 0) {
        errors.seekMentor = "Please enter the reason for seeking a Mentor";
      } else if (values.seekMentor && values.seekMentor.length > 200) {
        errors.seekMentor =
          "Please enter Title less than or equal to 200 characters";
      }
      if (language.length === 0) {
        errors.language = "Please select atleast one Language";
      }
      if (
        values.industry.length === 0 ||
        values.industry.toLowerCase().includes("select industry")
      ) {
        errors.industry = "Please select Preferred Industry";
      }
      if (
        (values.industry === "Others" && industry.length === 0) ||
        (industry && industry.toLowerCase() === "select industry")
      ) {
        errors.otherIndustry = "Please enter Preferred Industry";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      finalValues.description = description;
      finalValues.user_id = authUser.unique_id;
      finalValues.whyYouNeedHelp = values.seekMentor;
      finalValues.requestedForDetails = values.topic;
      finalValues.socialProblem = values.challenges;
      finalValues.preferredLanguage = language;
      finalValues.requested_for =
        values.industry === "Others" ? industry : values.industry;
      finalValues.preferredConsultationMode = "Virtual";
      if (Object.keys(errors).length == 0) {
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Scholarship request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
            });
        }
      }
    } else if (activeStep === 2) {
      if (!defaultImageUrl) {
        errors.image = "Please upload at least one image";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
        setErrors({});
        saveDonationRequest(defaultImageUrl);
        if (imageUpload) {
          toastr.success(
            "Your Mentoring Request is successfully" +
              (props.match.params.id ? " updated" : " created ")
          );
          props.history.push(`/donationRequest/${name}`);
        }
      }
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleNext);

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
    saveDonationRequest();
  };
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  const onCloseModal = () => {
    setOpenOrgModal(false);
  };
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const loadRequestData = () => {
    const newValues = {};
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          setDonationRequest(response);
          setId(response._id);
          setDefaultImageUrl(response.defaultImageUrl);
          newValues.fundsRecipient =
            response.orgId &&
            response.orgId._id &&
            response.fundsRecipient !== "self"
              ? response.orgId && response.orgId._id
              : "self";
          setName(response.name);
          newValues.title = response.title ? response.title : "";
          setDescription(
            response.description && response.description !== " "
              ? response.description
              : ""
          );
          newValues.seekMentor = response.whyYouNeedHelp
            ? response.whyYouNeedHelp
            : "";
          newValues.topic = response.requestedForDetails
            ? response.requestedForDetails
            : "";
          newValues.challenges = response.socialProblem
            ? response.socialProblem
            : "";
          setLanguage(
            response.preferredLanguage ? response.preferredLanguage : ""
          );
          newValues.industry = response.requested_for
            ? industries.includes(response.requested_for)
              ? response.requested_for
              : "Others"
            : "";
          setIndustry(
            response.requested_for
              ? industries.includes(response.requested_for)
                ? ""
                : response.requested_for
              : ""
          );
          setImageUpload(true);
          ((response.orgId && response.orgId.user_info) ||
            response.fundsRecipient === "self") &&
            setOrgEdit(authUser.unique_id === response.user_info._id);
          setValues((currentValues) => {
            return {
              ...currentValues,
              ...newValues,
            };
          });
          response.shipping_address &&
            setAddress({
              line1: response.shipping_address.line1,
              line2: response.shipping_address.line2,
              city: response.shipping_address.city,
              state: response.shipping_address.state,
              zip_code: response.shipping_address.zip_code,
              country: response.shipping_address.country,
            });
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };
  useEffect(() => {
    if (authUser && authUser.unique_id) {
      const newValues = {};

      getUserData(authUser && authUser.unique_id).then((response) => {
        const userData = response;
        setEducationType(userData.educationalQualification);
        newValues.fieldofStudy = userData.functionalExpertise;
        newValues.currentjob = userData.professionalExperience;
        newValues.yearsofExperience = userData.yearsOfExperience;
        setAddress({
          country: userData && userData.address && userData.address.country,
          state: userData && userData.address && userData.address.state,
          city: userData && userData.address && userData.address.city,
        });
        setValues((currentValues) => {
          return {
            ...currentValues,
            ...newValues,
          };
        });
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (props.match.params.id) {
      setEditMode(true);
      setIsLoading(true);
      loadRequestData(props.match.params.id);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);
  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <Sidemenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
            xs={12}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/career-help-icon.svg"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "10px",
                      borderRadius: "75%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="Mentoring"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Mentoring
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Empowering Growth: Connect with Mentors Today.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid className="informatic-layout">
              <div>
                <Stepper
                  activeStep={activeStep}
                  style={{ background: "transparent" }}
                  alternativeLabel
                >
                  {getSteps().map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div className={styles.field_length}>
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Basic Info
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                            marginBottom: "10px",
                          }}
                          error={errors && errors.title}
                        >
                          <TextField
                            label="Title of the Requirement*"
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.title
                                  ? styles.bg_red
                                  : values.title
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="title"
                            value={values.title}
                            onChange={changeHandler}
                            inputProps={{
                              maxLength: 70,
                            }}
                          />
                          <span
                            style={{
                              fontSize: "18px",
                              marginLeft: "5px",
                              position: "absolute",
                              top: "5px",
                              color: "#ccc",
                              right: "20px",
                              padding: "20px",
                              paddingRight: "30px",
                            }}
                          >
                            {" "}
                            {values.title ? values.title.length : 0}
                            /70
                          </span>
                          <Typography className="custom-error">
                            {errors.title}
                          </Typography>
                        </FormControl>
                      </Grid>
                      {(props.match.params.id ? orgEdit : true) && (
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            className={styles.blood_grp}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select/Add Organization
                              <span style={{ color: "red" }}> </span>
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.fundsRecipient}
                              onChange={changeHandler}
                              placeholder="Organization"
                              name="fundsRecipient"
                              label="Select/Add Organization"
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-amount ${
                                  errors.fundsRecipient
                                    ? styles.bg_red
                                    : values.fundsRecipient
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                            >
                              <MenuItem value={"self"}>Self</MenuItem>
                              {orgList &&
                                orgList.length > 0 &&
                                orgList.map((org) => {
                                  return (
                                    <MenuItem
                                      value={org && org.orgId && org.orgId._id}
                                    >
                                      <img
                                        src={
                                          org &&
                                          org.orgId &&
                                          org.orgId.defaultImageUrl
                                            ? org.orgId.defaultImageUrl
                                            : "/images/orgLogo.png"
                                        }
                                        alt="orgLogo"
                                        width="20px"
                                        style={{ marginRight: 8 }}
                                      />{" "}
                                      {org && org.orgId && org.orgId.orgName}{" "}
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          textAlign: "end",
                                        }}
                                      >
                                        {org &&
                                        org.orgId &&
                                        org.orgId.status === 1 ? (
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <ErrorOutlineIcon
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </span>
                                    </MenuItem>
                                  );
                                })}
                              <MenuItem
                                value="add"
                                onClick={handleOrganization}
                              >
                                Add Organization
                              </MenuItem>
                            </Select>{" "}
                            <div className="custom-error">
                              {errors.fundsRecipient}
                            </div>
                          </FormControl>
                        </Grid>
                      )}
                      {editMode && !orgEdit && (
                        <Grid item xs={12} md={12}>
                          <InputLabel className="secFormLabel">
                            Select /Add Organization
                          </InputLabel>
                          <div className=" recipient-e ">
                            <Avatar
                              aria-label="recipe"
                              className={classes.avatar}
                            >
                              <Img
                                src={
                                  donationRequest &&
                                  donationRequest.orgId &&
                                  donationRequest.orgId._id
                                    ? donationRequest.orgId.defaultImageUrl
                                      ? donationRequest.orgId.defaultImageUrl
                                      : "/images/orgLogo.png"
                                    : donationRequest &&
                                      donationRequest.user_info &&
                                      donationRequest.user_info.image_url
                                    ? donationRequest.user_info.image_url
                                    : "/images/default-profile-photo1.png"
                                }
                                alt="user-profile"
                                className="user-profile-img"
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                onClick={
                                  donationRequest && donationRequest.orgId
                                    ? () =>
                                        props.history.push(
                                          `/organization/${donationRequest.orgId._id}`
                                        )
                                    : () =>
                                        props.history.push(
                                          `/${donationRequest.user_info.username}`
                                        )
                                }
                              />
                            </Avatar>

                            <TextField
                              type="text"
                              variant="outlined"
                              className="profile-fields lnames recipient-bdr "
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              name="fundsRecipient"
                              disabled
                              value={values.fundsRecipient}
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Educational Background
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          // error={errors && errors.selectProfile}
                          variant="outlined"
                          className={styles.blood_grp}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Highest Level of Education
                            <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={educationType}
                            onChange={handleChangeEducationType}
                            name="educationType"
                            label="Select Education"
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors.educationType
                                  ? styles.bg_red
                                  : educationType
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          >
                            <MenuItem value={""}>Select Education</MenuItem>
                            {educationTypes.map((educationType) => {
                              return (
                                <MenuItem value={educationType}>
                                  {educationType}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <Typography className="custom-error">
                            {errors.educationType}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.fieldofStudy}
                        >
                          <TextField
                            label="Field of Study "
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.fieldofStudy
                                  ? styles.bg_red
                                  : values.fieldofStudy
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="fieldofStudy"
                            value={values.fieldofStudy}
                            onChange={changeHandler}
                          />
                          <Typography className="custom-error">
                            {errors.fieldofStudy}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Professional Experience(Optional)
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.currentjob}
                        >
                          <TextField
                            label="Current Job Title*"
                            type="text"
                            variant="outlined"
                            style={{
                              width: "101%",
                              marginTop: "10px",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.currentjob
                                  ? styles.bg_red
                                  : values.currentjob
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="currentjob"
                            value={values.currentjob}
                            onChange={changeHandler}
                          />
                          <Typography className="custom-error">
                            {errors.currentjob}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          className={styles.blood_grp}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Years of Experience
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={values.yearsofExperience}
                            onChange={changeHandler}
                            name="yearsofExperience"
                            label="Years of Experience"
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors.yearsofExperience
                                  ? styles.bg_red
                                  : values.yearsofExperience
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          >
                            <MenuItem value={""}>yearsExperience</MenuItem>
                            {yearsExperience.map((year) => {
                              return <MenuItem value={year}>{year}</MenuItem>;
                            })}
                          </Select>
                          <Typography className="custom-error">
                            {errors.yearsofExperience}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Box className="pod_buttons">
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "160px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => createMentoringRequest(e)}
                          >
                            Save and continue
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "23px",
                          lineHeight: "37px",
                          color: "#000",

                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Additional Information
                      </Typography>
                      <Grid item xs={12} style={{ alignItems: "center" }}>
                        <Typography
                          component="p"
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            fontSize: "15px",
                            color: "#000",
                            margin: "20px 0 10px 0",
                          }}
                          gutterBottom
                        >
                          Brief Description
                          <span style={{ color: "red" }}> *</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.description}
                        >
                          <Grid className="ck-editor-sc">
                            <CKEditor
                              style={{ padding: "10px 20px" }}
                              maxLength={300}
                              data={description}
                              name="description"
                              editor={ClassicEditor}
                              onChange={changeDescriptionHandler}
                              config={{
                                toolbar: [
                                  "Heading",
                                  "bold",
                                  "italic",
                                  "bulletedList",
                                  "numberedList",
                                  "blockQuote",
                                  "Link",
                                ],
                                placeholder: "Brief Description",
                              }}
                            />
                          </Grid>
                          <Typography className="custom-error">
                            {errors.description}
                          </Typography>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.seekMentor}
                        >
                          <div className="cov-input">
                            <TextField
                              label="Reason for seeking mentor *"
                              type="text"
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              name="seekMentor"
                              inputProps={{
                                maxLength: 200,
                              }}
                              value={values.seekMentor}
                              onChange={changeHandler}
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-title  ${
                                  errors.seekMentor
                                    ? styles.bg_red
                                    : values.highestEducation
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                            />
                            <span
                              style={{
                                fontSize: "18px",
                                marginLeft: "5px",
                                position: "absolute",
                                color: "#ccc",
                                right: "0px",
                                padding: "20px",
                                paddingRight: "30px",
                              }}
                            >
                              {" "}
                              {values.seekMentor ? values.seekMentor.length : 0}
                              /200
                            </span>
                            <Typography className="custom-error">
                              {errors.seekMentor}
                            </Typography>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.topic}
                        >
                          <TextField
                            label="Specific Mentoring Topics / Needs "
                            type="text"
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                            }}
                            name="topic"
                            value={values.topic}
                            onChange={changeHandler}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.topic
                                  ? styles.bg_red
                                  : values.topic
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          />
                          <Typography className="custom-error">
                            {errors.topic}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.challenges}
                        >
                          <TextField
                            label="Challanges faced"
                            type="text"
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                            }}
                            name="challenges"
                            value={values.challenges}
                            onChange={changeHandler}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.challenges
                                  ? styles.bg_red
                                  : values.challenges
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          />
                          <Typography className="custom-error">
                            {errors.challenges}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Typography>
                          Preferred Language of Communication{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", paddingLeft: "10px" }}
                          error={errors && errors.language}
                        >
                          <RadioGroup
                            row
                            value={language}
                            onChange={handleChangeLanguage}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="any"
                              control={<Radio />}
                              label="Any"
                            />
                            <FormControlLabel
                              value="english"
                              control={<Radio />}
                              label="English"
                            />
                            <FormControlLabel
                              value="hindi"
                              control={<Radio />}
                              label="Hindi"
                            />
                            <FormControlLabel
                              value="telugu"
                              control={<Radio />}
                              label="Telugu"
                            />
                          </RadioGroup>
                          <FormHelperText>
                            <div className={styles.custom_error}>
                              {errors.language}
                            </div>
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          className={styles.blood_grp}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Preferred Industry / Field for Mentorship{" "}
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={values.industry}
                            onChange={changeHandler}
                            name="industry"
                            label="Select Industry"
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors.industry
                                  ? styles.bg_red
                                  : values.industry
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          >
                            <MenuItem value={"select industry"}>
                              Select Industry
                            </MenuItem>
                            {industries.map((industry) => {
                              return (
                                <MenuItem value={industry}>{industry}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText>
                            <div className={styles.custom_error}>
                              {errors.industry}
                            </div>
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          className={styles.blood_grp}
                        >
                          {values.industry === "Others" && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              style={{
                                paddingTop: "30px",
                                paddingLeft: "1px",
                                fontStyle: "bold",
                                fontWeight: "400",
                                fontSize: "15px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="industry"
                              placeholder="Enter Industry"
                              value={industry}
                              onChange={handleChangeIndustry}
                            />
                          )}
                          <FormHelperText>
                            <div className={styles.custom_error}>
                              {errors.otherIndustry}
                            </div>
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          className="d-flex align-items-center"
                          style={{ gap: "15px" }}
                        >
                          <Typography>Mentoring Format :</Typography>
                          <Chip label="Virtual"></Chip>
                        </Box>
                      </Grid>
                      <Box className="pod_buttons">
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "160px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => createMentoringRequest(e)}
                          >
                            Save and continue
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "23px",
                          lineHeight: "37px",
                          color: "#000",

                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Almost Done
                      </Typography>
                      <div className="request-step-two">
                        <div className="request-two-step">
                          <h3 className="step-two-title">
                            Add a cover photo or video
                          </h3>
                        </div>
                        <p className="image-desc">
                          This is the image that will feature right on top of
                          your page. Make it impactful!
                        </p>
                        <div className="fund-img-txt">
                          <p> A picture is worth a thousand words</p>
                          <ul>
                            <li>
                              <span>Keep it relevant</span>
                            </li>
                            <li>
                              <span>Keep it clear</span>
                            </li>
                            <li>
                              <span>Types: .PNG, .JPG, .BMP, .MP4</span>
                            </li>
                            <li>
                              <span>Size: 700px X 400px</span>
                            </li>
                          </ul>
                        </div>
                        <MuiThemeProvider
                          theme={theme2}
                          className="req-tooltip-img"
                        >
                          <img
                            src={
                              defaultImageUrl
                                ? defaultImageUrl
                                : "/images/default-req-img.gif"
                            }
                            alt="default"
                            style={{ width: "100%" }}
                          />
                        </MuiThemeProvider>
                        {errors && errors.image && (
                          <Typography className="custom-error">
                            {errors.image}
                          </Typography>
                        )}
                        <div className={styles.add_btn_fund}>
                          <Button onClick={() => setShowingAddPhotoModal(true)}>
                            <FontAwesomeIcon
                              color="#283E4A"
                              size="1x"
                              style={{ marginRight: 6 }}
                              icon={faFileUpload}
                            />{" "}
                            Add Images
                          </Button>
                          <Button
                            className="document-btn"
                            onClick={() => setShowingAddDocumentModal(true)}
                          >
                            <DescriptionIcon
                              style={{ fontSize: 14, marginRight: 6 }}
                            />{" "}
                            Upload supporting document(s)
                          </Button>
                        </div>
                      </div>

                      <Box display="flex" justifyContent="flex-end">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={createMentoringRequest}
                          >
                            {editMode ? "Update" : "Submit"}
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
        multiple={true}
      />
      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={authUser && authUser.unique_id}
        collection={`/DonationRequest/${donationRequest._id}/Responses`}
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={onCloseModal}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(MentoringRequest);

import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import FormHelperText from "@material-ui/core/FormHelperText";
import NewAddress from "../common/NewAddress";
import { appContext } from "../../App";
import {
  createDonationRequest,
  getUserData,
  updateProfile,
} from "../../utils/api";
import { HOME_PAGE } from "../../utils/utils";
import "./CareerHelp.scss";
import "react-phone-number-input/style.css";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import PhoneNumber from "../Phone/PhoneNumber";
import { Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CareerHelp = (props) => {
  const { authUser, setAuthUser, region } = useContext(appContext);
  const [closeSideMenu] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [checked1, setChecked1] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked1.indexOf(value);
    const newChecked = [...checked1];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked1(newChecked);
  };

  const [address, setAddress] = useState(null);
  const [step, setStep] = useState(0);
  const headings = [
    "Personal Information",
    "Your Areas of Interest",
    "You Are Almost Done",
  ];
  const values = ["Mentor/Mentee", "College Bound", "Career Pivots"];
  const careerMenu = {
    "Mentor/Mentee": [
      "Resume preparation/review",
      "Interview preparation",
      "Mock Interviews",
      "Strategies for Job Application",
      "Choosing industry and career to pursue",
      "Identifying your brand",
      "Networking and references",
      "Others",
    ],
    "College Bound": [
      "Selecting types of colleges or vocational schools",
      "Career Paths",
      "Essay and/or Application Review",
      "Peer mentoring",
      "Others",
    ],
    "Career Pivots": [
      "Leadership styles",
      "Change Management",
      "Strategic Decision Making",
      "Expand span of influence",
    ],
  };

  const [checked, setChecked] = useState("");
  const [careerOptionsSelected, setCareerOptionsSelected] = useState({});
  const [optionsCount] = new useState({});
  useEffect(() => {
    setCareerOptionsSelected(careerOptionsSelected);
    updateCount();
  });
  const [count, setCount] = useState(0);
  const [isFirst] = useState(true);

  const [optionsErr, setOptionsErr] = useState("");

  const updateCount = () => {
    let count1 = 0;
    for (let group in careerOptionsSelected) {
      optionsCount[group] = careerOptionsSelected[group].length;
      count1 += careerOptionsSelected[group].length;
    }
    if (count === 0 && !isFirst) {
      setOptionsErr("Please select at least one category");
    } else if (count > 0) {
      setOptionsErr("");
    }
    setCount(count1);
  };

  

  const handleToggleSelection = (event, value, item) => {
    const currentIndex = careerOptionsSelected[value]
      ? careerOptionsSelected[value].indexOf(item)
      : -1;
    const newChecked = careerOptionsSelected[value]
      ? [...careerOptionsSelected[value]]
      : [];
    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCareerOptionsSelected({
      ...careerOptionsSelected,
      [value]: [...newChecked],
    });
  };
 

  const [mentorMentee, setmentorMentee] = useState("");

  const handleMentorMentee = (name) => {
    setmentorMentee(name);
    setStep(step + 1);
  };
  const [ocError, setOCError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [briefError, setBriefError] = useState("");
  const [descError, setDescError] = useState("");

  const [occupation, setOccupation] = useState("");
  const [occupationUpdate, setOccupationUpdate] = useState("");
  const handleOccupation = (event) => {
    if (event.target.value.length === 0)
      setOCError("Please enter your occupation");
    else setOCError("");
    setOccupationUpdate(event.target.value);
  };
  const [linkedIn, setLinkedIn] = useState("");
  const [linkedInUpdate, setLinkedInUpdate] = useState("");
  const handleLinkedIn = (event) => {
    setLinkedInUpdate(event.target.value);
  };

  const [title, setTitle] = useState("");
  const handleTitle = (event) => {
    if (event.target.value.length === 0) setTitleError("Please enter title");
    else setTitleError("");
    setTitle(event.target.value);
  };

  const [brief, setBrief] = useState("");
  const handleBrief = (event) => {
    if (event.target.value.length === 0) setBriefError("Please enter brief");
    else setBriefError("");
    setBrief(event.target.value);
  };
  const [description, setDescription] = useState("");
  const handleDescription = (event) => {
    if (event.target.value.length === 0)
      setDescError("Please enter description");
    else setDescError("");
    setDescription(event.target.value);
  };
  const [policy1, setPolicy1] = useState(true);
  const handlePolicy1 = (event) => {
    if (event.target.checked) {
      setPolicy1(true);
    } else {
      setPolicy1(false);
    }
  };
  const [policy2, setPolicy2] = useState(true);
  const handlePolicy2 = (event) => {
    if (event.target.checked) {
      setPolicy2(true);
    } else {
      setPolicy2(false);
    }
  };
  const [policy3, setPolicy3] = useState(true);
  const handlePolicy3 = (event) => {
    if (event.target.checked) {
      setPolicy3(true);
    } else {
      setPolicy3(false);
    }
  };
  const { regions, setRegion } = useContext(appContext);
  const [tempAddress, setTempAddress] = useState(null);
  const [extraErrors, setExtraErrors] = useState({});
  const [phoneError] = useState("");

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const phoneRef = useRef();
  const handleNext = () => {
    let errors = {};
    if (step === 0) {
      setStep(step + 1);
    } else if (step === 1) {
      if (
        tempAddress &&
        tempAddress.city !== "" &&
        tempAddress.state !== "" &&
        tempAddress.country !== ""
      ) {
        setAddress(tempAddress);
        setExtraErrors(errors);
      } else {
        errors.address = "Please select country, state and city";
        setExtraErrors(errors);
      }
      if (
        occupation &&
        occupationUpdate &&
        occupation.length === 0 &&
        occupationUpdate.length === 0
      )
        setOCError("Please enter your occupation");
      else setOCError("");

      if (
        phoneRef &&
        phoneRef.current &&
        phoneRef.current.isValid() &&
        ((occupation && occupation.length !== 0) ||
          (occupationUpdate && occupationUpdate.length !== 0)) &&
        errors &&
        !errors.address
      ) {
        setStep(step + 1);
      }
    } else if (step === 2) {
      if (count === 0) {
        setOptionsErr("Please select at least one category");
      } else if (count > 0) {
        setOptionsErr("");
      }
      if (count !== 0) {
        setStep(step + 1);
      }
    } else {
      if (title.length === 0) setTitleError("Please enter title");
      else setTitleError("");
      if (brief.length === 0) setBriefError("Please enter brief");
      else setBriefError("");
      if (description.length === 0) setDescError("Please enter description");
      else setDescError("");
      if (
        title.length !== 0 &&
        brief.length !== 0 &&
        description.length !== 0
      ) {
        handleSubmitData();
      }
    }
  };
  const handleSubmitData = () => {
    updateUserProfile();

    const finalValues = {};
    finalValues.user_id = authUser.unique_id;
    finalValues.region = region._id;
    finalValues.request_type = "career";
    finalValues.creatorType = mentorMentee;

    finalValues.additionalInfo = careerOptionsSelected;
    finalValues.title = title;
    finalValues.brief = brief;
    finalValues.description = description;
    finalValues.quantity = 1;
    finalValues.line1 = address.line1;
    finalValues.line2 = address.line2;
    finalValues.zip_code = address.zip_code;
    finalValues.region = region._id;
    finalValues.city = tempAddress.city;
    finalValues.state = tempAddress.state;
    finalValues.country = tempAddress.country;

    createDonationRequest(finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (mentorMentee === "mentor") {
            toastr.success(
              "Thank you for showing your interest in becoming a mentor. You will receive email confirmation. Best wishes."
            );
          } else if (mentorMentee === "mentee") {
            toastr.success(
              "Thank you for showing your interest in getting assistance from our mentors. You will receive email confirmation. Best wishes."
            );
          }
          //Redirect to details page
          props.history.push(`/donationRequest/${resp.data.name}`);
        }
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      loadUserData(authUser.unique_id);
    }
  }, [authUser]);

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setOccupation(userData.occupation);
        setOccupationUpdate(userData.occupation);
        setLinkedIn(userData.linkedInProfileUrl);
        setLinkedInUpdate(userData.linkedInProfileUrl);
        if (userData.address) {
          //DO NOT DELETE
          // if (
          //   userData.address.hasOwnProperty("country") &&
          //   userData.address.country === "India"
          // ) {
          //   setRegion(regions.find((region) => region.name === "India"));
          // } else {
          //   setRegion(
          //     regions.find((region) => region.name === "United States")
          //   );
          // }
          //=====
          setAddress({
            line1: userData.address.hasOwnProperty("line1")
              ? userData.address.line1
              : "",
            line2: userData.address.hasOwnProperty("line2")
              ? userData.address.line2
              : "",
            city: userData.address.hasOwnProperty("city")
              ? userData.address.city
              : "",
            state: userData.address.hasOwnProperty("state")
              ? userData.address.state
              : "",
            zip_code: userData.address.hasOwnProperty("zip_code")
              ? userData.address.zip_code
              : "",
            country: userData.address.hasOwnProperty("country")
              ? userData.address.country
              : "",
          });
        }
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  };
  const updateUserProfile = () => {
    const finalValues = {};
    finalValues.name = authUser.name;
    if (linkedInUpdate && linkedIn !== linkedInUpdate)
      finalValues.linkedInProfileUrl = linkedInUpdate;

    if (occupationUpdate && occupation !== occupationUpdate)
      finalValues.occupation = occupationUpdate;
    if (Object.keys(finalValues).length > 0) {
      updateProfile(authUser.unique_id, finalValues)
        .then((response) => {
          setAuthUser(response.data.data);
          if (authUser && authUser.unique_id) {
            localStorage.setItem(
              "authUser",
              JSON.stringify(response.data.data)
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &
                error.response.data.message &
                (error.response.data.errors.length > 0)
            ) {
              toastr.error(error.response.data.errors[0].msg);
            } else {
              toastr.error(error.response.data.message);
            }
          } else {
            toastr.error(error.response.data.message);
          }
        });
    }
  };
  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <Grid md={3} style={{ marginRight: "8px" }}>
              <SideMenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src={
                      step === 0
                        ? "/images/career-help.svg"
                        : mentorMentee === "mentor"
                        ? "/images/mentor.svg"
                        : "/images/mentee.svg"
                    }
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "20px",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    {step === 0
                      ? "Career Help"
                      : mentorMentee === "mentor"
                      ? "TalMentor"
                      : "TalMentee"}
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    {step === 0
                      ? "Bridging the gap between mentors and mentees to improve the workforce."
                      : mentorMentee === "mentor"
                      ? "Be a mentor"
                      : "Find a mentor"}
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid container>
              <Grid item md={12} xs={12}>
                <div className="form-container">
                  {step === 0 ? (
                    <div
                      className="mentor-mentee-container"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid container spacing={3} md={8}>
                        <Grid item sm={12} xs={12} className="careertitle">
                          Find the Business Help You Need.
                          <br></br>
                          Share the Knowledge You Have.
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography className="crtitle">
                            Be a Mentor
                          </Typography>

                          <div
                            value="mentor"
                            name="mentor"
                            label="Mentor"
                            className={"mentor-mentee-selected"}
                            onClick={() => handleMentorMentee("mentor")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/images/mentor.svg"
                              alt="Mentor"
                              style={{
                                margin: " 0 10px",
                              }}
                            />
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "19px",
                                textAlign: "center",
                              }}
                              gutterBottom
                            >
                              TalMentor
                            </Typography>
                            <KeyboardArrowRight />
                          </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography className="crtitle">
                            Find a Mentor
                          </Typography>

                          <div
                            className={
                              "mentor-mentee-selected mentor-mentee-selected-last"
                            }
                            value="mentee"
                            name="mentee"
                            label="Mentee"
                            onClick={() => handleMentorMentee("mentee")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/images/mentee.svg"
                              alt="Mentee"
                              style={{
                                margin: " 0 10px",
                              }}
                            />
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "19px",
                                textAlign: "center",
                              }}
                              gutterBottom
                            >
                              TalMentee
                            </Typography>
                            <KeyboardArrowRight />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Step {step}
                      </Typography>
                      <Typography variant="h4" gutterBottom>
                        {headings[step - 1]}
                      </Typography>
                      <BorderLinearProgress
                        variant="determinate"
                        value={33 * (step - 1)}
                      />
                    </>
                  )}
                    {step === 1 && (
                    <>
                      <div className="text-field-box">
                        <InputLabel>Phone</InputLabel>
                        <PhoneNumber
                          phone={authUser && authUser.phone}
                          isPhoneVerified={authUser && authUser.phone_verified}
                          ref={phoneRef}
                        />
                        {phoneError && (
                          <div className="custom-error">{phoneError}</div>
                        )}
                      </div>

                      <div className="text-field-box">
                        <TextField
                          id="outlined-error-helper-text"
                          label="Occupation"
                          onChange={handleOccupation}
                          value={occupationUpdate}
                          variant="outlined"
                          error={ocError.length !== 0}
                          helperText={ocError.length === 0 ? "" : ocError}
                          className="text-field"
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="text-field-box">
                        <TextField
                          id="outlined-error-helper-text"
                          label={
                            "LinkedIn profile / other social profiles (Optional)"
                          }
                          value={linkedInUpdate}
                          onChange={handleLinkedIn}
                          variant="outlined"
                          className="text-field"
                        />
                      </div>
                      <div className="address-field-box">
                        <FormControl className="text-field">
                          <p>Select your location</p>
                          <NewAddress
                            value={address}
                            onChange={handleChangeAddress}
                          />
                          {extraErrors && extraErrors.address && (
                            <div className="custom-error">
                              {extraErrors.address}
                            </div>
                          )}
                        </FormControl>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Mentor/Mentee" {...a11yProps(0)} />
                            <Tab label="College Bound" {...a11yProps(1)} />
                            <Tab label="Career Pivots" {...a11yProps(2)} />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <List>
                            {careerMenu["Mentor/Mentee"].map((value) => {
                              const labelId = { value };

                              return (
                                <InputLabel
                                  key={value}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(value)}
                                  className="careerCheck"
                                >
                                  <ListItemIcon style={{ minWidth: "0" }}>
                                    <Checkbox
                                      edge="start"
                                      checked={checked1.indexOf(value) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      checked={
                                        careerOptionsSelected[
                                          "Mentor/Mentee"
                                        ] &&
                                        careerOptionsSelected[
                                          "Mentor/Mentee"
                                        ].indexOf(value) > -1
                                      }
                                      onChange={(e) =>
                                        handleToggleSelection(
                                          e,
                                          "Mentor/Mentee",
                                          value
                                        )
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={value}
                                    className="checkTxt"
                                  />
                                </InputLabel>
                              );
                            })}
                          </List>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <List>
                            {careerMenu["College Bound"].map((value) => {
                              const labelId = { value };

                              return (
                                <InputLabel
                                  key={value}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(value)}
                                  className="careerCheck"
                                >
                                  <ListItemIcon style={{ minWidth: "0" }}>
                                    <Checkbox
                                      edge="start"
                                      // checked={checked1.indexOf(value) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      checked={
                                        careerOptionsSelected[
                                          "College Bound"
                                        ] &&
                                        careerOptionsSelected[
                                          "College Bound"
                                        ].indexOf(value) > -1
                                      }
                                      onChange={(e) =>
                                        handleToggleSelection(
                                          e,
                                          "College Bound",
                                          value
                                        )
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={value}
                                    className="checkTxt"
                                  />
                                </InputLabel>
                              );
                            })}
                          </List>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <List>
                            {careerMenu["Career Pivots"].map((value) => {
                              const labelId = { value };

                              return (
                                <InputLabel
                                  key={value}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(value)}
                                  className="careerCheck"
                                >
                                  <ListItemIcon style={{ minWidth: "0" }}>
                                    <Checkbox
                                      edge="start"
                                      // checked={checked1.indexOf(value) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      checked={
                                        careerOptionsSelected[
                                          "Career Pivots"
                                        ] &&
                                        careerOptionsSelected[
                                          "Career Pivots"
                                        ].indexOf(value) > -1
                                      }
                                      onChange={(e) =>
                                        handleToggleSelection(
                                          e,
                                          "Career Pivots",
                                          value
                                        )
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={value}
                                    className="checkTxt"
                                  />
                                </InputLabel>
                              );
                            })}
                          </List>
                        </TabPanel>
                        <FormControl
                          className="text-field"
                          variant="outlined"
                          error={optionsErr.length !== 0}
                        >
                          <FormHelperText>
                            {optionsErr.length === 0
                              ? ""
                              : "Please select at least one category"}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      {/* <div className="text-field-box">
                        <FormControl
                          className="text-field"
                          variant="outlined"
                          error={optionsErr.length !== 0}
                        >
                          <Select
                            value={selected}
                            onClick={(e) => handleMenuOptions(e)}
                            open={open}
                            inputProps={{
                              name: "cateogory",
                              id: "category",
                            }}
                          >
                            <MenuItem
                              key="Select Career Help Category"
                              value="Select Career Help Category"
                            >
                              Select Career Help Category
                            </MenuItem>
                            {values.map((value) => {
                              return (
                                <MenuItem
                                  value={value}
                                  key={value}
                                  className="headmenu"
                                >
                                  <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    className="mainmenu"
                                  >
                                    <ListItem
                                      button
                                      key={value}
                                      value={value}
                                      onClick={() => handleSelect(value)}
                                      className="submenu"
                                    >
                                      <Checkbox
                                        checked={
                                          optionsCount[value] ===
                                          careerMenu[value].length
                                        }
                                        indeterminate={
                                          optionsCount[value] > 0 &&
                                          optionsCount[value] <
                                            careerMenu[value].length
                                        }
                                        name="Mentor"
                                        value={value}
                                        onChange={(e) =>
                                          handleAllSelect(e, value)
                                        }
                                      />
                                      <ListItemText primary={value} />

                                      {checked === value ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItem>
                                    <Collapse
                                      in={checked === value}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <List component="div" disablePadding>
                                        {careerMenu[value].map((item) => (
                                          <label
                                            key={item}
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              backgroundColor: "white",
                                            }}
                                          >
                                            <ListItem
                                              button
                                              key={item}
                                              className="border-left"
                                            >
                                              <ListItemIcon
                                                style={{ minWidth: "25px" }}
                                              >
                                                <Checkbox
                                                  checked={
                                                    careerOptionsSelected[
                                                      value
                                                    ] &&
                                                    careerOptionsSelected[
                                                      value
                                                    ].indexOf(item) > -1
                                                  }
                                                  onChange={(e) =>
                                                    handleToggleSelection(
                                                      e,
                                                      value,
                                                      item
                                                    )
                                                  }
                                                  value={item}
                                                  name="Mentor"
                                                />
                                              </ListItemIcon>
                                              <ListItemText primary={item} />
                                            </ListItem>
                                          </label>
                                        ))}
                                      </List>
                                    </Collapse>
                                  </List>
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText>
                            {optionsErr.length === 0
                              ? ""
                              : "Please select at least one category"}
                          </FormHelperText>
                        </FormControl>
                      </div> */}
                      <div className="text-field-box" style={{}}>
                        {values.map((group) => (
                          <>
                            <b>
                              {careerOptionsSelected[group] &&
                                careerOptionsSelected[group].length > 0 &&
                                group}
                            </b>
                            <List>
                              {careerOptionsSelected[group] &&
                                careerOptionsSelected[group].map(
                                  (element, index) => (
                                    <ListItemText
                                      key={index}
                                      primary={element}
                                      style={{ marginLeft: "10px" }}
                                    />
                                  )
                                )}
                            </List>
                          </>
                        ))}
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div className="text-field-box">
                        <TextField
                          id="outlined-error-helper-text"
                          label="Title"
                          value={title}
                          error={titleError.length !== 0}
                          helperText={titleError.length === 0 ? "" : titleError}
                          variant="outlined"
                          className="text-field"
                          onChange={handleTitle}
                          inputProps={{
                            maxLength: "50",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "15px",
                            marginLeft: "5px",
                            position: "absolute",
                            top: "10px",
                            color: "#999",
                            padding: "9px",
                            background: "#fff",
                            right: "1px",
                          }}
                        >
                          {" "}
                          {title ? title.length : 0}/50
                        </span>
                      </div>
                      <div className="text-field-box">
                        <TextField
                          id="outlined-error-helper-text"
                          label="Brief"
                          value={brief}
                          multiline
                          rows={2}
                          onChange={handleBrief}
                          error={briefError.length !== 0}
                          helperText={briefError.length === 0 ? "" : briefError}
                          variant="outlined"
                          className="text-field"
                          inputProps={{
                            maxLength: "120",
                            spellCheck: true,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "15px",
                            marginLeft: "5px",
                            position: "absolute",
                            top: "1px",
                            color: "#999",
                            padding: "6px",
                            background: "none",
                            right: "1px",
                          }}
                        >
                          {" "}
                          {brief ? brief.length : 0}/120
                        </span>
                      </div>
                      <div className="text-field-box">
                        <TextField
                          id="outlined-error-helper-text"
                          label="Description"
                          multiline
                          rows={5}
                          value={description}
                          onChange={handleDescription}
                          error={descError.length !== 0}
                          helperText={descError.length === 0 ? "" : descError}
                          variant="outlined"
                          className="text-field"
                          inputProps={{
                            maxLength: "300",
                            spellCheck: true,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "15px",
                            marginLeft: "5px",
                            position: "absolute",
                            top: "1px",
                            color: "#999",
                            padding: "6px",
                            background: "none",
                            right: "1px",
                          }}
                        >
                          {" "}
                          {description ? description.length : 0}/300
                        </span>
                      </div>
                      <div>
                        Disclaimers:
                        <div className="checkbox-box">
                          <Checkbox
                            checked={policy1}
                            onChange={handlePolicy1}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            style={{ marginTop: "0px", paddingTop: "0px" }}
                          />
                          <Typography variant="body2" gutterBottom>
                            All registrants of TAL Career Help must participate
                            in a mandatory brief introduction session. These
                            sessions are held every Friday at 5:00 PM PST and
                            you will be sent the meeting link details to your
                            registered email.
                          </Typography>
                        </div>
                        <div className="checkbox-box">
                          <Checkbox
                            checked={policy2}
                            onChange={handlePolicy2}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            style={{ marginTop: "0px", paddingTop: "0px" }}
                          />
                          <Typography variant="body2" gutterBottom>
                            I am ok to be contacted by the TAL team or mentors.
                          </Typography>
                        </div>
                        <div className="checkbox-box">
                          <Checkbox
                            checked={policy3}
                            onChange={handlePolicy3}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            style={{ marginTop: "0px", paddingTop: "0px" }}
                          />
                          <Typography variant="body2" gutterBottom>
                            By submitting, I agree to your
                            <a
                              href="https://www.touchalife.org/terms-and-conditions/"
                              target="blank"
                            >
                              Terms of Use
                            </a>{" "}
                            and{" "}
                            <a
                              href="https://www.touchalife.org/privacy-policy/"
                              target="blank"
                            >
                              Privacy Policy
                            </a>
                            .{" "}
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    {step !== 0 && (
                      <>
                        <Button
                          style={{
                            color: "var(--tal_primary)",
                          }}
                          className="text-field"
                          onClick={() => setStep(step - 1)}
                        >
                          Back
                        </Button>

                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: !(policy1 && policy2 && policy3)
                              ? "#9D9D9D"
                              : "var(--tal_primary)",
                            color: "white",
                          }}
                          className="text-field"
                          disabled={!(policy1 && policy2 && policy3)}
                          onClick={handleNext}
                        >
                          {step === 3 ? "Submit" : "Next"}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(CareerHelp);

import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { appContext } from "../../App";
import "../Organization/Organization.scss";
import validator from "validator";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],
    // paddingBottom: "20px",
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    paddingTop: 20,
    display: "inline-block",
  },

  title: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    background: "#F44234",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
  },
  deleteBtn: {
    marginLeft: "50px",
  },
  btnCntr: {
    // width: "40%",
    padding: "20px",
    margin: "auto",
  },
  contactListCntr: {
    // width: "60%",
    margin: "auto",
    overflowX: "auto",
    height: "auto",
    maxHeight: "300px",
  },
  list: {
    borderBottom: "1px solid rgb(246, 247, 251)",
  },
}));

const headCells = [
  { id: "sno", numeric: true, disablePadding: false, label: "SL. NO." },
  { id: "name", numeric: true, disablePadding: true, label: "NAMES" },
  { id: "email", numeric: true, disablePadding: false, label: "EMAILS" },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const EmailPreviousDonors = (props) => {
  const {
    setSelectedGroups,
    contactsByGroupName,
    contactsCountByGroupName,
    selectedContactsForEmailCampaign,
    setSelectedContactsForEmailCampaign,
  } = useContext(appContext);
  const { currentContactGroup } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState([]);

  const updateGroupsandCount = () => {
    for (let group in contactsByGroupName) {
      contactsCountByGroupName[group] = 0;
    }
    selectedContactsForEmailCampaign.forEach((id) => {
      for (let group in contactsByGroupName) {
        if (group === "Previous Donors") {
          contactsByGroupName[group].forEach((item) => {
            if (item.donationId === id) {
              contactsCountByGroupName[group] =
                contactsCountByGroupName[group] + 1;
            }
          });
        } else {
          contactsByGroupName[group].forEach((item) => {
            if (item._id === id) {
              contactsCountByGroupName[group] =
                contactsCountByGroupName[group] + 1;
            }
          });
        }
      }
    });
    setSelectedGroups([]);
    let grps = [];
    for (let group in contactsCountByGroupName) {
      if (contactsCountByGroupName[group] > 0) grps.push(group);
    }
    setSelectedGroups(grps);
  };

  const handleSelectAllClick = (event) => {
    const checkedIds = checked
      .filter((contact) => validator.isEmail(contact.email))
      .map((contact) => contact.donationId);
    if (event.target.checked) {
      if (currentContactGroup) {
        //setSelectedGroups([...selectedGroups, currentContactGroup.name]);
        setSelectedContactsForEmailCampaign(
          [...selectedContactsForEmailCampaign, ...checkedIds].filter(function (
            item,
            index,
            inputArray
          ) {
            return inputArray.indexOf(item) === index;
          })
        );
        updateGroupsandCount();
      }
      return;
    }
    const selObj = selectedContactsForEmailCampaign.filter(
      (item) => checkedIds.indexOf(item) === -1
    );
    setSelectedContactsForEmailCampaign(selObj);
    updateGroupsandCount();
  };
  const handleToggle = (value) => () => {
    const currentIndex = selectedContactsForEmailCampaign.indexOf(value);
    const newChecked = [...selectedContactsForEmailCampaign];
    if (currentContactGroup) {
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setSelectedContactsForEmailCampaign(newChecked);
      updateGroupsandCount();
    }
  };
  useEffect(() => {
    updateGroupsandCount();
  }, [selectedContactsForEmailCampaign]);
  useEffect(() => {
    if (currentContactGroup) {
      setChecked(contactsByGroupName[currentContactGroup]);
      updateGroupsandCount();
    }
    updateGroupsandCount();
  }, [contactsByGroupName[currentContactGroup]]);

  let i = 1;

  return (
    <>
      {checked && checked.length > 0 ? (
        <TableContainer
          style={{
            border: "1px solid #E5E5E5",
            borderRadius: "5px",
          }}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={contactsCountByGroupName[currentContactGroup]}
              onSelectAllClick={handleSelectAllClick}
              rowCount={contactsByGroupName[currentContactGroup].length}
            />
            <TableBody>
              {contactsByGroupName[currentContactGroup].map((contact) => {
                if (checked.indexOf(contact) === -1) return null;
                const labelId = `checkbox-list-label-${contact.donationId}`;
                return (
                  <TableRow
                    role="checkbox"
                    padding="checkbox"
                    hover
                    key={contact.donationId}
                    role={undefined}
                    dense
                    button
                    className={classes.list}
                  >
                    <TableCell align="center">
                      {validator.isEmail(contact.email) && (
                        <Checkbox
                          edge="start"
                          checked={
                            selectedContactsForEmailCampaign.indexOf(
                              contact.donationId
                            ) >= 0
                          }
                          onClick={handleToggle(contact.donationId)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">{i++}</TableCell>
                    <TableCell align="center">
                      {`${contact.firstName} ${contact.lastName}`}
                    </TableCell>
                    <TableCell align="center">
                      <span
                        style={{
                          color: validator.isEmail(contact.email)
                            ? ""
                            : "#FF0000",
                        }}
                      >
                        {contact.email || contact.phoneNumber}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            fontSize: "12px",
            padding: "10px 0",
            textAlign: "center",
          }}
        >
          No Contacts
        </div>
      )}
    </>
  );
};

export default withRouter(EmailPreviousDonors);

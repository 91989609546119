import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { appContext } from "../../App";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Button } from "@material-ui/core";
import EmailGroupContactsSelectionModal from "./EmailGroupContactsSelectionModal";
import EmailAddContactsModal from "./EmailAddContactsModal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import validator from "validator";

function createData(sno, grpname, contacts, slist) {
  return { sno, grpname, contacts, slist };
}
const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: green[600],
    },
    "&$indeterminate": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const headCells = [
  { id: "sno", numeric: true, disablePadding: false, label: "S. NO." },
  { id: "grpname", numeric: false, disablePadding: true, label: "GROUP NAME" },
  {
    id: "contacts",
    numeric: true,
    disablePadding: false,
    label: "NO. OF CONTACTS",
  },
  { id: "slist", numeric: true, disablePadding: false, label: "SHOW LIST" },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <GreenCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "15px",
      paddingBottom: "10px",
    },
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 50%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      {/* <Input
      className="searchcontact"
        id="input-with-icon-adornment"
        label="Outlined" variant="outlined"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      /> */}
      <Typography color="inherit" variant="subtitle1" component="div">
        {numSelected} selected groups
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,

    borderRadius: "5px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EmailContactGroupsTable() {
  const classes = useStyles();
  const {
    contactGroups,
    contacts,
    selectedGroups,
    setSelectedGroups,
    selectedContactsForEmailCampaign,
    setSelectedContactsForEmailCampaign,
    contactsByGroupName,
    contactsCountByGroupName,
  } = useContext(appContext);
  //const [selected, setSelected] = useState(contactGroups.map((n) => n.name));
  const [currentContactGroup, setCurrentContactGroup] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleEditContactGroup = (cg) => {
    setCurrentContactGroup(cg);
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
    setCurrentContactGroup(null);
  };
  const [openAddContactsModal, setOpenAddContactsModal] = useState(false);
  const handleAddContacts = () => {
    setOpenAddContactsModal(true);
  };
  const handleAddContactsModalClose = () => {
    setOpenAddContactsModal(false);
  };

  const rows = contactGroups
    .filter((allcg) => allcg.contactCount > 0)
    .map((cg, index) =>
      createData(
        index + 1,
        cg.name,
        cg.contactCount ? cg.contactCount : 0,
        <IconButton
          title="Edit Contact"
          onClick={() => {
            handleEditContactGroup(cg);
          }}
        >
          <VisibilityIcon />
        </IconButton>
      )
    );

  // if (emailContactType === "allcontacts") {
  //   rows.push(
  //     createData(
  //       rows.length + 1,
  //       "Previous Donors",
  //       talContacts ? talContacts.length : 0,
  //       <IconButton
  //         title="Edit Contact"
  //         onClick={() => {
  //           handleEditContactGroup("Previous Donors");
  //         }}
  //       >
  //         <VisibilityIcon />
  //       </IconButton>
  //     )
  //   );
  // } else if (emailContactType === "prevdonors") {
  //   rows.length = 0;
  //   rows.push(
  //     createData(
  //       1,
  //       "Previous Donors",
  //       talContacts ? talContacts.length : 0,
  //       <IconButton
  //         title="Edit Contact"
  //         onClick={() => {
  //           handleEditContactGroup("Previous Donors");
  //         }}
  //       >
  //         <VisibilityIcon />
  //       </IconButton>
  //     )
  //   );
  // }

  useEffect(() => {
    updateGroupsandCount();
  }, [selectedContactsForEmailCampaign]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let scec = [];
      let contactIds = [];
      const newSelected = contactGroups.map((n) => n.name);
      // if (emailContactType === "prevdonors")
      //   newSelected.push("Previous Donors");
      newSelected.forEach((newSelect) => {
        contactIds = contactsByGroupName[newSelect]
          .filter((contact) => validator.isEmail(contact.email))
          .map((contact) => contact._id);
        scec = [...scec, ...contactIds];
      });
      scec = scec.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) === index;
      });
      setSelectedContactsForEmailCampaign(scec);
    } else {
      setSelectedContactsForEmailCampaign([]);
    }
    updateGroupsandCount();
  };

  const handleClick = (event, grpname) => {
    const contactIds = contactsByGroupName[grpname]
      .filter((contact) => validator.isEmail(contact.email))
      .map((contact) => contact._id);
    if (event.target.checked) {
      let scec = [...selectedContactsForEmailCampaign, ...contactIds];
      scec = scec.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) === index;
      });
      setSelectedContactsForEmailCampaign(scec);
    } else {
      setSelectedContactsForEmailCampaign(
        selectedContactsForEmailCampaign.filter(
          (contactid) => !contactIds.includes(contactid)
        )
      );
    }
    updateGroupsandCount();
  };

  const updateGroupsandCount = () => {
    for (let group in contactsByGroupName) {
      contactsCountByGroupName[group] = 0;
    }
    selectedContactsForEmailCampaign.forEach((id) => {
      for (let group in contactsByGroupName) {
        if (group === "Previous Donors") {
          contactsByGroupName[group].forEach((item) => {
            if (item.donationId === id) {
              contactsCountByGroupName[group] =
                contactsCountByGroupName[group] + 1;
            }
          });
        } else {
          contactsByGroupName[group].forEach((item) => {
            if (item._id === id) {
              contactsCountByGroupName[group] =
                contactsCountByGroupName[group] + 1;
            }
          });
        }
      }
    });
    setSelectedGroups([]);
    let grps = [];
    for (let group in contactsCountByGroupName) {
      if (contactsCountByGroupName[group] > 0) grps.push(group);
    }
    setSelectedGroups(grps);
  };
  const isSelected = (grpname) => selectedGroups.indexOf(grpname) !== -1;
  const selectedGroupsCount = () => {
    let index = selectedGroups.indexOf("Previous Donors");
    if (index === -1) return selectedGroups.length;
    else return selectedGroups.length - 1;
  };
  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <div>
          <EnhancedTableToolbar numSelected={selectedGroups.length} />
        </div>
        {contacts.length > 0 && (
          <>
            <TableContainer
              style={{ border: "1px solid #E5E5E5", borderRadius: "5px" }}
            >
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selectedGroupsCount()}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.grpname);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow role="checkbox">
                        <TableCell
                          hover
                          padding="checkbox"
                          onClick={(event) => handleClick(event, row.grpname)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.grpname}
                          selected={isItemSelected}
                        >
                          <GreenCheckbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell align="center">{row.sno}</TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.grpname}
                        </TableCell>
                        <TableCell align="center">{row.contacts}</TableCell>
                        <TableCell align="center">{row.slist}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              className="save-button"
              style={{ textAlign: "right", padding: "10px" }}
            >
              <Button
                onClick={handleAddContacts}
                variant="contained"
                style={{ background: "#EF6C00", color: "white" }}
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                aria-label="add contacts"
              >
                Add Contacts
              </Button>
            </div>
          </>
        )}
        {contacts.length === 0 && (
          <TableContainer
            style={{
              border: "1px solid #E5E5E5",
              borderRadius: "5px",
              height: "245px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography color="inherit" variant="subtitle1" component="div">
              No contacts added
            </Typography>
            <Button
              onClick={handleAddContacts}
              variant="contained"
              className={classes.button}
              startIcon={<AddCircleOutlineIcon />}
              aria-label="add contacts"
            >
              Add Contacts
            </Button>
          </TableContainer>
        )}
      </div>
      <EmailAddContactsModal
        openModal={openAddContactsModal}
        onModalClose={handleAddContactsModalClose}
        onContactGroupSave={() => {
          setOpenAddContactsModal(false);
        }}
      ></EmailAddContactsModal>
      <EmailGroupContactsSelectionModal
        currentContactGroup={currentContactGroup}
        updateGroupsandCount={updateGroupsandCount}
        openModal={openModal}
        onModalClose={handleModalClose}
        onContactGroupSave={() => {
          setOpenModal(false);
        }}
      ></EmailGroupContactsSelectionModal>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getChatRooms } from "../../utils/api";
import { makeStyles } from "@material-ui/core/styles";


import { Box, TablePagination, Typography } from "@material-ui/core";
import { appContext } from "../../App";
import { withRouter } from "react-router";
import Sidemenu from "../SideMenu/Sidemenu";
import Row from "./Row";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  table_tab: {
    width: "100%",
    "& td:last-child": {
      padding: "0",
    },
  },

  rightmenu: {
    "& Button": {
      marginLeft: "5px",
      textAlign: "right",
    },

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  livestreamings: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    lineHeight: "30px",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      height: "12px",
      backgroundColor: "#31A93D",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "var(--tal_primary)",
      fontSize: "22px",
      fontWaite: "bold",
      marginRight: "5px",
    },
    "& h4": {
      fontSize: "16px",
      color: "#575757",
      margin: "0",
      lineHeight: "35px",
    },

    "&  Button:nth-of-type(1)": {
      border: "var(--tal_primary) 2px solid",
      backgroundColor: "white",
      color: "var(--tal_primary)",
      borderRadius: "5px",
      padding: "5px",
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "#CD2729",
      color: "white",
    },
  },
}));

function ChatRoom(props) {
  const { chats, setChats } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleChatRooms = (id) => {
    props.history.push({
      pathname: "/createAudioRoom",
      state: {
        selectedId: id,
      },
    });
  };

  const loadChatRoom = () => {
    setIsLoading(true);
    getChatRooms()
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          let data = response.data;
          if (props.id)
            data = data.filter((chat) => props.id === chat.ownerId._id);
          const list = data.map((i) =>
            moment(i.startTime).format("DD,MMM YYYY")
          );
          const uniqueList = Array.from(new Set(list));
          const merged = uniqueList.map((c) => {
            return { startTime: c, chats: [] };
          });

          data.forEach((d) => {
            merged
              .find(
                (g) => g.startTime === moment(d.startTime).format("DD,MMM YYYY")
              )
              .chats.push(d);
          });

          setChats(merged);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Streamings:" + error);
      });
  };

  useEffect(() => {
    setChats("");
    loadChatRoom();
  }, [props.id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const classes = useStyles();
  return (
    <Box component="div" className={classes.table_tab}>
      <TableContainer component={Paper} className="au_table">
        <Table aria-label="all streamings table" style={{ width: "1230px" }}>
          {/* <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Moderator</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead> */}
          <TableBody>
            {chats &&
              chats.length > 0 &&
              chats
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => <Row key={row.startTime} row={row} />)}
            {isLoading && (
              <TableRow align="center">
                <TableCell colSpan="10">
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      padding: "10px 0",
                    }}
                  >
                    Loading...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {chats && chats.length === 0 && !isLoading && (
              <TableRow align="center">
                <TableCell colSpan="10">
                  <Typography
                    style={{
                      textAlign: "center",
                      margin: "25px 0",
                    }}
                  >
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={chats.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ width: "1230px" }}
        />
      </TableContainer>
    </Box>
  );
}
export default withRouter(ChatRoom);

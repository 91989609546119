import React, { useContext, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import useFormValidation from "../../hooks/useFormValidation";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { inviteTALLeader } from "../../utils/api";
import { appContext } from "../../App";
import validateInvitation from "./ValidateInvitation";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Container from "@material-ui/core/Container";
import Sidemenu from "../SideMenu/Sidemenu";
import cssstyles from "./InvitingATALLeader.module.scss";

const useStyles = makeStyles((theme) => ({
  Paper: {
    minHeight: "100vh",
  },

  icons: {
    display: "flex",
    gridGap: "25px",
    justifyContent: "center",

    "& img": {
      cursor: "pointer",
    },
  },
  sign_right: {
    padding: "2rem",
    minHeight: "100vh",

    "& p:last-child": {
      textAlign: "left",
      "& strong": {
        cursor: "pointer",
      },
    },
    "& section": {
      padding: "2rem",

      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0 0",
      },

      "& hr": {
        position: "relative",
        top: "20px",
      },

      "& label": {
        display: "block",
        color: "#696F79",
        padding: "1rem 0",
      },

      "& h4": {
        fontWeight: "800",
        // textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        marginBottom: "20px",
        color: "#273d48",
        fontSize: "32px",
        textAlign: "center",
      },

      "& small": {
        color: "#BABABA",
        display: "block",
        position: "relative",
        backgroundColor: "white",
        padding: "0 5px 0 5px",
        left: "0",
        right: "0",
        top: "-4px",
        width: "220px",
        margin: "0 auto",
        fontSize: "14px",
      },

      "& FormControl": {
        display: "block",
      },
    },
  },
}));

const InvitingATALLeader = (props) => {
  const { userLocaleInfo, authUser } = useContext(appContext);
  const classes = useStyles();

  const initialState = {
    email: "",
    secondaryEmail: "",
    firstName: "",
    lastName: "",
    phone: "",
  };
  const [phone, setPhone] = useState(initialState.phone || null);
  const [otherErrors, setOtherErrors] = useState({});
  const [id, setId] = useState({});

  const inviteHandler = () => {
    const finalValues = values;
    //validate the phone input
    if (phone && !isPossiblePhoneNumber(phone)) {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "Please enter valid phone number",
        };
      });
      return;
    } else {
      finalValues.phone = phone;
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "",
        };
      });
    }
    inviteTALLeader(finalValues)
      .then((response) => {
        if (response.statusCode === 200) {
          setId(response.data._id);
          toastr.success("Invitation sent successfully");
          setOtherErrors({});
          setValues({
            email: "",
            secondaryEmail: "",
            firstName: "",
            lastName: "",
            phone: "",
          });
          setPhone("");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          setValues({
            email: "",
            secondaryEmail: "",
            firstName: "",
            lastName: "",
            phone: "",
          });
          setOtherErrors((currentValues) => {
            return {
              ...currentValues,
              title:
                "This account already accepted the invitation and has TALLeader access",
            };
          });
          toastr.error(
            "This account already accepted the invitation and has TALLeader access"
          );
        } else {
          toastr.error(
            error.response && error.response.data && error.response.data.message
          );
          setValues({
            email: "",
            secondaryEmail: "",
            firstName: "",
            lastName: "",
            phone: "",
          });
          setPhone("");
        }
      });
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  const wholeValidateInvite = () => {
    const errors = validateInvitation(values);
    const otherErrors = {};

    if (phone && !isPossiblePhoneNumber(phone)) {
      otherErrors.phone = "Please enter valid Phone Number";
    }
    setOtherErrors(otherErrors);
    return errors;
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, wholeValidateInvite, inviteHandler);
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="barnav">
        <Container MaxWidth="lg">
          <div className="admin-head">
            {authUser && (
              <Button onClick={handleCloseSideMenu}>
                {!closeSideMenu && <MenuIcon />}
                {closeSideMenu && <ArrowBackIcon />}
              </Button>
            )}
            <h2>Invite someone to join TALLeaders Circle</h2>
          </div>
          <Container maxWidth="lg">
            <div className={ cssstyles.menu_container}>
              {closeSideMenu && (
                <div style={{ marginRight: "10px" }}>
                  <Sidemenu />
                </div>
              )}
              <Grid item md={6} sm={12} xs={12}>
                {/* <Box component="h4">
                  Invite someone to join TALLeaders Circle sss
                </Box> */}
                <Box component="article" my={2}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <div className="names">
                          <TextField
                          
                          className={cssstyles.plasma_input
                           + " " + `input-field request-title
                      ${
                           errors.firstName
                          ? cssstyles.bg_red
                          : values.firstName
                          ? cssstyles.bg_green
                          : cssstyles.bg_normal
                      }
                      `}
                            variant="outlined"
                            fullWidth
                            label="First Name"
                            placeholder="First Name *"
                            name="firstName"
                            onChange={changeHandler}
                            onKeyPress={(e) => handleEnterClick(e)}
                            value={values.firstName}
                            autoComplete="off"
                            required
                          />
                          {errors && (
                            <div className={cssstyles.custom_error}>{errors.firstName}</div>
                          )}
                        </div>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <div className="names">
                          <TextField
                             className={cssstyles.plasma_input
                              + " " + `input-field request-title
                         ${
                          errors && errors.lastName
                             ? cssstyles.bg_red
                             : values.lastName
                             ? cssstyles.bg_green
                             : cssstyles.bg_normal
                         }
                         `}
                            variant="outlined"
                            fullWidth
                            label="Last Name"
                            placeholder="Last Name *"
                            name="lastName"
                            onChange={changeHandler}
                            value={values.lastName}
                            onKeyPress={(e) => handleEnterClick(e)}
                            autoComplete="off"
                            required
                          />
                          {errors && errors.lastName && (
                            <div className={cssstyles.custom_error}>{errors.lastName }</div>
                          )}
                        </div>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Box
                          component="div"
                          border="1px solid #ccc"
                          borderRadius="5px"
                          p={2}
                        >
                          <PhoneInput
                            variant="outlined"
                            fullWidth
                            placeholder=" Phone number"
                            name=" Phone number "
                            defaultCountry={
                              userLocaleInfo && userLocaleInfo.country_code
                            }
                            value={phone}
                            onChange={setPhone}
                            onKeyPress={(e) => handleEnterClick(e)}
                          />
                        </Box>

                        {otherErrors && otherErrors.phone && (
                          <div className="custom-error">
                            {otherErrors.phone}
                          </div>
                        )}
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          className={`full-width border-radius signup-input ${
                            errors.secondaryEmail && "error-text"
                          }`}
                          fullWidth
                          label="Personal email address"
                          placeholder="Personal email address"
                          name="secondaryEmail"
                          variant="outlined"
                          onChange={changeHandler}
                          onKeyPress={(e) => handleEnterClick(e)}
                          value={values.secondaryEmail}
                          autoComplete="off"
                        />
                        {errors && errors.secondaryEmail && (
                          <div className="custom-error">
                            {errors.secondaryEmail}
                          </div>
                        )}
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          className={cssstyles.plasma_input
                            + " " + `input-field request-title
                       ${
                        errors && errors.email
                           ? cssstyles.bg_red
                           : values.email
                           ? cssstyles.bg_green
                           : cssstyles.bg_normal
                       }
                       `}
                          fullWidth
                          label="@touchalife.org email address"
                          placeholder="@touchalife.org email address *"
                          name="email"
                          variant="outlined"
                          onChange={changeHandler}
                          onKeyPress={(e) => handleEnterClick(e)}
                          value={values.email}
                          autoComplete="off"
                          required
                        />
                        {errors && errors.email && (
                          <div className={cssstyles.custom_error}>{errors.email }</div>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Button
                  fullWidth
                  className="tal_primary_bg "
                  variant="contained"
                  type="submit"
                  onClick={submitHandler}
                >
                  Invite
                </Button>
              </Grid>
            </div>
          </Container>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(InvitingATALLeader);

import React, { useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
import toastr from "toastr";
import firebase from "../../firebase/firebase";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputBase,
  IconButton,
  Tooltip,
  Grid,
  Container,
  ListItemText,
} from "@material-ui/core";
import PodcastThumbnail from "./PodcastThumbnail";
import { makeStyles } from "@material-ui/core/styles";
import SideMenu from "../SideMenu/Sidemenu";
import "./podcast.scss";
import { appContext } from "../../App";
import "./styles.scss";
import "../liveStreaming/styles.scss";
import VideoPlayer from "./VideoPlayer";
import Audio from "../liveStreaming/Audio";
import Box from "@material-ui/core/Box";
import { getMediaDocuments, getPodcasts, getTALRadioCategories } from "../../utils/api";
import { Typography } from "@material-ui/core";
import { ArrowBack, ArrowRightOutlined } from "@material-ui/icons";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { SearchRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
  },
}));

const Podcast = () => {
  const { authUser, talmediaLanguage, setTalmediaLanguage } =
    useContext(appContext);
  const [path, setPath] = useState("");
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [channel, setChannel] = useState("telugu");
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pRef = useRef(null);
  const nRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [category, setCategory] = useState("all");
  const [Categories, setCategories] = useState([]);

  const loadCategories = () => {
    getTALRadioCategories("")
    .then((response) => {
      if(response && response.data){
        setCategories(response.data);
      } 
      })
    .catch((error) => {
      toastr.error("Problem in fetching Categories:" + error);
    })
  }

  useEffect(() => {
    loadCategories()
  },[])

  const loadMediaDocumentsKeyword = (keyword) => {
    setIsLoading(true);
    getPodcasts(
      (keyword && keyword.length > 0) || talmediaLanguage === "all"
        ? ""
        : talmediaLanguage,
        category === "all"
        ? ""
        : category,
      rowsPerPage,
      offset,
      keyword
    )
      .then((response) => {
        if (response) {
          setDocuments([...response.data]);
          setTotalCountOfRecords(response.totalCountOfRecords);
          // setOffset(offset + rowsPerPage);
        } else {
          setTotalCountOfRecords(response.totalCountOfRecords);
          // setOffset(0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Podcast:" + error);
      });
  };

  useEffect(() => {
    loadMediaDocumentsKeyword(keyword);
  }, [talmediaLanguage,category, rowsPerPage, page]);

  const songsSearch = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      setKeyword(event && event.target.value);
      setIsSearchMode(true);
    }
  };

  useEffect(() => {
    if (keyword && isSearchMode) {
      setOffset(0);
      setPage(0);
      loadMediaDocumentsKeyword(keyword);
    }
  }, [keyword, isSearchMode]);

  const onClosePlayVideo = () => {
    setDocument({});
    setIsPlayingVideo(false);
  };

  const changeCategoryHandler =(event) => {
    setCategory(event.target.value);
  }
  const changeHandler = (e) => {
    setPage(0);
    setDocuments([]);
    setOffset(0);
    setTalmediaLanguage(e.target.value);
  };
  const changeRowsPerPage = (e) => {
    setPage(0);
    setDocuments([]);
    setOffset(0);
    setRowsPerPage(e.target.value);
  };
  const handlePageNext = (e) => {
    setPage(page + 1);
    nRef.current.blur();
    window.scrollTo(0, 0);
    setOffset(offset + rowsPerPage);
  };
  const handlePagePrevious = (e) => {
    setPage(page - 1);
    pRef.current.blur();
    window.scrollTo(0, 0);
    setOffset(offset - rowsPerPage);
  };
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const classes = useStyles();

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  return (
    <div className="podcast">
      {/* {authUser && (
        <Button
          onClick={handleCloseSideMenu}
          style={{
            color: "#ef6c00",
            minWidth: "50px",
            height: "50px",
            borderRadius: "0px 50px 50px 0",
            marginRight: "10px",
            background: "white",
            position: "fixed",
            zIndex: "3",
            top: "40%",
            boxShadow: "0 0 5px #0000003b",
          }}
        >
          {!closeSideMenu && <MenuIcon />}
          {closeSideMenu && <ArrowBackIcon />}
        </Button>
      )} */}

      <div className="menu-container" style={{ display: "inline-block" }}>
        {authUser && closeSideMenu && (
          <div style={{ marginRight: "10px" }}>
            <SideMenu />
          </div>
        )}

        <div>
          <div className="podcast-title">
            <p>
              Start your day with a concise round-up of what you need to know
              today.
            </p>

            <Audio />
          </div>

          <Box
            p={1}
            className="pod-bar"
          >
            <Container maxWidth="lg">
              <Grid container>
                <Grid items sm={3} xs={12}>
            <Box
              component="h3"
              m={0}
              alignSelf="center"
              color="var(--tal_primary) "
            >
              PODCAST
            </Box>
            </Grid>
            <Grid items sm={3} xs={12}>
            <FormControl className="slct-pod">
              <InputLabel style={{ marginLeft: "18px" }}>
                Select Language
              </InputLabel>
              <Select
                variant="outlined"
                name="language"
                value={talmediaLanguage}
                label="Select Language"
                onChange={changeHandler}
                style={{ width: "100%" }}
              >
                <MenuItem value="all">All Languages</MenuItem>
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="hindi">Hindi</MenuItem>
                <MenuItem value="telugu">Telugu</MenuItem>
                <MenuItem value="none">Items not tagged to any Language</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid items sm={3} xs={12}>
              <FormControl className="slct-pod">
                <InputLabel style={{marginLeft:"18px"}} >
                  Select Category
                </InputLabel>
                <Select
                  variant="outlined"
                  name="Categories"
                  value={category}
                  label="Select category"
                  onChange={changeCategoryHandler}
                  style={{width: "100%"}}
                >
                  <MenuItem
                    key="all"
                    value="all"
                  >
                    <ListItemText primary="All Categories" />
                  </MenuItem>
                  {Categories && 
                    Categories.map((categoryItem) => {
                      return (
                        <MenuItem value={categoryItem._id}>
                          {categoryItem.name}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid items sm={3} xs={12}>
            <Box whiteSpace="noWrap" className="pod-search">
              <div>
                <SearchRounded style={{ margin: "0 4px", height: "46px" }} />
                <InputBase
                  placeholder="Enter search keyword"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onKeyPress={songsSearch}
                />
              </div>
              {/* {keyword && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  className="search_btn"
                >
                  <Tooltip title="Clear Search Results">
                    <CloseIcon onClick={loadMediaDocuments} />
                  </Tooltip>
                </IconButton>
              )} */}
            </Box>
            </Grid>  
            </Grid>
            </Container>
          </Box>

          <Box className="pod-results">
            {isSearchMode && (
              <p
                className="result"
                style={{ marginLeft: "20px", float: "right" }}
              >
                Search Results for
                <span
                  style={{
                    // boxShadow: "rgb(239 239 239) 0px 0px 7px",
                    // borderRadius: "5px",
                    padding: "3px 10px 5px 10px",
                    marginLeft: "10px",
                    fontWeight: " bold",
                    color: "#686868",
                    background: "#f8f8f8",
                  }}
                >
                  {keyword}
                </span>
                <IconButton
                  edge="start"
                  aria-label="close"
                  style={{
                    padding: "3px",
                    color: "rgb(205, 38, 39)",
                    position: "relative",
                    top: "-2px",
                    margin: "0 0 0 10px",
                    border: "1px solid #ededed",
                  }}
                >
                  <Tooltip title="Clear Search Results">
                    <CloseIcon
                      onClick={() => {
                        setKeyword("");
                        setIsSearchMode(false);
                        loadMediaDocumentsKeyword("");
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </p>
            )}
          </Box>
          {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
          <div className="podcast-recent-upload">
            {!isLoading && documents &&
              documents.length > 0 &&
              documents
                //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((document, index) => {
                  return (
                    <PodcastThumbnail
                      key={index}
                      document={document}
                      name={document.name}
                      fileName={document.fileName}
                      thumbnailUrl={
                        Array.isArray(document.thumbnailImageUrl)
                          ? document.thumbnailImageUrl[0]
                          : document.thumbnailImageUrl
                      }
                      note={document.description}
                    />
                  );
                })}
            <VideoPlayer
              isOpen={isPlayingVideo}
              onClose={onClosePlayVideo}
              name={document && document.name}
              url={document && document.url && document.url[0]}
              note={document && document.description}
              thumbnailUrl={document && document.thumbnailImageUrl}
            />
          </div>
          <Box
            position="sticky"
            bgcolor="white"
            boxShadow="0 0 4px #c6c6c6"
            p={1}
            display="flex"
            justifyContent="flex-end"
            flexWrap="wrap"
            alignItems="center"
          >
            <FormControl style={{ width: "125px" }}>
              <InputLabel style={{ marginLeft: "18px" }}>
                Rows Per Page
              </InputLabel>
              <Select
                variant="outlined"
                name="language"
                value={rowsPerPage}
                label="Select Language"
                onChange={changeRowsPerPage}
                style={{ width: "100%" }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <Typography style={{ marginLeft: "4px" }}>
              {page * rowsPerPage + 1} -{" "}
              {page * rowsPerPage + rowsPerPage < totalCountOfRecords
                ? page * rowsPerPage + rowsPerPage
                : totalCountOfRecords}{" "}
              of {totalCountOfRecords}
            </Typography>{" "}
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: "10px",
                fontsize: "5px",
                background: "#f35a57",
              }}
              disabled={page === 0}
              ref={pRef}
              onClick={handlePagePrevious}
            >
              <ChevronLeftIcon /> Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              ref={nRef}
              style={{ marginLeft: "10px", background: "#f35a57" }}
              disabled={page * rowsPerPage + rowsPerPage > totalCountOfRecords}
              onClick={handlePageNext}
            >
              Next <ChevronRightIcon />
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Podcast;

import React, { useState, useContext, useEffect, Fragment } from "react";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import RadioButton from "@material-ui/core/Radio";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@material-ui/core";
import "../donationRequest/DonationRequestForm.scss";
import { appContext } from "../../App";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import useFormValidation from "../../hooks/useFormValidation";
import { getDonationDetails, updateWithdrawRequest } from "../../utils/api";
import NewAddress from "../common/NewAddress";
import ValidateWithdrawalForm from "./ValidateWithdrawalForm";
import AddDocument from "../common/AddDocument";

const DonationWithdrawalForm = (props) => {
  const { authUser, isSuperAdmin, isAdmin, isVolunteer, region } =
    useContext(appContext);
  const initialState = {
    requestType: "",
    units: "USD",
    status: "0",
  };
  const [id, setId] = useState("");
  const [address, setAddress] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawType, setWithDrawType] = useState("online");
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [availableAmount, setAvailableAmount] = useState("");

  const handleWithdrawal = (event) => {
    setValues((currentValues) => {
      return {
        ...currentValues,
        withdrawType: event.target.value,
      };
    });
    setWithDrawType(event.target.value);
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };
  const onSavingDocument = (message, url) => {
    toastr.success(message);
  };
  useEffect(() => {
    if (props.withdrawalRequest) {
      setAmountError("");
      setId(
        props.withdrawalRequest.userId && props.withdrawalRequest.userId._id
      );
      if (props.withdrawalRequest.address) {
        setAddress({
          line1: props.withdrawalRequest.address.line1,
          line2: props.withdrawalRequest.address.line2,
          city: props.withdrawalRequest.address.city,
          state: props.withdrawalRequest.address.state,
          zip: props.withdrawalRequest.address.zip,
          country: props.withdrawalRequest.address.country,
        });
      }
      if (props.withdrawalRequest.bankInfo) {
        setValues((currentValues) => {
          return {
            ...currentValues,
            accountNumber: props.withdrawalRequest.bankInfo.accountNumber,
            label: props.withdrawalRequest.bankInfo.label,
            routingNumber: props.withdrawalRequest.bankInfo.routingNumber,
            isDefault: props.withdrawalRequest.bankInfo.isDefault,
          };
        });
      }
      if (props.withdrawalRequest.requestId) {
        setValues((currentValues) => {
          return {
            ...currentValues,
            donated_quantity:
              props.withdrawalRequest.requestId.donated_quantity,
            quantity: props.withdrawalRequest.requestId.quantity,
            title: props.withdrawalRequest.requestId.title,
            units: props.withdrawalRequest.requestId.units,
          };
        });
      }
      setWithDrawType(props.withdrawalRequest.requestType);
      setValues((currentValues) => {
        return {
          ...currentValues,
          amount: props.withdrawalRequest.amount,
          status: props.withdrawalRequest.status,
          notes: props.withdrawalRequest.notes,
          adminNotes: props.withdrawalRequest.adminNotes,
          payeeName: props.withdrawalRequest.payeeName,
        };
      });
    }
  }, [props.withdrawalRequest]);

  useEffect(() => {
    if (
      props.withdrawalRequest.requestId &&
      props.withdrawalRequest.requestId._id
    ) {
      const requestId =
        props.withdrawalRequest.requestId &&
        props.withdrawalRequest.requestId._id;

      getDonationDetails(requestId)
        .then((response) => {
          const { donated_quantity, withdrawAmount } =
            response && response.data;
          setAvailableAmount(donated_quantity - withdrawAmount);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  }, [props.withdrawalRequest.requestId]);

  useEffect(() => {
    setValues((currentValues) => {
      return {
        ...currentValues,
        withdrawType,
      };
    });
  }, []);

  const saveWithdrawalRequest = () => {
    const finalValues = {};
    setAmountError("");
    if (!values.amount || values.amount <= 0) {
      setAmountError("Please enter valid amount");
      return;
    } else if (values.amount > availableAmount) {
      setAmountError(`Please enter amount less than ${availableAmount}`);
      return;
    }
    finalValues.payeeName = values.payeeName;
    finalValues.amount = values.amount;
    finalValues.notes = values.notes;
    finalValues.requestType = withdrawType;
    finalValues.adminNotes = values.adminNotes;
    finalValues.status = values.status;
    if (withdrawType === "online") {
      const bankInfo = {};

      bankInfo.label = values.label;
      bankInfo.accountNumber = values.accountNumber;
      bankInfo.routingNumber = values.routingNumber;
      bankInfo.isDefault = values.isDefault;
      finalValues.bankInfo = bankInfo;
    } else {
      if (address) {
        const obj = {};
        obj.line1 = address.line1;
        obj.line2 = address.line2;
        obj.city = address.city;
        obj.state = address.state;
        obj.zip = address.zip;
        obj.country = address.country;
        finalValues.address = obj;
      } else {
        errors.address = "Please provide an address for this request";
      }
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      updateWithdrawRequest(finalValues, props.withdrawalRequest._id)
        .then((response) => {
          toastr.success("Withdrawal Request is updated successfully.");
          props.onSave(response);
        })
        .catch((error) => {
          toastr.error(error);
        });
    }
  };

  const handleChangeAddress = (newAddress) => {
    setAddress(newAddress);
  };

  const onConfirm = () => {
    saveWithdrawalRequest();
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, ValidateWithdrawalForm, onConfirm);

  return (
    <div className="make-req-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="make-req-container"
        open={props.open}
        onClose={props.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className="request-container ">
            <div className="req-main-title drop-box">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{ color: "rgb(205, 38, 39)" }}
                >
                  Withdrawal Request
                </Typography>
                <span
                  className="close-modal drop-box"
                  onClick={props.close}
                  style={{
                    padding: "10px",
                    borderRadius: "100%",
                    height: "28px",
                    lineHeight: "0.4",
                    cursor: "pointer",
                  }}
                >
                  X
                </span>
              </div>
            </div>
            <form
              noValidate
              autoComplete="off"
              className="request-form cash-request-from"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  <TextField
                    type="number"
                    required
                    className="input-field cash"
                    variant="outlined"
                    label="Amount"
                    name="amount"
                    value={values.amount}
                    onChange={changeHandler}
                  />
                  <div>
                    {amountError && (
                      <Typography className="custom-error">
                        {amountError}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Select
                    name="cashUnit"
                    value={values.units}
                    className="input-field currency"
                    disabled
                  >
                    <MenuItem value={values.units}>{values.units}</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <div className="withdrawal-container">
                <Grid container spacing={3}>
                  <Grid item sm={3} xs={6}>
                    <div
                      className={
                        withdrawType === "online"
                          ? "withdrawal-selected"
                          : "withdrawal"
                      }
                    >
                      <RadioButton
                        value="online"
                        label="online"
                        onChange={handleWithdrawal}
                        checked={withdrawType === "online"}
                        style={{
                          color:
                            withdrawType === "online" ? "#f44336" : "#2D3748",
                        }}
                      />
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "19px",
                          textAlign: "center",
                          color:
                            withdrawType === "online" ? "#f44336" : "#2D3748",
                        }}
                        gutterBottom
                      >
                        Bank Account
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <div
                      className={
                        withdrawType === "cheque"
                          ? "withdrawal-selected"
                          : "withdrawal"
                      }
                    >
                      <RadioButton
                        value="cheque"
                        label="cheque"
                        onChange={handleWithdrawal}
                        checked={withdrawType === "cheque"}
                        style={{
                          color:
                            withdrawType === "cheque" ? "#f44336" : "#2D3748",
                        }}
                      />
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "19px",
                          textAlign: "center",
                          color:
                            withdrawType === "cheque" ? "#f44336" : "#2D3748",
                        }}
                        gutterBottom
                      >
                        Cheque Payment
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {withdrawType === "online" ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <InputLabel className="secFormLabel">Payee Name</InputLabel>
                    <TextField
                      type="text"
                      className="profile-fields user name"
                      variant="outlined"
                      style={{ width: "100%" }}
                      name="payeeName"
                      // disabled={!editMode}
                      value={values.payeeName}
                      onChange={changeHandler}
                    />
                    {errors && errors.payeeName && (
                      <Typography className="custom-error">
                        {errors.payeeName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className="noEdit">
                    <InputLabel className="secFormLabel">
                      Account Number
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      className="profile-fields password"
                      style={{ width: "100%" }}
                      name="accountNumber"
                      // disabled={!editMode}
                      onChange={changeHandler}
                      type="number"
                      value={values.accountNumber}
                    />
                    {errors && errors.accountNumber && (
                      <Typography className="custom-error">
                        {errors.accountNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className="noEdit">
                    <InputLabel className="secFormLabel">
                      {region && region._id === "IN"
                        ? "IFSC Code"
                        : region && region._id === "US" && "Routing Number"}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      style={{ width: "100%" }}
                      name="routingNumber"
                      //  disabled={!editMode}
                      // value={accountValues && accountValues.routingNumber}
                      onChange={changeHandler}
                      className="profile-fields changepassword"
                      type="text"
                      value={values.routingNumber}
                    />
                    {errors && errors.routingNumber && (
                      <Typography className="custom-error">
                        {errors.routingNumber}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <InputLabel className="secFormLabel">
                        Cheque Name (In Favour of)
                        <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <TextField
                        type="text"
                        className="profile-fields user name"
                        variant="outlined"
                        style={{ width: "100%" }}
                        name="payeeName"
                        // disabled={!editMode}
                        value={values.payeeName}
                        onChange={changeHandler}
                      />
                      {errors && errors.payeeName && (
                        <Typography className="custom-error">
                          {errors.payeeName}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container className="address">
                    <Grid item xs={12}>
                      <InputLabel
                        style={{ margin: "10px 0 0 0" }}
                        className="secFormLabel"
                      >
                        Address
                      </InputLabel>
                      <NewAddress
                        value={address}
                        onChange={handleChangeAddress}
                        //disabled={!editMode}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: "relative" }}>
                  <InputLabel className="secFormLabel">User Notes</InputLabel>
                  <TextareaAutosize
                    rowsMin={5}
                    variant="outlined"
                    className="input-field brief"
                    name="notes"
                    value={values.notes}
                    onChange={changeHandler}
                  />
                  <span className="admin-title-length"></span>
                </Grid>
              </Grid>
              {(isSuperAdmin || isAdmin) && (
                <Grid item xs={12} style={{ position: "relative" }}>
                  <InputLabel className="secFormLabel">Admin Notes</InputLabel>
                  <TextareaAutosize
                    rowsMin={5}
                    type="text"
                    className="input-field brief"
                    variant="outlined"
                    style={{ width: "100%" }}
                    name="adminNotes"
                    value={values.adminNotes}
                    onChange={changeHandler}
                  />
                  <span className="admin-title-length"></span>
                </Grid>
              )}
              <div>
                <Button
                  className="document-btn"
                  onClick={() => setShowingAddDocumentModal(true)}
                  style={{
                    width: "60%",
                    font: "500 12px Roboto",
                    padding: "20px 30px",
                    margin: "10px 5px",
                    background: "transparent",
                    color: "#283E4A",
                    border: "1px solid #283E4A",
                  }}
                >
                  <DescriptionIcon style={{ fontSize: 14, marginRight: 6 }} />{" "}
                  View supporting document(s)
                </Button>
              </div>
              {(isSuperAdmin || isAdmin) && (
                <Fragment>
                  <div>
                    <Typography>Status</Typography>
                    <Select
                      name="status"
                      value={values.status}
                      className="input-field currency"
                      style={{ width: "100%" }}
                      onChange={changeHandler}
                    >
                      <MenuItem value="0">Pending</MenuItem>
                      <MenuItem value="1">Approved</MenuItem>
                      <MenuItem value="2">Completed</MenuItem>
                      <MenuItem value="-1">Rejected</MenuItem>
                      <MenuItem value="-2">Incomplete</MenuItem>
                      <MenuItem value="-3">On Hold</MenuItem>
                    </Select>
                  </div>
                </Fragment>
              )}
              <div style={{ display: "grid", placeContent: "center" }}>
                <Button
                  style={{ width: "200px" }}
                  className="submit-btn"
                  onClick={submitHandler}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={id}
        collection="User"
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
    </div>
  );
};

export default withRouter(DonationWithdrawalForm);

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputBase,
  Paper,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { SearchRounded } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { withRouter } from "react-router";
import toastr from "toastr";
import { appContext } from "../../App";
import { getActivityLog } from "../../utils/api";
import { DATE_FORMAT, DATE_TIME_FORMAT, HOME_PAGE } from "../../utils/utils";
import Sidemenu from "../SideMenu/Sidemenu";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
  },
}));

const UserActivityLog = (props) => {
  const { authUser, isAdmin, isVolunteer, isSuperAdmin } =
    useContext(appContext);
  const [activityLog, setActivityLog] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [activityFilter, setActivityFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [startDateFormat, setStartDateFormat] = useState(
    moment().subtract(7, "d").format("x")
  );
  const [endDateFormat, setEndDateFormat] = useState(moment().format("x"));
  const [focusedInput, setFocusedInput] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [query, setQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const loadActivityLog = () => {
    setIsLoading(true);
    getActivityLog(query)
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);
          setActivityLog(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const initialFilters = () => {
    setActivityFilter("");
    setEmailFilter("");
    setPage(0);
    var sDate = moment().subtract(7, "d");
    var eDate = moment();
    setStartDate(sDate);
    setEndDate(eDate);
    setStartDateFormat(sDate.format("x"));
    setEndDateFormat(eDate.format("x"));
    var queryStr = `startDate=${sDate.format("x")}&endDate=${eDate.format(
      "x"
    )}`;
    setQuery(queryStr);
  };

  useEffect(() => {
    var queryStr = `startDate=${startDateFormat}&endDate=${endDateFormat}`;
    setQuery(queryStr);
  }, []);

  useEffect(() => {
    if (query) {
      loadActivityLog(query);
    }
  }, [query]);

  const onActivityChange = (event) => {
    setActivityFilter(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmailFilter(event.target.value);
  };

  useEffect(() => {
    if (authUser) {
      if (isAdmin || isVolunteer || isSuperAdmin) {
      } else {
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  }, [authUser]);

  const handleDateChange = (fromDate, toDate) => {
    if (fromDate != null) {
      setStartDateFormat(moment(fromDate._d).format("x"));
      setStartDate(fromDate);
      setEndDate(null);
    }
    if (toDate != null) {
      setEndDateFormat(moment(toDate._d).format("x"));
      setEndDate(toDate);
    }
  };

  const classes = useStyles();

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const handleApply = () => {
    var queryStr = `email=${emailFilter}&activity=${activityFilter}&startDate=${startDateFormat}&endDate=${endDateFormat}`;
    setQuery(queryStr);
    setPage(0);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="barnav">
        <Container MaxWidth="lg">
          <div className="search-right">
            <div style={{ display: "flex" }}>
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4 className="image-title">Activity Log</h4>
            </div>
            <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
              <FormControl className={classes.mt10}>
                <DateRangePicker
                  className={classes.br}
                  displayFormat={DATE_FORMAT}
                  startDate={startDate}
                  startDateId="start_date_id"
                  endDate={endDate}
                  endDateId="end_date_id"
                  startDatePlaceholderText="From"
                  endDatePlaceholderText="To"
                  onDatesChange={({ startDate, endDate }) =>
                    handleDateChange(startDate, endDate)
                  }
                  isOutsideRange={() => false}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                  customClearIcon={true}
                />
              </FormControl>
              <div className="DateRangePickerInput__withBorder">
                <SearchRounded style={{ margin: "0 4px", height: "50px" }} />
                <InputBase
                  placeholder="Email Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  width="200px"
                  value={emailFilter}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => onEmailChange(e)}
                />
              </div>
              <div className="DateRangePickerInput__withBorder">
                <SearchRounded style={{ margin: "0 4px", height: "50px" }} />
                <InputBase
                  placeholder="Activity Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  width="200px"
                  value={activityFilter}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => onActivityChange(e)}
                />
              </div>
              <Button
                style={{ backgroundColor: "#ef6c00", color: "white" }}
                onClick={handleApply}
                ontype="submit"
              >
                Apply
              </Button>
              <Button
                style={{ backgroundColor: "#ef6c00", color: "white" }}
                onClick={initialFilters}
              >
                Clear
              </Button>
            </div>
          </div>
        </Container>
      </div>

      <Container MaxWidth="lg">
        <div className="myDonations-container">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            )}
            <div style={{ width: "100%", overflowX: "auto" }}>
              <div className="table-fixed drop-box">
                <Box className={classes.table_tab}>
                  <TableContainer component={Paper}>
                    <Table aria-label="UserActivityLog Table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>User</TableCell>
                          <TableCell>Log Message</TableCell>
                          <TableCell>ActivityEvent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activityLog &&
                          activityLog.length > 0 &&
                          activityLog
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow key={row._id}>
                                <TableCell>
                                  {moment(row.createdAt).format(
                                    DATE_TIME_FORMAT
                                  )}
                                </TableCell>
                                <TableCell>
                                  {row.userInfo &&
                                    row.userInfo.name &&
                                    `${row.userInfo.name.first_name} ${row.userInfo.name.last_name}`}
                                  <br />
                                  {row.userInfo && row.userInfo.email}
                                  <br />
                                  {row.userInfo && row.userInfo.phone}
                                </TableCell>
                                <TableCell>{row.message}</TableCell>
                                <TableCell>{row.activity}</TableCell>
                              </TableRow>
                            ))}
                        {isLoading && (
                          <p style={{ textAlign: "center" }}>Loading...</p>
                        )}
                        {!isLoading &&
                          activityLog &&
                          activityLog.length === 0 && (
                            <Typography className="no-req-text">
                              No Records Found
                            </Typography>
                          )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default withRouter(UserActivityLog);

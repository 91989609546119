import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Link,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { HighlightOff, PrintRounded } from "@material-ui/icons";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import { HOME_PAGE, formatNumber } from "../../utils/utils";
import Box from "@material-ui/core/Box";
import {
  fetchAllDonations,
  getTransactionDetails,
  getDonationRequest,
  getDonationsByFilter,
} from "../../utils/api";
import { DATE_FORMAT, TIME_FORMAT_SECONDS } from "../../utils/utils";
import Loader from "../common/Loader";
import PrintReceiptModal from "../donationDetails/PrintReceiptModal";
import OfflineDonations from "../donationRequest/OfflineDonationDetails";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  // table: {
  //   minWidth: 750,
  // },
  container: {
    padding: "0 10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formcontrol: {

    minWidth: "150px",
  },
  liveBg: {
    background: "#20a91d",
  },
  expireBg: {
    background: "var(--tal_primary)",
  },


  rowBtn: {
    color: "white",
    border: "none",
    height: "25px",
    fontSize: "12px",
    "&:hover": {
      background:"var(--tal_primary)",
      },
  },
  br20: {
    borderRadius: "20px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt25: {
    marginTop: "15px",
  },
  dBtn: {
    backgroundColor: "#ef6701",
    color: "white",
    border: "none",
    textAlign: "right",
    borderRadius: "5px",
    padding: "8px 15px",
    margin: "10px 0",
  },
  filterHeader: {
    backgroundColor: "#fff",
    marginBottom: "10px",
    height: "75px",
    marginTop: "-20px",
  },
  br0: {
    border: 0,
  },
}));
const colors = [
  { key: "0", color: "#ffcf33", status: "New/Pending" },
  { key: "1", color: "#00a152", status: "Approved" },
  { key: "2", color: "#357a38", status: "Completed" },
  { key: "-1", color: "#ff5722", status: "Rejected" },
  { key: "-2", color: "#ffac33", status: "Incomplete" },
  { key: "-3", color: "#0276aa", status: "Spam" },
  { key: "-4", color: "#b22a00", status: "Expired" },
  { key: "5", color: "#ff784e", status: "Deactivated" },
];
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const AdminDonations = (props) => {
  const { authUser, isFinAdmin, isSuperAdmin } = useContext(appContext);

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const [donorsList, setDonorsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [region, setRegion] = useState("");
  const [status, setStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [donationFor, setDonationFor] = useState("");
  const [values, setValues] = useState(null);
  const [id, setId] = useState(0);
  const [openOfflineDonationPopUp, setOpenOfflineDonationPopUp] = useState(
    false
  );
  const [focusedInput, setFocusedInput] = React.useState(0);
  const [row, setRow] = useState(null);
  const [isOpenPrintModal, setIsOpenPrintModal] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  useEffect(() => {
    if (isFinAdmin || (isSuperAdmin && authUser)) {
      fetchAllDonations(10000)
        .then((response) => {
          ProcessData(response.data.data);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    } else {
      toastr.warning(
        "You do not have the privilege to access the requested page!"
      );
      props.history.push(HOME_PAGE);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const clearState = () => {
    setStatus("");
    setRegion("");
    setDonationFor("");
    setStartDate(null);
    setEndDate(null);
    setPaymentMethod("");
    fetchAllDonations(10000)
      .then((response) => {
        ProcessData(response.data.data);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  useEffect(() => {
    if (
      status ||
      donationFor ||
      region ||
      paymentMethod ||
      startDate ||
      endDate
    ) {
      callService();
    }
  }, [status, donationFor, region, paymentMethod, startDate, endDate]);

  const callService = async () => {
    setIsWaitingForResponse(true);
    let counter = 0;
    let str = "";
    let state = {
      status,
      donationFor,
      region,
      paymentMethod,
      startDate,
      endDate,
    };
    for (let [key, val] of Object.entries(state)) {
      if (val) {
        if (key === "startDate" || key === "endDate") {
          var date = new Date(
            `${moment.utc(val).format("YYYY-MM-DD")} ${
              key === "startDate" ? "00:00:00" : "23:59:59"
            }`
          );
          val = date.getTime();
          if (key === "startDate") {
            val = date.getTime() + 1000 * 60 * 60 * 24;
          }
        } else if (key === "status" && val === "All") {
          val = `''`;
        }
        if (counter === 0) {
          str = `${key}=${val}`;
        } else {
          str += `&${key}=${val}`;
        }
        counter++;
      }
    }
    await getDonationsByFilter(str)
      .then((response) => {
        ProcessData(response.data);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
    setIsWaitingForResponse(false);
  };
  const ProcessData = (response) => {
    response.forEach((item) => {
      item.newDate = new Date(
        `${moment
          .utc(item.createdAt)
          .format(`${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`)}`
      ).getTime();
    });
    response.sort((response1, response2) => {
      return response2.newDate - response1.newDate;
    });
    const detailsArray = [];
    setDonorsList([]);
    response.map((details) => {
      if (details && details.donationFor === "tal") {
        const obj = {};
        obj.donationId = details["_id"];
        obj.notes = details.notes;
        obj.donationFor = "TOUCH-A-LIFE";
        if (details.billingAddress) {
          obj.address = `${details.billingAddress.state}, ${details.billingAddress.country}`;
        }
        if (details.transactionInfo) {
          obj.paymentMethod = details.transactionInfo.paymentMethod;
        }
        if (details.donation_request_info) {
          obj.status = details.donation_request_info.status;
        }
        obj.donatedDate = moment(details.createdAt).format(
          `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`
        );
        obj.amount = `${
          details.units && details.units.toUpperCase()
        } ${formatNumber(details.quantity)}`;
        if (details.anonymous === true) {
          obj.fullName = "Anonymous";
          obj.anonymous = true;
        } else if (
          details.user_info &&
          details.user_info.name &&
          details.user_info !== null
        ) {
          obj.fullName = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
        } else if (!details.user_info && details.externalUserInfo) {
          obj.fullName = `${details.externalUserInfo.name} (External User)`;
        }
        if (details.user_info && details.user_info.username) {
          obj.donorReference = details.user_info.username;
        }
        if (details.user_info && details.user_info.image_url) {
          obj.image = details.user_info.image_url;
        } else {
          obj.image = null;
        }
        if (details.user_info && details.user_info.username) {
          obj.username = details.user_info.username;
        }
        detailsArray.push(obj);
      } else if (details && details.donationFor === "donationRequest") {
        const obj = {};
        obj.donationId = details["_id"];
        obj.notes = details.notes;
        obj.donationFor = "Donation Request";
        if (details.donation_request_info) {
          obj.status = details.donation_request_info.status;
        }
        if (details.transactionInfo) {
          obj.paymentMethod = details.transactionInfo.paymentMethod;
        }
        if (details.billingAddress) {
          obj.address = `${details.billingAddress.state}, ${details.billingAddress.country}`;
        }
        obj.donatedDate = moment(details.createdAt).format(
          `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`
        );
        if (
          details.donation_request_info &&
          details.donation_request_info.title
        ) {
          obj.reference = details.donation_request_info.title;
          obj.donationName = details.donation_request_info.name;
          obj.donationRequestId = details.donation_request_info["_id"];
        }
        obj.amount = `${
          details.units && details.units.toUpperCase()
        } ${formatNumber(details.quantity)}`;
        if (details.anonymous === true) {
          obj.fullName = "Anonymous";
          obj.anonymous = true;
        } else if (
          details.user_info &&
          details.user_info.name &&
          details.user_info !== null
        ) {
          obj.fullName = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
        } else if (!details.user_info && details.externalUserInfo) {
          obj.fullName = `${details.externalUserInfo.name} (External User)`;
        }
        if (details.user_info && details.user_info.username) {
          obj.donorReference = details.user_info.username;
        }
        if (details.user_info && details.user_info.image_url) {
          obj.image = details.user_info.image_url;
        } else {
          obj.image = null;
        }
        if (details.user_info && details.user_info.username) {
          obj.username = details.user_info.username;
        }
        detailsArray.push(obj);
      } else if (details && details.donationFor === "individual") {
        const obj = {};
        obj.donationId = details["_id"];
        obj.notes = details.notes;
        obj.donationFor = "Individual";
        if (details.donation_request_info) {
          obj.status = details.donation_request_info.status;
        }
        if (details.transactionInfo) {
          obj.paymentMethod = details.transactionInfo.paymentMethod;
        }
        if (details.billingAddress) {
          obj.address = `${details.billingAddress.state}, ${details.billingAddress.country}`;
        }
        obj.donatedDate = moment(details.createdAt).format(
          `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`
        );
        obj.amount = `${
          details.units && details.units.toUpperCase()
        } ${formatNumber(details.quantity)}`;
        if (details.anonymous === true) {
          obj.fullName = "Anonymous";
          obj.anonymous = true;
        } else if (
          details.user_info &&
          details.user_info.name &&
          details.user_info !== null
        ) {
          obj.fullName = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
        } else if (!details.user_info && details.externalUserInfo) {
          obj.fullName = `${details.externalUserInfo.name} (External User)`;
        }
        if (details.user_info && details.user_info.username) {
          obj.donorReference = details.user_info.username;
        }
        if (details.user_info && details.user_info.image_url) {
          obj.image = details.user_info.image_url;
        } else {
          obj.image = null;
        }
        if (details.toUserInfo && details.toUserInfo.name) {
          obj.inividualDonee = `${details.toUserInfo.name.first_name} ${details.toUserInfo.name.first_name}`;
          obj.inividualDoneeReference = details.toUserInfo.username;
        } else {
          obj.inividualDonee = "";
        }
        obj.donationTo = obj.donationFor;
        detailsArray.push(obj);
      }
      return null;
    });
    setDonorsList(detailsArray);
    setPage(0);
  };

  const handleDateChange = (fromDate, toDate) => {
    if (fromDate != null) {
      setStartDate(fromDate);
      setEndDate(null);
    }
    if (toDate != null) {
      setEndDate(toDate);
    }
  };
  const handlePrintModal = (row) => {
    var donationQuery;
    if (row.donationFor === "Donation Request" || row.donationFor === "TAL") {
      donationQuery = "tal";
    } else {
      donationQuery = "individual";
    }
    getTransactionDetails(row.donationId, donationQuery)
      .then((response) => {
        if (response.data && Object.keys(response.data).length > 0) {
          const details = { ...row };
          const txnDetails = response.data;
          details.userMail = response.data.userId && response.data.userId.email;
          details.receiptId = response.data.transactionNumber;
          details.paymentId = response.data.transactionId;
          details.paymentMethod = response.data.paymentMethod;
          details.donatedTo = row.donationFor;
          details.name = row.fullName;
          details.userMail = response.data.userId && response.data.userId.email;
          details.phone = response.data.userId && response.data.userId.phone;
          details.address =
            txnDetails.donationData && txnDetails.donationData.billingAddress;
          details.donationFor = row.donationFor;
          if (
            response.data.donationData &&
            response.data.donationData.donation_request_info
          ) {
            details.donatedTo =
              response.data.donationData.donation_request_info.title;
          }
          setRow(details);
        } else {
          const details = { ...row };
          details.paymentMethod = "Offline";
          details.name = row.fullName;
          details.donatedTo = row.reference;
          details.donatedFor = "TOUCH-A-LIFE FOUNDATION";
          setRow(details);
        }
        setIsOpenPrintModal(true);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  const handleRedirect = (event) => {
    fetchAllDonations(10000)
      .then((response) => {
        ProcessData(response.data.data);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
    setPage(0);
  };
  const donateNow = (row) => {
    setOpenOfflineDonationPopUp(!openOfflineDonationPopUp);
    setId(row.donationRequestId);
    getDonationRequest(
      row.donationRequestId,
      authUser && authUser.unique_id
    ).then((response) => {
      let donatedPercentage = 0;
      if ((response.donated_quantity * 100) / response.quantity > 100) {
        donatedPercentage = 100;
      } else {
        donatedPercentage = Math.round(
          (response.donated_quantity * 100) / response.quantity
        );
      }
      setValues((currentValues) => {
        return {
          ...currentValues,
          requestType: response.request_type,
          title: response.title,
          description: response.description,
          brief: response.brief,
          tags: response.tags,
          quantity: response.quantity,
          unit: response.units,
          donatedQuantity: response.donated_quantity,
          whyYouNeedHelp: response.whyYouNeedHelp,
          donatedPercentage: donatedPercentage,
          userInfo: response.user_info,
          createdAt: response.createdAt,
          due_date: response.due_date,
          cashUnit: response.units,
          cashQuantity: response.quantity,
        };
      });
    });
  };
  const getStatus = (key) => {
    let obj = colors.filter((item) => item.key === key + "");
    if (obj.length > 0) {
      return obj[0].status;
    }
  };
  const getColor = (key) => {
    let obj = colors.filter((item) => item.key === key + "");
    if (obj.length > 0) {
      return obj[0].color;
    }
  };
  const exportToCSV = () => {
    const JsonFields = [
      "DATE",
      "NAME",
      "DONATED TO",
      "BILLING ADDRESS",
      "PAYMENT MODE",
      "AMOUNT",
      "STATUS",
    ];
    var csvStr = JsonFields.join(",") + "\n";
    donorsList.forEach((element) => {
      let amount = 0;
      let status = "";
      let name = "";
      let address = "";
      amount = element.amount;
      let paymentMethod = element.paymentMethod;
      if (element.notes === "Offline donation by Admin") {
        paymentMethod = "Offline";
      }
      let reference = element.reference;
      reference =
        element.donationFor === "Individual"
          ? element.inividualDonee
          : element.donationFor === "Donation Request"
          ? element.reference
          : element.donationFor;
      status = getStatus(element.status);
      name = element.fullName;
      if (element.address) {
        address = element.address.replace(",", " -");
      }
      if (!status) {
        status = "-N.A-";
      }
      if (!name) {
        name = "";
      }
      if (!paymentMethod) {
        paymentMethod = "";
      }
      if (!reference) {
        reference = "";
      }
      if (!address) {
        address = "";
      }
      csvStr +=
        element.donatedDate +
        "" +
        "," +
        name +
        "," +
        reference +
        "," +
        address +
        "," +
        paymentMethod +
        "," +
        amount.replace(/,/g, "") +
        "," +
        status +
        "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = `Donations-${moment().format(
      `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`
    )}.csv`;
    hiddenElement.click();
  };
  return (
    <React.Fragment>
      <div className="myDonations-container">
        <div className="barnav" style={{ paddingBottom: "0", border: "none" }}>
          <Container MaxWidth="lg">
            <div className="menu-container">
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}

              <div style={{display:"flex", gap:"5px", flexWrap:"wrap"}}>
                <FormControl>
                  <DateRangePicker
                    className={classes.br0}
                    showDefaultInputIcon={true}
                    displayFormat={DATE_FORMAT}
                    startDate={startDate}
                    startDateId="start_date_id"
                    endDate={endDate}
                    endDateId="end_date_id"
                    startDatePlaceholderText="From"
                    endDatePlaceholderText="To"
                    onDatesChange={({ startDate, endDate }) =>
                      handleDateChange(startDate, endDate)
                    }
                    isOutsideRange={() => false}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput)
                    }
                    customClearIcon={true}
                  />
                </FormControl>
                <FormControl variant="filled" className={classes.formcontrol}>
                  <InputLabel htmlFor="Select Location">
                    All Location
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="region"
                    value={region}
                    label="Select Location"
                    onChange={(e) => setRegion(e.target.value)}
                  >
                    <MenuItem value="inr">India</MenuItem>
                    <MenuItem value="usd">USA</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={classes.formcontrol}>
                  <InputLabel htmlFor="Select Status">Select Status</InputLabel>
                  <Select
                    variant="outlined"
                    value={status}
                    name="status"
                    label="Select Status"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="1,2,0">Live</MenuItem>
                    <MenuItem value="2">Closed</MenuItem>
                    <MenuItem value="-4">Expired</MenuItem>
                    <MenuItem value="2">Fulfilled</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={classes.formcontrol}>
                  <InputLabel htmlFor="Select Payment mode">
                    Payment Method
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="paymentMethod"
                    value={paymentMethod}
                    label="Select Payment mode"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <MenuItem value="offline">Offline</MenuItem>
                    <MenuItem value="paypal">Paypal</MenuItem>
                    <MenuItem value="stripe">Stripe</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={classes.formcontrol}>
                  <InputLabel htmlFor="Donated to">Donated to</InputLabel>
                  <Select
                    variant="outlined"
                    name="donationFor"
                    label="Donated to"
                    value={donationFor}
                    onChange={(e) => setDonationFor(e.target.value)}
                  >
                    <MenuItem value="donationRequest">DonationRequest</MenuItem>
                    <MenuItem value="tal">TAL</MenuItem>
                    <MenuItem value="user">USER</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  
                  style={{ cursor: "pointer", }}
                >
                  <Button style={{borderRadius:"5px", marginRight:"0", height:"55px"}}>
                    <HighlightOff onClick={clearState} />
                    Clear All Filters
                  </Button>
                </FormControl>
                <Button
                  startIcon={<FileCopyOutlinedIcon />}
                  onClick={exportToCSV}
               
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    color: "white",
                    height:"55px",
                    margin:"0",
                    backgroundColor:"var(--tal_primary) "

                  }}
                >
                  Export Data
                </Button>
              </div>
            </div>
          </Container>

        
        </div>
        <Container maxWidth="lg">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            )}

            <div
              className={classes.root}
              style={{ width: "100%", overflowX: "auto" }}
            >
              <TableContainer className="don-page drop-box">
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="sticky table"
                  x
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>DATE</StyledTableCell>
                      <StyledTableCell>NAME</StyledTableCell>
                      <StyledTableCell>DONATED TO</StyledTableCell>
                      <StyledTableCell>BILLING ADDRESS</StyledTableCell>
                      <StyledTableCell>PAYMENT MODE</StyledTableCell>
                      <StyledTableCell>AMOUNT</StyledTableCell>
                      <StyledTableCell>STATUS</StyledTableCell>
                      <StyledTableCell>RECEIPT</StyledTableCell>
                      <StyledTableCell>ACTION</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {donorsList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{data.donatedDate}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Link
                              href={
                                data.anonymous
                                  ? "javascript:void(0);"
                                  : `/${data.donorReference}`
                              }
                              target="blank"
                            >
                              {data.fullName}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.donationFor === "Individual" ? (
                              <Link
                                href={`/${data.inividualDoneeReference}`}
                                target="blank"
                              >
                                {data.inividualDonee}
                              </Link>
                            ) : data.donationFor === "Donation Request" ? (
                              <Link
                                href={`/donationRequest/${data.donationRequestId}`}
                                target="blank"
                              >
                                {data.reference}
                              </Link>
                            ) : (
                              data.donationFor
                            )}
                          </StyledTableCell>
                          <StyledTableCell>{data.address}</StyledTableCell>
                          <StyledTableCell>
                            {(data.notes === "Offline donation by Admin" &&
                              "Offline") ||
                              data.paymentMethod}
                          </StyledTableCell>
                          <StyledTableCell>{data.amount}</StyledTableCell>
                          <StyledTableCell>
                            {
                              <Chip
                                label={
                                  getStatus(data.status) ||
                                  (!getStatus(data.status) && "-N.A-")
                                }
                                style={{
                                  background: getColor(data.status),
                                }}
                              />
                            }
                          </StyledTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <PrintRounded
                                onClick={(e) => {
                                  handlePrintModal(data);
                                }}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              className={[classes.rowBtn, classes.expireBg]}
                              onClick={(e) => donateNow(data)}
                            >
                              Donate
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                {donorsList && donorsList.length === 0 && (
                  <Typography className="no-req-text">
                    No Donations Found
                  </Typography>
                )}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  component="div"
                  count={donorsList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  style={{
                    position: "sticky",
                    background: "white",
                    bottom: "0",
                    borderTop: "1px solid #eaeaea",
                  }}
                />
              </TableContainer>
            </div>
          </div>
        </Container>
      </div>
      <PrintReceiptModal
        receiptData={row}
        isOpen={isOpenPrintModal}
        onClose={(e) => setIsOpenPrintModal(false)}
      />
      {values && openOfflineDonationPopUp && (
        <OfflineDonations
          redirect={true}
          redirectEvent={handleRedirect}
          isOpen={openOfflineDonationPopUp}
          donationFor="donationRequest"
          donationRequestId={id}
          donationDetails={values}
          onClose={(e) => setOpenOfflineDonationPopUp(false)}
        />
      )}
      <Loader
        isOpen={isWaitingForResponse}
        onClose={(e) => setIsWaitingForResponse(false)}
      />
    </React.Fragment>
  );
};

export default AdminDonations;

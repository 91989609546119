import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Close from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { postFeedback } from "../../utils/api";
import "../donationDetails/Payment.scss";

const ShareThroughEmail = (props) => {
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [subject, setSubject] = useState(props && props.subject);
  const [body, setBody] = useState(props && props.content);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      const val = value.trim();
      if (val && isValid(val)) {
        setItems([...items, val]);
        setValue("");
      }
    }
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    setItems(items.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();
    const paste = evt.clipboardData.getData("text");
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/g);
    if (emails) {
      const toBeAdded = emails.filter((email) => !this.isInList(email));
      setItems([...items, ...toBeAdded]);
    }
  };

  const isValid = (email) => {
    let error = null;
    if (isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      setError(error);
      return false;
    }
    return true;
  };
  const isInList = (email) => {
    return items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/.test(email);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const changeDescriptionHandler = (e, editor) => {
    setBody(editor.getData());
  };
  const closePopup = () => {
    props.onClose();
    if (props.donationRequestName) {
      props.history.push(`/donationRequest/${props.donationRequestName}`);
    }
  };

  const submitHandler = () => {
    const obj = {};
    let isValid = false;
    // obj.emails = items.join(", ");
    if (items.length === 0) {
      setError("Please add Recipients");
    } else {
      obj.recipientEmailIds = items;
      isValid = true;
    }
    obj.subject = subject;
    obj.content = body;
    if (isValid === true) {
      props.onClose();
      postFeedback(obj)
        .then((response) => {
          if (response && response.statusCode === 200) {
            if (props.donationRequestName) {
              props.history.push(
                `/donationRequest/${props.donationRequestName}`
              );
            }
            toastr.success("Submitted successfully");
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className="payment-modal"
        open={props.isOpen}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade
          in={props.isOpen}
          style={{ width: "75%", height: "700px", "overflow-y": "scroll" }}
        >
          <div className="public-payment-container ">
            <div className="payment-page">
              <div>
                <div className="pay-headername">
                  <h2>Share Through Email</h2>
                  <span
                    className="close-payment"
                    onClick={closePopup}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      "margin-top": "-37px",
                    }}
                  >
                    <Close />
                  </span>
                </div>
                <div>
                  {items.map((item) => (
                    <div
                      className="tag-item"
                      key={item}
                      style={{
                        "background-color": "#d4d5d6",
                        display: "inline-block",
                        "font-size": "14px",
                        "border-radius": "30px",
                        height: "30px",
                        padding: "0 4px 0 1rem",
                        "align-items": "center",
                        margin: "0 0.3rem 0.3rem 0",
                      }}
                    >
                      {item}
                      <button
                        type="button"
                        style={{
                          "background-color": "white",
                          width: "22px",
                          height: "22px",
                          "border-radius": "50%",
                          border: "none",
                          cursor: "pointer",
                          font: "inherit",
                          "margin-left": "10px",
                          "font-weight": "bold",
                          padding: 0,
                          "line-height": 1,
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                        }}
                        className="button"
                        onClick={(e) => handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                  <input
                    className={"input " + (error && " has-error")}
                    value={value}
                    placeholder="To Add Recipients Press Enter Key.."
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "1px solid #ccc",
                    }}
                  />
                  {error && error ? (
                    <p
                      className="error"
                      style={{ color: "red", "font-size": "12px" }}
                    >
                      {error}
                    </p>
                  ) : (
                    <span
                      style={{
                        float: "left",
                        color: "red",
                        "font-size": "12px",
                      }}
                    >
                      <FontAwesomeIcon size="1x" icon={faInfoCircle} />
                      Press Enter Key after entering Email Address.
                    </span>
                  )}
                </div>
                <TextField
                  type="text"
                  className="full-width border-radius input-field"
                  placeholder="Subject"
                  name="subject"
                  value={subject}
                  onChange={handleSubjectChange}
                  style={{ width: "100%" }}
                  disabled
                />
                <CKEditor
                  disabled
                  data={body}
                  editor={ClassicEditor}
                  onChange={changeDescriptionHandler}
                  config={{
                    toolbar: [
                      "Heading",
                      "bold",
                      "italic",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                      "Link",
                    ],
                  }}
                />
                <div className="payment-btn">
                  <Button
                    color="primary"
                    className="pay-btn"
                    variant="contained"
                    type="submit"
                    onClick={submitHandler}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default withRouter(ShareThroughEmail);

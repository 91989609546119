import React, { useState, useEffect, useContext } from "react";
import { useBeforeunload } from "react-beforeunload";
import Img from "react-cool-img";
import firebase from "../../firebase/firebase";
import Song from "./Song";
import Play from "./Play";
import Stop from "./Stop";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { getChannels } from "../../utils/api";
import { appContext } from "../../App";
import ChannelSelectionBox from "../TALRadio/ChannelSelectBox";
import toastr from "toastr";
//import { fetchAndCreateBlobUrl } from "../../utils/utils";
import Loader from "../common/Loader";
import { checkDefaultChannel } from "../../utils/utils";

const FIELD_TOTAL_LISTENERS_COUNT = "totalListenersCount";
const FIELD_CURRENT_LISTENERS_COUNT = "currentListenersCount";

const Audio = (props) => {
  const {
    authUser,
    isPlaying,
    setIsPlaying,
    channel,
    setChannel,
    channels,
    setChannels,
    setTalmediaLanguage,
  } = useContext(appContext);
  const [didUserInteract, setDidUserInteract] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [mute, setMute] = useState(false);
  const [previousCurrentStreamingRef, setPreviousCurrentStreamingRef] =
    useState(null);
  const [currentStreaming, setCurrentStreaming] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAudioAndPlay = (shouldPlayFromSeekTime) => {
    const audio = document.getElementById("audio");
    audio.load();
    if (currentStreaming && currentStreaming.downloadUrl) {
      audio.src = currentStreaming.downloadUrl;
      if (shouldPlayFromSeekTime) {
        const seekTimeRef = firebase.realtimeDb.ref(`/seekTime/${channel}`);
        if (seekTimeRef) {
          seekTimeRef
            .once("value")
            .then((snapshot) => {
              if (snapshot.exists()) {
                if (isPlaying) {
                  audio.currentTime = snapshot.val();
                  audio
                    .play()
                    .then(() => {
                      //continue playing
                    })
                    .catch((error) => {
                      //audio.play();
                      console.log(error);
                      fetchAudioAndPlay(true);
                    });
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.log("seekTime data not available");
        }
      } else {
        if (isPlaying) {
          audio.currentTime = 0;
          audio
            .play()
            .then(() => {
              //continue playing
            })
            .catch((error) => {
              //audio.play();
              console.log(error);
              fetchAudioAndPlay(true);
            });
        }
      }
    }
  };

  const onChannelChange = (oldChannel, newChannel) => {
    if (isPlaying) {
      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
        isIncrement: false,
        channel: oldChannel,
      });
      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
        isIncrement: true,
        channel: newChannel,
      });
      updateListenersCount({
        field: FIELD_TOTAL_LISTENERS_COUNT,
        isIncrement: true,
        channel: newChannel,
      });
    }
  };

  const goOffline = () => {
    const audio = document.getElementById("audio");
    audio.src = "";
    audio.currentTime = 0;
    setIsPlaying(false);
    if (isPlaying) {
      audio.pause();
    }
  };

  const channelHandler = (channel) => {
    if (channel) {
      // Get the selected channel from the list of channels
      const selectedChannel = channels.find(
        (eachChannel) => eachChannel.name === channel
      );
      let currentStreamingRef;
      if (selectedChannel && selectedChannel.name) {
        // Get the firebase path ref of the selected channel
        currentStreamingRef = firebase.realtimeDb.ref(
          `/currentStreaming/${selectedChannel.name}`
        );
      }

      // stop listening to the old channel
      if (previousCurrentStreamingRef) {
        previousCurrentStreamingRef.off();
      }

      // start listening to the new channel
      if (currentStreamingRef) {
        currentStreamingRef.on("value", (snapshot) => {
          if (snapshot.exists()) {
            // const downloadedUrl = await fetchAndCreateBlobUrl(
            //   snapshot.val().downloadUrl
            // );
            // setCurrentStreaming({ ...snapshot.val(), downloadedUrl });
            setCurrentStreaming(snapshot.val());
          } else {
            setCurrentStreaming({ title: "Offline", thumbnailUrl: "" });
            goOffline();
          }
        });
      }
      setPreviousCurrentStreamingRef(currentStreamingRef);
    }
  };

  useEffect(() => {
    if (currentStreaming && currentStreaming.downloadUrl) {
      fetchAudioAndPlay(true);
    }
  }, [currentStreaming]);

  const updateListenersCount = (data = {}) => {
    if (didUserInteract) {
      const channelName = data.channel && data.channel.trim();
      const field = data.field && data.field.trim();
      const isIncrement = data.isIncrement;
      var path = "";
      if (channelName && channelName.length > 0) {
        path = `channels/${channelName}/${field}`;
      } else {
        path = `channels/${field}`;
      }

      var currentListenersCountRef = firebase.realtimeDb.ref(path);

      currentListenersCountRef
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            const currentListenersCount = Number(snapshot.val());
            var count = 0;
            if (isIncrement) {
              count = currentListenersCount + 1;
            } else {
              count = currentListenersCount > 0 ? currentListenersCount - 1 : 0;
            }
            currentListenersCountRef.set(count);
          } else {
            currentListenersCountRef.set(1);
          }
        })
        .catch((error) => {
          console.log(
            "error while getting the current listeners count.",
            error.message
          );
        });
    }
  };

  const loadChannels = () => {
    setIsLoading(true);
    getChannels()
      .then((response) => {
        setIsLoading(false);
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setChannels(response.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Channels:" + error.message);
      });
  };

  const decideDefaultChannel = () => {
    let defaultChannel = "";
    if (!channel) {
      if (authUser && authUser.unique_id && authUser.defaultChannel) {
        defaultChannel = authUser.defaultChannel;
      } else if (
        props &&
        props.match &&
        props.match.params &&
        props.match.params.defaultChannel
      ) {
        //TODO: What if the props.match.params.defaultChannel is an invalid value?
        // Need to validate it and set a default channel
        defaultChannel = props.match.params.defaultChannel;
      } else {
        if (channels && channels.length > 0) {
          channels.forEach((channel) => {
            if (channel.isDefault) {
              defaultChannel = channel.name;
            }
          });
        }
      }
      setTalmediaLanguage(checkDefaultChannel(defaultChannel));

      if (defaultChannel) {
        setChannel(defaultChannel);
        //channelHandler(defaultChannel);
      }
    }
  };

  useEffect(() => {
    if (channel) {
      channelHandler(channel);
    }
  }, [channel]);

  useEffect(() => {
    if (channels && channels.length > 0) {
      // channels are already loaded. Just decide the defaultChannel
      decideDefaultChannel();
    } else {
      // channels are not laoded yet. So load the channels and then decide the defaultChannel
      loadChannels();
    }
    return () => {
      // stop listening to the firebase realtime db
      if (previousCurrentStreamingRef) {
        previousCurrentStreamingRef.off();
      }
      const audio = document.getElementById("audio");
      if (isPlaying) {
        audio.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (channels && channels.length > 0) {
      decideDefaultChannel();
    }
  }, [channels]);

  useEffect(() => {
    if (isPlaying) {
      fetchAudioAndPlay(true);

      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
        isIncrement: true,
      });
      updateListenersCount({
        field: FIELD_TOTAL_LISTENERS_COUNT,
        isIncrement: true,
      });

      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
        isIncrement: true,
        channel: channel,
      });
      updateListenersCount({
        field: FIELD_TOTAL_LISTENERS_COUNT,
        isIncrement: true,
        channel: channel,
      });
    } else {
      const audio = document.getElementById("audio");
      audio.pause();
      // Decrement aggregate current listeners count
      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
      });
      // Decrement channel current listeners count
      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
        channel: channel,
      });
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio = document.getElementById("audio");
    audio.volume = volume;
  }, [volume]);

  const handlePlayer = (status) => {
    setIsPlaying(status);
    setDidUserInteract(true);
  };

  useBeforeunload((event) => {
    if (isPlaying) {
      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
      });

      updateListenersCount({
        field: FIELD_CURRENT_LISTENERS_COUNT,
        channel: channel,
      });
      event.preventDefault();
    }
  });

  return (
    <div className="main_player">
      <ChannelSelectionBox onChannelChange={onChannelChange} />
      <div className="tal_radio_plaer">
        <Img
          src={
            currentStreaming && currentStreaming.thumbnailUrl
              ? currentStreaming.thumbnailUrl
              : "/images/talradio-active.png"
          }
          error="/images/talradio-active.png"
        />
      </div>
      <audio id="audio" muted={mute} loop preload="auto">
        Your browser does not support the <code>audio</code> element.
      </audio>

      {isPlaying ? (
        <div style={{ visibility: "visible" }} className="wave_img"></div>
      ) : (
        <div style={{ visibility: "hidden" }} className="wave_img_jpg"></div>
      )}

      <div className="flex_player">
        <div className="controls">
          {isPlaying ? (
            <Stop handleClick={() => handlePlayer(false)} />
          ) : (
            <Play handleClick={() => handlePlayer(true)} />
          )}
        </div>

        <Song
          songName={currentStreaming && currentStreaming.title}
          songArtist={currentStreaming && currentStreaming.rjUserId}
          songProducer={currentStreaming && currentStreaming.producer}
        />

        <div className="range">
          <div
            onClick={() => setMute((currentValue) => !currentValue)}
            style={{ cursor: "pointer" }}
          >
            {!mute && <VolumeUpIcon />}
            {mute && <VolumeOffIcon />}
          </div>
          <input
            type="range"
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={(event) => setVolume(event.target.value)}
          />
        </div>
      </div>
      <Loader isOpen={isLoading} onClose={(e) => setIsLoading(false)} />
    </div>
  );
};

export default Audio;

import React, { useContext, useState, useEffect, useRef } from "react";
import moment from "moment";
import toastr from "toastr";
import ReactAvatar from "react-avatar";
import { DATE_TIME_FORMAT } from "../../utils/utils";
import { appContext } from "../../App";
import { Grid, Typography, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SendIcon from "@material-ui/icons/Send";
import firebase from "../../firebase/firebase";

import "./Comments.scss";

const Comments = (props) => {
  const { isOpen, donationRequest } = props;
  const { authUser, isAdmin, isSuperAdmin } = useContext(appContext);
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState("");
  const [editedMsg, setEditedMsg] = useState("");
  const [showTextbox, setShowTextbox] = useState(false);
  const [more, setMore] = useState(3);
  const [setShowMore] = useState(false);
  const [refresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [myCom, setMyCom] = useState([]);
  const [test, setTest] = useState([]);
  const [commentsList, setCommentsList] = useState();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleMobileMenuClose();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event, comment) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setMyCom(comment);
  };

  const loadComments = () => {
    const ref = firebase.realtimeDb.ref(
      "/conversations/" + donationRequest._id
    );

    ref.on("value", (snapshot) => {
      const comments = snapshot.val();
      const commentsList = [];
      snapshot.forEach((productSnapshot) => {
        let key = productSnapshot.key;
        let data = productSnapshot.val();
        commentsList.push({ key: key, ...data });
      });
      setComments(commentsList && commentsList.reverse());
    });
  };
  useEffect(() => {
    if (isOpen && donationRequest) {
      loadComments();
    }
  }, [isOpen]);

  useEffect(() => {
    loadComments();
  }, [refresh]);

  const textChangeHandler = (event) => {
    setMessage(event.target.value);
  };

  const handleEditComment = (comment) => {
    let com = comments.filter((item) => item.key === comment.key);
    setTest(com);
    setShowTextbox(true);
  };

  const handleDeleteComment = (key) => {
    if (window.confirm("Are you sure you want to delete the comment?")) {
      firebase.realtimeDb
        .ref("/conversations/" + donationRequest._id + "/" + key)
        .remove()
        .then(() => {
          toastr.success("Successfully deleted");
          loadComments();
          if (props.onClick) {
            props.onClick();
          }

          handleMobileMenuClose();
        });
    }
  };

  const checkEditMsg = (event, comm) => {
    if (event.key === "Enter") {
      const newComment = event.target.value;
      firebase.realtimeDb
        .ref("/conversations/" + donationRequest._id + "/" + comm.key)
        .update({
          text: newComment,
          whenSent: new Date().getTime(),
        })
        .then((snapshot) => {
          toastr.success("Successfully edited");
          setShowTextbox(false);
          loadComments();
          if (props.onClick) {
            props.onClick();
          }
        })
        .catch((error) => {
          toastr.error(error.message);
          setShowTextbox(true);
        });
    }
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      submitMessage();
    }
  };

  const handleMoreComments = () => {
    setMore(more + 3);
  };

  const submitMessage = () => {
    if (message && message.trim()) {
      const values = {};
      values.text = message;
      //values.requestId = donationRequest._id;
      values.userId = authUser.unique_id;
      values.senderName =
        (authUser &&
          authUser.name &&
          authUser.name.first_name + " " + authUser.name.last_name) ||
        "";
      values.senderImageUrl = authUser.profile_image_url || "";
      values.whenSent = new Date().getTime();
      const ref = firebase.realtimeDb.ref(
        "/conversations/" + donationRequest._id
      );
      setMessage("");
      if (props.onClick) {
        props.onClick();
      }
      ref.push(values);
    }
  };

  const handleEditMsg = (e) => {
    setEdit(true);
    setEditedMsg(e.target.value);
  };

  return (
    <div className="comments-container" ref={wrapperRef}>
      {props.isOpen && (
        <div className="comment-section-block">
          {authUser && (
            <div className="comment-textfield-container">
              <Avatar
                src={authUser && authUser.profile_image_url}
                alt="Avatar"
                className="comment-avatar"
              />
              <TextField
                type="text"
                name="message"
                value={message}
                className="comments-field"
                onChange={textChangeHandler}
                onKeyPress={keyPressHandler}
                placeholder="Say something about this"
                width={16}
              />
              <SendIcon
                name="send"
                color="blue"
                size="big"
                onClick={submitMessage}
                className="send-icon"
                title="Click to send"
              />
            </div>
          )}
          <div>
            {comments && comments.length > 0 ? (
              comments.slice(0, more).map((comment) => {
                return (
                  <Typography className="recent-comments">
                    {comment.senderImageUrl ? (
                      <Avatar
                        src={comment.senderImageUrl}
                        alt="Avatar"
                        className="comment-avatar"
                      />
                    ) : (
                      <ReactAvatar
                        name={comment.senderName}
                        size="30"
                        round={true}
                        className="comment-avatar"
                      />
                    )}{" "}
                    <Typography className="comments-main-details">
                      {test &&
                      test[0] &&
                      test[0].whenSent === comment.whenSent &&
                      showTextbox ? (
                        <TextField
                          type="text"
                          name="message"
                          value={edit ? editedMsg : comment.text}
                          className="comments-edit-field"
                          onChange={handleEditMsg}
                          onKeyPress={(e) => checkEditMsg(e, comment)}
                          placeholder="Say something about this"
                          width={16}
                          autoFocus
                        />
                      ) : (
                        <Typography>
                          <Typography className="comment-details">
                            <span className="commentor-name">
                              {comment.senderName}
                            </span>{" "}
                            |{" "}
                            <span className="comment-time">
                              {moment(comment.whenSent).format(
                                DATE_TIME_FORMAT
                              )}
                            </span>
                          </Typography>{" "}
                          <Typography className="comment-text">
                            {comment.text}
                          </Typography>
                        </Typography>
                      )}
                    </Typography>
                    {((authUser &&
                      comment.userId &&
                      authUser.unique_id === comment.userId) ||
                      isSuperAdmin ||
                      isAdmin) && (
                      <>
                        <IconButton
                          className="more-btn"
                          aria-label="show more"
                          aria-controls={mobileMenuId}
                          aria-haspopup="true"
                          onClick={(e) => handleMobileMenuOpen(e, comment)}
                          color="inherit"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={mobileMoreAnchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          id={mobileMenuId}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={isMobileMenuOpen}
                          onClose={handleMobileMenuClose}
                        >
                          {authUser && myCom.userId === authUser.unique_id && (
                            <MenuItem
                              onClick={() => handleEditComment(myCom)}
                              className="my-list"
                              style={{ height: 40 }}
                            >
                              {" "}
                              <IconButton
                                color="inherit"
                                style={{ fontSize: 18 }}
                              >
                                <p>Edit</p>
                              </IconButton>
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => handleDeleteComment(myCom.key)}
                            style={{
                              height: 30,
                              marginTop: isAdmin || isSuperAdmin ? 10 : 0,
                            }}
                            className="my-list"
                          >
                            <IconButton
                              color="inherit"
                              style={{ fontSize: 18 }}
                            >
                              <p>Delete</p>
                            </IconButton>
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Typography>
                );
              })
            ) : (
              <div>
                <h4 align="center">No comments yet</h4>
                <p align="center">Be the first to comment</p>
              </div>
            )}
          </div>
        </div>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {!authUser && (
            <Typography className="not-logged-text">
              Please signup or login to add a comment
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {comments.length > more && (
            <div className="comment-btn" onClick={handleMoreComments}>
              View more comments
            </div>
          )}
        </Grid>
      </Grid>
    </div>

    // </Dialog>
  );
};

export default Comments;

import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { appContext } from "../../App";
import Avatar from "@material-ui/core/Avatar";
import "./TalLeaders.scss";
import {
  Button,
  Grid,
  Slide,
  TextField,
  TablePagination,
  Hidden,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  ChatBubbleOutlineOutlined,
  ThumbUpAlt,
  ThumbUpAltOutlined,
} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import { Close } from "@material-ui/icons";
import toastr from "toastr";
import Loader from "../common/Loader";
import {
  getRegisteredLeaders,
  likeApi,
  disLike,
  createTalleaderComment,
  editComment,
  deleteComment,
  getUsers,
  checkUrlWithHttp,
} from "../../utils/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const TalLeaders = (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  const { authUser } = useContext(appContext);
  const [leaderList, setLeadersList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [comments, setComments] = useState("");
  const [editCommentValue, setEditCommentValue] = useState("");
  const [isEditComments, setIsEditComments] = useState(false);
  const [editedComments, setEditedComments] = useState({});
  const [selectedRowComments, setSelectedRowComments] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [more, setMore] = useState(3);
  const [commentsCount, setCommentsCount] = useState([]);
  const [showTextbox, setShowTextbox] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClickCommentsOpen = (e, rowData) => {
    setopenDialog(true);
    setSelectedRowComments(rowData);
    setCommentsCount(rowData.userComments);
  };

  const handleDialogClose = () => {
    setopenDialog(false);
    setComments("");
    setEditCommentValue("");
    setIsEditComments(false);
    setCommentsCount([]);
    setMore(3);
  };

  const loadTalLeadersList = (page = 0, tabValue, leaderList) => {
    setIsLoading(true);
    const query = `sourceOfSignup=talleaders`;
    getUsers(query, rowsPerPage, page * rowsPerPage, null, tabValue)
      .then((response) => {
        setTotalRecords(response.totalCountOfRecords);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          const donationRequestArray = [];
          if (tabValue == 0) {
            leaderList.map((data) => {
              const obj = data;
              if (data.user_info && data.user_info.name) {
                obj.donee = `${data.user_info.name.first_name} ${data.user_info.name.last_name}`;
              }
              donationRequestArray.push(obj);
              return null;
            });
            const responseArray = [];
            response.data.map((data) => {
              const obj = data;
              responseArray.push(obj);
              return null;
            });
            setLeadersList([...donationRequestArray, ...responseArray]);
          } else {
            setLeadersList([...response.data]);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching users:" + error.message);
      });
  };

  useEffect(() => {
    setLeadersList([]);
    loadTalLeadersList(0, tabValue, []);
  }, [tabValue]);

  useEffect(() => {
    loadTalLeadersList(0, tabValue, []);
  }, [rowsPerPage]);

  const handleLike = (row) => {
    if (row && row._id) {
      let user =
        (row.userLikes &&
          row.userLikes.some((item) => item._id === authUser.unique_id)) ||
        row.userLikes.includes(authUser.unique_id);

      if (user) {
        disLike(row._id).then((resp) => {
          resp && resp.data && updateUserData(resp.data);
        });
      } else {
        likeApi(row._id).then((resp) => {
          resp && resp.data && updateUserData(resp.data);
        });
      }
    }
  };

  const updateUserData = (updatedResponse) => {
    setLeadersList((talLeader) => {
      return talLeader.map((userData) => {
        if (userData._id === updatedResponse._id) {
          return {
            ...userData,
            userLikes: updatedResponse.userLikes,
          };
        }
        return userData;
      });
    });
  };

  const handleCommentsSubmit = (e) => {
    e.preventDefault();
    if (isEditComments) {
      if (selectedRowComments && selectedRowComments._id) {
        const requestBody = {
          comment: editCommentValue,
        };
        //Update Comments
        editComment(
          requestBody,
          selectedRowComments._id,
          editedComments?._id
        ).then((response) => {
          if (response && Object.keys(response)?.length > 0) {
            setIsEditComments(false);
            setShowTextbox(false);
            loadTalLeadersList(0, tabValue, []);
            toastr.success(response?.data?.message);
            setEditCommentValue("");
            setComments("");
          }
        });
      }
    } else {
      if (selectedRowComments && selectedRowComments._id) {
        const requestBody = {
          comment: comments,
        };
        //Create Comments
        createTalleaderComment(requestBody, selectedRowComments._id).then(
          (response) => {
            if (response && Object.keys(response)) {
              loadTalLeadersList(0, tabValue, []);
              setIsEditComments(false);
              toastr.success("Comment Added Successfully");
              setEditCommentValue("");
              setComments("");
              setCommentsCount(response.userComments && response.userComments);
            }
          }
        );
      }
    }
  };

  const editComments = (e, uComments) => {
    setIsEditComments(true);
    setEditedComments(uComments);
    setEditCommentValue(uComments.comments);
    setShowTextbox(true);
  };
  //Delete Comments
  const handleDelete = (e, uComments) => {
    if (window.confirm("Are you sure you want to delete the comment?"))
      if (selectedRowComments && selectedRowComments._id) {
        const requestBody = {
          user_id: selectedRowComments._id,
        };
        deleteComment(requestBody, selectedRowComments._id, uComments?._id)
          .then((response) => {
            if (response && Object.keys(response)?.length > 0) {
              setIsEditComments(false);
              loadTalLeadersList(0, tabValue, []);
              setCommentsCount(
                response.data.data && response.data.data.userComments
              );
              toastr.success(response?.data?.message);
            }
          })
          .catch();
      }
  };
  const handleChangePage = (event, newPage) => {
    loadTalLeadersList(newPage, tabValue, leaderList);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMoreComments = () => {
    setMore(more + 3);
  };

  return (
    <>
      <Grid className="main_box">
        <Container>
          <Grid container>
            <Grid item md={12}>
              <h2>TALLeaders</h2>
              <p>
                A global community of TALLeaders™ united by the common goal of
                utilizing their professional success for social good.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Container className="main_tab">
        <TabContext value={tabValue}>
          <AppBar position="static">
            <TabList
              onChange={handleChange}
              aria-label="simple tabs example"
              className="menu_select"
            >
              <Tab label="New Leaders" value={0} />
              <Tab label="All Leaders" value={1} />
            </TabList>
          </AppBar>
          <TabPanel value={0}>
            <Grid className="side_new">
              <h2>New Onboarded Leaders</h2>

              {/* <p>
                Touch-A-Life Foundation Leadership Team supports innovative ways
              </p> */}
            </Grid>

            <Grid container className="mid_row">
              <Grid item xs={4}>
                <Hidden smDown>
                  <p className="le_text_algin">Name</p>
                </Hidden>
              </Grid>
              <Grid item xs={4}>
                <Hidden smDown>
                  <p className="Ares_interst">Areas of Interest</p>
                </Hidden>
              </Grid>
              <Grid item xs={3}>
                <Hidden smDown>Profile</Hidden>
              </Grid>
              <Grid item xs={1}>
                <Hidden smDown>Action</Hidden>
              </Grid>
            </Grid>
            {leaderList &&
              leaderList.length > 0 &&
              leaderList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <span key={row._id}>
                      <Grid container className="name_align">
                        <Grid item md={4} sm={6} xs={12} className="img_align">
                          <Hidden mdUp>
                            <p>Name</p>
                          </Hidden>
                          <Grid>
                            <div>
                              {row && row.image_url !== "image_url" ? (
                                <img
                                  src={
                                    row && row.image_url !== ""
                                      ? row.image_url
                                      : "../images/tal-leader-icon.svg"
                                  }
                                  className="image_style"
                                />
                              ) : (
                                <img
                                  src="../images/tal-leader-icon.svg"
                                  className="image_style"
                                />
                              )}
                            </div>
                            <div className="name_txt">
                              <p className="name_title">
                                {row && row.name.first_name}{" "}
                                {row && row.name.last_name}
                              </p>
                              {row && row.currentRole}
                              <p>
                                <strong>
                                  {" "}
                                  {row && row.currentCompanyName && (
                                    <>
                                      <span style={{ fontWeight: "normal" }}>
                                        At{" "}
                                      </span>
                                      {row.currentCompanyName}
                                    </>
                                  )}
                                </strong>
                                <br />
                                <span>
                                  {row && row.address && row.address.state}
                                </span>{" "}
                                &nbsp;
                                <span>
                                  {row && row.address && row.address.country}
                                </span>
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sm={6}
                          xs={12}
                          className="name_pro_box  name_pro_boxs"
                        >
                          <Hidden mdUp>
                            <p>Area of Interests</p>
                          </Hidden>
                          <Grid item xs={12} className="tabs-chips">
                            {row.areasOfInterest.map((area, i) => (
                              <Button
                                key={i}
                                className="tabs-chips"
                                style={{ cursor: "none" }}
                              >
                                {area}
                              </Button>
                            ))}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          md={3}
                          sm={6}
                          xs={12}
                          className="Veiw_Profile"
                        >
                          <Hidden mdUp>
                            <p>Profile</p>
                          </Hidden>
                          <a
                            href={checkUrlWithHttp(row.linkedInProfileUrl)}
                            target="_blank"
                          >
                            <Button>
                              Visit profile
                              <img
                                className="profile_imgs"
                                src="../images/external-link.svg"
                                alt="mail"
                              ></img>
                            </Button>
                          </a>
                        </Grid>
                        <Grid item md={1} sm={6} xs={12}>
                          <Hidden mdUp>
                            <p>Action</p>
                          </Hidden>
                          <Grid className="act_btn_tal">
                            <BootstrapTooltip title="Like">
                              <Button onClick={(e) => handleLike(row)}>
                                {/* <ThumbUpAltOutlined
                                style={{
                                  color:
                                    (row &&
                                      row.userLikes &&
                                      row.userLikes.some(
                                        (item) =>
                                          item._id === authUser.unique_id
                                      )) ||
                                    row.userLikes.includes(authUser.unique_id)
                                      ? "#cf3b3a"
                                      : "#555",
                                }}
                              /> */}
                                {(row &&
                                  row.userLikes &&
                                  row.userLikes.some(
                                    (item) => item._id === authUser.unique_id
                                  )) ||
                                row.userLikes.includes(authUser.unique_id) ? (
                                  <ThumbUpAlt style={{ color: "#29a027" }} />
                                ) : (
                                  <ThumbUpAltOutlined
                                    style={{ color: "#cf3b3a" }}
                                  />
                                )}
                                <span>
                                  {row && row.userLikes && row.userLikes.length}
                                </span>
                              </Button>
                            </BootstrapTooltip>
                            <BootstrapTooltip title="Comments">
                              <Button
                                onClick={(e) => handleClickCommentsOpen(e, row)}
                              >
                                <ChatBubbleOutlineOutlined />
                                <span>{row?.userComments?.length}</span>
                              </Button>
                            </BootstrapTooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </span>
                  );
                })}
            {!isLoading && leaderList && leaderList.length == 0 && (
              <p style={{ textAlign: "center", marginTop: "40px" }}>
                Uh-oh! Currently, there are no new leaders. Please come back
                later.
              </p>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
                className="Dialog-Box"
              >
                <Grid className="commemt_align">
                  <div className="comment_color">
                    <h4>Comments</h4>
                    <span className="close_icon">
                      <Close onClick={handleDialogClose} />
                    </span>
                  </div>
                  <hr />

                  <Grid className="tal_line">
                    <Avatar
                      src={authUser && authUser.profile_image_url}
                      alt="Avatar"
                      className="comment-avatar"
                    />

                    <div className="comments-main-details leader_pop">
                      <form onSubmit={handleCommentsSubmit}>
                        <TextField
                          placeholder="Say something about this"
                          className="w-100"
                          name="comments"
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                        />
                      </form>
                    </div>

                    <Button
                      className="submit_text"
                      onClick={handleCommentsSubmit}
                      disabled={comments === "" && editCommentValue === ""}
                    >
                      submit
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="recent-comments">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    {leaderList &&
                      leaderList.map((leaders, i) => {
                        if (leaders._id === selectedRowComments._id) {
                          return leaders?.userComments
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt).getTime() -
                                new Date(a.createdAt).getTime()
                            )
                            ?.slice(0, more)
                            ?.map((uComments, indx) => {
                              return (
                                <div key={uComments._id}>
                                  {isEditComments &&
                                  showTextbox &&
                                  uComments._id === editedComments._id ? (
                                    <form onSubmit={handleCommentsSubmit}>
                                      <div className="comments-main-details leader_pop">
                                        <TextField
                                          placeholder="Say something about this"
                                          className="w-100"
                                          name="editCommentValue"
                                          value={editCommentValue}
                                          onChange={(e) =>
                                            setEditCommentValue(e.target.value)
                                          }
                                        />
                                      </div>
                                    </form>
                                  ) : (
                                    <div className="comments-main-details ">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Avatar
                                          src={
                                            uComments.userId &&
                                            uComments.userId.image_url
                                          }
                                          alt="Avatar"
                                          className="comment-avatar"
                                        />

                                        <span style={{ marginLeft: "10px" }}>
                                          {uComments &&
                                            uComments.userId.name
                                              .first_name}{" "}
                                          &nbsp;
                                          {uComments &&
                                            uComments.userId.name.last_name}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          margin: "15px 15px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <p>{uComments.comments}</p>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        {authUser &&
                                          uComments &&
                                          uComments.userId &&
                                          uComments.userId._id ===
                                            authUser.unique_id && (
                                            <Button
                                              onClick={(e) =>
                                                editComments(e, uComments)
                                              }
                                            >
                                              Edit
                                            </Button>
                                          )}

                                        {authUser &&
                                          uComments &&
                                          uComments.userId &&
                                          uComments.userId._id ===
                                            authUser.unique_id && (
                                            <Button
                                              onClick={(e) =>
                                                handleDelete(e, uComments)
                                              }
                                            >
                                              Delete
                                            </Button>
                                          )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            });
                        }
                      })}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                {!isLoading && commentsCount && commentsCount.length == 0 && (
                  <p style={{ textAlign: "center" }}>
                    Uh-oh! Currently, there are no comments. But be the first
                    one to comment.
                  </p>
                )}
                <Grid item xs={12} sm={6}>
                  {commentsCount.length > more && (
                    <div className="comment-btn" onClick={handleMoreComments}>
                      View more comments...
                    </div>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <div className="pop_para">
                      {/* <p>
                        Touch-A-Life Foundation Leadership Team supports
                        innovative ways
                      </p> */}
                    </div>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Dialog>
            </>
          </TabPanel>

          <TabPanel value={1}>
            <Grid container className="leader_line">
              {leaderList &&
                leaderList.map((Tleaders, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <img
                        src={
                          Tleaders && Tleaders.image_url !== ""
                            ? Tleaders.image_url
                            : " ../images/tal-leader-icon.svg"
                        }
                        style={{ width: "273px", height: "273px" }}
                      />
                      <div className="leader_group">
                        <p className="head_leader">
                          {Tleaders.name.first_name} {Tleaders.name.last_name}
                        </p>
                        <p>
                          <span className="para_line">
                            {Tleaders.currentRole}
                          </span>
                          <strong>
                            {" "}
                            {Tleaders && Tleaders.currentCompanyName && (
                              <>
                                <span style={{ fontWeight: "normal" }}>
                                  At{" "}
                                </span>
                                {Tleaders.currentCompanyName}
                              </>
                            )}
                          </strong>
                        </p>

                        <div className="Veiw_Profile">
                          <a
                            href={checkUrlWithHttp(Tleaders.linkedInProfileUrl)}
                            target="_blank"
                          >
                            <Button>
                              Visit profile
                              <img
                                className="profile_imgs"
                                src="../images/external-link.svg"
                                alt="mail"
                              ></img>
                            </Button>
                          </a>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            {!isLoading && leaderList && leaderList.length == 0 && (
              <p style={{ textAlign: "center" }}>
                Uh-oh! Currently, there are no leaders. Please come back later.
              </p>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TabPanel>
        </TabContext>
      </Container>
      <Loader
        isOpen={isLoading ? isLoading : isLoaded}
        onClose={() => (isLoading ? setIsLoading(false) : setIsLoaded(false))}
      />
    </>
  );
};

export default withRouter(TalLeaders);

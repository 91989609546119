import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  LinearProgress,
  FormGroup,
} from "@material-ui/core";
import Sidemenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createDonationRequest,
  updateDonationRequest,
  getUserData,
  updateProfile,
  getDonationRequest,
} from "../../utils/api";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddPhotos from "../common/AddPhotos";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PhoneNumber from "../Phone/PhoneNumber";
import {
  getQueryStringValue,
  HOME_PAGE,
  STATUS_NEW,
  STATUS_APPROVED,
  STATUS_INCOMPLETE,
  getKeyByValue,
} from "../../utils/utils";
import jwt_decode from "jwt-decode";
import validator from "validator";
import {
  FormControlLabel,
  Checkbox,
  MuiThemeProvider,
  Tooltip,
  createMuiTheme,
} from "@material-ui/core";

import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import ShortAddress from "../common/ShortAddress";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  root: {
    width: "100%",
    padding: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  fieldlength: {},
}));
const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(language, languageSet, theme) {
  return {
    fontWeight:
      languageSet.indexOf(language) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const BoardMemberRequest = (props) => {
  const {
    authUser,
    setAuthUser,
    isSuperAdmin,
    isAdmin,
    isVolunteer,
    region,
    regions,
    setRegion,
    orgList,
  } = useContext(appContext);
  const theme = useTheme();
  const initialState = {
    title: "",
    brief: "",
    description: "",
    numberOfCommitsPerYear: "",
    fundsRecipient: "",
    firstName: "",
    lastName: "",
    email: "",
  };
  const [closeSideMenu] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const classes = useStyles();
  const phoneRef = useRef(phone);
  const [description, setDescription] = useState("");
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [selectedFunctionalExpertise, setSelectedFunctionalExpertise] =
    useState([]);
  const [selectedPersonalTraits, setSelectedPersonalTraits] = useState([]);
  const [languageSet, setLanguageSet] = React.useState([]);
  const [editMode, setEditMode] = useState(false);
  const [userId, setUserId] = useState("");
  const [id, setId] = useState("");
  const [term, setTerm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");
  const handleTerm = (event) => {
    if (event.target.checked) {
      setTerm(true);
    } else {
      setTerm(false);
    }
  };
  const [functionalExpertise, setFunctionalExpertise] = React.useState({
    "Procurement & Supply Chain": false,
    "Growth & Scaleup": false,
    Management: false,
    Operations: false,
    "Data Analytics": false,
    "Product Development": false,
    "ESG & Sustainability": false,
    Entrepreneurship: false,
    "Sales Leadership": false,
    "Charity/NGO": false,
    Fundraising: false,
  });

  const [personalTraits, setPersonalTraits] = React.useState({
    Trustworthy: false,
    Communicator: false,
    Approchable: false,
    Leadership: false,
    Diligent: false,
    Gravitas: false,
    Entrepreneur: false,
    Innovation: false,
    Commercial: false,
    Analytics: false,
    Critical: false,
    Networker: false,
  });
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [imageUpload, setImageUpload] = useState(false);
  const [openOrgModal, setOpenOrgModal] = useState(false);

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
    saveDonationRequest(url);
  };

  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
    };
    if (
      authUser &&
      authUser.unique_id &&
      donationRequest &&
      donationRequest.user_info &&
      authUser.unique_id == donationRequest.user_info._id
    ) {
      finalValues.status = 0;
    } else if (!editMode) {
      finalValues.status = 0;
    }
    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
        toastr.success(
          "Default profile Image is set for this Board Member request."
        );
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the Board Member request. " +
            error.message
        );
      });
  };
  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const handleChangeFunctionalExpertise = (event) => {
    const value = event.target.value;
    if (value === "all") {
      if (!event.target.checked) {
        setFunctionalExpertise(
          Object.keys(functionalExpertise).reduce((item, key) => {
            item[key] = false;
            return item;
          }, {})
        );
        setSelectedFunctionalExpertise([]);
      } else {
        setFunctionalExpertise(
          Object.keys(functionalExpertise).reduce((item, key) => {
            item[key] = true;
            return item;
          }, {})
        );
        setSelectedFunctionalExpertise([...Object.keys(functionalExpertise)]);
      }

      return;
    }
    setFunctionalExpertise({
      ...functionalExpertise,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      setSelectedFunctionalExpertise([
        ...selectedFunctionalExpertise,
        event.target.name,
      ]);
    } else {
      setSelectedFunctionalExpertise(
        selectedFunctionalExpertise.filter((item) => item !== event.target.name)
      );
    }
  };

  const handleChangePersonalTraits = (event) => {
    setPersonalTraits({
      ...personalTraits,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      setSelectedPersonalTraits([...selectedPersonalTraits, event.target.name]);
    } else {
      setSelectedPersonalTraits(
        selectedPersonalTraits.filter((perk) => perk !== event.target.name)
      );
    }
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handleCreatePost = () => {
    const errors = {};
    if (activeStep === 0) {
      const userValues = {};
      const userErrors = {};
      if (!values.firstName) {
        userErrors.firstName = "Please enter First name";
      }
      if (!values.lastName) {
        userErrors.lastName = "Please enter Last name";
      }
      userValues.name = {
        first_name: values.firstName,
        last_name: values.lastName,
      };
      userValues.email = values.email;
      //validate the phone input
      if (!phoneRef.current.isValid()) {
        return;
      }
      if (values.fundsRecipient === "self") {
        userValues.orgId = "Self";
      } else {
        userValues.orgId = values.fundsRecipient;
      }
      if (!values.fundsRecipient) {
        userErrors.fundsRecipient = "Please select one";
      }
      userValues.linkedInProfileUrl = values.linkedInProfileUrl;
      setErrors(userErrors);
      if (userErrors && Object.keys(userErrors).length > 0) {
        // toastr.error("Please enter the mandatory fields ");
        return;
      }
      if (Object.keys(userErrors).length == 0) {
        setActiveStep(activeStep + 1);
        updateProfile(authUser.unique_id, userValues)
          .then((response) => {
            setAuthUser(response.data.data);
            if (authUser.unique_id) {
              localStorage.setItem(
                "authUser",
                JSON.stringify(response.data.data)
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                toastr.error(error.response.data.errors[0].msg);
              } else {
                toastr.error(error.response.message);
              }
            } else {
              toastr.error(error.message);
            }
          });
      }
    } else if (activeStep === 1) {
      const finalValues = {};
      if (values.title.length === 0) {
        errors.title = "Please enter Title";
      } else if (values.title && values.title.length > 50) {
        errors.title = "Please enter title of 50 characters";
      }
      if (values.brief.length === 0) {
        errors.brief = "Please enter Brief";
      } else if (values.brief && values.length > 120) {
        errors.brief = "Please enter brief of 120 characters";
      }
      if (description.length === 0) {
        errors.description = "Please enter Description";
      } else if (description && description.length <= 10) {
        errors.description = "Please enter atleast 10 characters";
      }
      if (
        !tempAddress ||
        !tempAddress.city ||
        !tempAddress.state ||
        !tempAddress.country
      ) {
        errors.address = "Please select country, state and city";
      }
      if (
        selectedFunctionalExpertise &&
        selectedFunctionalExpertise.length < 1
      ) {
        errors.functionalexpertise = "Please select at least one";
      }
      if (selectedPersonalTraits && selectedPersonalTraits.length < 3) {
        errors.personaltarits = "Please select at least three";
      }
      if (values.numberOfCommitsPerYear.length == 0) {
        errors.numberOfCommitsPerYear = "Please enter no.of commits per year";
      } else if (
        parseInt(values.numberOfCommitsPerYear) < 1 &&
        parseInt(values.numberOfCommitsPerYear) > 100
      ) {
        errors.numberOfCommitsPerYear = "Please enter between 1 to 100";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      finalValues.request_type = "board member";
      finalValues.title = values.title;
      finalValues.brief = values.brief;
      finalValues.description = description;
      finalValues.user_id = authUser.unique_id;
      finalValues.quantity = 1;
      finalValues.orgId = values.fundsRecipient;
      setAddress(tempAddress);
      finalValues.region = region && region._id;
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
      const additionalInfo = {
        functionalExpertise: selectedFunctionalExpertise,
        personalTraits: selectedPersonalTraits,
        numberOfCommitsPerYear: parseInt(values.numberOfCommitsPerYear),
        languages: languageSet,
      };
      finalValues.additionalInfo = additionalInfo;
      if (Object.keys(errors).length == 0) {
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Board Member request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
              // setActiveStep(0);
            });
        }
      }
    } else if (activeStep === 2) {
      if (!defaultImageUrl) {
        errors.image = "Please upload at least one image";
      }
      if (term === false) {
        errors.term = "Please select the checkboxes";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
        setErrors({});
        if (imageUpload) {
          toastr.success(
            "Your Board Member Request is successfully" +
              (props.match.params.id ? " updated" : " created ")
          );
          props.history.push(`/donationRequest/${name}`);
        }
      }
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleNext);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
    saveDonationRequest();
  };
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  const loadRequestData = () => {
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          if (
            isSuperAdmin ||
            isAdmin ||
            isVolunteer ||
            (response &&
              (response.status === Number(STATUS_NEW) ||
                response.status === Number(STATUS_INCOMPLETE) ||
                response.status === Number(STATUS_APPROVED)) &&
              response.user_info &&
              authUser.unique_id === response.user_info._id)
          ) {
            setEditMode(true);
          } else {
            setEditMode(false);
          }
          if (editMode) {
            setDonationRequest(response);
            setId(response._id);
            setDefaultImageUrl(response.defaultImageUrl);
            values.fundsRecipient =
              response.orgId && response.orgId._id
                ? response.orgId && response.orgId._id
                : "self";
            values.title = response.title;
            values.brief = response.brief;
            values.numberOfCommitsPerYear =
              response.additionalInfo &&
              response.additionalInfo.numberOfCommitsPerYear;
            setName(response.name);
            response.additionalInfo &&
              response.additionalInfo.functionalExpertise.map((item) => {
                functionalExpertise[item] = true;
              });
            response.additionalInfo &&
              response.additionalInfo.personalTraits.map((item) => {
                personalTraits[item] = true;
              });
            const functionalExpertiseAsArray = getKeyByValue(
              functionalExpertise,
              true
            );
            setFunctionalExpertise(functionalExpertise);
            setSelectedFunctionalExpertise(functionalExpertiseAsArray);
            const personalTraitsAsArray = getKeyByValue(personalTraits, true);
            setPersonalTraits(personalTraits);
            setSelectedPersonalTraits(personalTraitsAsArray);
            setLanguageSet(
              response.additionalInfo && response.additionalInfo.languages
            );
            setValues(values);
            setDescription(response.description);
            setImageUpload(true);
            setTerm(true);
            response.shipping_address &&
              setAddress({
                line1: response.shipping_address.line1,
                line2: response.shipping_address.line2,
                city: response.shipping_address.city,
                state: response.shipping_address.state,
                zip_code: response.shipping_address.zip_code,
                country: response.shipping_address.country,
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };

  useEffect(() => {
    if (props.match.params.id) {
      loadRequestData(props.match.params.id);
    }
  }, [props.match.params.id, authUser, editMode]);

  useEffect(() => {
    if (props.match.params.id) {
      loadRequestData(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      let userData = authUser;
      setValues((currentValues) => {
        return {
          ...currentValues,
          firstName: userData.name.first_name,
          lastName: userData.name.last_name,
          email: userData.email,
          linkedInProfileUrl: userData.linkedInProfileUrl,
        };
      });
      setPhone(userData.phone);
      setIsPhoneVerified(userData.phone_verified);
    }
  }, [authUser]);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <Sidemenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/boardmember.png"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "10px",
                      borderRadius: "75%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Board Member Request
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Use this form to receive applications for board members from
                    our TALLeaders group.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid className={classes.root}>
              <div>
                <div className="field-length">
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Verify your information and select the organization
                        </Typography>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={12}>
                          <InputLabel className="secFormLabel">
                            First Name <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            className="profile-fields fname"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="firstName"
                            //disabled={!editMode}
                            value={values.firstName}
                            onChange={changeHandler}
                          />
                          {errors && errors.firstName && (
                            <Typography className="custom-error">
                              {errors.firstName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <InputLabel className="secFormLabel">
                            Last Name <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%" }}
                            name="lastName"
                            //disabled={!editMode}
                            value={values.lastName}
                            onChange={changeHandler}
                          />
                          {errors && errors.lastName && (
                            <Typography className="custom-error">
                              {errors.lastName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <InputLabel className="secFormLabel">
                            Email
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="email"
                            disabled={!editMode}
                            value={values.email}
                            onChange={changeHandler}
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ width: "100%" }}
                        >
                          <InputLabel className="secFormLabel">
                            Phone <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <PhoneNumber
                            phone={phone}
                            isPhoneVerified={isPhoneVerified}
                            ref={phoneRef}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            style={{ width: "100%", padding: "10px 0px" }}
                            className="recipient"
                            error={errors && errors.fundsRecipient}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{ padding: "5px 23px" }}
                            >
                              Select/Add Organization
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.fundsRecipient}
                              onChange={changeHandler}
                              variant="outlined"
                              placeholder="Organization"
                              // className={`input-field request-amount ${
                              //   errors && errors.fundsRecipient
                              //     ? "bg-red"
                              //     : values.fundsRecipient
                              //     ? "bg-green"
                              //     : "bg-normal"
                              // }`}
                              name="fundsRecipient"
                              label="Select/Add Organization"
                            >
                              <MenuItem value={"self"}>Self</MenuItem>
                              {orgList &&
                                orgList.length > 0 &&
                                orgList.map((org) => {
                                  return (
                                    <MenuItem
                                      value={org && org.orgId && org.orgId._id}
                                    >
                                      <img
                                        src={
                                          org &&
                                          org.orgId &&
                                          org.orgId.defaultImageUrl
                                            ? org.orgId.defaultImageUrl
                                            : "/images/orgLogo.png"
                                        }
                                        alt="orgLogo"
                                        width="20px"
                                        style={{ marginRight: 8 }}
                                      />{" "}
                                      {org && org.orgId && org.orgId.orgName}{" "}
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          textAlign: "end",
                                        }}
                                      >
                                        {org &&
                                        org.orgId &&
                                        org.orgId.status === 1 ? (
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <ErrorOutlineIcon
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </span>
                                    </MenuItem>
                                  );
                                })}
                              <MenuItem
                                value="add"
                                onClick={handleOrganization}
                              >
                                Add Organization
                              </MenuItem>
                            </Select>{" "}
                            <FormHelperText>
                              {errors.fundsRecipient}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Linkedin URL
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%", marginTop: "10px" }}
                            name="linkedInProfileUrl"
                            //disabled={!editMode}
                            label="Enter linkedIn profile url"
                            value={values.linkedInProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.linkedInProfileUrl && (
                            <Typography className="custom-error">
                              {errors.linkedInProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Box className="board-btns">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "-12px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => props.history.push(HOME_PAGE)}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "-12px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={handleCreatePost}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Grid items xs={12} className="pos-rel">
                        <InputLabel
                          className="secFormLabel"
                          style={{ paddingLeft: "10px" }}
                        >
                          Title <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          type="text"
                          className="profile-fields fname"
                          variant="outlined"
                          style={{ width: "100%", padding: "10px" }}
                          name="title"
                          value={values.title}
                          onChange={changeHandler}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "18px",
                            marginLeft: "5px",
                            position: "absolute",
                            top: "10px",
                            color: "#ccc",
                            right: "0px",
                            padding: "20px",
                          }}
                        >
                          {" "}
                          {values.title ? values.title.length : 0}/50
                        </span>
                        {errors && errors.title && (
                          <Typography className="custom-error-global">
                            {errors.title}
                          </Typography>
                        )}
                      </Grid>
                      <Grid items xs={12} className="pos-rel">
                        <InputLabel
                          className="secFormLabel"
                          style={{ paddingLeft: "10px", paddingTop: "10px" }}
                        >
                          Brief <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                          type="text"
                          className="profile-fields fname"
                          variant="outlined"
                          style={{ width: "100%", padding: "10px" }}
                          name="brief"
                          value={values.brief}
                          onChange={changeHandler}
                          inputProps={{
                            maxLength: 120,
                          }}
                        />
                        <span
                          style={{
                            fontSize: "18px",
                            marginLeft: "5px",
                            position: "absolute",
                            top: "20px",
                            color: "#ccc",
                            right: "0px",
                            padding: "20px",
                          }}
                        >
                          {" "}
                          {values.brief ? values.brief.length : 0}/120
                        </span>
                        {errors && errors.brief && (
                          <Typography className="custom-error-global">
                            {errors.brief}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className=" recipient"
                          error={errors && errors.address}
                        >
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#000",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            Select your location{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <ShortAddress
                            value={address}
                            onChange={handleChangeAddress}
                          />
                          <FormHelperText>{errors.address}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#263238",
                              paddingLeft: "10px",
                              paddingTop: "10px",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            Description<span style={{ color: "red" }}>*</span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl
                            style={{ width: "100%", padding: "10px" }}
                            className="recipient"
                            error={errors && errors.description}
                          >
                            <Grid className="ck-editor-css">
                              <CKEditor
                                style={{ padding: "10px 20px" }}
                                maxLength={300}
                                data={description}
                                name="description"
                                editor={ClassicEditor}
                                onChange={changeDescriptionHandler}
                                config={{
                                  toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "Link",
                                  ],
                                  placeholder:
                                    "Give description about this Board Member Request",
                                }}
                              />
                            </Grid>
                            {errors && errors.description && (
                              <FormHelperText>
                                {errors.description}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                          }}
                          gutterBottom
                        >
                          Functional Expertise & Other
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.functionalexpertise}
                        >
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <FormControl
                                style={{ paddingRight: "20px" }}
                                sx={{ m: 3 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        classes={{
                                          indeterminate:
                                            classes.indeterminateColor,
                                        }}
                                        indeterminate={
                                          selectedFunctionalExpertise &&
                                          selectedFunctionalExpertise.length >
                                            0 &&
                                          selectedFunctionalExpertise.length <
                                            Object.keys(functionalExpertise)
                                              .length
                                        }
                                        checked={
                                          Object.keys(functionalExpertise)
                                            .length ===
                                          selectedFunctionalExpertise.length
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        value="all"
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise[
                                            "Procurement & Supply Chain"
                                          ]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Procurement & Supply Chain"
                                      />
                                    }
                                    label="Procurement & Supply Chain"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise[
                                            "Growth & Scaleup"
                                          ]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Growth & Scaleup"
                                      />
                                    }
                                    label="Growth & Scaleup"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise["Management"]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Management"
                                      />
                                    }
                                    label="Management"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise[
                                            "ESG & Sustainability"
                                          ]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="ESG & Sustainability"
                                      />
                                    }
                                    label="ESG & Sustainability"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise[
                                            "Sales Leadership"
                                          ]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Sales Leadership"
                                      />
                                    }
                                    label="Sales Leadership"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <FormControl
                                style={{ paddingLeft: "-1px" }}
                                component="fieldset"
                                sx={{ m: 3 }}
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise["Fundraising"]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Fundraising"
                                      />
                                    }
                                    label="Fundraising"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise["Operations"]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Operations"
                                      />
                                    }
                                    label="Operations"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise["Data Analytics"]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Data Analytics"
                                      />
                                    }
                                    label="Data Analytics"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise[
                                            "Product Development"
                                          ]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Product Development"
                                      />
                                    }
                                    label="Product Development"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise[
                                            "Entrepreneurship"
                                          ]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Entrepreneurship"
                                      />
                                    }
                                    label="Entrepreneurship"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          functionalExpertise["Charity/NGO"]
                                        }
                                        onChange={
                                          handleChangeFunctionalExpertise
                                        }
                                        name="Charity/NGO"
                                      />
                                    }
                                    label="Charity/NGO"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <FormHelperText>
                            {errors && errors.functionalexpertise}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginLeft: "10px" }}>
                        <Box component="h5" mt={2} mb={2} className="clrblack">
                          <InputLabel className="secFormLabel">
                            Personal Traits
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                        </Box>
                        <p>
                          Please select MAXIMUM of 3 personal traits that are
                          essential the incoming board members should possess.
                          These traits could be culturally important to fit with
                          your company, or traits you perceive to be crucial to
                          the board member to have the desired impact on your
                          organization.
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.personaltarits}
                        >
                          <Grid container>
                            <Grid item sm={6} xs={12}>
                              <FormControl
                                style={{ paddingRight: "20px" }}
                                sx={{ m: 3 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Trustworthy"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Trustworthy"
                                      />
                                    }
                                    label="Trustworthy"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Communicator"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Communicator"
                                      />
                                    }
                                    label="Communicator"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Approchable"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Approchable"
                                      />
                                    }
                                    label="Approchable"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Leadership"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Leadership"
                                      />
                                    }
                                    label="Leadership"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Diligent"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Diligent"
                                      />
                                    }
                                    label="Diligent"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Gravitas"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Gravitas"
                                      />
                                    }
                                    label="Gravitas"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <FormControl
                                style={{ paddingLeft: "-1px" }}
                                component="fieldset"
                                sx={{ m: 3 }}
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Entrepreneur"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Entrepreneur"
                                      />
                                    }
                                    label="Entrepreneur"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Innovation"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Innovation"
                                      />
                                    }
                                    label="Innovation"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Commercial"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Commercial"
                                      />
                                    }
                                    label="Commercial"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Analytics"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Analytics"
                                      />
                                    }
                                    label="Analytics"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Critical"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Critical"
                                      />
                                    }
                                    label="Critical"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={personalTraits["Networker"]}
                                        onChange={handleChangePersonalTraits}
                                        name="Networker"
                                      />
                                    }
                                    label="Networker"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <FormHelperText>
                            {errors && errors.personaltarits}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#000",
                              paddingLeft: "10px",
                              paddingTop: "20px",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            Number of commits per year
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "20px",
                            }}
                            error={errors && errors.numberOfCommitsPerYear}
                          >
                            <TextField
                              fullWidth
                              type="number"
                              min="1"
                              max="100"
                              variant="outlined"
                              style={{
                                fontWeight: "400",
                                fontSize: "15px",
                                color: "#000000",
                                width: "100%",
                              }}
                              name="numberOfCommitsPerYear"
                              label="Number Of Commits Per Year"
                              placeholder="Enter number of commits per year"
                              value={values.numberOfCommitsPerYear}
                              onChange={changeHandler}
                            />
                            <FormHelperText>
                              {errors.numberOfCommitsPerYear}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="p"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#000",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                          gutterBottom
                        >
                          Languages
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ width: "100%", padding: "10px" }}
                          className="recipient"
                          error={errors && errors.languages}
                        >
                          <Autocomplete
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={[]}
                            value={languageSet}
                            onChange={(e, newval) => {
                              setLanguageSet(newval);
                            }}
                            defaultValue={[]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Languages"
                                placeholder="Type a language and press Enter key to enter more languages"
                              />
                            )}
                          />
                          <FormHelperText>{errors.languages}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Box className="board-btns">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "-12px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "-12px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 2 && term ? false : true}
                            onClick={handleCreatePost}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <div className="request-step-two">
                        <div className="request-two-step">
                          <h3 className="step-two-title">
                            Add a cover photo or video
                          </h3>
                          <p className="image-desc">
                            This is the image that will feature right on top of
                            your page. Make it impactful!
                          </p>
                          <div className="fund-img-txt">
                            <p> A picture is worth a thousand words</p>
                            <ul>
                              <li>
                                <span>Keep it relevant</span>
                              </li>
                              <li>
                                <span>Keep it clear</span>
                              </li>
                              <li>
                                <span>Types: .PNG, .JPG, .BMP, .MP4</span>
                              </li>
                              <li>
                                <span>Size: 700px X 400px</span>
                              </li>
                            </ul>
                          </div>
                          <MuiThemeProvider
                            theme={theme2}
                            className="req-tooltip-img"
                          >
                            <img
                              src={
                                defaultImageUrl
                                  ? defaultImageUrl
                                  : "/images/default-req-img.gif"
                              }
                              alt="default"
                              className="fund-cover-img"
                            />
                          </MuiThemeProvider>
                          {errors && errors.image && (
                            <div className="custom-error">{errors.image}</div>
                          )}
                          <div className="add-btn-fund">
                            <Button
                              style={{
                                font: "500 12px Roboto",
                                padding: "20px 0px",
                                margin: "10px 5px",
                                background: "transparent",
                                color: "#283E4A",
                                border: "1px solid #283E4A",
                              }}
                              onClick={() => setShowingAddPhotoModal(true)}
                            >
                              <FontAwesomeIcon
                                color="#283E4A"
                                size="1x"
                                style={{ marginRight: 6 }}
                                icon={faFileUpload}
                              />{" "}
                              Add Images
                            </Button>
                          </div>
                        </div>
                      </div>
                      <Grid item xs={12}>
                        <FormControl error={errors && errors.term}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="term"
                                checked={term}
                                onChange={handleTerm}
                                required="required"
                              />
                            }
                            label={
                              <div className="linktag">
                                <span>
                                  {" "}
                                  By submitting the form you agree to the{" "}
                                </span>
                                <a
                                  href="https://www.touchalife.org/terms-and-conditions/"
                                  target="blank"
                                  style={{ color: "#0000FF" }}
                                >
                                  Terms of Use
                                </a>{" "}
                                and{" "}
                                <a
                                  href="https://www.touchalife.org/privacy-policy/"
                                  target="blank"
                                  style={{ color: "#0000FF" }}
                                >
                                  our Privacy Policy
                                </a>
                                .{" "}
                              </div>
                            }
                          />
                          <FormHelperText>{errors.term}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Box display="flex" justifyContent="flex-end">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "-12px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "-12px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 2? false : true}
                            onClick={handleCreatePost}
                          >
                            {editMode ? "Update" : "Create"}
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
        multiple={true}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(BoardMemberRequest);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import csstyle from "./TalHeros.module.scss";
import { Container, Grid,Box, Button } from "@material-ui/core";
import NominateAdd from "./NominateAdd";


const TalHeros = (props) => {

  const [openOrgModal, setOpenOrgModal] = useState(false);

  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    props.history.push(`/NominateAdd/${orgId}`);
  };
  
    return (
        <Grid >
        <Grid className={csstyle.main_head}  >
        <Container >
          <Grid container > 
            <Grid item xs={6} >
                <Grid className={csstyle.banner_titles}>
              <h1>TALHeros Awards</h1>
              <p>Recommend for TALHeros Nomination</p>
              </Grid>
            </Grid>
            <Grid item xs={6}>
                <Box className={csstyle.trophy_side}>
              <img src="./../images/trophy.png" alt=" "/>
              </Box>
            </Grid>
          </Grid>
        </Container>
        </Grid>
        <Container >
        
        <Grid container className={csstyle._empower_box_grid} >
        <Grid item md={4}   className={csstyle._empower_box_in}>
            <Grid className={csstyle._empower_ele}>
              <Box className={csstyle._make_img}>
                <img src="./../images/Group1.jpg" alt="" />
                </Box>
                <Box >
                <p className={csstyle.txt_style}>Individuals</p>
                <p>Design Thinking is a  5-steps process to come up  with  meaningful ideas  that  solves rea...</p>
                </Box>
                <Grid className={csstyle.add_fund_btn} >
                    <Button onClick={handleOrganization} >
                        Nominate Individuals
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid item md={4} className={csstyle._empower_box_in}>
            <Grid className={csstyle._empower_ele}>
              <Box className={csstyle._make_img}>
                <img src="./../images/Frame1.jpg" alt="" />
                </Box>
                <Box>
                <p  className={csstyle.txt_style}>NonProfits</p>
                <p>Design Thinking is a  5-steps process to come up  with  meaningful ideas  that  solves rea...</p>
                </Box>
                <Grid className={csstyle.add_fund_btn} >
                    <Button  onClick={handleOrganization} >
                        Nominate Nonprofits
                    </Button>
                </Grid>

            </Grid>
        </Grid>
        <Grid item md={4} className={csstyle._empower_box_in}>
            <Grid className={csstyle._empower_ele}>
              <Box className={csstyle._make_img}>
                <img src="./../images/Group2.jpg" alt="" />
                </Box>
                <Box >
                <p className={csstyle.txt_style}> Corporate</p>
                <p>Design Thinking is a  5-steps process to come up  with  meaningful ideas  that  solves rea...</p>
                </Box>
                <Grid className={csstyle.add_fund_btn} >
                    <Button  onClick={handleOrganization} >
                        Nominate Corporate
                    </Button>
                </Grid>

            </Grid>
        
        
      </Grid>

        </Grid>
        </Container>
        <NominateAdd 
          openOrgModal={openOrgModal}
          onCloseModal={(e) => setOpenOrgModal(false)}
          onSavingOrg={onSavingOrg}
        />
      </Grid>
      
        
      )

}
  

export default withRouter(TalHeros) ;
import React, { Fragment, useState } from "react";
import { Tooltip } from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import MailIcon from "@material-ui/icons/Mail";
import ShareThroughEmail from "../common/ShareThroughEmail";

const ShareDonationRequest = (props) => {
  const [openShareEmail, setOpenShareEmail] = useState(false);

  const shareThroughMail = () => {
    setOpenShareEmail(!openShareEmail);
  };
  const { donationRequest } = props;
  return (
    <Fragment>
      <Tooltip title="Share on Facebook">
        <FacebookShareButton
          url={`${process.env.REACT_APP_API_BASE_URL}/donationRequestShare/${donationRequest.name}`}
          quote={donationRequest.title}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </Tooltip>{" "}
      <Tooltip title="Share on Twitter">
        <TwitterShareButton
          url={`${process.env.REACT_APP_API_BASE_URL}/donationRequestShare/${donationRequest.name}`}
          quote={donationRequest.title}
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </Tooltip>{" "}
      <Tooltip title="Share on LinkedIn">
        <LinkedinShareButton
          url={`${process.env.REACT_APP_API_BASE_URL}/donationRequestShare/${donationRequest.name}`}
          quote={donationRequest.title}
        >
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
      </Tooltip>{" "}
      <Tooltip title="Share on WhatsApp">
        <WhatsappShareButton
          url={`${process.env.REACT_APP_API_BASE_URL}/donationRequestShare/${donationRequest.name}`}
          quote={donationRequest.title}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </Tooltip>
      <Tooltip title="Share by Email">
        <MailIcon
          onClick={(e) => shareThroughMail(e)}
          style={{
            color: "#fff",
            background: "#7777ff",
            borderRadius: "50%",
            padding: 6,
            marginLeft: 5,
            fontSize: 28,
            cursor: "pointer",
          }}
        />
      </Tooltip>
      {donationRequest && openShareEmail && openShareEmail === true && (
        <ShareThroughEmail
          isOpen={openShareEmail}
          subject={`[Touch-A-Life] Have you seen the ${
            donationRequest.request_type === "cash" ? "fundraiser" : "request"
          } "${donationRequest.title}"?`}
          content={`Hello,<br /><br />I thought you might be interested in supporting this ${
            donationRequest.request_type
          } request:<br /> ${process.env.REACT_APP_BASE_URL}/donationRequest/${
            donationRequest.name
          } <br /><br /><img width="400" id=${donationRequest.name} src=${
            donationRequest.defaultImageUrl
          }/><br/><br /> ${
            donationRequest.request_type === "cash"
              ? " Even a small donation could help and reach their fundraising goal. And if you can't make a donation, it would be great if you could share the fundraiser to help spread the word. Please contribute to the fundraiser that I am sharing with you."
              : ""
          } <br /><br /> Thanks, <br />Touch-A-Life Foundation`}
          onClose={(e) => setOpenShareEmail(false)}
          donationRequestDetails={donationRequest}
        />
      )}
    </Fragment>
  );
};

export default ShareDonationRequest;

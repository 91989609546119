import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NewAddress from "../common/NewAddress";
import FormControl from "@material-ui/core/FormControl";
import { appContext } from "../../App";
import Tabs from "@material-ui/core/Tabs";
import cssstyles from "./FeedbackTalLeaders.module.scss";
import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
// import noValidate from "./noValidate";
import useFormValidation from "../../hooks/useFormValidation";
import { addOrganization, updateOrganizationStatus } from "../../utils/api";
import toastr from "toastr";
// import "./Organization.scss";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Tab,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import PhoneNumber from "../Phone/PhoneNumber";
import { useRef } from "react";
import PhoneInput from "react-phone-number-input";
import Avatar from "react-avatar";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "70vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },

  orgTitle: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "600",
    textTransform: "inheirt",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const FeedbackTalAwards = (props) => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    occupation: "",
    gender: "",
    audioRoom: "",
  };
  const classes = useStyles();
  const { authUser, orgList, setOrgList, userLocaleInfo } =
    useContext(appContext);
  const [id, setId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phone, setPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  //   const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const updateDefaultImageUrl = (newImageUrl) => {
    setDefaultImageUrl(newImageUrl);
    setOrgList(
      orgList.map((org) => {
        if (org && org.orgId && org.orgId._id === id) {
          org.orgId.defaultImageUrl = newImageUrl;
        }
        return org;
      })
    );
  };

  useEffect(() => {
    if (defaultImageUrl) {
      updateDefaultImageUrl(defaultImageUrl);
    }
  }, [defaultImageUrl]);

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const { orgDetails } = props;

  useEffect(() => {
    if (orgDetails && orgDetails.orgAddress) {
      setAddress({
        line1: orgDetails.orgAddress.hasOwnProperty("line1")
          ? orgDetails.orgAddress.line1
          : "",
        line2: orgDetails.orgAddress.hasOwnProperty("line2")
          ? orgDetails.orgAddress.line2
          : "",
        city: orgDetails.orgAddress.hasOwnProperty("city")
          ? orgDetails.orgAddress.city
          : "",
        state: orgDetails.orgAddress.hasOwnProperty("state")
          ? orgDetails.orgAddress.state
          : "",
        zip_code: orgDetails.orgAddress.hasOwnProperty("zip_code")
          ? orgDetails.orgAddress.zip_code
          : "",
        country: orgDetails.orgAddress.hasOwnProperty("country")
          ? orgDetails.orgAddress.country
          : "",
      });
    }
    if (orgDetails) {
      setId(orgDetails._id);
      setDefaultImageUrl(orgDetails.defaultImageUrl);
      setEditMode(true);
    }
  }, [orgDetails]);

  const handleAddOrg = () => {
    const finalValues = {};
    finalValues.userId = authUser && authUser.unique_id;
    finalValues.orgName = values.name ? values.name : orgDetails.orgName;
    finalValues.orgType = values.type ? values.type : orgDetails.orgType;
    finalValues.description = values.description
      ? values.description
      : orgDetails.description;
    finalValues.orgEmail = values.email
      ? values.email
      : orgDetails && orgDetails.orgEmail;
    finalValues.websiteUrl = values.website
      ? values.website
      : orgDetails && orgDetails.websiteUrl;
    finalValues.defaultImageUrl = defaultImageUrl;
    finalValues.hasCsrWing = values.isCSRWing;
    if (
      tempAddress &&
      (tempAddress.line1 || tempAddress.line2) &&
      tempAddress.city &&
      tempAddress.state &&
      tempAddress.country &&
      tempAddress.zip_code
    ) {
      finalValues.orgAddress = {
        line1: tempAddress.line1,
        line2: tempAddress.line2,
        city: tempAddress.city,
        state: tempAddress.state,
        country: tempAddress.country,
        zip_code: tempAddress.zip_code,
      };
      setAddressError("");
    } else {
      setAddressError("Please provide the full address of the Organization.");
      return;
    }
    if (editMode && orgDetails && orgDetails._id) {
      updateOrganizationStatus(orgDetails._id, finalValues).then((resp) => {
        if (resp.status === 200) {
          setIsOpen(true);
          toastr.success(resp.data.message);
          props.onSavingOrg(orgDetails._id);
          setId(orgDetails._id);
          setOrgList((currentOrgs) => {
            return [...currentOrgs, { orgId: resp.data }];
          });
        }
      });
    } else if (step === 1 && !editMode) {
      addOrganization(finalValues)
        .then((resp) => {
          setIsOpen(true);
          if (resp.statusCode === 200) {
            toastr.success(resp.message);
            const org = resp.data;
            setStep(2);
            setId(resp.data.orgId);
            setOrgList((currentOrgs) => {
              return [
                ...currentOrgs,
                { ...org, orgId: { _id: resp.data.orgId, ...finalValues } },
              ];
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toastr.error(
              "That organization name is already taken. Please use a different name"
            );
          } else {
            toastr.error(error.response.data.message);
          }
        });
    } else if (step === 2 && !editMode) {
      const valuesToSave = {};
      valuesToSave.defaultImageUrl = defaultImageUrl;
      updateOrganizationStatus(id, valuesToSave).then((resp) => {
        if (resp.status === 200) {
          setId(resp.data.data._id);
          setOrgList(
            orgList.map((org) => {
              if (org && org.orgId && org.orgId._id === id) {
                org.orgId.defaultImageUrl = defaultImageUrl;
              }
              return org;
            })
          );
          // setOrgList((currentOrgs) => {
          //   return [...currentOrgs, { orgId: resp.data.data }];
          // });
          toastr.success(resp.data.message);
          props.onSavingOrg(id);
        }
      });
    }
  };
  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    handleAddOrg
  );

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openOrgModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openOrgModal}>
          <div className={classes.paper}>
            <div className="tab-bar">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.orgTitle}>Nominated to Barry Rudolp</h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  display: "grid",
                  placeContent: "center",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon  />
              </span>
            </div>
              <div className={cssstyles.cards_style}>
                <Grid item xs={6} className={cssstyles.img_align}>
                  <div className={cssstyles.img_chge}>
                    <img src="../images/tal-leader-icon.png" />
                  </div>
                  <div className={cssstyles.name_txt}>
                    <span className={cssstyles.name_title}>Barry Rudolp</span>
                    <p>
                      President <span>TiE, Hyderabad</span>
                      <p>
                        Founder & CEO <span>Feuji, Inc</span>
                      </p>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6} className={cssstyles.link_btn}>
                  <Button>
                    <img src="../images/linkedin.svg" />
                  </Button>
                </Grid>
              </div>
              <Grid item xs ={12}>
                <div className={cssstyles.waring_text}>
                    <span>
                    Touch-A-Life Foundation Leadership Team supports innovative ways
                    to leverage 
                    technology to support donation and kindness.

                    </span>
                </div>
              </Grid>
              <Divider />
              <div className={cssstyles.cards_style}>
                <Grid item xs={6} className={cssstyles.img_align}>
                  <div className={cssstyles.img_chge}>
                    <img src="../images/tal-leader-icon.png" />
                  </div>
                  <div className={cssstyles.name_txt}>
                    <span className={cssstyles.name_title}>Barry Rudolp</span>
                    <p>
                      President <span>TiE, Hyderabad</span>
                      <p>
                        Founder & CEO <span>Feuji, Inc</span>
                      </p>
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6} className={cssstyles.link_btn}>
                  <Button>
                    <img src="../images/linkedin.svg" />
                  </Button>
                </Grid>

              </div>
              <Grid item xs ={12}>
                <div style={{background:"#eee",margin:"15px",padding:"15px",borderRadius:"10px",color:"#060606"}}>
                    <span>
                    Touch-A-Life Foundation Leadership Team supports innovative ways
                    to leverage 
                    technology to support donation and kindness.

                    </span>
                </div>
              </Grid>

              <Divider />

              <Divider />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default withRouter(FeedbackTalAwards);

import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import EmailCampaign from "./EmailCampaign";
import { getContactEmailTemplates } from "../../utils/api";
import { appContext } from "../../App";

import "./EmailEdit.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    borderRadius: "0",
  },

  span: {
    background: "rgb(205, 38, 39)",
  },
}));

export default function ContactEmailCampaignTemplate() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const { selectedOrganization } = useContext(appContext);

  useEffect(() => {
    if (selectedOrganization) {
      getContactEmailTemplates(selectedOrganization._id).then((response) => {
        setEmailTemplates(response.data.data);
      });
    } else {
      getContactEmailTemplates(selectedOrganization).then((response) => {
        setEmailTemplates(response.data.data);
      });
    }
  }, [selectedOrganization]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper
        className={classes.root}
        style={{ borderBottom: "1px solid #f6f7fb" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="primary"
          aria-label="email"
        >
          <Tab
            label="Image Based Email"
            {...a11yProps(0)}
            stle={{ padding: "0" }}
          />
          <Tab label="Text Email" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <EmailCampaign type="image" emailTemplates={emailTemplates} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmailCampaign type="text" emailTemplates={emailTemplates} />
      </TabPanel>
    </div>
  );
}

import React, { useState, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { TextField, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AddDocument from "../common/AddDocument";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DateFnsUtils from "@date-io/date-fns";
import validatePlasmaRequest from "./validatePlasmaDonor";
import useFormValidation from "../../hooks/useFormValidation";
import toastr from "toastr";

import { appContext } from "../../App";
import { createDonationRequest, updateDonationRequest } from "../../utils/api";

import "./PlasmaDonor.scss";
import PhoneNumber from "../Phone/PhoneNumber";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  modalTitle: {
    font: "600 20px Roboto",
    margin: "15px 0",
  },
  modalDesc: {
    font: "400 16px Roboto",
  },

  otpCont: {
    "& div": {
      justifyContent: "center",
      "& input": {
        width: "2.5em !important",
        height: "2.5em",
      },
    },
  },
  resendOtp: {
    float: "right",
    color: "#aaa",
    cursor: "pointer",
  },
  saveBtn: {
    background: "var(--tal_primary)  !important",
    color: "#fff!important",
    // width: "40% !important",
    padding: "15px 20px",
    margin: "30px 0",
    display: "inline-block",
    marginLeft: "10px",
  },
}));

function getSteps() {
  return ["Basic Info", "Communication Info"];
}

const PlasmaRequest = (props) => {
  const classes = useStyles();
  const { authUser, region } = useContext(appContext);
  const [declare, setDeclare] = useState(false);
  const [recovered, setRecovered] = useState(false);
  const [diseases, setDiseases] = useState(false);
  const [id, setId] = useState("");
  const [extraErrors, setExtraErrors] = useState({});
  const [phoneContact, setPhoneContact] = useState(false);
  const [emailContact, setEmailContact] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [age, setAge] = useState(null);
  const [dateRecover, setDateRecover] = useState(null);
  const [documentUrl, setDocumentUrl] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const initialState = {
    title: "",
    blood: "",
    patientName: "",
    gender: "",
  };

  const handleCheckbox = (e, type) => {
    if (type === "declare") {
      setDeclare(e.target.checked);
    }
    if (type === "recovered") {
      setRecovered(e.target.checked);
    }
    if (type === "diseases") {
      setDiseases(e.target.checked);
    }
    if (type === "phone") {
      setPhoneContact(e.target.checked);
    }
    if (type === "email") {
      setEmailContact(e.target.checked);
    }
  };

  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const phoneRef = useRef();
  const handleShowStepper = () => {
    if (phoneRef.current.isValid()) {
      setShowStepper(true);
    }
  };

  const handleBack = () => {
    if (activeStep >= 1) {
      setEditMode(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setShowStepper(false);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const onSavingDocument = (message, url) => {
    setDocumentUrl(url);
    toastr.success(message);
  };

  const handleAgeChange = (date) => {
    setAge(date);
  };

  const handleRecoverDateChange = (date) => {
    setDateRecover(date);
  };

  const previewHandler = () => {
    localStorage.setItem("donationRequestBrief", values.brief);
    localStorage.setItem("donationRequestLocation", values.location);
    localStorage.setItem("isPreviewMode", "true");
    window.open(`/donationRequest/${name}`, "blank");
  };

  const handleNext = () => {
    savePlasmaRequest();
  };

  const savePlasmaRequest = () => {
    const finalValues = values;
    const errors = {};
    finalValues.user_id = authUser.unique_id;
    finalValues.region = region._id;
    finalValues.request_type = "plasma";
    finalValues.quantity = 1;
    finalValues.status = -2;
    finalValues.creatorType = "donor";
    finalValues.requested_for = values.blood;
    finalValues.title = values.title;
    finalValues.blood = values.blood;
    finalValues.first_name = values.first_name;
    finalValues.last_name = values.last_name;
    finalValues.weight = values.weight;
    finalValues.covidRecover = values.covRecover;
    finalValues.gender = values.gender;

    if (age == null) {
      errors.age = "Please enter your age";
    } else {
      finalValues.age = age;
    }
    if (values.covRecover === "Yes" && dateRecover == null) {
      errors.dateRecover = "Please enter recovered date";
    } else {
      finalValues.dateRecover = dateRecover;
    }
    if (activeStep === 0) {
      if (Object.keys(errors).length > 0) {
        setExtraErrors(errors);
      } else {
        setActiveStep(1);
      }
    } else if (activeStep === 1) {
      if (!values.healthIssues) {
        errors.healthIssues =
          "Please enter if any health issues or else enter NA.";
      }
      if (!values.location) {
        errors.city = "Please enter city";
      }
      if (!values.brief) {
        errors.brief = "Please enter brief.";
      } else if (values.brief && values.brief.length > 120) {
        errors.brief = "Brief cannot be more than 120 characters";
      }

      if (!documentUrl) {
        errors.documentUrl = "Please upload recovery documents";
      }
    }
    if (Object.keys(errors).length > 0) {
      setExtraErrors(errors);
    } else if (editMode) {
      let additionalValues = {};
      additionalValues.user_id = authUser.unique_id;
      additionalValues.status = 0;
      additionalValues.documentUrl = documentUrl;
      additionalValues.shipping_address = {
        city: values.location,
      };
      additionalValues.brief = values.brief;
      additionalValues.healthIssues = values.healthIssues;
      additionalValues.alternateContact = values.altCont;
      additionalValues.additionalInfo = {
        contactByPhone: phoneContact,
        contactByEmail: emailContact,
        dob: moment(age).valueOf(),
        dateRecover: moment(dateRecover).valueOf(),
      };
      updateDonationRequest(additionalValues, id)
        .then((response) => {
          toastr.success("Request created successfully");
          if (activeStep === 0) {
            setActiveStep(1);
          }
          props.history.push(`/donationRequest/${response.name}`);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    } else {
      createDonationRequest(finalValues).then((resp) => {
        if (resp.statusCode === 200) {
          setId(resp.data._id);
          setName(resp.data.name);
          setTitle(resp.data.title);
          setEditMode(true);
          setActiveStep(1);
        }
      });
    }
  };

  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    validatePlasmaRequest,
    handleNext
  );

  return (
    <div className="plasma-container">
      <div>
        <div>
          <h1 className="stepper-title">Here we go!</h1>
          <hr className="sub-hr" />
          <p className="stepper-subheader">Be a Hero! Make an impact</p>
        </div>
        {!showStepper ? (
          <div>
            <div className="plasma-otp">
              <div>
                <input
                  type="checkbox"
                  id="checkbox-c"
                  name="declare"
                  checked={declare}
                  onChange={(e) => handleCheckbox(e, "declare")}
                />
                <label for="checkbox-c">Checkbox 3</label>
              </div>
              <span>
                I hereby declare that I am willing to donate Plasma to save
                Covid patients.
              </span>
            </div>
            <div className="plasma-otp">
              <div>
                <input
                  type="checkbox"
                  id="checkbox-d"
                  name="recovered"
                  checked={recovered}
                  onChange={(e) => handleCheckbox(e, "recovered")}
                />
                <label for="checkbox-d">Checkbox 3</label>
              </div>
              <span>
                I have fully recovered and is now free of symptoms for 14 days.
              </span>
            </div>
            <div className="plasma-otp">
              <div>
                <input
                  type="checkbox"
                  id="checkbox-e"
                  name="diseases"
                  checked={diseases}
                  onChange={(e) => handleCheckbox(e, "diseases")}
                />
                <label for="checkbox-e">Checkbox 3</label>
              </div>
              <span>
                I am not a cancer survivor or has Chronic Kidney, heart, lung or
                liver diseases
              </span>
            </div>
            <div className="plasma-otp">
              <div>
                <input
                  type="checkbox"
                  id="checkbox-f"
                  name="phone"
                  checked={phoneContact}
                  onChange={(e) => handleCheckbox(e, "phone")}
                />
                <label for="checkbox-f">Checkbox 3</label>
              </div>
              <span>Recipients may contact me by Phone</span>
            </div>
            <div className="plasma-otp">
              <div>
                <input
                  type="checkbox"
                  id="checkbox-g"
                  name="email"
                  checked={emailContact}
                  onChange={(e) => handleCheckbox(e, "email")}
                />
                <label for="checkbox-g">Checkbox 3</label>
              </div>
              <span>Recipients may contact me by Email</span>
            </div>
            <InputLabel className="secFormLabel">Phone</InputLabel>
            <div className="phonebutton">
              <PhoneNumber
                phone={authUser && authUser.phone}
                isPhoneVerified={authUser && authUser.phone_verified}
                ref={phoneRef}
              />
            </div>

            <div>
              <Button
                className="savebtn"
                disabled={declare && recovered && diseases ? false : true}
                style={{
                  opacity: declare && recovered && diseases ? 1 : 0.5,
                }}
                onClick={handleShowStepper}
              >
                Save & Continue
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Stepper
              activeStep={activeStep}
              style={{ background: "transparent" }}
              alternativeLabel
            >
              {getSteps().map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === getSteps().length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography className={classes.instructions}>
                    {activeStep === 0 && (
                      <div className="request-step-one">
                        <form
                          noValidate
                          autoComplete="off"
                          className="plasma-request-form"
                        >
                          <div style={{ position: "relative" }}>
                            <TextField
                              type="text"
                              required
                              className={`input-field request-title ${
                                errors && errors.title
                                  ? "bg-red"
                                  : values.title
                                  ? "bg-green"
                                  : "bg-normal"
                              }`}
                              variant="outlined"
                              label="Request Title"
                              name="title"
                              value={values.title}
                              onChange={changeHandler}
                              inputProps={{
                                maxLength: 50,
                              }}
                            />
                            <span className="title-length">
                              {" "}
                              {values.title ? values.title.length : 0}
                              /50
                            </span>
                          </div>
                          {errors && errors.title && (
                            <div className="custom-error">{errors.title}</div>
                          )}

                          <div className="flname">
                            <div className="names">
                              <TextField
                                className={`full-width border-radius signup-input 
                                    }`}
                                variant="outlined"
                                label="First Name *"
                                name="first_name"
                                onChange={changeHandler}
                                value={values.first_name}
                              />
                              {errors && (
                                <div className="custom-error">
                                  {errors.first_name}
                                </div>
                              )}
                            </div>
                            <div className="names">
                              <TextField
                                className={`full-width border-radius signup-input`}
                                variant="outlined"
                                label="Last Name *"
                                name="last_name"
                                onChange={changeHandler}
                                value={values.last_name}
                              />
                              {errors && errors.last_name && (
                                <div className="custom-error">
                                  {errors.last_name}
                                </div>
                              )}
                            </div>
                          </div>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              style={{ width: "100%" }}
                              margin="normal"
                              className={`input-field dob`}
                              format="dd-MMM-yyyy"
                              label="Date of Birth *"
                              disableFuture
                              name="age"
                              value={age}
                              onChange={handleAgeChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {extraErrors && extraErrors.age && (
                            <div className="custom-error">
                              {extraErrors.age}
                            </div>
                          )}
                          <FormControl className="blood-grp">
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{ top: 8, left: 5 }}
                            >
                              Blood Group *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.blood}
                              onChange={changeHandler}
                              variant="outlined"
                              className={`input-field request-amount ${
                                errors && errors.blood
                                  ? "bg-red"
                                  : values.blood
                                  ? "bg-green"
                                  : "bg-normal"
                              }`}
                              name="blood"
                              label="Blood Group *"
                              style={{ width: "100%" }}
                            >
                              <MenuItem value={"A+"}>A+</MenuItem>
                              <MenuItem value={"A-"}>A-</MenuItem>
                              <MenuItem value={"B+"}>B+</MenuItem>
                              <MenuItem value={"B-"}>B-</MenuItem>
                              <MenuItem value={"AB+"}>AB+</MenuItem>
                              <MenuItem value={"AB-"}>AB-</MenuItem>
                              <MenuItem value={"O+"}>O+</MenuItem>
                              <MenuItem value={"O-"}>O-</MenuItem>
                            </Select>{" "}
                            {errors && errors.blood && (
                              <div className="custom-error">{errors.blood}</div>
                            )}
                          </FormControl>
                          <TextField
                            type="number"
                            className={`input-field request-title ${
                              errors && errors.weight
                                ? "bg-red"
                                : values.weight
                                ? "bg-green"
                                : "bg-normal"
                            }`}
                            variant="outlined"
                            label="Weight (Kgs) *"
                            name="weight"
                            value={values.weight}
                            onChange={changeHandler}
                          />

                          {errors && errors.weight && (
                            <div className="custom-error">{errors.weight}</div>
                          )}
                          <FormControl className="blood-grp">
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              style={{ top: 8, left: 5 }}
                            >
                              Covid Recovered? *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.covRecover}
                              onChange={changeHandler}
                              variant="outlined"
                              className={`input-field request-amount ${
                                errors && errors.covRecover
                                  ? "bg-red"
                                  : values.covRecover
                                  ? "bg-green"
                                  : "bg-normal"
                              }`}
                              name="covRecover"
                              label="Covod Recovered?"
                              style={{ width: "100%" }}
                            >
                              <MenuItem value={"Yes"}>Yes</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </Select>{" "}
                            {errors && errors.covRecover && (
                              <div className="custom-error">
                                {errors.covRecover}
                              </div>
                            )}
                          </FormControl>
                          {values.covRecover === "Yes" && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                style={{ width: "100%" }}
                                margin="normal"
                                className={`input-field dob`}
                                format="dd-MMM-yyyy"
                                label="Date of Recovery *"
                                disableFuture
                                name="dateRecover"
                                value={dateRecover}
                                onChange={handleRecoverDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          )}
                          {extraErrors && extraErrors.dateRecover && (
                            <div className="custom-error">
                              {extraErrors.dateRecover}
                            </div>
                          )}
                        </form>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div className="request-step-two">
                        <p style={{ float: "right" }}>
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                              placement="top"
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    If you have fully recovered from COVID-19,
                                    you may be able to help patients currently
                                    fighting the infection by donating your
                                    plasma. Because you fought the infection,
                                    your plasma now contains COVID-19
                                    antibodies. These antibodies provided one
                                    way for your immune system to fight the
                                    virus when you were sick, so your plasma may
                                    be able to be used to help others fight off
                                    the disease.
                                  </Typography>
                                  <br />
                                  <Typography color="inherit">
                                    I feel proud & responsible to contribute
                                    back to human survival with Plasma donation
                                    and help COVID-19 patients
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                              <InfoOutlinedIcon onClick={handleTooltipOpen} />
                            </Tooltip>
                          </ClickAwayListener>
                        </p>
                        <form
                          noValidate
                          autoComplete="off"
                          className="plasma-request-form"
                        >
                          <div style={{ position: "relative" }}>
                            <TextField
                              type="text"
                              required
                              className={`input-field request-title ${
                                errors && errors.brief
                                  ? "bg-red"
                                  : values.brief
                                  ? "bg-green"
                                  : "bg-normal"
                              }`}
                              variant="outlined"
                              label="Brief"
                              name="brief"
                              value={values.brief}
                              onChange={changeHandler}
                              inputProps={{
                                maxLength: 120,
                              }}
                            />

                            <span className="title-length">
                              {" "}
                              {values.brief ? values.brief.length : 0}
                              /120
                            </span>
                          </div>
                          {extraErrors && extraErrors.brief && (
                            <div className="custom-error">
                              {extraErrors.brief}
                            </div>
                          )}

                          <TextField
                            type="text"
                            required
                            className={`input-field request-title ${
                              errors && errors.location
                                ? "bg-red"
                                : values.location
                                ? "bg-green"
                                : "bg-normal"
                            }`}
                            variant="outlined"
                            label="City"
                            name="location"
                            value={values.location}
                            onChange={changeHandler}
                          />

                          {extraErrors && extraErrors.city && (
                            <div className="custom-error">
                              {extraErrors.city}
                            </div>
                          )}
                          <TextField
                            type="text"
                            required
                            className={`input-field request-title ${
                              errors && errors.healthIssues
                                ? "bg-red"
                                : values.healthIssues
                                ? "bg-green"
                                : "bg-normal"
                            }`}
                            variant="outlined"
                            label="Any Health Issues? Please mention"
                            name="healthIssues"
                            value={values.healthIssues}
                            onChange={changeHandler}
                          />

                          {extraErrors && extraErrors.healthIssues && (
                            <div className="custom-error">
                              {extraErrors.healthIssues}
                            </div>
                          )}
                          <TextField
                            type="number"
                            className={`input-field request-title ${
                              errors && errors.altCont
                                ? "bg-red"
                                : values.altCont
                                ? "bg-green"
                                : "bg-normal"
                            }`}
                            variant="outlined"
                            label="Alternate Contact (Optional)"
                            name="altCont"
                            value={values.altCont}
                            onChange={changeHandler}
                          />
                        </form>
                        <Button
                          className="document-btn"
                          onClick={() => setShowingAddDocumentModal(true)}
                          style={{
                            width: "60%",
                            font: "500 12px Roboto",
                            padding: "20px 30px",
                            margin: "10px 5px",
                            background: "transparent",
                            color: "#283E4A",
                            border: "1px solid #283E4A",
                          }}
                        >
                          <CloudUploadIcon
                            style={{ fontSize: 14, marginRight: 6 }}
                          />{" "}
                          Upload supporting document(s)
                        </Button>

                        {extraErrors && extraErrors.documentUrl && (
                          <div className="custom-error">
                            {extraErrors.documentUrl}
                          </div>
                        )}
                      </div>
                    )}

                    <div>
                      <Button
                        onClick={handleBack}
                        style={{
                          border: "1px solid #ccc",
                          width: activeStep === 0 ? "18%" : "10%",
                        }}
                        className={classes.backButton}
                      >
                        Back
                      </Button>
                      {activeStep === 1 && (
                        <Button
                          className="preview-btn"
                          style={{
                            border: "1px solid var(--tal_primary) ",
                            color: "var(--tal_primary) ",
                            background: "#fff",
                            marginLeft: 10,
                          }}
                          onClick={previewHandler}
                        >
                          Preview
                        </Button>
                      )}
                      <Button
                        style={{
                          width: activeStep === 0 ? "40%" : "38%",
                        }}
                        variant="contained"
                        className={`save-btn ${
                          activeStep === 0 ? "short-btn" : "continue-btn"
                        }`}
                        onClick={submitHandler}
                      >
                        {activeStep === getSteps().length - 1
                          ? "Submit"
                          : "Save & Continue"}
                      </Button>
                    </div>
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={id}
        collection="DonationRequest"
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className={classes.modalTitle}>
              Request for Plasma Donation
            </h2>
            {showSuccess ? (
              <div>
                <p
                  id="transition-modal-description"
                  className={classes.modalDesc}
                >
                  Mobile number verification has been successful!
                </p>
                <input type="checkbox" className="tick-check" id="chk" />
                <label for="chk"></label>
                <div>
                  <Button className="savebtn" onClick={handleFinishOtp}>
                    Continue
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p
                  id="transition-modal-description"
                  className={classes.modalDesc}
                >
                  Please enter the OTP that has been sent to{" "}
                  {phone && phone.replace(/(\+\d{3})\d{7}/, "$1*******")}
                </p>
                <div className={classes.otpCont}>
                  <OtpInput
                    onChange={(e) => handleOtpCode(e)}
                    numInputs={6}
                    value={otpValues}
                    separator={<span> &nbsp; &nbsp; </span>}
                    shouldAutoFocus={true}
                  />
                </div>
                <p className={classes.resendOtp} onClick={() => resendOtp()}>
                  Resend OTP
                </p>
              </div>
            )}
          </div>
        </Fade>
      </Modal> */}
    </div>
  );
};

export default withRouter(PlasmaRequest);

import React from "react";
import { withRouter } from "react-router-dom";
import Stripe from "../index";

const StripePayment = () => {
  return (
    <div>
      <Stripe />
    </div>
  );
};

export default withRouter(StripePayment);

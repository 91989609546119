import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { appContext } from "../../App";
import Tabs from "@material-ui/core/Tabs";
import cssstyles from "./FeedbackTalLeaders.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  Slide,
  Tab,
  TextField,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  createTalleaderComment,
  deleteComment,
  editComment,
  getUserData,
} from "../../utils/api";
import toastr from "toastr";
import Loader from "../common/Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "hidden",
    overflowY: "auto",

    height: "70vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },

  orgTitle: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "600",
    textTransform: "inheirt",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ViewTALLeaders = (props) => {
  const {
    userData,
    setUserData,
    selectedRowComments,
    setSelectedRowComments,
    commentsData,
    setCommentsData,
    value,
    setValue,
  } = props;
  const classes = useStyles();
  const { authUser } = useContext(appContext);
  //   const classes = useStyles();
  const theme = useTheme();
  const [more, setMore] = useState(5);
  const [showTextbox, setShowTextbox] = useState(false);
  const [editCommentValue, setEditCommentValue] = useState("");
  const [editedComments, setEditedComments] = useState("");
  const [isEditComments, setIsEditComments] = useState(false);
  const [comments, setComments] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseFeedbackModal = () => {
    setValue(0);
    props.onCloseModal();
    setMore(5);
    setComments("");
    setEditCommentValue("");
    setIsEditComments(false);
    setCommentsData([]);
    setUserData("");
  };

  const loadUserData = (rowId) => {
    setIsLoaded(true);
    getUserData(rowId)
      .then((response) => {
        setUserData(response);
        setSelectedRowComments(response);
        setCommentsData(response.userComments);
        setIsLoaded(false);
      })
      .catch((error) => {
        setIsLoaded(false);
        toastr.error(error.response);
      });
  };

  const handleCommentsSubmit = (e) => {
    e.preventDefault();
    if (isEditComments) {
      if (selectedRowComments && selectedRowComments.unique_id) {
        const requestBody = {
          comment: editCommentValue,
        };
        //Update Comments
        editComment(
          requestBody,
          selectedRowComments.unique_id,
          editedComments?._id
        ).then((response) => {
          if (response && Object.keys(response)?.length > 0) {
            setIsEditComments(false);
            setShowTextbox(false);
            loadUserData(response.data.data._id);
            toastr.success(response?.data?.message);
            setEditCommentValue("");
            setComments("");
          }
        });
      }
    } else {
      if (selectedRowComments && selectedRowComments.unique_id) {
        const requestBody = {
          comment: comments,
        };
        //Create Comments
        createTalleaderComment(requestBody, selectedRowComments.unique_id).then(
          (response) => {
            if (response && Object.keys(response)) {
              loadUserData(response._id);
              setIsEditComments(false);
              toastr.success("Comment Added Successfully");
              setEditCommentValue("");
              setComments("");
            }
          }
        );
      }
    }
  };

  const editComments = (e, uComments) => {
    setIsEditComments(true);
    setEditedComments(uComments);
    setEditCommentValue(uComments.comments);
    setShowTextbox(true);
  };
  //Delete Comments
  const handleDeleteComment = (e, uComments) => {
    if (window.confirm("Are you sure you want to delete the comment?"))
      if (selectedRowComments && selectedRowComments.unique_id) {
        const requestBody = {
          user_id: selectedRowComments.unique_id,
        };
        deleteComment(
          requestBody,
          selectedRowComments.unique_id,
          uComments?._id
        )
          .then((response) => {
            if (response && Object.keys(response)?.length > 0) {
              setIsEditComments(false);
              loadUserData(response.data.data._id);
              toastr.success(response?.data?.message);
            }
          })
          .catch();
      }
  };

  const handleMoreComments = () => {
    setMore(more + 5);
  };

  const handleVisitProfile = (username) => {
    window.open(`/${username}`);
  };

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openFeedbackModal}
        onClose={() => handleCloseFeedbackModal()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openFeedbackModal}>
          <div className={classes.paper}>
            <div className="tab-bar">
              <Grid item xs={12}>
                <div className={cssstyles.tabs_bar1 + " " + "tabs_like_com"}>
                  <AppBar position="static" color="default">
                    <div>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab
                          label={`Likes ( ${
                            userData &&
                            userData.userLikes &&
                            userData.userLikes.length > 0
                              ? userData.userLikes.length
                              : 0
                          } )`}
                          {...a11yProps(0)}
                        />
                        <Tab
                          label={`Comments ( ${
                            userData &&
                            userData.userComments &&
                            userData.userComments.length > 0
                              ? userData.userComments.length
                              : 0
                          } )`}
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </div>
                  </AppBar>
                  <div className={cssstyles.close_btn}>
                    <CloseIcon onClick={() => handleCloseFeedbackModal()} />
                  </div>
                </div>
              </Grid>
            </div>
            {value === 0 && (
              <div className={cssstyles.pop_like_com}>
                {userData && userData.userLikes && userData.userLikes.length > 0
                  ? userData.userLikes.slice(0, more).map((likedUserData) => (
                      <>
                        <div className={cssstyles.cards_style}>
                          <Grid item xs={6} className={cssstyles.img_align}>
                            <div className={cssstyles.img_chge}>
                              <img
                                src={
                                  likedUserData && likedUserData.image_url
                                    ? likedUserData.image_url
                                    : "../images/tal-leader-icon.svg"
                                }
                              />
                            </div>
                            <div className={cssstyles.name_txt}>
                              <span className={cssstyles.name_title}>
                                {likedUserData.name &&
                                  likedUserData.name.first_name}{" "}
                                {likedUserData.name &&
                                  likedUserData.name.middle_name}{" "}
                                {likedUserData.name &&
                                  likedUserData.name.last_name}
                              </span>
                              <p>
                                {likedUserData && likedUserData.currentRole}
                                <strong>
                                  {" "}
                                  {likedUserData &&
                                    likedUserData.currentCompanyName && (
                                      <>
                                        <span style={{ fontWeight: "normal" }}>
                                          At{" "}
                                        </span>
                                        {likedUserData.currentCompanyName}
                                      </>
                                    )}
                                </strong>
                              </p>
                            </div>
                          </Grid>

                          <Grid item xs={6} className={cssstyles.link_btn}>
                            <Button
                              onClick={() =>
                                handleVisitProfile(likedUserData.username)
                              }
                            >
                              <span>Visit profile</span>
                              <img
                                className={cssstyles.profile_imgs}
                                src="../images/external-link.svg"
                                alt="mail"
                              />
                            </Button>
                          </Grid>
                        </div>
                        <Divider className={cssstyles.dr_margin} />
                      </>
                    ))
                  : !isLoaded &&
                    userData && (
                      <div style={{ marginTop: "120px" }}>
                        <p style={{ textAlign: "center" }}>
                          Uh-oh! Currently, there are no Likes. But be the first
                          one to Like.
                        </p>
                      </div>
                    )}
              </div>
            )}
            {value === 1 && (
              <div>
                <div>
                  {/* <div className={cssstyles.cards_style}>
                    <Grid item xs={6} className={cssstyles.img_align}>
                      <div className={cssstyles.img_chge}>
                        <img
                          src={
                            userData && userData.profile_image_url
                              ? userData.profile_image_url
                              : "../images/tal-leader-icon.svg"
                          }
                        />
                      </div>
                      <div className={cssstyles.name_txt}>
                        <span className={cssstyles.name_title}>
                          {userData &&
                            userData.name &&
                            userData.name.first_name}{" "}
                          {userData &&
                            userData.name &&
                            userData.name.middle_name}{" "}
                          {userData && userData.name && userData.name.last_name}
                        </span>
                        <p>
                          {userData &&
                            userData.address &&
                            userData.address.country}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6} className={cssstyles.link_btn}>
                      <Button
                        onClick={() => window.open(`/${userData.username}`)}
                      >
                        Visit profile
                        <img
                          className={cssstyles.profile_imgs}
                          src="../images/external-link.svg"
                          alt="mail"
                        />
                      </Button>
                    </Grid>
                  </div> */}
                  {/* <div className={cssstyles.waring_text}>
                    <span>
                      Touch-A-Life Foundation Leadership Team supports
                      innovative ways to leverage technology to support donation
                      and kindness.
                    </span>
                  </div> */}
                  <Grid item xs={12}>
                    <>
                      <Grid className="commemt_align">
                        <div className="comment_color">
                          <h4>Add your comment</h4>
                        </div>
                        <Grid className="tal_line">
                          <Avatar
                            src={authUser && authUser.profile_image_url}
                            alt="Avatar"
                            className="comment-avatar"
                          />
                          <div className="comments-main-details leader_pop">
                            <form onSubmit={handleCommentsSubmit}>
                              <TextField
                                placeholder="Say something about this"
                                className="w-100"
                                name="comments"
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                              />
                            </form>
                          </div>
                          <Button
                            className="submit_text"
                            onClick={handleCommentsSubmit}
                            disabled={
                              comments === "" && editCommentValue === ""
                            }
                          >
                            submit
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container className="recent-comments">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          {userData &&
                          userData.userComments &&
                          userData.userComments.length > 0
                            ? userData.userComments
                                .sort(
                                  (a, b) =>
                                    new Date(b.createdAt).getTime() -
                                    new Date(a.createdAt).getTime()
                                )
                                .slice(0, more)
                                .map((userComments) => (
                                  <div key={userComments._id}>
                                    {isEditComments &&
                                    showTextbox &&
                                    userComments._id === editedComments._id ? (
                                      <form onSubmit={handleCommentsSubmit}>
                                        <div className="comments-main-details leader_pop">
                                          <TextField
                                            placeholder="Say something about this"
                                            className="w-100"
                                            name="editCommentValue"
                                            value={editCommentValue}
                                            onChange={(e) =>
                                              setEditCommentValue(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </form>
                                    ) : (
                                      <div className="comments-main-details ">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Avatar
                                            src={
                                              userComments.userId &&
                                              userComments.userId.image_url
                                            }
                                            alt="Avatar"
                                            className="comment-avatar"
                                          />

                                          <span
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {userComments &&
                                              userComments.userId.name
                                                .first_name}{" "}
                                            &nbsp;
                                            {userComments &&
                                              userComments.userId.name
                                                .last_name}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            margin: "15px 15px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <p>{userComments.comments}</p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          {authUser &&
                                            userComments &&
                                            userComments.userId &&
                                            userComments.userId._id ===
                                              authUser.unique_id && (
                                              <Button
                                                onClick={(e) =>
                                                  editComments(e, userComments)
                                                }
                                              >
                                                Edit
                                              </Button>
                                            )}

                                          {authUser &&
                                            userComments &&
                                            userComments.userId &&
                                            userComments.userId._id ===
                                              authUser.unique_id && (
                                              <Button
                                                onClick={(e) =>
                                                  handleDeleteComment(
                                                    e,
                                                    userComments
                                                  )
                                                }
                                              >
                                                Delete
                                              </Button>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))
                            : !isLoaded &&
                              userData && (
                                <div style={{ marginTop: "50px" }}>
                                  <p style={{ textAlign: "center" }}>
                                    Uh-oh! Currently, there are no comments. But
                                    be the first one to comment.
                                  </p>
                                </div>
                              )}
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {commentsData && commentsData.length > more && (
                          <div
                            className="comment-btn"
                            onClick={handleMoreComments}
                          >
                            View more comments...
                          </div>
                        )}
                      </Grid>
                      {/* <Grid container spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <div className="pop_para">
                            <p>
                              Touch-A-Life Foundation Leadership Team supports
                              innovative ways
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid> */}
                    </>
                  </Grid>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(ViewTALLeaders);

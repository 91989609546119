import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import toastr from "toastr";
import {
  getTALRadioCategories,
  saveRadioCategory,
  updateRadioCategory,
} from "../../utils/api";
import AddPhotos from "../common/AddPhotos";

export default function AddCategory(props) {
  const {
    isEnableEdit,
    setIsEnableEdit,
    setCategories,
    row,
    categories,
  } = props;
  const [category, setCategory] = useState("");
  const [extraErrors, setExtraErrors] = useState({});
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [finalPayload, setFinalPayload] = useState(false);
  //const [row, setRow] = useState({});
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleAddCategory = () => {
    const extraErrors = {};

    if (category === "") {
      extraErrors.category = "Please enter Category";
    }

    if (isEnableEdit) {
      if (!thumbnailUrl) {
        extraErrors.thumbnail =
          "Please select a thumbnail image file to set a thumbnail for this Category";
      }
    }

    setExtraErrors(extraErrors);

    if (extraErrors && Object.keys(extraErrors).length > 0) return;

    if (!isEnableEdit) {
      setFinalPayload({
        name: category,
        thumbnailImageUrl: thumbnailUrl,
      });
    } else {
      updateRadioCategory(row._id, {
        name: category,
        thumbnailImageUrl: thumbnailUrl,
      })
        .then((resp) => {
          if (resp.statusCode === 200) {
            toastr.success(resp.message);
            const oldRows = categories.map((item) => {
              if (item._id === row._id) {
                return {
                  _id: row._id,
                  name: category,
                  thumbnailImageUrl: thumbnailUrl,
                  createdAt: item.createdAt,
                  noOfStreamings: item.noOfStreamings,
                  updatedAt: new Date(),
                };
              } else {
                return item;
              }
            });
            setCategories(oldRows);
            setCategory("");
            props.handleClose();
            setIsEnableEdit(false);
          } else {
            toastr.error(resp.message);
          }
        })
        .catch((e) => {
          if (e.response && e.response.data) {
            // client received an error response (5xx, 4xx)
            toastr.error(e.response.data.message);
          } else {
            // anything else
            toastr.error(e.message);
          }
        });
    }
  };

  useEffect(() => {
    if (!isEnableEdit) {
      setCategory("");
    }
    setExtraErrors({});
  }, [isEnableEdit]);

  useEffect(() => {
    if (!isEnableEdit && finalPayload) {
      saveRadioCategory({
        name: category,
      })
        .then((resp) => {
          if (resp.statusCode === 200) {
            setCategories([
              {
                id: resp.data._id,
                name: category,
                thumbnailImageUrl: "/images/radio_thumbnail.png",
                createdAt: new Date(),
                noOfStreamings: 0,
                updatedAt: new Date(),
              },
              ...categories,
            ]);
            setCategory("");
            toastr.success(resp.message);
            setIsEnableEdit(false);
            props.handleClose();
          } else {
            toastr.error(resp.message);
          }
        })
        .catch((e) => {
          if (e.response && e.response.data) {
            // client received an error response (5xx, 4xx)
            toastr.error(e.response.data.message);
          } else {
            // anything else
            toastr.error(e.message);
          }
        });
    }
  }, [finalPayload]);

  const loadCategories = (selectedId) => {
    getTALRadioCategories(selectedId)
      .then((response) => {
        if (response && response.data) {
          // setRow(response.data);
          setCategory(response.data.name);
          setThumbnailUrl(response.data.thumbnailImageUrl);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Categories:" + error.message);
      });
  };

  useEffect(() => {
    if (row && isEnableEdit) {
      //loadCategories(selectedId);
      setCategory(row.name);
      setThumbnailUrl(row.thumbnailImageUrl);
    }
  }, [row, isEnableEdit]);

  const updateDefaultImageUrl = (url) => {
    updateRadioCategory(row._id, {
      name: category,
      thumbnailImageUrl: url,
    })
      .then((resp) => {
        if (resp.statusCode === 200) {
          const oldRows = categories.map((item) => {
            if (item._id === row._id) {
              return {
                _id: row._id,
                name: category,
                thumbnailImageUrl: url,
                createdAt: item.createdAt,
                noOfStreamings: item.noOfStreamings,
                updatedAt: new Date(),
              };
            } else {
              return item;
            }
          });
          setCategories(oldRows);
          //loadCategories(row._id);
          //setCategory(row.name);
          setThumbnailUrl(url);
        } else {
          toastr.error(resp.message);
        }
      })
      .catch((e) => {
        if (e.response && e.response.data) {
          // client received an error response (5xx, 4xx)
          toastr.error(e.response.data.message);
        } else {
          // anything else
          toastr.error(e.message);
        }
      });
  };

  const changeProfileImageClickHandler = () => {
    setShowingAddPhotoModal(true);
  };

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="RJ-details"
      open={props.open}
    >
      <Box p={1}>
        <Typography
          style={{
            padding: "10px 0",
            height: "40px",
            fontStyle: "bold",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "21px",
            color: "var(--tal_primary)",
            alignText: "center",
          }}
        >
          {isEnableEdit ? "Edit " : "Create "} Category
        </Typography>
        <div>
          <FormControl fullWidth error={extraErrors && extraErrors.category}>
            <TextField
              variant="outlined"
              style={{
                padding: "10px 0",
                fontStyle: "bold",
                fontWeight: "400",
                fontSize: "20px",
                color: "#000000",
              }}
              id="Category"
              label="Category"
              placeholder="Category"
              value={category}
              onChange={handleCategory}
            >
              {category}
            </TextField>
            <FormHelperText>{extraErrors.category}</FormHelperText>
          </FormControl>
        </div>
        {isEnableEdit && (
          <Fragment>
            <img
              src={thumbnailUrl ? thumbnailUrl : "/images/radio_thumbnail.png"}
              style={{
                cursor: "pointer",
                borderRadius: "5px",
                background: "white",
                boxShadow: "0 0 2px #c6c6c6",
                padding: "5px",
                marginBottom: "10px",
                minWidth: "50px",
                maxWidth: "100px",
                maxHeight: "100px",
                minHeight: "50px",
              }}
              onClick={changeProfileImageClickHandler}
            />
            {extraErrors.thumbnail && (
              <Typography style={{ color: "red" }}>
                {extraErrors.thumbnail}
              </Typography>
            )}
            <AddPhotos
              title="Manage Default Image"
              isOpen={showingAddPhotoModal}
              onClose={onCloseAddPhoto}
              uid={row && row._id}
              collection="Category"
              profilephotoURL={row && row.thumbnailImageUrl}
              onProfilephotoURLChange={updateDefaultImageUrl}
              canEdit={true}
            />
          </Fragment>
    
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <br />
          <Button variant="contained" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{color:"white", backgroundColor:"var(--tal_primary)"}}
            onClick={handleAddCategory}
          >
            {isEnableEdit ? "Save" : " Add Category"}
          </Button>
        </div>
      </Box>
    </Dialog>
  );
}

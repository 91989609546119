import validator from "validator";

const validateSignup = (values) => {
  let errors = {};
  if (!values.first_name) {
    errors.first_name = "Please enter your first name.";
  }

  if (!values.last_name) {
    errors.last_name = "Please enter your last name.";
  }

  if (
    values.secondaryEmail &&
    !validator.isEmail(values.secondaryEmail.trim())
  ) {
    errors.secondaryEmail = "Please enter a valid email address.";
  }

  if (!values.email) {
    errors.email = "Touch-A-Life email is required";
  } else if (!values.email.match(/^[A-Za-z0-9._\-]{1,}@(touchalife.org)$/)) {
    errors.email =
      "Please enter a valid @touchalife.org email address. (Eg: xyz@touchalife.org)";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (
    !values.password.match(/[a-z]/) ||
    !values.password.match(/[A-Z]+/) ||
    !values.password.match(/\d/) ||
    !values.password.match(/[^a-zA-Z0-9\-/]/) ||
    values.password.length < 6
  ) {
    errors.password = "The password does not meet all the below requirements";
  }
  return errors;
};

export default validateSignup;

import React, { useState, useEffect, useContext, useRef } from "react";
import toastr from "toastr";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import useFormValidation from "../../hooks/useFormValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@material-ui/icons/Close";
import { Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  faRupeeSign,
  faDollarSign,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import validateRequest from "./validateRequest";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  createDonationRequest,
  updateDonationRequest,
  getOrganizationList,
  getCategories,
} from "../../utils/api";
import "./NewRequest.scss";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
import AddVideo from "../common/AddVideo";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { WhatsappShareButton } from "react-share";
import ShareThroughEmail from "../common/ShareThroughEmail";
import ShortAddress from "../common/ShortAddress";
import { LOGIN_PAGE } from "../../utils/utils";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: "100%",
        color: "#000",
        font: "400 14px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        top: 15,
      },
    },
  },
});

const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
}));

function getSteps() {
  return ["Fundraise Info", "Image/Video", "Fundraise Details"];
}

const NewRequest = (props) => {
  const { authUser, regions, region, setRegion, categories, setCategories } =
    useContext(appContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const initialState = {
    cashQuantity: "",
    title: "",
    requestType: "cash",
    cashUnit: "USD",
    status: "-2",
    fundsRecipient: "",
  };
  const [category, setCategory] = React.useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [showingAddVideoModal, setShowingAddVideoModal] = useState(false);
  const [description, setDescription] = useState("");
  const [brief, setBrief] = useState("");
  const [justifyToOthers, setJustifyToOthers] = useState("");
  const [, setCopySuccess] = useState("");
  const [shareEmail, setShareEmail] = useState(false);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const textAreaRef = useRef(null);
  const [openShareEmail, setOpenShareEmail] = useState(false);
  const [tempAddress, setTempAddress] = useState(null);
  const [address, setAddress] = useState(null);

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
    } else {
      toastr.warning("Please signup or login to create a Fundraiser");
      props.history.push(LOGIN_PAGE);
    }
  }, []);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      getOrganizationList(authUser.unique_id)
        .then((resp) => {
          setOrgList(resp);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
      getCategories()
        .then((response) => {
          setCategories(response.data.data);
        })
        .catch(() => {
          //toastr.error("Could not get categories: " + error.message);
        });
    }
  }, [authUser, refresh]);

  const shareThroughMail = () => {
    setOpenShareEmail(!openShareEmail);
    setShareEmail(false);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleNext = () => {
    saveDonationRequest();
  };

  const handleOrganization = () => {
    setOpenOrgModal(true);
  };

  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  const copyCodeToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const categoryChangeHandler = (event) => {
    setCategory(
      categories.find((category) => event.target.value === category._id)
    );
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const onCloseAddVideo = () => {
    setShowingAddVideoModal(false);
  };

  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
  };

  const wholeValidateRequest = () => {
    const errors = validateRequest(values);
    const extraErrors = {};
    if (new Date(dueDate).getTime() === 0) {
      extraErrors.dueDate = "Please select date before which you want funds";
    }
    if (!category) {
      extraErrors.category = "Please select a category";
    }
    if (
      !tempAddress ||
      !tempAddress.city ||
      !tempAddress.state ||
      !tempAddress.country
    ) {
      extraErrors.address = "Please select country, state and city";
    }
    if (
      tempAddress &&
      tempAddress.country &&
      ((tempAddress.country === "India" &&
        values &&
        values.cashUnit === "USD") ||
        (tempAddress.country !== "India" &&
          values &&
          values.cashUnit === "INR"))
    ) {
      errors.cashQuantity =
        "Please select the currency appropriate for the location that you selected";
    }
    setExtraErrors(extraErrors);
    return errors;
  };

  const saveDonationRequest = () => {
    const finalValues = values;
    const errors = {};
    finalValues.request_type = values.requestType;
    if (values.requestType === "cash") {
      finalValues.quantity = values.cashQuantity;
      finalValues.units = values.cashUnit;
    } else if (values.requestType === "kind") {
      finalValues.quantity = values.kindQuantity;
      finalValues.units = values.kindUnit;
      finalValues.requested_for = values.requestedFor;
    } else if (values.requestType === "time") {
      finalValues.quantity = values.timeQuantity;
      finalValues.units = values.timeUnit;
    }
    if (new Date(dueDate).getTime() === 0) {
      errors.dueDate = "Please select date before which you want funds";
    } else {
      finalValues.due_date = new Date(dueDate).getTime();
    }
    if (category) {
      finalValues.tags = [category._id];
    } else {
      errors.category = "Please select a category";
    }
    if (values.fundsRecipient && values.fundsRecipient !== "self") {
      finalValues.orgId = values.fundsRecipient;
    }
    if (
      tempAddress &&
      tempAddress.city &&
      tempAddress.state &&
      tempAddress.country
    ) {
      setAddress(tempAddress);
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
    } else {
      errors.address = "Please select country, state and city";
    }
    finalValues.user_id = authUser.unique_id;
    finalValues.defaultImageUrl = defaultImageUrl;
    finalValues.brief = brief;
    finalValues.organization = values.organization;
    finalValues.whyYouNeedHelp = justifyToOthers;
    finalValues.description = description;
    finalValues.region = region._id;
    if (activeStep === 1) {
      if (!finalValues.defaultImageUrl) {
        errors.image = "Please upload at least one image for your fundraiser";
      }
    } else if (activeStep === 2) {
      if (!finalValues.description) {
        errors.description = "Please write a story for your fundraiser.";
      }
      if (finalValues.whyYouNeedHelp === "") {
        errors.justifyToOthers =
          "Please justify why you are seeking help from others";
      }
      if (!finalValues.brief) {
        errors.brief = "Please enter brief.";
      } else if (finalValues.brief && finalValues.brief.length > 120) {
        errors.brief = "Brief cannot be more than 120 characters";
      }
    }
    if (Object.keys(errors).length > 0) {
      setExtraErrors(errors);
    } else if (editMode) {
      if (activeStep === 2) {
        finalValues.status = 0;
        if (finalValues.fundsRecipient !== "self") {
          finalValues.fundsRecipient = "";
        }
      }

      updateDonationRequest(finalValues, id)
        .then(() => {
          if (activeStep === 0) {
            setActiveStep(1);
          } else if (activeStep === 1) {
            setActiveStep(2);
          } else if (activeStep === 2) {
            setShareEmail(true);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    } else {
      finalValues.status = -2; // means incomplete
      createDonationRequest(finalValues)
        .then((response) => {
          setId(response.data._id);
          setName(response.data.name);
          setTitle(response.data.title);
          setEditMode(true);
          setActiveStep(1);
          setErrors({});
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrors((currentValues) => {
              return {
                ...currentValues,
                title:
                  "This title is already taken. Please change the title and try again.",
              };
            });
          } else {
            toastr.error("Can not continue. " + error.message);
          }
          setActiveStep(0);
        });
    }
  };

  const updateDefaultImageUrl = (defaultImageUrl) => {
    setDefaultImageUrl(defaultImageUrl);
    setExtraErrors((currentValues) => {
      return {
        ...currentValues,
        image: "",
      };
    });
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser.unique_id,
    };
    updateDonationRequest(finalValues, id)
      .then(() => {})
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the fund raiser. " +
            error.message
        );
      });
  };

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onSavingVideo = (message) => {
    toastr.success(message);
  };

  const closeShareSreen = () => {
    setShareEmail(false);
    props.history.push(`/donationRequest/${name}`);
  };

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const chageBriefHandler = (event) => {
    setBrief(event.target.value);
  };

  const changeJustifyToOthersHandler = (event) => {
    setJustifyToOthers(event.target.value);
  };

  const previewHandler = () => {
    if (brief === "") {
      setExtraErrors((currentValues) => {
        return {
          ...currentValues,
          brief: "Please enter brief for your fundraiser.",
        };
      });
    } else if (justifyToOthers === "") {
      setExtraErrors((currentValues) => {
        return {
          ...currentValues,
          brief: "",
          justifyToOthers:
            "Please justify why you are seeking help from others.",
        };
      });
    } else if (!description) {
      setExtraErrors((currentValues) => {
        return {
          ...currentValues,
          brief: "",
          justifyToOthers: "",
          description: "Please write a story for your fundraiser.",
        };
      });
    } else {
      setExtraErrors((currentValues) => {
        return {
          ...currentValues,
          brief: "",
          justifyToOthers: "",
          description: "",
        };
      });
      localStorage.setItem("donationRequestDescription", description);
      localStorage.setItem("donationRequestBrief", brief);
      localStorage.setItem("donationRequestJustifyToOthers", justifyToOthers);
      localStorage.setItem("isPreviewMode", "true");
      window.open(`/donationRequest/${name}`, "blank");
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, wholeValidateRequest, handleNext);

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  return (
    <div className="make-new-req-container">
      {/* <Hidden mdUp>
        {" "}
        {authUser && (
          <Button
            onClick={handleCloseSideMenu}
            style={{
              color: "#ef6c00",
              minWidth: "50px",
              height: "50px",
              borderRadius: "0px 50px 50px 0",
              marginRight: "10px",
              background: "white",
              position: "fixed",
              zIndex: "3",
              top: "40%",
              boxShadow: "0 0 5px #0000003b",
            }}
          >
            {!closeSideMenu && <MenuIcon />}
            {closeSideMenu && <ArrowBackIcon />}
          </Button>
        )}
      </Hidden> */}

      <div className="request-container-main">
        <Container maxWidth="lg">
          <div className="menu-container">
            {/* {closeSideMenu && (
              <Hidden mdUp>
                {" "}
                <div style={{ marginRight: "10px" }}>
                  <SideMenu />
                </div>
              </Hidden>
            )} */}

            <Hidden smDown>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>

            <div
              className="container-center drop-box"
              style={{ width: "100%", padding: "0", marginBottom: "20px" }}
            >
              <div className={classes.header}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <img
                      src="/images/cash.svg"
                      alt="new request"
                      style={{
                        width: "80px",
                        height: "80px",
                        marginLeft: "20px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Typography
                      component="h4"
                      style={{
                        fontWeight: "bold",
                        fontSize: "32px",
                        lineHeight: "37px",
                        color: "#fff",
                      }}
                      variant="title"
                      gutterBottom
                    >
                      Create A Fundraiser
                    </Typography>
                    <Typography
                      component="p"
                      style={{
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: "#fff",
                      }}
                      variant="headline"
                      gutterBottom
                    >
                      Bridging the gap between those in need, with those willing
                      to give.
                    </Typography>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.root}>
                <h1 className="stepper-title">Here we go!</h1>
                <hr className="sub-hr" />
                <p className="stepper-subheader">
                  Share your fundraise requirement details
                </p>
                <Stepper
                  activeStep={activeStep}
                  style={{ background: "transparent" }}
                  alternativeLabel
                >
                  {getSteps().map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {activeStep === getSteps().length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <Button onClick={handleReset}>Reset</Button>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.instructions}>
                        {activeStep === 0 && (
                          <div className="request-step-one">
                            <form
                              noValidate
                              autoComplete="off"
                              className="make-request-form"
                            >
                              <p className="step-one-title">
                                Enter your goal here
                              </p>
                              <MuiThemeProvider theme={theme}>
                                <Tooltip
                                  classes="req-tooltip"
                                  title={
                                    <Box component="div" color="white">
                                      <span>Set achievable goals</span>
                                    </Box>
                                  }
                                  placement="right"
                                  arrow={true}
                                >
                                  <span style={{ position: "relative" }}>
                                    <label className="amount-label">
                                      Target Amount
                                    </label>
                                    <Select
                                      name="cashUnit"
                                      value={region && region.currency}
                                      className="req-currency"
                                      onChange={currencyChangeHandler}
                                    >
                                      {regions &&
                                        regions.length > 0 &&
                                        regions.map((region) => {
                                          return (
                                            <MenuItem value={region.currency}>
                                              {region.currency === "INR" ? (
                                                <FontAwesomeIcon
                                                  color="#059585"
                                                  size="2x"
                                                  icon={faRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  color="#059585"
                                                  size="2x"
                                                  icon={faDollarSign}
                                                />
                                              )}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                    <TextField
                                      type="number"
                                      required
                                      className={`input-field request-amount ${
                                        errors && errors.cashQuantity
                                          ? "bg-red"
                                          : values.cashQuantity
                                          ? "bg-green"
                                          : "bg-normal"
                                      }`}
                                      variant="outlined"
                                      placeholder="0"
                                      name="cashQuantity"
                                      value={values.cashQuantity}
                                      onChange={changeHandler}
                                    />
                                    {errors && errors.cashQuantity && (
                                      <div className="custom-error">
                                        {errors.cashQuantity}
                                      </div>
                                    )}
                                  </span>
                                </Tooltip>
                              </MuiThemeProvider>
                              <div style={{ position: "relative" }}>
                                {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                                <TextField
                                  type="text"
                                  required
                                  className={`input-field request-title ${
                                    errors && errors.title
                                      ? "bg-red"
                                      : values.title
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  variant="outlined"
                                  label="Fundraiser Title"
                                  name="title"
                                  value={values.title}
                                  onChange={changeHandler}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                />
                                <span className="title-length">
                                  {" "}
                                  {values.title ? values.title.length : 0}/50
                                </span>
                                {errors && errors.title && (
                                  <div className="custom-error">
                                    {errors.title}
                                  </div>
                                )}
                              </div>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  showTodayButton
                                  format="dd-MMM-yyyy"
                                  style={{ width: "100%" }}
                                  margin="normal"
                                  className={`input-field dob ${
                                    extraErrors && extraErrors.dueDate
                                      ? "bg-red"
                                      : dueDate
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  // variant="inline"
                                  label="Fundraiser Deadline"
                                  name="dueDate"
                                  value={dueDate}
                                  onChange={handleDueDateChange}
                                  disablePast
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                              {extraErrors && extraErrors.dueDate && (
                                <div className="custom-error">
                                  {extraErrors.dueDate}
                                </div>
                              )}
                              <FormControl className="category-select">
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Category
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={category && category._id}
                                  onChange={categoryChangeHandler}
                                  variant="outlined"
                                  label="Category"
                                  className={`input-field request-for ${
                                    extraErrors && extraErrors.category
                                      ? "bg-red"
                                      : category && category._id
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  style={{ width: "100%" }}
                                >
                                  {categories &&
                                    categories.length > 0 &&
                                    categories.map((category) => {
                                      return (
                                        <MenuItem value={category._id}>
                                          {category.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                {extraErrors && extraErrors.category && (
                                  <div className="custom-error">
                                    {extraErrors.category}
                                  </div>
                                )}
                              </FormControl>
                              <FormControl className="recipient">
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Recipient
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={values.fundsRecipient}
                                  onChange={changeHandler}
                                  variant="outlined"
                                  className={`input-field request-amount ${
                                    errors && errors.fundsRecipient
                                      ? "bg-red"
                                      : values.fundsRecipient
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  name="fundsRecipient"
                                  label="Recipient"
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value={"self"}>Self</MenuItem>
                                  {orgList &&
                                    orgList.length > 0 &&
                                    orgList.map((org) => {
                                      return (
                                        <MenuItem
                                          value={
                                            org && org.orgId && org.orgId._id
                                          }
                                        >
                                          <img
                                            src={
                                              org &&
                                              org.orgId &&
                                              org.orgId.defaultImageUrl
                                                ? org.orgId.defaultImageUrl
                                                : "/images/orgLogo.png"
                                            }
                                            alt="orgLogo"
                                            width="20px"
                                            style={{ marginRight: 8 }}
                                          />{" "}
                                          {org.orgId.orgName}{" "}
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: "100%",
                                              textAlign: "end",
                                            }}
                                          >
                                            {org.orgId.status === 1 ? (
                                              <CheckCircleOutlineIcon
                                                style={{ color: "green" }}
                                              />
                                            ) : (
                                              <ErrorOutlineIcon
                                                style={{ color: "red" }}
                                              />
                                            )}
                                          </span>
                                        </MenuItem>
                                      );
                                    })}
                                  <MenuItem
                                    value="add"
                                    onClick={handleOrganization}
                                  >
                                    Add Organization
                                  </MenuItem>
                                </Select>{" "}
                                {errors && errors.fundsRecipient && (
                                  <div className="custom-error">
                                    {errors.fundsRecipient}
                                  </div>
                                )}
                              </FormControl>
                              <FormControl className=" recipient">
                                <p className="step-one-title">
                                  Select your location
                                </p>
                                <ShortAddress
                                  value={address}
                                  onChange={handleChangeAddress}
                                />
                                {extraErrors && extraErrors.address && (
                                  <div className="custom-error">
                                    {extraErrors.address}
                                  </div>
                                )}
                              </FormControl>
                            </form>
                          </div>
                        )}
                        {activeStep === 1 && (
                          <div className="request-step-two">
                            <div style={{ width: "63%" }}>
                              <h3 className="step-two-title">
                                Add a cover photo or video
                              </h3>
                              <p className="image-desc">
                                This is the image that will feature right on top
                                of your page. Make it impactful!
                              </p>
                              <MuiThemeProvider theme={theme2}>
                                <Tooltip
                                  classes="req-tooltip"
                                  open={true}
                                  title={
                                    <div>
                                      <h6 style={{ font: "400 12px Roboto" }}>
                                        A picture is worth a thousand words
                                      </h6>
                                      <p
                                        style={{
                                          margin: 3,
                                          font: "400 10px Roboto",
                                        }}
                                      >
                                        <span className="tooltip-dots" />
                                        Keep it relevant
                                      </p>
                                      <p
                                        style={{
                                          margin: 3,
                                          font: "400 10px Roboto",
                                        }}
                                      >
                                        <span className="tooltip-dots" />
                                        Keep it clear
                                      </p>
                                      <p
                                        style={{
                                          margin: 3,
                                          font: "400 10px Roboto",
                                        }}
                                      >
                                        <span className="tooltip-dots" />
                                        Types: .PNG, .JPG, .BMP, .MP4
                                      </p>
                                      <p
                                        style={{
                                          margin: 3,
                                          font: "400 10px Roboto",
                                        }}
                                      >
                                        <span className="tooltip-dots" />
                                        Size: 700px X 400px
                                      </p>
                                    </div>
                                  }
                                  placement="right"
                                  arrow={true}
                                >
                                  <img
                                    src={
                                      defaultImageUrl
                                        ? defaultImageUrl
                                        : "/images/default-req-img.gif"
                                    }
                                    alt="default"
                                    style={{ width: "100%", height: "364px" }}
                                  />
                                </Tooltip>
                              </MuiThemeProvider>
                              {extraErrors && extraErrors.image && (
                                <div className="custom-error">
                                  {extraErrors.image}
                                </div>
                              )}
                              <div style={{ width: "100%" }}>
                                <Button
                                  style={{
                                    width: "48%",
                                    font: "500 12px Roboto",
                                    padding: "20px 30px",
                                    margin: "10px 5px",
                                    background: "transparent",
                                    color: "#283E4A",
                                    border: "1px solid #283E4A",
                                  }}
                                  onClick={() => setShowingAddPhotoModal(true)}
                                >
                                  <FontAwesomeIcon
                                    color="#283E4A"
                                    size="1x"
                                    style={{ marginRight: 6 }}
                                    icon={faFileUpload}
                                  />{" "}
                                  Add Images
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setShowingAddVideoModal(true)}
                                  style={{
                                    background: "transparent",
                                    color: "#283E4A",
                                    border: "1px solid #283E4A",
                                    font: "500 12px Roboto",
                                    padding: "20px 30px",
                                    margin: "10px 5px",
                                    width: "48%",
                                  }}
                                  component="span"
                                >
                                  <FontAwesomeIcon
                                    color="#283E4A"
                                    size="1x"
                                    style={{ marginRight: 6 }}
                                    icon={faFileUpload}
                                  />{" "}
                                  Add Videos
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                        {activeStep === 2 && (
                          <div className="request-step-three">
                            <div style={{ width: "63%" }}>
                              <div style={{ position: "relative" }}>
                                <TextareaAutosize
                                  style={{ padding: "10px 80px 10px 10px" }}
                                  placeholder="Brief description for this fundraiser"
                                  multiline
                                  maxLength={120}
                                  rows="4"
                                  type="text"
                                  required
                                  className={`input-field request-title ${
                                    errors && errors.brief
                                      ? "bg-red"
                                      : brief
                                      ? "bg-green"
                                      : "bg-normal"
                                  }`}
                                  variant="outlined"
                                  label="Self about your Fundraiser"
                                  name="brief"
                                  value={brief}
                                  onChange={chageBriefHandler}
                                  inputProps={{
                                    maxLength: 120,
                                  }}
                                />
                                <span
                                  className="title-length"
                                  style={{ top: 10, padding: "0 4px" }}
                                >
                                  {" "}
                                  {brief ? brief.length : 0}/120
                                </span>
                                {extraErrors && extraErrors.brief && (
                                  <div className="custom-error">
                                    {extraErrors.brief}
                                  </div>
                                )}
                              </div>
                              <TextareaAutosize
                                maxLength={300}
                                name="justifyToOthers"
                                variant="outlined"
                                multiline
                                rows="4"
                                style={{
                                  padding: 10,
                                  border: "1px solid #ccc",
                                }}
                                className={`input-field request-title ${
                                  extraErrors && extraErrors.justifyToOthers
                                    ? "bg-red"
                                    : justifyToOthers
                                    ? "bg-green"
                                    : "bg-normal"
                                }`}
                                placeholder=" Justify why you need help from others "
                                value={justifyToOthers}
                                onChange={changeJustifyToOthersHandler}
                              />
                              {extraErrors && extraErrors.justifyToOthers && (
                                <div className="custom-error">
                                  {extraErrors.justifyToOthers}
                                </div>
                              )}
                              <label
                                style={{
                                  marginTop: 15,
                                  font: "600 16px Roboto",
                                }}
                              >
                                More detailed information about fundraiser:
                              </label>
                              <CKEditor
                                data={description}
                                editor={ClassicEditor}
                                placeholder="More detailed information about the Fundraiser"
                                onChange={changeDescriptionHandler}
                                config={{
                                  toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "Link",
                                  ],
                                }}
                              />
                              {/* </Tooltip>
                          </MuiThemeProvider> */}
                              {extraErrors && extraErrors.description && (
                                <div className="custom-error">
                                  {extraErrors.description}
                                </div>
                              )}
                              <Button
                                className="document-btn"
                                onClick={() => setShowingAddDocumentModal(true)}
                                style={{
                                  width: "60%",
                                  font: "500 12px Roboto",
                                  padding: "20px 30px",
                                  margin: "10px 5px",
                                  background: "transparent",
                                  color: "#283E4A",
                                  border: "1px solid #283E4A",
                                }}
                              >
                                <DescriptionIcon
                                  style={{ fontSize: 14, marginRight: 6 }}
                                />{" "}
                                Upload supporting document(s)
                              </Button>
                              <p className="agree-text">
                                By clicking submit, I agree that i have read and
                                accepted the{" "}
                                <a
                                  href="https://www.touchalife.org/terms-and-conditions/"
                                  target="blank"
                                >
                                  Terms of use
                                </a>{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </Typography>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          style={{
                            display: activeStep === 0 ? "none" : "inline-block",
                            border:
                              activeStep === 1
                                ? "1px solid #var(--tal_primary) "
                                : "1px solid #445761",
                            color:
                              activeStep === 1
                                ? "#var(--tal_primary)"
                                : "#445761",
                          }}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        {activeStep === 2 && (
                          <Button
                            className="preview-btn"
                            onClick={previewHandler}
                          >
                            Preview
                          </Button>
                        )}
                        <Button
                          style={{
                            width:
                              activeStep === 0
                                ? "100%"
                                : activeStep === 1
                                ? "73%"
                                : "37% !important",
                          }}
                          variant="contained"
                          className={`${
                            activeStep === 2
                              ? "short-btn"
                              : activeStep === 1
                              ? "mid-btn"
                              : "continue-btn"
                          }`}
                          onClick={submitHandler}
                        >
                          {activeStep === getSteps().length - 1
                            ? "Submit"
                            : "Save & Continue"}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={shareEmail}
        onClose={closeShareSreen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={shareEmail}>
          <div className={`${classes.paper} share-modal`}>
            <h5 id="transition-modal-title">
              Hurray! Your fundraiser submission is successful.{" "}
              <CloseIcon className="share-close" onClick={closeShareSreen} />
            </h5>
            <h1>Invite friends and family. Raise more funds easily.</h1>
            <p>
              When you share the fundraiser with your friends, family members
              and others you know, the chances of raising funds are higher than
              usual. Go, share the fundraiser now!
            </p>
            <label>Your Fundraiser link: </label>
            <div className="req-link">
              <div>
                <input
                  readOnly
                  ref={textAreaRef}
                  value={`${process.env.REACT_APP_BASE_URL}/donationRequest/${name}`}
                />
              </div>
              {document.queryCommandSupported("copy") && (
                <div>
                  <button onClick={copyCodeToClipboard}>Copy</button>
                </div>
              )}
            </div>
            <label style={{ marginTop: 10, padding: "10px 0" }}>
              Share it via:{" "}
            </label>
            <div className="share-btn">
              <a
                onClick={(e) => shareThroughMail(e)}
                style={{ cursor: "pointer" }}
              >
                Email
              </a>
              <WhatsappShareButton
                url={`${process.env.REACT_APP_BASE_URL}/donationRequest/${name}`}
                quote={title}
                style={{ borderRadius: 4 }}
              >
                <button>Whatsapp</button>
              </WhatsappShareButton>
            </div>
          </div>
        </Fade>
      </Modal>

      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
      />
      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={id}
        collection="DonationRequest"
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
      <AddVideo
        title="Add Videos"
        isOpen={showingAddVideoModal}
        onClose={onCloseAddVideo}
        uid={id}
        collection="DonationRequest"
        onSavingDocument={onSavingVideo}
        canEdit={true}
      />
      {openShareEmail && openShareEmail === true && (
        <ShareThroughEmail
          isOpen={openShareEmail}
          subject={`[TALGiving] I am raising funds for "${name}?"`}
          content={`<br />Please contribute to the fundraiser that I created. Follow the link below for more information: <br /> ${process.env.REACT_APP_BASE_URL}/donationRequest/${name} <br /><br /><img width="400" id=${name} src=${defaultImageUrl}/> <br /><br /> Thanks, <br />Touch-A-Life Foundation`}
          onClose={() => setOpenShareEmail(false)}
          donationRequestName={name}
        />
      )}

      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default NewRequest;

import React, { useContext, useState, Fragment, useEffect } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import { Tooltip } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { signup } from "../../../utils/api";
import useFormValidation from "../../../hooks/useFormValidation";
import validateSignup from "../validateSignup";
import { appContext } from "../../../App";
import cuid from "cuid";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import LogDesign from "../Logdesign";

const useStyles = makeStyles((theme) => ({
  Paper: {
    minHeight: "100vh",
  },

  icons: {
    display: "flex",
    gridGap: "25px",
    justifyContent: "center",

    "& img": {
      cursor: "pointer",
    },
  },
  sign_right: {
    padding: "2rem",
    minHeight: "100vh",

    "& p:last-child": {
      textAlign: "left",
      "& strong": {
        cursor: "pointer",
      },
    },
    "& section": {
      padding: "2rem",

      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0 0",
      },

      "& hr": {
        position: "relative",
        top: "20px",
      },

      "& label": {
        display: "block",
        color: "#696F79",
      },

      "& h4": {
        fontWeight: "800",
        // textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        marginBottom: "20px",
        color: "#273d48",
        fontSize: "32px",
        textAlign: "center",
      },

      "& small": {
        color: "#BABABA",
        display: "block",
        position: "relative",
        backgroundColor: "white",
        padding: "0 5px 0 5px",
        left: "0",
        right: "0",
        top: "-4px",
        width: "220px",
        margin: "0 auto",
        fontSize: "14px",
      },

      "& FormControl": {
        display: "block",
      },
    },
  },
}));

const SignUp = (props) => {
  const classes = useStyles();

  const {
    userLocaleInfo,
    orgUserPayload,
    inviteGeneralUserPayload,
    setHideLoginButton,
    TALLeaderPayload,
  } = useContext(appContext);

  const inviteUserPayload = orgUserPayload
    ? orgUserPayload
    : inviteGeneralUserPayload
    ? inviteGeneralUserPayload
    : TALLeaderPayload
    ? TALLeaderPayload
    : "";

  const initialState = {
    email:
      inviteUserPayload && inviteUserPayload.email
        ? inviteUserPayload.email
        : "",
    secondaryEmail:
      inviteUserPayload && inviteUserPayload.secondaryEmail
        ? inviteUserPayload.secondaryEmail
        : "",
    first_name:
      inviteUserPayload && inviteUserPayload.firstName
        ? inviteUserPayload.firstName
        : "",
    last_name:
      inviteUserPayload && inviteUserPayload.lastName
        ? inviteUserPayload.lastName
        : "",
    phone:
      inviteUserPayload && inviteUserPayload.phone
        ? inviteUserPayload.phone
        : "",
    password: "",
    sourceOfSignup:
      inviteUserPayload &&
      inviteUserPayload.role &&
      inviteUserPayload.role.length > 0 &&
      inviteUserPayload.role.includes("radiojockey")
        ? "talradio"
        : "talleaders",
  };

  const [phone, setPhone] = useState(initialState.phone || null);
  const [showPassword, setShowPassword] = useState(true);
  const [otherErrors, setOtherErrors] = useState({});

  const signupHandler = () => {
    const finalValues = values;
    finalValues.account_verified = true;
    finalValues.sourceOfSignup = "talleaders";
    finalValues.isTALLeader = true;
    //validate the phone input
    if (phone && !isPossiblePhoneNumber(phone)) {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "Please enter valid phone number",
        };
      });
      return;
    } else {
      finalValues.phone = phone;
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "",
        };
      });
    }

    signup(finalValues)
      .then((response) => {
        if (response) {
          if (response.data.data.account_verified === true) {
            props.history.push("/user/interestsAndExpertise");
          } else {
            props.history.push("/");
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          toastr.warning(
            "You already have an account with TALLeaders. Please login to continue."
          );
          props.history.push("/login");
        } else if (error.response && error.response.data) {
          toastr.error(error.response.data.message);
        }
      });
  };

  const googleLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/google/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=google",
      "_self"
    );
  };

  const facebookLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/facebook/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=facebook",
      "_self"
    );
  };

  const linkedInLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/linkedin/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=linkedin",
      "_self"
    );
  };

  const twitterLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/twitter/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=twitter",
      "_self"
    );
  };

  const showSigninPage = () => {
    props.history.push("/login");
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  useEffect(() => {
    setHideLoginButton(true);
    return () => {
      setHideLoginButton(false);
    };
  }, []);
  const wholeValidateSignup = () => {
    const errors = validateSignup(values);
    const otherErrors = {};

    if (phone && !isPossiblePhoneNumber(phone)) {
      otherErrors.phone = "Please enter valid Phone Number";
    }
    setOtherErrors(otherErrors);
    return errors;
  };

  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    wholeValidateSignup,
    signupHandler
  );

  var pswd = values.password;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (
      TALLeaderPayload &&
      TALLeaderPayload.email &&
      TALLeaderPayload.isExistingUser
    ) {
      props.history.push("/user/interestsAndExpertise");
    }
  }, []);
  return (
    <Box component="main" mt={-4}>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <LogDesign />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Paper elevation={0}>
            <Box component="aside" className={classes.sign_right}>
              <Box component="section">
                <Box component="h4">Create a TALLeaders Account</Box>

                {/* {(!orgUserPayload || !inviteGeneralUserPayload) && (
                  <Fragment>
                    <Box component="div" className={classes.icons}>
                      <img
                        src="/images/google_icon.svg"
                        alt="google"
                        width="25px"
                        onClick={googleLoginHandler}
                      />
                      <img
                        src="/images/facebook_icon.svg"
                        alt="facebook"
                        width="13px"
                        onClick={facebookLoginHandler}
                      />
                      <img
                        src="/images/linkedin_icon.svg"
                        alt="linkedin"
                        width="25px"
                        onClick={linkedInLoginHandler}
                      />
                      <img
                        src="/images/twitter_icon.svg"
                        alt="twitter"
                        width="25px"
                        onClick={twitterLoginHandler}
                      />
                    </Box>
                  </Fragment>
                )}

                <hr />
                <Box component="small">or use your email for registration</Box> */}

                <Box component="article" my={2}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <div className="names">
                          {(orgUserPayload && orgUserPayload.firstName) ||
                          (inviteGeneralUserPayload &&
                            inviteGeneralUserPayload.firstName) ? (
                            <TextField
                              className={`full-width border-radius signup-input ${
                                errors.first_name && "error-text"
                              }`}
                              fullWidth
                              placeholder="First Name *"
                              name="first_name"
                              variant="outlined"
                              onChange={changeHandler}
                              onKeyPress={(e) => handleEnterClick(e)}
                              value={values.first_name}
                              disabled
                              label="First Name *"
                            />
                          ) : (
                            <TextField
                              className={`full-width border-radius signup-input ${
                                errors.first_name && "error-text"
                              }`}
                              variant="outlined"
                              fullWidth
                              placeholder="First Name *"
                              name="first_name"
                              onChange={changeHandler}
                              onKeyPress={(e) => handleEnterClick(e)}
                              value={values.first_name}
                              label="First Name *"
                            />
                          )}
                          {errors && (
                            <div className="custom-error">
                              {errors.first_name}
                            </div>
                          )}
                        </div>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <div className="names">
                          {(orgUserPayload && orgUserPayload.lastName) ||
                          (inviteGeneralUserPayload &&
                            inviteGeneralUserPayload.lastName) ? (
                            <TextField
                              className={`full-width border-radius signup-input ${
                                errors.last_name && "error-text"
                              }`}
                              fullWidth
                              variant="outlined"
                              placeholder="Last Name *"
                              name="last_name"
                              onChange={changeHandler}
                              value={values.last_name}
                              onKeyPress={(e) => handleEnterClick(e)}
                              disabled
                              label="Last Name *"
                            />
                          ) : (
                            <TextField
                              className={`full-width border-radius signup-input ${
                                errors.last_name && "error-text"
                              }`}
                              variant="outlined"
                              fullWidth
                              placeholder="Last Name *"
                              name="last_name"
                              onChange={changeHandler}
                              value={values.last_name}
                              onKeyPress={(e) => handleEnterClick(e)}
                              label="Last Name *"
                            />
                          )}
                          {errors && errors.last_name && (
                            <div className="custom-error">
                              {errors.last_name}
                            </div>
                          )}
                        </div>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Box
                          component="div"
                          border="1px solid #ccc"
                          borderRadius="5px"
                          p={2}
                        >
                          <PhoneInput
                            variant="outlined"
                            fullWidth
                            placeholder=" Phone number"
                            name=" Phone number "
                            defaultCountry={
                              userLocaleInfo && userLocaleInfo.country_code
                            }
                            value={phone}
                            onChange={setPhone}
                            onKeyPress={(e) => handleEnterClick(e)}
                            label="Phone number"
                          />
                        </Box>

                        {otherErrors && otherErrors.phone && (
                          <div className="custom-error">
                            {otherErrors.phone}
                          </div>
                        )}
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          className={`full-width border-radius signup-input ${
                            errors.secondaryEmail && "error-text"
                          }`}
                          fullWidth
                          placeholder="Personal email address"
                          name="secondaryEmail"
                          variant="outlined"
                          onChange={changeHandler}
                          onKeyPress={(e) => handleEnterClick(e)}
                          value={values.secondaryEmail}
                          label="Personal email address"
                        />
                        {errors && errors.secondaryEmail && (
                          <div className="custom-error">
                            {errors.secondaryEmail}
                          </div>
                        )}
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        {(orgUserPayload && orgUserPayload.email) ||
                        (TALLeaderPayload && TALLeaderPayload.email) ||
                        (inviteGeneralUserPayload &&
                          inviteGeneralUserPayload.email) ? (
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.email && "error-text"
                            }`}
                            fullWidth
                            label="Email address *"
                            name="email"
                            variant="outlined"
                            onChange={changeHandler}
                            onKeyPress={(e) => handleEnterClick(e)}
                            value={values.email}
                            disabled
                          />
                        ) : (
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.email && "error-text"
                            }`}
                            fullWidth
                            placeholder="@touchalife.org email address *"
                            name="email"
                            variant="outlined"
                            onChange={changeHandler}
                            onKeyPress={(e) => handleEnterClick(e)}
                            value={values.email}
                            autoComplete="off"
                            label="@touchalife.org email address *"
                          />
                        )}
                        {errors && errors.email && (
                          <div className="custom-error">{errors.email}</div>
                        )}
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <div className="flname">
                          <div className="pass-container">
                            <div className="names pass-field">
                              <Box component="div" position="relative">
                                <TextField
                                  className={`full-width border-radius signup-input ${
                                    errors.password && "error-text"
                                  }`}
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Create a Password *"
                                  type={showPassword ? "password" : "text"}
                                  name="password"
                                  onChange={changeHandler}
                                  onKeyPress={(e) => handleEnterClick(e)}
                                  // onClick={showPasswordRules}
                                  value={values.password}
                                  label="Create a Password"
                                  required
                                />
                                <InputAdornment
                                  fullWidth
                                  position="end"
                                  className="showpassword"
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    style={{
                                      top: "3px",
                                      position: "absolute",
                                      right: "10px",
                                    }}
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Tooltip title="Click to Show the Password">
                                        <VisibilityOff />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Click to hide the Password">
                                        <Visibility />
                                      </Tooltip>
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              </Box>
                            </div>

                            {(errors ||
                              !pswd.match(/[a-z]/) ||
                              !pswd.match(/[A-Z]+/) ||
                              !pswd.match(/\d/) ||
                              !pswd.match(/[^a-zA-Z0-9\-/]/) ||
                              pswd.length < 6) && (
                              <div className="custom-error">
                                {errors.password}
                              </div>
                            )}
                            {pswd &&
                              (!pswd.match(/[a-z]/) ||
                                !pswd.match(/[A-Z]+/) ||
                                !pswd.match(/\d/) ||
                                !pswd.match(/[^a-zA-Z0-9\-/]/) ||
                                pswd.length < 6) && (
                                <div className="password-strength">
                                  <ul className="password-error">
                                    <li>
                                      {pswd.match(/[a-z]/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one letter</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one letter</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li>
                                      {pswd.match(/[A-Z]+/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one capital letter</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one capital letter</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li>
                                      {pswd.match(/\d/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one digit</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one digit</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li>
                                      {pswd.match(/[^a-zA-Z0-9\-/]/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one special character</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one special character</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li
                                      className={
                                        pswd.length >= 6 ? "valid" : "invalid"
                                      }
                                      style={{ "text-align": "start" }}
                                    >
                                      {pswd.length >= 6 ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>6 characters</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>6 characters</strong>
                                        </Alert>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Button
                  fullWidth
                  className="tal_primary_bg "
                  variant="contained"
                  type="submit"
                  onClick={submitHandler}
                >
                  Signup
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(SignUp);

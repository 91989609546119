import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
  Paper,
  MenuItem,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import AddDocument from "../common/AddDocument";
import FormHelperText from "@material-ui/core/FormHelperText";
import NewAddress from "../common/NewAddress";
import { appContext } from "../../App";
import Select from "@material-ui/core/Select";
import {
  createDonationRequest,
  getUserData,
  updateDonationRequest,
  updateProfile,
} from "../../utils/api";
import useFormValidation from "../../hooks/useFormValidation";
import { donationRequestShareUrl, HOME_PAGE } from "../../utils/utils";
import "./HomeWork.scss";
import IconButton from "@material-ui/core/IconButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "react-phone-number-input/style.css";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShortAddress from "../common/ShortAddress";
import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import PhoneNumber from "../Phone/PhoneNumber";
import { Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const useStyles = makeStyles((theme) => ({
  field: {
    width: "589px",
    margin: "auto",
    marginRight: "125",
  },
}));
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Homework = (props) => {
  const initialState = {
    occupation: "",
    experience: "",
    linkedInProfile: "",
    subject: "",
    preferredLanguage: "",
    title: "",
    brief: "",
  };
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState);
  const { authUser, setAuthUser, region } = useContext(appContext);
  const [closeSideMenu] = useState(false);

  const [dueDate, setDueDate] = useState(null);
  const [description, setDescription] = useState("");
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const onSavingDocument = (message, url) => {
    setDocumentUrl(url);
    toastr.success(message);
  };

  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [id, setId] = useState("");

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const classes = useStyles();
  const phoneRef = useRef();

  const handleSubmitData = () => {
    const finalValues = {};
    const errors = {};
    if (description.length === 0) {
      errors.description = "Please enter Description";
    } else if (description && description.length <= 10) {
      errors.description = "Please enter atleast 10 characters";
    }
    if (values.title.length === 0) {
      errors.title = "Please enter Title";
    }
    if (
      tempAddress === null ||
      tempAddress.city === null ||
      tempAddress.state === null ||
      tempAddress.country === null
    ) {
      errors.address = "Please Select your Location";
    }
    if (dueDate === null) {
      errors.dueDate = "Please Select Due Date";
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      finalValues.user_id = authUser.unique_id;
      finalValues.region = region._id;
      finalValues.request_type = "homework help";
      finalValues.creatorType = "student";
      //  finalValues.tags = ["Homework", "Student"];
      finalValues.requested_for = values.subject;
      let additionalInfo = {
        contactNumber: authUser.phone,
        occupation: values.occupation,
        linkedInProfile: values.linkedInProfile,
        preferred_language: values.preferredLanguage,
        documentUrl: documentUrl,
      };
      finalValues.additionalInfo = additionalInfo;
      finalValues.title = values.title;
      finalValues.brief = values.brief;
      finalValues.description = description;
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
      finalValues.quantity = "0";

      finalValues.due_date = dueDate.getTime();
      if (activeStep === 0) {
        createDonationRequest(finalValues)
          .then((response) => {
            if (response.statusCode === 200) {
              setId(response.data._id);
              setActiveStep(1);
              setErrors({});
              scrollToTop();
            }
          })
          .catch((error) => {
            toastr.error(error.response.data.message);
          });
      } else {
        additionalInfo = { ...additionalInfo, documentUrl: documentUrl };
        finalValues.additionalInfo = additionalInfo;
        updateDonationRequest(finalValues, id)
          .then((response) => {

              props.history.push(`/donationRequest/${response.name}`);
              toastr.success(
                "Your Homework Help Request is successfully created "
              );
          })
          .catch((error) => {
            toastr.error(error.response.data.message);
          });
      }
    }
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      loadUserData(authUser.unique_id);
    }
  }, [authUser]);

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        values.occupation = userData.occupation;
        values.linkedInProfile = userData.linkedInProfileUrl;
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  };

  return (
    <div className="career-help-container">
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <Grid md={3} style={{ marginRight: "8px" }}>
              <SideMenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/homework.png"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "20px",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Homework Help
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Bridging the gap between students and teachers to make
                    better students
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid container>
              <div className={classes.field}>
                {activeStep === 0 && (
                  <Grid item md={12} xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          lineHeight: "37px",
                          color: "#000",
                          padding: "10px",
                          textAlign: "start",
                        }}
                      >
                        Personal Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl style={{ padding: "15px", width: "100%" }}>
                        <PhoneNumber
                          phone={authUser && authUser.phone}
                          isPhoneVerified={authUser && authUser.phone_verified}
                          ref={phoneRef}
                        />
                      </FormControl>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12}>
                        <FormControl
                          style={{ padding: "15px", width: "100%" }}
                          error={errors && errors.occupation}
                        >
                          <TextField
                            id="outlined-error-helper-text"
                            label={"Occupation"}
                            value={values.occupation}
                            onChange={changeHandler}
                            variant="outlined"
                            className="text-field"
                            name="occupation"
                          />
                          <FormHelperText>{errors.occupation}</FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={6}>
                    <FormControl
                      style={{ padding: "15px", width: "100%" }}
                      error={errors && errors.experience}
                    >
                      <TextField
                        id="outlined-error-helper-text"
                        label="Experience"
                        onChange={changeHandler}
                        name="experience"
                        variant="outlined"
                        className="text-field"
                        values={values.experience}
                      />
                      <FormHelperText>{errors.experience}</FormHelperText>
                    </FormControl>
                  </Grid> */}
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        style={{ padding: "15px", width: "100%" }}
                        error={errors && errors.linkedInProfile}
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label={"LinkedIn profile"}
                          value={values.linkedInProfile}
                          onChange={changeHandler}
                          variant="outlined"
                          className="text-field"
                          name="linkedInProfile"
                        />
                        <FormHelperText>
                          {errors.linkedInProfile}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{ width: "100%", padding: "15px" }}
                        className=" recipient"
                        error={errors && errors.address}
                      >
                        <p className="step-one-title">Select your location</p>
                        <ShortAddress
                          value={address}
                          onChange={handleChangeAddress}
                        />
                        <FormHelperText>{errors.address}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        style={{ width: "100%", padding: "0 15px 15px" }}
                        error={errors && errors.subject}
                      >
                        <TextField
                          id="subject"
                          label="Enter Subject/Topic in which you need help"
                          value={values.subject}
                          onChange={changeHandler}
                          variant="outlined"
                          className="text-field"
                          name="subject"
                          placeholder="Enter Subject/Topic in which you need help"
                        />

                        <FormHelperText>{errors.subject}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{ padding: "15px", width: "100%" }}
                        error={errors && errors.title}
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label="Give a title to the homework help you need"
                          value={values.title}
                          onChange={changeHandler}
                          variant="outlined"
                          className="text-field"
                          name="title"
                          placeholder="Give a title to the homework help you need"
                        />
                        <FormHelperText>{errors.title}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        component="p"
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#263238",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Description for Homework Help
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{ width: "100%", padding: "15px" }}
                        className="recipient"
                        error={errors && errors.description}
                      >
                        <CKEditor
                          style={{ padding: "10px 20px" }}
                          data={description}
                          name="description"
                          editor={ClassicEditor}
                          onChange={changeDescriptionHandler}
                          config={{
                            toolbar: [
                              "Heading",
                              "bold",
                              "italic",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "Link",
                            ],
                            placeholder: "Describe the homework help in detail",
                          }}
                        />

                        {errors && errors.description && (
                          <FormHelperText>{errors.description}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        component="p"
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#263238",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Brief for Homework Help
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{ width: "100%", padding: "15px" }}
                        className="recipient"
                        error={errors && errors.brief}
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label="Brief"
                          placeholder="Describe the homework help in brief"
                          value={values.brief}
                          onChange={changeHandler}
                          variant="outlined"
                          name="brief"
                        />
                        <FormHelperText>{errors.brief}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{
                          width: "100%",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        className="recipient"
                        error={errors && errors.dueDate}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            showTodayButton
                            format="dd-MMM-yyyy"
                            style={{ width: "100%" }}
                            margin="normal"
                            className={`input-field dob`}
                            inputVariant="outlined"
                            label="Due Date"
                            name="endDate"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            disablePast
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>{errors.dueDate}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{ width: "100%", padding: "15px" }}
                        error={errors && errors.preferredLanguage}
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label="Preferred Language"
                          placeholder="Enter Preferred Language"
                          value={values.preferredLanguage}
                          onChange={changeHandler}
                          variant="outlined"
                          className="text-field"
                          name="preferredLanguage"
                        />
                        <FormHelperText>
                          {errors.preferredLanguage}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <>
                    <Grid item xs={12} style={{ padding: "0 10px" }}>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          lineHeight: "37px",
                          color: "#000",
                          padding: "0px 5px",
                          textAlign: "start",
                        }}
                      >
                        Upload any supporting documents that can help the
                        teacher in understanding your need better
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0 10px" }}>
                      <Button
                        className="document-btn"
                        onClick={() => setShowingAddDocumentModal(true)}
                        style={{
                          // width: "60%",
                          font: "500 12px Roboto",
                          // padding: "20px 30px",
                          margin: "10px 5px",
                          background: " #fff",
                          color: " rgb(244, 67, 54)",
                          padding: "15px",
                          border: "1px solid   rgb(244, 67, 54)",
                        }}
                      >
                        <CloudUploadIcon
                          style={{ fontSize: 6, marginRight: 4 }}
                        />{" "}
                        Upload file(s)
                      </Button>
                      {documentUrl ? (
                        <Typography variant="p" style={{ marginLeft: "15px" }}>
                          Document uploaded
                        </Typography>
                      ) : (
                        <Typography variant="p" style={{ marginLeft: "15px" }}>
                          No file uploaded
                        </Typography>
                      )}
                      {errors && errors.documentUrl && (
                        <div className="custom-error">{errors.documentUrl}</div>
                      )}
                    </Grid>
                  </>
                )}
                <Grid>
                  <Button
                    style={{
                      background: "#f44336",
                      margin: "30px 15px 30px 0",
                      float: "right",
                    }}
                    className="submit-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmitData}
                  >
                    {activeStep === 0 ? "Next" : "Create"}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div>
        <AddDocument
          title="Add Supporting Documents"
          isOpen={showingAddDocumentModal}
          onClose={onCloseAddDocument}
          uid={id}
          collection="DonationRequest"
          onSavingDocument={onSavingDocument}
          canEdit={true}
        />
      </div>
    </div>
  );
};

export default withRouter(Homework);

import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import "./TalLeaders.scss";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Drawer,
  Fade,
  Grid,
  InputBase,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  TablePagination,
  TextareaAutosize,
  Typography,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import cssStyle from "./AdminTALHerosAwards.module.scss";
import cssstyles from "./TalHeroProfile.module.scss";
import {
  checkUrlWithHttp,
  disLikeTALHero,
  getTALHeroData,
  getTALHeros,
  likeTALHero,
  updateTALHeroProfile,
} from "../../utils/api";
import toastr from "toastr";
import Loader from "../common/Loader";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { SearchRounded, ThumbUpAltOutlined } from "@material-ui/icons";
import FeedbackTalLeaders from "./FeedbackTalLeaders";
import { appContext } from "../../App";
import { TAL_HEROS_COUNTRIES } from "../../utils/utils";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    " & > div": {
      "&:first-child": {
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      },
    },
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    width: "450px",
    overflowX: "auto",
    height: "351px",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  modalorg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  papers: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",

    overflowX: "auto",
    width: "450px",
    height: "219px",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  paper2: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "450px",
    overflowX: "auto",
    height: "220px",
    boxShadow: theme.shadows[5],
    paddingBottom: "10px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
}));
const AdminTALHerosAwards = (props) => {
  const { authUser, region } = useContext(appContext);
  const classes1 = useStyles();
  const [value, setValue] = React.useState("1");
  const [openView, setOpenView] = useState(false);
  const [age, setProfile] = React.useState("");
  const [showNewNominees, setShowNewNominees] = useState(true);
  const [showApprovedNominees, setShowApprovedNominees] = useState(false);
  const [showRejectedNominees, setShowRejectedNominees] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState("");
  const [rowId, setRowId] = useState("");
  const [tabStatus, setTabStatus] = useState(0);
  const [regionFilter, setRegionFilter] = useState(" ");
  const [isLoaded, setIsLoaded] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [viewNoteModal, setViewNoteModal] = useState(false);
  const [selectedRowComments, setSelectedRowComments] = useState("");
  const [commentsData, setCommentsData] = useState([]);
  const [approvalOrRejectionNotes, setApprovalOrRejectionNotes] = useState("");
  const [approveClicked, setApproveClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [id, setId] = useState("");
  const [approvedNotes, setApprovedNotes] = useState("");
  const [rejectedNotes, setRejectedNotes] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [query, setQuery] = useState("");
  const [likeCommentValue, setLikeCommentValue] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const [fromViewApplication, setFromViewApplication] = useState(false);
  const [isFileDownload, setIsFileDownload] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState(" ");

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      // backgroundColor: '#f5f5f9',
      // color: 'rgba(0, 0, 0, 0.87)',
      // maxWidth: 220,
      // fontSize: theme.typography.pxToRem(12),
      // border: '1px solid #dadde9',
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "noral",
    },
  }))(Tooltip);

  const handleNewNominees = (status) => {
    if (status !== tabStatus) {
      setTabStatus(status);
      setShowNewNominees(true);
      setShowApprovedNominees(false);
      setShowRejectedNominees(false);
    }
  };
  const handleApproved = (status) => {
    if (status !== tabStatus) {
      setTabStatus(status);
      setShowApprovedNominees(true);
      setShowNewNominees(false);
      setShowRejectedNominees(false);
    }
  };
  const handleRejected = (status) => {
    if (status !== tabStatus) {
      setTabStatus(status);
      setShowRejectedNominees(true);
      setShowNewNominees(false);
      setShowApprovedNominees(false);
    }
  };

  const handleCategoryFilter = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleRegionFilter = (event) => {
    setRegionFilter(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setProfile(event.target.value);
  };
  const [drawerstate, setDrawerstate] = useState(false);

  const toggleDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerstate({ ...state, right: isopen });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadTALHeros(newPage, query, userList);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUserList([]);
  };

  const loadTALHeros = (page = 0, query, userList) => {
    setIsLoading(true);
    getTALHeros(query, rowsPerPage, page * rowsPerPage, null)
      .then((response) => {
        setTotalCountOfRecords(response.totalCountOfRecords);
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          const userListArray = [];
          userList.map((data) => {
            const obj = data;
            userListArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            responseArray.push(obj);
            return null;
          });
          setUserList([...userListArray, ...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching users:" + error.message);
      });
  };

  const handleViewProfile = (state, isopen, rowId) => {
    setDrawerstate({ ...state, right: isopen });
    setRowId(rowId);
  };

  const handleUserData = (rowId) => {
    setIsLoaded(true);
    getTALHeroData(rowId)
      .then((response) => {
        setIsLoaded(false);
        setUserData(response);
        setSelectedRowComments(response);
        setCommentsData(response.userComments);
      })
      .catch((error) => {
        setIsLoaded(false);
        toastr.error(error.response);
      });
  };

  useEffect(() => {
    var query = `sourceOfSignup=talleaders&status=${tabStatus}&startDate=${1722796200000}`;
    setQuery(query);
  }, []);

  useEffect(() => {
    if (
      TAL_HEROS_COUNTRIES &&
      TAL_HEROS_COUNTRIES.length > 0 &&
      region &&
      region._id
    ) {
      TAL_HEROS_COUNTRIES.forEach((country) => {
        if (country.key === "United States" && region._id === "US") {
          setRegionFilter(country.key);
        } else if (country.key === "India" && region._id === "IN") {
          setRegionFilter(country.key);
        }
      });
    }
  }, [region, TAL_HEROS_COUNTRIES]);

  useEffect(() => {
    query && loadTALHeros(0, query, []);
  }, [query]);

  useEffect(() => {
    if (rowId) {
      handleUserData(rowId);
    }
  }, [rowId]);

  useEffect(() => {
    query && loadTALHeros(0, query, []);
  }, [rowsPerPage]);

  useEffect(() => {
    var query = "";
    if (
      searchFilter ||
      categoryFilter ||
      (regionFilter == " " ? "" : regionFilter) ||
      tabStatus
    ) {
      query = `sourceOfSignup=talleaders&status=${tabStatus}&keyword=${searchFilter}&country=${regionFilter.trim()}&awardCategory=${categoryFilter.trim()}&startDate=${1722796200000}`;
      setQuery(query);
      setUserList([]);
      setPage(0);
    } else {
      query = `sourceOfSignup=talleaders&status=${tabStatus}&startDate=${1722796200000}`;
      setQuery(query);
      setUserList([]);
      setPage(0);
    }
  }, [tabStatus, regionFilter, categoryFilter, searchFilter]);

  const handleViewModal = (rowId, tabValue) => {
    setLikeCommentValue(tabValue);
    setOpenView(true);
    setRowId(rowId);
  };

  const handleCloseViewModal = () => {
    setOpenView(false);
    setRowId("");
    setUserData("");
    setErrors({});
  };

  const handleShortList = () => {
    setReasonModal(true);
    const finalValues = {};
    const errors = {};
    if (!approvalOrRejectionNotes) {
      errors.notes = "Please provide a approval reason";
    }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      return;
    } else {
      finalValues.status = 1;
      finalValues.notes = approvalOrRejectionNotes;
      finalValues.reviewedBy = authUser && [{ userId: authUser.unique_id }];
      updateTALHeroProfile(id, finalValues)
        .then((response) => {
          toastr.success("Nominee shortlisted successfully");
          setReasonModal(false);
          setApprovalOrRejectionNotes("");
          setUserList(userList.filter((user) => user && user._id !== id));
          fromViewApplication && setDrawerstate(false);
          setTotalCountOfRecords(totalCountOfRecords - 1);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              toastr.error(error.response.data.errors[0].msg);
            } else {
              toastr.error(error.response.message);
            }
          } else {
            toastr.error(error.message);
          }
        });
    }
  };

  const handleReject = () => {
    const finalValues = {};
    const errors = {};
    if (!approvalOrRejectionNotes) {
      errors.notes = "Please provide a rejection reason";
    }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      return;
    } else {
      finalValues.status = -1;
      finalValues.notes = approvalOrRejectionNotes;
      finalValues.reviewedBy = authUser && [{ userId: authUser.unique_id }];
      updateTALHeroProfile(id, finalValues)
        .then((response) => {
          toastr.success("Nominee rejected successfully");
          setReasonModal(false);
          setApprovalOrRejectionNotes("");
          setUserList(userList.filter((user) => user && user._id !== id));
          fromViewApplication && setDrawerstate(false);
          setTotalCountOfRecords(totalCountOfRecords - 1);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              toastr.error(error.response.data.errors[0].msg);
            } else {
              toastr.error(error.response.message);
            }
          } else {
            toastr.error(error.message);
          }
        });
    }
  };

  const handleApproveReject = (status, rowId, isViewApplication) => {
    setReasonModal(true);
    setId(rowId);
    status === "1" ? setApproveClicked(true) : setApproveClicked(false);
    isViewApplication == true
      ? setFromViewApplication(true)
      : setFromViewApplication(false);
  };

  const handleCloseNotesModal = () => {
    setReasonModal(false);
    setApprovalOrRejectionNotes("");
    setErrors("");
  };

  const handleViewNotesModal = (row) => {
    setViewNoteModal(true);
    if (row && row.status == "1") {
      setApprovedNotes(row.notes);
      setRejectedNotes("");
    } else {
      setRejectedNotes(row.notes);
      setApprovedNotes("");
    }
  };

  const handleLikeUnlike = (row) => {
    if (row && row._id) {
      let user =
        (row.userLikes &&
          row.userLikes.some((item) => item._id === authUser.unique_id)) ||
        row.userLikes.includes(authUser.unique_id);

      if (user) {
        disLikeTALHero(row._id).then((response) => {
          response && response.data && saveTALHero(response.data);
        });
      } else {
        likeTALHero(row._id).then((response) => {
          response && response.data && saveTALHero(response.data);
        });
      }
    }
  };

  const handleMovetoNewNominee = (fromViewApplication, rowId) => {
    const finalValues = {};
    finalValues.status = 0;
    updateTALHeroProfile(rowId, finalValues)
      .then(() => {
        toastr.success("Nominee moved successfully");
        setUserList(userList.filter((user) => user && user._id !== rowId));
        fromViewApplication && setDrawerstate(false);
        setTotalCountOfRecords(totalCountOfRecords - 1);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            toastr.error(error.response.data.errors[0].msg);
          } else {
            toastr.error(error.response.message);
          }
        } else {
          toastr.error(error.message);
        }
      });
  };

  const handleSearchFilter = (event) => {
    let keyword = event.target.value;
    if (keyword === "") {
      setSearchFilter("");
    }
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      setSearchFilter(event.target.value);
    }
  };

  const exportToCSV = () => {
    setIsFileDownload(true);
    let download = true;
    getTALHeros(query, "", "", "", download).then((response) => {
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(response);
      hiddenElement.target = "_blank";
      hiddenElement.download = `Tal_Heroes_Award_Data-${new Date().getTime()}.csv`;
      hiddenElement.click();
      setIsFileDownload(false);
    });
  };

  const saveTALHero = (updatedResponse) => {
    setUserList((users) => {
      return users.map((userData) => {
        if (userData._id === updatedResponse._id) {
          return {
            ...userData,
            userLikes: updatedResponse.userLikes,
          };
        }
        return userData;
      });
    });
  };

  return (
    <>
      <Grid className="main_box">
        <Container>
          <Grid container>
            <Grid item md={12}>
              <h2>TALHero Awards </h2>
              <p>
                An initiative of Touch-a-Life Foundation looking to celebrate
                and honor unsung heroes.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid>
        <Container>
          <Box className={cssStyle.Admin_statusBar}>
            <Box>
              <Button
                className={
                  showNewNominees ? cssStyle.Btn_newLed : cssStyle.Btn_new
                }
                onClick={() => handleNewNominees("0")}
              >
                New Nominees
              </Button>

              <Button
                className={
                  showApprovedNominees ? cssStyle.Btn_newLed : cssStyle.Btn_new
                }
                onClick={() => handleApproved("1")}
              >
                Shortlisted
              </Button>

              <Button
                className={
                  showRejectedNominees ? cssStyle.Btn_newLed : cssStyle.Btn_new
                }
                onClick={() => handleRejected("-1")}
              >
                Rejected
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid>

      <Container>
        <Grid container className={cssStyle.nomi_bar}>
          <Grid item md={6}>
            <div className={cssStyle.sub_head}>
              <h2>New Nominated TALHeroes</h2>
              <p>
                Touch-A-Life Foundation Leadership Team supports innovative ways
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container style={{ margin: "30px 0px" }}>
          <Grid item md={5} className={cssStyle.Sort_slelcts}>
            <Box className={cssStyle.btn_Exports}>
              <SearchRounded style={{ margin: "0 4px", height: "50px" }} />
              <InputBase
                placeholder=" Search TALLeaders"
                width="200px"
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => handleSearchFilter(event)}
                onKeyPress={(event) => handleEnterClick(event)}
              />
            </Box>
          </Grid>
          <Grid item md={3} className={cssStyle.Srch_Exports}>
            <span>Sort By :</span>
            <Select
              className={cssStyle.Select_btn}
              variant="outlined"
              name="region"
              placeholder="All Profile"
              value={regionFilter}
              onChange={(event) => handleRegionFilter(event)}
            >
              <MenuItem value=" ">All Locations</MenuItem>
              {TAL_HEROS_COUNTRIES &&
                TAL_HEROS_COUNTRIES.map((country, index) => {
                  return (
                    <MenuItem value={country.key} key={index}>
                      {country.value}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item md={4} className={cssStyle.Sort_slelct}>
            <FormControl variant="outlined">
              <Select
                variant="outlined"
                className={cssStyle.Select_btn}
                value={categoryFilter}
                onChange={(event) => handleCategoryFilter(event)}
              >
                <MenuItem value=" ">All Profiles</MenuItem>
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Non Profit">Non Profit</MenuItem>
                <MenuItem value="Corporate">Corporate</MenuItem>
              </Select>
            </FormControl>

            <Box>
              <Button className={cssStyle.Export_btn} onClick={exportToCSV}>
                <img src="../images/Expot.svg" />{" "}
                {!isFileDownload && <p>Export</p>}{" "}
                {isFileDownload && (
                  <CircularProgress
                    style={{
                      color: "var(--tal_primary) ",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Container>

      <Container className={cssStyle.main_tab}>
        <TabContext value={value}>
          <TabPanel value="1" style={{ padding: "24px 0px" }}>
            <Grid container className={cssStyle.mid_row}>
              {showNewNominees ? (
                <Grid item md={3}>
                  <p className={cssStyle.le_text_algin}>Name</p>
                </Grid>
              ) : (
                <Grid item md={2}>
                  <p className={cssStyle.le_text_algin}>Name</p>
                </Grid>
              )}
              <Grid item md={2}>
                <p style={{ marginLeft: "33px" }}>Category</p>
              </Grid>
              {!showRejectedNominees && showNewNominees ? (
                <Grid item md={2}>
                  <p>Nominated By</p>
                </Grid>
              ) : showApprovedNominees ? (
                <Grid item md={1}>
                  <p>Nominated By</p>
                </Grid>
              ) : (
                ""
              )}
              <Grid item md={2}>
                <p style={{ marginLeft: "5px" }}>Profile</p>
              </Grid>
              {!showNewNominees && (
                <>
                  <Grid item md={1}>
                    <p style={{ marginLeft: "5px" }}>Votes</p>
                  </Grid>
                  <Grid item md={1}>
                    <p style={{ marginLeft: "5px" }}>Comments</p>
                  </Grid>
                  <Grid item md={2}>
                    <p style={{ marginLeft: "5px" }}>Note</p>
                  </Grid>
                </>
              )}
              {showRejectedNominees && (
                <Grid item md={1}>
                  <p style={{ marginLeft: "-10px" }}>Rejected By</p>
                </Grid>
              )}
              {showNewNominees ? (
                <Grid item md={3}>
                  <p style={{ marginLeft: "15px" }}>Action</p>
                </Grid>
              ) : (
                <Grid item md={1}>
                  <p style={{ marginLeft: "15px" }}>Action</p>
                </Grid>
              )}
            </Grid>
            {userList &&
              userList.length > 0 &&
              userList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Grid container className={cssStyle.name_align}>
                    {showNewNominees ? (
                      <Grid item md={3} className={cssStyle.img_align}>
                        {/* <div>
                  <img src="../images/tal-leader-icon.png" />
                </div> */}
                        <div className={cssStyle.name_txt}>
                          <span className={cssStyle.name_title}>
                            {row.nomineeFirstName}&nbsp; {row.nomineeLastName}
                          </span>
                          <p>{row.country}</p>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item md={2} className={cssStyle.img_align}>
                        {/* <div>
                  <img src="../images/tal-leader-icon.png" />
                </div> */}
                        <div className={cssStyle.name_txt}>
                          <span className={cssStyle.name_title}>
                            {row.nomineeFirstName}&nbsp; {row.nomineeLastName}
                          </span>
                          <p>{row.country}</p>
                        </div>
                      </Grid>
                    )}
                    <Grid item md={2} className={cssStyle.name_pro_box}>
                      <span>{row.awardCategory}</span>
                    </Grid>
                    {showNewNominees ? (
                      <Grid item md={2} className={cssStyle.Veiw_Profile}>
                        <span>{row.firstName}</span>
                      </Grid>
                    ) : showApprovedNominees ? (
                      <Grid item md={1} className={cssStyle.Veiw_Profile}>
                        <span>{row.firstName}</span>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item md={2} className={cssStyle.Veiw_Profile}>
                      <Button
                        onClick={() =>
                          handleViewProfile("right", true, row._id)
                        }
                      >
                        View Application
                      </Button>
                      <Drawer
                        anchor="right"
                        open={drawerstate["right"]}
                        onClose={toggleDrawer("right", false)}
                        className="Veiw_Profile_radius Veiw_Profile_radius1"
                      >
                        {!isLoaded && userData && (
                          <div>
                            <div className={cssstyles.Fade_style}>
                              {/* <h1 className={classes.orgTitle}>TALHero profile</h1> */}
                              <div
                                style={{
                                  marginTop: "20px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <div className={cssstyles.Fad_style}>
                                  <div className={cssstyles.img_style}>
                                    <img
                                      className={cssStyle.image_style}
                                      src={
                                        userData && userData.nomineeImageUrl
                                          ? userData.nomineeImageUrl
                                          : "../images/tal-leader-icon.svg"
                                      }
                                    />
                                  </div>
                                  <div className={cssstyles.name_text}>
                                    <div className={cssstyles.text_mrg}>
                                      <span className={cssstyles.name_title}>
                                        {userData.nomineeFirstName}&nbsp;
                                        {userData.nomineeLastName}
                                      </span>
                                      <div>
                                        <span className={cssstyles.name_ptag}>
                                          {userData.awardCategory}
                                        </span>
                                      </div>
                                    </div>
                                    <div className={cssstyles.text_mrg}>
                                      <div>
                                        <img
                                          className={cssstyles.profile_img}
                                          src="../images/map-pin.svg"
                                          alt="phone"
                                        ></img>
                                      </div>
                                      <div>
                                        <p>{userData.country}</p>
                                      </div>
                                    </div>

                                    <div className={cssstyles.text_mrg}>
                                      <div>
                                        {" "}
                                        <img
                                          className={cssstyles.profile_img}
                                          src="../images/mail.svg"
                                          alt="mail"
                                        ></img>
                                      </div>
                                      <div>
                                        {" "}
                                        <p>{userData.nomineeEmail}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <span
                                    className={
                                      cssstyles.drop_box_close_organizatin
                                    }
                                  >
                                    <a
                                      href={
                                        userData &&
                                        checkUrlWithHttp(
                                          userData.nomineeSocialProfile
                                        )
                                      }
                                      target="_blank"
                                    >
                                      <Button>
                                        Visit profile
                                        <img
                                          className={cssstyles.profile_imgs}
                                          src="../images/external-link.svg"
                                          alt="mail"
                                        />
                                      </Button>
                                    </a>
                                  </span>
                                </div>
                              </div>
                              <div
                                className={cssstyles.Veiw_align}
                                onClick={toggleDrawer("right", false)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                            <div className={cssstyles.plasma}>
                              <div className={cssstyles.profile_mrg}>
                                <Typography className={cssstyles.profile_p}>
                                  Nominee Short Bio/Descripition
                                </Typography>
                              </div>
                              <div>
                                <p
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  {userData.nomineeDescription}
                                </p>
                              </div>
                              <hr />
                              <div className={cssstyles.profile_mrg}>
                                <span className={cssstyles.name_title}>
                                  Nominated By
                                </span>
                              </div>
                              <div className={cssStyle.mrg_flex}>
                                {" "}
                                <div className={cssstyles.img_style}>
                                  <img
                                    className={cssStyle.image_style}
                                    src={
                                      userData && userData.imageUrl
                                        ? userData.imageUrl
                                        : "../images/tal-leader-icon.svg"
                                    }
                                  />
                                </div>
                                <div className={cssStyle.center_style}>
                                  <span>
                                    {userData.firstName}&nbsp;
                                    {userData.lastName}
                                  </span>
                                  <div className={cssstyles.text_mrg}>
                                    <div>
                                      {" "}
                                      <img
                                        className={cssstyles.profile_img}
                                        src="../images/mail.svg"
                                        alt="mail"
                                      ></img>
                                    </div>
                                    <div>
                                      {" "}
                                      <p>{userData.email}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ marginTop: "20px" }}>
                                <p
                                  style={{
                                    whiteSpace: "pre-line",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  {userData.description}
                                </p>
                              </div>
                            </div>
                            <div className={cssStyle.btn_fixed}>
                              <hr style={{ margin: "30px 0px" }} />
                              <div>
                                <div className={cssstyles.text_flexs}>
                                  {" "}
                                  {!showRejectedNominees && (
                                    <div>
                                      <Button
                                        className={cssStyle.Rejct_btn}
                                        onClick={() =>
                                          handleApproveReject(
                                            "-1",
                                            userData._id,
                                            true
                                          )
                                        }
                                      >
                                        Reject
                                      </Button>
                                    </div>
                                  )}
                                  {(showNewNominees ||
                                    showRejectedNominees) && (
                                    <div>
                                      <Button
                                        className={cssStyle.Approve_btn}
                                        onClick={() =>
                                          handleApproveReject(
                                            "1",
                                            userData._id,
                                            true
                                          )
                                        }
                                      >
                                        Shortlist
                                      </Button>
                                    </div>
                                  )}
                                  {(showApprovedNominees ||
                                    showRejectedNominees) && (
                                    <div>
                                      <Button
                                        className={cssStyle.Approve_Btn}
                                        onClick={() =>
                                          handleMovetoNewNominee(
                                            true,
                                            userData._id
                                          )
                                        }
                                      >
                                        Move to New Nominee
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Drawer>
                    </Grid>
                    {!showNewNominees && (
                      <>
                        <Grid item md={1}>
                          <span>
                            <span
                              className={cssStyle.Like_btn}
                              onClick={(e) => handleLikeUnlike(row)}
                            >
                              <ThumbUpAltOutlined
                                style={{
                                  fontSize: "18px",
                                  color:
                                    (row &&
                                      row.userLikes &&
                                      row.userLikes.some(
                                        (item) =>
                                          item._id === authUser.unique_id
                                      )) ||
                                    row.userLikes.includes(authUser.unique_id)
                                      ? "#29a027"
                                      : "#555",
                                }}
                              />
                            </span>
                            <span
                              className={cssStyle.Like_btn}
                              onClick={() => handleViewModal(row._id, 0)}
                            >
                              (
                              {row && row.userLikes && row.userLikes.length > 0
                                ? row.userLikes.length
                                : 0}
                              )
                            </span>
                            {/* <span onClick={() => handleLikeUnlike(row._id)}>
                            <img
                              src="../images/vote-icon.svg"
                              style={{
                                color:
                                  row &&
                                  row.userLikes &&
                                  row.userLikes.some(
                                    (item) => item._id === authUser.unique_id
                                  )
                                    ? "red"
                                    : "#555",
                              }}
                            />
                          </span> */}
                          </span>
                        </Grid>
                        <Grid item md={1} className={cssStyle.Veiw_Profile}>
                          <div
                            className={cssStyle.comment_styel}
                            onClick={() => handleViewModal(row._id, 1)}
                          >
                            <img src="../images/comment.svg" />
                            {"  "}
                            <span style={{ marginRight: "10px" }}>
                              (
                              {row &&
                              row.userComments &&
                              row.userComments.length > 0
                                ? row.userComments.length
                                : 0}
                              )
                            </span>
                          </div>
                        </Grid>
                        <Grid item md={2} className={cssStyle.Veiw_Profile}>
                          <span
                            className={cssStyle.View_style}
                            onClick={() => handleViewNotesModal(row)}
                          >
                            <VisibilityOutlinedIcon /> View Note
                          </span>
                        </Grid>
                        {showRejectedNominees && (
                          <Grid item md={1} className={cssStyle.Veiw_Profile}>
                            <div
                              style={{ wordBreak: "break-all", width: "60px" }}
                            >
                              {row.reviewedBy &&
                                row.reviewedBy[0] &&
                                row.reviewedBy[0].userId &&
                                row.reviewedBy[0].userId.name && (
                                  <span>
                                    {row.reviewedBy[0].userId.name.first_name}{" "}
                                    {row.reviewedBy[0].userId.name.middle_name}{" "}
                                    {row.reviewedBy[0].userId.name.last_name}
                                  </span>
                                )}
                            </div>
                          </Grid>
                        )}
                      </>
                    )}
                    {!showNewNominees && (
                      <Grid
                        item
                        xs={12}
                        md={1}
                        sm={6}
                        className={cssStyle.Veiw_Profile}
                      >
                        <span className={cssStyle.View_style}>
                          {" "}
                          {showApprovedNominees && (
                            <BootstrapTooltip
                              title="Remove from Shortlisted and
                                    move to New Nominee"
                            >
                              <img
                                style={{ marginRight: "10px" }}
                                src="../images/corner-up-left.svg"
                                onClick={() =>
                                  handleMovetoNewNominee(false, row._id)
                                }
                              />
                            </BootstrapTooltip>
                          )}
                          {showRejectedNominees && (
                            <BootstrapTooltip
                              title="Remove from Rejected and
                                    move to New Nominee"
                            >
                              <img
                                style={{ marginRight: "10px" }}
                                src="../images/corner-up-left.svg"
                                onClick={() =>
                                  handleMovetoNewNominee(false, row._id)
                                }
                              />
                            </BootstrapTooltip>
                          )}
                          {showApprovedNominees && (
                            <BootstrapTooltip title="Reject Nominee">
                              <img
                                src="../images/user-x.svg"
                                onClick={() =>
                                  handleApproveReject("-1", row._id, false)
                                }
                              />
                            </BootstrapTooltip>
                          )}
                          {showRejectedNominees && (
                            <BootstrapTooltip title="Shortlist this profile">
                              <img
                                src="../images/new_web_images/user-check.svg"
                                onClick={() =>
                                  handleApproveReject("1", row._id, false)
                                }
                              />
                            </BootstrapTooltip>
                          )}
                        </span>
                      </Grid>
                    )}

                    {showNewNominees && !showApprovedNominees && (
                      <Grid item md={3}>
                        <Grid className={cssStyle.act_btn_tal}>
                          <Button
                            className={cssStyle.Approve_btn}
                            onClick={() =>
                              handleApproveReject("1", row._id, false)
                            }
                          >
                            {" "}
                            Shortlist
                          </Button>
                          <Button
                            className={cssStyle.Rejct_btn}
                            onClick={() =>
                              handleApproveReject("-1", row._id, false)
                            }
                          >
                            Reject
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ))}
            {!isLoading &&
              showNewNominees &&
              !showApprovedNominees &&
              !showRejectedNominees &&
              userList &&
              userList.length == 0 && (
                <p style={{ textAlign: "center", marginTop: "40px" }}>
                  Uh-oh! Currently, there are no nominations received. Please
                  come back later.
                </p>
              )}
            {!isLoading &&
              showApprovedNominees &&
              !showNewNominees &&
              !showRejectedNominees &&
              userList &&
              userList.length == 0 && (
                <p style={{ textAlign: "center", marginTop: "40px" }}>
                  Uh-oh! Currently, there are no profiles shortlisted. Please
                  come back later.
                </p>
              )}
            {!isLoading &&
              showRejectedNominees &&
              !showNewNominees &&
              !showApprovedNominees &&
              userList &&
              userList.length == 0 && (
                <p style={{ textAlign: "center", marginTop: "40px" }}>
                  Uh-oh! Currently, there are no nominations rejected. Please
                  come back later.
                </p>
              )}
          </TabPanel>

          <TabPanel value="2">All Leaders</TabPanel>
        </TabContext>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCountOfRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
      <FeedbackTalLeaders
        openFeedbackModal={openView}
        onCloseModal={handleCloseViewModal}
        userData={userData}
        value={likeCommentValue}
        setValue={setLikeCommentValue}
        setUserData={setUserData}
        selectedRowComments={selectedRowComments}
        setSelectedRowComments={setSelectedRowComments}
        commentsData={commentsData}
        setCommentsData={setCommentsData}
      />
      <Loader
        isOpen={isLoading ? isLoading : isLoaded}
        onClose={() => (isLoading ? setIsLoading(false) : setIsLoaded(false))}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes1.modal}
        open={reasonModal}
        onClose={() => setReasonModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={reasonModal}>
          <div className={classes1.paper}>
            <div className={cssStyle.tab_bar}>
              <p className={cssStyle.Rejct_span}>
                {approveClicked
                  ? "Provide a reason for Approval"
                  : "Provide a reason for Rejection"}
              </p>
              <p>
                {" "}
                <CloseIcon
                  className={cssStyle.cursor_view}
                  onClick={handleCloseNotesModal}
                />
              </p>
            </div>
            <Divider />
            <div>
              {" "}
              <TextareaAutosize
                id="w3review"
                name="w3review"
                rows="4"
                //cols="50"
                multiline
                maxLength={1200}
                className={cssStyle.Rejct_Area}
                placeholder={
                  approveClicked
                    ? "Add your note explaning your approval decision....."
                    : "Add your note explaning your rejection decision....."
                }
                value={approvalOrRejectionNotes}
                onChange={(event) =>
                  setApprovalOrRejectionNotes(event.target.value)
                }
              ></TextareaAutosize>
              <div>
                {errors && errors.notes && (
                  <Typography className="custom-errors">
                    {errors.notes}
                  </Typography>
                )}
              </div>
            </div>

            <Divider
              style={{ marginTop: "10px" }}
              className={cssStyle.custom_errors}
            />
            <div
              className={
                approveClicked ? cssStyle.Rejct_text : cssStyle.accept_btn
              }
            >
              <Button onClick={handleCloseNotesModal}>Cancel</Button>
              {approveClicked ? (
                <Button onClick={() => handleShortList()}>Shortlist</Button>
              ) : (
                <Button onClick={() => handleReject()}>Reject</Button>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes1.modalorg}
        open={viewNoteModal}
        onClose={() => setViewNoteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={viewNoteModal}>
          <div className={classes1.papers}>
            <div className={cssStyle.tab_bars}>
              <p className={cssStyle.Rejct_span}>
                {showApprovedNominees ? "Approved Notes" : "Rejected Note"}
              </p>
              <p>
                {" "}
                <CloseIcon
                  className={cssStyle.cursor_view}
                  onClick={() => setViewNoteModal(false)}
                />
              </p>
            </div>
            <Divider style={{ margin: "0px,14px" }} />
            <div>
              {" "}
              <p className={cssStyle.Rejct_Areas}>
                {approvedNotes ? approvedNotes : rejectedNotes}
              </p>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default withRouter(AdminTALHerosAwards);

import React, { useEffect, useContext } from "react";
import Loader from "../common/Loader";
import { Grid } from "@material-ui/core";
import { appContext } from "../../App";
import toastr from "toastr";
import { VerifyOrganizationEmailMessage } from "../../utils/api";
import Container from "@material-ui/core/Container";

const VerifyOrganizationEmail = (props) => {
  const { authUser } = useContext(appContext);

  useEffect(() => {
    const orgId = props.match.params.orgId;
    const userId = props.match.params.userId;
    if (orgId && userId) {
      VerifyOrganizationEmailMessage(orgId)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            if (authUser !== null && authUser.unique_id === userId) {
              props.history.push("/");
            } else {
              props.history.push("/login");
            }
          }
        })
        .catch((error) => {
          toastr.error(
            error.response && error.response.data && error.response.data.message
          );
          if (authUser !== null && authUser.unique_id === userId) {
            props.history.push("/");
          } else {
            props.history.push("/login");
          }
        });
    } else {
      toastr.warning("Error. Invalid verification link");
      props.history.push("/login");
    }
  }, [authUser]);

  return (
    <div className="myDonations-container">
      <Container maxWidth="lg">
        <Grid container>
          <Grid xs={9}>
            <Loader isOpen={true} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default VerifyOrganizationEmail;

import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import { Grid, Typography, TextField } from "@material-ui/core";
import validateAddress from "./validateAddress";
import useSpecialFormValidation from "../../hooks/useSpecialFormValidation";
import { getAllCountries, getAllStates, getAllCities } from "../../utils/api";
import { appContext } from "../../App";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { getCountry } from "../../utils/utils";

import "./styles.scss";

const ShortAddressIsolated = (props) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [shouldUpdateValues, setShouldUpdateValues] = useState(true);

  const filter = createFilterOptions();

  const handleChangeSelectedCountry = (event, newValue) => {
    if (newValue) {
      values.country = newValue.name;
      getAllStates(newValue.id)
        .then((result) => {
          setStates(result.data);
        })
        .catch((error) => {
          toastr.error("Problem in getting states: " + error.message);
        });
    } else {
      values.country = newValue;
    }
    onConfirm();
  };

  const handleChangeSelectedState = (event, newStateValue) => {
    if (typeof newStateValue === "string") {
      values.state = newStateValue;
    } else if (newStateValue && newStateValue.name) {
      // Create a new value from the user input
      values.state = newStateValue.name;
      getAllCities(newStateValue.id)
        .then((result) => {
          setCities(result.data);
        })
        .catch((error) => {
          toastr.error("Problem in getting states: " + error.message);
        });
    } else {
      values.state = newStateValue;
    }
    onConfirm();
  };

  const handleChangeSelectedCity = (event, newCityValue) => {
    if (typeof newCityValue === "string") {
      values.city = newCityValue;
    } else if (newCityValue && newCityValue.name) {
      // Create a new value from the user input
      values.city = newCityValue.name;
    } else {
      values.city = newCityValue;
    }
    onConfirm();
  };

  useEffect(() => {
    getAllCountries().then((result) => {
      const countryData = getCountry(result);
      countryData && setCountries(countryData);
      if (props.value) {
        if (props.value.country && countries && countries.length > 0) {
          getAllStates(
            countries.filter(
              (country) => country.name === props.value.country
            )[0].id
          )
            .then((result) => {
              setStates(result.data);

              getAllCities(
                result.data.filter(
                  (state) => state.name === props.value.state
                )[0].id
              )
                .then((result) => {
                  setCities(result.data);
                })
                .catch((error) => {
                  toastr.error("Problem in getting states: " + error.message);
                });
            })
            .catch((error) => {
              toastr.error("Problem in getting states: " + error.message);
            });
        }
      }
    });

    if (props && props.value && props.value.city && shouldUpdateValues) {
      setValues({
        city: props.value.city,
        state: props.value.state,
        country: props.value.country,
      });
      setShouldUpdateValues(false);
    }
  }, [props.value]);

  const initialState = {
    city: props.value && props.value.city,
    state: props.value && props.value.state,
    country: props.value && props.value.country,
  };

  const onConfirm = () => {
    const address = {
      city: values.city,
      state: values.state,
      country: values.country,
    };
    props.onChange(address);
  };

  const { values, setValues } = useSpecialFormValidation(
    initialState,
    validateAddress,
    onConfirm
  );

  return (
    <Grid
      container
      spacing={2}
      className="newAddr-container select-your-location    "
    >
      <Grid item xs={12} md={12} className="addr-select-container">
        <Autocomplete
          className="select-add"
          value={values.country}
          onChange={handleChangeSelectedCountry}
          id="country-option"
          options={countries}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.name) {
              return option.name;
            }
            return option.name;
          }}
          renderOption={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Country"
              variant="outlined"
              id="country"
              name="country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <Autocomplete
          className="select-add"
          value={values.state}
          onChange={handleChangeSelectedState}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `${params.inputValue}`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="state-option"
          options={states}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.name) {
              return option.name;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(option) => option.name}
          style={{ width: "100%" }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select State"
              variant="outlined"
              id="state"
              name="state"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} className="addr-select-container">
        <Autocomplete
          className="select-add"
          value={values.city}
          onChange={handleChangeSelectedCity}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `${params.inputValue}`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="city-option"
          options={cities}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.name) {
              return option.name;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(option) => option.name}
          style={{ width: "100%" }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select City"
              variant="outlined"
              id="city"
              name="city"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          {values.city} {values.city && ", "} {values.state}{" "}
          {values.state && ", "} {values.country}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ShortAddressIsolated;

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import toastr from "toastr";
import {
  Link,
  Grid,
  List,
  IconButton,
  Tooltip,
  Paper,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Menu from "@material-ui/core/Menu";
import Container from "@material-ui/core/Container";
import Img from "react-cool-img";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { appContext } from "../../App";
import Hidden from "@material-ui/core/Hidden";
import Audio from "../liveStreaming/Audio";

import "./home2.scss";
import {
  getDonationRequests,
  getUserDonationRequests,
  getChatRooms,
} from "../../utils/api";

import SideMenu from "../SideMenu/Sidemenu";
import StickyBox from "react-sticky-box";
import useInfiniteScroll from "react-infinite-scroll-hook";
import CloseIcon from "@material-ui/icons/Close";
import {
  AVATAR_DONEE,
  STATUS_APPROVED,
  AVATAR_DONOR,
  STATUS_ALL,
  STATUS_REJECTED,
  STATUS_SPAM,
  STATUS_EXPIRED,
  STATUS_COMPLETED,
  STATUS_NEW,
  HOME_PAGE,
  TALMEDIA_PAGE,
  TALRADIO_SITE,
} from "../../utils/utils";

import Chip from "@material-ui/core/Chip";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DonationRequestCard from "../donationRequest/DonationRequestCard";
import PublicHome from "./PublicHome";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div" className="details-child">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
  img: {
    maxWidth: "100%",
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    padding: "10px 0",

    "& img": {
      width: "290px",
    },
  },

  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    paddingTop: "8px",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "4px",
    background: "rgb(246, 247, 251)",
    fontWeight: "400",
  },
  alert: {
    width: "100%",
    backgroundColor: "rgb(243, 90, 87)",
    maxHeight: "-10px !important",
  },
  ptag: {
    color: "#fff",
    padding: "8px",
    textAlign: "center",
    marginTop: "-25px",
  },
}));

const Home2 = (props) => {
  const ITEM_HEIGHT = 260;
  const classes = useStyles();

  const donationRequestFilters = [
    {
      key: "0",
      label: "Board Member",
      filterData: '{"request_type":"board member"}',
    },
    {
      key: "1",
      label: "Podcast",
      filterData: '{"request_type":"podcast"}',
    },
    {
      key: "2",
      label: "Mentoring",
      filterData: '{"request_type":"mentoring"}',
    },
    {
      key: "3",
      label: "Event Speaker",
      filterData: '{"request_type":"eventSpeaker"}',
    },
  ];
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);

  const [chipData, setChipData] = useState(donationRequestFilters);
  const donationRequestFeedStatusFilters = [
    { key: STATUS_APPROVED, label: "Approved" },
    { key: STATUS_COMPLETED, label: "Fulfilled" },
    { key: STATUS_EXPIRED, label: "Expired" },
  ];
  const defaultDonationRequestFeedStatusFilters = [
    { key: STATUS_APPROVED, label: "Approved" },
    { key: STATUS_COMPLETED, label: "Fulfilled" },
  ];
  const defaultRequestTypesToGetUserRequests = JSON.stringify([
    { request_type: "cash" },
    { request_type: "plasma", creatorType: "donor" },
    { request_type: "plasma", creatorType: "donee" },
    { request_type: "career", creatorType: "mentee" },
    { request_type: "help", creatorType: "donee" },
    { request_type: "internship", creatorType: "employer" },
    { request_type: "volunteering", creatorType: "volunteering organization" },
    { request_type: "board member" },
    { request_type: "podcast" },

    { request_type: "homework help", creatorType: "student" },
    { request_type: "mentoring" },
    { request_type: "eventSpeaker" },
  ]);
  const [feedStatusData, setFeedStatusData] = useState(
    defaultDonationRequestFeedStatusFilters
  );

  const handleCheck = (event) => {
    event.persist();
    if (event.target.checked) {
      if (chipData.some((chip) => chip.key === event.target.name)) {
      } else {
        setChipData((currentData) => [
          ...currentData,
          donationRequestFilters[event.target.name],
        ]);
      }
    } else {
      setChipData((chips) =>
        chips.filter((chip) => chip.key !== event.target.name)
      );
    }
  };

  const updateFeedStatus = (event) => {
    if (event.target.checked) {
      if (feedStatusData.some((status) => status.key === event.target.name)) {
      } else {
        setFeedStatusData((currentData) => [
          ...currentData,
          donationRequestFeedStatusFilters.find(
            (donationRequestFeedStatusFilter) =>
              donationRequestFeedStatusFilter.key === event.target.name
          ),
        ]);
      }
    } else {
      setFeedStatusData(
        feedStatusData.filter((status) => status.key !== event.target.name)
      );
    }
  };

  const handleDelete = (chipToDelete) => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleFeedStatusDelete = (chipToDelete) => {
    setFeedStatusData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const [open] = useState(false);
  const anchorRef = useRef(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const {
    authUser,
    region,
    avatar,
    isVolunteerApplicationFlow,
    currentYear,
    chats,
    setChats,
  } = useContext(appContext);
  const [donationRequests, setDonationRequests] = useState([]);
  const [countSummary, setCountSummary] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchDonationRequests, setSearchDonationRequests] = useState([]);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(STATUS_ALL);
  const openPostMenu = Boolean(anchorEl);
  const openPostMenu2 = Boolean(anchorEl2);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const defaultRequestTypes = JSON.stringify([
    { request_type: "board member" },
    { request_type: "podcast" },
    { request_type: "mentoring" },
    { request_type: "eventSpeaker" },
  ]);

  const handlePostMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePostMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePostMenuClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handlePostMenuClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClearSearch = () => {
    setHasNextPage(false);
    props.history.push(HOME_PAGE);
  };

  const loadChatRoom = () => {
    setIsLoading(true);
    getChatRooms()
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          let data = response.data;
          if (authUser && authUser.unique_id)
            data = data.filter(
              (chat) => authUser.unique_id === chat.ownerId._id
            );
          const list = data.map((i) =>
            moment(i.startTime).format("DD,MMM YYYY")
          );
          const uniqueList = Array.from(new Set(list));
          const merged = uniqueList.map((c) => {
            return { startTime: c, chats: [] };
          });

          data.forEach((d) => {
            merged
              .find(
                (g) => g.startTime === moment(d.startTime).format("DD,MMM YYYY")
              )
              .chats.push(d);
          });

          setChats(merged);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Streamings:" + error);
      });
  };

  useEffect(() => {
    loadChatRoom();
  }, []);

  const loadDonationRequests = () => {
    if (avatar === AVATAR_DONEE && region && region._id) {
      setIsLoading(true);
      getUserDonationRequests(
        authUser && authUser.unique_id,
        status,
        region && region._id,
        rowsPerPage,
        offset,
        true,
        defaultRequestTypesToGetUserRequests
      )
        .then((response) => {
          setIsLoading(false);
          if (
            response.data &&
            response.data instanceof Array &&
            response.data.length >= 0
          ) {
            const newOffset = offset + response.data.length;
            if (newOffset < response.totalCountOfRecords) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }
            setOffset(newOffset);
            setDonationRequests([...donationRequests, ...response.data]);
            setTotalCountOfRecords(response.totalCountOfRecords);
            setCountSummary(response.countSummary);
          } else {
            setHasNextPage(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setHasNextPage(false);
          toastr.error(
            "Problem in fetching donation requests:" + error.message
          );
        });
    } else if (region && region._id) {
      setIsLoading(true);
      let requestType = "";
      chipData &&
        chipData.forEach((chip, index) => {
          if (index === 0) {
            requestType = "[";
          }
          requestType =
            requestType +
            chip.filterData +
            (index < chipData.length - 1 ? "," : "");
          if (index === chipData.length - 1) {
            requestType = requestType + "]";
          }
        });
      let feedStatus = "";
      feedStatusData &&
        feedStatusData.forEach((chip, index) => {
          feedStatus =
            feedStatus +
            chip.key +
            (index < feedStatusData.length - 1 ? "," : "");
        });
      getDonationRequests(
        region && region._id,
        rowsPerPage,
        offset,
        authUser && authUser.unique_id,
        requestType == "" ? defaultRequestTypes : requestType,
        "",
        feedStatus,
        "",
        authUser && authUser.unique_id
      )
        .then((response) => {
          setIsLoading(false);
          if (
            response.data &&
            response.data instanceof Array &&
            response.data.length >= 0
          ) {
            const newOffset = offset + response.data.length;
            if (newOffset < response.totalCountOfRecords) {
              setHasNextPage(true);
            } else {
              setHasNextPage(false);
            }
            setOffset(newOffset);
            setDonationRequests([...donationRequests, ...response.data]);
            setTotalCountOfRecords(response.totalCountOfRecords);
            setCountSummary(response.countSummary);
          } else {
            setHasNextPage(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setHasNextPage(false);
          toastr.error(
            "Problem in fetching donation requests:" + error.message
          );
        });
    }
  };

  const handleScroll = (e) => {
    if (
      window.innerHeight + e.target.documentElement.scrollTop + 1 ===
      e.target.documentElement.scrollHeight
    ) {
      loadDonationRequests(keyword);
    }
  };

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: region && region._id && hasNextPage,
    onLoadMore: loadDonationRequests,
  });

  const [keywordRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: keyword && hasNextPage,
    onLoadMore: () => loadDonationRequestsBasedOnKeyword(keyword),
  });

  const removeFromList = (id) => {
    setDonationRequests(
      donationRequests.filter((donationRequest) => donationRequest._id !== id)
    );
  };

  const loadDonationRequestsBasedOnKeyword = (keyword) => {
    setIsLoading(true);
    let requestType = "";
    donationRequestFilters &&
      donationRequestFilters.forEach((filter, index) => {
        if (index === 0) {
          requestType = "[";
        }
        requestType =
          requestType +
          filter.filterData +
          (index < donationRequestFilters.length - 1 ? "," : "");
        if (index === donationRequestFilters.length - 1) {
          requestType = requestType + "]";
        }
      });
    getDonationRequests(
      "",
      rowsPerPage,
      offset,
      authUser && authUser.unique_id,
      requestType == "" ? defaultRequestTypes : requestType,
      encodeURIComponent(keyword),
      "",
      "",
      authUser && authUser.unique_id
    )
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          const newOffset = offset + response.data.length;
          if (newOffset < response.totalCountOfRecords) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
          setOffset(newOffset);
          setSearchDonationRequests([
            ...searchDonationRequests,
            ...response.data,
          ]);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setHasNextPage(false);
        setIsLoading(false);
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  useEffect(() => {
    setKeyword(props.match.params.keyword);
  }, [props.match.params.keyword]);

  useEffect(() => {
    if (keyword) {
      setIsSearchMode(true);
      setOffset(0);
      setHasNextPage(true);
      setSearchDonationRequests([]);
    } else {
      setDonationRequests([]);
      setIsSearchMode(false);
      setOffset(0);
      setHasNextPage(true);
    }
  }, [keyword]);

  useEffect(() => {
    if (region && region._id) {
      setOffset(0);
      setDonationRequests([]);
      setHasNextPage(true);
      window.scrollTo(0, 0);
    }
  }, [region]);

  useEffect(() => {
    setOffset(0);
    setDonationRequests([]);
    setHasNextPage(true);
    setIsSearchMode(false);
    window.scrollTo(0, 0);
  }, [avatar]);

  useEffect(() => {
    setOffset(0);
    setDonationRequests([]);
    setHasNextPage(true);
  }, [status]);

  useEffect(() => {
    setOffset(0);
    setDonationRequests([]);
    setHasNextPage(true);
  }, [chipData]);

  useEffect(() => {
    setOffset(0);
    setDonationRequests([]);
    setHasNextPage(true);
  }, [feedStatusData]);

  useEffect(() => {
    if (isVolunteerApplicationFlow) {
      if (authUser && authUser.unique_id) {
        toastr.warning("Please fillup the answers to all the questions.");
        props.history.push(`/volunteerProfile/${authUser.unique_id}`);
      } else {
        toastr.warning(
          "Please sign up and then fillup the TALScout application form."
        );
        props.history.push("/signup");
      }
    }
  }, []);

  const [expanded] = React.useState(false);

  const handleChange = (event, newValue) => {
    setOffset(0);
    setDonationRequests([]);
    setHasNextPage(true);
    setValue(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  var settingsms = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 0,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          dots: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: true,
        },
      },
    ],
  };

  var settingsls = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 0,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
          initialSlide: 0,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (authUser) {
      const queryString = encodeURI(
        `?first_name=${authUser.name.first_name}&last_name=${
          authUser.name.last_name
        }&email=${authUser.email}&phone=${authUser.phone}&country=${
          authUser.address && authUser.address.country
        }&role=${authUser.institutionalRole}&website=${
          authUser.institutionUrl
        }&institution=${authUser.institutionName}&userId=${
          authUser.unique_id
        }&profilePictureUrl=${authUser.profile_image_url}&authToken=${
          authUser.token_detail && authUser.token_detail.token
        }`
      );
    }
  }, [authUser]);

  const settingsslider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      <div className="home2-container">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              {authUser && (
                <Hidden smDown>
                  <div className="container-left">
                    <StickyBox offsetTop={20} offsetBottom={20}>
                      <SideMenu />
                    </StickyBox>
                  </div>
                </Hidden>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="order-col-2">
              <div className="container-center">
                {!isSearchMode && avatar === AVATAR_DONOR && (
                  <Fragment>
                    <Paper square>
                      <Tabs
                        value={value}
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="filter tabs"
                      >
                        <Tab label="All Filters" />
                        <Tab label="By Apps" />
                        <Tab label="By Status" />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                        <section className="tabs-chips drop-box">
                          {/* <div className="chip-main">   </div> */}
                          {/* <div className="chip-box-desktop"> </div> */}
                          <div className="filter-st">
                            <Slider {...settings}>
                              {chipData.map((data) => {
                                return (
                                  <div key={data.key}>
                                    <Chip
                                      label={data.label}
                                      onDelete={() => handleDelete(data)}
                                      className={classes.chip}
                                    />
                                  </div>
                                );
                              })}
                              {feedStatusData.map((data) => {
                                return (
                                  <div key={data.key}>
                                    <Chip
                                      label={data.label}
                                      onDelete={() =>
                                        handleFeedStatusDelete(data)
                                      }
                                      className={classes.chip}
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>

                          <div className="filter-bg">
                            <div className="filter-one">
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handlePostMenuClick}
                              ></IconButton>
                              <Menu
                                style={{ zIndex: "1" }}
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={openPostMenu}
                                onClose={handlePostMenuClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "25ch",
                                  },
                                }}
                                className="popup"
                              >
                                <AppBar
                                  style={{
                                    position: "static",
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                  }}
                                  className="app-bar"
                                >
                                  <Tabs
                                    value={value1}
                                    onChange={handleChange1}
                                    aria-label="simple tabs example"
                                  >
                                    <Tab
                                      label="All"
                                      {...a11yProps(0)}
                                      style={{ fontWeight: "bold" }}
                                    />

                                    <Tab
                                      label="Status"
                                      {...a11yProps(1)}
                                      style={{ fontWeight: "bold" }}
                                    />
                                  </Tabs>
                                </AppBar>
                                <TabPanel
                                  component="div"
                                  value={value1}
                                  index={0}
                                  style={{ padding: "10px" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={chipData.some(
                                          (chip) => chip.key === "0"
                                        )}
                                        onChange={handleCheck}
                                        name="0"
                                      />
                                    }
                                    label="Board Member"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={chipData.some(
                                          (chip) => chip.key === "1"
                                        )}
                                        onChange={handleCheck}
                                        name="1"
                                      />
                                    }
                                    label="Podcast"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={chipData.some(
                                          (chip) => chip.key === "2"
                                        )}
                                        onChange={handleCheck}
                                        name="2"
                                      />
                                    }
                                    label="Mentoring"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={chipData.some(
                                          (chip) => chip.key === "3"
                                        )}
                                        onChange={handleCheck}
                                        name="3"
                                      />
                                    }
                                    label="Event Speaker"
                                  />
                                </TabPanel>

                                <TabPanel
                                  component="div"
                                  value={value1}
                                  index={1}
                                  style={{ padding: "10px" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={feedStatusData.some(
                                          (status) =>
                                            status.key == STATUS_APPROVED
                                        )}
                                        onChange={updateFeedStatus}
                                        name={STATUS_APPROVED}
                                      />
                                    }
                                    label={
                                      "Approved " +
                                      (countSummary &&
                                      countSummary.length > 0 &&
                                      countSummary.find(
                                        (item) => item._id == STATUS_APPROVED
                                      )
                                        ? "(" +
                                          countSummary.find(
                                            (item) =>
                                              item._id == STATUS_APPROVED
                                          ).count +
                                          ")"
                                        : "")
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={feedStatusData.some(
                                          (status) =>
                                            status.key == STATUS_COMPLETED
                                        )}
                                        onChange={updateFeedStatus}
                                        name={STATUS_COMPLETED}
                                      />
                                    }
                                    label={
                                      "Fulfilled " +
                                      (countSummary &&
                                      countSummary.length > 0 &&
                                      countSummary.find(
                                        (item) => item._id == STATUS_COMPLETED
                                      )
                                        ? "(" +
                                          countSummary.find(
                                            (item) =>
                                              item._id == STATUS_COMPLETED
                                          ).count +
                                          ")"
                                        : "")
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={feedStatusData.some(
                                          (status) =>
                                            status.key == STATUS_EXPIRED
                                        )}
                                        onChange={updateFeedStatus}
                                        name={STATUS_EXPIRED}
                                      />
                                    }
                                    label={
                                      "Expired " +
                                      (countSummary &&
                                      countSummary.length > 0 &&
                                      countSummary.find(
                                        (item) => item._id == STATUS_EXPIRED
                                      )
                                        ? "(" +
                                          countSummary.find(
                                            (item) => item._id == STATUS_EXPIRED
                                          ).count +
                                          ")"
                                        : "")
                                    }
                                  />
                                </TabPanel>
                              </Menu>
                            </div>
                          </div>
                        </section>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <section className="tabs-chips drop-box">
                          {/* <div className="chip-main"> </div>
                          <div className="chip-box-desktop"> </div>
                            <div component="ul" className={classes.root}> </div> */}
                          <div className="filter-st byapps">
                            <Slider {...settingsms}>
                              {chipData.map((data) => {
                                return (
                                  <div key={data.key}>
                                    <Chip
                                      label={data.label}
                                      onDelete={() => handleDelete(data)}
                                      className={classes.chip}
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                          <div className="filter-bg">
                            <div className="filter-one">
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handlePostMenuClick}
                              ></IconButton>
                              <Menu
                                style={{ zIndex: "1" }}
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={openPostMenu}
                                onClose={handlePostMenuClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "23ch",
                                  },
                                }}
                                className="popup"
                              >
                                <AppBar
                                  style={{
                                    position: "static",
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                  }}
                                  className="app-bar"
                                >
                                  <Tabs
                                    value={value1}
                                    onChange={handleChange1}
                                    aria-label="simple tabs example"
                                  >
                                    <Tab
                                      label="Apps"
                                      {...a11yProps(0)}
                                      style={{ fontWeight: "bold" }}
                                    />
                                  </Tabs>
                                </AppBar>
                                <TabPanel
                                  component="div"
                                  value={value1}
                                  index={0}
                                  style={{ padding: "10px" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={chipData.some(
                                          (chip) => chip.key === "4"
                                        )}
                                        onChange={handleCheck}
                                        name="0"
                                      />
                                    }
                                    label="Board Member"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={chipData.some(
                                          (chip) => chip.key === "5"
                                        )}
                                        onChange={handleCheck}
                                        name="1"
                                      />
                                    }
                                    label="Podcast"
                                  />
                                </TabPanel>
                              </Menu>
                            </div>
                          </div>
                        </section>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <section className="tabs-chips drop-box">
                          {/* <div className="chip-main"></div>
                          <div className="chip-box-desktop">  </div>
                            <div component="ul" className={classes.root}></div> */}
                          <div className="filter-st last-slider">
                            <Slider {...settingsls}>
                              {feedStatusData.map((data) => {
                                return (
                                  <div key={data.key}>
                                    <Chip
                                      label={data.label}
                                      onDelete={() =>
                                        handleFeedStatusDelete(data)
                                      }
                                      className={classes.chip}
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                          <div className="filter-bg">
                            <div className="filter-one">
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handlePostMenuClick2}
                              ></IconButton>

                              <Menu
                                style={{ zIndex: "1" }}
                                id="second-menu"
                                anchorEl={anchorEl2}
                                keepMounted
                                open={openPostMenu2}
                                onClose={handlePostMenuClose2}
                                PaperProps={{}}
                                className="popup"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px 10px 0 ",
                                  }}
                                >
                                  <p
                                    style={{
                                      borderBottom:
                                        "1px solid rgb(246, 247, 251)",
                                      margin: "0",
                                      paddingBottom: "8px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Filter Requests
                                  </p>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={feedStatusData.some(
                                          (status) =>
                                            status.key === STATUS_APPROVED
                                        )}
                                        onChange={updateFeedStatus}
                                        name={STATUS_APPROVED}
                                      />
                                    }
                                    label={
                                      "Approved " +
                                      (countSummary &&
                                      countSummary.length > 0 &&
                                      countSummary.find(
                                        (item) => item._id === STATUS_APPROVED
                                      )
                                        ? "(" +
                                          countSummary.find(
                                            (item) =>
                                              item._id === STATUS_APPROVED
                                          ).count +
                                          ")"
                                        : "")
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={feedStatusData.some(
                                          (status) =>
                                            status.key === STATUS_COMPLETED
                                        )}
                                        onChange={updateFeedStatus}
                                        name={STATUS_COMPLETED}
                                      />
                                    }
                                    label={
                                      "Fulfilled " +
                                      (countSummary &&
                                      countSummary.length > 0 &&
                                      countSummary.find(
                                        (item) => item._id == STATUS_COMPLETED
                                      )
                                        ? "(" +
                                          countSummary.find(
                                            (item) =>
                                              item._id == STATUS_COMPLETED
                                          ).count +
                                          ")"
                                        : "")
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={feedStatusData.some(
                                          (status) =>
                                            status.key == STATUS_EXPIRED
                                        )}
                                        onChange={updateFeedStatus}
                                        name={STATUS_EXPIRED}
                                      />
                                    }
                                    label={
                                      "Expired " +
                                      (countSummary &&
                                      countSummary.length > 0 &&
                                      countSummary.find(
                                        (item) => item._id == STATUS_EXPIRED
                                      )
                                        ? "(" +
                                          countSummary.find(
                                            (item) => item._id == STATUS_EXPIRED
                                          ).count +
                                          ")"
                                        : "")
                                    }
                                  />
                                </div>
                              </Menu>
                            </div>
                          </div>
                        </section>
                      </TabPanel>
                    </Paper>
                  </Fragment>
                )}
                {isSearchMode && (
                  <Box className="drop-box search_home">
                    <Box display="felx">
                      <Box component="span" alignSelf="center" pr={1}>
                        {" "}
                        Search Results for{" "}
                      </Box>

                      <Box
                        component="span"
                        bgcolor="rgb(243 243 243)"
                        borderRadius="50px"
                        color="rgb(205, 38, 39)"
                        alignSelf="center"
                        p={1}
                        boxShadow="0 0 5px greay"
                      >
                        {keyword}{" "}
                      </Box>
                    </Box>
                    <Box alignSelf="center">
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        className="search_btn"
                      >
                        <Tooltip title="Clear Search Results">
                          <CloseIcon onClick={handleClearSearch} />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </Box>
                )}
                <div>
                  {isSearchMode &&
                    searchDonationRequests &&
                    searchDonationRequests.length > 0 &&
                    searchDonationRequests.map((donationRequest, index) => {
                      return (
                        <DonationRequestCard
                          donationRequest={donationRequest}
                          key={index}
                        />
                      );
                    })}
                  {isSearchMode &&
                    !isLoading &&
                    !hasNextPage &&
                    searchDonationRequests.length === 0 && (
                      <h4 style={{ textAlign: "center" }}>
                        No requests found.
                      </h4>
                    )}
                </div>
                {!isSearchMode && avatar === AVATAR_DONEE && (
                  <div className="myreq-card-details drop-box">
                    <Typography component="div" className="myreq-container">
                      <Typography component="div" className="myreq-header">
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          className={`${classes.root} myreq-header-list`}
                        >
                          <ListItem
                            button
                            className="myreq-sublist"
                            onClick={() => setStatus(STATUS_ALL)}
                          >
                            <ListItemText
                              primary="All"
                              style={{
                                borderBottom:
                                  status === STATUS_ALL
                                    ? "2px solid red"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                            />
                          </ListItem>
                          <ListItem button className="myreq-sublist">
                            <ListItemText
                              primary="Approved"
                              style={{
                                borderBottom:
                                  status === STATUS_APPROVED
                                    ? "2px solid red"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                              onClick={() => setStatus(STATUS_APPROVED)}
                            />
                          </ListItem>
                          <ListItem button className="myreq-sublist">
                            <ListItemText
                              primary="Rejected"
                              style={{
                                borderBottom:
                                  status === STATUS_REJECTED
                                    ? "2px solid red"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                              onClick={() => setStatus(STATUS_REJECTED)}
                            />
                          </ListItem>
                          <ListItem button className="myreq-sublist">
                            <ListItemText
                              primary="Pending"
                              style={{
                                borderBottom:
                                  status === STATUS_NEW
                                    ? "2px solid red"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                              onClick={() => setStatus(STATUS_NEW)}
                            />
                          </ListItem>
                          <ListItem button className="myreq-sublist">
                            <ListItemText
                              primary="Spam"
                              style={{
                                borderBottom:
                                  status === STATUS_SPAM
                                    ? "2px solid red"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                              onClick={() => setStatus(STATUS_SPAM)}
                            />
                          </ListItem>
                          <ListItem button className="myreq-sublist">
                            <ListItemText
                              primary="Expired"
                              style={{
                                borderBottom:
                                  status === STATUS_EXPIRED
                                    ? "2px solid red"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                              onClick={() => setStatus(STATUS_EXPIRED)}
                            />
                          </ListItem>
                          <ListItem button className="myreq-sublist">
                            <ListItemText
                              primary="Completed"
                              style={{
                                borderBottom:
                                  status === STATUS_COMPLETED
                                    ? "2px solid red"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                              onClick={() => setStatus(STATUS_COMPLETED)}
                            />
                          </ListItem>
                        </List>
                      </Typography>
                    </Typography>{" "}
                  </div>
                )}
                <div>
                  {!isSearchMode &&
                    donationRequests &&
                    donationRequests.length > 0 &&
                    donationRequests.map((donationRequest, index) => {
                      return (
                        <DonationRequestCard
                          donationRequest={donationRequest}
                          setDonationRequests={setDonationRequests}
                          donationRequests={donationRequests}
                          key={index}
                          removeFromList={() =>
                            removeFromList(donationRequest._id)
                          }
                        />
                      );
                    })}
                  {hasNextPage && (
                    <p
                      ref={keyword ? keywordRef : infiniteRef}
                      style={{ textAlign: "center" }}
                    >
                      Loading...
                    </p>
                  )}
                </div>
                {!hasNextPage &&
                  !isLoading &&
                  !isSearchMode &&
                  donationRequests.length === 0 && (
                    <Typography component="div" className="myreq-cards ">
                      <figure className="drop-box">
                        <Img
                          src="/images/no-req.png"
                          alt="no requests"
                          width="100%"
                        />
                      </figure>
                      <Typography
                        className="drop-box"
                        style={{
                          textAlign: "center",
                          background: "white",
                          padding: "20px 10px",
                        }}
                      >
                        <h4>The requests list is empty</h4>
                      </Typography>
                    </Typography>
                  )}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={3} className="order-col">
              {/* <div>
                <Link
                  href="https://www.touchalife.org/nominate-talhero/"
                  target="_blank"
                >
                  {" "}
                  <figure className="drop-box">
                    <Img
                      src="/images/call-for-nomination.png"
                      alt="no requests"
                      width="100%"
                    />
                  </figure>
                </Link>
              </div> */}
              <div className="container-right">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <div>
                    <Hidden smDown>
                      <div
                        className=" no-pad promoBlk"
                        style={{ padding: "0", marginBottom: "25px" }}
                      >
                        <div className="promotion">
                          <div className="quick-menu">
                            <p>TALRadio - Listen, Feel and Act! </p>
                            <Audio />
                          </div>
                        </div>
                      </div>
                    </Hidden>

                    <Hidden smDown>
                      <div className="sidemenu-container">
                        <div className="store-block drop-box">
                          <p>Download The TALLeaders Circle App Now</p>
                          <hr></hr>
                          <div className="scan-img">
                            <img
                              src="/images/android-ios-store.png"
                              alt="Scan to download the app"
                            />
                          </div>
                          <div className=" scan">
                            <PhoneAndroidIcon />
                            Scan to Download
                          </div>
                        </div>
                      </div>

                      <section
                        className=" no-pad latestNewsBlk drop-box"
                        style={{ padding: "0" }}
                      ></section>
                      <div className="home-footer drop-box">
                        <p>
                          <a
                            href="https://www.touchalife.org/about/"
                            target="blank"
                          >
                            About .
                          </a>{" "}
                          <a
                            href={`${process.env.REACT_APP_TALGIVING_URL}/user/help-desk`}
                            target="blank"
                          >
                            Help Desk .
                          </a>{" "}
                          <a
                            href={`${process.env.REACT_APP_TALGIVING_URL}/user/Privacy`}
                            target="blank"
                          >
                            Privacy .{" "}
                          </a>{" "}
                          <a
                            href={`${process.env.REACT_APP_TALGIVING_URL}/user/Terms`}
                            target="blank"
                          >
                            Terms
                          </a>
                          {". "}
                          <a
                            href={`${process.env.REACT_APP_TALGIVING_URL}/user/ContactUs`}
                            target="blank"
                          >
                            Contact Us
                          </a>{" "}
                        </p>
                        <p>Touch-A-Life Foundation INC &copy; {currentYear}</p>
                      </div>
                    </Hidden>
                  </div>
                </StickyBox>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default Home2;

import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import getBlobDuration from "get-blob-duration";
import { saveShortUrl } from "./api";
export const DATE_FORMAT = "DD-MMM-YYYY";
export const DATE_FORMAT_FULL = "dddd, Do MMMM YYYY";
export const DATE_TIME_FORMAT = "DD-MMM-YYYY hh:mm A";
export const DATE_TIME_FORMAT_FULL = "dddd, Do MMMM YYYY, hh:mm A";
export const DATE_TIME_FORMAT_FULL_PICKER = "dd-MMMM-yyyy, hh:mm a";
export const DATE_TIME_TIMEZONE_FORMAT_FULL = "dddd, Do MMMM YYYY, hh:mm A ZZ";
export const TIME_FORMAT = "hh:mm A";
export const TIME_FORMAT_SECONDS = "HH:mm:ss";
export const HOME_PAGE = "/";
export const DASHBOARD_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const TALMEDIA_PAGE = "/talmedia";
export const TAL_LEADERS_PAGE = "/admin/TalLeaders";
export const TAL_HEROS_PAGE = "/admin/TalHeros";
export const TALRADIO_SITE = "https://talradio.org";
export const AVATAR_DONOR = "Donor";
export const AVATAR_DONEE = "Donee";
export const STATUS_NEW = "0";
export const STATUS_APPROVED = "1";
export const STATUS_COMPLETED = "2";
export const STATUS_ALL = "";
export const STATUS_REJECTED = "-1";
export const STATUS_INCOMPLETE = "-2";
export const STATUS_SPAM = "-3";
export const STATUS_EXPIRED = "-4";
export const STATUS_DEACTIVATED = "-5";
export const ROLE_OWNER = "owner";
export const ROLE_ADMIN = "admin";
export const ROLE_MEMBER = "member";
export const REQUEST_TYPE_CASH = "cash";
export const REQUEST_TYPE_PLASMA = "plasma";
export const REGION_ID_INDIA = "IN";
export const REGION_ID_USA = "US";
export const COUNTRY_CODE_ISO3_INDIA = "IND";
export const COUNTRY_CODE_ISO3_USA = "USA";
export const CURRENCY_USD = "USD";
export const CURRENCY_INR = "INR";

export const DONATION_REQUEST_STATUS_MAP = {
  0: {
    shortLabel: "Pending",
    label: "APPROVAL IS PENDING BY TOUCH-A-LIFE",
    bgcolor: "info.main",
    color: "info.contrastText",
    ribbonBgColor: "PENDING",
    ribbonTextColor: "text_white",
  },
  1: {
    shortLabel: "Approved",
    label: "APPROVED",
    bgcolor: "success.main",
    color: "success.contrastText",
    ribbonBgColor: "APPROVED",
    ribbonTextColor: "text_white",
  },
  2: {
    shortLabel: "Fulfilled",
    label: "FULFILLED",
    bgcolor: "success.main",
    color: "success.contrastText",
    ribbonBgColor: "FULFILLED",
    ribbonTextColor: "text_white",
  },
  "-1": {
    shortLabel: "Rejected",
    label: "REJECTED",
    bgcolor: "error.main",
    color: "error.contrastText",
    ribbonBgColor: "REJECTED",
    ribbonTextColor: "text_white",
  },
  "-2": {
    shortLabel: "Incomplete",
    label: "INCOMPLETE INFORMATION BY DONEE",
    bgcolor: "text.disabled",
    color: "background.paper",
    ribbonBgColor: "INCOMPLETE",
    ribbonTextColor: "text_white",
  },
  "-3": {
    shortLabel: "Spam",
    label: "SPAM",
    bgcolor: "text.secondary",
    color: "background.paper",
    ribbonBgColor: "SPAM",
    ribbonTextColor: "text_white",
  },
  "-4": {
    shortLabel: "Expired",
    label: "EXPIRED",
    bgcolor: "warning.main",
    color: "warning.contrastText",
    ribbonBgColor: "EXPIRED",
    ribbonTextColor: "text_white",
  },
  "-5": {
    shortLabel: "Deactivated",
    label: "DEACTIVATED",
    bgcolor: "warning.main",
    color: "warning.contrastText",
    ribbonBgColor: "DEACTIVATED",
    ribbonTextColor: "text_white",
  },
};

export const CONTENT_TYPE_EXTENSIONS_MAP = {
  "audio/mpeg": ".mp3",
  "video/mpeg": ".mpeg",
  "audio/ogg": ".oga",
  "video/ogg": ".ogv",
  "audio/opus": ".opus",
  "audio/wav": ".wav",
  "audio/webm": ".weba",
  "video/webm": ".webm",
  "video/3gpp": ".3gp",
  "audio/3gpp": ".3gp",
  "video/3gpp2": ".3g2",
  "audio/3gpp2": ".3g2",
  "audio/aac": ".aac",
  "video/x-msvideo": ".avi",
  "audio/midi": ".mid",
  "audio/x-midi": ".midi",
};

export const GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX = "_776x435";

export const getGoogleCloudStorageSuffixedUrl = (url) => {
  if (url) {
    if (url.indexOf(GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX) < 0) {
      return url.replace(
        "?",
        GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX + "?"
      );
    } else {
      return url;
    }
  } else {
    return url;
  }
};

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const formatScore = (value) => {
  if (value >= 1000000) {
    value = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000) {
    value = (value / 1000).toFixed(1) + "K";
  } else if (value >= 1000000000000) {
    value = (value / 1000000000000).toFixed(1) + "B";
  }
  return value;
};

export const formatNumber = (number) => {
  if (isNaN(number)) {
    return number;
  }
  if (number) {
    return Number(number).toLocaleString();
  }
  return number;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isImage = (contentType) => {
  return (
    contentType === "image/jpeg" ||
    contentType === "image/gif" ||
    contentType === "image/png" ||
    contentType === "image/bmp" ||
    contentType === "image/tiff" ||
    contentType === "image/svg+xml" ||
    contentType === "image/webp"
  );
};

export const isAudio = (contentType) => {
  if (contentType) {
    return (
      contentType.indexOf("audio") >= 0 ||
      contentType.indexOf("application/octet-stream") >= 0
    );
  }
  return false;
};

export const isValidPassword = (password) => {
  let msg = "";
  if (!password) {
    msg += "Password is required. ";
  }

  if (!password.match(/[a-z]/)) {
    msg += "Contains at least one letter. ";
  }

  if (!password.match(/[A-Z]+/)) {
    msg += "Contains at least one capital letter. ";
  }

  if (!password.match(/\d/)) {
    msg += "Contains at least one digit. ";
  }

  if (!password.match(/[^a-zA-Z0-9\-/]/)) {
    msg += "Contains at least one special character. ";
  }

  if (password.length < 6) {
    msg += "Contains at least 6 characters. ";
  }

  return msg;
};

export const areUrlsSame = (url1, url2) => {
  if (url1) {
    url1 = url1.substring(0, url1.indexOf("?"));
  }
  if (url2) {
    url2 = url2.substring(0, url2.indexOf("?"));
  }
  return url1 === url2;
};

export const fetchAndCreateObject = async function (fileUrl) {
  console.log("fetchAndCreateObject URL ==> ", fileUrl);
  if (!fileUrl) {
    return;
  }

  try {
    const fetchFromUrl = await fetch(fileUrl);
    const blobData = fetchFromUrl.blob();
    return blobData;
  } catch (e) {
    throw e.message;
  }
};

export const getBlobFromUrl = async function (fileUrls = []) {
  if (!fileUrls) {
    return [];
  }

  let blobFiles = [];
  let index = 0;
  for (const eachFileUrl of fileUrls) {
    const blobContent = await fetchAndCreateObject(eachFileUrl);
    blobFiles.push(blobContent);
    if (index === fileUrls.length - 1) {
      return blobFiles;
    }
    index++;
  }
};

export const mergeAudioFiles = async (selectedFiles) => {
  const ffmpeg = createFFmpeg({
    log: false,
  });

  //load ffmpeg library
  await ffmpeg.load();

  let concatCmd = [];

  for (let i = 0; i < selectedFiles.length; i++) {
    const fetchedFile = await fetchFile(URL.createObjectURL(selectedFiles[i]));
    ffmpeg.FS("writeFile", `f${i + 1}.mp3`, fetchedFile);
    concatCmd.push(`file f${i + 1}.mp3`);
    if (i === selectedFiles.length - 1) {
      return new Promise(async (resolve, reject) => {
        ffmpeg.FS("writeFile", "concat_list.txt", concatCmd.join("\n"));
        await ffmpeg.run(
          "-f",
          "concat",
          "-safe",
          "0",
          "-i",
          "concat_list.txt",
          "finaloutput.mp3"
        );

        //await ffmpeg.run("-i", concatCmd, "-safe", "0", "finaloutput.mp3");
        const finaloutput = ffmpeg.FS("readFile", "finaloutput.mp3");

        //find file duration
        const fileDuration = await getBlobDuration(
          new Blob([finaloutput.buffer], { type: "audio/mp3" })
        );

        await ffmpeg.run("unlink", "finaloutput.mp3");
        await ffmpeg.run("unlink", "concat_list.txt");

        resolve({
          file: finaloutput,
          duration: fileDuration && parseInt(fileDuration),
        });
      });
    }
  }
};

export const getObjectValuesInStringFormat = (obj, concat = ", ") => {
  if (!obj) {
    return "";
  }
  for (var propName in obj) {
    if (!obj[propName] || obj[propName] === true) {
      delete obj[propName];
    }
  }
  return Object.values(obj).join(concat);
};
export function arraysEqual(a, b) {
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i]._id !== b[i]._id) return false;
  }
  return true;
}
export function getDonationRequestSharedUrl(donationRequest = {}) {
  let url =
    process.env.REACT_APP_BASE_URL +
    "/donationRequest/" +
    "#name#?uniqueId=#_id#&name=#name#&type=donationRequest";
  if (donationRequest) {
    url = url.replaceAll("#name#", donationRequest.name);
    url = url.replace("#_id#", donationRequest._id);
    url = url.replace("#title#", donationRequest.title);
    return encodeURI(url);
  }
}

export function getAudioRoomSessionSharedUrl(chatRoom = {}, authUser = {}) {
  let url = process.env.REACT_APP_CHAT_SERVER_BASE_URL + "/join/#_id#";

  if (chatRoom) {
    url = url.replace("#_id#", chatRoom && chatRoom._id);
    return encodeURI(url);
  } else {
    console.log("error occured");
    return;
  }
}

export function convertFirstCharacterToUppercase(stringToConvert) {
  var firstCharacter = stringToConvert.substring(0, 1);
  var restString = stringToConvert.substring(1);

  return firstCharacter.toUpperCase() + restString;
}

export function podcastShareUrl(podcastItem = {}) {
  let url =
    process.env.REACT_APP_API_BASE_URL +
    "/talmediaShare/#fileName#?note=#note#&path=/talmedia/#fileName#&contentType=#contentType#&type=podcast&uniqueId=#unique_id#&name=#name#&thumbnailUrl=#thumbnailUrl#";
  if (podcastItem) {
    url = url.replace(/#fileName#/g, podcastItem.file);
    url = url.replace("#note#", podcastItem.note);
    url = url.replace("#contentType#", podcastItem.contentType);
    url = url.replace("#unique_id#", podcastItem.file);
    url = url.replace("#name#", podcastItem.name);
    url = url.replace("#thumbnailUrl#", podcastItem.thumbnailUrl);
    return encodeURI(url);
  } else {
    console.log("error occured");
    return;
  }
}

// Capitalizes first letter of each word of a given string
export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

// get value of a query string from the URL
export function getQueryStringValue(key = null) {
  if (!key) return;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return params.get(key) || null;
}

export const getShortUrl = (url, representsWhat, referenceId) => {
  return new Promise((resolve, reject) => {
    const payload = {
      url,
      representsWhat,
      referenceId,
    };

    saveShortUrl(payload)
      .then((response) => {
        resolve(response.data.data.shortUrl);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getKeyByValue(object, value) {
  return Object.keys(object).filter((key) => object[key] == value);
}

export function validateUrl(userURL) {
  try {
    var url = new URL(userURL);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (err) {
    return false;
  }
}

export function checkDefaultChannel(currentChannel = null) {
  if (currentChannel == "telugu") {
    return "telugu";
  } else if (currentChannel == "hindi") {
    return "hindi";
  } else if (currentChannel == "talradio") {
    return "english";
  } else {
    return "all";
  }
}

export function getCountry(result) {
  return result.data.filter(
    (country) => country.sortname === "IN" || country.sortname === "US"
  );
}

export function checkUrlWithHttp(url = "") {
  return url && !/^https?:\/\//i.test(url) ? `http://${url}` : url;
}

export const REQUEST_TYPES = [
  { key: "mentoring", value: "Mentoring" },
  { key: "eventSpeaker", value: "Event Speaker" },
  { key: "board member", value: "Board Member" },
  { key: "podcast", value: "Podcast" },
];

export const TAL_HEROS_COUNTRIES = [
  { key: "Afghanistan", value: "Afghanistan" },
  { key: "Åland Islands", value: "Åland Islands" },
  { key: "Albania", value: "Albania" },
  { key: "Algeria", value: "Algeria" },
  { key: "American Samoa", value: "American Samoa" },
  { key: "Andorra", value: "Andorra" },
  { key: "Angola", value: "Angola" },
  { key: "Anguilla", value: "Anguilla" },
  { key: "Antarctica", value: "Antarctica" },
  { key: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { key: "Argentina", value: "Argentina" },
  { key: "Armenia", value: "Armenia" },
  { key: "Aruba", value: "Aruba" },
  { key: "Australia", value: "Australia" },
  { key: "Austria", value: "Austria" },
  { key: "Azerbaijan", value: "Azerbaijan" },
  { key: "Bahamas", value: "Bahamas" },
  { key: "Bahrain", value: "Bahrain" },
  { key: "Bangladesh", value: "Bangladesh" },
  { key: "Barbados", value: "Barbados" },
  { key: "Belarus", value: "Belarus" },
  { key: "Belgium", value: "Belgium" },
  { key: "Belize", value: "Belize" },
  { key: "Benin", value: "Benin" },
  { key: "Bermuda", value: "Bermuda" },
  { key: "Bhutan", value: "Bhutan" },
  { key: "Bolivia", value: "Bolivia" },
  { key: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { key: "Botswana", value: "Botswana" },
  { key: "Bouvet Island", value: "Bouvet Island" },
  { key: "Brazil", value: "Brazil" },
  {
    key: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
  },
  { key: "Brunei Darussalam", value: "Brunei Darussalam" },
  { key: "Bulgaria", value: "Bulgaria" },
  { key: "Burkina Faso", value: "Burkina Faso" },
  { key: "Burundi", value: "Burundi" },
  { key: "Cambodia", value: "Cambodia" },
  { key: "Cameroon", value: "Cameroon" },
  { key: "Canada", value: "Canada" },
  { key: "Cape Verde", value: "Cape Verde" },
  { key: "Cayman Islands", value: "Cayman Islands" },
  { key: "Central African Republic", value: "Central African Republic" },
  { key: "Chad", value: "Chad" },
  { key: "Chile", value: "Chile" },
  { key: "China", value: "China" },
  { key: "Christmas Island", value: "Christmas Island" },
  { key: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { key: "Colombia", value: "Colombia" },
  { key: "Comoros", value: "Comoros" },
  { key: "Congo", value: "Congo" },
  {
    key: "Congo, The Democratic Republic of The",
    value: "Congo, The Democratic Republic of The",
  },
  { key: "Cook Islands", value: "Cook Islands" },
  { key: "Costa Rica", value: "Costa Rica" },
  { key: "Cote D'ivoire", value: "Cote D'ivoire" },
  { key: "Croatia", value: "Croatia" },
  { key: "Cuba", value: "Cuba" },
  { key: "Cyprus", value: "Cyprus" },
  { key: "Czech Republic", value: "Czech Republic" },
  { key: "Denmark", value: "Denmark" },
  { key: "Djibouti", value: "Djibouti" },
  { key: "Dominica", value: "Dominica" },
  { key: "Dominican Republic", value: "Dominican Republic" },
  { key: "Ecuador", value: "Ecuador" },
  { key: "Egypt", value: "Egypt" },
  { key: "El Salvador", value: "El Salvador" },
  { key: "Equatorial Guinea", value: "Equatorial Guinea" },
  { key: "Eritrea", value: "Eritrea" },
  { key: "Estonia", value: "Estonia" },
  { key: "Ethiopia", value: "Ethiopia" },
  { key: "Falkland Islands (Malvinas)", value: "Falkland Islands (Malvinas)" },
  { key: "Faroe Islands", value: "Faroe Islands" },
  { key: "Fiji", value: "Fiji" },
  { key: "Finland", value: "Finland" },
  { key: "France", value: "France" },
  { key: "French Guiana", value: "French Guiana" },
  { key: "French Polynesia", value: "French Polynesia" },
  { key: "French Southern Territories", value: "French Southern Territories" },
  { key: "Gabon", value: "Gabon" },
  { key: "Gambia", value: "Gambia" },
  { key: "Georgia", value: "Georgia" },
  { key: "Germany", value: "Germany" },
  { key: "Ghana", value: "Ghana" },
  { key: "Gibraltar", value: "Gibraltar" },
  { key: "Greece", value: "Greece" },
  { key: "Greenland", value: "Greenland" },
  { key: "Grenada", value: "Grenada" },
  { key: "Guadeloupe", value: "Guadeloupe" },
  { key: "Guam", value: "Guam" },
  { key: "Guatemala", value: "Guatemala" },
  { key: "Guernsey", value: "Guernsey" },
  { key: "Guinea", value: "Guinea" },
  { key: "Guinea-bissau", value: "Guinea-bissau" },
  { key: "Guyana", value: "Guyana" },
  { key: "Haiti", value: "Haiti" },
  {
    key: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands",
  },
  {
    key: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
  },
  { key: "Honduras", value: "Honduras" },
  { key: "Hong Kong", value: "Hong Kong" },
  { key: "Hungary", value: "Hungary" },
  { key: "Iceland", value: "Iceland" },
  { key: "India", value: "India" },
  { key: "Indonesia", value: "Indonesia" },
  { key: "Iran, Islamic Republic of", value: "Iran, Islamic Republic of" },
  { key: "Iraq", value: "Iraq" },
  { key: "Ireland", value: "Ireland" },
  { key: "Isle of Man", value: "Isle of Man" },
  { key: "Israel", value: "Israel" },
  { key: "Italy", value: "Italy" },
  { key: "Jamaica", value: "Jamaica" },
  { key: "Japan", value: "Japan" },
  { key: "Jersey", value: "Jersey" },
  { key: "Jordan", value: "Jordan" },
  { key: "Kazakhstan", value: "Kazakhstan" },
  { key: "Kenya", value: "Kenya" },
  { key: "Kiribati", value: "Kiribati" },
  {
    key: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of",
  },
  { key: "Korea, Republic of", value: "Korea, Republic of" },
  { key: "Kuwait", value: "Kuwait" },
  { key: "Kyrgyzstan", value: "Kyrgyzstan" },
  {
    key: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
  },
  { key: "Latvia", value: "Latvia" },
  { key: "Lebanon", value: "Lebanon" },
  { key: "Lesotho", value: "Lesotho" },
  { key: "Liberia", value: "Liberia" },
  { key: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya" },
  { key: "Liechtenstein", value: "Liechtenstein" },
  { key: "Lithuania", value: "Lithuania" },
  { key: "Luxembourg", value: "Luxembourg" },
  { key: "Macao", value: "Macao" },
  {
    key: "Macedonia, The Former Yugoslav Republic of",
    value: "Macedonia, The Former Yugoslav Republic of",
  },
  { key: "Madagascar", value: "Madagascar" },
  { key: "Malawi", value: "Malawi" },
  { key: "Malaysia", value: "Malaysia" },
  { key: "Maldives", value: "Maldives" },
  { key: "Mali", value: "Mali" },
  { key: "Malta", value: "Malta" },
  { key: "Marshall Islands", value: "Marshall Islands" },
  { key: "Martinique", value: "Martinique" },
  { key: "Mauritania", value: "Mauritania" },
  { key: "Mauritius", value: "Mauritius" },
  { key: "Mayotte", value: "Mayotte" },
  { key: "Mexico", value: "Mexico" },
  {
    key: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
  },
  { key: "Moldova, Republic of", value: "Moldova, Republic of" },
  { key: "Monaco", value: "Monaco" },
  { key: "Mongolia", value: "Mongolia" },
  { key: "Montenegro", value: "Montenegro" },
  { key: "Montserrat", value: "Montserrat" },
  { key: "Morocco", value: "Morocco" },
  { key: "Mozambique", value: "Mozambique" },
  { key: "Myanmar", value: "Myanmar" },
  { key: "Namibia", value: "Namibia" },
  { key: "Nauru", value: "Nauru" },
  { key: "Nepal", value: "Nepal" },
  { key: "Netherlands", value: "Netherlands" },
  { key: "Netherlands Antilles", value: "Netherlands Antilles" },
  { key: "New Caledonia", value: "New Caledonia" },
  { key: "New Zealand", value: "New Zealand" },
  { key: "Nicaragua", value: "Nicaragua" },
  { key: "Niger", value: "Niger" },
  { key: "Nigeria", value: "Nigeria" },
  { key: "Niue", value: "Niue" },
  { key: "Norfolk Island", value: "Norfolk Island" },
  { key: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { key: "Norway", value: "Norway" },
  { key: "Oman", value: "Oman" },
  { key: "Pakistan", value: "Pakistan" },
  { key: "Palau", value: "Palau" },
  {
    key: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied",
  },
  { key: "Panama", value: "Panama" },
  { key: "Papua New Guinea", value: "Papua New Guinea" },
  { key: "Paraguay", value: "Paraguay" },
  { key: "Peru", value: "Peru" },
  { key: "Philippines", value: "Philippines" },
  { key: "Pitcairn", value: "Pitcairn" },
  { key: "Poland", value: "Poland" },
  { key: "Portugal", value: "Portugal" },
  { key: "Puerto Rico", value: "Puerto Rico" },
  { key: "Qatar", value: "Qatar" },
  { key: "Reunion", value: "Reunion" },
  { key: "Romania", value: "Romania" },
  { key: "Russian Federation", value: "Russian Federation" },
  { key: "Rwanda", value: "Rwanda" },
  { key: "Saint Helena", value: "Saint Helena" },
  { key: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { key: "Saint Lucia", value: "Saint Lucia" },
  { key: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  {
    key: "Saint Vincent and The Grenadines",
    value: "Saint Vincent and The Grenadines",
  },
  { key: "Samoa", value: "Samoa" },
  { key: "San Marino", value: "San Marino" },
  { key: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { key: "Saudi Arabia", value: "Saudi Arabia" },
  { key: "Senegal", value: "Senegal" },
  { key: "Serbia", value: "Serbia" },
  { key: "Seychelles", value: "Seychelles" },
  { key: "Sierra Leone", value: "Sierra Leone" },
  { key: "Singapore", value: "Singapore" },
  { key: "Slovakia", value: "Slovakia" },
  { key: "Slovenia", value: "Slovenia" },
  { key: "Solomon Islands", value: "Solomon Islands" },
  { key: "Somalia", value: "Somalia" },
  { key: "South Africa", value: "South Africa" },
  {
    key: "South Georgia and The South Sandwich Islands",
    value: "South Georgia and The South Sandwich Islands",
  },
  { key: "Spain", value: "Spain" },
  { key: "Sri Lanka", value: "Sri Lanka" },
  { key: "Sudan", value: "Sudan" },
  { key: "Suriname", value: "Suriname" },
  { key: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { key: "Swaziland", value: "Swaziland" },
  { key: "Sweden", value: "Sweden" },
  { key: "Switzerland", value: "Switzerland" },
  { key: "Syrian Arab Republic", value: "Syrian Arab Republic" },
  { key: "Taiwan", value: "Taiwan" },
  { key: "Tajikistan", value: "Tajikistan" },
  {
    key: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
  },
  { key: "Thailand", value: "Thailand" },
  { key: "Timor-leste", value: "Timor-leste" },
  { key: "Togo", value: "Togo" },
  { key: "Tokelau", value: "Tokelau" },
  { key: "Tonga", value: "Tonga" },
  { key: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { key: "Tunisia", value: "Tunisia" },
  { key: "Turkey", value: "Turkey" },
  { key: "Turkmenistan", value: "Turkmenistan" },
  { key: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { key: "Tuvalu", value: "Tuvalu" },
  { key: "Uganda", value: "Uganda" },
  { key: "Ukraine", value: "Ukraine" },
  { key: "United Arab Emirates", value: "United Arab Emirates" },
  { key: "United Kingdom", value: "United Kingdom" },
  { key: "United States", value: "United States" },
  {
    key: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
  },
  { key: "Uruguay", value: "Uruguay" },
  { key: "Uzbekistan", value: "Uzbekistan" },
  { key: "Vanuatu", value: "Vanuatu" },
  { key: "Venezuela", value: "Venezuela" },
  { key: "Viet Nam", value: "Viet Nam" },
  { key: "Virgin Islands, British", value: "Virgin Islands, British" },
  { key: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
  { key: "Wallis and Futuna", value: "Wallis and Futuna" },
  { key: "Western Sahara", value: "Western Sahara" },
  { key: "Yemen", value: "Yemen" },
  { key: "Zambia", value: "Zambia" },
  { key: "Zimbabwe", value: "Zimbabwe" },
];

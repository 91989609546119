import React, { useContext, useEffect, useState } from "react";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { getPlaylistItem } from "../../utils/api";
import { makeStyles } from "@material-ui/core/styles";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import CloseIcon from "@material-ui/icons/Close";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { withRouter } from "react-router";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import VideoPlayer from "../common/VideoPlayer";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { SearchRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
    "& td:nth-of-type(5)": {
      whiteSpace: "nowrap",
    },
    "& td:nth-of-type(6)": {
      whiteSpace: "nowrap",
    },
  },

  rightmenu: {
    "& Button": {
      marginLeft: "5px",
    },

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  livestreamings: {
    display: "flex",
    gap: "10px",
    alignItems: "center",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      height: "12px",
      backgroundColor: "#31A93D",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "var(--tal_primary)",
      fontSize: "18px",
      fontWaite: "bold",
      margin: "0",
      whiteSpace: "nowrap",
    },
    "& h4": {
      fontSize: "18px",
      fontWaite: "bold",
      color: "#575757",
      margin: "0",
    },

    "&  Button:nth-of-type(1)": {
      border: "#CD2729 2px solid",
      backgroundColor: "white",
      color: "#CD2729",
      borderRadius: "5px",
      padding: "5px",
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "#CD2729",
      color: "white",
    },
  },
}));

function ManagePlaylist(props) {
  const { orows, sublist, setSublist } = props;
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const SortableRow = SortableElement(() => (
    <TableCell>
      <SwapVertIcon />
    </TableCell>
  ));
  const handleMove = (row, direction) => {
    const index = sublist.map((item) => item._id).indexOf(row._id);
    const pos = index + direction;
    if (pos < 0 || pos > sublist.length - 1) {
      return;
    } else {
      setSublist(arrayMove(sublist, index, pos));
    }
  };
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPage2(0);
  };

  const SortableTable = SortableContainer(({ sublist }) => {
    return (
      <TableContainer component={Paper} style={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="selected playlist" size={"medium"}>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 20, paddingLeft: "32px" }}>
                Up
              </TableCell>
              <TableCell style={{ minWidth: 20, paddingLeft: "25px" }}>
                Down
              </TableCell>
              <TableCell style={{ minWidth: 100 }}>Title</TableCell>
              <TableCell style={{ minWidth: 30 }}>Audio Length</TableCell>
              <TableCell style={{ minWidth: 30 }}>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sublist
              //  .slice(page2 * rowsPerPage, page2 * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row._id}>
                  {/* <SortableRow key={`item-${index}`} index={index} row={row} /> */}
                  <TableCell>
                    <Button onClick={() => handleMove(row, -1)}>
                      <ArrowUpwardIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => handleMove(row, +1)}>
                      <ArrowDownwardIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Play this song">
                      <Button onClick={() => onPlayAudio(row)}>
                        {row.title}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {row.duration &&
                      row.duration > 0 &&
                      new Date(row.duration * 1000).toISOString().substr(11, 8)}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => removeFromSublist(index)}>
                      <ClearOutlinedIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {sublist && sublist.length === 0 && (
              <TableRow align="center">
                <TableCell colSpan="10">
                  <Typography
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sublist.length}
          rowsPerPage={rowsPerPage}
          page={page2}
          onChangePage={handleChangePage2}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSublist(arrayMove(sublist, oldIndex, newIndex));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAddToSublist = (row) => {
    //const index = sublist.map((item) => item._id).indexOf(row._id);
    //if (index === -1)
    setSublist([...sublist, row]);
  };
  const removeFromSublist = (index) => {
    sublist.splice(index, 1);
    setSublist([...sublist]);
  };

  const onPlayAudio = (row) => {
    setSelectedRow(row);
    setIsPlayingAudio(true);
  };
  const handlePlayerClose = () => {
    setSelectedRow({});
    setIsPlayingAudio(false);
  };
  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (event.target.value) {
        event.target.blur();
        setKeyword(event.target.value);
      }
    }
  };

  const clearSearchResultsHandler = () => {
    setKeyword("");
    setIsSearchMode(false);
  };
  useEffect(() => {
    if (keyword) {
      setIsSearchMode(true);
      setPage(0);
      setRows(
        orows.filter(
          (row) =>
            row.title && row.title.toLowerCase().includes(keyword.toLowerCase())
        )
      );
    } else {
      setPage(0);
      setIsSearchMode(false);
      setRows(orows);
    }
  }, [keyword, orows]);
  const duration = new Date(
    sublist.reduce((sum, item) => sum + item.duration, 0) * 1000
  )
    .toISOString()
    .substr(11, 8);
  const classes = useStyles();
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box
          classes="manage_playlist"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          minWidth="50%"
        >
          <Box className={classes.livestreamings}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <h2>Add/Remove Playlist Items</h2>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <div className="bar-main">
              <Box className="searchbar-dr" whiteSpace="noWrap">
                <SearchRounded style={{ margin: "0 4px", height: "46px" }} />
                <InputBase
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onKeyPress={(e) => handleEnterClick(e)}
                />
              </Box>

              {isSearchMode && (
                <p className="result" style={{ marginLeft: "20px" }}>
                  Search Results for
                  <span
                    style={{
                      // boxShadow: "rgb(239 239 239) 0px 0px 7px",
                      // borderRadius: "5px",
                      padding: "3px 10px 5px 10px",
                      marginLeft: "10px",
                      fontWeight: " bold",
                      color: "#686868",
                      background: "#f8f8f8",
                    }}
                  >
                    {keyword}
                  </span>
                  <IconButton
                    edge="start"
                    aria-label="close"
                    style={{
                      padding: "3px",
                      color: "var(--tal_primary)",
                      position: "relative",
                      top: "-2px",
                      margin: "0 0 0 10px",
                      border: "1px solid #ededed",
                    }}
                  >
                    <Tooltip title="Clear Search Results">
                      <CloseIcon onClick={clearSearchResultsHandler} />
                    </Tooltip>
                  </IconButton>
                </p>
              )}
            </div>
          </Box>
        </Box>
        <Box style={{ float: "right" }}>
          <TextField
            variant="outlined"
            id="Total Duration"
            label="Total Duration"
            value={duration}
          >
            {duration}
          </TextField>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} style={{ width: "99%" }}>
            <Table
              aria-labelledby="playlist"
              size={"medium"}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Audio Length</TableCell>
                  <TableCell style={{ paddingLeft: "35px" }}>Add</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row._id}>
                        <TableCell>
                          <Tooltip title="Play this song">
                            <Button onClick={() => onPlayAudio(row)}>
                              {row.title}
                            </Button>
                          </Tooltip>
                        </TableCell>

                        <TableCell>
                          {row.duration &&
                            row.duration > 0 &&
                            new Date(row.duration * 1000)
                              .toISOString()
                              .substr(11, 8)}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleAddToSublist(row)}
                            style={{ padding: "6px 0" }}
                          >
                            <AddIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                {props.isLoading && (
                  <TableRow align="center">
                    <TableCell colSpan="10">
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          padding: "10px 0",
                        }}
                      >
                        Loading...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!props.isLoading && rows && rows.length === 0 && (
                  <TableRow align="center">
                    <TableCell colSpan="10">
                      <Typography
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SortableTable sublist={sublist} onSortEnd={onSortEnd} />
        </Grid>
      </Grid>
      <VideoPlayer
        isOpen={isPlayingAudio}
        onClose={handlePlayerClose}
        name={selectedRow.title}
        url={selectedRow.downloadUrl}
        note={selectedRow.description}
      />
    </Box>
  );
}
export default withRouter(ManagePlaylist);

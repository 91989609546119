import React, { useState, useContext } from "react";
import { Button, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { getSelectedEmailTemplate } from "../../utils/api";
import { appContext } from "../../App";
import EmailPreviewCard from "./EmailPreviewCard";
import "./EmailCampaign.scss";

const EmailCampaign = (props) => {
  const { setSelectedTemplate, selectedOrganization } = useContext(appContext);
  const { emailTemplates } = props;
  const [extraErrors] = useState({});

  const showEmail = (emailTemplate) => {
    if (emailTemplate) {
      if (selectedOrganization) {
        getSelectedEmailTemplate(
          emailTemplate._id,
          selectedOrganization._id
        ).then((data) => {
          setSelectedTemplate(data.data.data);
        });
      } else {
        getSelectedEmailTemplate(emailTemplate._id).then((data) => {
          setSelectedTemplate(data.data.data);
        });
      }
      //setSelectedTemplate(emailTemplate);
      props.history.push(
        `/startContactEmailCampaign/EmailEdit/${props.match.params.id}`
      );
    }
  };
  return (
    <div>
      {emailTemplates && emailTemplates.length > 0 && (
        <div className="template-thumbnail">
          {emailTemplates.map((emailTemplate, index) => {
            if (emailTemplate.type === props.type) {
              return (
                <div key={index} style={{ maxWidth: 250, textAlign: "center" }}>
                  <EmailPreviewCard
                    name={emailTemplate.name}
                    emailTemplate={emailTemplate}
                    imgUrl={emailTemplate.previewImageUrl}
                    description={
                      emailTemplate.previewGlossaryValues.description
                    }
                  />
                  <Button
                    variant="contained"
                    className="submit-btn"
                    style={{
                      color: "white",
                      margin: 10,
                      background: "#ef6c00",
                    }}
                    onClick={() => showEmail(emailTemplate)}
                  >
                    Select
                  </Button>
                </div>
              );
            }
          })}
        </div>
      )}
      {extraErrors && extraErrors.selectedEmailTemplate && (
        <Typography className="custom-error email-template">
          {extraErrors.selectedEmailTemplate}
        </Typography>
      )}
    </div>
  );
};

export default withRouter(EmailCampaign);

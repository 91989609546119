import React, { useEffect, useState, useContext } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { appContext } from "../../App";
import CSVReader from "react-csv-reader";
import "../Organization/Organization.scss";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import InfoIcon from "@material-ui/icons/Info";
import {
  addContacts,
  getMyContacts,
  getMyContactGroups,
} from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    heigth: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  title: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
    marginRight: "10px",
  },
  deleteBtn: {
    marginTop: "40px",
    float: "right",
  },
  assignContact: {
    width: "100%",
  },
}));

const AddContactsModal = (props) => {
  const { authUser, contacts, setContacts, setContactGroups } =
    useContext(appContext);
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [upContacts, setUpContacts] = useState([]);

  const parseCSV = (data) => {
    const cts = data
      .filter((item) => item.phonenumber != null || item.email != null)
      .map((contact) => {
        return {
          firstName: contact.firstname ? contact.firstname : "",
          lastName: contact.lastname ? contact.lastname : "",
          email: contact.email ? contact.email : "",
          phoneNumber: contact.phonenumber ? contact.phonenumber : "",
          userId: authUser.unique_id,
        };
      });
    setUpContacts(cts);
    setChecked(cts);
    toastr.success("Number of contacts found: " + cts.length);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const submitHandler = async () => {
    try {
      let data = await addContacts(authUser.unique_id, {
        contacts: [...checked],
      });
      setContacts([...checked, ...contacts]);

      getMyContacts(authUser.unique_id)
        .then((resp) => {
          setContacts(resp);
        })
        .catch((error) => {
          toastr.error(error.message);
        });

      const groups = await getMyContactGroups(authUser.unique_id);
      setContactGroups(groups);
      toastr.success(data.message);
      props.onContactsSave();
    } catch (error) {
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message);
      } else {
        toastr.error(error.message);
      }
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };
  useEffect(() => {
    if (!props.openModal) {
      setUpContacts([]);
    }
  }, [props.openModal]);

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.title}> Import Contacts</h1>
              <div style={{ dispaly: "flex" }}>
                {upContacts && upContacts.length > 0 && (
                  <Button className={classes.saveBtn} onClick={submitHandler}>
                    Save Selected Contacts
                  </Button>
                )}

                <span
                  style={{
                    textAlign: "end",
                    cursor: "pointer",
                    color: "#000",
                    borderRadius: "50px",
                    padding: "4px",
                    float: "right",
                  }}
                  className="drop-box close-organizatin "
                >
                  <CloseIcon onClick={props.onModalClose} />
                </span>
              </div>
            </div>
            <div className={classes.plasma}>
              <form noValidate autoComplete="off">
                <p>
                  <InfoIcon /> Select a csv file that contains the following
                  header fields: firstname, lastname, email, phonenumber
                </p>
                <p>
                  <InfoIcon /> Please note that contacts without an email and a
                  mobile number will be skipped
                </p>
                <CSVReader
                  onFileLoaded={parseCSV}
                  parserOptions={papaparseOptions}
                />
              </form>
              <div className={classes.contactListCntr}>
                {upContacts && upContacts.length > 0 ? (
                  <List className={classes.root}>
                    {upContacts.map((contact) => {
                      const labelId = `checkbox-list-label-${contact._id}`;
                      return (
                        <ListItem
                          key={contact._id}
                          role={undefined}
                          dense
                          button
                          onClick={handleToggle(contact)}
                          style={{
                            borderBottom: "1px solid rgb(246, 247, 251)",
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(contact) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={`${contact.firstName} ${contact.lastName}`}
                            secondary={
                              <>
                                {" "}
                                <span>{`${contact.email} `}</span>{" "}
                                <span>{` ${contact.phoneNumber} `} </span>
                              </>
                            }
                            className="next-line"
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      padding: "10px 0",
                      textAlign: "center",
                    }}
                  >
                    No Contacts
                  </div>
                )}
              </div>
              {upContacts && upContacts.length > 0 && (
                <Button className={classes.saveBtn} onClick={submitHandler}>
                  Save Selected Contacts
                </Button>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddContactsModal;
